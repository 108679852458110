import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const pcl5: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'pcl5-overall',
    unit: UnitType.Number,
    abbreviation: 'PCL 5 Overall',
    description: `PCL 5 Overall`,
    assessmentType: LogDefinitionTypes.PCL5
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-trauma',
    unit: UnitType.Number,
    abbreviation: 'Trauma',
    description: `Trauma`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'GAD7_NERVOUS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-dreams',
    unit: UnitType.Number,
    abbreviation: 'Dreams',
    description: `Dreams`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_DREAMS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-recur',
    unit: UnitType.Number,
    abbreviation: 'Recur',
    description: `Recur`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_RECUR'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-physical',
    unit: UnitType.Number,
    abbreviation: 'Physical',
    description: `Physical`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_PHYSICAL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-reminder',
    unit: UnitType.Number,
    abbreviation: 'Reminder',
    description: `Reminder`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_REMINDER'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-avoiding-thoughts',
    unit: UnitType.Number,
    abbreviation: 'Avoiding Thoughts',
    description: `Avoiding Thoughts`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_AVOIDING_THOUGHTS'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-avoiding-external',
    unit: UnitType.Number,
    abbreviation: 'Avoiding External',
    description: `Avoiding External`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_AVOIDING_EXTERNAL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-memory',
    unit: UnitType.Number,
    abbreviation: 'Memory',
    description: `Memory`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_MEMORY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-self-image',
    unit: UnitType.Number,
    abbreviation: 'Self Image',
    description: `Self Image`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_SELF_IMAGE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-blame',
    unit: UnitType.Number,
    abbreviation: 'Blame',
    description: `Blame`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_BLAME'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-feelings',
    unit: UnitType.Number,
    abbreviation: 'Feeling',
    description: `Feelings`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_FEELINGS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-interest',
    unit: UnitType.Number,
    abbreviation: 'Interest',
    description: `Interest`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-distant',
    unit: UnitType.Number,
    abbreviation: 'Distant',
    description: `Distant`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_DISTANT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-positive',
    unit: UnitType.Number,
    abbreviation: 'Positive',
    description: `Positive`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_POSITIVE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-irritation',
    unit: UnitType.Number,
    abbreviation: 'Irritation',
    description: `Irritation`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_IRRITATION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-risks',
    unit: UnitType.Number,
    abbreviation: 'Risks',
    description: `Risks`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_RISKS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-alert',
    unit: UnitType.Number,
    abbreviation: 'Alert',
    description: `Alert`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_ALERT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-jumpy',
    unit: UnitType.Number,
    abbreviation: 'Jumpy',
    description: `Jumpy`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_JUMPY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-concentration',
    unit: UnitType.Number,
    abbreviation: 'Concentration',
    description: `Concentration`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_CONCENTRATION'
  }),
    new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-sleep',
    unit: UnitType.Number,
    abbreviation: 'Sleep',
    description: `Sleep`,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_SLEEP'
  }),
  //#endregion
];
