import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { NoctemLibModule } from '@noctem/web';
import { SharedModule } from './../../shared/shared.module';
import { LayoutModule } from '../../layout/layout.module';
import { SleepQualityPipe } from './sleep-quality.pipe';

const routes: Routes = [
    { path: '', component: HomeComponent },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressBarModule,
        FormsModule,
        NoctemLibModule,
        SharedModule,
        LayoutModule
    ],
    declarations: [
        HomeComponent,
        SleepQualityPipe
    ],
    exports: [
        RouterModule
    ],
})
export class HomeModule { }
