export const pcPtsdDef = {
  "name": "pcPtsd5",
  "description": "Primary Care PTSD Screen",
  "instructions": null,
  "questions": [
    {
      "id": 0,
      "questionText": "In the past week, have you:",
      "questionEmphasis":
        "Had nightmares about or thought about upsetting, traumatic, or horrible events when you did not want to?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PCPTSD_NIGHTMARE"
    },
    {
      "id": 1,
      "questionText": "In the past week, have you:",
      "questionEmphasis":
        "Tried hard not to think about the event(s) or went out of your way to avoid situations that reminded you of the event(s)?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PCPTSD_AVOID"
    },
    {
      "id": 2,
      "questionText": "In the past week, have you:",
      "questionEmphasis": "Been constantly on guard, watchful, or easily startled?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PCPTSD_ON_GUARD"
    },
    {
      "id": 3,
      "questionText": "In the past week, have you:",
      "questionEmphasis": "Felt numb or detached from people, activities, or your surroundings?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PCPTSD_NUMB"
    },
    {
      "id": 4,
      "questionText": "In the past week, have you:",
      "questionEmphasis":
        "Felt guilty or unable to stop blaming yourself or others for the event(s) or any problem the event(s) may have caused?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false },
        { "display": "Prefer not to answer", "value": "N/A" }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PCPTSD_BLAME"
    }
  ]
};
