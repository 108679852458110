import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardStateService, UserStateService } from '@noctem/web';
import { Subject } from 'rxjs'; 
import { takeUntil } from 'rxjs/operators';
import { APPLICATION_ORGANIZATION } from 'projects/noctem-lib/src/constants/constants'; 
import { RTMReportService } from 'projects/noctem-lib/src/core/services/RTMReportService';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, AfterViewInit, OnDestroy {
    RTMClinics = [];
    destroy$: Subject<any> = new Subject();
    
    constructor(
        public stateService: DashboardStateService,
        public userStateService: UserStateService,
        public rtmReportService: RTMReportService,
        public router: Router
      ) { 
        userStateService.initialize();
      }

    ngOnInit() {
        this.userStateService.onUserLoaded$.pipe(takeUntil(this.destroy$)).subscribe(userState => {
            this.RTMClinics = userState.User.groups.filter (o => {
                return o.display?.toLowerCase() !== APPLICATION_ORGANIZATION.name?.toLocaleLowerCase()
            });      
            // Note: group ids are prepended by a _ for some users. TODO: investigate.
            this.RTMClinics.map((o) => {
                if ('id' in o && !('_id' in o)) {
                    o['_id'] = o['id'];
                }
            })
        });
    }

    ngAfterViewInit() {
        ;
    }

    ngOnDestroy() {
        ;
    }
    
    getRTMReportWrapper(clinicId: string, clinicName: string, calendarMonthPeriods: number = 1) {
        this.stateService.setIsLoading(true);
        this.rtmReportService.getRTMReport(clinicId, calendarMonthPeriods).subscribe(r => {
            if (r && r.resultsCSVEncoded) {
                const RTMReportResults = `data:text/csv;base64,` + r.resultsCSVEncoded;
                const link = document.createElement("a");
                link.href = RTMReportResults;
                link.download = `RTM_Billing-${clinicName}-L${calendarMonthPeriods}M-${Math.floor(new Date().getTime() / 1000)}.csv`;
                link.click();
                this.stateService.setIsLoading(false);
            } else {
                alert(`Error. Unable to download report.`);
                this.stateService.setIsLoading(false);
            }
        });
    }
}
