import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { relaxation } from '../questions/relaxation/relaxation';

export const relaxationDef: AssetDefinition = {
  id: 22,
  name: 'relaxation',
  label: 'Relaxation',
  description: `Stress is often felt in the body as muscle tension, and releasing this tension can reduce feelings of anxiety. Help your Soldiers learn two relaxation skills: guided muscle relaxation (i.e. tensing and relaxing of muscle groups) and relaxing visualization (i.e. imagining a peaceful scene). An  audio-guided example is included for each skill.`,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    relaxation
  ],
  url: 'relaxation'
};
