import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { dreamRetraining } from '../questions/dream-retraining/dream-retraining';

export const dreamRetrainingDef: AssetDefinition = {
  id: 4,
  name: 'dream-retraining',
  label: 'Dream Retraining',
  description: `Recurrent bad dreams and nightmares make it difficult to fall and stay asleep.
                Help your Soldiers retrain how their dreams unfold by mentally rehearsing desirable dream scenarios during wakeful hours.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    dreamRetraining
  ],
  url: 'dream-retraining'
};
