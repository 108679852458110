import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { pcl5Short } from '../questions/pcl5-short';

export const stressCheckIn: AssetDefinition = {
  id: 14,
  name: 'stress-check-in',
  label: 'Stress Check-In',
  readOnly: false,
  showInLibrary: false,
  categories: [],
  assessments: [pcl5Short],
  url: 'stress-check-in'
};
