import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { fatigueScanning } from '../questions/fatigue-scanning/fatigue-scanning';


export const fatigueScanningDef: AssetDefinition = {
  id: 5,
  name: 'fatigue-scanning',
  label: 'Fatigue Scanning',
  description: `Fatigue decreases performance and readiness. Twenty-four hours without sleep is like being drunk.
                Encourage your Soldiers to scan themselves and their battle buddies for signs of fatigue. When fatigued, get sleep.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    fatigueScanning
  ],
  url: 'fatigue-scanning'
};
