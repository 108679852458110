export const shiftWorkDef = {
  "name": "shiftWork",
  "description": "Shift Work",
  "instructions": null,
  "questions": [
    {
      "id": 0,
      "questionText": "Do you have a work schedule that starts before 07:00 or after 14:00?", // "Do you perform any shift work, including extended overtime or non-standard hours (e.g., start before 07:00 or after 14:00; night shifts?)"
      "questionType": "radio",
      "uniqueAnswerId": "SHIFT_WORK_CURRENT",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      // conditional question will be removed in 1.5
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [true],
          "id": 1,
          "questionText": "On average, how many times per week do you work a non-standard shift?",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "1 to 2 times per week", "value": "1 to 2 times per week" },
            { "display": "3 to 4 times per week", "value": "3 to 4 times per week" },
            { "display": "5 to 7 times per week", "value": "5 to 7 times per week" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_CURRENT_COUNT",
          "conditionalQuestions": []
        },
        {
          "displayIfAnswerEquals": [true],
          "id": 2,
          "questionText": "When did you start working a non-standard shift? ",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "In the past month", "value": "In the past month" },
            { "display": "1 to 6 months ago", "value": "1 to 6 months ago" },
            { "display": "7 to 12 months ago", "value": "7 to 12 months ago" },
            { "display": "1 to 5 years ago", "value": "1 to 5 years ago" },
            { "display": "More than 5 years ago", "value": "More than 5 years ago" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_CURRENT_START",
          "conditionalQuestions": []
        }
      ]
    },
    //questions will be removed in 1.5
    {
      "id": 3,
      "questionText":
        "Do you have a work schedule with a start time that changes over time (i.e., rotating shifts)?",
      "questionType": "radio",
      "uniqueAnswerId": "SHIFT_WORK_CHANGE",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [true],
          "id": 4,
          "questionText": "On average, how many times per week do you work a non-standard shift?",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "1 to 2 times per week", "value": "1 to 2 times per week" },
            { "display": "3 to 4 times per week", "value": "3 to 4 times per week" },
            { "display": "5 to 7 times per week", "value": "5 to 7 times per week" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_CHANGE_COUNT",
          "conditionalQuestions": []
        },
        {
          "displayIfAnswerEquals": [true],
          "id": 5,
          "questionText": "When did you start working a non-standard shift? ",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "In the past month", "value": "In the past month" },
            { "display": "1 to 6 months ago", "value": "1 to 6 months ago" },
            { "display": "7 to 12 months ago", "value": "7 to 12 months ago" },
            { "display": "1 to 5 years ago", "value": "1 to 5 years ago" },
            { "display": "More than 5 years ago", "value": "More than 5 years ago" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_CHANGE_START",
          "conditionalQuestions": []
        }
      ]
    },
    {
      "id": 6,
      "questionText":
        "Do you have a work schedule that includes hours outside the typical 07:00 to 18:00 workday?",
      "questionType": "radio",
      "uniqueAnswerId": "SHIFT_WORK_OUTSIDE_TYPICAL",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [true],
          "id": 7,
          "questionText": "On average, how many times per week do you work a non-standard shift?",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "1 to 2 times per week", "value": "1 to 2 times per week" },
            { "display": "3 to 4 times per week", "value": "3 to 4 times per week" },
            { "display": "5 to 7 times per week", "value": "5 to 7 times per week" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_TYPICAL",
          "conditionalQuestions": []
        },
        {
          "displayIfAnswerEquals": [true],
          "id": 8,
          "questionText": "When did you start working a non-standard shift? ",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "In the past month", "value": "In the past month" },
            { "display": "1 to 6 months ago", "value": "1 to 6 months ago" },
            { "display": "7 to 12 months ago", "value": "7 to 12 months ago" },
            { "display": "1 to 5 years ago", "value": "1 to 5 years ago" },
            { "display": "More than 5 years ago", "value": "More than 5 years ago" }
          ],
          "uniqueAnswerId": "SHIFT_WORK_TYPICAL_START",
          "conditionalQuestions": []
        }
      ]
    }
  ]
};
