import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const morningLog: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'attempt-to-sleep',
    unit: UnitType.EveningTime,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'ATTEMPT_TO_SLEEP'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'was-sleepy',
    unit: UnitType.YesNo,
    abbreviation: 'Was Sleep',
    description: `Was Sleep`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'WAS_SLEEPY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-latency',
    unit: UnitType.Minutes,
    abbreviation: 'Latency',
    description: `Latency`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'MINUTES_TO_SLEEP'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'total-minutes-wokeups',
    unit: UnitType.Minutes,
    abbreviation: 'Total Minutes Wokeups',
    description: `Total Minutes Wokeups`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'TOTAL_MINUTES_WOKEUPS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'wake-time',
    unit: UnitType.MorningTime,
    abbreviation: 'Wake Time',
    description: `Wake Time`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'WAKE_TIME'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'rise-time',
    unit: UnitType.MorningTime,
    abbreviation: 'Rise Time',
    description: `Rise Time`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'RISE_TIME'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-quality',
    unit: UnitType.Number,
    abbreviation: 'SE',
    description: `Sleep Quality`,
    assessmentType: LogDefinitionTypes.MorningLog,
    answerId: 'SLEEP_QUALITY'
  }),
  //#endregion
];
