
import {  USER_STATE_SERVICE } from '../../core/web-ng';
import { Router, CanActivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { UserStateService } from '../state/user-state';
import { find as _find} from 'lodash';
import { Observable } from 'rxjs';
import { AUTH_SERVICE, AuthService } from '../state/user';
import { UserService } from '../../core';
import { NetworkService } from '../../lib/services/network-service';
import { filter, take } from 'rxjs/operators';
import { NoctemUser } from '../services/models';
import { APPLICATION_ORGANIZATION } from '../../constants/constants';
import { ApplicationStateService } from '../state';
import { query } from '@angular/animations';

import { Location } from '@angular/common'; 

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(
      @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
      private applicationStateService: ApplicationStateService,
      private router: Router,
      private location: Location) {
    }

    canActivate(): Observable<boolean> {
        return Observable.create(o => {
            this.userStateService.state$.subscribe(userState => {
                if (userState.IsUserInitialized && !userState.IsLoading) {
                    if (!userState.IsLoggedIn) {
                        this.userStateService.expire();
                        localStorage.clear();
                        const state = this.applicationStateService.stateModel.get();
                        state.UserName = null;
                        this.applicationStateService.stateModel.set(state);

                        const retURL = this.location.path();
                        if (/^\/login/.test(retURL)) {
                            // Do not redirect /login paths
                            ;
                        } else if (retURL) {                        
                            this.router.navigate(['/login'], { queryParams: {next: retURL}});
                        } else {
                            this.router.navigate(['/login']);
                        }
                    }

                    o.next(!!userState.IsLoggedIn);
                    o.complete();
                }
            });
        });
    }
}

@Injectable()
export class OfflineHandlingGuard implements CanActivate {
    constructor(
      @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
      private networkService: NetworkService,
      private router: Router) {
    }


    canActivate(): Observable<boolean> {
        return new Observable(o => {
            o.next(true)
            o.complete();
        })
    }
}


@Injectable()
export class IsAdminGuard implements CanActivate {
    constructor(
      @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
      private applicationStateService:ApplicationStateService,
      private router: Router) {
    }

    canActivate(): Observable<boolean> {

        return Observable.create(o => {
            this.userStateService.state$.subscribe(userState => {

                const user = (userState.User as any as NoctemUser);
                let isAdmin:boolean = false;
                if(user && user.profile && user.profile.emailAddress) {
                    isAdmin = user.profile.emailAddress?.toLowerCase().indexOf("@noctemhealth.com") !== -1;
                }

                if (isAdmin && userState.Organizations) {
                    let organization = userState.Organizations.find(o => o._id !== APPLICATION_ORGANIZATION._id);
                    if (organization && organization['Roles']) {
                        isAdmin = !!organization['Roles'].find(role => role['Name'] === 'Administrator');
                    }
                }

                if(!isAdmin) this.applicationStateService.logout();
                 o.next(isAdmin);
                o.complete();
            });
        });
    }
}


@Injectable()
export class IsLocalLoginGuard implements CanActivate {
    constructor(
      private applicationStateService:ApplicationStateService,
      private router: Router) {
    }

    canActivate(): Observable<boolean> {
        return Observable.create(o => {
            const hasLocalLogin = this.applicationStateService.hasLocalLogin() === true;
            if (!hasLocalLogin) { this.router.navigateByUrl('/'); }
            o.next(hasLocalLogin);
            o.complete();
        })
    }
}
