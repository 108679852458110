import { Component, OnInit, Input } from '@angular/core';
import { LogStateService, Log } from '../../state';
import { Router } from '@angular/router';

@Component({
  selector: 'log-info',
  templateUrl: './log-info.component.html',
  styleUrls: []
})
export class LogInfoComponent implements OnInit {
  @Input() currentLog: Log;
  @Input() logDate: string
  public incompleteLogCount: number;
  public countText: string;
  
  constructor(private logStateService: LogStateService, private router: Router) { }

  ngOnInit() {
    this.incompleteLogCount = this.logStateService.getIncompleteLogCount();
    this.countText = this.incompleteLogCount < 2 ? `${this.incompleteLogCount} EXPIRING LOG` : `${this.incompleteLogCount} EXPIRING LOGS`;
  }

  goToUrl(url: string) {
    this.router.navigateByUrl(url);
  }

}