import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const phq8: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'phq-overall',
    unit: UnitType.Number,
    abbreviation: 'PHQ 8 Overall',
    description: `PHQ 8 Overall`,
    assessmentType: LogDefinitionTypes.PHQ8
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-interest',
    unit: UnitType.Number,
    abbreviation: 'Interest',
    description: `Interest`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-depression',
    unit: UnitType.Number,
    abbreviation: 'Depression',
    description: `Depression`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_DEPRESSION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-sleep',
    unit: UnitType.Number,
    abbreviation: 'Sleep',
    description: `Sleep`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_SLEEP'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-fatigue',
    unit: UnitType.Number,
    abbreviation: 'Fatigue',
    description: `Fatigue`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_FATIGUE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-appetite',
    unit: UnitType.Number,
    abbreviation: 'Appetite',
    description: `Appetite`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_APPETITE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-failure',
    unit: UnitType.Number,
    abbreviation: 'Failure',
    description: `Failure`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_FAILURE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-concentration',
    unit: UnitType.Number,
    abbreviation: 'Concentration',
    description: `Concentration`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_CONCENTRATION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq8-slowly',
    unit: UnitType.Number,
    abbreviation: 'Slowly',
    description: `Slowly`,
    assessmentType: LogDefinitionTypes.PHQ8,
    answerId: 'PHQ_SLOWLY'
  }),

  //#endregion
];
