import { SleepModuleDefinition, Condition, DataSummationType, EqualityType } from "../../../../noctem-lib/src/core/contracts/models.calculations";
export const jetLagAlgorithm = new SleepModuleDefinition({
  name: 'control-jet-lag',
  isMedic: true,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'bed-time',
      valueToCompare: 359, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0,
      additionalCondition:  new Condition({
        summationType: DataSummationType.Average,
        fieldName: 'bed-time',
        valueToCompare: 1201, // in minutes
        operator: EqualityType.LessThan,
        priority: 0
      })
    }),
  ],
});

