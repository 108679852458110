/**
 * Titles for different pages of the clinician portal app. Having different titles is primarily to give google analytics different names for
 * tracking user time spent on each page.
 */
export enum ClinicianPortalTitles {
    HOME = "Noctem Portal - Home",
    SECURE_CHAT = "Noctem Portal - Secure Chat",
    USER_SETTINGS = "Noctem Portal - User Settings",
    CLINICIAN_MANAGEMENT = "Noctem Portal - Clinician Mgmt",
    PATIENT = "Noctem Portal - Patient",
    CALENDAR = "Noctem Portal - Calendar",
    SECURE_CHAT_OPEN = "Noctem Portal - View Patient Chat"
}