import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const phq3: Array<FieldDefinitionBase> =
[
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'phq3-overall',
    unit: UnitType.Number,
    abbreviation: 'phq3-overall',
    description: `PHQ3 Overall`,
    assessmentType: LogDefinitionTypes.PHQ3
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq3-interest',
    unit: UnitType.Number,
    abbreviation: 'Interest',
    description: `Interest`,
    assessmentType: LogDefinitionTypes.PHQ3,
    answerId: 'PHQ3_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq3-depression',
    unit: UnitType.Number,
    abbreviation: 'Depression',
    description: `Depression`,
    assessmentType: LogDefinitionTypes.PHQ3,
    answerId: 'PHQ3_DEPRESSION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq3-si',
    unit: UnitType.Number,
    abbreviation: 'Suicidal Ideation',
    description: `Suicidal Ideation`,
    assessmentType: LogDefinitionTypes.PHQ3,
    answerId: 'PHQ3_SI'
  }),
];
