import { ThresholdIndices } from '../modules/thresholds/threshold-indices';

export const chartOptionsDefs = {
  bar: {
    tooltips: { enabled: false},
    plugins: {
        labels: {
          render: () => {}
        }
    },
    onClick: (e) => { e.stopPropagation(); },
    responsive: true,
    scales:
    {
        yAxes: [
        {
            ticks: {
                fontColor: 'white',  // y axes color
                fontSize: 12,
                callback: (value) => { if (typeof value === 'number' && Number.isInteger(value)) { return value; } },
                beginAtZero:true
              },
              scaleLabel: {
                display: true,
                fontColor: 'white',
                fontSyle:'bold',
                fontSize:14,
                labelString: 'Service Members'

              }
        }],
        xAxes: [
        {

            ticks: {
                fontColor: 'white',  // x axes color
                fontSize: 12
              },
              scaleLabel: {

                display: true,

                fontColor: 'white',
                fontStyle: 'bold'
              }
        }]
    }
  },
  barriers: {
    tooltips: { enabled: false},
    plugins: {
        labels: {
          render: () => {}
        }
    },
    onClick: (e) => { e.stopPropagation(); },
    responsive: true,
    scales:
    {
        yAxes: [
        {
            ticks: {
                fontColor: 'white',  // y axes color
                fontSize: 12,
                callback: (value) => { if (typeof value === 'number' && Number.isInteger(value)) { return value; } },
                beginAtZero:true
              },
              scaleLabel: {
                display: true,
                fontColor: 'white',
                fontSyle:'bold',
                fontSize:14,
                labelString: 'Occurences'
              }
        }],
        xAxes: [
        {

            ticks: {
                fontColor: 'white',  // x axes color
                fontSize: 12
              },
              scaleLabel: {

                display: true,

                fontColor: 'white',
                fontStyle: 'bold'
              }
        }]
    }
  },
  pie: {
    tooltips: { enabled: false},
    plugins: {
        labels: {
          render: () => {}
        }
    },
    onClick: (e) => { e.stopPropagation(); },
    responsive: true,
    scales:
    {
        yAxes: [
        {
            ticks: {
                fontColor: 'white',  // y axes color
                fontSize: 12,
                callback: (value) => { if (typeof value === 'number' && Number.isInteger(value)) { return value; } },
              },
        }],
        xAxes: [
        {

            ticks: {
                fontColor: 'white',  // x axes color
                fontSize: 12
              },
              scaleLabel: {

                display: true,

                fontColor: 'white',
                fontStyle: 'bold'
              }
        }]
    }
  },

  line: {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    scales:
    {
        yAxes: [  // This will put the data values on the right of the graph
        {
            id: "y-axis-right",
            position: 'right',
            ticks:
            {
                //maxTicksLimit: 10,  // If need to restrict number of ticks on y-axis
                //stepSize: 5,  // If need to set y-axis step size
                beginAtZero: true,
                fontColor: 'white',  // Y-axis font color
                callback: function(value) {if (value as number % 1 === 0) {return value;}} // This will allow integer axis values
            },
             scaleLabel: {
                display: false,
            }
        }],
        xAxes: [
        {
            ticks:
            {
                fontColor: 'white'  // X-axis font color
            },
            scaleLabel: {
                display: true,
                labelString: 'In Days',
                fontColor: 'white'
            },
        }]
    },
    annotation: null


},

  radar: {
    responsive: true,

    fill:true,
    scale:
    {
        gridLines:
        {
            color: [ThresholdIndices.RED.color, ThresholdIndices.YELLOW.color, ThresholdIndices.GREEN.color],
            lineWidth: [165, 100, 27]

        },
        angleLines: {
            display: false,
        },
        ticks:
        {
            display: false,
            backdropColor: 'blue', // should render black behind the text,
            max:3,
            min:0,
            beginAtZero:true,
            callback: (value) => { if (typeof value === 'number' && Number.isInteger(value)) { return value; } }
        },
        pointLabels: {
            fontColor: 'rgb(201, 203, 206)',
            fontSize: 13,
            padding:0
        }
    },
    legend: {
        display: false,
    },
  }
};
