<div *ngIf="stateService.state$ | async as state">
    <div mat-dialog-content>
        <div class="modal-header">
            <h3 class="modal-title">Add Check-in</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-9">
                    <div class="row breathe">
                        <div class="col-6">
                            <input type="date" placeholder="Date" [value]="checkInDate" [(ngModel)]="checkInDate"
                                name="checkInDate">
                        </div>
                    </div>
                    <div class="row breathe">
                        <div class="col-12">
                            <input type="text" placeholder="Notes" [(ngModel)]="checkInNotes">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div mat-dialog-actions>
            <button mat-button class="btn btn-secondary" (click)="onNoClick()">Close</button>
            <button mat-button class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
    </div>
</div>