import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { FooterComponent } from './footer/footer.component';
import { ManagePatientDialog } from '../routes/patient/patient-page/dialog/manage-patient-dialog/manage-patient-dialog.component'
import { SharedModule } from '../shared/shared.module';
import { PatientDialogComponent } from '../routes/patient/patient-page/dialog/patient-dialog/patient-dialog.component';
import { TermsDialog } from '../routes/patient/patient-page/dialog/terms-dialog/terms-dialog.component';
import { ConsentDialog } from '../routes/patient/patient-page/dialog/consent-dialog/consent-dialog.component';
import { AddCheckinDialog } from '../routes/patient/patient-page/dialog/add-check-in-dialog/add-check-in-dialog.component';
import { BugReportDialogComponent } from '../routes/patient/patient-page/dialog/bug-report-dialog/bug-report-dialog.component'

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent,
        PatientDialogComponent,
        ManagePatientDialog,
        TermsDialog,
        ConsentDialog,
      AddCheckinDialog,
      BugReportDialogComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent,
        PatientDialogComponent,
        TermsDialog,
        ConsentDialog,
        AddCheckinDialog
    ],
    entryComponents: [
        ManagePatientDialog,
        TermsDialog,
        ConsentDialog,
      AddCheckinDialog,
      BugReportDialogComponent
    ],
})
export class LayoutModule { }