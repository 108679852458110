import TYPES, { IApplicationContext } from './contracts/models';

export class ApiHelper {
    private appContext: IApplicationContext;
    private apiVersion = '/v1';

    constructor(applicationContext: IApplicationContext) {
        this.appContext = applicationContext;
    }

    public RootUrl() {
        if (this.appContext.AppHost) {
            return this.appContext.AppHost
        } else {
            const host = this.appContext.Host;
            // if the host starts with / like /api, don't prefix with 'http'
            const prefix = host.startsWith('/') ? '' : 'http://';
            return prefix + this.appContext.Host;
        }
    }

    public urlPrefix() {
        return this.appContext.AppHost ? '' : '/api'
    }

    public InstanceUrl(className: string, id?: string): string {
        let url = `${this.RootUrl()}/instance/` + className;
        if (id != null) {
            url = url + '/' + id;
        }
        return url;
    }

    public FactoryUrl(className: string): string {
        const url = this.RootUrl() + 'e/factory/' + className;
        return url;
    }

    public LockerUploadUrl(fileId?: string): string {
        const url = fileId ? this.RootUrl() + 'e/locker/upload?fileId=' + fileId : this.RootUrl() + 'e/locker/upload';
        return url;
    }

    public LockerUrl(fileId: string): string {
        const url = this.RootUrl() + 'e/locker/id/' + fileId;
        return url;
    }

    public ValidateUrl(className: string): string {
        const url = `${this.RootUrl()}/instance/validate/${className}`;
        return url;
    }

    public MessageUrl(): string {
        const url = this.RootUrl() + 'messaging';
        return url;
    }

    public RegisterUrl(): string {
        const url = this.RootUrl() + 'account/register';
        return url;
    }

    public AclUrl(modelName: string, id: string): string {
        return this.RootUrl() + 'e/api/' + modelName + '/' + id + '/acl';
    }

    public SequenceUrl(modelName: string): string {
        return `${this.RootUrl()}/instance/sequence/${modelName}`;
    }

    public FunctionUrl(modelName: string, functionName: string): string {
        return `${this.RootUrl()}` + modelName + '/' + functionName;
    }

    public Headers(): any {
        const headers = {
            Authorization: `${this.appContext.Token}`,
            // "content-type": "application/json"
        };

        return headers;
    }


}

export class Utility {
    public Serialize(obj, prefix?): string {
        let str = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
                str.push((v !== null && typeof v === 'object') ? this.Serialize(v, k)
                    : encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
        return str.join('&');
    }
}
