import { SocketIoConfig } from 'ngx-socket-io';

export const socketConfig: SocketIoConfig = {
    url: '/', options: {
      path: '/noctem-socket',
      transports: ['websocket', 'polling'],
      secure: true,
      autoConnect : false
    }
  };
