import { map } from 'rxjs/operators';
import { Translations, TranslationService } from '../translations';
import { Observable, Subject } from 'rxjs';
import { Model, ModelFactory } from '../../core/web-ng';

export class BaseStateService<T> {
  protected t: Translations;
  public stateModel: Model<T>;
  public state$: Observable<T>;
  public onChange: Subject<T>;

  constructor(
    stateType: T,
    private stateFactory: ModelFactory<T>,
    protected translationsService: TranslationService
  ) {
    this.stateModel = stateFactory.create(stateType);
    this.state$ = this.stateModel.data$.pipe(map(items => items));
    if (translationsService) {
      this.t = translationsService.get();
    }

    this.onChange = new Subject<T>();
  }

  protected getState(state?: T): T {
    return state || this.stateModel.get();
  }

  protected setState(state: T): T {
    this.stateModel.set(state);
    this.onChange.next(state);
    return state;
  }

  public patchState(partialState: Partial<T>, state?: T): T {
    state = this.getState(state);
    const patchedState = Object.assign(state, partialState);
    return this.setState(patchedState);
  }
}
