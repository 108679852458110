<fieldset class="form-group question">
    <div class="form-group">
        <select class="form-control no-border round-select" [(ngModel)]="selectedValue" (change)="valueUpdated()">
            <option *ngFor="let value of selectableValues" [ngValue]="value.value">
                {{value.label}}
            </option>
        </select>
    </div>
    <checkbox-question [question]="question" [previousValue]="previousValue" *ngIf="question.answerOptions"
        (answerUpdated)="checkboxValueUpdated($event)" (otherAnswerUpdated)="otherInputUpdated($event)">
    </checkbox-question>
</fieldset>