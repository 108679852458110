import * as DefaultClinicianEULA from './clinician-EULAs/default';
import * as DefaultClinicianConsent from './clinician-consents/default';
import * as DefaultPatientConsent from './patient-consents/default';
import * as NHRCClinianConsent from './clinician-consents/NHRC';
import * as NHRCPatientConsent from './patient-consents/NHRC';
import * as PAAPatientConsent from './patient-consents/PopeAirField';
import * as MAMCPatientConsent from './patient-consents/MAMC';
import * as WHASCPatientConsent from './patient-consents/WHASC';
import * as CRDAMCPatientConsent from './patient-consents/CRDAMC';
import * as RTMPatientConsent from './patient-consents/RTM';
import * as RTMIntermountainPatientConsent from './patient-consents/RTMIntermountain';
import * as DefaultPatientEULA from './patient-EULAs/default';




export const userAgreements:any = {
  PatientEULAs: [
      DefaultPatientEULA.default],
  ClinicianConsents: [
      DefaultClinicianConsent.default,
      NHRCClinianConsent.default],
  PatientConsents: [
      DefaultPatientConsent.default,
      NHRCPatientConsent.default,
      PAAPatientConsent.default, 
      MAMCPatientConsent.default,
      WHASCPatientConsent.default,
      CRDAMCPatientConsent.default,
      RTMPatientConsent.default,
      RTMIntermountainPatientConsent.default
    ],
  ClinicianEULAs: [
      DefaultClinicianEULA.default]
}

