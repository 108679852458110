import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { gaugeSleepHealth } from '../questions/gauge-sleep-health/gauge-sleep-health';


export const gaugeSleepHealthDef: AssetDefinition = {
  id: 6,
  name: 'gauge-sleep-health',
  label: 'Gauge Sleep Health',
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    gaugeSleepHealth
  ],
  url: 'gauge-sleep-health'
};
