import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { sleepAreaSafeguarding } from '../questions/sleep-area-safeguarding/sleep-area-safeguarding';

export const sleepAreaSafeguardingDef: AssetDefinition = {
  id: 9,
  name: 'sleep-area-safeguarding',
  label: 'Sleep Area Safeguarding',
  categories: ['sleep-help'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    sleepAreaSafeguarding
  ],
  url: 'sleep-area-safeguarding'
};
