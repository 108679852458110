import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const essWeekly: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'ess-weekly-overall',
    unit: UnitType.Number,
    abbreviation: 'ESS Weekly Overall',
    description: `ESS Weekly Overall`,
    assessmentType: LogDefinitionTypes.ESSWeekly
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-read',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_READ'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-tv',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_TV'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-public',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_PUBLIC'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-car',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_CAR'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-afternoon',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_AFTERNOON'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-talk',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_TALK'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-lunch-no-alcohol',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_LUNCH_NO_ALOCHOL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'essw-sleep-traffic',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.ESSWeekly,
    answerId: 'ESSW_SLEEP_TRAFFIC'
  }),
  //#endregion
];
