import { NgModule } from '@angular/core';
import { NoctemLibComponent } from './noctem-lib.component';
import { InboxDialogComponent } from './components/dialogs/inbox-dialog/inbox-dialog.component';
import { EventDialogComponent } from './components/dialogs/event-dialog/event-dialog.component';
import { ApiService } from './services';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddEditEventDialog } from './components/dialogs/add-edit-event/add-edit-event-dialog.component';
import { ThreadMessageDialog } from './components/dialogs/thread-message-dialog/thread-message-dialog.component';
import { LogInfoComponent } from './components/log-info/log-info.component';
import { LogQuestionComponent } from './components/log-question/log-question.component';
import { LogNavigationComponent } from './components/log-navigation/log-navigation.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { RadioGroupQuestionComponent } from './components/radio-group-question/radio-group-question.component';
import { CheckboxQuestionComponent } from './components/checkbox-question/checkbox-question.component';
import { SliderQuestionComponent } from './components/slider-question/slider-question.component';
import { ChatComponent } from './components/chat/chat.component';
import { AlertDisplayComponent } from './components/alert-display/alert-display.component';
import { WheelSelectorModule } from '@hyperblob/ngx-wheel-selector';
import { TimeInputModalComponent } from './components/time-input-modal/time-input-modal.component';
import { WheelSelectorComponent } from './components/wheel-selector/wheel-selector.component';
import { TextInputComponent } from './components/web-inputs';
import { SelectInputComponent } from './components/web-inputs/select-input/select-input.component';
import { WheelSelectorIndexService } from './services/wheel-selector-index-service';
import { CheckInQuestionComponent } from './components/check-in-question/check-in-question.component';
import { CarouselSlideComponent } from './components/carousel/carousel-slide/carousel-slide.component';
import { CarouselContainerComponent } from './components/carousel/carousel-container/carousel-container.component';
import { ChartsComponent } from './components/charts/charts.component';
import { AccordionContainerComponent } from './components/charts/accordion-container/accordion-container.component';
import { ChartsModule } from 'ng2-charts';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MatSliderModule } from '@angular/material/slider';
import { ModuleCardComponent } from './components/module-card/module-card.component';
import { ColumnQuestionComponent } from './components/column-question/column-question.component';
import { NocTimepickerComponent } from './components/noc-timepicker/noc-timepicker.component';
import { DropdownQuestionComponent } from './components/dropdown-question/dropdown-question.component';
import { LogCacheServiceInterface } from './state/log-cache-service.interface';

const sharedComponents = [
  NoctemLibComponent,
  InboxDialogComponent,
  EventDialogComponent,
  AddEditEventDialog,
  ThreadMessageDialog,
  ChatComponent,
  LogInfoComponent,
  LogQuestionComponent,
  CheckInQuestionComponent,
  ChartsComponent,
  AccordionContainerComponent,
  CarouselSlideComponent,
  CarouselContainerComponent,
  ModuleCardComponent,
  LogNavigationComponent,
  LoadingIndicatorComponent,
  AlertDisplayComponent,
  TimeInputModalComponent,
  TextInputComponent,
  SelectInputComponent,
  ColumnQuestionComponent,
  NocTimepickerComponent
];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    SwiperModule,
    WheelSelectorModule,
    MatSliderModule
  ],
  declarations: [
    sharedComponents,
    LoadingIndicatorComponent,
    RadioGroupQuestionComponent,
    CheckboxQuestionComponent,
    DropdownQuestionComponent,
    SliderQuestionComponent,
    WheelSelectorComponent
  ],
  exports: [sharedComponents, MatSliderModule],
  providers: [ApiService, WheelSelectorIndexService],
  entryComponents: [AddEditEventDialog, ThreadMessageDialog]
})
export class NoctemLibModule {}
