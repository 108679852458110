const phaseOrder = {
    'Follow Up ': 3000,
    'Post': 2000,
    'Week ': 1000,
    'Baseline': 0
};

const statusOrder = {
    'NOT_DUE': 2,
    'UPCOMING_DUE': 1,
    'PAST_DUE': 0
};

export const sortStatus = (status): number => {
    if (!status) {
        return 4000;
    } else if (status !== 'Post' && status !== 'Baseline') {
        const [ phaseNumber ] = status.match(/\d+$/g); // get number from end of phase name
        const index = status.indexOf(phaseNumber);
        const phaseType = status.slice(0,index); // get phase name without number
        const phaseOrderValue = phaseOrder[phaseType] // map phase type to order defining number
        return phaseOrderValue + +phaseNumber; // sort within order based on number at end of string
    } else {
        return phaseOrder[status];
    }
}

export const sortSleepPrescription = ({dueDate, status}, direction, name): string => {
    let order = statusOrder[status];
    if (direction === 'desc' && status === 'NOT_DUE') { // always sort NOT_DUE to bottom
        order = order * -1;
    }
    if (status === 'UPCOMING_DUE') { // values not 'Past Due' or '-' are sorted by due date
        const date = new Date(dueDate);
        const dateString = date.toISOString().substring(0, 10)
        order = `${order}${dateString}`;
    }
    return `${order}${name.toLowerCase()}`; // sort first by order string then by name
}
