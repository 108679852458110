import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const unwindBeforeBed: AssessmentDefinition = {
  name: 'unwind-before-bed',
  description: 'Unwind Before Bed',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Unwind Before Bed',
      questionEmphasis: ``,
      subText: `<p>It can be difficult to make the transition from being vigilant during wakeful hours to sleep-ready when the opportunity presents.</p>
                <p>Creating a wind down routine to do before bed can support decreased brain and body activation when an opportunity for sleep becomes available.</p>
                <p><img src='./assets/img/module-images/strategic-napping/sleeping-soldier.png'/></p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `
                <h5><b>Create your own wind down routine</b></h5>
                <p>Select at least two activities you will consistently do before each attempted sleep period.</p>
      `,
      questionType: 'checkbox',

      uniqueAnswerId: 'UNWIND_ACTIVITIES_SELECT',
      autofill: false,
      isCheckInQuestion: false,
      answerOptions: [
        {
          display: 'Practice Guided Muscle Relaxation',
          value: 0, isChecked: false
        },
        {
          display: 'Practice Relaxing Visualization',
          value: 1, isChecked: false
        },
        {
          display: 'Listen to music or a pre-sleep playlist',
          value: 2, isChecked: false
        },
        {
          display: 'Do some light stretches',
          value: 3, isChecked: false
        },
        {
          display: 'Write in a journal',
          value: 4, isChecked: false
        },
        {
          display: 'Read a book or magazine',
          value: 5, isChecked: false
        },
        {
          display: 'Do a puzzle (Soduku, Crossword)',
          value: 6, isChecked: false
        },
        {
          display: 'Brush your teeth',
          value: 7, isChecked: false
        },
      ],
      conditionalQuestions: [
        {
          id: 0,
          questionText: 'Or write an activity you will consistently do before each attempted sleep period.',
          questionType: 'textarea',
          uniqueAnswerId: 'UNWIND_ACTIVITY',
          autofill: true,
          answerOptions: null,
          isCheckInQuestion: false,
          conditionalQuestions: []
        },
      ]
    },
    {
      id: 2,
      questionText: 'HEY!  Keep your bed/sleep area for sleep only!',
      questionEmphasis: ``,
      subText: `<p>It is best if your wind down routine occurs outside of your bed or sleeping area.</p>

                <p>If that is not possible, consider doing your routine sitting up in bed and reserve lying down for sleeping only.</p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
