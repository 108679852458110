import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditEventDialog } from '../add-edit-event/add-edit-event-dialog.component';
import { CalendarStateService } from '../../../state';

@Component({
  selector: 'calendar-event-dialog',
  templateUrl: './event-dialog.component.html',
})
export class EventDialogComponent implements OnInit {
  @Input() modalType;

  constructor(
    public dialog: MatDialog,
    public stateService: CalendarStateService
    ) {}

  ngOnInit() {
  }

  public openNewDialog() {
    const state = this.stateService.stateModel.get();
    const dialogRef = this.dialog.open(AddEditEventDialog, {
      width: '750px',
      data: {calEvent: {}, isEdit: false }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
