import { Injectable, Inject } from '@angular/core';

const USER_STATE_KEY = "BASE_USER_STATE_KEY";

export class CacheService {

    constructor(private cacheEnabled: boolean = false) {}

    public set(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public get(key: string): any {
        let stringifiedObject = localStorage.getItem(key);
        try {
            return stringifiedObject ? JSON.parse(stringifiedObject) : null;
        } catch (e) {
            return null;
        }
    }
    
    public delete(key: string): void {
        localStorage.removeItem(key);
    }

    public hasLoadedUser(): boolean {
        return !!localStorage.getItem(USER_STATE_KEY);
    }

    public shouldCache() {
        return this.cacheEnabled;
    }
}