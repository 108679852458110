import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const prioritizeThoughtsAlgorithm = new SleepModuleDefinition({
  name: 'prioritize-thoughts',
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-irritable',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-worry',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-worry-too-much',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-relaxing',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-restless',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-afraid',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
  ],
  content: new Array<ModuleContentPage>(),
});
