import { AssessmentDefinition } from "../../../../../noctem-lib/src/core/contracts/models.calculations";

export const trainingFeedbackSurvey: AssessmentDefinition = {
  name: 'training-feedback-survey',
  description: 'Training feedback survey',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Please rate your agreement with the following statements:',
      questionEmphasis: 'This training session was relevant for Soldiers in my unit.',
      questionType: 'radio',
      uniqueAnswerId: 'POST-TOOL-RELEVANT',
      autofill: false,
      answerOptions: [
        { display: 'Strongly disagree', value: 0 },
        { display: 'Disagree', value: 1 },
        { display: 'Neither agree nor disagree', value: 2 },
        { display: 'Agree', value: 3 },
        { display: 'Strongly agree', value: 4 },
        //{ display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    },
    {
        id: 1,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I found this training session to be useful.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-USEFUL',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 2,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'This training session used good case examples.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-EXAMPLE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 3,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I understood the information in this training session.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-UNDERSTOOD',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 4,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'Viewing and discussing the AIRE tool demonstrations helped me learn the training concepts.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-DEMONSTRATE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 5,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I learned specific actions in this training session that I can take to manage my stress and anxiety.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MANAGE_STRESS',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 6,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I learned specific actions in this training session that I can take to manage issues that arise between team members.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-ARISE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 7,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I learned specific actions in this training session that I can take to manage my sleep and fatigue.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-FATIGUE',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 8,
        questionText: 'Please rate your agreement with the following statements:',
        questionEmphasis: 'I learned ways I can maximize my performance in a deployed environment.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-DEPLOYED',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 9,
        questionText: 'How much time is needed for this training?',
        questionEmphasis: null,
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-TIME-NEEDED',
        autofill: false,
        answerOptions: [
          { display: 'Less time', value: 0 },
          { display: 'Same amount of time', value: 1 },
          { display: 'More time', value: 2 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 10,
        questionText: 'The following questions pertains to the training you just received:',
        questionEmphasis: 'How helpful did you find the training?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-HELPFUL-TRAINING',
        autofill: false,
        answerOptions: [
          { display: 'Not at all', value: 0 },
          { display: 'A little', value: 1 },
          { display: 'Somewhat', value: 2 },
          { display: 'Very much', value: 3 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 11,
        questionText: 'The following questions pertains to the training you just received:',
        questionEmphasis: 'How helpful do you think the AIRE tool will be for you?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-HELPFUL-TOOL',
        autofill: false,
        answerOptions: [
          { display: 'Not at all', value: 0 },
          { display: 'A little', value: 1 },
          { display: 'Somewhat', value: 2 },
          { display: 'Very much', value: 3 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 12,
        questionText: 'The following questions pertains to the training you just received:',
        questionEmphasis: 'How helpful is the training for Soldiers that are deploying with limited behavioral health resources?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-HEALTH-RESOURCES',
        autofill: false,
        answerOptions: [
          { display: 'Not at all', value: 0 },
          { display: 'A little', value: 1 },
          { display: 'Somewhat', value: 2 },
          { display: 'Very much', value: 3 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 13,
        questionText: 'The following questions pertains to the training you just received:',
        questionEmphasis: 'How helpful is the AIRE tool for Soldiers that are deploying with limited behavioral health resources?',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-LIMITED-HEALTH-RESOURCES',
        autofill: false,
        answerOptions: [
          { display: 'Not at all', value: 0 },
          { display: 'A little', value: 1 },
          { display: 'Somewhat', value: 2 },
          { display: 'Very much', value: 3 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 14,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements:',
        questionEmphasis: 'Behavioral health apps are only for people that have serious problems.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-SERIOUS-PROBLEMS',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neutral', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id:118,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements:',
        questionEmphasis: 'I think that it is helpful to use an app to manage behavioral health problems.',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-MANAGE-BEHAVIOR',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neutral', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 16,
        questionText: 'Please rate how much you DISAGREE or AGREE with the following statements:',
        questionEmphasis: 'I prefer to handle my problems myself as opposed to seek mental health treatment',
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-SEEK-TREATMENT',
        autofill: false,
        answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neutral', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 17,
        questionText: 'Please select the strategies that are helpful when experiencing an acute stress reaction (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-EXPERIENCE-ACUTE-STRESS',
        autofill: false,
        answerOptions: [
          { display: 'Practice grounding by bringing your awareness to things you can see, feel and hear.', value: 0 },
          { display: 'Focus on the most important task at the moment.', value: 1 },
          { display: 'Acknowledge distracting thoughts but decide to address them at another time.', value: 2 },
          { display: 'Leave the stressful situation and never put yourself in a similar situation again.', value: 3 },
          { display: 'Find a safe place, if possible, and check in with a buddy about how you are feeling.', value: 4 },
          //{ display: 'Prefer not to answer', value: 5 }
        ],
        conditionalQuestions: []
    },
    {
        id: 18,
        questionText: 'Please select the strategies that are helpful when experiencing problematic anxiety (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-PROBLEMATIC-ANXIETY',
        autofill: false,
        answerOptions: [
          { display: 'Practice deliberate deep breathing.', value: 0 },
          { display: 'Identify and replace problematic thoughts.', value: 1 },
          { display: 'Practice visualizing a successful performance of a relevant task.', value: 2 },
          { display: 'Systematically tense and relax different muscle groups, paying attention to the sensations.', value: 3 },
          { display: 'Focus on negative thoughts until they go away.', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 19,
        questionText: 'Please select the strategies that are helpful when experiencing a sleep disturbance (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-SLEEP-DISTURBANCE',
        autofill: false,
        answerOptions: [
          { display: 'Using caffeine strategically and not 6 hours before bedtime', value: 0 },
          { display: 'Napping briefly before your shift', value: 1 },
          { display: 'Limiting light exposure, caffeine, nicotine or exercise before sleeping', value: 2 },
          { display: 'Prepare for a period with reduced sleep by getting extra hours of sleep every day', value: 3 },
          { display: 'Prepare for a period with reduced sleep by limiting your sleep each day so your body gets used to it', value: 4 },
          { display: 'Creating and following a sleep routine to decompress before falling asleep', value: 5 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 20,
        questionText: 'Please select the strategies that are helpful when experiencing dysfunctional team communication (click all that apply):',
        questionEmphasis: null,
        questionType: 'checkbox',
        uniqueAnswerId: 'POST-TOOL-HELPFUL-STRATEGIES',
        autofill: false,
        answerOptions: [
          { display: 'Communicate all relevant information concisely with easily understood terms and concepts.', value: 0 },
          { display: 'Use “I Statements” to express your thoughts, feelings and concerns.', value: 1 },
          { display: 'Always communicate by yelling as loud as possible.', value: 2 },
          { display: 'Describe the situation that is creating conflict in a calm manner.', value: 3 },
          { display: 'Actively listen when others are speaking and give appropriate feedback.', value: 4 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 21,
        questionText: 'Would you recommend the AIRE tool and/or the training to other Soldiers?',
        questionEmphasis: null,
        questionType: 'radio',
        uniqueAnswerId: 'POST-TOOL-RECOMMEND',
        autofill: false,
        answerOptions: [
          { display: 'Yes, the AIRE tool only', value: 0 },
          { display: 'Yes, the training only', value: 1 },
          { display: 'Yes, the AIRE tool and training', value: 2 },
          { display: 'No', value: 3 },
          //{ display: 'Prefer not to answer', value: 'N/A' }
        ],
        conditionalQuestions: []
    },
    {
        id: 22,
        questionText: 'Please list what you liked about the training you just received:',
        questionEmphasis: null,
        questionType: 'textarea',
        uniqueAnswerId: 'POST-TOOL-LIST-LIKES',
        autofill: false,
        answerOptions: [
        { display: 'Prefer not to answer', value: 0 },
        ],
        conditionalQuestions: [],
        showPreferNotToAnswer: false
    },
    {
        id: 23,
        questionText: 'Please list what you think should be improved about the training you just received:',
        questionEmphasis: null,
        questionType: 'textarea',
        uniqueAnswerId: 'POST-TOOL-LIST-IMPROVEMENTS',
        autofill: false,
        showPreferNotToAnswer: false,
        conditionalQuestions: [],
        answerOptions:[
          { display: 'Prefer Not To Answer', value:'N/A'}
        ]
    },
    {
      id: 16,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I think I would like to use the AIRE tool frequently.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_FREQUENT_USE',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 17,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I found the AIRE tool unnecessarily complex.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_UNNECESSARY',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 18,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I thought the AIRE tool was easy to use.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_EASY_USE',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 19,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I think that I would need the support of a technical person to be able to use the AIRE tool',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_TECH_PERSON',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 20,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I found the various functions in the AIRE tool well integrated.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_INTEGRATED',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 21,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I thought there was too much inconsistency in the AIRE tool.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_INCONSISTENCY',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 22,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I would imagine that most people would learn to use the AIRE tool very quickly.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_LEARN_QUICKLY',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 23,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I found the AIRE tool very cumbersome to use.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_CUMBERSOME',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 24,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I felt very confident using the AIRE tool.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_CONFIDENT_USE',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 25,
      questionText: 'For each of the following statements below, mark one response that best describes your reactions to the AIRE tool today.',
      questionEmphasis: 'I needed to learn a lot of things before I could get going with the AIRE tool.',
      questionType: 'radio',
      uniqueAnswerId: 'POST_TRAIN_SOLDIER_LEARN_LOTS',
      autofill: false,
      answerOptions: [
          { display: 'Strongly disagree', value: 0 },
          { display: 'Disagree', value: 1 },
          { display: 'Neither agree nor disagree', value: 2 },
          { display: 'Agree', value: 3 },
          { display: 'Strongly agree', value: 4 },
          { display: 'Prefer not to answer', value: 'N/A' },
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
