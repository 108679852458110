import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, Injectable, Inject } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GtagModule } from 'angular-gtag';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { useModelFactory, ANON_TOKEN, CLIENT_ID, ApplicationContext, ModelFactory, CacheService } from '@noctem/web';

import { CommonModule } from '@angular/common';
import { SimpleHttpInterceptor } from './core/httpInterceptor';
import { PagesModule } from './routes/pages/pages.module';
import { environment } from '../environments/environment';
import { NoctemLibModule, socketConfig } from '@noctem/web';
import { HomeModule } from './routes/home/home.module';
import { SocketIoModule, Socket } from 'ngx-socket-io';
import { LogCacheService } from './logs/log-cache-service';
import { LogCacheServiceInterface } from '../../../../noctem-lib/src/lib/state/log-cache-service.interface';

import { ReportingModule } from './routes/reporting/reporting.module';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getCacheService(appContext: ApplicationContext) { return new CacheService(appContext); }


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        GtagModule.forRoot({ trackingId: 'G-GTPGRZ5W4B', trackPageviews: false }),
        BrowserAnimationsModule, // required for ng2-tag-input
        CommonModule,
        LayoutModule,
        PagesModule,
        CoreModule.forRoot(),
        SharedModule.forRoot(),
        RoutesModule,
        NoctemLibModule,
        HomeModule,
        ReportingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SocketIoModule.forRoot(socketConfig),

    ],
    providers: [
        [{
            provide: ModelFactory,
            useFactory: useModelFactory
        }],
        LogCacheService,
        ApplicationContext,
        CacheService,
        { provide: LogCacheServiceInterface, useClass: LogCacheService },
        { provide: CLIENT_ID, useValue: environment.organization },
        { provide: ANON_TOKEN, useValue: environment.token },
        { provide: HTTP_INTERCEPTORS, useClass: SimpleHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
