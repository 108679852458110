<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation" *ngIf="applicationStateService.state$ | async as appState">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" routerLink="/">
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logos/noctem-light-bg.svg" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
      <li class="nav-item">
          <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
          <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
              <em class="fas fa-bars"></em>
          </a>
      </li>
        <!-- START lock screen-->
        <li *ngIf="false" class="nav-item d-none d-md-block">
            <a class="nav-link"  title="Lock screen" [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li>
        <!-- END lock screen-->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row">
        <!--li class="nav-item patientAddIcon">
            <patient-dialog modalType="consent"></patient-dialog>
        </li-->
       
        <li class="nav-item patientAddIcon">
            <patient-dialog modalType="manage-patient"></patient-dialog>
        </li>
        <!-- Fullscreen (only desktops)-->
        <li *ngIf="false" class="nav-item d-none d-md-block">
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li   class="nav-item d-none d-md-block">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <li  class="nav-item dropdown dropdown-list" dropdown>
                <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" 
                    gtagEvent trackOn="click" 
                    category="custom_events"
                    dropdownToggle>
                    <i class="fas fa-bars"></i>
                </a>
            <!-- START Dropdown menu-->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right fade-in">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                       <!-- list item-->
                        <div class="list-group-item list-group-item-action" (click)="openManual()">
                            <div class="media">
                            <div class="align-self-start mr-2">
                                <em class="fa-2x mr-2 fas fa-book-medical"></em>
                            </div>
                            <div class="media-body align-self-center">
                                <p class="m-0">COAST Manual</p>
                            </div>
                            </div>
                        </div>
                        <div class="list-group-item list-group-item-action" (click)="goToConsent()" *ngIf="isResearch || isConsentRequired">
                            <div class="media">
                            <div class="align-self-start mr-2">
                                <em class="fa-2x mr-2 fas fa-book-reader"></em>
                            </div>
                            <div class="media-body align-self-center">
                                <p class="m-0">Consent</p>
                            </div>
                            </div>
                        </div>
                        <div class="list-group-item list-group-item-action" (click)="openTermsDialog()">
                            <div class="media">
                            <div class="align-self-start mr-2">
                                 <em class="fa-2x mr-2 fas fa-file-signature"></em>
                            </div>
                            <div class="media-body align-self-center">
                                <p class="m-0">EULA</p>
                            </div>
                            </div>
                        </div>
                       <div class="list-group-item list-group-item-action" (click)="goToChangePassword()" *ngIf="applicationStateService.hasLocalLogin()">
                            <div class="media">
                               <div class="align-self-start mr-2">
                                  <em class="fa-2x mr-2 fas fa-exchange-alt"></em>
                               </div>
                               <div class="media-body align-self-center">
                                  <p class="m-0">Change Password</p>
                               </div>
                            </div>
                         </div>
                      <div class="list-group-item list-group-item-action" (click)="openBugReportDialog()">
                        <div class="media">
                          <div class="align-self-start mr-2">
                            <em class="fa-2x mr-2 fas fa-bug"></em>
                          </div>
                          <div class="media-body align-self-center">
                            <p class="m-0">Report a Bug</p>
                          </div>
                        </div>
                      </div>
                       <div class="list-group-item list-group-item-action" (click)="applicationStateService.logout()">
                          <div class="media">
                             <div class="align-self-start mr-2">
                                <em class="fa-2x mr-2 fas fa-sign-out-alt"></em>
                             </div>
                             <div class="media-body align-self-center">
                                <p class="m-0">Logout</p>
                             </div>
                          </div>
                       </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>

        <li>
            <span class="badge badge-warning" *ngIf="isOffHours" rel="tooltip" title="Out-Of-Office Auto-Reply Is Active">off-hours</span>
        </li>
      
        <!-- START Offsidebar button-->
        <li *ngIf="false" class="nav-item">
            <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
                <em class="icon-notebook"></em>
            </a>
        </li>
        <!-- END Offsidebar menu-->
    </ul>
    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
<!-- END Top Navbar-->
