<div class="row" *ngIf="logStateService.state$ | async as logState">
    <div class="col">
        <div class="form-group">
            <div class="question-text" *ngIf="showLabels" [innerHtml]="question.questionText"></div>
            <div class="center-align" *ngIf="showLabels">
                <span class="question-emphasis" *ngIf="question.questionEmphasis"
                    [innerHtml]="question.questionEmphasis"></span>
            </div>
            <div class="question-subtext" [innerHtml]="subText" *ngIf="question.subText && showLabels"></div>

            <div *ngIf="question.questionType === 'text'">
                <input type="text" class="form-control" [(ngModel)]="answerValue" (keyup)="valueUpdated($event)"
                    (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
                <div class="center-align" *ngIf="question.showNotApplicableHint">
                    (answer "NA" if Not Applicable)
                </div>
                <div class="center-align" *ngIf="question.showPreferNotToAnswer">
                    <checkbox-question [question]="question" [previousValue]="answerValue"
                        *ngIf="question.answerOptions" (answerUpdated)="preferNotAnswerUpdated($event)"
                        (otherAnswerUpdated)="otherInputUpdated($event)">
                    </checkbox-question>
                </div>
            </div>
            <div *ngIf="question.questionType === 'textarea'">
                <textarea rows="6" [(ngModel)]="answerValue" (keydown)="valueUpdated($event)"
                    (focus)="controlFocused($event)" (blur)="controlBlurred($event)"></textarea>
                <div class="center-align" *ngIf="question.showNotApplicableHint">
                    (answer "NA" if Not Applicable)
                </div>
                <div class="center-align" *ngIf="question.showPreferNotToAnswer">
                    <checkbox-question [question]="question" [previousValue]="answerValue"
                        *ngIf="question.answerOptions" (answerUpdated)="preferNotAnswerUpdated($event)"
                        (otherAnswerUpdated)="otherInputUpdated($event)">
                    </checkbox-question>
                </div>
            </div>
            <div *ngIf="question.questionType === 'select'" class="form-group select">
                <select id="inputState" class="form-control" [(ngModel)]="answerValue">
                    <option value="select" disabled></option>
                    <option *ngFor="let option of question.answerOptions" [value]="option.value">
                        {{ option.display }}
                    </option>
                </select>
                <div class="select_arrow"></div>
            </div>

            <input *ngIf="question.questionType === 'number'" type="number" pattern="[0-9]*"
                (keypress)="valueValidated($event, question.validation?.maxLength)" class="form-control"
                [(ngModel)]="answerValue" (keyup)="valueUpdated($event)" (focus)="controlFocused($event)"
                (blur)="controlBlurred($event)" />

            <input id="timeInput" *ngIf="
                question.questionType === 'time' ||
                question.questionType === 'hours-minutes' ||
                question.questionType === 'duration'
              " readonly="readonly" type="text" class="form-control" [(ngModel)]="answerValue"
                (click)="openTimeInput()" (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />

            <radio-group-question [question]="question" [previousValue]="answerValue"
                *ngIf="question.questionType === 'radio'" (answerUpdated)="radioValueUpdated($event)">
            </radio-group-question>

            <checkbox-question [question]="question" [previousValue]="answerValue"
                *ngIf="question.questionType === 'checkbox'" (answerUpdated)="checkboxValueUpdated($event)"
                (otherAnswerUpdated)="otherInputUpdated($event)" (handleSelectAll)="handleSelectAll($event)">
            </checkbox-question>

            <dropdown-question [question]="question" [previousValue]="answerValue"
                *ngIf="question.questionType === 'dropdown'" (answerUpdated)="dropdownValueUpdated($event)"
                (otherAnswerUpdated)="otherInputUpdated($event)">
            </dropdown-question>

            <slider-question [question]="question" *ngIf="question.questionType === 'slider'" [step]="question.step"
                [min]="question.min" [max]="question.max" [initialValue]="answerValue"
                (answerUpdated)="radioValueUpdated($event)">
            </slider-question>

            <time-input-modal *ngIf="
                isModalDisplayed && logState.isModalDisplayed &&
                (question.questionType === 'time' ||
                  question.questionType === 'hours-minutes' ||
                  question.questionType === 'duration')
              " [question]="question" (answerUpdated)="onTimeQuestionAnswered($event)"></time-input-modal>

            <column-question *ngIf="question.questionType === 'column'" [question]="question"
                (answerUpdated)="updateMultipleQuestionsQuestion($event)"></column-question>
        </div>
        <div class="question-subtext pb-4" [innerHtml]="question.postText" *ngIf="question.postText && showLabels">
        </div>
    </div>
</div>