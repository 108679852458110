import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";


export const gad7: AssessmentDefinition = {
  name: 'gad7',
  description: 'Generalized Anxiety Disorder',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Feeling nervous, anxious, or on edge.`,
      subText: ``,
      questionType: 'slider',

      uniqueAnswerId: 'GAD7_NERVOUS',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 1,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Not being able to stop or control worrying`,
      subText: ``,
      questionType: 'slider',

      uniqueAnswerId: 'GAD7_WORRY',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 2,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Worrying too much about different things`,
      subText: ``,
      questionType: 'slider',

      uniqueAnswerId: 'GAD7_WORRY_TOO_MUCH',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 3,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Trouble Relaxing`,
      subText: ``,
      questionType: 'slider',

      uniqueAnswerId: 'GAD7_TROUBLE_RELAXING',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 4,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Feeling restless so that it's hard to sit still`,
      subText: ``,
      questionType: 'slider',

      uniqueAnswerId: 'GAD7_RESTLESS',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 5,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Becoming easily annoyed or irritable`,
      subText: ``,
      questionType: 'slider',
      uniqueAnswerId: 'GAD7_IRRITABLE',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 6,
      questionText: 'Over the past day, how often have you been bothered by any of these problems?',
      questionEmphasis: `Feeling afraid as if something awful might happen`,
      subText: ``,
      questionType: 'slider',
      uniqueAnswerId: 'GAD7_AFRAID',
      autofill: false,
      answerOptions: [
        {
          display: 'Not at all',
          value: 0
        },
        {
          display: 'Less than half the day',
          value: 1
        },
        {
          display: 'More than half the day',
          value: 2
        },
        {
          display: 'Nearly all day',
          value: 3
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
  ]
};
