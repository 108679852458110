<span class="form-group d-inline w-50">
        <label class="mr-2" for="inputText">{{labelText}}</label>
        <select [id]="id" [name]="id" [class.is-invalid]="errorMessages?.length > 0"
            [class.is-valid]="errorMessages?.length === 0" [ngModel]="value" (change)="onChange($event)"
            class="form-control d-inline" aria-describedby="inputHelp">
            <option *ngFor="let option of options" [value]="option[optionValueField]">{{option[optionTextField]}}
            </option>
        </select>
        <small id="inputHelp" class="form-text text-muted">{{inputHelpText}}</small>
        <div class="alert alert-danger" *ngIf="errorMessages?.length > 0">
            <div *ngFor="let error of errorMessages">
                &#8226;&nbsp;{{error}}
            </div>
        </div>
</span>