import { find as _find } from 'lodash';
import moment, { Moment } from 'moment';
import { Injectable } from '@angular/core';
import { CalendarEvent } from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class LegacyService {
  public getLegacyEndDate(event: CalendarEvent, weeks: Array<CalendarEvent>): moment.Moment {
    let nextWeek: CalendarEvent = null;
    switch (event.phaseName) {
      case 'weekFive':
        nextWeek = _find(weeks, week => week.phaseName === 'followup');
        return moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
      case 'followup':
        nextWeek = _find(weeks, week => week.phaseName === 'followup2');
        return moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
      case 'followup2':
        nextWeek = _find(weeks, week => week.phaseName === 'followup3');
        return moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
      case 'followup3':
        const prevWeek: CalendarEvent = _find(weeks, week => week.phaseName === 'followup3');
        return moment
          .utc(prevWeek.startDate)
          .add(4 * 7 * 6 + 14, 'days')
          .endOf('day')
          .local();
    }
  }
}
