import { FieldDefinitionBase, CalculatedFieldDefinition, FieldType, UnitType, OperationType } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const CalculatedFieldDefinitions: Array<FieldDefinitionBase> = [

  new CalculatedFieldDefinition({
    _type: FieldType.Calculated,
    name: 'longest-sleep',
    includeZeroValuesInAggregates: false,
    unit: UnitType.Minutes,
    abbreviation: 'SE',
    description: '',
    calculations: [
      {
        dataFields: [
          { dataFieldName: 'wake-time'},
          { dataFieldName: 'bed-time'},

        ],
        operation: OperationType.Subtract,
        priority: 0
      }
    ],
    priority: 0
  }),
  new CalculatedFieldDefinition({
    _type: FieldType.Calculated,
    name: 'total-sleep-minus-latency',
    includeZeroValuesInAggregates: false,
    unit: UnitType.Minutes,
    abbreviation: 'SE',
    description: '',
    calculations: [
      {
        dataFields: [
          { dataFieldName: 'longest-sleep'},
          { dataFieldName: 'sleep-latency'},
          { dataFieldName: 'sleep-interruption-total'}

        ],
        operation: OperationType.Subtract,
        priority: 0
      }
    ],
    priority: 1
  }),
  new CalculatedFieldDefinition({
    _type: FieldType.Calculated,
    name: 'total-caffeine-intake',
    unit: UnitType.Number,
    abbreviation: '',
    description: '',
    calculations: [
      {
        dataFields: [
          { dataFieldName: 'caffeine-amount-1'},
          { dataFieldName: 'caffeine-amount-2'},
          { dataFieldName: 'caffeine-amount-3'},
          { dataFieldName: 'caffeine-amount-4'},

        ],
        operation: OperationType.Subtract,
        priority: 0
      }
    ],
    priority: 0
  }),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'sleep-efficiency',
  unit: UnitType.Percent,
  includeZeroValuesInAggregates: false,
  abbreviation: 'SE',
  description: '',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'total-sleep-minus-latency'},
        { dataFieldName: 'longest-sleep'},
      ],
      operation: OperationType.Divide,
      priority: 0
    }
  ],
  priority: 1
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'teamwork-concerns',
  unit: UnitType.Number,
  abbreviation: 'TeamworkOverall',
  description: 'Used to get chart data for team concerns filter on stats tab',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'team-work'},
        { dataFieldName: 'team-communication'},
        { dataFieldName: 'team-expressing'},
        { dataFieldName: 'team-angry'},
        { dataFieldName: 'team-handle-conflict'},
        { dataFieldName: 'team-left-out'},
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ],
  priority: 0
}),



new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'overall-teamwork',
  unit: UnitType.Number,
  abbreviation: 'OverallTeamwork',
  description: 'Used to get chart data for overall teamwork filter on stats tab',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'team-communication-overall'},
        { dataFieldName: 'vacds-overall'},
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ],
  priority: 0
}),



]
