<div [(ngModel)]="state['diaryReminderFrequency']" ngDefaultControl *ngIf="stateService.state$ | async as state">
    <div class="user-actions-container d-flex">
        <a class="user-actions" (click)="openReportDialog()" *ngIf="this.modalType === 'edit-patient'">
            <div class="round-icon"><i class="fas fa-file-invoice" style="font-size:.9em;"></i></div>
            <span class="roundText">Intervention Report</span>
        </a>
        <a class="user-actions" (click)="openThreadDialog()" *ngIf="this.modalType === 'edit-patient'">
            <div class="round-icon"><i class="fas fa-envelope" style="font-size:.7em;"></i></div>
            <span class="roundText">Message Patient</span>
        </a>
        <a class="user-actions" (click)="openEditDialog()" *ngIf="this.modalType === 'edit-patient'">
            <div class="round-icon"><i class="fas fa-pencil-alt" style="font-size:.7em;"></i></div>
            <span class="roundText">Edit Patient</span>
        </a>
        <div class="user-actions" *ngIf="this.modalType === 'edit-patient'">
            <select [(ngModel)]="state.patientInfo.diaryReminderFrequency"
                (change)="onNotificationChange($event, state.userId)">
                <option value="never">No</option>
                <option value="morning">Morning</option>
                <option value="evening">Evening</option>
                <option value="morning_and_evening">Morning and Evening</option>
            </select>
            <span class="roundText">Log Reminders</span>
        </div>
    </div>
        <a class="nav-link" (click)="openAddDialog()" *ngIf="this.modalType === 'manage-patient'">
            <em class="icon-plus"></em><span class="ml-2">Add Patient</span>
        </a>
    <a class="reminder" (click)="openCheckinDialog()" *ngIf="this.modalType === 'add-checkin' && !isEditWeek"> <i
            class="fas fa-plus-circle"></i> Add Check In</a>
    <div *ngIf="this.modalType === 'terms' && !EULAAgreed">
        <a href="#" (click)="openTermsDialog()">Terms and conditions</a>
    </div>
    <div *ngIf="this.modalType === 'terms' && EULAAgreed">
        <a href="#" (click)="openTermsDialog()">Terms and conditions</a>
    </div>
    <div *ngIf="this.modalType === 'confirm-delete'">
        <a (click)="openConfirmDeleteDialog()"><i class="fas fa-times-circle"></i></a>
    </div>
</div>
