import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const strategicCaffeine: AssessmentDefinition = {
  name: 'strategic-caffeine',
  description: 'Strategic Caffeine',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Caffeine is a temporary wake promoting substance',
      questionEmphasis: ``,
      subText: `<p>It typically reaches its peak alerting effect after 45 minutes and lasts for about 4-6 hours. When used strategically, it can have beneficial effects on: </p>
                <p>
                  <b>
                    Vigilance<br />
                    Reaction Time<br />
                    Accuracy<br />
                  </b>
                </p>
                <p>Caffeine <b>DOES NOT</b> eliminate the need for sleep. Only sleep can address the body’s need for sleep. </p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'What does strategic use of caffeine look like?',
      questionEmphasis: ``,
      subText: `<p>Distributing caffeine intake throughout the day rather than one large dose. Use 200 mg of caffeine every 2 to 4 hours, as needed, to sustain alertness.</p>

                <p>NOT using caffeine at least 6 hours before your next sleep opportunity. This allows time for the alerting effects of caffeine to wear off. </p>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: `Here's what 200 mg of caffeine looks like`,
      questionEmphasis: ``,
      subText: `<p>
                <div class='row pb-1 pt-1'>
                  <div class='col-4 center-align'><b>4</b> cans of Diet Coke</div>
                  <div class='col-8 my-auto'>
                    <img class='module-image-margin module-image-background' src='./assets/img/module-images/strategic-caffeine/soda.png'/>
                  </div>
                </div>
                <div class='row pb-1 pt-1'>
                  <div class='col-4 center-align'><b>2.5</b> cans of Red Bull</div>
                  <div class='col-8 my-auto'>
                    <img class='module-image-margin module-image-background' src='./assets/img/module-images/strategic-caffeine/cans.png'/>
                  </div>
                </div>
                <div class='row pb-1 pt-1'>
                  <div class='col-4 center-align'><b>1 to 2 (8 oz)</b> cups of coffee</div>
                  <div class='col-8 my-auto'>
                    <img class='module-image-margin module-image-background' src='./assets/img/module-images/strategic-caffeine/cups.png'/>
                  </div>
                </div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: `Caffeine can be a powerful ally when used properly`,
      questionEmphasis: ``,
      subText: `
                <p>Taking too much caffeine at one time, when already sleepy, can lead to rebound fatigue. This is when the caffeine all wears off at the same time multiplying sleepiness felt. </p>
                <p>Taking too much caffeine at one time can also cause restlessness, nervousness, and digestive problems. </p>
                <p>Taking caffeine too close to a sleep opportunity makes it more difficult to fall asleep. This can limit the total amount of sleep attained during a given sleep opportunity. </p>

      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
