import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const gad7: Array<FieldDefinitionBase> =
[

//#endregion
new AssessmentGroupScoreFieldDefinition({
  _type: FieldType.AssessmentGroupScore,
  name: 'gad7-overall',
  unit: UnitType.Number,
  abbreviation: 'Gad 7 Overall',
  description: `Gad 7 Overall`,
  assessmentType: LogDefinitionTypes.GAD7
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-nervous',
  unit: UnitType.Number,
  abbreviation: 'Nervous',
  description: `Nervous`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_NERVOUS'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-control',
  unit: UnitType.Number,
  abbreviation: 'Control',
  description: `Control`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_CONTROL'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-worrying',
  unit: UnitType.Number,
  abbreviation: 'Worrying',
  description: `Worrying`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_WORRYING'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-relaxing',
  unit: UnitType.Number,
  abbreviation: 'Relaxing',
  description: `Relaxing`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_RELAXING'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-restless',
  unit: UnitType.Number,
  abbreviation: 'Restless',
  description: `Restless`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_RESTLESS'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-irritable',
  unit: UnitType.Number,
  abbreviation: 'Irritable',
  description: `Irritable`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_IRRITABLE'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'gad7-fear',
  unit: UnitType.Number,
  abbreviation: 'Fear',
  description: `Fear`,
  assessmentType: LogDefinitionTypes.GAD7,
  answerId: 'GAD7_FEAR'
}),
  //#endregion
];
