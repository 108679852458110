import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations'
import { trainingFeedbackSurvey } from '../questions/training-survey/training-feedback-survey';

export const trainingFeedback: AssetDefinition = {
  id: 1,
  name: 'training-feedback',
  label: 'Training Feedback',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  assessments: [trainingFeedbackSurvey],
  url: 'training-feedback'
};
