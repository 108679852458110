export class WheelSelectorIndexService {
  private hourIndexTrackerBegin = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23
  ];
  private hourIndexTrackerEnd = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71
  ];
  private twelveHourTimeDisplay: any = [
    '(12am)00 :',
    '(1am)01 :',
    '(2am)02 :',
    '(3am)03 :',
    '(4am)04 :',
    '(5am)05 :',
    '(6am)06 :',
    '(7am)07 :',
    '(8am)08 :',
    '(9am)09 :',
    '(10am)10 :',
    '(11am)11 :',
    '(12pm)12 :',
    '(1pm)13 :',
    '(2pm)14 :',
    '(3pm)15 :',
    '(4pm)16 :',
    '(5pm)17 :',
    '(6pm)18 :',
    '(7pm)19 :',
    '(8pm)20 :',
    '(9pm)21 :',
    '(10pm)22 :',
    '(11pm)23 :'
  ];

  private hourMinDisplay: any = [
    '00 :',
    '01 :',
    '02 :',
    '03 :',
    '04 :',
    '05 :',
    '06 :',
    '07 :',
    '08 :',
    '09 :',
    '10 :',
    '11 :',
    '12 :',
    '13 :',
    '14 :',
    '15 :',
    '16 :',
    '17 :',
    '18 :',
    '19 :',
    '20 :',
    '21 :',
    '22 :',
    '23 :'
  ];

  private hourValues: any = [`00 :`, `01 :`, `02 :`, `03 :`, `04 :`, `05 :`, `06 :`, `07 :`, `08 :`, `09 :`,
    10 + ` :`,
    11 + ` :`,
    12 + ` :`,
    13 + ` :`,
    14 + ` :`,
    15 + ` :`,
    16 + ` :`,
    17 + ` :`,
    18 + ` :`,
    19 + ` :`,
    20 + ` :`,
    21 + ` :`,
    22 + ` :`,
    23 + ` :`
  ];
  private minuteIndexTrackerBegin = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  private minuteIndexTrackerEnd = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
  private minuteValues: any = ['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  evaluateCurrentArrayForScrollingUp(currentIndex: number, gIndex: number) {
    const wholeN = Math.round(currentIndex);

    if (gIndex == 0) {
      return this.hourIndexTrackerEnd.includes(wholeN);
    } else {
      return this.minuteIndexTrackerEnd.includes(wholeN);
    }
  }

  evaluateCurrentArrayForScrollingDown(currentIndex: number, gIndex: number) {
    const wholeN = Math.round(currentIndex);

    if (gIndex == 0) {
      return this.hourIndexTrackerBegin.includes(wholeN);
    } else {
      return this.minuteIndexTrackerBegin.includes(wholeN);
    }
  }

  getHourValues() {
    return this.hourValues;
  }

  getMinuteValues() {
    return this.minuteValues;
  }

  getTwelveHourTimeDisplay() {
    return this.twelveHourTimeDisplay;
  }

  getHourMinDisplay() {
    return this.hourMinDisplay;
  }
}
