export const morningLogDef = {
  "name": "morningLog",
  "frequency": 1,
  "questions": [
    {
      "id": 0,
      "questionText": "I tried to go to sleep at:",
      "questionType": "time",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [],
      "uniqueAnswerId": "ATTEMPT_TO_SLEEP",
      "defaultAnswer": "23:00",
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "questionText": "I was sleepy when I got into bed",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "uniqueAnswerId": "WAS_SLEEPY",
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "It took me about ____ minutes to fall asleep",
      "questionType": "number",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [],
      "uniqueAnswerId": "MINUTES_TO_SLEEP",
      "conditionalQuestions": [],
      "validation": {
        "maxLength": 3
      }
    },
    {
      "id": 3,
      "questionText": "Thoughts racing through my mind kept me awake.",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "uniqueAnswerId": "RACING_MIND",
      "conditionalQuestions": []
    },
    {
      "id": 4,
      "questionText": "After falling asleep I woke during the night.",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "uniqueAnswerId": "WAS_RESTLESS",
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [true],
          "id": 5,
          "questionText": "In total, I woke up ___ time(s)",
          "questionType": "number",
          "questionHeader": false,
          "autofill": false,
          "answerOptions": [],
          "uniqueAnswerId": "TOTAL_WOKEUPS",
          "conditionalQuestions": [],
          "validation": {
            "maxLength": 2
          }
        },
        {
          "displayIfAnswerEquals": [true],
          "id": 6,
          "questionText": "for a total of about ___ minute(s)",
          "questionType": "number",
          "questionHeader": false,
          "autofill": false,
          "answerOptions": [],
          "uniqueAnswerId": "TOTAL_MINUTES_WOKEUPS",
          "conditionalQuestions": [],
          "validation": {
            "maxLength": 3
          }
        }
      ]
    },
    {
      "id": 7,
      "questionText": "I woke up at:",
      "questionType": "time",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [],
      "uniqueAnswerId": "WAKE_TIME",
      "defaultAnswer": "08:00",
      "conditionalQuestions": []
    },
    {
      "id": 9,
      "questionText": "I actually got out of bed at:",
      "questionType": "time",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [],
      "uniqueAnswerId": "RISE_TIME",
      "defaultAnswer": "08:00",
      "conditionalQuestions": []
    },
    {
      "id": 10,
      "questionText": "The quality of my sleep was:",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Very bad", "value": "1" },
        { "display": "Bad", "value": "2" },
        { "display": "Alright, but could be better", "value": "3" },
        { "display": "Good", "value": "4" },
        { "display": "Very Good", "value": "5" }
      ],
      "uniqueAnswerId": "SLEEP_QUALITY",
      "conditionalQuestions": []
    },
    {
      "id": 11,
      "questionText": "I feel/felt:",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Very tired as if I did not sleep", "value": "1" },
        { "display": "Tired and not ready for the day", "value": "2" },
        { "display": "Alright. Will likely feel more energized once I begin my day", "value": "3" },
        { "display": "Good", "value": "4" },
        { "display": "Very Good", "value": "5" }
      ],
      "uniqueAnswerId": "MORNING_FEEL",
      "conditionalQuestions": []
    },
    {
      "id": 12,
      "questionText": "I remember I had dreams or nightmares",
      "questionType": "radio",
      "questionHeader": true,
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "uniqueAnswerId": "HAD_DREAMS",
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [true],
          "id": 13,
          "questionText": "These dreams or nightmares bothered me",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            { "display": "Yes", "value": true },
            { "display": "No", "value": false }
          ],
          "uniqueAnswerId": "DREAMS_BOTHERED",
          "conditionalQuestions": []
        }
      ]
    }
  ]
};
