import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { ApplicationContext } from '../../../core/web-ng';
import { Validator } from '../../../core/utils';
import { ApiHelper } from '../../../core/helpers';
import { SecurityUser } from '../../../public-api';
import { NetworkService } from '../../services/network-service';



export class AuthResponse {
    constructor(public success: boolean, public messages: string[]) { }
}
export class LoginResponse extends AuthResponse {
    success: boolean;
    data: any;

    public Token: string;
    public TokenType: string;
    public ExpiresIn: number;
    public Issued: Date;
    public UserName: string;
    public Organization: string;
    public IsCustomer: boolean;

}



@Injectable()
export class AuthService {
    private apiHelper: ApiHelper;

    constructor(
        applicationContext: ApplicationContext,
        private networkService: NetworkService,
        private http: HttpClient) {
        this.apiHelper = new ApiHelper(applicationContext);
    }

    public authenticate(username: string, password: string, clientId = 'sys', isCustomer = true, role?: string): Observable<LoginResponse> {
        const xmlContentHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        xmlContentHeaders.set('Accept', 'application/json');
        const isCustomerArgument = isCustomer ? '&isCustomer=' + (clientId !== 'sys').toString() : '&isCustomer=false';
        let data = 'grant_type=password' +
            '&username=' + encodeURIComponent(username) +
            '&password=' + encodeURIComponent(password) +
            isCustomerArgument +
            '&organization=' + clientId;

        if (role) {
          data += '&role=' + role;
        }
        const requestOptions = { headers: xmlContentHeaders };

        return Observable.create(o => {
            this.http.post<any>(`${this.apiHelper.RootUrl()}/auth/token`, data, requestOptions).pipe(
                map(res => {
                    return {
                        success: res.success,
                        Token: res.data.access_token,
                        TokenType: res.data.token_type,
                        ExpiresIn: res.data.expires_in,
                        Issued: new Date(res.data['.issued'])
                    };
                })
            ).subscribe(
                response => {
                    //reconnect to socket on login.  If there is no active network connection, reinitialize network service.  
                    if(this.networkService.hasInitialized()){
                        this.networkService.connectSocketService();
                    } else{
                        this.networkService.initialize();
                    }
                    o.next(response);
                    o.complete();
                },
                (err: HttpErrorResponse) => {
                    o.next(new LoginResponse(false, [ err.error.error_description ] ));
                    o.complete();
                }
            );
        });
    }

    public logout(token: string): Observable<boolean> {
        const requestOptions = { headers: { Authorization: `Bearer ${token}` } };
        return Observable.create(o => {
            this.http.get(`${this.apiHelper.RootUrl()}/auth/signout`,  requestOptions as any).pipe(
                finalize(() => o.complete())
            ).subscribe(res => {
                localStorage.setItem('token', '');
                localStorage.clear(); //clear local storage on signout
                this.networkService.disconnectSocketService(); //disconnect socket on signout
                o.next(true);
            }, (err: HttpErrorResponse) => {
                o.next(false);
            });
        });

    }

    public validate(token: string): Observable<boolean> {
        return Observable.create(o => {
            this.http.get(`${this.apiHelper.RootUrl()}/auth/validatetoken`, this.requestOptions(token)).pipe(
                finalize(() => o.complete())
            ).subscribe(res => {
                o.next(true);
            }, (err: HttpErrorResponse) => {
                o.next(false);
            });
        });
    }

    public updatePassword(token: string, currentPassword: string, newPassword: string): Observable<AuthResponse> {
        const data = {
            oldPassword: currentPassword,
            newPassword
        };

        return Observable.create(o => {
            this.http.post(`${this.apiHelper.RootUrl()}/auth/update-password`, data, this.requestOptions(token, 'json'))
                .subscribe(
                response => {
                    o.next(response);
                    o.complete();
                },
                (err: HttpErrorResponse) => {
                    o.next(new AuthResponse(false, err.error.error_description));
                    o.complete();
                });
        });
    }

    public resetPasswordParams(redirectUrl: string, isCustomer: boolean = true): Object {
        Validator.throwIfNil(redirectUrl, 'Missing redirectUrl');
        return { redirectUrl, isCustomer };
    }

    public updatePin(userId:string, pin:string)
    {
      return Observable.create(o => {
        this.http.post(`${this.apiHelper.RootUrl()}/auth/updatepin`, {userId, pin})
            .subscribe(
            response => {
                o.next(response);
                o.complete();
            },
            (err: HttpErrorResponse) => {
                o.next(new AuthResponse(false, err.error.error_description));
                o.complete();
            });
    });
    }

    public sendResetPasswordEmail(token: string, userEmail: string): Observable<any> {
        let params: Object;
        const isCustomer = true;
        let url: string;
        Validator.throwIfNil(userEmail, 'Missing userEmail'); // TODO use Validator.throwIfInvalidEmail after @/utils v0.2.19

        url = this.forgotPasswordEndpoint(userEmail);
        params = this.resetPasswordParams(`${window.location.origin}/user/update-password`, isCustomer);

        const options = this.requestOptions(token);
        options.search = params;
        return this.http.get(url, options);
    }

    public forgotPasswordEndpoint(userEmail: string): string {
        if (!Validator.isValidEmailAddress(userEmail)) {
            throw new Error(`Invalid email address ${userEmail}`);
        }
        return `${this.basePrefix()}account/resetpassword/${userEmail}`;
    }

    public saveSecurityUser(user: SecurityUser):Observable<any> {
      let url: string, payload: any;

      url = `${this.apiHelper.RootUrl()}/auth/saveSecurityUser`;

      payload = {
          user:user
      };

      return Observable.create(o => {

          this.http.post(url, payload).pipe(
              map((r: any) => {
                  const responseJson = r;
                  return responseJson;
              })
          ).subscribe(re => {
              o.next(re);
              o.complete(re);
          }, err => {
              console.log(err);
              throw Error(err);
          });
      });
    }


    private requestOptions(token: string, responseType?: string): any {
        responseType = responseType || 'text';
        return { responseType, headers: { Authorization: `Bearer ${token}` } };
    }

    private basePrefix(): string {
        return `${this.apiHelper.RootUrl()}/v1`;
    }

    public updateResetPassword(passwords: any, confirmId: string): Observable<any> {
        Validator.throwIfNil(confirmId, 'Missing confirmId');
        Validator.throwIfNil(passwords, 'Missing passwords');
        Validator.throwIfNil(passwords.Password, 'Missing passwords.Password');
        Validator.throwIfNil(passwords.ConfirmPassword, 'Missing passwords.ConfirmPassword');

        let url: string, payload: any;

        url = `${this.basePrefix()}account/updatepassword`;

        payload = {
            newPassword: passwords.Password,
            confirmNewPassword: passwords.ConfirmPassword,
            confirmId
        };

        return this.http.post(url, payload);
    }

    public getAuthUsers(): Observable<any>{
        const url = `${this.apiHelper.RootUrl()}/auth/getClinicians`;
        return this.http.get(url);
    }

    public removeUser(id:string):Observable<any>{
        const url = `${this.apiHelper.RootUrl()}/auth/delete`;
        const options:any = {
            responseType:'json',
            params:[id],
            headers: this.apiHelper.Headers()
        }
        options.search = { id };
        return this.http.delete(url, options);
    }

    public getUser(id:string): Observable<any>{
        const url = `${this.apiHelper.RootUrl()}/auth/getUser`;
        const options:any = {
            responseType:'json',
            params:[id],
            headers: this.apiHelper.Headers()
        }
        options.search = { id };
        return this.http.get(url, options);
    }
}
