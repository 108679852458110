import { Inject, Injectable } from '@angular/core';
import { UserStateService } from '../state/user-state';

import { Observable, of } from 'rxjs';
import { ClientFuncService, USER_STATE_SERVICE, ApplicationContext } from '../../core/web-ng';
import { BaseRepository, SystemResponse, UserSettings } from '../../core';
import { MessageThread } from './models';

@Injectable({
  providedIn: 'root'
})
export class MessageThreadService extends ClientFuncService<MessageThread> {
  modelName = 'MessageThread';

  constructor(
    applicationContext: ApplicationContext,
    @Inject(USER_STATE_SERVICE)
    userStateService: UserStateService
  ) {
    super(
      applicationContext,
      new BaseRepository<any>(applicationContext, 'MessageThread'),
      userStateService
    );
  }

  send(text: string, recipientUserId: string, senderIsPatient: boolean) {
    if (!text) {
      return of(new SystemResponse(true));
    } else {
      return this.postFunc('send', {
        text,
        recipientUserId,
        senderIsPatient
      });
    }
  }

  init(): Observable<SystemResponse<{ allRecipientIds: string[]; groupUsers: UserSettings[] }>> {
    return this.postFunc('init', {});
  }

  markRead(recipientId: string): Observable<SystemResponse<boolean>> {
    return this.postFunc('markread', {
      recipientId
    });
  }

  markToRecipientRead(recipientId: string): Observable<SystemResponse<boolean>> {
    return this.postFunc('markToRecipientRead', {
      recipientId
    });
  }

  allUnread(): Observable<SystemResponse<Array<any>[]>> {
    return this.getFunc(`unread`);
  }
}
