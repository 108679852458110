export class FieldDictionary {
  static LogDate = 'log-date';
  static BedTime = 'bed-time';
  static RiseTime = 'rise-time';
  static WakeTime = 'wake-time';
  static SleepLatency = 'sleep-latency';
  static RacingMind = 'racing-mind';
  static SleepEfficiency = 'sleep-efficiency';
  static SleepQuality = 'sleep-quality';
  static TotalWakeupsMinutes = 'total-minutes-wokeups';
  static TimeInBed = 'time-in-bed';
  static AsleepTime = 'asleep-time';
  static MorningTimeInBed = 'morning-time-in-bed';
  static TotalSleepTime = 'total-sleep-time';
  static WASO = 'waso';
  static ISIScore = 'isi-score';
  static GAD7 = {
    Irritable: 'irritable'
  };
  static SleepyFeels = 'sleepy-feels';
  static UsedCaffeine = 'used-caffeine';
  static PreventedSleep = 'prevented-sleep';
  static SleptPast24 = 'slept-past-24';
  static TotalSleepPast24 = 'total-sleep-past-24';
  static DailySleepQuality = 'daily-sleep-quality';
  static CaffeineSources = 'caffeine-sources';
  static CaffeineAmount = 'caffeine-amount';
  static StressStats = 'stress-stats';
  static TeamStats = 'team-stats';
}
