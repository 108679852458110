import { Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { UserStateService } from '../state/user-state';
import { Log } from '../state/log-state.service';
import { Socket } from 'ngx-socket-io';
import { Assessment, Group, Medicine, NoctemUser, SimpleInstance, Notification,
            AssessmentTemplate, MedicalPrescription, CalendarEvent, SleepPrescription, ExtraMeta, SentModule } from './models';
import { MessageService as BaseMessageService } from '../../core/services/MessageService';
import { ClientFuncService, ApplicationContext, USER_STATE_SERVICE } from '../../core/web-ng';
import { BaseRepository, Message, TimedSession } from '../../core';
import { BaseRepositoryCacheDecorator } from '../../core/base-repository-cache-decorator';
import { CacheService }                     from '../services/cache-service';
import { NetworkService }                   from '../services/network-service';
import moment from 'moment';
import { AssessmentDefinition } from '../../core/contracts/models.calculations';
import { logDefinitions } from '../../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class GroupService extends ClientFuncService<Group> {
    modelName = 'Group';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'Group'), userStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AssessmentTemplateService extends ClientFuncService<AssessmentTemplate> {
    modelName = 'AssessmentTemplate';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService) {
        super(applicationContext, new BaseRepository<any>(applicationContext, 'AssessmentTemplate'), userStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends ClientFuncService<Notification> {
    modelName = 'Notification';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'Notification'), userStateService);
    }
}


@Injectable({
    providedIn: 'root'
})
export class SleepPrescriptionService extends ClientFuncService<SleepPrescription> {
    modelName = 'SleepPrescription';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, private cacheService: CacheService, private networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'SleepPrescription'), userStateService);
    }

    public createSleepPrescription(instance: SleepPrescription): Observable<any> {
        return this.postFunc('create', instance).pipe(
            map(response => {
                return response;
            }));
    }

    public update(instance:SleepPrescription):Observable<any>{
        if (!this.cacheService.shouldCache()) {
            return this.postFunc('update', instance).pipe(
                map(response => {
                    return response;
                }));
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection) {
                        this.postFunc('update', instance).pipe(
                            map(response => {
                                this.cacheService.set('sleepprescription/update', response);
                                o.next(response);
                                o.complete();
                            }));
                    } else {
                        o.next(this.cacheService.get('sleepprescription/update'));
                        o.complete();
                    }
                });
            })
        } else {
            if (this.networkService.isOnline()) {
                return this.postFunc('update', instance).pipe(
                    map(response => {
                        return response;
                    }));
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get('sleepprescription/update'))
                    o.complete();
                })
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CalendarEventService extends ClientFuncService<CalendarEvent> {
    modelName = 'CalendarEvent';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'CalendarEvent'), userStateService);
    }

    public addCalendarEvent(patientId: string, type: string) {
        return this.postFunc('', {type, patientId}).pipe(
            map(response => {
                return response;
            }));
    }

    public deleteCalendarEvent(id) {
        return this.deleteFunc(id).pipe(
            map(response => {
                return response;
            }));
    }
}

@Injectable({
    providedIn: 'root'
})
export class MedicineService extends ClientFuncService<Medicine> {
    modelName = 'Medicine';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService) {
        super(applicationContext, new BaseRepository<any>(applicationContext, 'Medicine'), userStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class MedicalPrescriptionService extends ClientFuncService<MedicalPrescription> {
    modelName = 'MedicalPrescription';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'MedicalPrescription'), userStateService);
    }

    public getMedicalPrescriptionList(userId: string): Observable<any> {
        return this.getAll({'Payload.patient.id': userId}).pipe(
            map(response => {
               return response;
            }));
    }

    public createMedicalPrescription(medInstance: MedicalPrescription): Observable<any> {
        return this.postFunc('create', medInstance).pipe(
            map(response => {
               return response;
            }));
    }

    public delMedicalPrescription(medId: string): Observable<any> {
        return this.deleteFunc(medId).pipe(
            map(response => {
               return response;
            }));
    }
}

@Injectable()
export class LogService extends ClientFuncService<Log> {
    modelName = 'log';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService) {
        super(applicationContext, new BaseRepository<any>(applicationContext, 'log'), userStateService);
    }
}

@Injectable()
export class ExtraMetaService extends ClientFuncService<ExtraMeta> {
    modelName = 'extraMeta';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'extraMeta'), userStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SearchService extends ClientFuncService<any> {
    modelName = 'search';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService,
                private cacheService: CacheService, private networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'search'), userStateService);
    }

    public getSleepDailyOutcomeRecords(fromIndex: number, size: number, sort: Array<any>, filters: Array<any>, dimensions: Array<any>, phrase: string): Observable<any> {
        const params = { model: 'sleepdailyoutcome', fromIndex, size, sort, filters, dimensions, phrase };

        if (!this.cacheService.shouldCache()) {
            return this.postFunc('records', params).pipe(
                map(response => {
                    if (response.success) {
                        this.cacheService.set('search/sleepdailyoutcome', response.data);
                        return response.data
                    }
                }));
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection) {
                        this.postFunc('records', params).subscribe(response => {
                                if (response.success) {
                                    this.cacheService.set('search/sleepdailyoutcome', response.data);
                                    o.next(response.data);
                                    o.complete();
                                }
                            });
                    } else {
                        o.next(this.cacheService.get('search/sleepdailyoutcome'))
                        o.complete();
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return this.postFunc('records', params).pipe(
                    map(response => {
                        if (response.success) {
                            this.cacheService.set('search/sleepdailyoutcome', response.data);
                            return response.data
                        }
                    }));
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get('search/sleepdailyoutcome'))
                    o.complete();
                })
            }
        }

    }

    public getRecords(params: { model: string,  filters: any[], fromIndex?: number, size?: number, sort?: any[], dimensions?: any[] }): Observable<any> {
        if (!params.fromIndex) {
            params.fromIndex = 0;
        }

        // records caching needs to differentiate between from filter and to filter
        // for now use the lenght of the param
        const paramsLength = JSON.stringify(params).length;

        if (!this.cacheService.shouldCache()) {
            return this.postFunc('records', params).pipe(
                map(response => {
                    if (response.success) {
                        this.cacheService.set('search/all_records'+paramsLength, response.data);
                        return response.data;
                    }
                }));
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection) {
                        this.postFunc('records', params).subscribe(response => {
                                if (response.success) {
                                    this.cacheService.set('search/all_records'+paramsLength, response.data);
                                    o.next(response.data);
                                    o.complete();
                                }
                            });
                    } else {
                        o.next(this.cacheService.get('search/all_records'+paramsLength))
                        o.complete();
                    }
                });
            })
        } else {
            if (this.networkService.isOnline()) {
                return this.postFunc('records', params).pipe(
                    map(response => {
                        if (response.success) {
                            this.cacheService.set('search/all_records'+paramsLength, response.data);
                            return response.data;
                        }
                    }));
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get('search/all_records'+paramsLength))
                    o.complete();
                })
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserFuncService extends ClientFuncService<any> {

    modelName = 'user';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, private networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'UserSettings'), userStateService);
    }

    public getUser(userId: string): Observable<any> {
        const params = { userId };
        return this.postFunc('getByUserId', params).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                    // TODO:  HANDLE ERROR
                }
            }));
    }


    public updateNotificationToken(token): Observable<any> {
        if (this.networkService.isOnline()) {
            return this.postFunc('updateNotificationToken', {
                Id: token,
                Type: 'FMS'
            }).pipe(
                map(response => {
                    if (response.success) {
                        return response.data;
                    } else {
                    }
                }));
        }
    }

    public updateUser(userId: string, updateAttributes: any): Observable<any> {
        //if (this.networkService.isOnline()) { // cannot use isOnline only here, need to check whether network has initialized and subscribe to network changes
        return this.putFunc(userId, updateAttributes).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                }
            }));
        //}
    }

    public indexUser(userId?: string, user?: NoctemUser): Observable<any> {
        if (!userId) {
            const user = this.userStateService.model.get().User;
            userId = user.UserId;
        }
        user = user || null;
        const params = { userId, user };
        return this.postFunc('index', params).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                    return response.warnings.join(', ');
                }
            }));
    }

    /**
     * Deletes indexes for user
     * @param userId user id to be removed
     */
    public deleteUserIndex(userId: string){
      return this.postFunc('removeIndexes', { userId }).pipe(
        map(response => {
            if (response.success) {
                return response;
            } else {
                return response.data;
            }
        }));
    }

    create(patientInfo): Observable<string> {
        return this.postFunc('create', patientInfo).pipe(
            map(response => {
                if (response.success) {
                    // Returns user id of created user
                    return response.data as string;
                } else {
                    // TODO:  HANDLE ERROR
                }
            }));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AssessmentService extends ClientFuncService<Assessment> {
    modelName = 'assessment';

    CACHE_ASSESSMENTS_KEY = "getAllAsync:assessment";

    constructor(
      applicationContext: ApplicationContext,
      @Inject(USER_STATE_SERVICE) userStateService: UserStateService,
      private cacheService: CacheService, private networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService,
                                                                        networkService,
                                                                        applicationContext,
                                                                        'assessment'),
                                                                        userStateService);
    }

    /**
     * Get log from cached async assessments instead during offline mode
     * @param assessmentDate
     * @param logType
     * @param userId
     */
    private getLogFromCache(assessmentDate: string,
        logType: string,
        userId?: string){
        const cacheAssessments = <Array <Assessment>> this.cacheService.get(this.CACHE_ASSESSMENTS_KEY);
        if(cacheAssessments && cacheAssessments.length){
            if(userId){
                return cacheAssessments.find(assessment => assessment.assessmentDate.includes(assessmentDate) && assessment.groups[0].type==logType && assessment.user.id==userId);
            }
            else{
                return cacheAssessments.find(assessment => assessment.assessmentDate.includes(assessmentDate) && assessment.groups[0].type==logType);
            }
        }
        else{
            return null;
        }
    }

    /**
     * getAllAsync
     * overrride to accomodate caching different queries
     * @param query 
     * @param size 
     * @param sorts 
     * @returns 
     */
    public async getAllAsync(query?: any, size?: number): Promise<Array<Assessment>> {
        let keyName = this.CACHE_ASSESSMENTS_KEY;
        if(size){
            keyName+= `:${size}`;
        }
        if(!size){//the size per soldier grows fast, so let's set 2000 by default
            size = 2000;
        }
        if (!this.cacheService.shouldCache()) {
            return super.getAllAsync(query, size);
        } else if (!this.networkService.hasInitialized()) {
            return new Promise((resolve, reject) => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection.connected) {
                        super.getAllAsync(query, size).then(data => {
                            this.cacheService.set(keyName, data)
                            resolve(data);
                        })
                    } else {
                        return resolve(this.cacheService.get(keyName));
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return super.getAllAsync(query, size).then(data => {
                    this.cacheService.set(keyName, data)
                    return data;
                });
            } else {
                return new Promise((resolve, reject) => {
                    resolve(this.cacheService.get(keyName))
                })
            }
        }

    }

    public getLog(
      assessmentDate: string,
      logType: string,
      userId?: string
    ): Observable<any> {
        const params = { assessmentDate, logType };
        const action = "getLog";
        if (userId) { params['userId'] = userId; }

        //return this.makeCachedRequest(params, 'getLog', 'assessment/getLog');
        if (!this.cacheService.shouldCache()) {
            return this.postFunc(action, params).pipe(
                map(response => {
                  if (response.success) {
                        return response.data;
                  }
                })
              );
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection) {
                        this.postFunc(action, params).pipe(
                            map(response => {
                                if (response.success) {
                                    o.next(response.data);
                                    o.complete();
                                }
                            })
                        );
                    } else {
                        o.next(this.getLogFromCache(assessmentDate, logType, userId))
                        o.complete();
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return this.postFunc(action, params).pipe(
                    map(response => {
                      if (response.success) {
                            return response.data;
                      }
                    })
                  );
            } else {
                return new Observable(o => {
                    o.next(this.getLogFromCache(assessmentDate, logType, userId))
                    o.complete();
                })
            }
        }
    }

    public getLogs(userId: string): Observable<any> {
      // Include the current local time so we don't see future logs
      // ...remember database is UTC
      const params = {
        userId,
        dateRange: {
          to: moment().format(),
        }
      };
      return this.makeCachedRequest(params, 'getLogs', 'assessment/getLogs');
    }

    public getConsent(id: string): Observable<any> {
        const params = {id};

        return this.makeCachedRequest(params, 'getConsent', 'assessment/getConsent');
    }

    public saveLogs(request: any): Observable<any> {
        request.timeZoneOffset = new Date().getTimezoneOffset();

        return this.makeCachedRequest(request, 'saveLogs', 'assessment/saveLogs');
    }



    public saveLogsForPatient(request: any): Observable<any> {
        return this.postFunc('saveLogsForPatient', request).pipe(
            map(response => {
                console.log(response);
                return response;
            })
        )
    }

    private makeCachedRequest(params: any, action: string, cacheKey: string): Observable<any> {



        if (!this.cacheService.shouldCache()) {
            return this.postFunc(action, params).pipe(
                map(response => {
                  if (response.success) {
                        this.cacheService.set(cacheKey, response.data);
                        return response.data;
                  }
                })
              );
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection) {
                        this.postFunc(action, params).pipe(
                            map(response => {
                                if (response.success) {
                                    this.cacheService.set(cacheKey, response.data);
                                    o.next(response.data);
                                    o.complete();
                                }
                            })
                        );
                    } else {
                        o.next(this.cacheService.get(cacheKey))
                        o.complete();
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return this.postFunc(action, params).pipe(
                    map(response => {
                      if (response.success) {
                            this.cacheService.set(cacheKey, response.data);
                            return response.data;
                      }
                    })
                  );
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get(cacheKey))
                    o.complete();
                })
            }
        }
    }

    public getCalculations(startDate: string, endDate: string, fields: string, patientIds: string, includeIndividualStatistics: boolean, treatmentPhases: string) : Observable<any> {
        return this.getFunc(
            `calculations?startDate=${startDate}&endDate=${endDate}&fields=${fields}&patientIds=${patientIds}&includeIndividualStatistics=${includeIndividualStatistics}&treatmentPhases=${treatmentPhases}`
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class WearableService extends ClientFuncService<Assessment> {
    modelName = 'wearable';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService) {
        super(applicationContext, new BaseRepository<any>(applicationContext, 'wearable'), userStateService);
    }

    public getLogs(userId: string): Observable<any> {
        const params = { userId };
        return this.postFunc('getSleeps', params).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                    // TODO:  HANDLE ERROR
                }
            }));
    }

    public sync(userId: string): Observable<any>{
        const params = { userId };
        return this.postFunc('syncFitbit', params).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                    // TODO:  HANDLE ERROR
                }
            }));
    }
}

@Injectable({
  providedIn: 'root'
})
export class SentModuleService extends ClientFuncService<SentModule>{
  modelName: 'SentModule';
  constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, private cacheService: CacheService, private networkService: NetworkService) {
    super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'sentModule'), userStateService);
  } 

  /**
   * getAllAsync
   * overrride to accomodate caching different queries
   * @param query 
   * @param size 
   * @param sorts 
   * @returns 
   */
   public async getAllAsync(query?: any, size?: number): Promise<Array<SentModule>> {
    let keyName = `getAllAsync:sentModule`;
    if(size){
        keyName+= `:${size}`;
    }
    if(!size){//the size per soldier grows fast, so let's set 2000 by default
        size = 2000;
    }
    if (!this.cacheService.shouldCache()) {
        return super.getAllAsync(query, size);
    } else if (!this.networkService.hasInitialized()) {
        return new Promise((resolve, reject) => {
            this.networkService.onConnectionChange$.subscribe(connection => {
                if (connection.connected) {
                    super.getAllAsync(query, size).then(data => {
                        this.cacheService.set(keyName, data)
                        resolve(data);
                    })
                } else {
                    return resolve(this.cacheService.get(keyName));
                }
            })
        })
    } else {
        if (this.networkService.isOnline()) {
            return super.getAllAsync(query, size).then(data => {
                this.cacheService.set(keyName, data)
                return data;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(this.cacheService.get(keyName))
            })
        }
    }

    }
}

@Injectable({
    providedIn: 'root'
})
export class GraphService extends ClientFuncService<Assessment> {
    modelName = 'graph';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'assessment'), userStateService);
    }

    public get(): Observable<any> {
        const params = {  };
        return this.postFunc('createAvg', params).pipe(
            map(response => {
                if (response.success) {
                    return response.data;
                } else {
                    // TODO:  HANDLE ERROR
                }
            }));
    }
}

@Injectable()
export class EmailMessageService {

    constructor(private messageService: BaseMessageService) {
    }

    public async sendEmail(sender, subject: string, content: string, recipientEmails: Array<string>): Promise<any> {
        const message = new Message();
        const senderInfo = this.buildSenderInfo(sender);
        message.Subject = subject;
        message.Content = `${senderInfo}<br><br>${content}`;
        message.From = 'dev@noctemhealth.com';
        message.Recipients = [];
        for (const emailAddress of recipientEmails) {
            message.Recipients.push({
                Id: null,
                Address: emailAddress,
                Type: 0,
                AcknowledgedOn: null
              });
        }
        const response = await this.messageService.send(message, null);
        return response;
    }

    private buildSenderInfo(sender: NoctemUser): string {
        return `Reported By: ${sender.profile.firstName} ${sender.profile.lastName}`;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private isInitialized = false;
    private socket        = null;
    public onMessageReceived$: Subject<{ id: string, model: string, payload: any }> = new Subject<any>();
    constructor() {
        
    }



    initialize(userId: string, socket) {
        this.socket = socket;
        if (!this.isInitialized) {
            const addMessages = (message) => {
                // $("#messages").append(`<h4> ${message.name} </h4> <p> ${message.message} </p>`)
                this.onMessageReceived$.next(message);
            };
        }

        this.isInitialized = true;
    }

    sendCTTData(data) {
        this.socket.emit('timedSession', data);
      }

    subscribe(channelName: string) {
        this.socket.on(channelName, (data) => {
            // TODO: Should we push out a unique id
            this.onMessageReceived$.next({ id: '123', model: 'MessageThread', payload: data });
        });
    }

    disconnect() {
        this.socket.disconnect(true);
    }


}

@Injectable({
    providedIn: 'root'
})
export class AssessmentDefinitionService extends ClientFuncService<AssessmentDefinition> {
    modelName = 'AssessmentDefinition';
    private isInitialized: boolean = false;
    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService, networkService, applicationContext, 'AssessmentDefinition'), userStateService);
    }

    public initialize() {
        var groupBasedAssessments = []
        if (!this.isInitialized) {
            const groups = ((this.userStateService.model.get().User as any)?.groups as SimpleInstance[]).map(o => o.display);
            this.getAll().pipe(take(1)).subscribe(defs =>{
                defs.forEach(def =>{
                    if(!def.groups || (def.groups && def.groups.length==0)){
                        if(!groupBasedAssessments.find(d => d === def.name)){
                            logDefinitions[logDefinitions.findIndex(ld => ld.name === def.name)] = def;
                        }
                    }
                    else if((def.groups && def.groups.find(g => groups.indexOf(g)>-1))){
                        groupBasedAssessments.push(def.name); // NOTE: no uniqueness checking
                        let prevDef = {...logDefinitions[logDefinitions.findIndex(ld => ld.name === def.name)]};
                        prevDef.name = prevDef.name+'-legacy';
                        logDefinitions.push(prevDef); //store previous one as *-legacy, again, no uniqueness checking so always override 
                        logDefinitions[logDefinitions.findIndex(ld => ld.name === def.name)] = def;
                    }
                })
            })    
        }
        this.isInitialized = true;
    }

    public getByName(name: string): any {
        return logDefinitions.find(d => d.name===name);
    }
}

function create_hash(arg0: string) {
    throw new Error('Function not implemented.');
}

@Injectable({
    providedIn: 'root'
})
export class TimedSessionReadingService extends ClientFuncService<TimedSession>{

    modelName = 'TimedSession';

    constructor(applicationContext: ApplicationContext, @Inject(USER_STATE_SERVICE) userStateService: UserStateService, networkService: NetworkService, cacheService: CacheService) {
        super(applicationContext, new BaseRepositoryCacheDecorator<any>(cacheService,
            networkService,
            applicationContext,
            'TimedSession'),
            userStateService);
    }

    public getTimedSessions(request) {
        // HTTP POST request to api/timedsession/session. TODO: perhaps there is a more descriptive endpoint?
        return this.postFunc(
            'session', request
        )

    }  
}
