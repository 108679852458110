import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const sleepAndHighTempoOps: AssessmentDefinition = {
  name: 'sleep-and-high-tempo-ops',
  description: 'Sleep and High Tempo Ops',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Getting limited or no sleep decreases performance',
      questionEmphasis: ``,
      subText: `<p>24 hours of no sleep is like performing drunk.</p>
                <p>If you have a brief opportunity for sleep now, do it.</p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'Here are some things you can do.',
      questionEmphasis: ``,
      subText: `<p>Get sleep <i>whenever</i> possible.</p>
                <p>Monitor for fatigue.</p>
                <p>Use caffeine strategically.</p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'Tip 1',
      questionEmphasis: ``,
      subText: `<p>Sleep whenever the operation permits and for as long as your duties allow. </p>
                <p>2-3 longer periods of sleep is ideal, but brief naps can also boost Warfighter sustainment and alertness.  </p>
                <p>Even a short (5-20 minute) nap can be beneficial. </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: 'Tip 2',
      questionEmphasis: ``,
      subText: `<p>Monitor yourself and your battle buddy for fatigue red flags.</p>
                <p>
                <table>
                  <tr>
                    <td>Frequent Yawns<td>
                    <td>Forgetfulness</td>
                  </tr>
                  <tr>
                    <td>Nodding Off<td>
                    <td>Irritable, Bad Mood</td>
                  </tr>
                  <tr>
                    <td>Difficulty Concentrating<td>
                    <td>Decreased Motivation</td>
                  </tr>
                </table>
                </p>
                <p>We are not a good judge of our own performance when fatigued and may think we are performing well when objectively we are not.</p>
                <p>If your battle buddy says you are showing signs of fatigue like nodding off, making errors, or irritable, get some hours as soon as possible. </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Tip 3',
      questionEmphasis: ``,
      subText: `
                <p>Use 200 mg of caffeine every 4 hours, as needed.</p>

                <p><b>For very limited sleep</b><br />
                Use 200 mg of caffeine regularly at night and into the morning (at 00:00, 04:00, and 08:00).</p>

                <p>If needed, also take 200 mg of caffeine during the day and early evening (at 12:00 and 16:00).</p>

                <p>If you can anticipate an upcoming opportunity for sleep, aim for no caffeine 6 hours prior to that time.</p>

      `,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'This tactic will lead you to success',
      questionEmphasis: ``,
      subText: `
        <p>Getting sleep augments your combat edge. It’s especially important for tasks involving more complex thinking; such as time-pressured decisions, managing change, and coordinating squad tactics. </p>
        <p>Tasks involving physical strength such as lifting, carrying, or digging are less impacted by sleep loss. Overtime, however, performance on these will also decline. </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    }
  ]
};
