import { TreatmentPhase } from '../state';
import { BaseObject, BaseRepository, UserSettings, Device, Name } from '../../core';
import { SafeHtml } from '@angular/platform-browser';
import { CheckboxChangeEventDetail } from '@ionic/core';

export interface Owner {
  Id: number;
  FirstName: string;
  LastName: string;
  Name: string;
  Gender: string;
  Dob?: any;
  HomeStreetAddress?: any;
  HomeState?: any;
  HomeZipCode?: any;
  HomeCountry?: any;
  EmailAddress?: any;
  CellPhoneNumber?: any;
  HomePhoneNumber?: any;
  UserName?: any;
  Password?: any;
  FitbitUserId?: any;
  FitbitClientKey?: any;
  FitbitClientSecret?: any;
  HasFitbit: boolean;
  LastFitbitSync?: any;
  UserId: number;
  Groups?: any;
}

export class Group extends BaseObject {
  id: string;
  name: string;
  description: string;
  isResearch: boolean;
  isConsentRequired?: boolean;
  coordinator?: SitePerson;
  pi?: SitePerson;
  doctor?: string;
  poc?: SitePerson;
  provider_name?: string;
  provider_contact_name?: string;
  provider_contact_number?: string;
  consentId: number;
  eulaId: number;
}

export class SitePerson {
  name: string;
  phone: string;
  email: string;
  title: string;
}

export async function hydrate<T>(instance: T, field: string): Promise<T> {
  const nonHydratedProperty: SimpleInstance = instance[field];
  const hydratedProperty = await new BaseRepository(
    null,
    nonHydratedProperty.model
  ).getAsync(nonHydratedProperty.id);
  instance[field] = hydratedProperty;
  return instance;
}

export class SimpleInstance {
  id: string;
  display: string;
  model: string;
}

export class UserSimpleInstance extends SimpleInstance {
  providers?: Array<any>;
}


export class SubstanceType {
  public static caffeinatedDrink: SubstanceType = {
    name: 'caffeinatedDrink',
    label: 'Caffeinated Drink'
  };
  public static alcoholicDrink: SubstanceType = {
    name: 'alcoholicDrink',
    label: 'Alcoholic Drink'
  };
  public static tobacco: SubstanceType = { name: 'tobacco', label: 'Tobacco' };
  public static cigarettes: SubstanceType = {
    name: 'cigarettes',
    label: 'Cigarettes'
  };
  name: string;
  label: string;
}

export class Substance {
  type: string;
  count: number;
}

export class Medicine extends BaseObject {
  id: string;
  name: string;
  // brand: string;
  brand: any;
  description: string;
}

export class MedicalPrescription extends BaseObject {
  id: string;
  patient: UserSimpleInstance;
  medicine: SimpleInstance;
  dose: string;
  instruction: string;
}

export class Distraction {
  type: string;
  count: number;
}

export class EventTimeType {
  public static dinner: EventTimeType = { name: 'dinner', label: 'Dinner' };
  public static breakfast: EventTimeType = {
    name: 'breakfast',
    label: 'Breakfast'
  };
  public static wakeup: EventTimeType = { name: 'wakeup', label: 'Wakeup' };
  public static getOutOfBed: EventTimeType = {
    name: 'getOutOfBed',
    label: 'Get out of Bed'
  };
  public static firstContact: EventTimeType = {
    name: 'firstContact',
    label: 'First Contact'
  };
  public static startWork: EventTimeType = {
    name: 'startWork',
    label: 'Start Work'
  };
  public static goToBed: EventTimeType = {
    name: 'goToBed',
    label: 'Go To Bed'
  };
  public static tryToSleep: EventTimeType = {
    name: 'tryToSleep',
    label: 'Try To Sleep'
  };
  name: string;
  label: string;

  /*
          wakeuptime: string;
      getoutbedtime: string;
      firstcontact: string;
      startwork: string;
      breakfasttime: string;
      dinnertime: string;
      gotobedtime: string;
      trytosleeptime: string;
  */
}

export class EventTime {
  type: string;
  time: string;
}

// TODO: Is SleepEvent better name?
export class OccurrenceCountType {
  public static numberOfDreams: OccurrenceCountType = {
    name: 'numberOfDreams',
    label: 'Number of Dreams'
  };
  public static numberOfNightmare: OccurrenceCountType = {
    name: 'numberOfNightmare',
    label: 'Number Of Nightmares'
  };
  public static numberOfBadDream: OccurrenceCountType = {
    name: 'numberOfBadDream',
    label: 'Number Of Bad Dreams'
  };
  public static numberOfPanicAttacks: OccurrenceCountType = {
    name: 'numberOfPanicAttacks',
    label: 'Number Of Panic Attacks'
  };
  public static wokeUpScreaming: OccurrenceCountType = {
    name: 'wokeUpScreaming',
    label: 'Woke Up Screaming'
  };
  name: string;
  label: string;

  /*

These are daily occurences
numberofdreams: string;
numberofnightmare: string;
numberofbaddream: string;
numberofpanicattack: string;
wokeupscreaming: string;

bathroomwokeups: string;
noisechildbedpartnerwokeups: string;
discomfortwokeups: string;
otherwokeups: string;

*/
}

export class OccurrenceCount {
  type: string;
  count: number;
}

export class MeasurementType {
  public static dreamPleasantness: MeasurementType = {
    name: 'dreamPleasantnesss',
    label: 'Dream Pleasantness'
  };
  public static dreamIntensity: MeasurementType = {
    name: 'dreamIntensity',
    label: 'Dream Intensity'
  };
  public static sleepQuality: MeasurementType = {
    name: 'sleepQuality',
    label: 'Sleep Quality'
  };
  public static mood: MeasurementType = { name: 'mood', label: 'Mood' };
  public static alertness: MeasurementType = {
    name: 'alertness',
    label: 'Alertness'
  };
  public static nightmareEmotionallyIntense: MeasurementType = {
    name: 'nightmareEmotionallyIntense',
    label: 'Nightmare Emotionally Intense'
  };
  public static nightmareVivid: MeasurementType = {
    name: 'nightmareVivid',
    label: 'Nightmare Vivid'
  };
  public static nightmareBother: MeasurementType = {
    name: 'nightmareBother',
    label: 'Nightmare Bother'
  };
  public static nigthmareRelateToEvent: MeasurementType = {
    name: 'nigthmareRelateToEvent',
    label: 'Nigthmare Relate To Event'
  };
  name: string;
  label: string;

  /*
Measurements
dreampleasantness: string;
dreamintense: string;
sleepquality: string;
mood: string;
alertness: string;

nightmareimotionallyintense
nightmarevivid
nightmarebother
nigthmarerelatetoevent
*/
}

export class Measurement {
  type: string;
  value: number;
}

export class Exercise {
  intensity: ExerciseIntensity;
  length: DurationLength;
}

export class SleepDate {
  date: Date;
  startDate: Date;
  endDate: Date;
  milliseconds: number;

  /*
  replaces
  bedtimedate: Date;
  bedtimestartdate: Date;
  bedtimeenddate: Date;

  waketimedate: Date;
  waketimestartdate: Date;
  waketimeenddate: Date;

  */
}

export class SleepDiary extends BaseObject {
  id: string;

  /*
  ownerkey: string;
  */

  owner: SimpleInstance;
  eventTimes: EventTime[];
  substances: Substance[];
  occurrenceCounts: OccurrenceCount[];
  measurements: Measurement[];
  medicines: SimpleInstance[];

  awakeningCause: AwakeningCause; // Why is this a standalone event?
  exercise: Exercise;

  minutesToFallAsleep: number;
  totalMinutesWokeups: number;

  // Can we convert this to a measurement?
  racingmind: boolean;

  bedTime: SleepDate;
  wakeTime: SleepDate;

  // SimpleInstance reference to Medicine
}

export class ClinicalIndicator {
  ownerId: number;
  firstName: string;
  lastName: string;
  indicator1: boolean;
  indicator2: boolean;
  indicator3: boolean;
  indicator4: boolean;
  indicator5: boolean;
  indicator6: boolean;
  indicator7: boolean;
  indicator8: boolean;
  indicator9: boolean;
  indicator10: boolean;
  indicator11: boolean;
  indicator12: boolean;
  indicator13: boolean;
  indicator14: boolean;
  indicator15: boolean;
  lastWeekAdherence: number;
  overallAdherence: number;
  weeklyAssessmentStatus: string;
}

export class SearchRecord<AttributeType> {
  Id: string;
  Name: string;
  Attributes: AttributeType;

  Brand: string;
  Category: string;
  SubCategory: string;
  Tags: string[];

  IndexedOn: string;
  Size: string[];
  Type: string;
  Specs: Array<Specification>;
}

export class Specification {
  name: string;
  value: any;
}

export class SearchSleepDailyOutcome extends SearchRecord<SleepDailyOutcomeAttributes> {
  public Id: string;
  public SleepDiaryId: string;
  public OwnerId: string;
  public Content: string;
  public Specs: Array<any>;
  public Attributes: SleepDailyOutcomeAttributes;
}

export class AireUser extends UserSettings {
  treatmentStartDate: string;
  constructor() {
    super();
    this.profile = new AireProfile();
    this.roles = new Array<UserRole>();
  }
  id: string;
  profile: AireProfile;
  pin: string;
  groups: SimpleInstance[];
  roles: Array<UserRole>;
  get rolesDisplay(): string {
    if (!this.roles || this.roles.length === 0) return 'None';
    else return this.roles[0].Label;
  }

  get assignedProvidersDisplay(): string {
    if (!this.profile.assignedProviders || this.roles.length === 0)
      return 'None';
    else
      return `${this.profile.assignedProviders[0].display}, ${this.profile.assignedProviders[0]?.display}`;
  }
}

export class BaseProfile {}

export class Profile extends BaseProfile {
  name: Name;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  device: Device;

  gender: string;
  dateOfBirth: string;
  age: number;
  height: number;
  weight: number;
  race: string[];
  ethnicity: string;
  maritalStatus: string;
  militaryStatus: string;
  branch: string;
  rank: string;
  externalId: string;
  diagnosticCodes: string[];
  isActive: boolean;
  disabledReason: string;
  alertTime?: string;
}

export class AireProfile extends BaseProfile {
  constructor() {
    super();
    this.assignedProviders = [];
  }
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  mos: string;
  deviceId: string;
  assignedProviders: Array<SimpleInstance>;
  trainingFeedbackComplete: boolean;
  trainingFeedbackDateComplete: any;
  postFieldComplete:boolean;
  preFieldComplete:boolean;
  preFieldSurveyComplete: boolean;
  preFieldSurveyDateComplete: any;
  quickstartComplete: boolean;
  alertTime?: string;
}

export class SecurityUser {
  PictureUrl: string;
  Organizations: Array<UserOrganization>;
  EncryptedPassword: string;
  Aliases: Alias[];
  _id: string;
  EmailAddress: string;
  PhoneNumber: string;
  Addresses: any[];
  Name: { First: string; Last: string };

  FailedLoginAttempts: number;
  LastLogin: Date;
  RegisteredOn: Date;
  PasswordIsExpired: boolean;

  CurrentOrganization(): any {
    throw new Error('Method not implemented.');
  }
}

export class Alias {
  Name: string;
  EncryptedPassword: any;
  IsPrimary: boolean;
}

export class UserRole {
  _id: string;
  Name: string;
  Label: string;
}

export class UserOrganization {
  _id: string;
  Roles: UserRole[];
  Name: string;
}

export enum NoctemUserReminderFrequencies {
  NEVER = 'never',
  MORNING = 'morning',
  EVENING = 'evening',
  MORNING_AND_EVENING = 'morning_and_evening'
}

export class NoctemUser extends UserSettings {
  id: string;
  UserId: string;
  profile: Profile;

  lastview: string;
  diaryReminderFrequency: NoctemUserReminderFrequencies;
  fitbitUserId: string;
  emergencyContacts: EmergencyContact[];
  additionalProviders: AdditionalProviders[];
  assignedClinician: SimpleInstance[];
  treatmentStartDate: string;
  groups: SimpleInstance[];
  pin: string;
  lastSentDate: MessageThread;
  timeZoneOffset: number;

  unavailability: ClinicianUnavailability[];
  unavailabilityMessage: string;

  applicationData?: {
    pointsAccumulated: number;
    consentObtainedOn: string;
    eulaAgreedOn: string;
    quickStartReadDate: string;
    sleepEducationReadDate?: string;
    sleepDisturbancesEducationReadDate?: string;
    sleepTreatmentEductationReadDate?: string;
    quickStartAfterBaselineReadDate?: string;
  };
}

export class AdditionalProviders {
  firstName: string;
  lastName: string;
  email: string;
  org: string;
}

export class EmergencyContact {
  firstName: string;
  lastName: string;
  email: string;
  relation: string;
}

export class ClinicianUnavailability{
  startTime: string;
  endTime: string;
  //array of boolean where first element is for Sunday (getDay() == 0), second is Monday, ..., last is Saturday (getDay() == 6)
  days: boolean[];
  allDay: boolean;

  constructor(startTime, endTime, days){
    this.startTime = startTime;
    this.endTime = endTime;
    this.days = days;
    this.allDay = false;
  }

  get daysDisplay() {
    const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let result = ""
    let prev = false;
    for(let i = 0; i < 7; i++){
      if(prev){
        if((!this.days[i] || i == 6) && !result.includes(names[i - 1])){
          result = result.slice(0, -2);
          result += " - "+ names[i - 1] + ", ";
        }
        if(this.days[i] && i == 6){
          result = result.slice(0, -2);
          result += " - "+ names[i] + ", ";
        }
      }
      else{
        if(this.days[i]){
          result += names[i] + ", ";
        }
      }
      prev = this.days[i];
    }
    return result.slice(0, -2);
  }

  get isDaySelected() {
    return this.days.some(a => a);
  }

  get isValid() {
    return ((this.startTime && this.startTime.length>0 && this.endTime && this.endTime.length > 0) || this.allDay) && this.isDaySelected; //assuming 24 hours, need revisit otherwise
  }
}

export class SleepDailyOutcomeAttributes {
  public ownerId: string;
  public firstName: string;
  public lastName: string;
  public gender: string;
  public bedTimeDate: string;
  public goToBedTime?: string;
  public goodNightTime?: string;
  public goodMorningTime?: string;
  public getoutBedTime?: string;
  public latency?: number;
  public timeInBed?: number;
  public wakeTimeAfterSleepOnset?: number;
  public asleep?: number;
  public sleepEfficiency?: number;
  public sleepQuality?: number;
  public minutesAwakeInBed?: number;
  public restless: number;
  public nightmare: number;
  public thinking: number;
  public clinicalIndicator: ClinicalIndicator;
  public icon: outcomeIcon;
  public id: string;
  public userString: string;
  public status: string;
  public flags: Array<outcomeFlag>;
  public flagsCount: number;
  public pgi: string;
  public isi: string;
  public ess: string;
  public efficiency: number;
  public quality: number;
  public lastViewed: string;
  public adherence: number;
  public message: boolean;
  public position: string;
  public devices: Array<any>;
}

export class outcomeFlag {
  num: number;
  name: string;
  desc: string;
  isActive: boolean;
}

export class outcomeIcon {
  trendingUp: boolean;
  trendingDown: boolean;
  isWatched: boolean;

  constructor() {
    this.trendingUp = false;
    this.trendingDown = false;
    this.isWatched = true;
  }
}

export class EvaluationTerm {
  public static none: EvaluationTerm = { name: 'none', value: '' };
  public static sleepDiary: EvaluationTerm = { name: 'sleepDiary', value: '1' };
  public static wakeUpAlarm: EvaluationTerm = {
    name: 'wakeUpAlarm',
    value: '2'
  };
  public static return: EvaluationTerm = { name: 'return', value: '3' };
  public static weekOne: EvaluationTerm = { name: 'weekOne', value: '4' };
  public static weekTwo: EvaluationTerm = { name: 'weekTwo', value: '5' };
  public static weekThree: EvaluationTerm = { name: 'weekThree', value: '6' };
  public static weekFour: EvaluationTerm = { name: 'weekFour', value: '7' };
  public static followUp: EvaluationTerm = { name: 'followUp', value: '8' };
  public static followUpTwo: EvaluationTerm = {
    name: 'followUpTwo',
    value: '9'
  };
  public static weekFive: EvaluationTerm = { name: 'weekFive', value: '10' };
  public static followUpThree: EvaluationTerm = { name: 'Week1', value: '11' };
  name: string;
  value: string;
  // How should we describe the time range?  0 - 7 days
}

export class AssessmentStatus {
  public static ACTIVE: EventTimeType = { name: 'ACTIVE', label: 'Active' };
  public static TBD: EventTimeType = { name: 'TBD', label: 'TBD' };
  public static LOGGED: EventTimeType = { name: 'LOGGED', label: 'Logged' };
  public static EXPIRING: EventTimeType = {
    name: 'EXPIRING',
    label: 'Expiring'
  };
  public static MISSED: EventTimeType = { name: 'MISSED', label: 'Missed' };
  public static PRE_REGISTRATION: EventTimeType = { name: 'N/A', label: 'N/A' };
  name: string;
  label: string;
}

export class AssessmentTemplate {
  // Taken from 'Patient App Questionairres' doc
  public static consent: AssessmentTemplate = {
    name: 'consent',
    label: 'Consent',
    evaluationTerms: [EvaluationTerm.weekOne],
    questions: []
  };
  public static gad2: AssessmentTemplate = {
    name: 'gad2',
    label: 'GAD-2',
    evaluationTerms: [],
    questions: []
  };
  public static phq2: AssessmentTemplate = {
    name: 'phq2',
    label: 'PHQ-2',
    evaluationTerms: [],
    questions: []
  };
  public static pcs: AssessmentTemplate = {
    name: 'pcs',
    label: 'PCS',
    evaluationTerms: [],
    questions: []
  };
  public static ess: AssessmentTemplate = {
    name: 'ess',
    label: 'ESS',
    evaluationTerms: [],
    questions: []
  };
  public static essWeekly: AssessmentTemplate = {
    name: 'essWeekly',
    label: 'ESS Weekly',
    evaluationTerms: [],
    questions: []
  };
  public static isi: AssessmentTemplate = {
    name: 'isi',
    label: 'Insomnia Sleep Index',
    evaluationTerms: [],
    questions: []
  };
  public static sideEffects: AssessmentTemplate = {
    name: 'sideEffects',
    label: 'Side Effect Monitoring',
    evaluationTerms: [],
    questions: []
  }; // Used to be Asberg
  public static cgi: AssessmentTemplate = {
    name: 'cgi',
    label: 'Clinician General Impression',
    evaluationTerms: [],
    questions: []
  };
  public static pgi: AssessmentTemplate = {
    name: 'pgi',
    label: 'Patient General Impression',
    evaluationTerms: [],
    questions: []
  }; // TODO:  VALIDATE LABEL
  public static morningLog: AssessmentTemplate = {
    name: 'morningLog',
    label: 'Morning Sleep Log',
    evaluationTerms: [],
    questions: []
  };
  public static eveningLog: AssessmentTemplate = {
    name: 'eveningLog',
    label: 'Evening Sleep Log',
    evaluationTerms: [],
    questions: []
  };
  public static shiftWork: AssessmentTemplate = {
    name: 'shiftWork',
    label: 'Shift Work Questionairre',
    evaluationTerms: [],
    questions: []
  };
  public static peg: AssessmentTemplate = {
    name: 'peg',
    label: 'PEG',
    evaluationTerms: [],
    questions: []
  };
  public static promis: AssessmentTemplate = {
    name: 'promis',
    label: 'PROMIS',
    evaluationTerms: [],
    questions: []
  };
  public static sleepGoalsPre: AssessmentTemplate = {
    name: 'sleepGoalsPre',
    label: 'Sleep Goals and Anticipated Barriers (PRE)',
    evaluationTerms: [],
    questions: []
  };
  public static sleepGoalsPost: AssessmentTemplate = {
    name: 'sleepGoalsPost',
    label: 'Sleep Goals and Anticipated Barriers (POST)',
    evaluationTerms: [],
    questions: []
  };
  public static gad7: AssessmentTemplate = {
    name: 'gad7',
    label: 'GAD-7',
    evaluationTerms: [],
    questions: []
  };
  public static phq8: AssessmentTemplate = {
    name: 'phq8',
    label: 'PHQ-8',
    evaluationTerms: [],
    questions: []
  };
  public static medicationUse: AssessmentTemplate = {
    name: 'medicationUse',
    label: 'Medication Use Questions',
    evaluationTerms: [],
    questions: []
  };
  public static sus: AssessmentTemplate = {
    name: 'sus',
    label: 'System Usability Scale (SUS)',
    evaluationTerms: [],
    questions: []
  };
  public static sus2: AssessmentTemplate = {
    name: 'sus2',
    label: 'System Usability Scale (SUS) Part 2',
    evaluationTerms: [],
    questions: []
  };
  public static pcl5: AssessmentTemplate = {
    name: 'pcl5',
    label: 'PCL-5',
    evaluationTerms: [],
    questions: []
  };
  public static pcPtsd: AssessmentTemplate = {
    name: 'pcPtsd5',
    label: 'PC-PTSD5',
    evaluationTerms: [],
    questions: []
  };
  public static swps: AssessmentTemplate = {
    name: 'swps',
    label: 'Advanced (ASWPS) Delayed Sleep-Wake Phase Syndrome (DSWPS)',
    evaluationTerms: [],
    questions: []
  };
  public static swps2: AssessmentTemplate = {
    name: 'swps2',
    label: 'Advanced (ASWPS) Delayed Sleep-Wake Phase Syndrome (DSWPS)',
    evaluationTerms: [],
    questions: []
  };
  public static swps3: AssessmentTemplate = {
    name: 'swps3',
    label: 'Advanced (ASWPS) Delayed Sleep-Wake Phase Syndrome (DSWPS)',
    evaluationTerms: [],
    questions: []
  };
  public static swps4: AssessmentTemplate = {
    name: 'swps4',
    label: 'Advanced (ASWPS) Delayed Sleep-Wake Phase Syndrome (DSWPS)',
    evaluationTerms: [],
    questions: []
  };
  public static trauma: AssessmentTemplate = {
    name: 'trauma',
    label: 'Trauma and Stress',
    evaluationTerms: [],
    questions: []
  };
  public static osa: AssessmentTemplate = {
    name: 'osa',
    label: 'Obstructive Sleep Apnea',
    evaluationTerms: [],
    questions: []
  };
  public static osaDetailsTrue: AssessmentTemplate = {
    name: 'osaDetailsTrue',
    label: 'Obstructive Sleep Apnea Details',
    evaluationTerms: [],
    questions: []
  };
  public static osaDetailsFalse: AssessmentTemplate = {
    name: 'osaDetailsFalse',
    label: 'Obstructive Sleep Apnea Details',
    evaluationTerms: [],
    questions: []
  };
  public static nsi: AssessmentTemplate = {
    name: 'nsi',
    label: 'Neurobehavioral Symptom Inventory',
    evaluationTerms: [],
    questions: []
  }
  public static niqM: AssessmentTemplate = {
    name: 'niqm',
    label: 'Noctem Insomnia Questionnaire (NIQ)',
    evaluationTerms: [],
    questions: []
  }
  public static niqW: AssessmentTemplate = {
    name: 'niqw',
    label: 'Noctem Insomnia Questionnaire (NIQ)',
    evaluationTerms: [],
    questions: []
  }
  public static nps: AssessmentTemplate = {
    name: 'nps',
    label: 'Net Promoter Score',
    evaluationTerms: [],
    questions: []
  }
  public static demographics: AssessmentTemplate = {
    name: 'demographics',
    label: '',
    evaluationTerms: [],
    questions: []
  }
  public static propr: AssessmentTemplate = {
    name: 'propr',
    label: '',
    evaluationTerms: [],
    questions: []
  }
  public static pssuq: AssessmentTemplate = {
    name: 'pssuq',
    label: '',
    evaluationTerms: [],
    questions: []
  }
  public static sleepHealthTools: AssessmentTemplate = {
    name: 'sleepHealthTools',
    label: '',
    evaluationTerms: [],
    questions: []
  }
  public static cicadaSessions: AssessmentTemplate = {
    name: 'cicadaSessions',
    label: '',
    evaluationTerms: [],
    questions: []
  }

  // Intermountain
  public static phq3: AssessmentTemplate = {
    name: 'phq3',
    label: 'PHQ-3',
    evaluationTerms: [],
    questions: []
  };
  public static phq9: AssessmentTemplate = {
    name: 'phq9',
    label: 'PHQ-9',
    evaluationTerms: [],
    questions: []
  };

  name: string;
  label: string;
  questions: Array<AssessmentQuestion>;
  evaluationTerms: EvaluationTerm[];
}

// We're replacing Cgi, Asberg, WeeklyAssesment with Questionnaire type

export class AssessmentQuestion {
  id: number;
  name?: string;
  questionText: string;
  questionType: string;
  questionHeader?: boolean;
  postText?: string;
  autofill: boolean | string;
  answerOptions: Array<{ display: string; value: any; fillin?: string; isChecked?: boolean; clearOtherAnswers?: boolean }>;
  uniqueAnswerId?: string;
  answer?: any;
  subText?: string;
  conditionalQuestions?: Array<AssessmentQuestion>;
  displayIfAnswerEquals?: any;
  questionEmphasis?: string;
  assessmentName?: string;
  defaultAnswer?: string;
  showNotApplicableHint?: boolean;
  showPreferNotToAnswer?: boolean;
  step?: number; // These next 3 values are for slider questions. step = interval the slider moves. min = slider minimum value. max = slider maximum value
  min?: number;
  max?: number;
  conditionalQuestionFor?: number; // Flag indicating what question number this is a conditional question for. Starting at question 0
  multipleQuestionArray?: Array<AssessmentQuestion>; // This differs from conditionalQuestions. This array will hold the questions for a question that requires multiple answers
  redirectMap?: Map<number | string | boolean, number>;
  redirectOptions?: any;
  validation?: AssessmentQuestionValidation;
  conditionalQuestionLayout?: string;
  conditionalQuestionOptions?: any;
  invertValues?: boolean;
  isCheckInQuestion?: boolean;
  dropdownAnswerOptions?: Array<{ display: string; value: any; fillin?: string; isChecked?: boolean; clearOtherAnswers?: boolean }>;
  redirectMustMeetSpecificValues?: boolean;
  followUpTemplateQuestions?: Array<AssessmentQuestion>;
}

export class AssessmentQuestionValidation {
  maxLength: number;
}

export class AssessmentAnswer {
  public index: number;
  public value: any;
  public fillin?: string;
  public uniqueAnswerId?: string;
  public conditionalQuestionAnswers?: AssessmentAnswer[];
  public multipleQuestionArrayAnswers?: AssessmentAnswer[];
  public isChecked?: boolean;
  public clearOtherAnswers?: boolean;
}

export class AssessmentGroup {
  type: string;
  answers: AssessmentAnswer[];
}

export class Assessment extends BaseObject {
  Id: number;
  id: string;
  // ownerkey: string;

  // TODO: find a name that works for patient or clinician
  // This allows CGI to use this model as well
  user: UserSimpleInstance;
  name?: string;
  comment: string;

  evaluationTerm: string; // legacy field for older app versions
  phaseName: string;
  startTime: Date;
  endTime: Date;
  timeZoneOffset: number;
  assessmentDate: string;

  groups: AssessmentGroup[];
  isIncomplete?: boolean;

  percentageComplete: number;
  moduleId?: number;
  isProviderRecommended?: boolean;
  lastPage?: number;
}

export enum ModuleSentBy {
  Medic,
  AIRE
}

export class SentModule extends BaseObject {
  id: number;
  sendDate: Date;
  medicId: string;
  sentBy:number;
  soldierId:number;
}

export class AdherenceData {
  constructor() {
    this.modulesData = new Map<number, ModuleAdherenceData>();
  }

  overallAdherence: number = 0;
  overallSoldierResponses:number = 0;
  overallSoldierAireResponses: number = 0;
  overallSoldierMedicResponses:number = 0;
  overallMedicSentModules: number = 0;
  overallAireSentModules: number = 0;
  overallSentModules: number = 0;
  overallAireAdherence: number = 0;
  overallMedicAdherence: number = 0;

  modulesData: Map<number, ModuleAdherenceData>;
}

export class ModuleAdherenceData {
  medicAdherencePercent:number = 0;
  aireAdherencePercent: number = 0;
  soldierMedicResponses:number = 0;
  soldierAireResponses:number = 0;
  totalDaysSinceStartDate: number = 0;
  medicSentCount: number = 0;
  aireSentCount: number = 0;
  lastMedicSent: string = '-';
  lastAireSent: string = '-';
  label: string = '';
  id:number;
}

export class UserAction {
  userActionType: string;
  displayText: string;
  subText?: string;
  evaluationTerm?: TreatmentPhase;
  phaseName?: TreatmentPhase;
  route: string;
  questions: Array<AssessmentQuestion>;
  isComplete?: boolean;
  isExpired?: boolean;
}

export class UserActionType {
  public static assessment: UserActionType = {
    name: 'assessment',
    label: 'Complete Assessment'
  };
  public static baselineAssessment: UserActionType = {
    name: 'baselineAssessment',
    label: 'Complete Baseline Assessment'
  };
  public static logs: UserActionType = { name: 'logs', label: 'Complete Logs' };
  public static readItem: UserActionType = {
    name: 'readItem',
    label: 'Read Item'
  };
  public static acknowledge: UserActionType = {
    name: 'acknowledge',
    label: 'Acknowledge'
  };
  public static quickStart: UserActionType = {
    name: 'quickStart',
    label: 'Read Quick Start Guide'
  };
  name: string;
  label: string;
}

export const NotificationTypes = {
  earnedPoints: 'earnedPoints',
  activeLog: 'activeLog',
  activeEveningLog: 'activeEveningLog',
  activeMorningLog: 'activeMorningLog',
  lateLog: 'lateLog',
  newChatMessage: 'newChatMessage',
  checkinNeedsCompleted: 'checkinNeedsCompleted',
  sleepRecommendation: 'sleepRecommendation',
  assessmentAvailable: 'assessmentAvailable',
  newCoin: 'newCoin',
  moduleMessage: 'moduleMessage'
};

export class Notification extends BaseObject {
  Id: number;
  text: string;
  subtext: string;
  type: string; // TODO: define notification type
  isRead: boolean;
  createdOn: string;
  patientReadDate: string;
  patient: UserSimpleInstance;
  url?: string;
}

export class SleepPrescription extends BaseObject {
  id: string;
  createdOn: string;
  lastModified: string;
  startDate: string;
  endDate: string;
  patient: SimpleInstance;
  prescriber: string;
  bedTime: string;
  sBedTime: string;
  riseTime: string;
  sRiseTime: string;
  note: string;
  docType: string;
  sDocType: string;
  isRestless: boolean;
  isSRestless: boolean;
  isThinking: boolean;
  isNightmare: boolean;
  isSThinking: boolean;
  isSNightmare: boolean;
  timeZoneOffset: number;
  clientTimeZoneOffset: number;
  isNightmareReadOn: string;
  isThinkingReadOn: string;
  isRestlessReadOn: string;
  isClockReadOn: string;
  isStressReadOn: string;
  isFatigueReadOn: string;
  patientFlags?: Array<SleepPrescriptionFlag>;
  phaseName?: string;
  weekType?: string; // legacy field for older patient apps
  notificationReadOn?: string;
  needsNotificationSaved: boolean;
  name?: string;
  notesLastSignedOn: string;
  notesLastSignedBy: string;

  // TODO: revisit
  riseTimeHour: number;
  riseTimeMinute: number;
  bedTimeHour: number;
  bedTimeMinute: number;
}

export class SleepPrescriptionHistory {
  bedTime: string;
  riseTime: string;
}

export class TreatmentPhaseHistory {
  phase: string;
  displayName: string;
  sleepPrescription: boolean | SleepPrescriptionHistory;
  startDate?: string | Date;
  endDate?: string | Date;
}
export class SleepPrescriptionFlag {
  label: string;
  name: string;
  isSelected: boolean;
  isRecommended: boolean;
}

export class MessageThread {
  Id: string;
  from: SimpleInstance;
  recipients: SimpleInstance[];
  subject: string;
  body: string;
  sentOn: Date;
  responses: MessageThread[];
  readDateTime: Date;
  respondingClinicianId: string;
}

export class TimeTracking {
  Id: string;
  activityContext: {
    activityType: string,
    clinicianId: string,
    patientId: string,
    isEULASigned: boolean
  };
  startTimeMs: number;
  endTimeMs: number;
  totalSeconds: number;
}

export class CalendarEvent extends BaseObject {
  id: string;
  user: SimpleInstance;
  title: string;
  startDate: Date | string;
  endDate: Date | string;
  phaseName: string;
  color: string;
  startTime: string;
  endTime: string;
  isDownloaded: boolean;
  isComplete: boolean;
  isExpired: boolean;
  completedOn: string;
  timeZoneOffset = 240; // if not specificied, default to EST
  repeatTimes: number;
  repeatType: number;
  pointsNotificationReadOn?: string;
  isLegacy?: boolean;
}

export class ExtraMeta extends BaseObject {
  name: string;
  createDate: string;
  payload: any;
}

/**
 *
 */
export class ReportDefinition extends BaseObject {
  public id: number;
  public name: string;
  public title: string;
  public pageTemplates: Array<any>;
  public dataMapping: Array<any>;
}

/**
 *
 */
export class ReportDataMappingBase {
  fieldName: string;
  source: ReportDataSource;
}

/**
 *
 */
export class ReportDatabaseCollectionDataMapping extends ReportDataMappingBase {
  modelName: string;
  propertyName: string;
  dateField?: string;
}

/**
 *
 */
export class ReportAssessmentDataMapping extends ReportDataMappingBase {
  assessmentName: string;
  uniqueAnswerId: string;
}

/**
 *
 */
export class ReportViewer {
  title: string;
  pages: Array<{name:string, content:SafeHtml, required:boolean}>;
}

/**
 *
 */
export enum ReportDataSource {
  Assessment,
  DataBaseCollection
}





export enum AwakeningCause {
  Alarm,
  Somebody,
  Noise,
  Myself,
}

enum ExerciseIntensity {
  Light,
  Moderate,
  Intense,
}

enum DurationLength {
  LessEqual30,
  LessEqual60,
  MoreThan60,
}

