import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition, AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const sleepCheckInFields: Array<FieldDefinitionBase> =
[

new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'sleepy-feels',
  unit: UnitType.Number,
  abbreviation: 'Sleepy Feels',
  description: `Rate how sleepy you feel`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEPY_FEELS'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'sleep-latency',
  unit: UnitType.Minutes,
  abbreviation: 'Sleep Latency',
  description: `Sleep Latency`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEP_LATENCY'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'used-caffeine',
  unit: UnitType.Number,
  abbreviation: 'Used caffeine',
  description: `Used caffeine`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'USED_CAFFEINE'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-amount-1',
  unit: UnitType.Number,
  abbreviation: 'Used caffeine',
  description: `Used caffeine`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_AMOUNT_1'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-amount-2',
  unit: UnitType.Number,
  abbreviation: 'Used caffeine',
  description: `Used caffeine`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_AMOUNT_2'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-amount-3',
  unit: UnitType.Number,
  abbreviation: 'Used caffeine',
  description: `Used caffeine`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_AMOUNT_3'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-amount-4',
  unit: UnitType.Number,
  abbreviation: 'Used caffeine',
  description: `Used caffeine`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_AMOUNT_4'
}),



new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'disrupted-sleep',
  unit: UnitType.Multi,
  abbreviation: 'What disrupted sleep',
  description: `What disrupted sleep`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEP_DISRUPTED'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'prevented-sleep',
  unit: UnitType.Multi,
  abbreviation: 'What prevented sleep',
  description: `What prevented sleep`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'WHAT_PREVENTED_SLEEP'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'slept-past-24',
  unit: UnitType.Number,
  abbreviation: 'Slept in past 24 hours',
  description: `Slept in past 24 hours`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEPT_IN_PAST_24'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'total-sleep-past-24',
  unit: UnitType.Time,
  abbreviation: 'Total sleep in past 24 hours',
  description: `Total sleep in past 24 hours`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'TOTAL_SLEEP_HOURS_PAST_24'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'sleep-quality',
  unit: UnitType.Number,
  abbreviation: 'Sleepy quality',
  description: `Sleepy quality`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEP_QUALITY'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-sources',
  unit: UnitType.Number,
  abbreviation: 'Caffeine sources',
  description: `Caffeine sources`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_SOURCES'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'caffeine-amount',
  unit: UnitType.Number,
  abbreviation: 'Caffeine amount',
  description: `Caffeine amount`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'CAFFEINE_AMOUNT'
}),
new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'sleep-interruption-total',
  unit: UnitType.Number,
  abbreviation: 'Sleep Interruption Total',
  description: `Sleep Interruption Total`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEP_INTERRUPTION_TOTAL'
}),

new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'bed-time',
  unit: UnitType.Time,
  abbreviation: 'Try to sleep',
  description: `Try to sleep`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'SLEEP_LONGEST_SLEEP'
}),

new AssessmentFieldDefinition({
  _type: FieldType.Assessment,
  name: 'wake-time',
  unit: UnitType.MorningTime,
  abbreviation: 'Wake Time',
  description: `Wake Time`,
  assessmentType: LogDefinitionTypes.SCI,
  answerId: 'WAKE_TIME'
}),

new AssessmentGroupScoreFieldDefinition({
  _type: FieldType.AssessmentGroupScore,
  name: 'isi-overall',
  unit: UnitType.Number,
  abbreviation: '',
  description: ``,
  assessmentType: LogDefinitionTypes.ISI
}),
];


