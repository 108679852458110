import { Subject, zip } from 'rxjs';
import { uniq as _uniq } from 'lodash';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MessageThreadService } from '../services/MessageThreadService';
import { SocketService, SearchService } from '../services/services';
import { MessageThreadState } from './stateModels';
import { BaseStateService } from './base-state.service';
import { ModelFactory, Organization, ApplicationContext } from '../../core';
import { MessageThread } from '../services/models';
import { APPLICATION_ORGANIZATION } from '../../constants/constants';
import { NetworkService } from '../../../../noctem-lib/src/lib/services/network-service';
/*

    The MessageThreadStateService extends the SearchStateService
    All records displayed / paged / etc are through the SearchStateService
    The records can optionally be searched and or filtered by the client side implementation
    It also exposes the ability to create new messages and listen for new messages
    via Websockets.  New messages are not received in their entirety, so that
    analysis can happen client side on whether or not fetching full record is
    necessary.

*/
@Injectable({
  providedIn: 'root'
})
export class MessageThreadStateService<T extends MessageThreadState> extends BaseStateService<T> {
  private updateOnReceive = true;

  private isSubscribed: string[] = [];
  private isInitialized = false;
  private model = 'MessageThread';
  public onMessageSent$: Subject<boolean> = new Subject<boolean>();
  public onMessageReceived$: Subject<MessageThread> = new Subject<MessageThread>();

  constructor(
    stateFactory: ModelFactory<T>,
    private searchService: SearchService,
    private socketService: SocketService,
    protected messageThreadService: MessageThreadService,
    private networkService: NetworkService,
  ) {
    super(new MessageThreadState() as T, stateFactory, null);
  }

  initialize(currentUserId: string, groups?: Organization[]) {
    // Load existing messages
    if (!this.isInitialized) {
      const state = this.stateModel.get();
      state.currentUserId = currentUserId;
      state.isPatient = !groups;
      // Initialize socket service
      let socket = this.networkService.getSocket();
      const query = `userId=${encodeURIComponent(currentUserId)}&token=${encodeURIComponent(localStorage.getItem('token'))}`;
      socket.ioSocket.io.opts.query = query;

      socket.on('connect', () => {
        socket.emit('register', { userId: currentUserId, token: localStorage.getItem('token')});
      })

      this.socketService.initialize(currentUserId, socket);
      // How do we know we care about this message here?
      this.socketService.onMessageReceived$
        .pipe(filter(m => m.model === this.model))
        .subscribe(m => {
          // These are all socket.io MessageThread emissions
          this.handleIncomingMessage(m.payload);
        });

      this.stateModel.set(state);
      this.isInitialized = true;
    }
    // Subscribe to messages via socket io
    // When a new message comes in, add it to current list of messages,
    // or fetch?
    // TODO: don't subscribe to same channels over and over again
    // If the user is a Patient, they just receive 1:1 messages which are published to their token
    // If it's a Clinician - they receive messages to whatever their group is
    if (!groups) {
      this.subscribe(localStorage.getItem('token'));
    } else {
      groups.map(g => {
        if (g._id !== APPLICATION_ORGANIZATION._id) {
          this.subscribe((g as any).Name);
          this.subscribe((g as any)._id);
        }
      });
    }

    let groupIds: string[] = null;
    if (groups) {
      groupIds = groups.map(g => g._id);
    }
    this.load(groupIds);
  }

  private subscribe(channelName) {
    if (this.isSubscribed.indexOf(channelName) < 0) {
      this.isSubscribed.push(channelName);
      this.socketService.subscribe(channelName);
    }
  }

  handleIncomingMessage(rawMessage: MessageThread) {
    const state = this.stateModel.get();
    this.stateModel.set(state);
    // This is where we fetch the latest messages
    setTimeout(() => {
      // Clear is typing, we have all latest now
      if (!state.messages) {
        state.messages = [];
      }
      state.rawMessages.push(rawMessage);
      this.stateModel.set(state);

      if (this.updateOnReceive) {
        this.updateMessagesOnReceive(rawMessage);
      }
      this.onMessageReceived$.next(rawMessage);
    }, 100);
  }

  disconnect() {
    // Update state to reflect disconnected
    // This could be used to connect / disconnect when a user leaves a page that
    // supports chat - removing unnecessary work
    this.socketService.disconnect();
  }

  // This triggers everything else
  load(groupIds?: string[]) {
    // Using the current state, add more
    const page = 0;
    let state = this.stateModel.get();

    const filtersFrom = [];
    if (state.currentUserId && state.currentUserId && state.isPatient) {
      const filter = {
        property: 'Specs.fromUserId',
        filterType: 'in',
        values: [state.currentUserId],
        isNameValuePair: true
      };
      filtersFrom.push(filter);
    }
    if (state.recipientId) {
      const filter = {
        property: 'Specs.recipientUserId',
        filterType: 'in',
        values: [state.recipientId],
        isNameValuePair: true
      };
      filtersFrom.push(filter);
    }

    if (groupIds) {
      filtersFrom.push({
        property: 'Specs.groupId',
        filterType: 'in',
        values: groupIds,
        isNameValuePair: true
      });
    }

    const filtersTo = [];
    if (state.groupIds) {
      const filter = {
        property: 'Specs.recipientUserId',
        filterType: 'in',
        values: [state.groupIds],
        isNameValuePair: true
      };
      filtersTo.push(filter);
    }

    if (state.recipientId) {
      const filter = {
        property: 'Specs.fromUserId',
        filterType: 'in',
        values: [state.recipientId],
        isNameValuePair: true
      };
      filtersTo.push(filter);
    }

    if (state.currentUserId && state.currentUserId && state.isPatient) {
      const filter = {
        property: 'Specs.recipientUserId',
        filterType: 'in',
        values: [state.currentUserId],
        isNameValuePair: true
      };
      filtersTo.push(filter);
    }
    if (groupIds) {
      filtersTo.push({
        property: 'Specs.groupId',
        filterType: 'in',
        values: groupIds,
        isNameValuePair: true
      });
    }

    // Get the distinct users and set the recipients
    const sort = [{ property: 'Specs.sentOn', direction: 'desc', isNameValuePair: true }];

    const obs1 = this.searchService.getRecords({
      model: this.model,
      filters: filtersFrom,
      size: 10000,
      sort
    });
    const obs2 = this.searchService.getRecords({
      model: this.model,
      filters: filtersTo,
      size: 10000,
      sort
    });

    zip(obs1, obs2).subscribe(data => {
      const state = this.stateModel.get();
      const records = data[0].Records;
      records.push(...data[1].Records);
      state.Records = records;

      const result = state.Records.reduce((r, obj) => {
        if (obj.Attributes.from.id !== state.currentUserId && !state.respondingClinicianId) {
          // Get the "from" id of the last clinician message
          state.respondingClinicianId = obj.Attributes.from.id;
        }

        return r.concat(obj.Attributes.recipients);
      }, []);

      if (!state.recipientId) {
        state.recipientIds = _uniq(result.map(r => r.id));
      }
      this.setState(state);

      this.onRecordsSet();
    });
  }

  send(message: string, recipientUserId: string) {
    // Post message to server
    // We're going to need some MessageThreadService for this
    const state = this.stateModel.get();

    // If there is no recipientUserId, then this is being sent from a patient
    // to a clinician group, so set the recipientUserId to the respondingClinicianId
    if (!recipientUserId) {
      recipientUserId = state.respondingClinicianId;
    }

    this.messageThreadService.send(message, recipientUserId, state.isPatient).subscribe(res => {
      this.onMessageSent$.next(true);
    });
  }

  filterByUser() {
    // Return comments by user
  }

  updateMessagesOnReceive(rawMessage: MessageThread) { }
  onRecordsSet() { }
}
