import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentAnswer, AssessmentQuestion } from '../../services/models';

@Component({
  selector: 'radio-group-question',
  templateUrl: './radio-group-question.component.html',
  styleUrls: []
})
export class RadioGroupQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Input() previousValue;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  radioSelected: any = null;
  
  constructor() { }

  ngOnInit() {
    if (this.hasOwnProperty('previousValue') && this.previousValue !== null) {
      this.radioSelected = this.previousValue;
    }
    
  }

  valueUpdated(e: any, answer: any) {
    this.radioSelected = answer.value;
    this.answerUpdated.emit(answer.value);
  }

}
