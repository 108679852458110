import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { sleepCheckin } from '../questions/sleep-check-in';
import { gad7 } from '../questions/gad7';
import { vacds } from '../questions/vacds';
import { vactcs } from '../questions/vactcs/vactcs';

export const sleepCheckIn: AssetDefinition = {
  id: 10,
  name: 'sleep-check-in',
  label: 'Daily Check-In',
  showInLibrary: false,
  readOnly: false,
  categories: [],
  isCheckIn: true,
  checkInFrequency: 1,
  assessments: [
    sleepCheckin, gad7, vacds, vactcs
  ],
  url: 'sleep-check-in'
};
