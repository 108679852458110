import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const phq2: AssessmentDefinition = {
  name: 'phq2',
  description: 'Patient Health Questionnaire',
  instructions: null,
  questions: [
    {
      id: 0,
      name: 'phq2-interest',
      questionText: 'IN THE PAST WEEK, how often have you been bothered by:',
      questionEmphasis: 'Little interest or pleasure in doing things',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ2_INTEREST',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      name: 'phq2-depression',
      questionText: 'IN THE PAST WEEK, how often have you been bothered by:',
      questionEmphasis: 'Feeling down, depressed, or hopeless',
      questionType: 'radio',
      uniqueAnswerId: 'PHQ2_DEPRESSION',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    }
  ]
};
