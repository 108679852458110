import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
import { DatePipe } from '@angular/common';
import { PatientStateService } from '@noctem/web';
import { TimeTrackingService } from '../../../../../../../../../noctem-lib/src/lib/services/time-tracking.service'; 

@Component({
    selector: 'confirm-delete-dialog',
    styleUrls: ['../../patient-page.component.scss'],
    templateUrl: './confirm-delete-dialog.component.html',
    providers: [TimeTrackingService]
})
export class ConfirmDeleteDialog implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<any>,
        public stateService: PatientStateService,
        private timeTrackingService: TimeTrackingService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.stateService.deleteCalendarEvent(this.data.eventToDelete.eventId)
        this.dialogRef.close();
    }

    

    ngOnInit() {
        // console.debug(this.data.eventToDelete)
    }

    ngAfterViewInit(){
        this.timeTrackingService.trackTimeOnElement("mat-dialog-container", { activityType: 'Patient_Page', subEvent: 'Delete_Confirmation_Dialog', patientId: this.data.recipientId });

    }

}
