<div class="row logInputStatus align-items-center py-2">
    <div class="morning current" *ngIf="currentLog.logType === 'morningLog'">
        <img src="assets/img/nav/morning_log_active.svg">
    </div>

    <div class="evening current" *ngIf="currentLog.logType === 'eveningLog'">
        <img src="assets/img/nav/night_log_active.svg">
    </div>
</div>

<div class="row py-3">
    <div class="col">
        <h4>Log Date: {{ logDate | date: 'shortDate' }}</h4>
    </div>
</div>