import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const sleepAndNightOps: AssessmentDefinition = {
  name: 'sleep-and-night-ops',
  description: 'Sleep and Night Ops',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Occasional bad dreams or nightmares are normal',
      questionEmphasis: ``,
      subText: `<p>It is harder to sleep during the daytime than during nighttime. </p>
                <p>Difficulty falling and staying asleep commonly occur with trying to sleep during the day.</p>
                <p>There are techniques you can use before, during, and after your shift to promote sustained alertness.  </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'What can you do?',
      questionEmphasis: ``,
      subText: `<p><img src="" /></p>
                <p>Allot more time for daytime sleep than you would for nighttime sleep. </p>
                <p>Use sleep, caffeine, and light strategically before, during, and after your shift. </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: `Take action, here's what you can do`,
      questionEmphasis: ``,
      subText: `<p>Allot more time for daytime sleep. It is often harder to fall and stay asleep during the day. </p>
                <p>You can also take short naps in the afternoon or evening to augment your main daytime sleep period. </p>
                <p>Aim for a sleep area that is cool, dark, and quiet. </p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: 'Before your shift',
      questionEmphasis: ``,
      subText: `<p>Take a brief nap before your shift so that you are starting with a refreshed brain. </p>
                <p>Upon awakening, have a routine you will do each time before your shift. These behaviors will signal to your brain that it is time to be alert for your duties, rather than winding down for sleep. </p>`,
      questionType: 'textarea',

      uniqueAnswerId: 'NIGHT_OPS_SHIFT',
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'During your shift',
      questionEmphasis: ``,
      subText: `<p>Take 200mg of caffeine at the beginning of your shift. Four hours later take an additional 200 mg if needed. </p>
                <p>Continue to use caffeine every four hours, if needed, to maintain alertness for duty. Aim to have your last dose of caffeine no later than 6 hours before your next planned sleep opportunity. </p>
                <p>If possible, take a 20-30 minute nap.  Caffeine taken just prior to a nap may help boost alertness upon awakening.</p> `,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 5,
      questionText: 'End of your shift',
      questionEmphasis: ``,
      subText: `<p>Limit light exposure by wearing blue light blocking sunglasses. </p>
                <p>Avoid caffeine, nicotine, or exercise. </p>
                <p>Get sleep as soon as you can. Aim for a sleep area that is dark with as few distractions or noises as possible. </p> `,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 6,
      questionText: 'End of your shift',
      questionEmphasis: ``,
      subText: `<p>Limit light exposure by wearing blue light blocking sunglasses. </p>
                <p>Avoid caffeine, nicotine, or exercise. </p>
                <p>Get sleep as soon as you can. Aim for a sleep area that is dark with as few distractions or noises as possible. </p> `,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 7,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p><img src=""/></p>
                <p>We are biologically designed to be awake and alert during the day, and asleep at night. </p>
                <p>Each of these techniques lessens the challenge of working against your internal sleep biology. </p> `,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    }
  ]
};
