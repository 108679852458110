import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalModuleProgress } from '@noctem/web';
import { AssetDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

@Component({
  selector: 'module-card',
  templateUrl:'./module-card.component.html',
  styleUrls: ['./module-card.component.scss']
})
export class ModuleCardComponent implements OnInit {
  @Input() module: AssetDefinition;
  @Input() showProgress: boolean;
  @Input() returnRoute: string;
  public percentageComplete: number = 0;

  constructor(private router: Router) { }

  ngOnInit() {
    const localStorageItem = localStorage.getItem('moduleProgress');
    let previousModules = new Map<string, LocalModuleProgress>();
    let currentModule: LocalModuleProgress = null;
    if (!localStorageItem || localStorageItem === '{}') {
      previousModules = new Map<string, LocalModuleProgress>();
    } else { previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorageItem)); }

    currentModule = previousModules.get(this.module.name);
    if (currentModule) {
      this.percentageComplete = currentModule.percentComplete ?? 0;
    }
  }

  goToUrl() {
    let medicRequested = null;
    if(this.module.medicRequested) {
      medicRequested = (this.module.medicRequested === true) ? 't' : 'f';
    }

    const url = `/module/${this.module.name}/${this.returnRoute ?? 'dashboard'}/${medicRequested}`;
    this.router.navigateByUrl(url,
      {
        queryParams: {
          medreq: medicRequested,
          new: this.module.percentComplete === 100 || this.module.percentComplete === 0 ? 't' : 'f'
        }
      });
  }
}
