import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const osa: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-diagnosed',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSA,
    answerId: 'OSA_DIAGNOSED'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-gasping',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_GASPING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-stop-breathing',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_STOP_BREATHING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-snore-loudly',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_SNORE_LOUDLY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-dry-mouth',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_DRY_MOUTH'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-congestion-headaches',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_CONGESTION_HEADACHES'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-blood-pressure',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_BLOOD_PRESSURE'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-sleepy',
    unit: UnitType.YesNo,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_SLEEPY'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'osa-neck-size',
    unit: UnitType.Number,
    abbreviation: 'OSA',
    description: `OSA`,
    assessmentType: LogDefinitionTypes.OSADetailsFalse,
    answerId: 'OSA_NECK_SIZE'
  }),


  //#endregion
];
