<div class="mat-dialog-content">
    <div class="modal-header">
        <h3 class="modal-title">Provider License Agreement For COAST™ Portal (PLA)</h3>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="row breathe">
                    <div class="terms">
                        <p>This Provider License Agreement ("PLA") is a legal agreement between you and Rehat, LLC d/b/a NOCTEM® (“NOCTEM”) that grants you permission to use the COAST™ system developed and owned by NOCTEM.</p>
                        <p>BY CLICKING THE BOX LABELED “I AGREE” BELOW, YOU (“YOU” OR THE “PROVIDER”), (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) AGREE TO THE TERMS OF THIS AGREEMENT WITH THE INTENT OF BEING LEGALLY BOUND. IF YOU NOT AGREE TO THE TERMS OF THIS PLA, YOU WILL NOT HAVE PERMISSION TO USE OR RETAIN A COPY OF THE COAST™ System, WHICH SHOULD, THEREFORE, BE DELETED.</p>
                        <ol>
                            <li><b><u>LICENSE GRANT</u></b>. Upon your acceptance of the terms of this PLA (by clicking
                                “I Agree”), and provided that you have lawfully obtained a User ID and Password], NOCTEM
                                grants you a nonexclusive, limited term, right and license to access and use the COAST™
                                System.</li>
                            <li><b><u>INTELLECTUAL PROPERTY AND PROPRIETARY INFORMATION</u></b>. As between you and NOCTEM, all intellectual rights covering or embodied in the COAST™ System (including, without limitation, copyrights, trademarks, trade secrets) are the sole property of NOCTEM. This PLA does not grant you any title or ownership interest in the COAST System but grants only a limited right to access and use it.  You understand and agree that the content, design, logic, flow, structure, assets, features, and functions of the COAST System are proprietary and confidential information of NOCTEM. (Collectively, “NOCTEM Proprietary Information”.) Except in the delivery of care to enrolled COAST patients, you shall not copy, display, disclose, or distribute NOCTEM Proprietary Information to any third party without NOCTEM’s prior written consent.</li>
                            <li><b><u>PROVIDER ID/PASSWORD/SECURITY</u></b>. You will not use or attempt to use any
                                Provider ID and Password for any unauthorized purpose. You may not use a third party's
                                Provider ID or Password or disclose your Provider ID and Password to third parties. You
                                are responsible for any and all actions taken performed with your Provider ID and
                                Password. You will notify NOCTEM immediately if you become aware of any unauthorized use
                                of your Provider ID or Password or any other security feature of the COAST System.</li>
                            <li><b><u>END PROVIDER OBLIGATIONS AND RESTRICTIONS</u></b>. You may use the COAST System to
                                access the services, functions, or features it makes available to you. You agree to use
                                the COAST System exclusively for authorized and legal purposes, consistent with all
                                applicable laws, regulations, and the rights of others, including privacy laws. You will
                                not attempt to reconfigure or engineer changes to the COAST System or attempt to gain
                                access to any unauthorized features of the COAST System. In addition, you will not
                                attempt to interfere with or disrupt the COAST System or attempt to gain access to any
                                systems or attempt to scan or probe, hack, defeat, or otherwise compromise any security
                                measures of the COAST System or any systems or network portals operated by NOCTEM in
                                connection with the COAST System.</li>
                            <li><b><u>TECHNICAL DATA</u></b>. You agree that NOCTEM may collect and use technical data and related information—including but not limited to technical information about your device and system usage--—that is gathered periodically to facilitate the provision of COAST System, updates, product support NOCTEM may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</li>
                            <li><b><u>PERSONAL INFORMATION/PRIVACY</u></b>. NOCTEM will maintain the confidentiality of any personal or health information that you provide to NOCTEM through use of the COAST System. in accordance with applicable law and the terms of NOCTEM’s Privacy Policy. NOCTEM’s Privacy Policy, which may be revised or updated from time to time, is available for review at <a href="https://noctemhealth.com/privacy" target="_blank">noctemhealth.com</a>.</li>
                            <li><b><u>DISCLAIMER OF WARRANTIES</u></b>
                                <ol type="a">
                                    <li>NOCTEM PROVIDES ACCESS TO THE COAST SYSTEM SUBJECT TO THIS PLA AND ON AN “AS IS”
                                        BASIS ONLY. NOCTEM MAKES NO WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
                                        AS TO ANY MATTER INCLUDING, BUT NOT LIMITED TO, WARRANTY OF FITNESS FOR
                                        PARTICULAR PURPOSE, OR MERCHANTABILITY. NOCTEM LLC DOES NOT GUARANTEE THE
                                        CONTINUOUS, UNINTERRUPTED OPERATION OF THE COAST SYSTEM OR ANY RELATED SERVICES.
                                        NOCTEM SHALL NOT BE LIABLE FOR INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES SUCH
                                        AS LOSS OF PROFITS OR INABILITY TO USE THE COAST SYSTEM OR ANY RELATED SERVCES
                                        OR DERIVATIONS THEREOF. UNDER NO CIRCUMSTANCE WILL NOCTEM BE LIABLE FOR DAMAGES
                                        GREATER THAN AMOUNTS RECEIVED BY NOCTEM IN CONSIDERATION OF THIS SPECIFIC PLA OR
                                        FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS,
                                        INTERRUPTION OF BUSINESS OR RELATED EXPENSES THAT MAY ARISE FROM YOUR USE OF THE
                                        COAST SYSTEM, INCLUDING BUT NOT LIMITED TO ANY THAT RESULT FROM DEFECTS IN THE
                                        COAST SYSTEM, LOSS OF DATA, OR INACCURACIES OF ANY KIND.</li>
                                    <li><u>PROFESSIONAL RESPONSIBILITY DISCLAIMER, INDEMNIFICATION</u>. THE COAST SYSTEM IS INTENDED AS A SUPPLEMENT TO, AND NOT A SUBSTITUTE FOR, THE KNOWLEDGE, EXPERTISE, SKILL, AND JUDGMENT OF PHYSICIANS, PSYCHOLOGISTS, SLEEP THERAPISTS, NURSES, OR OTHER HEALTHCARE PROFESSIONALS PROVIDING PATIENT CARE, CLINICAL DIAGNOSIS OR MEDICAL TREATMENT. YOUR USE OF THE COAST SYSTEM IS NOT A SUBSTITUTE FOR PROFESSIONAL JUDGMENT. THE COAST SYSTEM MAY NOT COLLECT OR CONSIDER ALL DATA, INFORMATION, RESOURCES, OR MEDICAL HISTORY NECESSARY FOR A COMPLETE OR ACCURATE HEALTH ASSESSMENT OR ADVICE, AND INFORMATION COLLECTED THROUGH THE COAST SYSTEM MAY NOT ALWAYS BE ACCURATE. ACCORDINGLY, NOCTEM DOES NOT ASSUME AND EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR YOUR ACTS OR OMISSIONS OF WHICH MAY RESULT IN ANY LIABILITY OR DAMAGES DUE TO MALPRACTICE, FAILURE TO WARN, NEGLIGENCE OR ANY OTHER BASIS AND YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS ANY NOCTEM INDEMNIFIED PARTY AGAINST ANY CLAIMS ARISING OUT OF, OR RELATED TO, ANY SUCH MALPRACTICE, FAILURE TO WARN, NEGLIGENCE OR ANY SIMILAR CLAIM WHETHER ARISING OUT OF CONTRACT, TORT, REGULATORY OR STATUTORY CAUSES OF ACTION.</li>
                                </ol>
                            </li>
                            <li><b><u>INDEMNIFICATION</u></b>. You agree to defend, indemnify and hold harmless NOCTEM,
                                its directors, officers, employees, attorneys and agents from all claims or demands made
                                against them (and any related losses, expenses or attorneys’ fees) arising out of or
                                relating to your use of or conduct regarding the COAST System.</li>
                            <li><b><u>TERMINATION</u></b>. Either you or NOCTEM may terminate this PLA at any time, for any reason, or for no reason, without notice. In addition, this PLA shall terminate immediately in the event you breach this Agreement. Upon termination, you will immediately discontinue access to and use of the COAST System.</li>
                            <li><b><u>MISCELLANEOUS PROVISIONS.</u></b>
                                <ol type="a">
                                    <li><b><u>No Waiver</u></b>. No delay or omission by NOCTEM to exercise any right or
                                        power under any term of this PLA shall be a waiver of NOCTEM’s rights to enforce
                                        this PLA in any respect.</li>
                                    <li><b><u>Severability</u></b>. If any provision of this Agreement is found to be
                                        illegal, invalid, unenforceable or void by a court of competent jurisdiction,
                                        then both parties shall comply with the provisions to the extent permitted by
                                        law. All provisions of this PLA shall be enforced to the fullest extent
                                        permitted by law.</li>
                                    <li><b><u>No Third Party Beneficiaries</u></b>. Nothing in this agreement creates
                                        any third party beneficiary rights.</li>
                                    <li><b><u>Headings</u></b>. Section headings contained in this Agreement are for
                                        reference purposes only and are not a part of this Agreement.</li>
                                    <li><b><u>Entire Agreement</u></b>. This PLA is the entire agreement between you and NOCTEM and supersedes all other agreements, understandings, statements and representations, written or oral, concerning the subject matter hereof.</li>
                                    <li><b><u>Governing Law; Forum</u></b>. This Agreement and all matters arising out
                                        of or relating to this Agreement shall be governed by the laws of the
                                        Commonwealth of Pennsylvania, without regard to its conflict of law provisions.
                                        Any legal action or proceeding relating to this Agreement shall be brought
                                        exclusively in the state or federal courts located in Allegheny County,
                                        Pennsylvania. You agree to submit to the exclusive jurisdiction of those courts
                                        in any such legal action or proceeding. In addition, you hereby waive any claim
                                        that any such action has been brought in an inconvenient forum, or that the
                                        court in Allegheny Count does not have jurisdiction over the Parties, or that
                                        venue is improper.</li>
                                </ol>
                            </li>
                        </ol>

                        <p><u>You may address questions about this PLA to NOCTEM at the following addresses:</u></p>
                        <p>
                            218 Oakland Avenue<br />
                            Pittsburgh PA 15213<br />
                            Email: support@noctemhealth.com
                        </p>
                        <p>
                            Provider License Agreement For COAST™ Portal (PLA)
                            This Provider License Agreement ("PLA") is a legal agreement between you and Rehat, LLC
                            d/b/a NOCTEM® (“NOCTEM”) that grants you permission to use the COAST™ system developed and
                            owned by NOCTEM.
                            BY CLICKING THE BOX LABELED “I AGREE” BELOW, YOU (“YOU” OR THE “PROVIDER”), (A) ACKNOWLEDGE
                            THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE
                            OR OLDER; AND (C) AGREE TO THE TERMS OF THIS AGREEMENT WITH THE INTENT OF BEING LEGALLY
                            BOUND. IF YOU NOT AGREE TO THE TERMS OF THIS PLA, YOU WILL NOT HAVE PERMISSION TO USE OR
                            RETAIN A COPY OF THE COAST™ System, WHICH SHOULD, THEREFORE, BE DELETED.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div mat-dialog-actions>
        <button mat-button class="btn btn-secondary" (click)="onNoClick()">Close</button>
    </div>
</div>
