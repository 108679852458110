export const sideEffectsDef = {
  "name": "sideEffects",
  "description": "Side Effects",
  "instructions": null,
  "questions": [
    {
      "id": 0,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "PHYSICAL TIREDNESS",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "NONE", "value": 0 },
        {
          // eslint-disable-next-line quotes
          "display": "I tire easily but I don't have to take breaks more often than usual",
          "value": 1
        },
        { "display": "I tire easily and I am forced to rest or take breaks", "value": 2 },
        { "display": "I am exhausted and I cannot do almost any activity", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_FATIGUE"
    },
    {
      "id": 1,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "HEADACHES",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "NONE", "value": 0 },
        {
          "display": "Occasional headache(s) but it does not interfere with my activities",
          "value": 1
        },
        { "display": "Constant and moderate or occasional and severe headache(s)", "value": 2 },
        { "display": "Constant and severe headache(s)", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_HEADACHES"
    },
    {
      "id": 2,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "DIZZINESS",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "NONE", "value": 0 },
        { "display": "Occasionally slight dizziness", "value": 1 },
        { "display": "Constant slight dizziness or occasional and severe dizziness", "value": 2 },
        { "display": "Constant and severe dizziness", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_DIZZINESS"
    },
    {
      "id": 3,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "REDUCED SEXUAL INTEREST",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "NONE", "value": 0 },
        { "display": "My sexual interest is reduced but not impaired", "value": 1 },
        {
          "display":
            "Definite reduction in sexual interest. My sexual activities are reduced or non-existent",
          "value": 2
        },
        { "display": "Complete sexual indifference", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_SEXUAL_INTEREST"
    },
    {
      "id": 4,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "DROWSINESS",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Fully awake", "value": 0 },
        { "display": "Slight yawns occasionally or others may say I look sleepy", "value": 1 },
        { "display": "I can easily fall asleep when left in peace", "value": 2 },
        {
          "display":
            "Severely drowsy: it is difficult to stay awake during the day and it interferes with my daily routines.",
          "value": 3
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_DROWSINESS"
    },
    {
      "id": 5,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "MUSCLE TIGHTNESS",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "None", "value": 0 },
        {
          "display": "Slight muscle stiffness but does not interfere with my daily activities",
          "value": 1
        },
        {
          "display": "Moderate muscle stiffness with some interference of my daily activities",
          "value": 2
        },
        { "display": "Constant and severe muscle stiffness", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_ MUSCLE_TIGHTNESS"
    },
    {
      "id": 6,
      "questionText": "Have you noticed the following in the past week:",
      "questionEmphasis": "SENSITIVITY TO LIGHT OR NOISE",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "None", "value": 0 },
        {
          "display":
            "Slight sensitivity to light or noise but does not interfere with my daily activities",
          "value": 1
        },
        {
          "display":
            "Moderate sensitivity to light or noise with some interference of my daily activities",
          "value": 2
        },
        { "display": "Constant and severe sensitivity to light or noise", "value": 3 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SIDE_EFFECTS_SENSITIVITY"
    }
  ]
};
