import { find as _find, pull as _pull, orderBy as _orderBy, isEmpty as _isEmpty, findIndex as _findIndex, filter as _filter , differenceWith as _differenceWith} from 'lodash';
import { Injectable } from '@angular/core';
import { flatMap as _flatMap } from 'lodash';
import { Router } from '@angular/router';
import { Subject, from, Observable } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NoctemUser, EvaluationTerm, SleepPrescription, NoctemUserReminderFrequencies, Group } from '../services/models';
import { ApiService, getAllActionTypes } from '../services';
import { UserFuncService, GroupService, MedicineService, SearchService, MedicalPrescriptionService, AssessmentService, SleepPrescriptionService, CalendarEventService, WearableService } from '../services/services';
import { CalculationService } from '../services/calculation.service';
import { PatientState } from './PatientState';
import { ModelFactory } from '../../core/web-ng';
import moment from 'moment';
import { APPLICATION_ORGANIZATION } from '../../constants/constants';
import { UserStateService } from '.';

@Injectable()
export class PatientStateService extends BaseStateService<PatientState> {
  public onUserSaved$: Subject<PatientState> = new Subject();
  public onUserCreated$: Subject<string> = new Subject();
  public onInfoLoaded$: Subject<any> = new Subject();
  public onDiaryLoaded$: Subject<any> = new Subject();
  public onLoadingChange$: Subject<boolean> = new Subject();
  public onWeekSelected$: Subject<boolean> = new Subject();

  constructor(
    stateFactory: ModelFactory<PatientState>,
    private router: Router,
    public dialog: MatDialog,
    private calculationService: CalculationService,
    private userFuncService: UserFuncService,
    private groupService: GroupService,
    private medicineService: MedicineService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private assessmentService: AssessmentService,
    private sleepPrescriptionService: SleepPrescriptionService,
    private calendarEventService: CalendarEventService,
    private wearableService: WearableService,
    private userStateService: UserStateService,
  ) {
    super(new PatientState(), stateFactory, null);
  }

  public async loadPatientInfo(userId: string) {
    this.setIsLoading(true);
    const state = this.stateModel.get();
    // updateLastView = this.apiService.updateLastView(userId).subscribe(res => { });
    state.weekDatesChanged = false;

    // Do not reload same user but still update user to state to ensure any possible updated made in patient management are reflected
    let user;
    if(state.userId !== userId){
      user = await this.loadPatientProfile(userId);
    } else {
      user = state.patientInfo;
    }
    state.events = await this.assessmentService.getLogs(state.userId).toPromise(); // getSleepLogs
    state.hasWearable = user.fitbitInfo!=null;
    if(state.hasWearable){
        await this.wearableService.sync(state.userId).toPromise().catch(_ => {});
        state.wearableEvents = await this.wearableService.getLogs(state.userId).toPromise(); // get wearable sleep
    }

    // Only load medicine and groups once
    if (Array.isArray(state.medicineList) && !state.medicineList.length) {
      state.medicineList = await this.medicineService.getAll().toPromise();
    }
    if (Array.isArray(state.groups) && !state.groups.length) {
      state.groups = await this.groupService.getAll().toPromise();
    }

    await this.loadPatientCalculations(true);
    await this.loadPatientPrescriptions();

    state.patientInfo.diaryReminderFrequency = user.diaryReminderFrequency || NoctemUserReminderFrequencies.NEVER;
    state.prescribedMedicineList = await this.medicalPrescriptionService.getMedicalPrescriptionList(state.userId).toPromise();
    
    // TODO: state.patientInfo.Dob = pipe.transform(new Date(user.profile.dateOfBirth), 'yyyy-MM-dd'),
    this.stateModel.set(state);
    this.onInfoLoaded$.next();
    this.setIsLoading(false);
  }

  public async loadPatientProfile(userId){
    const state = this.stateModel.get();
    const user = await this.userFuncService.getUser(userId.toString()).toPromise();
    state.userId = userId;
    state.patientInfo = user;
    state.userString = `${user.profile?.firstName} ${user.profile?.lastName}`;
    this.stateModel.set(state);
    return user;
  }

  public async loadPatientPrescriptions(){
    const state = this.stateModel.get();
    let startDate:any;
    if (!state.patientInfo.treatmentStartDate) {
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1).toISOString();
    } else {
      startDate = state.patientInfo.treatmentStartDate.split('T')[0];
    }
    const query = {
      $and: [
        { 'Payload.patient.id': state.userId },
        { 'Payload.startDate': { $gte: startDate } },
      ]
    };
    state.sleepPrescriptions = await this.sleepPrescriptionService.getAll(query).toPromise();
  }

  public async loadPatientCalculations(fromLoadPatient?){
    if(fromLoadPatient === false){
      this.setIsLoading(true);
    }
    const state = this.stateModel.get();
    const calculations = await this.calculationService.doSomething(state.userId, state.events ?? [], state.patientInfo.treatmentStartDate, state.patientInfo.timeZoneOffset);
    state.treatmentPlan = calculations.treatmentPlan;
    state.diaryAverages = calculations.diaryAverages;
    state.weekEvents = calculations.weeklyevents;
    await this.setWeek(calculations.currentWeek);
    if(fromLoadPatient === false){
      this.setIsLoading(false);
    }
  }


  public async calcAverages(sumGNTh, sumGNTm, sumGMTh, sumGMTm, sumLatency, sumWASO, sumEMA, sumTIB, sumAsleep,
                            sumSE, sumSQ, count, data, morningLogCount) {
    const state = this.stateModel.get();
    const averages =
      await this.calculationService.calcAverages(sumGNTh, sumGNTm, sumGMTh, sumGMTm, sumLatency, sumWASO, sumEMA, sumTIB,
                            sumAsleep, sumSE, sumSQ, count, data, state.userId, morningLogCount);
    // Only run this if flags are available
    if(state.selectedWeek.patientFlags !== undefined) {
      this.calculationService.setPatientFlags(state.selectedWeek, averages.sleepTacticRecommendations)
    }
    state.diaryAverages = averages;
    state.selectedWeek.outOfBedSuggested = averages.avgGMT;
    state.selectedWeek.bedTimeSuggested = averages.sBedTime;
    state.selectedWeek.timeInBedSuggested = averages.sTIB;

    this.setState(state);
  }

  public async savePatientInfo(patientInfo: NoctemUser) {
    // Get state for patient UserId but do not update until the requested new email address has been verified to not conflict
    const state = this.stateModel.get();

    // The emailAddress field for patients is now (Feb 2024) optional
    if (patientInfo.profile.emailAddress) {
      // Note: conflicts are determined cia the UserSettings collection - not the user_security model per the API's method
      const matchedUser = await this.userFuncService.getOneAsync({"Payload.UserId": state.patientInfo.UserId});
      const matchedUserEmail = matchedUser.profile.emailAddress;

      // If a conflict already exists in the database, permit it unless an email change is requested.
      if (matchedUserEmail !== patientInfo.profile.emailAddress) {
        const conflictingUser = await this.userFuncService.getOneAsync({"Payload.profile.emailAddress": patientInfo.profile.emailAddress});
        if (conflictingUser) {
          alert("Unable to update Patient. Email address already exists in the database.");
          this.loadPatientProfile(state.userId);
          this.stateModel.set(state);
          this.onUserSaved$.next(state);
          return;
        }
      }
    }

    // Continue if no conflict
    state.patientInfo.profile = patientInfo.profile;
    state.patientInfo.emergencyContacts[0] = patientInfo.emergencyContacts[0];
    state.patientInfo.emergencyContacts[1] = patientInfo.emergencyContacts[1];
    state.patientInfo.additionalProviders = patientInfo.additionalProviders;
    state.patientInfo.groups = patientInfo.groups;
    if(!state.patientInfo.timeZoneOffset){
      state.patientInfo.timeZoneOffset = moment().utcOffset();
    }

    this.userFuncService.save(state.patientInfo).subscribe(res => {
      this.loadPatientProfile(state.userId);
      this.stateModel.set(state);
      this.onUserSaved$.next(state);
    },
      err => {
        console.log(err);
      });
  }

  public async addNewPatient(patientInfo: NoctemUser) {
    // TODO: Add validation
    const state = this.stateModel.get();
    state.IsLoading = true;
    this.setState(state);
    
    // The emailAddress field for patients is now (Feb 2024) optional
    if (patientInfo.profile.emailAddress) {
      // Note: conflicts are determined cia the UserSettings collection - not the user_security model per the API's method
      const conflictingUser = await this.userFuncService.getOneAsync({"Payload.profile.emailAddress": patientInfo.profile.emailAddress});
      if (conflictingUser) {
        state.IsLoading = false;
        this.setState(state);

        alert("Unable to Create Patient. Email address already exists in the database.");
        this.onUserCreated$.next(null);
        return;
      }
    }

    // Continue if no conflict
    const newUserPayload = {
      emailAddress: patientInfo.profile.emailAddress,
      phoneNumber: patientInfo.profile.phoneNumber,
      firstName: patientInfo.profile.firstName,
      lastName: patientInfo.profile.lastName,
      groups: patientInfo.groups,
      emergencyContacts: patientInfo.emergencyContacts,
      additionalProviders: patientInfo.additionalProviders,
      age: patientInfo.profile.age,
      gender: patientInfo.profile.gender,
      height: patientInfo.profile.height,
      weight: patientInfo.profile.weight,
      race : patientInfo.profile.race,
      ethnicity : patientInfo.profile.ethnicity,
      maritalStatus: patientInfo.profile.maritalStatus,
      militaryStatus: patientInfo.profile.militaryStatus,
      branch: patientInfo.profile.branch,
      rank: patientInfo.profile.rank,
      assignedClinician: patientInfo.assignedClinician,
      isActive: true,
      timeZoneOffset: patientInfo.timeZoneOffset?patientInfo.timeZoneOffset:moment().utcOffset(),
      externalId: patientInfo.profile.externalId,
      diagnosticCodes: patientInfo.profile.diagnosticCodes,
    };

    this.userFuncService.create(newUserPayload).subscribe(userId => {
      state.IsLoading = false;
      if (userId) {
        this.onUserCreated$.next(userId);
      }

      this.setState(state);

    }, err => {
      // TODO: update state with errors
    });

  }

  public incrementCheckedLogsDays(increment: number): void {
    const state = this.stateModel.get();
    state.checkedLogDays += increment;
    this.stateModel.set(state);
  }

  public getCheckedLogDays(): number {
    return this.stateModel.get().checkedLogDays;
  }

  public async setWeek(selectedWeek) {
    const state = this.stateModel.get();
    if (!selectedWeek) {
      state.weekModified = false;
      this.stateModel.set(state);
      return;
    }

    if (state.weekModified) {
      const doSave = confirm('This week has been modified. Unsaved changes will be lost. Continue?');
      if (!doSave) {
        return;
      }
    }
    if (!selectedWeek.isActiveWeek) {
      state.weekReadOnly = true;
    } else {
      state.weekReadOnly = false;
    }
    state.selectedWeek = selectedWeek;
    state.weekModified = false;
    for (const thisWeek of state.treatmentPlan.weeks) {
      if (thisWeek.phaseName === selectedWeek.phaseName && thisWeek.startDate.diff(selectedWeek.startDate, 'days') === 0) {
        thisWeek.isOpen = true;
      } else {
        thisWeek.isOpen = false;
      }
    }
    this.stateModel.set(state);
    this.onWeekSelected$.next(state.selectedWeek);
  }

  public setWeekNotification(key, value, eventId?: string) {
    if(value == "true"){
      value = true
    } else if (value == "false"){
      value = false
    }
    
    const state = this.stateModel.get();
    if (state.weekReadOnly) {
      return;
    }

    let weekToUpdate = null;
    if(eventId)
      weekToUpdate = state.treatmentPlan.weeks.filter(w => { return w.eventId == eventId})[0];
    else
      weekToUpdate = state.selectedWeek;

      weekToUpdate[key] = value;
      state.weekModified = true;

    this.stateModel.set(state);
  }

  public setFlag(selectedFlag): void {
    const state = this.stateModel.get();
    if (
      state.weekReadOnly
    ) {
      return;
    }
    const thisWeek = state.selectedWeek;
    const thisFlag = _find(thisWeek.patientFlags, flag => flag.name === selectedFlag.name);
    thisFlag.isSelected = thisFlag.isSelected ? false : true;
    state.selectedWeek = thisWeek;
    state.weekModified = true;
    this.stateModel.set(state);
    this.onWeekSelected$.next(state.selectedWeek);
  }

  public getDuration(input) {
    let seconds = input * 60;
    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;
    const hours = Math.floor(seconds / 3600) % 24;
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60) % 60;
    return hours + 'h ' + minutes + 'm ';
  }

  public loadSleepDiary(sleepDiaryId, userId) {
    this.setIsLoading(true);
    const state = this.stateModel.get();
    // look through state.events and find this one, we alreayd have all the answers
    const thisDiary: any = _find(state.events, event => event.id?.toString() === sleepDiaryId?.toString());
    if (!thisDiary) {
      this.router.navigateByUrl(`/patient/${userId}`);
    } else {

      thisDiary.wake = this.translateLog(thisDiary.evening);
      thisDiary.sleep = this.translateLog(thisDiary.morning);
      state.sleepDiary = thisDiary;
      this.stateModel.set(state);
      this.onDiaryLoaded$.next(state.sleepDiary);
      this.setIsLoading(false);
      this.checkNavButtons();
    }
  }

  private calculateTime(assessment: any, date: string): moment.Moment {
    if(!date) return null;
    const returnDate: moment.Moment = moment.utc(assessment.assessmentDate);
    const hours: number = parseInt(date.split(':')[0], 10);
    const minutes: number = parseInt(date.split(':')[1], 10);
    returnDate.hours(hours).minutes(minutes);

    //returnDate.utcOffset(assessment.timeZoneOffset ?? 240);
    return returnDate;  // create new instance to make sure we're not affecting a
  }

  public translateLog(sleepLog) {
    const logObject: any = {
      id: sleepLog.id
    };
    if (sleepLog.groups?.length > 0 && sleepLog.groups[0].answers) {
      for (const thisAnswer of sleepLog.groups[0].answers) {
        const answerId = thisAnswer.uniqueAnswerId?.toLowerCase();
        if (answerId === 'attempt_to_sleep' || answerId === 'rise_time' || answerId === 'wake_time') {
          logObject[`${thisAnswer.uniqueAnswerId}`] = this.calculateTime(sleepLog, thisAnswer.value)?.format('HH:mm');
        } else {
          logObject[`${thisAnswer.uniqueAnswerId}`] = thisAnswer.value;
        }
      }
    }
    return logObject;
  }

  public setIsLoading(isLoading: boolean) {
    const state = this.stateModel.get();
    state.IsLoading = isLoading;
    this.stateModel.set(state);
  }

  public setValue() {
    const state = this.stateModel.get();
    state.weekModified = true;
  }

  public setWeekValue(key, value, eventId?: string) {
    const state = this.stateModel.get();
    let weekToUpdate = null;
    if(eventId)
      weekToUpdate = state.treatmentPlan.weeks.filter(w => { return w.eventId == eventId})[0];
    else
      weekToUpdate = state.selectedWeek;
      let weekIndex = state.treatmentPlan.weeks.reduce((acc, week, i) => week.eventId === state.selectedWeek.eventId ? i : acc, null);

      weekToUpdate[key] = value;
      state.selectedWeek[key] = value;
      state.treatmentPlan.weeks[weekIndex][key] = value;
      state.weekModified = true;

    this.stateModel.set(state);
  }

  public goHome() {
    this.router.navigateByUrl('/');
  }

  public editSleepDiary(sleepDiaryId) {
    const state = this.stateModel.get();
    let navUrl = `/patient/${state.userId}/sleepdiary/${sleepDiaryId}`;

    // Refresh the page to force reload of sleep diaries so validation is reconnected
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(()=> this.router.navigate([navUrl]));
  }

  public navigateDiary(direction) {
    const state = this.stateModel.get();
    const diaryIndex: any = _findIndex(state.events, (event: any) => event.id.toString() === state.sleepDiary.id.toString()); //
    this.editSleepDiary(state.events[diaryIndex - direction].id);
  }

  public checkNavButtons() {
    const state = this.stateModel.get();
    const diaryIndex: any = _findIndex(state.events, (event: any) => event.id.toString() === state.sleepDiary.id.toString()); //
    state.disablePrev = state.events[diaryIndex + 1] ? false : true;
    state.disableNext = state.events[diaryIndex - 1] ? false : true;
    this.stateModel.set(state);
  }

  public buttonAction(preface, key, value) {
    const state = this.stateModel.get();
    if (value > -1) {
      state.sleepDiary[preface][key] = value;
    }
    this.stateModel.set(state);
  }

  public sliderAction(preface: string, key: any, event: any) {
    const state = this.stateModel.get();
    state.sleepDiary[preface][key] = event.value;
    this.stateModel.set(state);
  }

  public radioButtonAction(key, value) {
    const state = this.stateModel.get();
    state.sleepDiary[key] = value;
    const isAwakening = _find(state.awakeningOptions, thisKey => thisKey === key);
    state.totalAwakenings = 0;
    if (isAwakening) {
      for (const thisKey of state.awakeningOptions) {
        if (state.sleepDiary[thisKey]) {
          state.totalAwakenings += state.sleepDiary[thisKey];
        }
      }
    }
    this.stateModel.set(state);
    this.onInfoLoaded$.next(state.sleepDiary);
  }


  public setDate(preface: string, key: string, value: any) {
    const state = this.stateModel.get();
    state.sleepDiary[preface][key] = moment(value).toISOString();
    this.stateModel.set(state);
  }

  public setSleepDiaryInfo(preface: string, key: string, value: any) {
    const state = this.stateModel.get();
    state.sleepDiary[preface][key] = value;
    this.stateModel.set(state);
  }


  public async saveSleepDiary() {
    const state = this.stateModel.get();
    if(state.sleepDiary.sleep.MINUTES_TO_SLEEP == '')
      state.sleepDiary.sleep.MINUTES_TO_SLEEP = '0';
    await this.assessmentService.saveLogs(state.sleepDiary).toPromise();
    alert('Diary Saved');
  }

  public async savePrescription(additionalMessage?: string) {
    this.setIsLoading(true);
    const state = this.stateModel.get();
    const prescriptionInstance = state.selectedWeek.thisWeek; // we set to thisWeek to get __i
    prescriptionInstance.id = state.selectedWeek.id;
    // prescriptionInstance.docType =  state.selectedWeek.thisWeek.docType; // TODO: remove once bitwise stuff is fixed

    // Trying to prevent out https://noctemhealth.atlassian.net/browse/COAST-477
    let saveableBedTime = state.selectedWeek.bedTime;
    if (saveableBedTime.length > 5) {
      console.error('Invalid data for saving bedTime');
      saveableBedTime = '';
    }
    prescriptionInstance.bedTime = saveableBedTime;

    prescriptionInstance.startDate = state.selectedWeek.startDate;
    prescriptionInstance.useWakeAlarm = state.selectedWeek.useWakeAlarm;
    prescriptionInstance.endDate = state.selectedWeek.endDate;
    prescriptionInstance.sBedTime = state.selectedWeek.bedTimeSuggested;

    // Trying to prevent out https://noctemhealth.atlassian.net/browse/COAST-477
    let saveableRiseTime = state.selectedWeek.outOfBed;
    if (saveableRiseTime.length > 5) {
      console.error('Invalid data for saving riseTime/outOfBed');
      saveableRiseTime = '';
    }
    prescriptionInstance.riseTime = saveableRiseTime;

    prescriptionInstance.sRiseTime = state.selectedWeek.outOfBedSuggested;
    prescriptionInstance.clientTimeZoneOffset = state.selectedWeek.clientTimeZoneOffset;
    prescriptionInstance.needsNotificationSaved = true;  // Each time prescription is saved/updated, create a new notification for it
    prescriptionInstance.isRestless = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsRestless').isSelected;
    prescriptionInstance.isThinking = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsThinking').isSelected;
    prescriptionInstance.isNightmare = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsNightmare').isSelected;
    prescriptionInstance.patientFlags = state.selectedWeek.patientFlags;
    const clock = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsClockRetraining').isSelected;
    const fatigue = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsFatgiueCounter').isSelected;
    const goal = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsSleepGoal')?.isSelected;
    const stress = _find(state.selectedWeek.patientFlags, flag => flag.name === 'IsStressReduction').isSelected;
    prescriptionInstance.note = state.selectedWeek.note;
    prescriptionInstance.timeZoneOffset = new Date().getTimezoneOffset();
    if (clock) {
      prescriptionInstance.docType = prescriptionInstance.docType | 8;
    } else {
      prescriptionInstance.docType = prescriptionInstance.docType & ~8;
    }
    if (fatigue) {
      prescriptionInstance.docType = prescriptionInstance.docType | 16;
    } else {
      prescriptionInstance.docType = prescriptionInstance.docType & ~16;
    }
    if (goal) {
      prescriptionInstance.docType = prescriptionInstance.docType | 32;
    } else {
      prescriptionInstance.docType = prescriptionInstance.docType & ~32;
    }
    if (stress) {
      prescriptionInstance.docType = prescriptionInstance.docType | 64;
    } else {
      prescriptionInstance.docType = prescriptionInstance.docType & ~64;
    }

    if (!prescriptionInstance.createdOn) {
      prescriptionInstance.createdOn = new Date().toISOString();
    }
    prescriptionInstance.lastModified = new Date().toISOString();

    const savedPrescription = await this.sleepPrescriptionService.createSleepPrescription(prescriptionInstance).toPromise();
    if (savedPrescription) {
      state.weekModified = false;
      prescriptionInstance.__i = savedPrescription.__i;
      await this.loadPatientPrescriptions();
      this.setState(state);
    }
    this.setIsLoading(false);
  }

  public async saveSleepPrescriptions(sleepPrescriptions: SleepPrescription[]) {
    const state = this.stateModel.get();
    for (let prescription of sleepPrescriptions) {
      prescription.lastModified = new Date().toISOString();
      this.sleepPrescriptionService.update(prescription).subscribe(r => {});
    }

    state.sleepPrescriptions = sleepPrescriptions;
    this.setState(state);
  }

  public async update(): Promise<any> {
    this.setIsLoading(true);
    const currentUser = this.userStateService.model.get();
    const state = this.stateModel.get();
    const prescriptionInstance = state.selectedWeek.thisWeek; // we set to thisWeek to get __i
    prescriptionInstance.id = state.selectedWeek.id;
    prescriptionInstance.note = state.selectedWeek.note;
    prescriptionInstance.lastModified = new Date().toISOString();
    prescriptionInstance.notesLastSignedOn = new Date().toISOString();
    prescriptionInstance.notesLastSignedBy = currentUser.User.UserId;
    prescriptionInstance.__i = (await this.sleepPrescriptionService.update(prescriptionInstance).toPromise()).__i;
    state.weekModified = false;
    this.setState(state);
    this.setIsLoading(false);
    alert("Notes have been signed & saved");
  }

  public impressionAction(key, value) {
    const state = this.stateModel.get();
    state.selectedWeek.impressionAnswers[key] = value;
    this.stateModel.set(state);
  }

  public async saveImpression(assessmentDate?: Date) {
    const state = this.stateModel.get();
    let impression: any = {};
    if (!_isEmpty(state.selectedWeek.impressionInfo)) {
      impression = state.selectedWeek.impressionInfo;
      impression.assessmentDate = new Date();
      impression.groups[0].answers = [];
      // tslint:disable-next-line: forin
      for (const answer in state.selectedWeek.impressionAnswers){
        let answerInstance = {};
        answerInstance = {
          index: 1,
          value: state.selectedWeek.impressionAnswers[answer],
          uniqueAnswerId:  answer
        }
        impression.groups[0].answers.push(answerInstance);
      }
    } else {
      const answerGroup: any = {};
      answerGroup.type = 'cgi';
      answerGroup.answers = [];
      // tslint:disable-next-line: forin
      for (const answer in state.selectedWeek.impressionAnswers){
        let answerInstance = {};
        answerInstance = {
          index: 1,
          value: state.selectedWeek.impressionAnswers[answer],
          uniqueAnswerId:  answer
        }
        answerGroup.answers.push(answerInstance);
      }
      impression = {
        id: 0,
        comment: '',
        startTime: new Date(),
        endTime: new Date(),
        user: state.selectedWeek.thisWeek.patient,
        assessmentDate: new Date(),
        groups: [],
        evaluationTerm: state.selectedWeek.phaseName, // TODO: testing with baseline and baseline doesnt have CGI
        phaseName: state.selectedWeek.phaseName
      }
      if(assessmentDate){
        impression.assessmentDate = assessmentDate;
      }
      impression.groups.push(answerGroup);
    }
    await this.assessmentService.save(impression).toPromise();
    this.loadPatientInfo(state.userId);
  }

  public async getPrescribedMed() {
    const state = this.stateModel.get();
    state.prescribedMedicineList = await this.medicalPrescriptionService.getMedicalPrescriptionList(state.userId).toPromise();
    this.stateModel.set(state);
  }

  public async addPrescribedMed(dose, instruction, medId) {
    const state = this.stateModel.get();
    const medInstance: any = {};
    medInstance.patient = {
      id: state.userId,
      display: `${state.patientInfo.profile.firstName} ${state.patientInfo.profile.lastName}`,
      model: 'UserSettings'
    };
    medInstance.id = 0;
    medInstance.instruction = instruction;
    medInstance.dose = dose;
    const matchedMedicine = state.medicineList.find(m => m.id === medId);

    let medDisplayName = matchedMedicine.name;
    if (matchedMedicine.name.toLowerCase() === 'other') {
      medDisplayName = `Other (${instruction})`;
    }

    if (matchedMedicine) {
      medInstance.medicine = {
        id: matchedMedicine.__i.guid,
        display: medDisplayName,
        model: 'Medicine'
      };
    }
    await this.medicalPrescriptionService.createMedicalPrescription(medInstance).toPromise();
    this.getPrescribedMed();
  }

  public async delPrescribedMed(medId) {
    const doDelete = confirm('Are you sure you want to delete this medicine?');
    if (doDelete) {
      await this.medicalPrescriptionService.delMedicalPrescription(medId).toPromise();
      this.getPrescribedMed();
    }
  }

  public getGroups() {
    const state = this.stateModel.get();
    if (Array.isArray(state.groups) && state.groups.length) {
      return;
    }
    this.groupService.getAll().subscribe(res => {
      state.groups = res.filter(g => g.id !== APPLICATION_ORGANIZATION._id);
      getAllActionTypes().subscribe(res => {
        state.phaseNames = res;
        this.stateModel.set(state);
      });
    });
  }

  public getGroupById(id:string):Promise<Group[]>
  {
    return this.groupService.getAllAsync();
  }

  public getPatients(query?:any, size?:number){
    return this.userFuncService.getAllAsync(query, size);
  }


   // TODO: change this name
  public backToList() {
    const state = this.stateModel.get();
    this.router.navigateByUrl(`/patient/${state.patientInfo.UserId}`);
  }

  public async saveWeekDates(thisWeek) {
    const state = this.stateModel.get();
    const weekEvent = state.weekEvents.find(event => event.Id === thisWeek.eventId );
    // If baseline, update treatmentStartDate
    if(weekEvent.phaseName === "baseline"){
      const user = await this.userFuncService.getUser(state.userId).toPromise();
      user.treatmentStartDate = thisWeek.startDate.toISOString();
      this.userFuncService.saveAsync(user);
    }

    weekEvent.startDate = thisWeek.startDate.toISOString();
    weekEvent.endDate = thisWeek.endDate.toISOString();
    this.calendarEventService.save(weekEvent).subscribe(r => {
      state.weekModified = false;
      this.setState(state);
    });
  }

  public async addCalendarEvent(patientId, type) {
    const state = this.stateModel.get();
    const result = await this.calendarEventService.addCalendarEvent(patientId, type).toPromise();
    await this.loadPatientCalculations(false);
    this.setState(state);
    return result;
  }

  public async deleteCalendarEvent(id) {
    const state = this.stateModel.get();
    // Find the _id from the id
    const timepoint = state.weekEvents.find(obj => obj.Id === id );
    const timepoint_id = timepoint.__i.guid
    await this.calendarEventService.deleteCalendarEvent(timepoint_id).toPromise();
    await this.loadPatientCalculations(false);
    this.setState(state);
  }

  public async addCheckin(event) {
    const state = this.stateModel.get();
    await this.calendarEventService.save(event).toPromise();
    this.loadPatientCalculations(false);
    this.setState(state);
  }

  /**
   * Does calculation for timeInBed for the selectedWeek of the current state model
   */
  public calculateTIB(bedTime?, outOfBed?){
    const state = this.stateModel.get();
    state.selectedWeek.bedTime = bedTime;
    state.selectedWeek.outOfBed = outOfBed;
    let result = this.calculationService.calculateTIB(state.selectedWeek.bedTime, state.selectedWeek.outOfBed);
    state.selectedWeek.timeInBed = result;
    return result;
  }


  public async shiftPatientData(days: number, patientId) {
    const assessments = await this.assessmentService.getAllAsync({
      'Payload.user.id': patientId
    });
    const promises = [];
    const user = await this.userFuncService.getUser(patientId).toPromise();
    user.treatmentStartDate = moment.utc(user.treatmentStartDate).add(days, 'days').toISOString()

    promises.push(this.userFuncService.saveAsync(user));

    assessments.forEach(assessment => {
      assessment.assessmentDate = moment.utc(assessment.assessmentDate).add(days, 'days').toISOString();
      promises.push(this.assessmentService.save(assessment).toPromise());
    });

    const calendarEvents = await this.calendarEventService.getAllAsync({$and: [{ 'Payload.user.id': patientId }]});
    calendarEvents.forEach(event => {
      event.startDate = moment.utc(event.startDate).add(days, 'days').toISOString();
      event.endDate = moment.utc(event.endDate).add(days, 'days').toISOString();
      promises.push(this.calendarEventService.save(event).toPromise());
    });
    Promise.all(promises).then(() => window.location.reload());
  }
}
