<div class="container-fluid" *ngIf="dashboardStateService.state$ | async as appState">
  <patient-dialog
    modalType="consent"
    [isOpen]="isConsent && isEULAAccepted && (isResearch || isConsentRequired)"
  >
  </patient-dialog>

  <patient-dialog
    modalType="EULA"
    [isOpen]="!isEULAAccepted"
  >
  </patient-dialog>

  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <h3 class="home-title pb-2 pt-2">Roll Call</h3>
        </div>
      </div>
      
        <div class="table-container">
          <div class="row align-items-center">
            <h4 class="mr-3 mt-3 ml-3">All Patients</h4>
            <span class="input-container mb-0">
              <input
                matInput
                type="text"
                placeholder="Filter patients"
                (keyup.enter)="setFilter($event.target.value)"
                (keyup)="ifReset($event.target.value)"
                #filterValue
              >
            </span>
            <button
            *ngIf="dataSource.filter"
            (click)="setFilter('', pageSize); filterValue.value='';"
            title="clear" class="text-danger"
          >
            Clear Filter
          </button>
          <button
          *ngIf="!dataSource.filter"
          (click)="setFilter(filterValue.value)"
          [disabled]="!filterValue.value"
          title="filter" 
        >
          Filter
        </button>

          </div>
         
          <div class="rollcall-table-container">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="table rollCall mt-1"
              matSortActive="sleepPrescription"
              matSortDirection="asc"
              matSortDisableClear
            >
              <ng-container matColumnDef="icon">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  &nbsp;
                </th>
                
                <td mat-cell *matCellDef="let element">
                  <i
                    class="fas fa-chart-line"
                    *ngIf="element.icon.trendingUp"
                  >
                  </i>

                  <i
                    class="fas fa-angle-double-down"
                    *ngIf="element.icon.trendingDown"
                  >
                  </i>
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="userString"
                >
                  Patient ID
                </th>

                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  <a
                    routerLink="/patient/{{ element.id }}"
                    class="table-user-id d-inline-block text-truncate"
                  >
                    {{ element.userString }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="appActivation">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Activated
                </th>

                <td
                  class="text-center"
                  mat-cell
                  *matCellDef="let element"
                >
                <i class="fa" [ngClass]="element.EULASignedDate !== null ? 'fa-check' : '0'"></i>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="status"
                >
                  Current Timepoint
                </th>

                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.status }}
                </td>
              </ng-container>

              <ng-container matColumnDef="flags">
                <th mat-header-cell class="tableHeader" *matHeaderCellDef
                  style="width: 100%;"
                > Flags</th>
                <td mat-cell *matCellDef="let element">
                  <div *ngFor="let thisFlag of element.flags">
                    <!-- Show alarms first e.g. SI -->
                    <span
                      *ngIf="thisFlag.isActive && thisFlag?.isAlarm"
                      title="{{ thisFlag.desc }}"
                      class="flag-circle bg-yellow"
                      (mouseenter)="hoverPatientFlag(thisFlag.name)"
                    >
                      {{ thisFlag.name }}
                    </span>
                  </div>

                  <div *ngFor="let thisFlag of element.flags">
                    <span
                      *ngIf="thisFlag.isActive && !thisFlag?.isAlarm"
                      title="{{ thisFlag.desc }}"
                      class="flag-circle"
                      (mouseenter)="hoverPatientFlag(thisFlag.name)"
                    >
                      {{ thisFlag.name }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="efficiency">
                <th
                  mat-header-cell
                  class="tableHeader"
                  *matHeaderCellDef
                  mat-sort-header="efficiency"
                >
                  Sleep Efficiency
                </th>

                <td class="text-center" mat-cell *matCellDef="let element">{{ element.efficiency }}%</td>
              </ng-container>

              <!--ng-container matColumnDef="quality">
                <th mat-header-cell class="tableHeader" *matHeaderCellDef mat-sort-header="quality">
                  Sleep Quality
                </th>
                <td  class="text-center" mat-cell *matCellDef="let element">
                  {{ element.quality | sleepQuality }}
                </td>
              </ng-container-->

              <ng-container matColumnDef="adherence">
                <th
                  mat-header-cell
                  class="table-header"
                  *matHeaderCellDef
                  mat-sort-header="adherence"
                >
                  Sleep Adherence
                </th>

                <td  class="text-center"
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.adherence }}%
                </td>
              </ng-container>

              <ng-container matColumnDef="sleepPrescription">
                <th
                  mat-header-cell
                  class="table-header"
                  *matHeaderCellDef
                  mat-sort-header="sleepPrescription"
                >
                  Prescription Due
                </th>

                <td  class="text-center"
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.sleepPrescription.status === 'PAST_DUE' ? 'past-due' : ''"
                >
                  {{element.sleepPrescription.display}}
                </td>
                
              </ng-container>

              <tr 
                mat-header-row
                *matHeaderRowDef="displayedColumns;
                sticky: true"
              >
              </tr>

              <tr
                mat-row
                *matRowDef="let row;
                columns: displayedColumns"
              >
              </tr>
            </table>
          </div>
            
            <span *ngIf="dataSource.filteredData.length === 0">
              No records found
            </span>
          </div>

          <mat-paginator
            hidePageSize="true"
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            (page)="onPaginateChange($event)"
          >
          </mat-paginator>
    </div>
  </div>
