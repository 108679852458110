import { LogDefinitionTypes } from "../../../../noctem-lib/src/constants/constants";
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition, AssessmentGroupScoreFieldDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const gad7Fields: Array<FieldDefinitionBase> =
[

//#endregion
new AssessmentGroupScoreFieldDefinition({
  _type: FieldType.AssessmentGroupScore,
  name: 'gad7-overall-lowest',
  unit: UnitType.Number,
  aggregateType: 'average',
  abbreviation: 'Gad 7 Overall',
  description: `Gad 7 Overall`,
  assessmentType: LogDefinitionTypes.GAD7
}),
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'gad7-overall',
    unit: UnitType.Number,
    aggregateType: 'average',
    abbreviation: 'Gad 7 Overall',
    description: `Gad 7 Overall`,
    assessmentType: LogDefinitionTypes.GAD7
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-nervous',
    unit: UnitType.Number,
    abbreviation: 'Nervous',
    description: `Nervous`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_NERVOUS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-worry',
    unit: UnitType.Number,
    abbreviation: 'Worry',
    description: `Worry`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_WORRY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-worry-too-much',
    unit: UnitType.Number,
    abbreviation: 'Worry Too Much',
    description: `WTM`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_WORRY_TOO_MUCH'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-relaxing',
    unit: UnitType.Number,
    abbreviation: 'Relaxing',
    description: `Relaxing`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_TROUBLE_RELAXING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-restless',
    unit: UnitType.Number,
    abbreviation: 'Restless',
    description: `Restless`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_RESTLESS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-irritable',
    unit: UnitType.Number,
    abbreviation: 'Irritable',
    description: `Irritable`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_IRRITABLE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-afraid',
    unit: UnitType.Number,
    abbreviation: 'Afraid',
    description: `Afraid`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_AFRAID'
  }),
  //#endregion
];
