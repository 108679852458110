import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const fatigueScanningAlgorithm = new SleepModuleDefinition({
  name: 'fatigue-scanning',
  isMedic: false,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleepy-feels',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    })

  ],
});

