import { UserAgreement } from "../../../../core";

export default {
  id:1,
  name:'nhrc',
  imgName:'nhrc.png',
  pages: [
    {
      requireInitials: true,
      content: `
      <div class='container text-center'>
        <h4 class="center">VOLUNTARY CONSENT TO PARTICIPATE IN A RESEARCH STUDY</h4>
        <h4 class="center">Optimizing the Scalability of Evidence-Based Behavioral Sleep Medicine Practices with a mobile Health Platform </h4>
        <p class="center">Rachel Markwald, PhD, Principal Investigator, Naval Health Research Center</p>
      </div>
        <p>You may be eligible to take part in this research study. This form gives you important information about the study.</p>
        <p>Please take time to carefully review this information. You should talk to the researchers about the research study and ask them any questions you have. You may also wish to talk to others (for example, your friends, family, or your personal physician) about your potential participation in this research study. You do not have to take part in this study. Participation is voluntary. You may also leave the research study at any time without penalization.</p>
        <p><b>Key Information</b></p>
        <p>The following focused information is being presented to assist you in understanding the key elements of this study, as well as the basic reasons why you may or may not wish to consider taking part. This section is only a summary; more detailed information, including how to contact the research team for additional information or questions, follows within the remainder of this document. </p>
        <p><b>Purpose:</b> The purpose of this study is to examine how a sleep digital platform called NOCTEM, can be best used to treat insomnia and other sleep disturbances in service members, veterans,
          and dependents who receive care at participating military treatment facilities and clinics.
          The study also aims to determine the magnitude of sleep improvements in patients who use the NOCTEM app with their clinicians.
        </p>
        <p>
          We invite you to take part in a research study examining the effectiveness of a digital system in improving the sleep of service members. This digital system includes a portal for clinicians that is linked to the smart device application that your patients will use. You are being asked if you would like to participate in this study because you are a military health care clinician who would like to treat patients for insomnia and sleep disturbance. This research is being conducted by researchers at the Naval Health Research Center (NHRC) in San Diego, California, with funding from the Medical Technology Enterprise Consortium and Military Operational Medicine Research Program.
        </p>
  `
    },
    {
      requireInitials: true,
      content: `
    <p><b>Procedures and Activites: </b>In general, your participation in this research involves spending approximately six weeks observing your patient’s sleep habits via a portal on your computer. You may set sleep prescriptions,
    recommend individualized sleep tactics, and will have the ability to confidentially communicate via text message with your patients during this time. On their personal smart devices,
    your patients will be asked to complete daily morning and evening sleep logs, and brief follow-up assessments that will be completed 1 and 3 months after the intervention is complete.
    You may choose to meet in-person with your patient(s) as many times as you deem necessary. However, this specific intervention only requires that you meet in-person one time to help your patient
    download the application on their phone and teach them how to use it. You will receive a training (either in an online webinar format or in-person) on how to use this mobile health platform and you
    will receive continuing education credits for your attendance at this training.</p>

    <p><b>Time Commitment Summary (for an estimated 36 patients)</b></p>
    <div class="container table">
        <div class="row">
            <div class="col center"><b>Event</b></div>
            <div class="col"><b>Approx. Time</b></div>
        </div>
        <div class="row">
            <div class="col"><p><b>Visit 1:</b> Consent process, download and learn how to use NOCTEM app, complete training assessment</p></div>
            <div class="col"><p>~2 hours</p></div>
        </div>
        <div class="row">
            <div class="col"><p><b>Assessment 1:</b> Clinicians will review the brief assessments patients will complete on their phone or computer.</p></div>
            <div class="col"><p>~9 hours</p></div>
        </div>
        <div class="row">
            <div class="col"><p><b>Home App Use:</b> Review the daily "Morning" and "Night" logs that patients complete for six weeks</p></div>
            <div class="col"><p>~1.2 hours</p></div>
        </div>
        <div class="row">
            <div class="col"><p><b>Clinician Recommendations:</b> Clinician will recommend various sleep tactics and a sleep prescription for each patient</p></div>
            <div class="col"><p>~6 hours</p></div>
        </div>
        <div class="row">
            <div class="col"><p><b>Total Time</b></p></div>
            <div class="col"><p>~18.2 hours</p></div>
        </div>
    </div>
    <p><b><i>Note:</i></b> The amount of time that the clinician and patient spend using NOCTEM may vary based on the clincian's judgement.</p>
    <p><b>Risks:</b> There is a small potential risk of loss of privacy and/or confidential information about you or your patient.</p>
    <p><b>Benefits:</b> There is no certain benefit for participating in the study.  You may find it easier to communicate relevant information as it occurs with your patient through the secure text messaging system embedded in the app. This research study may help researchers learn more about the effectiveness of smart device-assisted sleep interventions in improving the sleep of service members with disturbed sleep. Neither you, nor your patients will receive payment for your participation in this study.</p>
    <p>Your decision will not affect your future care at Naval Medical Center San Diego. If you decide to take part in this research study, you will be asked to sign this document. Before you sign this document, be sure you understand what the research study is about in all sections of the consent form, including the risks and possible benefits to you.</p>
    <p>Please tell the researchers if you are taking part in another research study.</p>
  `
    },
    {
      requireInitials: true,
      content: `
    <p><b><u>WHAT IS THE PURPOSE AND DURATION OF THIS RESEARCH AND WHO WILL TAKE PART?</u></b></p>
    <p>You are being asked to take part in this research study because you are a military health care clinician who would like to treat patients for insomnia and sleep disturbance. The purpose of this research study is to learn about the effectiveness of a digital system in improving the sleep of service members. This digital system includes a portal for clinicians that is linked to the smart device application that your patients will use. The duration of participation per visit is about 18 hours per patient over six weeks.</p>
    <p>There will be about 36 Clinicians taking part in the study at Naval Medical Center San Diego, over a period of one year.</p>
    `
    }
    ,
    {
      requireInitials: true,
      content: `
      <p><b><u>SCREENING PROCESS TO QUALIFY FOR PARTICIPATION IN THIS STUDY</u></b></p>
      <p>You are eligible for this study if: Your patient(s) have sleep difficulties and are willing to try to improve their sleep by modifying some of their daytime and nighttime behaviors known to improve sleep quality; and you are confident it is safe for your patient(s) to participate in the study.</p>
      <p>Since pregnant or breastfeeding women may already experience sleep loss, they may not benefit from the recommendations you will share and will not be included in this study. Also, persons who have become a parent to a newborn child born in the last 9 months may already experience sleep loss, and may not benefit from the clinician’s recommendations, and therefore will not be included in the study.</p>
      <p><b><u>WHAT WILL HAPPEN IF YOU DECIDE TO BE IN THIS RESEARCH?</u></b></p>
      <p>If you decide to take part in this study, there are <b>4 steps</b> that will happen over the 3-month duration.</p>
      <p><b>1. Consent to participating and downloading the NOCTEM platform.</b></p>

      <p>After providing your consent to participate in this study, you will begin using the NOCTEM clinician computer portal. You will be provided with a unique activation code to start using the portal on your computer. Then, you will be asked to attend an orientation on how to use the app and the mobile health platform, and you will receive continuing education (CE) credits for your attendance at this training. This training seminar may be either in an online webinar format or in- person. You will also receive an instruction sheet to help you remember how to use the app/platform.</p>
      <p>When you determine that one of your patients may benefit from insomnia treatment delivered by NOCTEM, you will ask those participating patients to download the NOCTEM app on their personal smart device. Your patients may download the NOCTEM app on all of their smart devices if they want, including a tablet, laptop, or desktop computer.</p>

      <p><b>2. Ask your patient(s) to complete the morning and evening lgos every day, and weekly questionnaires once per week for 4 to 6 weeks.</b></p>
      <p>Using the NOCTEM app, your patients will be asked to complete brief morning and evening logs that will ask questions about their sleep.  The total time for each morning entry is about 90 seconds.  Each evening entry takes about 60 seconds.  Additionally, your patients will be prompted by the NOCTEM app to complete weekly questionnaires that will each take about 5 minutes to complete.  Most patients complete the acute intervention phase in 4 weeks, but to accomodate for everyone's unique schedule, the duration of this phase can last up to 6 weeks.  The information your patient provides on the sleep/wake logs and weekly questionnaires will be shared and reviewed by you regularly.</p>

      <p><b>OPTIONAL STEP:</b> If your patient(s) are using wearable or non-wearable sensors, they can easily link them to the NOCTEM app. The information (completely de-identified) that is reported by your patients, will be shared with you, and may be used by the research team to develop better sleep/wake detection algorithms. These algorithms are specifically designed for people with sleep disturbances who are receiving sleep treatments.</p>
      <p><b>3. Ask your patient to follow specialized recommendations through the NOCTEM app to improve their sleep. </b></p>
      <p>The information your patient enters through the app will be used to provide recommendations to improve their sleep. In the first four to six weeks of the study, you may agree with the app's automatic recommendations, or you may make changes to the recommendations before you forward them to your patient(s), through the portal. The recommendations will give your patient(s) specific instructions about what to do with regard to their sleep, how to adhere to these recommendations, and why they are being asked to do this. The recommendations are changes in their daytime and nighttime behaviors that are known to be helpful for sleep.</p>

      <p>During the study, you will be able to send and receive text messages via the portal to and from your patient(s) with a text messaging system that is built into the NOCTEM platform. This text messaging system is not the one attached to your patient's cell phone plan, and does not use their personal data plan.</p>
      <p><b>4. The NOCTEM app will prompt your patients to complete the follow-up questionnaires 1 and 3 months after they have finished their treatment with you.</b></p>
      <p>After your patient(s) has completed treatment, they will be automatically prompted by the NOCTEM app to complete the same questionnaires again through the app 1 and 3 months later, or for the duration of their care with you. These questionnaires will allow the research team to assess (using completely de-identified information) if sleep improvements are maintained over time, and/or if new improvements occur after treatment.</p>

  `
    },
    {
      requireInitials: true,
      content: `
    <p><b><u>WHAT ARE THE RISKS OR DISCOMFORTS FROM BEING IN THIS RESEARCH?</u></b></p>
    <p>The investigators believe that the risks or discomforts are minimal and do not exceed the typical risks expected in clinical practice or use of smartphone and web-based computer tools.  Although efforts are made to protect your research study records, there is always a risk that someone could get access to the personal information in your medical records or other information researchers have stored about you.</p>

    <p>The main risks and discomforts for participating clinicians are: </p>
    <p><u>Breach of confidentiality:</u><br />It is possible that information regarding your patient(s) sleep and psychological health will be discovered by individuals outside of study personnel, despite careful steps to protect confidentiality. Breach of confidentiality rarely occurs (in less than 1 out of 100 participants). Maintaining strict security of the information that you or your patient(s) provide will minimize these risks. Study data will be kept strictly confidential and participants’ identities will not be revealed in any publication. All patients have a unique identifier number that will be used on forms and for data storage purposes, and this number will not be associated with any PII/PHI. No results will be released to employers, ensuring no impact on future insurability or employability. Computer databases are protected by several procedures, including password protection of patient data and a firewall around the entire NOCTEM network. The only party other than you and the study staff that will have access to identifiable information will be the NHRC Institutional Review Board (IRB).</p>
    <p><u>Risks specific to the use of smart devices:</u><br />
        If communicating with your patients via text for therapeutic and scheduling purposes, be aware that although every reasonable effort may be taken, confidentiality during internet communication and text messaging activities cannot be guaranteed and it is possible that information may be intercepted and used by others not associated with this study.
    </p>
    <p>
      The fact that you and your patient(s) are participating in a research study and that your patients are undergoing certain research procedures may also be made known to individuals involved in other administrative activities associated with the conduct of the study. However, this does not include any information regarding the results of the procedures. Study personnel have signed confidentiality statements indicating that they may not disclose information acquired in conjunction with this research study to anyone without your or your patient's consent.
    </p>
    <p>
      There may also be other risks of taking part in this study that we do not yet know about.
    </p>
    <p><b><u>WHAT ARE THE POSSIBLE BENEFITS FROM THIS RESEARCH?</u></b><br/>
    There are no direct benefits to you for taking part in the study. However, others may benefit in the future from the information learned during this study. You may also learn more about your patient(s) sleep and about healthy sleep practices that help sleep quality. You may see improvements in your patient(s) sleep quality. Your patient may find it easier to communicate relevant information as it occurs with you through the secure text messaging system embedded in the app. Finally, the information from this research study may help researchers learn more about the effectiveness of smart device-assisted sleep interventions in improving the sleep of Service Members with disturbed sleep. This information could help improve readiness and overall wellbeing of US Service Members.
    </p>
    <p><b><u>WHAT ARE THE ALTERNATIVES TO TAKING PART IN THIS RESEARCH?</u></b><br/>
    <p>The alternative to participation is to not enroll in this study.</p>
    <p><b><u>IS THERE COMPENSATION FOR YOUR PARTICIPATION IN THIS RESEARCH?</u></b></p>
    <p>No, you will not receive any compensation for participating in this study.</p>
    <p><b><u>ARE THERE COSTS FOR PARTICIPATING IN THIS RESEARCH?</u></b></p>
    <p>No, there are no costs to you for taking part in this research study.</p>
  `
    },
    {
      requireInitials: true,
      content: `

    <p><b><u>PRINCIPAL INVESTIGATOR (the person(s) responsible for the scientific and technical direction of the study):</u></b></p>
    <p>
      Dr. Rachel Markwald, Ph.D. <br />
      Sleep Physiologist
      <a href="mailto:rachel.r.markwald.civ@mail.mil">rachel.r.markwald.civ@mail.mil</a>
    </p>
    <p>
     <b>For questions about the technical aspects and the content of the NOCTEM app, portal, and messaging system</b>, contact Dr. Anne Germain at 412-897-3183 or <a href="mailto:anne@noctemhealth.com">anne@noctemhealth.com</a>. She is the overall Principal Investigator (PI) of the project and lead project manager for the NOCTEM team, the company who develops, deploys, and tests the NOCTEM platform.
    </p>
    <p><b><u>STUDY SPONSOR (the organizations or persons who oversee the study and are responsible for analyzing the study data)</u></b></p>
    <p>Naval Health Research Center</p>
    <p>As the sponsor of this research, the Department of Defense may have access to your research data in accordance with DoDI 3216.02.</p>
    <p><b><u>SOURCE OF FUNDING</u></b></p>
    <p>This research is being conducted by NOCTEM, LLC with funding from the Medical Technology Enterprise Consortium.</p>
    <p><b><u>LOCATION OF THE RESEARCH</u></b></p>
    <p>This study is being conducted by researchers at NOCTEM LLC, the Naval Health Research Center (NHRC) in San Diego, California, the Naval Medical Center San Diego (NMCSD), and the University of Pittsburgh. Study procedures will take place primarily through the NOCTEM app, which you can access from your own home or office.</p>
    <p><b><u>DISCLOSURE OF FINANCIAL INTERESTS AND OTHER PERSONAL ARRANGEMENTS</u></b></p>
    <p>A member of the research team, Dr. Anne Germain, is the founder and CEO of NOCTEM, LLC.</p>
  `
    },
    {
      requireInitials: true,
      content: `
      <p><b><u>WHO WILL SEE MY INFORMATION (PRIVACY) AND HOW WILL IT BE PROTECTED (CONFIDENTIALITY)?</u></b></p>
      <p>Records of your participation in this research study may only be disclosed in accordance with state and federal law, including the Federal Privacy Act, 5 U.S.C.552a, and its implementing regulations. DD Form 2005, Privacy Act Statement - Military Health Records, contains the Privacy Act Statement for the records. A copy of DD Form 2005 can be given to you upon request, or you can read on-line at:</p>
      <p><a href="https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf">https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf</a></p>
      <p>The research team will keep your research records. These records may be looked at by staff from the NHRC, the Institutional Review Board (IRB), and the DoD Higher Level Review as part of their duties. These duties include making sure that the research participants are protected.Confidentiality of your records will be protected to the extent possible under existing regulations and laws but cannot be guaranteed.</p>
      <p>Security and confidentiality in a mobile health application is of paramount importance. Procedures to protect the confidentiality of the data in this study include but are not limited to:</p>
      <p>Layered security measures to protect the privacy and confidentiality of the data, including: (1) the use of a forced PIN/password to access the app; (2) a strong authentication procedure that combines a PIN/password, the phone number of the device, and the device’s unique International Mobile Equipment Identity (IMEI) number; (3) encryption of all communication between the smartphone and the server using the AES (Advanced Encryption Standard) 192-bit key; (4) A novel security method that allows the research team to disable the app and to erase records from the device in the event of a lost smart device while preserving the records on the secure server;(5) a secure messaging system between your portal and your patient's smart device will be used in the place of SMS/text messaging, and lastly, (6) a secure server behind a firewall with security policy in place that includes a dedicated cloud, regular back-up of data, a monitored network with active security measures, and a well-defined, role-based, access to the database.</p>
      <p>You will receive a unique login and choose your own password to access the NOCTEM portal on your computer, and your patients will receive their own unique login for the app. You and members of the research team will have access to your patient(s) data. All data collected about your patients by NOCTEM, however, will be completely de-identified.</p>
      <p>When your patient(s) first enroll in the study and download the app, they will be asked to provide their phone number (to link their cellular-enabled smart device to your clinician portal), which will be maintained indefinitely. However, no private identifiable information will be associated with data at any time during this study, therefore minimizing the risk of breach to confidentiality. Maintenance of data will allow for further analysis, as well as comparison of this dataset to others.</p>
      <p>You, or your patient(s) may change your/their mind and revoke your/their permission to participate at any time. Additionally, as is true with any type of standard-of-care treatment, your patients have the right to notify you if they would like to stop receiving their insomnia treatment via NOCTEM at any time. To revoke your permission, you must write to the person in charge of the study, Dr. Rachel Markwald, at rachel.r.markwald.civ@mail.mil, as well as the NOCTEM team (NOCTEM4health@gmail.com) in order to terminate access to the portal. When you revoke permission, no new health information about your patient(s) will be gathered after that date and you/your patient(s) may no longer be allowed to further participate in the study.Information that has already been gathered may still be used and there is no guarantee that it will be removed from the electronic database for this study.
      Researchers will make every effort to protect your privacy and confidentiality; however, there are risks of breach of information security and information loss.
      Complete confidentiality cannot be promised for military personnel, because information regarding your health may be required to be reported to appropriate medical or command authorities to ensure the proper execution of the military mission, including evaluation of fitness for duty.
      Drs. Rachel Markwald and Anne Germain are responsible for storing your patient(s) completely de-identified health information and other information collected about them during the study. The researchers agree to safeguard your protected health information by using and disclosing it only as permitted by you in this consent or as directed by state and federal law.
      Information gained from your participation in this research study may be published in literature, discussed for educational purposes, and used generally to further science. You will not be personally identified when your information is shared in these ways; all information will de- identified.
      <p><b><u>LONG TERM USE OF DATA</u></b></p>
      <p>The investigator has requested to save selected data collected from your participation in this research study for possible use in future research. Identifiers will be removed, and de-identified information may be shared for future research. This future research may be in the same area as the original study or it may be for a different kind of study. You will be provided choices at the end of this consent form to allow or deny use in future research studies.</p>
      <p>Any future research using your retained data will require a research protocol for the proposed study approved by an Institutional Review Board (IRB) (a committee responsible for protecting research participants) or other authorized official responsible for protecting human subjects of research. The data protections for privacy and confidentiality described in this consent form will apply to any future use of your stored data.</p>
      <p><b><u>INCIDENTAL FINDINGS</u></b></p>
      <p>We do not anticipate discovering any abnormal medical results as part of this study. If we find something of urgent medical importance to you, we will inform you, although we expect that this will be a very rare occurrence.</p>
  `
    },
    {
      requireInitials: true,
      content: `
      <p><b><u>VOLUNTARY PARTICIPATION</u></b></p>
      <p>The decision to take part in this research study is completely voluntary on your part which means you do not have to take part if you do not want to. You may also leave the research study at any time. If you choose not to take part in this research study or if you leave the study before it is finished, there will be no penalty or loss of benefits to which you are otherwise entitled.</p>
      <p>You will be informed if significant new findings develop during the course of this research study that may relate to your decision to continue participation.</p>
      <p><b><u>WHAT HAPPENS IF I WITHDRAW FROM THIS RESEARCH?</u></b></p>
      <p>Should you choose to withdraw, you must write to the person in charge of the study, Dr. Rachel Markwald, at rachel.r.markwald.civ@mail.mil, as well as the NOCTEM team (NOCTEM4health@gmail.com) in order to terminate access to the app. When you revoke permission, no new health information about you will be gathered after that date and you may no longer be allowed to further participate in the study. Information that has already been gathered may still be used and there is no guarantee that it will be removed from the electronic database for this study.</p>
      <p>The principal investigator of this research study may terminate your participation in this research study at any time if she determines this to be in your best interest, if you are unable to comply with the procedures required, or if you no longer meet eligibility criteria.</p>
      `
    },
    {
      requireInitials:true,
      content: `
      <p><b><u>CONTACT INFORMATION</u></b></p>
      <p>The Principal Investigator or a member of the research staff will be available to answer any questions throughout this study.</p>
      <p>Principal Investigator: Rachel Markwald, Ph.D. <br />Phone: (619) 767-4494<br/>Mailing Address: 140 Sylvester Rd., San Diego, CA 92106-3521 <br/>Email: rachel.r.markwald.civ@mail.mil</p>
      <p><b><u>Institutional Review (IRB) Office</u></b></p>
      <p>If you have any questions about your rights as a research participant or if you have concerns or complaints about the research study, please contact the IRB Chair, Lt Col Patricia Rohrbeck, at: Phone: 619-553-8424; Email: usn.nhrc.irb@mail.mil.</p>
      <p>IF THERE IS ANY PORTION OF THIS DOCUMENT THAT YOU DO NOT UNDERSTAND, ASK THE INVESTIGATOR BEFORE SIGNING. YOU MAY CONSULT WITH YOUR PERSONAL PHYSICIAN OR LEGAL ADVISOR, IF YOU WISH.</p>
      <p>A signed and dated copy of this document will be given to you.</p>
        `
    },
    {
      requireInitials: false,
      content: `
    <p><b>Consent for future use.</b><br /> By signing below, you consent to use of information collected about you during this study to be used for future research efforts which may include research on behaviors and habits that predict sleep disturbances, and/or predict response to sleep interventions. Your research data may also be used to advance computerized methods to adapt and tailor sleep assessments and sleep interventions more precisely for one or groups of individuals. At present, the knowledge needed to estimate sleep disturbances and provide personalized interventions is limited to clinicians in specialty care clinics, such as sleep clinics. We aim to develop reliable computerized methods that support a greater number of clinicians to be able to reach and offer solid sleep assessment and interventions to a greater number of individuals, regardless of their geographical location. For these reasons, we request your permission to save your data for potential use in the future.</p>
  `
    }
   ]
} as UserAgreement
