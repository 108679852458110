// Did this as a separate variable so that way they stay in the same order
// where ever they are used
//const TRAUMA_QUESTIONS = ["pcl5"];

export const trauma = {
  "name": "trauma",
  "description": "Traumatic Events",
  "instructions": "",
  "questions": [
    {
      "questionText": "Stressful or traumatic events include situations where actual or threatened death, serious injury, or sexual violence occur.  Stressful or traumatic events can happen as part of one's personal life or professional duties",
      "questionEmphasis":
        "Have you ever experienced or directly witnessed such event(s) in your life?",
      "questionType": "radio",
      "uniqueAnswerId": "TRAUMA",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "redirectOptions": [
        { "value": true, "assessmentsToRemove": [], "assessmentsToAdd": ["pcl5"] },
        { "value": false, "assessmentsToRemove": ["pcl5"], "assessmentsToAdd": [] }
      ]
    }
  ]
};
