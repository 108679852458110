export const nsiDef = {
    name: 'nsi',
    description: 'Neurobehavioral Symptom Inventory (NSI)',
    instructions: '',
    questions: [
      /* commenting now, to only use the template from the database 
      {
        id: 0,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Feeling dizzy',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_FEELING_DIZZY'
      },
      {
        id: 1,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Loss of balance',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_LOSS_BALANCE'
      },
      {
        id: 2,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Poor coordination, clumsy',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_POOR_COORDINATION'
      },
      {
        id: 3,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Headaches',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_HEADACHES'
      },
      {
        id: 4,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Nausea',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_NAUSEA'
      },
      {
        id: 5,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Vision problems, blurring, trouble seeing',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_VISION_PROBLEMS'
      },
      {
        id: 6,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Sensitivity to light',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_LIGHT_SENSITIVITY'
      },
      {
        id: 7,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Hearing difficulty',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_HEARING_DIFFICULTY'
      },
      {
        id: 8,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Sensitivity to noise',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_NOISE_SENSITIVITY'
      },
      {
        id: 9,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Numbness or tingling on parts of my body',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_NUMBNESS'
      },
      {
        id: 10,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Change in taste and/or smell',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None – Rarely if ever present; not a problem at all', value: 0 },
          { display: 'Mild – Occasionally present, but it does not disrupt my activities; I can usually continue what I’m doing; doesn’t really concern me', value: 1 },
          { display: 'Moderate – Often present, occasionally disrupts my activities; I can usually continue what I’m doing with some effort; I feel somewhat concerned.', value: 2 },
          { display: 'Severe – Frequently present and disrupts activities; I can only do things that are fairly simple or take little effort; I feel I need help.', value: 3 },
          { display: 'Very Severe – Almost always present and I have been unable to perform at work, school or home due to this.', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_TASTE_CHANGE'
      },
      {
        id: 11,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Loss of appetite or increased appetite',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_LOST_APPETITE'
      },
      {
        id: 12,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Poor concentration, can’t pay attention, easily distracted',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_POOR_CONCENTRATION'
      },
      {
        id: 13,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Forgetfulness, can’t remember things',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_FORGENTFULNESS'
      },
      {
        id: 14,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Difficulty making decisions',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_DIFFICULTY_MAKING_DECISIONS'
      },
      {
        id: 15,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Slowed thinking, difficulty getting organized, can’t finish things',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_SLOWED_THINKING'
      },
      {
        id: 16,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Fatigue, loss of energy, getting tired easily',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_FATIGUE'
      },
      {
        id: 17,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Difficulty falling or staying asleep',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_DIFFICULTY_FALLING_ASLEEP'
      },
      {
        id: 18,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Feeling anxious or tense',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_ANXIOUS'
      },
      {
        id: 19,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Feeling depressed or sad',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_DEPRESSED'
      },
      {
        id: 20,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Irritability, easily annoyed',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None – Rarely if ever present; not a problem at all', value: 0 },
          { display: 'Mild – Occasionally present, but it does not disrupt my activities; I can usually continue what I’m doing; doesn’t really concern me', value: 1 },
          { display: 'Moderate – Often present, occasionally disrupts my activities; I can usually continue what I’m doing with some effort; I feel somewhat concerned.', value: 2 },
          { display: 'Severe – Frequently present and disrupts activities; I can only do things that are fairly simple or take little effort; I feel I need help.', value: 3 },
          { display: 'Very Severe – Almost always present and I have been unable to perform at work, school or home due to this.', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_IRRITABILITY'
      },
      {
        id: 21,
        questionText:
            'Please rate the following symptoms with regard to how much they have disturbed you SINCE YOUR INJURY',
        questionEmphasis:
          'Poor frustration tolerance, feeling easily overwhelmed by things',
        questionType: 'radio',
        autofill: false,
        answerOptions: [
          { display: 'None - Rarely if ever present', value: 0 },
          { display: 'Mild - Occasionally present, but does not disrupt activities', value: 1 },
          { display: 'Moderate - Often present, occasionally disrupts activities', value: 2 },
          { display: 'Severe - Frequently present and disrupts activities', value: 3 },
          { display: 'Very Severe - Almost always present and unable to do activities', value: 4 }
        ],
        conditionalQuestions: [],
        uniqueAnswerId: 'NSI_POOR_FRUSTRATION_TOLERANCE'
      },
      */
    ]
  };
  