import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const medicationUse: AssessmentDefinition = {
  name: 'medicationUse',
  description: 'Medication Use',
  instructions: null,
  questions: [
    {
      id: 0,
      name:'medication-use-add',
      questionText: 'In the past week, did you ADD a medication or OTC product to help you sleep?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          id: 1,
          name: 'medication-use-what',
          questionText: 'What medication(s)?',
          displayIfAnswerEquals: [true],
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          conditionalQuestions: [],
          uniqueAnswerId: 'MEDUSE_ADD_WHAT',
          showNotApplicableHint: false
        }
      ],
      uniqueAnswerId: 'MEDUSE_ADD'
    },
    {
      id: 2,
      name: 'medication-use-change',
      questionText:
        'In the past week, did you CHANGE a medication or OTC product to help you sleep?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          id: 3,
          name: 'medication-use-change-what',
          questionText: 'What medication(s)?',
          displayIfAnswerEquals: [true],
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          conditionalQuestions: [],
          uniqueAnswerId: 'MEDUSE_CHANGE_WHAT',
          showNotApplicableHint: false
        }
      ],
      uniqueAnswerId: 'MEDUSE_CHANGE'
    }
  ]
};
