import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import BaseInputComponent from '../base/base-input';
import { SelectOption } from '../types';

@Component({
  selector: 'noctem-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss',
                    '../base/base-input.scss'],
  })
  export class SelectInputComponent extends BaseInputComponent {
  constructor(private ngControl: NgControl) {
    super(ngControl);
  }

  @Input() options: any[] = [];
  @Input() optionValueField = 'value';
  @Input() optionTextField = 'text';
}
