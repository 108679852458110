import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { find as _find, filter as _filter, sortBy as _sortBy, last as _last, reverse as _reverse, pull as _pull, orderBy as _orderBy } from 'lodash';
import { ChatStateService, ChatMessage } from '../../state';
import { dateAgo } from '../../noctem-lib.service';
import moment from 'moment';



@Component({
    selector: 'chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
    @Output() onSend: EventEmitter<string> = new EventEmitter();
    @Input() messages: ChatMessage[];
    @Input() readonly = false;

    dateAgo = dateAgo;


    newRecipient;
    messageBody;
    constructor(
        public stateService: ChatStateService,
    ) {

    }

    ngOnInit() {
    }

    public setMessageBody(event) {
        this.messageBody = (event as HTMLTextAreaElement).value;
    }

    public decorateWithHeaders(messages) {
        let lastDateHeader = null;
        let today = new Date().toDateString();
        for (let message of messages) {
            let nextDate = new Date(message.sentOn).toDateString();
            if (lastDateHeader !== nextDate) {
                let dateLabel = nextDate === today ? "TODAY" : moment(nextDate).format("MMMM DD");
                message.dateHeader = dateLabel;
                lastDateHeader = nextDate;
            }
        }
        return messages;
    }

    send() {
        this.stateService.sendMessage();
    }


}
