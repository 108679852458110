export const swpsDef = {
  "name": "swps",
  "description": "Reported advanced or delayed sleep-wake schedule or shift work",
  "instructions": "",
  "questions": [
    {
      "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
      "id": 0,
      "questionText":
        "If you could choose to go to bed at any time, without restriction due to work or family responsibilities, your preferred bedtime would be:",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Between 22:00 and 24:00 ",
          "value": "1"
        },
        {
          "display": "Between 24:00 and 02:00",
          "value": "2"
        },
        {
          "display": "Between 02:00 and 04:00 ",
          "value": "3"
        },
        {
          "display": "Between 04:00 and 06:00",
          "value": "4"
        },
        {
          "display": "Between 06:00 and 12:00",
          "value": "5"
        },
        {
          "display": "Between 12:00 and 18:00",
          "value": "6"
        },
        {
          "display": "Between 18:00 and 20:00",
          "value": "7"
        },
        {
          "display": "Between 20:00 and 22:00",
          "value": "8"
        }
      ],
      "conditionalQuestions": [
        {
          "uniqueAnswerId": "SWPS_BEDTIME_IS_LATER",
          "id": 1,
          "questionText": "My preferred bedtime is more than 2 hours later than most people.",
          "displayIfAnswerEquals": ["3", "4", "5"],
          "questionEmphasis": "",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            {
              "display": "Yes",
              "value": true
            },
            {
              "display": "No",
              "value": false
            }
          ],
          "conditionalQuestions": [
            {
              "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
              "id": 2,
              "questionText": "My preferred bedtime is more than 2 hours earlier than most people.",
              "displayIfAnswerEquals": [false],
              "questionEmphasis": "",
              "questionType": "radio",
              "autofill": false,
              "answerOptions": [
                {
                  "display": "Yes",
                  "value": true
                },
                {
                  "display": "No",
                  "value": false
                }
              ],
              "conditionalQuestions": [
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "id": 3,
                  "questionText":
                    "If you could choose to wake up at any time, without restriction due to work or family responsibilities, your preferred rise time would be:",
                  "displayIfAnswerEquals": [true],
                  "questionEmphasis": "",
                  "questionType": "radio",
                  "autofill": "8-10",
                  "answerOptions":[
                    {
                      "display": "Between 04:00 and 06:00",
                      "value": "1"
                    },
                    {
                      // new rise time preference and value
                      "display": "Between 06:00 and 08:00",
                      "value": "9"
                    },
                    {
                      "display": "Between 08:00 and 10:00",
                      "value": "2"
                    },
                    {
                      "display": "Between 10:00 and 12:00",
                      "value": "3"
                    },
                    {
                      "display": "Between 12:00 and 14:00",
                      "value": "4"
                    },
                    {
                      "display": "Between 14:00 and 16:00",
                      "value": "5"
                    },
                    {
                      "display": "Between 16:00 and 24:00",
                      "value": "6"
                    },
                    {
                      "display": "Between 24:00 and 02:00",
                      "value": "7"
                    },
                    {
                      "display": "Between 02:00 and 04:00",
                      "value": "8"
                    }
                  ],
                  "conditionalQuestions": [],
                  "redirectMustMeetSpecificValues": true,
                  "redirectOptions": [
                    {
                      "answerValues": [
                        {
                          "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                          "values": ["3", "4", "5"]
                        },
                        {
                          "uniqueAnswerId": "SWPS_BEDTIME_IS_LATER",
                          "value": true
                        },
                        {
                          "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                          "values": ["3", "4", "5"]
                        }
                      ],
                      "assessmentsToRemove": ["swps3"],
                      "assessmentsToAdd": ["swps2"]
                    },
                    {
                      "answerValues": [
                        {
                          "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                          "values": ["7", "8"]
                        },
                        {
                          "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
                          "value": true
                        },
                        {
                          "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                          "values": ["1", "8"]
                        }
                      ],
                      "assessmentsToRemove": ["swps2"],
                      "assessmentsToAdd": ["swps3"]
                    },
                    {
                      "answerValues": [
                        {
                          "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                          "values": ["2", "6", "7"]
                        }
                      ],
                      "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                      "assessmentsToAdd": []
                    },
                    {
                      "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                      "assessmentsToAdd": []
                    }
                  ]
                },
                {
                  "uniqueAnswerId": "SWPS_RISETIME_IS_LATER",
                  "id": 4,
                  "questionText":
                    "My preferred rise time is more than 2 hours later than most people.",
                  "displayIfAnswerEquals": [false],
                  "questionEmphasis": "",
                  "questionType": "radio",
                  "autofill": false,
                  "answerOptions": [
                    {
                      "display": "Yes",
                      "value": true
                    },
                    {
                      "display": "No",
                      "value": false
                    }
                  ],
                  "conditionalQuestions": [],
                  "redirectOptions": [
                    { "value": true, "assessmentsToRemove": [], "assessmentsToAdd": ["swps4"] },
                    { "value": false, "assessmentsToRemove": ["swps4"], "assessmentsToAdd": [] }
                  ]
                }
              ]
            },
            {
              "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
              "id": 3,
              "questionText":
                "If you could choose to wake up at any time, without restriction due to work or family responsibilities, your preferred rise time would be:",
              "displayIfAnswerEquals": [true],
              "questionEmphasis": "",
              "questionType": "radio",
              "autofill": "8-10",
              "answerOptions": [
                {
                  "display": "Between 04:00 and 06:00",
                  "value": "1"
                },
                {
                  // new rise time preference and value
                  "display": "Between 06:00 and 08:00",
                  "value": "9"
                },
                {
                  "display": "Between 08:00 and 10:00",
                  "value": "2"
                },
                {
                  "display": "Between 10:00 and 12:00",
                  "value": "3"
                },
                {
                  "display": "Between 12:00 and 14:00",
                  "value": "4"
                },
                {
                  "display": "Between 14:00 and 16:00",
                  "value": "5"
                },
                {
                  "display": "Between 16:00 and 24:00",
                  "value": "6"
                },
                {
                  "display": "Between 24:00 and 02:00",
                  "value": "7"
                },
                {
                  "display": "Between 02:00 and 04:00",
                  "value": "8"
                }
              ],
              "conditionalQuestions": [],
              "redirectMustMeetSpecificValues": true,
              "redirectOptions": [
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                      "values": ["3", "4", "5"]
                    },
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_IS_LATER",
                      "value": true
                    },
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["3", "4", "5"]
                    }
                  ],
                  "assessmentsToRemove": ["swps3"],
                  "assessmentsToAdd": ["swps2"]
                },
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                      "values": ["7", "8"]
                    },
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
                      "value": true
                    },
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["1", "8"]
                    }
                  ],
                  "assessmentsToRemove": ["swps2"],
                  "assessmentsToAdd": ["swps3"]
                },
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["2", "6", "7"]
                    }
                  ],
                  "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                  "assessmentsToAdd": []
                },
                {
                  "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                  "assessmentsToAdd": []
                }
              ]
            }
          ]
        },
        {
          "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
          "id": 2,
          "questionText": "My preferred bedtime is more than 2 hours earlier than most people.",
          "displayIfAnswerEquals": ["7", "8"],
          "questionEmphasis": "",
          "questionType": "radio",
          "autofill": false,
          "answerOptions": [
            {
              "display": "Yes",
              "value": true
            },
            {
              "display": "No",
              "value": false
            }
          ],
          "conditionalQuestions": [
            {
              "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
              "id": 3,
              "questionText":
                "If you could choose to wake up at any time, without restriction due to work or family responsibilities, your preferred rise time would be:",
              "displayIfAnswerEquals": [true],
              "questionEmphasis": "",
              "questionType": "radio",
              "autofill": "8-10",
              "answerOptions": [
                {
                  "display": "Between 04:00 and 06:00",
                  "value": "1"
                },
                {
                  // new rise time preference and value
                  "display": "Between 06:00 and 08:00",
                  "value": "9"
                },
                {
                  "display": "Between 08:00 and 10:00",
                  "value": "2"
                },
                {
                  "display": "Between 10:00 and 12:00",
                  "value": "3"
                },
                {
                  "display": "Between 12:00 and 14:00",
                  "value": "4"
                },
                {
                  "display": "Between 14:00 and 16:00",
                  "value": "5"
                },
                {
                  "display": "Between 16:00 and 24:00",
                  "value": "6"
                },
                {
                  "display": "Between 24:00 and 02:00",
                  "value": "7"
                },
                {
                  "display": "Between 02:00 and 04:00",
                  "value": "8"
                }
              ],
              "conditionalQuestions": [],
              "redirectMustMeetSpecificValues": true,
              "redirectOptions": [
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                      "values": ["3", "4", "5"]
                    },
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_IS_LATER",
                      "value": true
                    },
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["3", "4", "5"]
                    }
                  ],
                  "assessmentsToRemove": ["swps3"],
                  "assessmentsToAdd": ["swps2"]
                },
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                      "values": ["7", "8"]
                    },
                    {
                      "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
                      "value": true
                    },
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["1", "8"]
                    }
                  ],
                  "assessmentsToRemove": ["swps2"],
                  "assessmentsToAdd": ["swps3"]
                },
                {
                  "answerValues": [
                    {
                      "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                      "values": ["2", "6", "7"]
                    }
                  ],
                  "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                  "assessmentsToAdd": []
                },
                {
                  "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
                  "assessmentsToAdd": []
                }
              ]
            },
            {
              "uniqueAnswerId": "SWPS_RISETIME_IS_LATER",
              "id": 4,
              "questionText": "My preferred rise time is more than 2 hours later than most people.",
              "displayIfAnswerEquals": [false],
              "questionEmphasis": "",
              "questionType": "radio",
              "autofill": false,
              "answerOptions": [
                {
                  "display": "Yes",
                  "value": true
                },
                {
                  "display": "No",
                  "value": false
                }
              ],
              "conditionalQuestions": [],
              "redirectOptions": [
                { "value": true, "assessmentsToRemove": [], "assessmentsToAdd": ["swps4"] },
                { "value": false, "assessmentsToRemove": ["swps4"], "assessmentsToAdd": [] }
              ]
            }
          ]
        },
        {
          "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
          "id": 3,
          "questionText":
            "If you could choose to wake up at any time, without restriction due to work or family responsibilities, your preferred rise time would be:",
          "displayIfAnswerEquals": ["1", "2", "6"],
          "questionEmphasis": "",
          "questionType": "radio",
          "autofill": "8-10",
          "answerOptions": [
            {
              "display": "Between 04:00 and 06:00",
              "value": "1"
            },
            {
              // new rise time preference and value
              "display": "Between 06:00 and 08:00",
              "value": "9"
            },
            {
              "display": "Between 08:00 and 10:00",
              "value": "2"
            },
            {
              "display": "Between 10:00 and 12:00",
              "value": "3"
            },
            {
              "display": "Between 12:00 and 14:00",
              "value": "4"
            },
            {
              "display": "Between 14:00 and 16:00",
              "value": "5"
            },
            {
              "display": "Between 16:00 and 24:00",
              "value": "6"
            },
            {
              "display": "Between 24:00 and 02:00",
              "value": "7"
            },
            {
              "display": "Between 02:00 and 04:00",
              "value": "8"
            }
          ],
          "conditionalQuestions": [],
          /* This assessment has conditional assessments that show should or hide based on answers to the specific questions if conditions
          are met in either of the redirect options that assessment option will be added. Any redirect option lacking answerValues will be 
          assumed to be the option to remove if none of the answerValue conditions are met. The redirectMustMeetSpecificValues flag must
          be added to the def as true to signal when to run these rules on an assessment definition. */
          "redirectMustMeetSpecificValues": true,
          "redirectOptions": [
            {
              "answerValues": [
                {
                  "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                  "values": ["3", "4", "5"]
                },
                {
                  "uniqueAnswerId": "SWPS_BEDTIME_IS_LATER",
                  "value": true
                },
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "values": ["3", "4", "5"]
                }
              ],
              "assessmentsToRemove": ["swps3"],
              "assessmentsToAdd": ["swps2"]
            },
            {
              "answerValues": [
                {
                  "uniqueAnswerId": "SWPS_BEDTIME_PREFERENCE",
                  "values": ["7", "8"]
                },
                {
                  "uniqueAnswerId": "SWPS_BEDTIME_IS_EARLIER",
                  "value": true
                },
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "values": ["1", "8"]
                }
              ],
              "assessmentsToRemove": ["swps2"],
              "assessmentsToAdd": ["swps3"]
            },
            {
              "answerValues": [
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "values": ["3", "4", "5"]
                }
              ],
              "assessmentsToRemove": ["swps3"],
              "assessmentsToAdd": ["swps2"]
            },
            {
              "answerValues": [
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "values": ["1", "8"]
                }
              ],
              "assessmentsToRemove": ["swps2"],
              "assessmentsToAdd": ["swps3"]
            },
            {
              "answerValues": [
                {
                  "uniqueAnswerId": "SWPS_RISETIME_PREFERENCE",
                  "values": ["2", "6", "7"]
                }
              ],
              "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
              "assessmentsToAdd": []
            },
            {
              "assessmentsToRemove": [["swps2"], ["swps3"], ["swps4"]],
              "assessmentsToAdd": []
            }
          ]
        }
      ]
    }
  ]
};
