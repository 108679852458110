import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const pcl5Fields: Array<FieldDefinitionBase> =
[

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-long-dreams',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_DREAMS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-long-sleep',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5,
    answerId: 'PCL5_SLEEP'
  }),
]
