import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const sleepAreaSafeguarding: AssessmentDefinition = {
  name: 'sleep-area-safeguarding',
  description: 'Sleep Area Safeguarding',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Just like any learned skill, such as shooting or visual signaling, you perform it like you practice it.</p>
                <p>If you repeatedly practice wakefulness in your bed and sleep area, your brain becomes uncertain if that is a location meant for sleep or alertness.</p>
                <p>This exercise trains your brain to re-learn and remember that your bed and sleep area are only for sleep.</p>`,
      questionType: null,

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Go to your bed or sleep area only when sleepy.</p>
                <p>Get out of bed when not sleeping.</p>
                <p>Don’t use technology or do other activities in your bed or sleep area. The bed is for sleep only.</p>`,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Don’t enter your sleep area or lay down in bed until you intend to go to sleep.</p>
                <p>If you are sleepy at your selected bedtime, then get into bed.</p>
                <p>If you are not sleepy at your selected bedtime, don’t get into bed. Find a low intensity, boring activity to do in low light. When sleepy, then get into bed. </p>
                <p>Practice good tech hygiene. Do not use your cell phone or other technology in bed.</p>`,
      questionType: null,
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: []
    }
  ]
};
