import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const imageryRehearsalAlgorithm = new SleepModuleDefinition({
  name: 'dream-retraining',
  isMedic: true,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-long-dreams',
      valueToCompare: 1,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Concat,
      fieldName: 'prevented-sleep',
      valueToCompare: [4],
      operator: EqualityType.Contains,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Concat,
      fieldName: 'disrupted-sleep',
      valueToCompare: [2],
      operator: EqualityType.Contains,
      priority: 0
    }),
  ],
});
