import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

export const upYourSleepArea: AssessmentDefinition = {
  name: 'up-your-sleep-area',
  description: 'Up Your Sleep Area',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Up your sleep area - Read about 6 ways to up your sleep area</p>
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <img src='assets/img/module-images/up-your-sleep-area/noise.png'/>
                    </div>
                    <div class="col-6">
                      <img src='assets/img/module-images/up-your-sleep-area/temperature.png' />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <img src='./assets/img/module-images/up-your-sleep-area/safety.png' />
                    </div>
                    <div class="col-6">
                      <img src='./assets/img/module-images/up-your-sleep-area/light.png' />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <img src='./assets/img/module-images/up-your-sleep-area/snoring.png' />
                    </div>
                    <div class="col-6">
                      <img src='./assets/img/module-images/up-your-sleep-area/surface.png' />
                    </div>
                  </div>
                </div>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'Loud, distracting noises',
      questionEmphasis: ``,
      subText: `<p>Your brain continues to be aware of sounds even when asleep. Too much noise can disrupt sleep making it less restful and restorative. </p>
                <div class='call-out'>
                  <h5 class='module'>Try it out!</h5>
                  During sleep use ear plugs or noise cancelling headphones. Use a room fan or white noise machine.
                </div>
                <div class='call-out'>
                  <h5 class='module'>What about missing important noises?</h5>
                  Trust your brain.  You will wake up if unusual, mission relevant noises occur while you are sleeping.
                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: 'Harsh Temperatures',
      questionEmphasis: ``,
      subText: `<p>The ideal temperature for sleep is around 65°F.  A cool sleep environment can support the brain and body’s transition to sleep because bedtime typically aligns with when one’s body temperature is naturally decreasing.</p>
                <div class="call-out">
                  <h5 class='module'>Too hot out?</h5>
                  <p>Use a room fan to cool off.</p>
                  <h5 class='module'>Too cold out?</h5>
                  <p>Use additional blankets or gear for warmth.</p>
                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: 'Sleep area safety',
      questionEmphasis: ``,
      subText: `<p>Failure to mitigate known environmental risks will add to feelings of needing to constantly monitor your environment. Constantly being “on watch” is counterproductive to good sleep.</p>
                <div class="call-out">
                  <h5 class="module">Try it out!</h5>
                  Assess for immediate potential hazards prior to sleep. For example, screen for the presence of animals and insects (snakes, spiders, etc.) in or near your sleep area.
                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: 'Bright Light',
      questionEmphasis: ``,
      subText: `<p>Bright light signals that it is time to be awake. Creating a dark sleep area (regardless of time of day) signals to your brain and body that it is time to transition into sleep.</p>
                <div class="call-out">
                  <h5 class='module'>Light Control</h5>
                  <p>Dim the lights, if possible, in the period before you go to sleep.</p>

                  <p>Wear sunglasses to block out bright light prior to sleep.</p>

                  <p>Cover windows with dark material (e.g. black trash bags) to block out light.</p>

                  <p>Wear an eye mask during sleep.</p>

                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 5,
      questionText: 'Dealing with Snoring',
      questionEmphasis: ``,
      subText: `<p>Limiting snoring reduces noises that can disrupt your own or your sleep mate’s sleep. If you have been told you are snoring, try these out:</p>
                <div class="call-out">
                  <h5 class='module'>Take action</h5>
                  <p>Use nasal strips to open your nasal passage.</p>

                  <p>Don't sleep flat on your back.  Sleep on your side or prop your head up.</p>

                  <p>Use a snoring mouthpiece.</p>

                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 6,
      questionText: 'Comfortable Sleep Surface',
      questionEmphasis: ``,
      subText: `<p>A comfortable sleep surface helps to minimize joint and muscle pain which can wake you up or make it difficult to fall asleep.</p>
                <div class="call-out">
                  <h5 class='module'>What to do:</h5>
                  <p>Bring comfort to your sleep area.</p>

                  <h5 class='module'>How to do it:</h5>

                  <p>Use a memory foam mattress pad or pillow to improve the comfort of your sleep surface.</p>

                  <p>Use additional pillows, blankets, or clothes to soften your sleep surface.</p>

                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
