import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { sleepBanking } from '../questions/sleep-banking/sleep-banking';

export const sleepBankingDef: AssetDefinition = {
  id: 12,
  name: 'sleep-banking',
  label: 'Sleep Banking',
  description: `
  In training and deployed environments, periods with reduced sleep are unavoidable. Boost your Soldiers’ endurance during periods of high OPTEMPO by encouraging sleep banking.

  `,
  categories: ['all', 'sleep-help', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    sleepBanking
  ],
  url: 'sleep-banking'
};
