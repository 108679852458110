import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const strategicCaffeineAlgorithm = new SleepModuleDefinition({
  name: 'strategic-caffeine',
  isMedic: false,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'total-caffeine-intake',
      valueToCompare: 599, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    })

  ],
});

