import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

export const teamCommunication: AssessmentDefinition = {
  name: 'team-communication',
  description: 'Team Communication',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `
        <h5>Learn</h5>
        <p>
          Effective communication between members of a team is crucial to accomplish the mission.
          Stress is known to negatively affect group functioning, and many mission failures can be traced to a breakdown in communication.
          However, there are some simple strategies you can use to improve communication & reduce conflict.
        </p>
        <p><img src='./assets/img/module-images/team-communication/soldier-walk.png'/></p>
        <p>Photo Credit Spc. Emily Houdershieldt</p>
        <p><b>Effective Communication needs to meet 4 standards</b></p>
        <div class="call-out">
          <b>1 Complete</b></br/>
          Communicate all relevant info while avoiding unnecessary details that might lead to confusion.
        </div>
        <div class="call-out">
          <b>2 Clear</b></br/>
          Use terminology and concepts that are easily understood.
        </div>
        <div class="call-out">
          <b>3 Brief</b></br/>
          Be concise, do not over explain.
        </div>
        <div class="call-out">
          <b>4 Timely</b></br/>
          Avoid delays in relaying information that could comprise mission success. Focus on what is important to know right now.
        </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <h5><b>Conflict Resolution</b></h5>
      <p>Conflicts between team members are inevitable, yet how you approach them can determine the outcome. </p>
      <p><b>These steps will help you work towards a solution:</b></p>
      <div class="call-out">
        <b>Identify Your Goal</b></br/>
        What would you want to be different? Don’t try to “win” the argument, rather seek an outcome that satisfies both of you – this will usually require compromises from both people.
      </div>
      <div class="call-out">
        <b>Make a Plan</b></br/>
        Identify a time and place to talk without distractions
      </div>
      <div class="call-out">
        <b>Use "I" Statements</b></br/>
        Express yourself using “I” statements telling the other person how you feel, rather than blaming them.
      </div>
      <div class="call-out">
        <b>Stay Calm</b></br/>
        When you are upset you are more likely to say things that don’t help the situation. Try to stay calm by breathing slowly or taking a brief break if things get too heated.
      </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `
        <div class='call-out'>
          <h5 class='module'><b>Practice</b></h5>
          <p><img src='./assets/img/module-images/team-communication/soldier-run.png'/></p>
          <h5 class='module'><b>Activity 1</b></h5>
          <p>Listen to this brief example of a leader using tactical communication.</p>
        </div>
        <audio class="w-100" controls>
          <source src="./assets/audio/team_communication.mp3" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <div class='call-out'>
          <h5 class='module'><b>Activity 2</b></h5>
          <p>
            The next time you are working & communication doesn’t seem to be great,
            check to make sure the information you are sharing meets the <b>Complete, Clear, Brief, Timely</b> standards.
          </p>
        </div>
        <div class='call-out'>
          <h5 class='module'><b>Activity 3</b></h5>
          <p>Think of a current conflict you are experiencing.</p>
        </div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: false,
      conditionalQuestions: []
    },
    {
      id: 3,
      questionText: '',
      subText: '<b>Goal:</b> What is your goal in discussing the conflict?',
      questionEmphasis: null,
      questionType: 'textarea',
      uniqueAnswerId: 'TEAM-COMM-EXERCISE',
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: '',
      subText: '<b>Plan:</b> When would be a good time to have a talk?',
      questionEmphasis: null,
      questionType: 'textarea',
      uniqueAnswerId: 'TEAM-COMM-EXERCISE-1',
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: '',
      subText: '<b>I Statements:</b> What are at least 2 "I Statements" you can use to express how you feel?',
      questionEmphasis: null,
      questionType: 'textarea',
      uniqueAnswerId: 'TEAM-COMM-EXERCISE-2',
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: false
    }
  ]
};
