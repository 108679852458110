//available in database
export const cicadaSessionsDef = {
    "name": "cicadaSessions",
    "description": "Number of Session Attended",
    "instructions": "",
    /*"questions": [
      {
        "id": 0,
        "questionText": "Since starting this research study, how many face-to-face or virtual appointments for insomnia have you attended with your sleep clinician?",
        "questionType": "radio",
        "questionHeader": false,
        "autofill": false,
        "answerOptions": [
            {
                "display":"I used the COAST app only for insomnia treatment",
                "value": 0,
            },
            {
                "display":"1",
                "value":1,
            },
            {
                "display":"2",
                "value":2,
            },
            {
                "display":"3",
                "value":3,
            },
            {
                "display":"4",
                "value":4,
            },
            {
                "display":"5",
                "value":5,
            },
            {
                "display":"6",
                "value":6,
            },
            {
                "display":"7",
                "value":7,
            },
            {
                "display":"8 or more",
                "value":8,
            },
            {
                "display":"None",
                "value":"NA",
            },
        ],
        "uniqueAnswerId": "NUM_SESSIONs",
        "conditionalQuestions": []
      }
    ]*/
  };