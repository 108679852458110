import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { dangersOfFatigue } from '../questions/dangers-of-fatigue/dangers-of-fatigue';


export const dangersOfFatigueDef: AssetDefinition = {
  id: 3,
  name: 'dangers-of-fatigue',
  label: 'Dangers of Fatigue',
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    dangersOfFatigue
  ],
  url: 'dangers-of-fatigue'
};
