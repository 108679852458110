import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-chart',
  styleUrls: ['./chart.component.scss'],
  templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit {
  public lineChartData: ChartDataSets[];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    aspectRatio: 2.75,
    annotation: null,
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'series',
          ticks: {
            fontSize: 14,
            fontFamily: 'Manrope'
          },
          time: {
            unit: 'day',
            displayFormats: {
              quarter: 'MMM D'
            }
          }
        }
      ],
      yAxes: [
        {
          stacked: true,

          ticks: {
            fontSize: 14,
            fontFamily: 'Manrope'
          }
        }
      ]
    }
  };
  public lineChartLegend = false;
  public lineChartType: ChartType = 'bar';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [
    {
      pointBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointBorderColor: 'rgba(255, 255, 255, 1)'
    }
  ];

  @Input() values = [];
  @Input() values2 = [];

  @Input() title: string;
  @Input() isTitleTop: boolean = false;
  @Input() isStepped: boolean = false;
  @Input() width = 560;
  @Input() height = 200;
  @Input() bgColor = 'rgb(153, 150, 250)';
  @Input() areaColor = 'rgba(90, 88, 134, 1)';
  @Input() yAxisMax: number;
  @Input() yAxisMin: number;
  @Input() yAxesLabel = v => v;

  private updateData() {
    this.lineChartOptions.scales.yAxes[0].ticks.callback = this.yAxesLabel;
    this.lineChartOptions.scales.yAxes[0].ticks.max = this.yAxisMax;
    this.lineChartOptions.scales.yAxes[0].ticks.min = this.yAxisMin;
    this.lineChartData = [
      {
        data: this.values,
        backgroundColor: this.areaColor,
        borderColor: this.areaColor,
        steppedLine: false, //his.isStepped ? 'middle' : false,
        pointRadius: this.isStepped ? 0 : 5,
        lineTension: 0,
        stack: "2"
      }
    ];

    if(this.values2){
      this.lineChartData.push({
        data: this.values2,
        backgroundColor: 'rgba(0, 0, 0',
        borderColor: 'rgb(0, 0, 0)',
        steppedLine: false, //his.isStepped ? 'middle' : false,
        pointRadius: this.isStepped ? 0 : 5,
        lineTension: 0,
        stack: "2"
      })
    }

    this.lineChartPlugins = [
      {
        beforeDraw: chart => {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;
          ctx.save();
          ctx.fillStyle = this.bgColor;
          ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );
          ctx.restore();
        }
      }
    ];
  }

  ngOnInit() {
    this.updateData();
  }

  ngOnChanges() {
    this.updateData();
  }
}
