import * as _ from 'lodash';
import {
    find, findIndex, last, isNumber, get, size, isNaN, compact, isString, isUndefined, isNil, isFunction,
    clone, forEach, map, capitalize, startCase, toUpper, snakeCase, chain, trim, toLower,
    round, floor, every, set, isBoolean
} from 'lodash';


export class CollectionUtils {
    static splitList(inputList: any[], maxChunkSize: number, doCopy?: boolean): any[] {
        const chunks: any[][] = [];

        const list: any[] = doCopy ? inputList.slice() : inputList;
        // split list into chunks by maxChunkSize
        while (size(list) > 0) {
            const sizeVal: number = size(list);
            const numItems: number = sizeVal >= maxChunkSize ? maxChunkSize : sizeVal;
            const currentChunk: any[] = list.splice(0, numItems);
            chunks.push(currentChunk);
        }
        return chunks;
    }

    static firstOrDefault<T>(items: T[], defaultVal?: T): T {
        return size(items) > 0 ? items[0] : (defaultVal || null);
    }

    static findBy<T>(attr: string, val: any, items: T[]): T {
        return find(items || [], item => get(item, attr) == val) || null;
    }

    static findIndexBy(attr: string, val: any, items: any[]): number {
        return findIndex(items || [], item => get(item, attr) == val);
    }

    static findByAndGet<T>(attr: string, val: any, items: any[], pathToGet: string, defaultVal?: T): T {
        const match = this.findBy(attr, val, items);
        return get<T>(match, pathToGet, defaultVal);
    }

    static getFromLastItem<T>(attr: string, items: any[]): T {
        return get<T>(last(items), attr, null);
    }
}


export class Validator {
    static anyAre(fxn: Function, items: any[]): boolean {
        this.throwIfNotFunction(fxn, 'Failed to execute Validator.anyAre.  Missing fxn param');
        for (const item of items || []) {
            if (fxn(item) == true) {
                return true;
            }
        }
        return false;
    }

    static allAre(fxn: Function, items: any[], valuesRequired?: boolean): boolean {
        this.throwIfNotFunction(fxn, 'Failed to execute Validator.allAre.  Missing fxn param');
        if (valuesRequired && !this.hasValues(items)) {
            return false;
        }
        for (const item of items || []) {
            if (fxn(item) === false) {
                return false;
            }
        }
        return true;
    }

    static noneAre(fxn: Function, items: any[]): boolean {
        this.throwIfNotFunction(fxn, 'Failed to execute Validator.noneAre.  Missing fxn param');
        for (const item of items || []) {
            if (fxn(item) === true) {
                return false;
            }
        }
        return true;
    }

    // SINGLE----------------------------------------------------------------------------------------------------------

    static isFalsey(item: any): boolean {
        return !item;
    }

    static isValidEmailAddress(emailAddress: string): boolean {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(emailAddress);
    }

    static isString(item: any): boolean {
        return typeof item === 'string';
    }

    static isValidNumber(item: any): boolean {
        return isNumber(item) && !isNaN(item);
    }

    static isNullAtPath(path: string, obj: any): boolean {
        return get<any, string>(obj, path) === null;
    }

    // LIST------------------------------------------------------------------------------------------------------------

    static isPopulatedListOfValidEmails(emails: string[]): boolean {
        return this.allAre(this.isValidEmailAddress, emails, true);
    }

    static hasValues(list: any[]): boolean {
        return size(compact(list)) > 0;
    }

    static allAreStrings(items: string[]): boolean {
        return this.allAre(isString, items);
    }

    static anyAreUndefined(items: string[]): boolean {
        return this.anyAre(isUndefined, items);
    }

    static anyAreNil(items: any[]): boolean {
        return this.anyAre(isNil, items);
    }

    static anyAreFalsey(items: any[]): boolean {
        return this.anyAre(this.isFalsey, items);
    }

    static noneAreFalsey(items: any[]): boolean {
        return this.noneAre(this.isFalsey, items);
    }

    static isPopulatedListOfStrings(items: string[]): boolean {
        return this.hasValues(items) && this.allAreStrings(items);
    }

    // THROWERS----------------------------------------------------------------------------------------------------------

    static throwIfNotFunction(fxn: Function, msg: string) {
        if (!fxn || !isFunction(fxn)) {
            throw new Error(msg);
        }
    }

    static throwIfAnyAreNil(items: any[], msg: string): void {
        if (this.anyAreNil(items)) {
            throw new Error(msg);
        }
    }

    static throwIfAnyAreFalsey(items: any[], msg: string): void {
        if (this.anyAreFalsey(items)) {
            throw new Error(msg);
        }
    }

    static throwIfNil(item: any, msg: string): void {
        if (isNil(item)) {
            throw new Error(msg);
        }
    }

    static throwIfFalsey(item: any, msg: string): void {
        if (this.isFalsey(item)) {
            throw new Error(msg);
        }
    }

    static throwIfHasNoValues(items: any[], msg: string): void {
        if (!this.hasValues(items)) {
            throw new Error(msg);
        }
    }

    static throwIfIsNotPopulatedListOfStrings(items: string[], msg: string): void {
        if (!this.isPopulatedListOfStrings(items)) {
            throw new Error(msg);
        }
    }

    static throwIfInvalidEmail(email: string, msg: string = 'Invalid Email.'): void {
        if (!this.isValidEmailAddress(email)) {
            throw new Error(msg);
        }
    }
}

export class UtilityService {
    constructor() { }

    static booleanOrDefault(bool: boolean, defaultVal?: boolean): boolean {
        return isBoolean(bool) ? bool : (isBoolean(defaultVal) ? defaultVal : false);
    }

    // COLLECTION
    static splitList(inputList: any[], maxChunkSize: number, doCopy?: boolean): any[] {
        return CollectionUtils.splitList(inputList, maxChunkSize, doCopy);
    }

    static firstOrDefault<T>(items: T[], defaultVal?: T): T {
        return CollectionUtils.firstOrDefault<T>(items, defaultVal);
    }

    static findBy<T>(attr: string, val: any, items: T[]): T {
        return CollectionUtils.findBy(attr, val, items);
    }

    static findIndexBy(attr: string, val: any, items: any[]): number {
        return CollectionUtils.findIndexBy(attr, val, items);
    }

    static findByAndGet<T>(attr: string, val: any, items: any[], pathToGet: string, defaultVal?: T): T {
        return CollectionUtils.findByAndGet<T>(attr, val, items, pathToGet, defaultVal);
    }

    // VALIDATION
    static getMissingParams<T>(attributeNames: string[], params: T): string[] {
        if (!attributeNames || !params) { throw new Error('Can not execute params validation.'); }
        const missingParamsMessages: string[] = _
            .chain(attributeNames)
            .map(attr => isUndefined(get(params, attr)) ? 'Missing parameter: ' + attr + '.' : null)
            .compact()
            .value();
        return missingParamsMessages;
    }

    static isPopulatedListOfValidEmails(emails: string[]): boolean {
        return Validator.isPopulatedListOfValidEmails(emails);
    }

    static isValidEmailAddress(emailAddress: string) {
        return Validator.isValidEmailAddress(emailAddress);
    }

    static hasValues(list: any[]): boolean {
        return Validator.hasValues(list);
    }

    // static anyAreUndefined

    static anyAreNil(items: any[]): boolean {
        return Validator.anyAreNil(items);
    }

    static anyAreFalsey(items: any[]): boolean {
        return Validator.anyAreFalsey(items);
    }

    static throwIfAnyAreNil(items: any[], msg: string): void {
        return Validator.throwIfAnyAreNil(items, msg);
    }

    static throwIfAnyAreFalsey(items: any[], msg: string): void {
        return Validator.throwIfAnyAreFalsey(items, msg);
    }

    // STRING
    static replaceEach(source: string, replacementPairs: string[][]) {
        return StringUtils.replaceEach(source, replacementPairs);
    }

    static capitalizeEachWord(input: string): string {
        return StringUtils.capitalizeEachWord(input);
    }

    static replaceAllSubstrings(input: string, subStrings: string[], replaceWith: string): string {
        return StringUtils.replaceAllSubstrings(input, subStrings, replaceWith);
    }

    static allToLower(items: string[]): string[] {
        return StringUtils.allToLower(items);
    }

    static isCaseInsensitiveMatch(one: string, two: string): boolean {
        return StringUtils.isCaseInsensitiveMatch(one, two);
    }

    static isCaseInsensitiveMatchAfterTrim(one: string, two: string): boolean {
        return StringUtils.isCaseInsensitiveMatchAfterTrim(one, two);
    }

    static allAreStrings(items: any[]) {
        return StringUtils.allAreStrings(items);
    }

    static oneContainsLowercaseTwo(one: string, two: string) {
        return StringUtils.oneContainsLowercaseTwo(one, two);
    }

    static toTitleCase(str: string): string {
        return StringUtils.toTitleCase(str);
    }

    static toUpperSnakeCase(str: string): string {
        return StringUtils.toUpperSnakeCase(str);
    }

    // NUMBER
    static containsNonDigit(input: string): boolean {
        return NumberUtils.containsNonDigit(input);
    }

    static isPositiveInt(input: any): boolean {
        return NumberUtils.isPositiveInt(input);
    }

    static getPriceWithNDecimals(price: number, numDecimals: number): number {
        return NumberUtils.getPriceWithNDecimals(price, numDecimals);
    }

    static roundUpTo99(price: number): number {
        return NumberUtils.roundUpTo99(price);
    }

    static allAreNumbers(items: number[]): boolean {
        return NumberUtils.allAreNumbers(items);
    }

    static isNumber(item: number): boolean {
        return NumberUtils.isNumber(item);
    }

    static numberOrDefault(input: number, defaultVal?: number): number {
        return NumberUtils.numberOrDefault(input, defaultVal);
    }

    static justDigits(input: string): string {
        return NumberUtils.justDigits(input);
    }
}





export class NumberUtils {
    static containsNonDigit(input: string): boolean {
        return /\D/.test(input);
    }

    static isPositiveInt(input: any): boolean {
        if (typeof input == 'number') { return input > 0; }
        return typeof input == 'string' && /^\+?[1-9]\d*$/.test(input);
    }

    static getInteger(item: any, defaultVal?: number): number {
        let result: number;
        if (Validator.isString(item)) {
            result = parseFloat(item);
        } else if (Validator.isValidNumber(item)) {
            result = item;
        } else {
            return isUndefined(defaultVal) ? null : defaultVal;
        }
        return round(result);
    }

    static getPriceWithNDecimals(price: number, numDecimals: number): number {
        return parseFloat(price.toFixed(numDecimals));
    }

    static roundUpTo99(price: number): number {
        return floor(price) + .99;
    }

    static allAreNumbers(items: number[]): boolean {
        return every(items || [], item => this.isNumber(item));
    }

    static isNumber(item: number): boolean {
        return isNumber(item) && !isNaN(item);
    }

    static numberOrDefault(input: number, defaultVal?: number): number {
        defaultVal = defaultVal || 0;
        return this.isNumber(input) ? input : defaultVal;
    }

    static justDigits(input: string): string {
        return typeof input == 'string' ? input.replace(/\D/g, '') : null;
    }

    public static getFloat(obj: any, path: string, numDecimals: number): number {
        let val = get(obj, path);
        if (typeof val == 'string') {
            val = parseFloat(val);
        }
        val = this.numberOrDefault(val, 0);
        numDecimals = this.numberOrDefault(numDecimals, 0);
        return this.decimal(val, numDecimals);
    }

    public static setFloatAtPaths<T>(obj: T, paths: string[], numDecimals: number): T {
        if (!obj || !size(paths)) {
            return obj;
        }
        for (const path of paths) {
            const val = this.getFloat(obj, path, numDecimals) || 0;
            set<any>(obj, path, val);
        }
        return obj;
    }

    public static decimal(input: number, numDecimals?: number): number {
        if (typeof input == 'string') {
            input = parseFloat(input);
        }
        input = this.numberOrDefault(input, 0);
        return round(input, numDecimals);
    }

    public static getSum(items: any[], numDecimals: number): number {
        let result = 0;
        for (const item of items) {
            result += this.decimal(item, numDecimals);
        }
        // _.reduce<number, number>(items, (sum, n:number) => sum + n)
        return this.decimal(result, numDecimals);
    }

    public static getSumByPaths(obj: any, paths: string[], numDecimals: number) {
        let result = 0;
        for (const path of paths) {
            result += this.getFloat(obj, path, numDecimals);
        }
        return this.decimal(result, numDecimals);
    }

    public static isSorted(items: number[]): boolean {
        return !find(items, (currentItem, index) => {
            if (index == 0) { return false; }
            const prevItem = items[index - 1];
            return prevItem < currentItem;
        });
    }

    public static commaSeparated(input: number): string {
        return input.toLocaleString('en');
    }

    public static containsNumericValue(item: string): boolean {
        const pattern: RegExp = /.*[0-9].*/;
        return pattern.test(item);
    }

    public static throwIfNotPositiveInteger(value: number, msg: string): void {
        if (!NumberUtils.isPositiveInt(value)) {
            throw new Error(msg);
        }
    }
}


export class StringUtils {
    static replaceEach(source: string, replacementPairs: string[][]) {
        const hasInvalidItem = !!find(replacementPairs, i => size(i) != 2);
        if (hasInvalidItem) {
            throw new Error('Invalid params.  replacementPairs be 2d array of strings... each of length 2.');
        }
        let result: string = clone(source);
        forEach(replacementPairs, i => {
            let substringToReplace: string, replaceWith: string;
            substringToReplace = i[0];
            replaceWith = i[1];
            result = result.split(substringToReplace).join(replaceWith);
        });
        return result;
    }

    static capitalizeEachWord(input: string): string {
        return typeof input == 'string' ? map(input.split(' '), i => capitalize(i)).join(' ') : null;
    }

    static replaceAllSubstrings(input: string, subStrings: string[], replaceWith: string): string {
        replaceWith = replaceWith || '';
        forEach(subStrings, subString => {
            input = input.split(subString).join(replaceWith);
        });
        return input;
    }

    static extractCharGroups(str: string, indexGroups: number[][]): string[] {
        return map<number[], string>(indexGroups, indexes => this.extractChars(str, indexes));
    }

    static extractChars(str: string, indexes: number[]): string {
        let chars = '';
        for (const index of indexes || []) {
            chars += str[index];
        }
        return chars;
    }

    static allAreStrings(items: any[]) {
        return !find(items || [], item => typeof item != 'string');
    }

    static allToLower(items: string[]): string[] {
        return map<string, string>(items, item => toLower(item));
    }

    static toTitleCase(str: string): string {
        return startCase(str || '').split(' ').join('');
    }

    static toUpperSnakeCase(str: string): string {
        return toUpper(snakeCase(str || ''));
    }

    static firstWordFromSnakeCase(snakeCaseString: string = ''): string {
        return snakeCaseString.split('_')[0];
    }

    static stringOrDefault(obj: any, path: string, defaultVal?: string): string {
        defaultVal = typeof defaultVal == 'string' ? defaultVal : '';
        return get(obj, path) || defaultVal;
    }

    static compactToLowerUnique(items: string[]): string[] {
        return chain(items)
            .compact()
            .map(item => toLower(item))
            .uniq()
            .value();
    }

    static flattenedUniqueToLower(lists: string[][]): string[] {
        let items: string[];
        items = chain(lists).flatten<string>().compact().uniq().value();
        return this.allToLower(items);
    }

    static replaceDoubleForwardSlashWithSingle(source: string): string {
        return StringUtils.replaceEach(source, [['//', '/']]);
    }

    public static containsPartialMatchCaseSensitive(one: string, two: string): boolean {
        return this.containsPartialMatch(one, two, true);
    }

    public static containsPartialMatchCaseInsensitive(one: string, two: string): boolean {
        return this.containsPartialMatch(one, two, false);
    }

    public static t(input: string, paramsList?: any[]): string {
        Validator.throwIfNil(input, 'Missing input string');
        if (!Validator.hasValues(paramsList)) {
            return input;
        }
        forEach(paramsList, (param, index) => {
            input = input.replace('{{' + index + '}}', param);
        });
        return input;
    }

    public static stripHtmlTags(input: string) {
        if (!input) { return ''; }
        const div = document.createElement('div');
        div.innerHTML = input;
        let text = div.textContent || div.innerText || '';
        text = StringUtils.replaceAllSubstrings(text, ['&nbsp;', '&nbsp'], ' ');
        return trim(text);
    }

    // ----------------------------------------------------------------------------------------------------------
    // MATCHING
    // ----------------------------------------------------------------------------------------------------------

    static isCaseInsensitiveMatch(one: string, two: string): boolean {
        // return _.toLower(one) === _.toLower(two);
        return this.isMatch(one, two, { allowPartial: false, allowTrim: false, ignoreCase: true });
    }

    static isCaseInsensitiveMatchAfterTrim(one: string, two: string): boolean {
        if (!this.allAreStrings([one, two])) { return false; }
        // return this.isCaseInsensitiveMatch(_.trim(one), _.trim(two));
        return this.isMatch(one, two, { allowPartial: false, allowTrim: true, ignoreCase: true });
    }

    static oneContainsLowercaseTwo(one: string, two: string) {
        return typeof one == 'string' && toLower(one).indexOf(two) > -1;
    }

    public static containsPartialMatch(one: string, two: string, isCaseSensitive: boolean = false): boolean {
        if (!Validator.isString(one) || !Validator.isString(two)) {
            return false;
        }
        let a: string, b: string;
        if (!isCaseSensitive) {
            a = toLower(one);
            b = toLower(two);
        } else {
            a = clone(one);
            b = clone(two);
        }
        return a.indexOf(b) > -1 || b.indexOf(a) > -1;
    }

    static containsCaseInsensitiveMatch(itemToMatch: string, items: string[]): boolean {
        return !!find(items, item => this.isCaseInsensitiveMatch(item, itemToMatch));
    }

    // TODO use matching params to reduce the amount of methods
    public static containsCaseInsensitiveMatchInItems(value: string, items: string[]): boolean {
        return !!find(items, i => this.isCaseInsensitiveMatch(value, i));
    }

    public static containsPartialCaseInsensitiveMatchInItems(value: string, items: string[]): boolean {
        return !!find(items, i => this.containsPartialMatchCaseInsensitive(value, i));
    }

    static isMatch(one: string, two: string, options?: StringMatchOptions): boolean {
        if (!isString(one) || !isString(two)) { return false; }
        if (!options) { return one == two; }
        let a: string = clone(one), b: string = clone(two);
        if (options.ignoreCase) {
            a = toLower(a);
            b = toLower(b);
        }
        if (options.allowTrim) {
            a = trim(a);
            b = trim(b);
        }
        if (options.allowPartial) {
            return a.indexOf(b) > -1 || b.indexOf(a) > -1;
        } else {
            return a == b;
        }
    }

    static containsOverlappingItem(itemsOne: string[], itemsTwo: string[], options?: StringMatchOptions): boolean {
        return !!find(itemsOne, itemOne => this.containsMatch(itemsTwo, itemOne, options));
    }

    static containsMatch(items: string[], itemToMatch: string, options: StringMatchOptions): boolean {
        return !!find(items, i => this.isMatch(i, itemToMatch, options));
    }

    static hasLowerCase(str: string): boolean {
        return !!str && (/[a-z]/.test(str));
    }
}

export class ErrorService {
    constructor() {

    }

    public static handle(error: any, message: string, showStack?: boolean): Error {
        return new Error(this.getMessage(error, message, showStack));
    }

    public static getMessage(error: any, message: string, showStack?: boolean): string {
        if (!error) {
            return message || 'Unkown error';
        }
        try {
            let resultArray = [];
            if (message) { resultArray.push(message); }

            if (typeof error == 'string') {
                resultArray.push(error);
            } else if (typeof error == 'object') {
                if (showStack) { resultArray.push(get(error, 'stack')) || ''; }
                if (error.Message) { resultArray.push(error.Message); }
                if (error.message) { resultArray.push(error.message); }
                if (size(error.messages)) {
                    for (let m of error.messages) {
                        if (typeof m === 'string') { resultArray.push(m); }
                    }
                }
            }
            let result = size(resultArray) ? resultArray.join('\n') : 'Unknown Error';
            console.error(result);
            return result;
        } catch (e) {
            throw new Error(e);
        }
    }
}


export class SimpleType {
    Name: string;
    Label: string;

    constructor(Name?: string, Label?: string) {
        this.Name = Name || null;
        this.Label = Label || null;
    }

    public static equals(one: SimpleType, two: SimpleType): boolean {
        return one && two && one.Name == two.Name;
    }
}

export class StringMatchOptions {
    ignoreCase?: boolean;
    allowTrim?: boolean;
    allowPartial?: boolean;
}
