import { UserAgreement } from '@noctem/web';

export default {
  id: 0,
  name: 'eula',
  title: 'EULA For COAST™ App',
  textReplacementMethod: 'replaceConsentText',
  questionHeader: '',
  agreementQuestions: [
    {
      answerId: 'reviewed',
      text: 'By checking this box, I agree to the terms and conditions described in this agreement.'
    }
  ],
  pages: [
    {
      title: '',
      imgPath: '',
      text: `
      <p class="pb-2">
      This End-User License Agreement ("EULA") is a legal agreement between you and NOCTEMⓇ Health Inc.
      (“NOCTEM”) that grants you permission to use NOCTEM’s COAST App. (the “App” or “NOCTEM’s COAST
      App”).
    </p>
    
    <p class="py-2">By clicking the box labeled “I agree” at the end of this EULA, you (“you” or the “end user”)</p>
    
    <div class="row pb-4 m-0 flex-nowrap align-items-baseline">
      <span class="text-bold text-primary mr-3">A </span>
      <span>Acknowledge that you have read and understand this agreement</span>
    </div>
    <div class="row pb-4 m-0 flex-nowrap align-items-baseline">
      <span class="text-bold text-primary mr-3">B</span>
      <span
        >Represent that you are 18 years of age or older
      </span>
    </div>
    <div class="row pb-4 m-0 flex-nowrap align-items-baseline">
      <span class="text-bold text-primary mr-3">C</span>
      <span
        >Agree to the terms of this agreement
        with the intent of being legally bound.
      </span>
    </div>
  
    
    <p>
      If you do not agree to the terms of this eula, you will not have permission to use or retain a
      copy of NOCTEM’s COAST app, which should, therefore, be deleted.
    </p>
    
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">License Grant</h2>
      <p class="pb-3">
        Upon your acceptance of the terms of this EULA (by clicking "I Agree"), and provided that you have
        lawfully obtained a User ID and Password, NOCTEM grants you a nonexclusive, limited term, right
        and license to access and use NOCTEM'S COAST App.
      </p>
      <h2 class="pb-2 text-bold">Intellectual Property</h2>
      <p class="pb-3">
        As between you and NOCTEM, all intellectual rights covering or embodied in NOCTEM'S COAST App
        (including, without limitation, copyrights, trademarks, trade secrets) are the sole property of
        NOCTEM
      </p>
      <p class="pb-3">
        This EULA does not grant you any title or ownership interest in NOCTEM's COAST App but grants only
        a limited access and use it
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">User ID/Password/Security.</h2>
      <p class="pb-3">
        You will not use or attempt to use any User ID and Password for any unauthorized purpose.
      </p>
      <p class="pb-3">
        You may not use a third party's User ID or Password or disclose your User ID and Password to third
        parties.
      </p>
      <p class="pb-3">
        You are responsible for any and all actions taken performed with your User ID and Password.
      </p>
      <p class="pb-3">
        You will notify NOCTEM immediately if you become aware of any unauthorized use of your User ID or
        Password or any other security feature of NOCTEM’s COAST App.
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">End User Obligations And Restrictions.</h2>
      <p class="pb-3">
        You may use NOCTEM’s COAST App to access the services, functions, or features it makes available
        to you. You agree to use the App exclusively for authorized and legal purposes, consistent with
        all applicable laws, regulations, and the rights of others, including privacy laws.
      </p>
      <p class="pb-3">
        You will not attempt to reconfigure or engineer changes to NOCTEM’s COAST App or attempt to gain
        access to any unauthorized features of the App. In addition, you will not attempt to interfere
        with or disrupt the App or attempt to gain access to any systems or attempt to scan or probe,
        hack, defeat, or otherwise compromise any security measures of the App or any systems or network
        portals operated by NOCTEM in connection with the COAST App.
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">Consent For Internet-Based or Wireless Services.</h2>
      <p class="pb-3">
        NOCTEM’s COAST App may connect to computer systems over an Internet-based and/or wireless network
        or connectivity. In some cases, you will not receive a separate notice when they connect. Using
        the App operates as your consent to the transmission of standard device information (including but
        not limited to technical information about your device, system and the App software, and
        peripherals) for Internet-based and/or wireless services.
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">Technical Data</h2>
      <p class="pb-3">
        You agree that NOCTEM may collect and use technical data and related information—including but not
        limited to technical information about your device and system usage that is gathered periodically
        to facilitate the provision NOCTEM’S COAST App, updates, product support NOCTEM may use this
        information, as long as it is in a form that does not personally identify you, to improve its
        products or to provide services or technologies to you. By downloading the App you consent to the
        app allocating up to 250 MB of storage space on your device.
      </p>
      <h2 class="pb-2 text-bold">Personal Information/Privacy.</h2>
      <p class="pb-3">
        NOCTEM will maintain the confidentiality of any personal or health information that you provide to
        NOCTEM through use of the App in accordance with applicable law and the terms of NOCTEM’s Privacy
        Policy.
      </p>
      <p class="pb-3">
        NOCTEM’s Privacy Policy, which may be revised or updated from time to time, is available for
        review at
        <a href="https://noctemhealth.com/privacy" target="_blank">noctemhealth.com</a>
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">Disclaimer Of Warranties</h2>
      <p class="pb-3">
        NOCTEM provides access to the app subject to this EULA and on an “as is” basis only.
      </p>
      <p class="pb-3">
      NOCTEM makes no warranties of any kind, either expressed or implied, as to any matter including,
        but not limited to, warranty of fitness for particular purpose, or merchantability.
      </p>
      <p class="pb-3">
      NOCTEM does not guarantee the continuous, uninterrupted operation of the COAST app or any related
        services. NOCTEM shall not be liable for indirect, special, or consequential damages such as loss
        of profits or inability to use the coast app or any related services or derivations thereof.
      </p>
      <p class="pb-3">
        Under no circumstance will NOCTEM be liable for damages greater than amounts received by NOCTEM in
        consideration of this specific eula or for any indirect, incidental, special or consequential
        damages, loss of profits, interruption of business or related expenses that may arise from your
        use of the COAST app, including but not limited to any that result from defects in the coast app,
        loss of data, or inaccuracies of any kind.
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold"> Professional Responsibility Disclaimer, Indemnification.</h2>
      <p class="pb-3">
        NOCTEM’s COAST app is intended as a supplement to, and not a substitute for, the knowledge, expertise, skill, and judgment of physicians, psychologists, sleep therapists, nurses, or other healthcare professionals providing patient care, clinical diagnosis or medical treatment.
      </p>
      <p class="pb-3">
        Your use of the app is not a substitute for professional judgment.
      </p>
      <p class="pb-3">
        The app may not collect or consider all data, information, resources, or medical history necessary for a complete or accurate health assessment or advice, and information collected through the coast app may not always be accurate.
      </p>
      <p class="pb-3">
        Accordingly, NOCTEM does not assume and expressly disclaims any responsibility for your acts or omissions of which may result in any liability or damages due to malpractice, failure to warn, negligence or any other basis and you hereby agree to indemnify, defend and hold harmless any NOCTEM indemnified party against any claims arising out of, or related to, any such malpractice, failure to warn, negligence or any similar claim whether arising out of contract, tort, regulatory or statutory causes of action.
      </p>      
      `
    },
    {
      title: '',
      imgPath: '',
      text: `
      <h2 class="pb-2 text-bold">Indemnification</h2>
      <p class="pb-3">
        You agree to defend, indemnify and hold harmless NOCTEM, its directors, officers, employees,
        attorneys and agents from all claims or demands made against them (and any related losses,
        expenses or attorneys’ fees) arising out of or relating to your use of or conduct regarding
        NOCTEM’s COAST App.
      </p>
      <h2 class="pb-2 text-bold">Termination</h2>
      <p class="pb-3">
        Either you or NOCTEM may terminate this EULA at any time, for any reason, or for no reason,
        without notice.
      </p>
      <p class="pb-3">
        In addition, this EULA shall terminate immediately in the event you breach this Agreement. Upon
        termination, you will immediately discontinue access to and use of the App.
      </p>
      
      `
    },
    {
      title: '',
      imgPath: '',
      nextButtonText: 'Complete',
      text: `
      <h2 class="pb-2 text-bold">Miscellaneous Provisions</h2>
<p class="pb-3">
  No Waiver. No delay or omission by NOCTEM to exercise any right or power under any term of this
  EULA shall be a waiver of NOCTEM’s rights to enforce this EULA in any respect.
</p>
<p class="pb-3">
  <span class="text-bold"> Severability: </span>
  If any provision of this Agreement is found to be illegal, invalid, unenforceable or void by a
  court of competent jurisdiction, then both parties shall comply with the provisions to the extent
  permitted by law. All provisions of this EULA shall be enforced to the fullest extent permitted by
  law.
</p>
<p class="pb-3">
  <span class="text-bold"> No Third Party Beneficiaries: </span>
  Nothing in this agreement creates any third party beneficiary rights.
</p>
<p class="pb-3">
  <span class="text-bold"> Headings: </span>
  Section headings contained in this Agreement are for reference purposes only and are not a part of
  this Agreement.
</p>
<p class="pb-3">
  <span class="text-bold"> Entire Agreement: </span>
  This EULA is the entire agreement between you and NOCTEM and supersedes all other agreements,
  understandings, statements and representations, written or oral, concerning the subject matter
  hereof.
</p>
<p class="pb-3">
  <span class="text-bold"> Governing Law, Forum: </span>
  This Agreement and all matters arising out of or relating to this Agreement shall be governed by
  the laws of the Commonwealth of Pennsylvania, without regard to its conflict of law provisions.
</p>
<ul class="pl-2">
  <li class="pr-2">
    Any legal action or proceeding relating to this Agreement shall be brought exclusively in the
    state or federal courts located in Allegheny County, Pennsylvania.
  </li>
  <li class="pr-2">
    You agree to submit to the exclusive jurisdiction of those courts in any such legal action or
    proceeding.
  </li>
  <li class="pr-2">
    In addition, you hereby waive any claim that any such action has been brought in an inconvenient
    forum, or that the court in Allegheny County does not have jurisdiction over the Parties, or that
    venue is improper.
  </li>
</ul>

<h2 class="pb-3">
  You may address questions about this EULA to NOCTEM at the following addresses:
</h2>

<h2 class="pb-3 text-bold">218 Oakland Avenue Pittsburgh PA 15213</h2>

<a href="mailto:support@noctemhealth.com">support@noctemhealth.com</a>

      `
    }
  ]
} as UserAgreement;
