import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { powerOfSleep } from '../questions/power-of-sleep/power-of-sleep';

export const powerOfSleepDef: AssetDefinition = {
  id: 25,
  name: 'power-of-sleep',
  label: 'Power of Sleep',
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    powerOfSleep
  ],
  url: 'power-of-sleep'
};
