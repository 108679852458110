export const niqWDef = {
    "name": "niqw",
    "description": "Noctem Insomnia Questionnaire (NIQ)",
    "instructions": "",
    "questions": [
        /* commenting now, to only use the template from the database 
      {
      "id": 0,
      "questionText": "In the past week, I have had problems going to sleep: ",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Never",
          "value": 0
        },
        {
          "display": "Sometime",
          "value": 1
        },
        {
          "display": "Frequently",
          "value": 2
        },
        {
          "display": "All the time",
          "value": 3
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "NIQ_GOING_TO_SLEEP"
    },
    {
      "id": 1,
      "questionText": "In the past week, after falling asleep, I have awakened and have had difficulty going back to sleep:",
      "questionEmphasis": " ",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Never",
          "value": 0
        },
        {
          "display": "Sometime",
          "value": 1
        },
        {
          "display": "Frequently",
          "value": 2
        },
        {
          "display": "All the time",
          "value": 3
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "NIQ_GOING_BACK_TO_SLEEP"
    },
    {
      "id": 2,
      "questionText": "In the past week, I have awakened before I want to: ",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Never",
          "value": 0
        },
        {
          "display": "Sometime",
          "value": 1
        },
        {
          "display": "Frequently",
          "value": 2
        },
        {
          "display": "All the time",
          "value": 3
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "NIQ_AWAKEN_EARLY"
    },
    {
      "id": 3,
      "questionText": "In the past week, the quality of my sleep was:",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "10  Very Good",
          "value": 10
        },
        {
          "display": "9   ",
          "value": 9
        },
        {
          "display": "8   Good",
          "value": 8
        },
        {
          "display": "7   ",
          "value": 7
        },
        {
          "display": "6   Average",
          "value": 6
        },
        {
          "display": "5   ",
          "value": 5
        },
        {
          "display": "4   Poor",
          "value": 4
        },
        {
          "display": "3   ",
          "value": 3
        },
        {
          "display": "2   ",
          "value": 2
        },
        {
          "display": "1   Very Poor",
          "value": 1
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "NIQ_SLEEP_QUALITY"
    },
    {
      "id": 5,
      "questionText": "In the past week, worries about my sleep problems have occupied my thoughts: ",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Never",
          "value": 0
        },
        {
          "display": "Sometime",
          "value": 1
        },
        {
          "display": "Frequently",
          "value": 2
        },
        {
          "display": "All the time",
          "value": 3
        }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "NIQ_WORRIES_ABOUT_SLEEP"
    },
    {
      "id": 6,
      "questionText": "In the past week, my problems sleeping have caused me problems during the time I am awake: ",
      "questionEmphasis": "",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "conditionalQuestions": [
        {
          "displayIfAnswerEquals": [
            true
          ],
          "id": 7,
          "questionText": "Select all that apply: ",
          "questionType": "checkbox",
          "autofill": false,
          "answerOptions": [
            {
              "display": "Aches or pains",
              "value": 0
            },
            {
              "display": "Difficulty focusing or concentrating",
              "value": 1
            },
            {
              "display": "Irritability or poor mood",
              "value": 2
            },
            {
              "display": "Sleepiness",
              "value": 3
            },
            {
              "display": "Problems with school or work performance",
              "value": 4
            }
          ],
          "uniqueAnswerId": "NIQ_AWAKE_TIME_PROBLEMS",
          "conditionalQuestions": []
        }
      ],
      "uniqueAnswerId": "NIQ_CAUSE_AWAKE_TIME_PROBLEMS"
    }*/
    ]
  };
  