import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const trauma: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'trauma',
    unit: UnitType.YesNo,
    abbreviation: 'Trauma',
    description: `Trauma`,
    answerId: 'TRAUMA',
    assessmentType: LogDefinitionTypes.Trauma
  }),

  //#endregion
];
