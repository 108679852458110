<div class="wrapper" #page>
  <div class="print-consent" *ngFor="let page of template?.pages">
    <div mat-dialog-content>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="row breathe">
              <div class="terms">
                <div [innerHtml]="page.content"></div>
                <div *ngIf="page.requireInitials">
                  <p><i>INITIAL:</i> {{initials[template?.pages.indexOf(page)]}}</p>
                  <img src="../../../../../assets/img/consent/{{template.imgName}}" width="170" class="consent-img-large">
                </div>
                <div *ngIf="template?.pages.indexOf(page) === template?.pages.length - 1">
                  <p><i>SIGNATURE:</i> {{consent.signature}}</p>
                  <img src="../../../../../assets/img/consent/{{template.imgName}}" width="170" class="consent-img-large">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
(Page Break)
  </div>
</div>
<div id="editor"></div>
<div class="modal-footer">
  <div mat-dialog-actions>
    <button mat-button class="btn btn-secondary" (click)="printPdf()">Download as PDF</button>
    <button mat-button class="btn btn-primary" (click)="print()">Print Consent</button>
  </div>
</div>
