import { TreatmentPhase } from '../../../lib/state';
import { AssessmentTemplate } from '../../../lib/services/models';

export const weekThreeDef = {
  name: 'week3',
  label: 'Week 3',
  order: 4,
  conditionalAssessments: [
    {
      conditionPhase: 'baseline',
      conditionAssessment: AssessmentTemplate.trauma.name,
      conditionField: 'TRAUMA',
      options: [
        {
          value: true,
          assessmentsToAdd: [AssessmentTemplate.pcPtsd.name],
          assessmentsToRemove: [],
          addAfterAssessment: AssessmentTemplate.gad2.name
        },
        {
          value: false,
          assessmentsToAdd: [],
          assessmentsToRemove: [AssessmentTemplate.pcPtsd.name],
          addAfterAssessment: AssessmentTemplate.gad2.name
        }
      ]
    }
  ],
  assessments: [
    AssessmentTemplate.morningLog.name,
    AssessmentTemplate.eveningLog.name,
    AssessmentTemplate.essWeekly.name,
    AssessmentTemplate.peg.name,
    AssessmentTemplate.sideEffects.name,
    AssessmentTemplate.medicationUse.name,
    AssessmentTemplate.pgi.name,
    AssessmentTemplate.phq2.name,
    AssessmentTemplate.gad2.name
  ]
} as TreatmentPhase;
