import { LogDefinitionTypes } from "../../../../noctem-lib/src/constants/constants";
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition, AssessmentGroupScoreFieldDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";
import { Dictionary } from "../../dictionary";

export const vacdsFields: Array<FieldDefinitionBase> =
[
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'vacds',
    unit: UnitType.Number,
    abbreviation: 'VACDS',
    description: `VACDS`,
    assessmentType: LogDefinitionTypes.VACDS,
    answerId: 'CURRENT_DISTRESS_SCALE'
  })
]
