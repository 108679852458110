import { LogDefinitionTypes } from "../../../../noctem-lib/src/constants/constants";
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition, AssessmentGroupScoreFieldDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const pcl5ShortFields: Array<FieldDefinitionBase> =
[
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'pcl5Short-overall-lowest',
    unit: UnitType.Number,
    abbreviation: 'Pcl 5 Overall',
    description: `Pcl 5 Overall`,
    aggregateType: 'lowest',
    assessmentType: LogDefinitionTypes.PCL5Short
  }),
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'pcl5Short-overall',
    unit: UnitType.Number,
    abbreviation: 'Pcl 5 Overall',
    description: `Pcl 5 Overall`,
    aggregateType: 'average',
    assessmentType: LogDefinitionTypes.PCL5Short
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-relive',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5Short,
    answerId: 'STRESS_RELIVE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-avoid',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5Short,
    answerId: 'STRESS_AVOID'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-distant',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5Short,
    answerId: 'STRESS_DISTANT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcl5-irritable',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.PCL5Short,
    answerId: 'STRESS_IRRITABLE'
  })
];
