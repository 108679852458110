import { CacheService }         from '../../../../noctem-lib/src/lib/services/cache-service';
import { AssessmentQuestion }   from '../../../../noctem-lib/src/lib/services/models';
import { Meta }                 from '../../../../noctem-lib/src/core';
import { AssetDefinition } from '../../core/contracts/models.calculations';

export class LogCacheServiceInterface {

    public saveLogForResubmission(logType: string, questions: Array<AssessmentQuestion>, logDate: string, isPartial?: boolean, questionsAnswered?:number, isMedicRecommended?:boolean, logMeta?: Meta, percentage?: number) {
        throw(new Error('The method saveLogForResubmission must be overriden.'))
    }

    public saveLastLogDate(logType: string, logDate: string) {
        throw(new Error('The method saveLastLogDate must be overriden.'))
    }

    public hasCompletedLogTypeToday(logType: string): boolean {
        throw(new Error('The method hasCompletedLogTypeToday must be overriden.'))
    }

    public submitCachedLogs() {
        throw(new Error('The method submitCachedLogs must be overriden.'))
    }

    public logsAvailable() {
        throw(new Error('The method logsAvailable must be overriden.'))
    }

    public retrieveCachedLogs() {
        throw(new Error('The method retrieveCachedLogs must be overriden.'))
    }

    public retrieveToDoAssessment(): Promise<Map<string, AssetDefinition>> {
      throw(new Error('The method retrieveCachedLogs must be overriden.'))
    }
}
