import { gad7 } from './gad7';
import { essWeekly } from './ess';
import { medicationUse } from './medication-use';
import { pcPtsd5 } from './pcPtsd';
import { pgi } from './pgi';
import { sideEffects } from './side-effects';
import { swps } from './swps';
import { phq8 } from './phq8';
import { pcl5 } from './pcl5';
import { isi } from './isi';
import { peg } from './peg';
import { promis } from './promis';
import { shiftWork } from './shift-work';
import { sleepGoalsPre } from './sleep-goals-pre';
import { sleepGoalsPost } from './sleep-goals-post';
import { trauma } from './trauma';
import { morningLog } from './morningLog';
import { eveningLog } from './eveningLog';
import { sus } from './sus';
import { sus2 } from './sus2';
import { gad2 } from './gad2';
import { phq2 } from './phq2';

export const questions = {
  morningLog,
  eveningLog,
  essWeekly,
  gad2,
  gad7,
  isi,
  medicationUse,
  pcl5,
  pcPtsd5,
  peg,
  pgi,
  phq2,
  phq8,
  promis,
  shiftWork,
  sideEffects,
  sleepGoalsPre,
  sleepGoalsPost,
  sus,
  sus2,
  swps,
  trauma
};
