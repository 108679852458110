import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { aceDaytimeSleep } from '../questions/ace-daytime-sleep/ace-daytime-sleep';

export const aceDaytimeSleepDef: AssetDefinition = {
  id: 0,
  name: 'ace-daytime-sleep',
  description: `Getting quality daytime sleep is a challenge. Help your Soldiers ace daytime sleep by following these 3 tips including napping, having a good sleep environment, and winding down before sleep. `,
  label: 'Ace Daytime Sleep',
  categories: ['all', 'sleep-help', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    aceDaytimeSleep
  ],
  url: 'ace-daytime-sleep'
};
