const OSA_DETAILS_TRUE = ['osa-details-true'];
const OSA_DETAILS_FALSE = ['osa-details-false'];

export const osaDef = {
  name: 'osa',
  description: 'Obstructive Sleep Apnea',
  instructions: '',
  questions: [
    {
      id: 0,
      questionText: 'Have you ever been diagnosed with obstructive sleep apnea?',
      uniqueAnswerId: 'OSA_DIAGNOSED',
      questionType: 'radio',
      autofill: false,
      conditionalQuestions: [],
      redirectOptions: [
        { value: true, assessmentsToRemove: OSA_DETAILS_FALSE, assessmentsToAdd: OSA_DETAILS_TRUE },
        { value: false, assessmentsToRemove: OSA_DETAILS_TRUE, assessmentsToAdd: OSA_DETAILS_FALSE }
      ],
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ]
    }
  ]
};
