<div class="row accordion">
    <div class="chart-title-container key-title accordion rounded-top-left col-8">
        {{chartTitle}}
    </div>
    <div class="chart-title-container time-span-text rounded-top-right col-4">
        <div class="bottom-text">
            {{timeSpan}}
        </div>
    </div>
</div>
<div class="card-body">
    <charts-component [data]="data" [xAxisLabels]="xAxisLabels" [options]="options" [chartType]="chartType"
        [legend]="legend" [inAccordion]="true">
    </charts-component>
</div>