import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
import { DatePipe } from '@angular/common';
import { PatientStateService } from '@noctem/web';

@Component({
    selector: 'add-check-in-dialog',
    styleUrls: ['../../patient-page.component.scss'],
    templateUrl: './add-check-in-dialog.component.html',
})
export class AddCheckinDialog implements OnInit {
    public pipe = new DatePipe('en-US'); 
    public checkInDate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    public checkInNotes;
    constructor(
        public dialogRef: MatDialogRef<any>,
        public stateService: PatientStateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        const state = this.stateService.stateModel.get();
        const startTime = new Date();
        const eventTime = this.pipe.transform(new Date(), 'HH:00');
        startTime.setHours(parseInt(eventTime.split(':')[0]), parseInt(eventTime.split(':')[1]));
        const eventInstance = {
            phaseName: 9,
            endTime: null,
            isDownloaded: false,
            user: {
                id: state.userId,
                display: `${state.patientInfo.profile.firstName} ${state.patientInfo.profile.lastName}`,
                model: 'UserSettings'
              },
            repeatTimes: 1,
            repeatType: 1,
            startTime: this.pipe.transform(new Date(startTime), 'hh:mm a'),
            startDate: this.checkInDate,
            endDate: null,
            title: `[${state.patientInfo.profile.firstName}${state.patientInfo.profile.lastName}]Check In`,
            color: '#000000'
        }
        this.stateService.addCheckin(eventInstance);
        this.dialogRef.close();
    }

   

    ngOnInit() {
    }

}