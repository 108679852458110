import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

const greenMessage = 'Feel calm and at ease, confident and prepared';
const yellowMessage = 'Moderate anxiety, some restlessness or occasional worrisome thoughts';
const redMessage = 'Extreme distress, unable to relax, constant worry';

export const vacds: AssessmentDefinition = {
  name: 'vacds',
  description: 'Visual Analog Current Distress Scale',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'What is your current level of distress?',
      name:'vacds',
      questionEmphasis: null,
      questionType: 'slider',
      uniqueAnswerId: 'CURRENT_DISTRESS_SCALE',
      autofill: false,
      step: 1,
      max: 10,
      min: 0,
      answerOptions: [
        { display: greenMessage, value: 0 },
        { display: greenMessage, value: 1 },
        { display: greenMessage, value: 2 },
        { display: greenMessage, value: 3 },
        { display: yellowMessage, value: 4 },
        { display: yellowMessage, value: 5 },
        { display: yellowMessage, value: 6 },
        { display: redMessage, value: 7 },
        { display: redMessage, value: 8 },
        { display: redMessage, value: 9 },
        { display: redMessage, value: 10 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
  ]
};
