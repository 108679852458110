import { NgModule, Optional, SkipSelf, APP_INITIALIZER, Injector, ModuleWithProviders, Injectable, Inject } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { MenuService } from './menu/menu.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { USER_STATE_SERVICE, ANON_TOKEN,
    ApplicationContext, TranslationService, useModelFactory, ModelFactory,
    UserService, LOG_CACHE, TreatmentPlanStateService, LogStateService  } from '@noctem/web';
import { initFactory } from './initFactory';
import { ServiceBus } from './state/service-bus.service';
import { socketConfig, ApplicationStateService, LoggedInGuard, UserStateService, AuthService } from '@noctem/web';


import {CalendarStateService} from '../../../../../noctem-lib/src/lib/state/calendar-state.service'
import { ChatStateService, PatientStateService, DashboardStateService, MessageService, Translations } from '@noctem/web';
import { LogService, AssessmentService } from '../../../../../noctem-lib/src/lib/services/services';
import { MessageThreadService } from "../../../../../noctem-lib/src/lib/services/MessageThreadService";
import { environment } from '../../environments/environment';
import { SearchService, UserFuncService } from '../../../../../noctem-lib/src/lib/services/services';
import { Router } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SocketIoModule, Socket } from 'ngx-socket-io';
import { CALC_CONFIG } from '../../../../../noctem-lib/src/public-api';
import { CalculationConstants} from '../../../../constant-definitions/constants';
import { CacheService } from '../../../../../noctem-lib/src/lib/services/cache-service';
import { NetworkService } from '../../../../../noctem-lib/src/lib/services/network-service';
import { ChartService } from '../../../../../noctem-lib/src/lib/state/charts-service';
import { LogCacheService } from '../../../../customer/src/app/logs/log-cache-service';
import { RTMReportService } from 'projects/noctem-lib/src/core/services/RTMReportService';

export function getTranslationsService(appContext: ApplicationContext) {
    return new TranslationService(appContext, new Translations());
}

export function getUserService(appContext: ApplicationContext) {
    return new UserService(appContext);
}

export function getUserFuncService(appContext: ApplicationContext, userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
    return new UserFuncService(appContext, userStateService, new CacheService(), getNetworkService());
}

export function getSearchService(appContext: ApplicationContext, userStateService: UserStateService, cacheService: CacheService, networkService: NetworkService) {
    return new SearchService(appContext, userStateService, new CacheService(), getNetworkService());
}

export function getSocket() { return new Socket(socketConfig); }

export class NotLoggedInGuard {

}

export function getNetworkService() {
    let socket = new Socket(socketConfig)
    return new NetworkService(socket);
}

export function getCacheService() {
    return new CacheService();
}

export function getMessageService(appContext: ApplicationContext) { return new MessageService(appContext); }

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        [{
            provide: ModelFactory,
            useFactory: useModelFactory
        }],
        SettingsService,
        MenuService,
        ApplicationContext,
        LogService,
        LogStateService,
        MessageThreadService,
        AssessmentService,
        AuthService,
        TreatmentPlanStateService,
        { provide: 'ngxSocket', useFactory: getSocket },
        HttpClient,
        { provide: TranslationService, useFactory: getTranslationsService, deps: [ApplicationContext] },
        { provide: USER_STATE_SERVICE, useClass: UserStateService },
        { provide: ANON_TOKEN, useValue: environment.token },
        { provide: CALC_CONFIG, useValue: CalculationConstants },
        { provide: LOG_CACHE, useValue: LogCacheService}
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders {
        return {
          ngModule: CoreModule,
          providers: [
            ApplicationContext,
            AuthService,
            ApplicationStateService,
            ServiceBus,
            ApplicationContext,
            CalendarStateService,
            ChatStateService,
            PatientStateService,
            DashboardStateService,
            ChartService,
            LogService,
            UserStateService,
            { provide: TranslationService, useFactory: getTranslationsService, deps: [ApplicationContext] },
            { provide: UserService, useFactory: getUserService, deps: [ ApplicationContext ] },
            { provide: SearchService, useFactory: getSearchService, deps: [ ApplicationContext ] },
            { provide: UserFuncService, useFactory: getUserFuncService, deps: [ ApplicationContext ] },
            { provide: USER_STATE_SERVICE, useClass: UserStateService },
            { provide: NetworkService, useFactory: getNetworkService },
            { provide: CacheService, useFactory: getCacheService },
            LoggedInGuard,
            { provide: ANON_TOKEN, useValue: environment.token },
            {
              provide: APP_INITIALIZER,
              useFactory: initFactory,
              deps: [ Injector ],
              multi: true
            },
            { provide: MessageService, useFactory: getMessageService, deps: [ApplicationContext] },
            { provide: RTMReportService, deps:[ ApplicationContext ] },
          ]
        };
    }
}
