import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const peg: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'peg-overall',
    unit: UnitType.Number,
    abbreviation: 'PEG Overall',
    description: `PEG Overall`,
    assessmentType: LogDefinitionTypes.PEG
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'peg-scale',
    unit: UnitType.Number,
    abbreviation: 'Scale',
    description: `Scale`,
    assessmentType: LogDefinitionTypes.PEG,
    answerId: 'PAIN_SCALE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'peg-scale-enjoyment',
    unit: UnitType.Number,
    abbreviation: 'Scale Enjoyment',
    description: `Scale Enjoyment`,
    assessmentType: LogDefinitionTypes.PEG,
    answerId: 'PAIN_SCALE_ENJOYMENT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'peg-scale-activity',
    unit: UnitType.Number,
    abbreviation: 'Scale Activity',
    description: `Scale Activity`,
    assessmentType: LogDefinitionTypes.PEG,
    answerId: 'PAIN_SCALE_ACTIVITY'
  }),

  //#endregion
];
