import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const pcPtsd: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'pcPtsd-overall',
    unit: UnitType.Number,
    abbreviation: 'PC-PTSD Overall',
    description: `PC-PTSD Overall`,
    assessmentType: LogDefinitionTypes.PCPTSD5
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcptsd-nightmare',
    unit: UnitType.YesNo,
    abbreviation: 'Nightmare',
    description: `Nightmare`,
    assessmentType: LogDefinitionTypes.PCPTSD5,
    answerId: 'PCPTSD_NIGHTMARE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcptsd-avoid',
    unit: UnitType.YesNo,
    abbreviation: 'Avoid',
    description: `Avoid`,
    assessmentType: LogDefinitionTypes.PCPTSD5,
    answerId: 'PCPTSD_AVOID'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcptsd-on-guard',
    unit: UnitType.YesNo,
    abbreviation: 'On Guard',
    description: `On Guard`,
    assessmentType: LogDefinitionTypes.PCPTSD5,
    answerId: 'PCPTSD_ON_GUARD'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcptsd-numb',
    unit: UnitType.YesNo,
    abbreviation: 'Numb',
    description: `Numb`,
    assessmentType: LogDefinitionTypes.PCPTSD5,
    answerId: 'PCPTSD_NUMB'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pcptsd-blame',
    unit: UnitType.YesNo,
    abbreviation: 'Blame',
    description: `Blame`,
    assessmentType: LogDefinitionTypes.PCPTSD5,
    answerId: 'PCPTSD_BLAME'
  }),
  //#endregion
];
