import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AssessmentAnswer, AssessmentQuestion } from '../../services/models';
import { some as _some } from 'lodash';
import { LogStateService } from '../../state';

@Component({
  selector: 'log-question',
  templateUrl: './log-question.component.html',
  styleUrls: []
})
export class LogQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Output() questionAnswered: EventEmitter<string> = new EventEmitter();
  @Output() questionsToAdd: EventEmitter<Array<AssessmentQuestion>> = new EventEmitter();
  @Output() questionsToRemove: EventEmitter<Array<AssessmentQuestion>> = new EventEmitter();
  @Output() inputFocused: EventEmitter<any> = new EventEmitter();
  @Output() inputBlurred: EventEmitter<any> = new EventEmitter();
  @Output() checkboxQuestionsAnswered: EventEmitter<any[]> = new EventEmitter();
  @Output() multipleQuestionsAnswered: EventEmitter<any[]> = new EventEmitter();
  answerValue: any;
  checkboxAnswers = new Array<{ display: string; value: any; fillin?: string; isChecked?: boolean }>();
  isModalDisplayed: boolean;
  multipleQuestionAnswers = []; // multiple question questions will have multiple answers. Use an array to keep track of them

  constructor(private logStateService: LogStateService) {}

  ngOnInit() {
    if (this.question.questionType === 'date' && this.question.autofill) {
      if (this.question?.answer !== null) {
        this.answerValue = this.question.answer;
      } else {
        this.answerValue = new Date().toLocaleDateString('en-US');
        this.questionAnswered.emit(this.answerValue.toISOString().split('T')[0]);
      }
    } else {
      if (
        this.question.hasOwnProperty('answer') &&
        (this.question.answer !== null || this.question.answer === false)
      ) {
        this.answerValue = this.question.answer;
      } else {
        this.answerValue = null;
      }

      this.logStateService.state$.subscribe((logState) => {
        this.isModalDisplayed = logState.isModalDisplayed;
      });
    }
    if (this.question.questionType === 'checkbox') {
      if(this.question.answer) {
        this.checkForConditionalCheckboxQuestions(this.question.answer);
      }
    } else {
      this.checkForConditionalQuestions(this.answerValue);
    }
  }

  openTimeInput() {
    document.getElementById('timeInput').blur();
    this.logStateService.setIsModalDisplayed(true);
  }

  openNumberWheelInput() {
    document.getElementById('numberInput').blur();
    this.logStateService.setIsModalDisplayed(true);
  }

  onTimeQuestionAnswered(e) {
    this.answerValue = e;
    this.questionAnswered.emit(e);
    this.checkForConditionalQuestions(e);
  }

  valueUpdated(e) {
    if (e && e.target && e.target.value && e.target.value !== '') {
      let value = e.target.value === 'select' || e.target.value === 'v' ? null : e.target.value;
      value = value === 0 ? '0' : value;
      this.questionAnswered.emit(value);
      // NOTE: event.which is depreciated. Do we still need this?
      if (e.which === 13) {
        e.target.blur();
      }

      this.checkForConditionalQuestions(value);
    }
  }

  selectValueUpdated(e) {
    this.inputBlurred.emit(true);
    this.valueUpdated(e);
    
    // Ensure the navigation is shown after change but before blur
    this.logStateService.setIsModalDisplayed(false);
  }

  valueValidated(e, maxlength) {
    if (e && e.target && e.target.value && e.target.value !== '') {
      return e.target.value.length < maxlength;
    } else {
      return true;
    }
  }

  controlFocused(event: FocusEvent) {
    this.inputFocused.emit(true);

    // Another option to ensure that navigation is shown regardless of the value in a select input being different is to simply not trigger this on focus
    this.logStateService.setIsModalDisplayed(true);
  }

  controlBlurred(event: FocusEvent) {
    this.inputBlurred.emit(true);
    this.logStateService.setIsModalDisplayed(false);
  }

  radioValueUpdated(answer) {
    answer = answer === 0 ? '0' : answer;
    this.questionAnswered.emit(answer);
    this.checkForConditionalQuestions(answer);
  }

  numberWheelValueUpdated(answer) {
    answer = answer === 0 ? '0' : answer;
    this.answerValue = answer;
    this.questionAnswered.emit(answer);
    this.checkForConditionalQuestions(answer);
  }

  checkboxValueUpdated(answer) {
    // Update the answers that are checked
    // If the answer.clearOtherAnswers is true remove all other options and vice versa
    this.checkboxAnswers = this.question.answerOptions.map(ans => {
      if (answer.clearOtherAnswers) {
        if (ans.value === answer.value && answer.isChecked) {  //if isChecked is not included this will always return true
            ans.isChecked = true;
            return ans;
        }
        ans.isChecked = false;
        return ans;
      }
      if (ans.clearOtherAnswers) {
        ans.isChecked = false;
        return ans;
      }
      if (ans.value !== answer.value) return ans;
      ans.isChecked = answer.isChecked
      return ans;
    });

    this.checkboxQuestionsAnswered.emit(this.checkboxAnswers);
    this.checkForConditionalCheckboxQuestions(this.checkboxAnswers);
  }

  private checkForConditionalCheckboxQuestions(checkboxAnswers) {
    // Populate questions to add and remove to determine display of conditional questions
    // available to a checkbox question
    if (checkboxAnswers) {
      const checkedAnswers: any[] = checkboxAnswers
        .filter(answer => answer.isChecked)
        .map(answer => answer.value);
      if (this.question.conditionalQuestions?.length > 0) {
        const questionUpdates = this.question.conditionalQuestions.reduce(
          (acc, conditionalQuestion) => {
            const answerIsChecked = conditionalQuestion.displayIfAnswerEquals.some(a =>
              checkedAnswers.includes(a)
            );
            if (answerIsChecked) {
              acc.questionsToAdd.push(conditionalQuestion);
            } else {
              acc.questionsToRemove.push(conditionalQuestion);
            }
            return acc;
          },
          { questionsToAdd: [], questionsToRemove: [] }
        );
        this.questionsToAdd.emit(questionUpdates.questionsToAdd);
        this.questionsToRemove.emit(questionUpdates.questionsToRemove);
      }
    }
  }

  private checkForConditionalQuestions(value) {
    value = this.convertToBoolean(value);

    if (this.question.conditionalQuestions?.length > 0) {
      const questionsToAdd = this.question.conditionalQuestions.filter(q =>
        _some(
          q.displayIfAnswerEquals,
          answer => answer === value || (value && answer.toString() === value.toString())
        )
      );

      const questionsToRemove = this.question.conditionalQuestions.filter((q) => {
        return q.displayIfAnswerEquals.find(a => a === value || a.toString() === value?.toString()) == undefined;
      });

      if (questionsToAdd?.length > 0) {
        this.questionsToAdd.emit(questionsToAdd);
      }

      if (questionsToRemove?.length > 0) {
        const questionsToRemoveReset = [];
         
        questionsToRemove.forEach(q => {
          q.answer = null
          questionsToRemoveReset.push(q)
        });
        this.questionsToRemove.emit(questionsToRemoveReset);
      }

      if (value === null) {
        this.questionsToRemove.emit(this.question.conditionalQuestions);
      }
    }
  }

  // This will continually update the "other" textbox answer for checkboxes
  otherInputUpdated(answer) {
    // const index = this.checkboxAnswers
    //   .map((ans) => ans.value)
    //   .indexOf(answer.value); // See if this answer is already selected
    // this.checkboxAnswers[index].fillin = answer.fillin;
    // this.checkboxQuestionsAnswered.emit(this.checkboxAnswers);
  }

  handleSelectAll(element) {
    // if (element.checked) {
    //   // Select all has been toggled to on. Any checkbox not in the checkboxAnswers array should be added
    //   this.question.answerOptions.forEach((answerOption) => {
    //     const index = this.checkboxAnswers
    //       .map((ans) => ans.value)
    //       .indexOf(answerOption.value); // See if this answer is already selected
    //     if (index && answerOption.value !== 0) {
    //       this.checkboxAnswers.push({ value: answerOption.value }); // If it's not, add it
    //     }
    //   });
    // } else {
    //   // Select all has been toggled to off. Remove all answers from checkAnswers
    //   this.checkboxAnswers.length = 0;
    // }
    // this.checkboxQuestionsAnswered.emit(this.checkboxAnswers);
  }

  private convertToBoolean(value: string) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }

  public growWrap() {
    const growers = Array(document.querySelector(".grow-wrap")  as HTMLElement | null);

    growers.forEach((grower) => {
      const textarea = grower.querySelector("textarea");
      textarea.addEventListener("input", () => {
        grower.dataset.replicatedValue = textarea.value;
      });
    });
  }
}
