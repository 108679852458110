import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AssessmentQuestion, AssessmentAnswer } from '../../services/models';
import { some as _some } from 'lodash';
import { LogStateService } from '../../state';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'check-in-question',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './check-in-question.component.html',
  styleUrls: ['./check-in-question.component.scss']
})
export class CheckInQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Input() showLabels = true;
  @Output() questionAnswered: EventEmitter<string> = new EventEmitter();
  @Output() checkboxQuestionsAnswered: EventEmitter<any[]> = new EventEmitter();
  @Output() multipleQuestionsAnswered: EventEmitter<any[]> = new EventEmitter();
  @Output() questionsToAdd: EventEmitter<Array<AssessmentQuestion>> = new EventEmitter();
  @Output() questionsToRemove: EventEmitter<Array<AssessmentQuestion>> = new EventEmitter();
  @Output() inputFocused: EventEmitter<any> = new EventEmitter();
  @Output() inputBlurred: EventEmitter<any> = new EventEmitter();
  public subText: SafeHtml;
  answerValue: any;
  isModalDisplayed: boolean;
  checkboxAnswers: Array<{ value: string; fillin?: string }> = []; // checkbox questions can have multiple answers. Use an array to keep track of them
  multipleQuestionAnswers = []; // multiple question questions will have multiple answers. Use an array to keep track of them

  constructor(public logStateService: LogStateService, public sanitizer: DomSanitizer) {}

  ngOnInit() {
    try{
    this.subText = this.sanitizer.bypassSecurityTrustHtml(this.question.subText);
    this.initQuestion(this.question);

    // this.logStateService.state$.subscribe(logState => {
    //   this.isModalDisplayed = logState.isModalDisplayed;
    // });
    }
    catch(e){
      console.log("error in init ci q comp");
      console.log(e);
    }
  }

  private initQuestion(question: AssessmentQuestion) {
    if (question.questionType === 'date' && question.autofill) {
      if (question?.answer !== null) {
        this.answerValue = question.answer;
      } else {
        this.answerValue = new Date().toLocaleDateString('en-US');
        this.questionAnswered.emit(this.answerValue.toISOString().split('T')[0]);
      }
    } else if (question.questionType === 'hours-minutes' && question.answer != null) {
      this.answerValue =
        Math.floor(question.answer / 60) + 'hrs ' + (question.answer % 60) + 'mins ';
    } else if (question.questionType === 'duration' && question.answer != null) {
      const hoursMins = question.answer?.split(':');
      const hours = hoursMins[0];
      const mins = hoursMins[1];
      this.answerValue = `${hours}hrs ${mins}mins`;
    }
    
    else if (question.showPreferNotToAnswer) {
      if (Array.isArray(question.answer)) {
        // If preferNotToAnswer is selected clear the answer
        const preferNotToAnswerSelected = question.answer.filter(ans => ans.isChecked);
        if(preferNotToAnswerSelected) {
          this.answerValue = null;
        }
      } else {
        // If the answer is set remove preferNotToAnswer
        question.answerOptions.forEach(answer => {
          answer.isChecked = false;
        });
        this.answerValue = question.answer;
      }
    }
    else {
      if (
        question.hasOwnProperty('answer') &&
        (question.answer !== null || question.answer === false)
      ) {
        this.answerValue = question.answer;
      } else {
        this.answerValue = null;
      }
    }
  }

  radioValueUpdated(answer) {
    this.clearOtherQuestions();
    this.answerValue = answer;
    this.questionAnswered.emit(answer);
    this.checkForConditionalQuestions(answer);
  }

  dropdownValueUpdated(answer) {
    this.clearOtherQuestions();
    this.answerValue = answer;
    this.questionAnswered.emit(answer);
    this.checkForConditionalQuestions(answer);
  }

  valueUpdated(e) {
    if (this.question.questionType !== 'checkbox' && this.question.questionType !== 'dropdown') {
      //  this.clearOtherQuestions();
    }
    // This is needed when the questionType is text and a preferNotToAnswer checkbox is includes
    // If the user starts typing in the textbox it should clear any other answer selected
    if(this.question.showPreferNotToAnswer) {
      this.question.answerOptions.forEach(ans => {
        ans.isChecked = false;
      })
    }

    if (e && e.target && e.target.value && e.target.value !== '') {
      let value = e.target.value === 'select' || e.target.value === 'v' ? null : e.target.value;
      this.questionAnswered.emit(value);
      this.updateMultipleQuestionsQuestion(value); // TODO: may have to change this later as multiple question types share this valueUpdated function
      if (e.which === 13) {
        e.target.blur();
      }
    }
  }

  valueValidated(e, maxValue) {
    var charCode = e.which ? e.which : e.keyCode;
    var isNumber = charCode < 58 && charCode > 47;
    if (isNumber) {
      const newNumber: number = parseInt(e.target.value.toString() + e.key);
      return !maxValue || newNumber <= maxValue;
    } else {
      return false;
    }
  }

  clearOtherQuestions() {
    this.checkboxValueUpdated({ value: 'N/A', isChecked: false });
  }

  controlFocused(event: FocusEvent) {
    this.inputFocused.emit(true);
  }

  controlBlurred(event: FocusEvent) {
    this.inputBlurred.emit(true);
  }

  updateMultipleQuestionsQuestion(value) {
    this.multipleQuestionsAnswered.emit(value);
  }

  onTimeQuestionAnswered(e) {
    if (this.question.questionType === 'time') {
      this.answerValue = e;
      this.questionAnswered.emit(e);
    } else {
      this.answerValue = e.replace(':', 'hrs ') + 'mins';
      this.questionAnswered.emit(e);
    }
    this.checkForConditionalQuestions(e);
    this.isModalDisplayed = false;
  }

  openTimeInput() {
    document.getElementById('timeInput').blur();
    this.logStateService.setIsModalDisplayed(true);
    this.isModalDisplayed = true;
  }

  // check answer values too for module
  checkboxValueUpdated(answer) {
    this.checkboxAnswers = this.question?.answerOptions.map(ans => {
      if (answer.clearOtherAnswers) {
        if (ans.value === answer.value) {
          ans.isChecked = true;
          return ans;
        }
        ans.isChecked = false;
        return ans;
      }
      if (ans.clearOtherAnswers) {
        ans.isChecked = false;
        return ans;
      }
      if (ans.value !== answer.value) return ans;
      ans.isChecked = answer.isChecked;
      return ans;
    });

    this.checkboxQuestionsAnswered.emit(this.checkboxAnswers);
    this.checkForConditionalCheckboxQuestions(this.checkboxAnswers);
  }

  handleSelectAll(element) {
    if (element.checked) {
      // Select all has been toggled to on. Any checkbox not in the checkboxAnswers array should be added
      this.question.answerOptions.forEach(answerOption => {
        const index = this.checkboxAnswers.map(ans => ans.value).indexOf(answerOption.value); // See if this answer is already selected
        if (index && answerOption.value !== 0) {
          this.checkboxAnswers.push({ value: answerOption.value }); // If it's not, add it
        }
      });
    } else {
      // Select all has been toggled to off. Remove all answers from checkAnswers
      this.checkboxAnswers.length = 0;
    }
  }

  // This will continually update the "other" textbox answer for checkboxes
  otherInputUpdated(answer) {}

  preferNotAnswerUpdated(answer) {
    if(answer) {
      this.answerValue = null;
    }

    this.checkboxValueUpdated(answer);
  }

  getSliderAnswer(question: AssessmentQuestion) {
    if (question.questionType === 'slider') {
      if (this.answerValue == null) {
        this.answerValue = Math.round((question.min + question.max) / 2); // This is the default of where the thumb slider will appear. i.e. the middle of the slider track
      }
      return this.answerValue;
    }
  }

  checkForConditionalQuestions(value) {
    value = this.convertToBoolean(value);
    if (this.question.conditionalQuestions && this.question.conditionalQuestions.length > 0) {
      const questionsToAdd = this.question.conditionalQuestions.filter(q =>
        _some(
          q.displayIfAnswerEquals,
          answer => answer === value || (value && answer.toString() === value.toString())
        )
      );
      if (questionsToAdd && questionsToAdd.length > 0) {
        this.questionsToAdd.emit(questionsToAdd);
      } else {
        if (value === null) {
          this.questionsToRemove.emit(this.question.conditionalQuestions);
        } else {
          const questionsToRemove = this.question.conditionalQuestions.filter(q =>
            _some(
              q.displayIfAnswerEquals,
              answer => answer !== value && answer.toString() !== value.toString()
            )
          );
          this.questionsToRemove.emit(questionsToRemove);
        }
      }
    }
  }

  private checkForConditionalCheckboxQuestions(checkboxAnswers) {
    // Populate questions to add and remove to determine display of conditional questions
    // available to a checkbox question
    if (checkboxAnswers) {
      const checkedAnswers: any[] = checkboxAnswers
        .filter(answer => answer.isChecked)
        .map(answer => answer.value);
      if (this.question.conditionalQuestions?.length > 0) {
        const questionUpdates = this.question.conditionalQuestions.reduce(
          (acc, conditionalQuestion) => {
            const answerIsChecked = conditionalQuestion.displayIfAnswerEquals.some(a =>
              checkedAnswers.includes(a)
            );
            if (answerIsChecked) {
              acc.questionsToAdd.push(conditionalQuestion);
            } else {
              acc.questionsToRemove.push(conditionalQuestion);
            }
            return acc;
          },
          { questionsToAdd: [], questionsToRemove: [] }
        );
        this.questionsToAdd.emit(questionUpdates.questionsToAdd);
        this.questionsToRemove.emit(questionUpdates.questionsToRemove);
      }
    }
  }

  private convertToBoolean(value) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }
}
