import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { buildConfidence } from '../questions/build-confidence/build-confidence';

export const buildConfidenceDef: AssetDefinition = {
  id: 18,
  name: 'build-confidence',
  label: 'Build Confidence',
  description: `Self-talk has a strong effect on performance. Help your Soldiers increase their
                confidence by identifying ineffective thoughts and replacing them with personalized
                power statements (i.e. productive statements focused on what one wants to happen).
  `,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    buildConfidence
  ],
  url: 'build-confidence'
};
