import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const prepareThroughVisualization: AssessmentDefinition = {
  name: 'prepare-through-visualization',
  description: 'Prepare Through Visualization',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Learn',
      questionEmphasis: ``,
      subText: `
      <p>Mental rehearsal of performance, by imagining or visualizing oneself engage in behaviors, has been shown to improve performance and reduce anxiety in athletes and the military.</p>

      <p>Mental imagery can be used to rehearse tactical skills and strategies, which provides additional opportunities to prepare and leads to increased confidence and performance of the mission.</p>

      <h5>Keep these guidelines in mind when using mental imagery (MI)</h5>
      <div class="call-out">MI should be positive.  Imagine performing successfully.</div>
      <div class="call-out-alt">Visualize the performance exactly as it would occur, imagine all of the steps, in order, do not speed anything up.</div>
      <div class="call-out">Select the best camera angle (internal or external) to give different perspectives on the skill. One viewpoint may feel more natural, use that. An internal view usually provides a more realistic feeling, while the external view can be good for altering your form or when you are working with other people.</div>
      <div class="call-out-alt">Include all of your senses: imagine what you see, hear, smell, feel, taste. When all senses are engaged your muscle memory is increased.</div>
      <div class="call-out">Simulate the environment: wear your uniform and gear, practice in a similar setting if possible.</div>
      <div class="call-out-alt">For cognitive tasks, go through your mental procedure to make decisions and progress through the process.</div>
      <div class="call-out">For more physical tasks, imagine your body moving through all of the steps, including how your muscles move and respond.</div>
      <div class="call-out-alt">Your physiological arousal may increase similar to how it would be during physical performance. This is OK.  You do not need to stay relaxed.
      </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 0,
      questionText: 'Practice',
      questionEmphasis: ``,
      subText: `
      <h5><b>Activity 1</b>: Audio Guided Preparation for APFT using Mental Imagery</h5>
      <audio class="w-100" controls>
        <source src="./assets/audio/pt_visualization.mp3" type="audio/mp3">
        Your browser does not support the audio element.
      </audio>
      <div class='call-out'>
        <h5 class='module'>Activity 2</h5>
        <p>Think about a complex behavior that you have to perform. Reflect on all of the steps or movements required to perform successfully.</p>

        <p>Get in a comfortable position, where you will not be interrupted for at least 10 minutes. Following the guidelines on the previous page, imagine yourself successfully performing. Make your mental imagery as vivid and realistic as possible. If you get off track, correct the image and continue. </p>

        <h5 class='module'>For best results, engage in this visualization activity as close to the time of performance as possible.</h5>

        <p>If you don’t know a specific time, practice at least once a week.</p>
      </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
