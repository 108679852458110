export const demographicsDef = {
    "name": "demographics",
    "description": "",
    "instructions": "",
    "questions": [
        /*
        //available in db for VA groups
      {
          "id": 0,
          "questionText": "What are your total years of military service?",
          "questionType": "number",
          "questionHeader": false,
          "autofill": false,
          "answerOptions": [],
          "uniqueAnswerId": "YEARS_OF_SERVICE",
          "conditionalQuestions": [],
          "validation": {
            "maxLength": 2
          }
      },
      {
        "id": 1,
        "questionText": "Do you have combat experience?",
        "questionType": "radio",
        "questionHeader": false,
        "autofill": false,
        "answerOptions": [
          { "display": "Yes", "value": true },
          { "display": "No", "value": false }
        ],
        "uniqueAnswerId": "HAVE_COMBAT_EXPERIENCE",
        "conditionalQuestions": []
      }*/
    ]
  };