import { Group, NoctemUser, SleepPrescription } from '../services/models';

export class PatientState {
  public IsLoading = false;
  patientInfo: NoctemUser = {
    ...new NoctemUser(),
    groups: []
  };
  events: any = [];
  wearableEvents: any = []; //wearable sleep logs
  hasWearable: boolean = false; 
  diaryReminderFrequency: any = null;
  weekEvents: any = [];
  time: Date = new Date();
  userId;
  sleepIds: any = [];
  disablePrev = false;
  disableNext = false;
  medicineList: any = [];
  prescribedMedicineList: any = [];
  sleepPrescriptions: Array<SleepPrescription> = []
  groups: Group[] = [];
  userGroups: any = [];
  treatmentPlan: any = {};
  selectedWeek: any = {};
  weekModified = false;
  assessmentScale: any = [0, 1, 2, 3];
  assessmentBooleanScale: any = [true, false];
  assessmentIndex: any = [0, 1, 2, 3, 4];
  totalAwakenings: any = 0;
  userDevices: any = [];
  awakeningOptions: any = [
    'BathroomWokeUps',
    'NoiseChildBedpartnerWokeUps',
    'DiscomfortWokeUps',
    'OtherWokeUps'
  ];
  impressionIllness: any = [
    { key: 'Not assessed', value: 0 },
    { key: 'Normal, no sleep problems', value: 1 },
    { key: 'Borderline sleep problems', value: 2 },
    { key: 'Mild sleep problems', value: 3 },
    { key: 'Moderate sleep problems', value: 4 },
    { key: 'Marked sleep problems', value: 5 },
    { key: 'Severe sleep problems', value: 6 },
    { key: 'Among the most extremely severe sleep problems', value: 7 },
  ];
  impressionImprovement: any = [
    { key: 'Not assessed', value: 0 },
    { key: 'Very much improved', value: 1 },
    { key: 'Much improved', value: 2 },
    { key: 'Minimally improved', value: 3 },
    { key: 'No change', value: 4 },
    { key: 'Minimally worse', value: 5 },
    { key: 'Much worse', value: 6 },
    { key: 'Very much worse', value: 7 },
  ];
  impressionEfficienyIndex: any = [
    { key: '00', value: 0 },
    { key: '01', value: 1 },
    { key: '02', value: 2 },
    { key: '03', value: 3 },
    { key: '04', value: 4 },
    { key: '05', value: 5 },
    { key: '06', value: 6 },
    { key: '07', value: 7 },
    { key: '08', value: 8 },
    { key: '09', value: 9 },
    { key: '10', value: 10 },
    { key: '11', value: 11 },
    { key: '12', value: 12 },
    { key: '13', value: 13 },
    { key: '14', value: 14 },
    { key: '15', value: 15 },
    { key: '16', value: 16 },
  ];
  assessmentInfo: any = {};
  impressionInfo: any = {};
  userName;
  userString;
  sleepDiary: any = {};
  hasFitBit;
  weekReadOnly = false;
  weekDatesChanged = false;
  diaryAverages: any = {};
  phaseNames: any = [];
  awakeningVals = [
    { id: 0, name: 'alarm clock/radio' },
    { id: 1, name: 'someone woke me' },
    { id: 2, name: 'noise' },
    { id: 3, name: 'I just woke up' },
  ];
  wakeUpVals = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5 or more' },
  ];
  dreamVals = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5 or more dreams' },
  ];
  nMareVals = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5 or more nightmares' },
  ];
  intensities = [
    { value: 'Light', name: 'Light' },
    { value: 'Moderate', name: 'Moderate' },
    { value: 'High', name: 'High' }
  ];
  durations = [
    { value: '<30', name: 'Less than 30 minutes' },
    { value: '>30', name: 'Greater than 30 minutes' },
  ];
  morningFeels = [
    {
      "name": "Very tired as if I did not sleep",
      "value": "1"
    },
    {
      "name": "Tired and not ready for the day",
      "value": "2"
    },
    {
      "name": "Alright. I will likely feel more energized once I begin my day",
      "value": "3"
    },
    {
      "name": "Good and well-rested",
      "value": "4"
    },
    {
      "name": "Very good and very well-rested",
      "value": "5"
    }
  ];
  checkedLogDays = 0;
}
