import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';


enum STATES {
    IDLE = 'IDLE',
    CONNECTING = 'CONNECTING',
    CONNECTED = 'CONNECTED',
    RECONNECTING = 'RECONNECTING'
};


export class NetworkService {

    private isInitialized: boolean          = false;
    private hasAttemptedConnect: boolean    = false;
    private timeoutRef: any                 = 0;

    private connectionState: STATES         = STATES.IDLE;

    public onConnectionChange$: Subject<{ connected: boolean }> = new Subject<any>();

    constructor(
        private socket: Socket
    ) {}

    initialize() {
        return new Promise((resolve, reject) => {
            if (!this.isInitialized) {
                this.socket.on('connect', () => {
                    this.hasAttemptedConnect = true;
                    this.connectionState = STATES.CONNECTED;
                    this.onConnectionChange$.next({ connected: true });
                    this.isInitialized = true;
                    resolve(true);
                });

                this.socket.on('connect_error', () => {
                    this.hasAttemptedConnect = true;
                    if (this.connectionState === STATES.CONNECTING) {
                        this.onConnectionChange$.next({ connected: false });
                    }
                    this.connectionState = STATES.RECONNECTING;
                    this.onConnectionChange$.next({ connected: false });
                    resolve(false);
                });

                this.socket.on('disconnect', () => {
                    this.connectionState = STATES.RECONNECTING;
                    this.hasAttemptedConnect = true;
                    this.onConnectionChange$.next({ connected: true });
                    resolve(false);
                });

                this.connectionState = STATES.CONNECTING;
                this.socket.connect();

            } else {
                resolve(true);
            }
        })
    }

    getSocket() {
        return this.socket;
    }

    hasInitialized() {
        return this.hasAttemptedConnect;
    }

    isOnline() {
        return this.connectionState === STATES.CONNECTED;
    }

    disconnectSocketService(){
        this.socket.disconnect();
    }

    connectSocketService(){
        this.socket.connect();
    }
}
