import { BaseObject, IApplicationContext,
         ValidationResponse }               from './contracts/models';
import { BaseRepository }                   from './core';
import { CacheService }                     from '../lib/services/cache-service';
import { NetworkService }                   from '../lib/services/network-service';
import { tap }                              from 'rxjs/operators';
import { Observable } from 'rxjs';

export class BaseRepositoryCacheDecorator<T extends BaseObject> extends BaseRepository<T> {
    constructor(private cacheService: CacheService, private networkService: NetworkService,
                applicationContext: IApplicationContext, private className: string) {
        super(applicationContext, className);
    }

    public async getAllAsync(query?: any, size?: number, sorts?: Array<{property: string, direction: string}>): Promise<Array<T>> {
        let keyName = `getAllAsync:${this.className}`;
        if (!this.cacheService.shouldCache()) {
            return super.getAllAsync(query, size, sorts);
        } else if (!this.networkService.hasInitialized()) {
            return new Promise((resolve, reject) => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (connection.connected) {
                        super.getAllAsync(query, size, sorts).then(data => {
                            this.cacheService.set(keyName, data)
                            resolve(data);
                        })
                    } else {
                        return resolve(this.cacheService.get(keyName));
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return super.getAllAsync(query, size, sorts).then(data => {
                    this.cacheService.set(keyName, data)
                    return data;
                });
            } else {
                return new Promise((resolve, reject) => {
                    resolve(this.cacheService.get(keyName))
                })
            }
        }

    }

    public getAll(query?: any, size?: number, sorts?: Array<{property: string, direction: string}>): Observable<Array<T>> {
        let keyName = `getAll:${this.className}`
        if (!this.cacheService.shouldCache()) {
            return super.getAll(query, size, sorts);
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(() => {
                    if (this.networkService.isOnline()) {
                        super.getAll(query, size, sorts).subscribe(data => {
                            this.cacheService.set(keyName, data)
                            o.next(data);
                            o.complete();
                        });
                    } else {
                        o.next(this.cacheService.get(keyName));
                        o.complete();
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return super.getAll(query, size, sorts).pipe(tap(data => {
                    this.cacheService.set(keyName, data)
                    return data;
                }))
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get(keyName));
                    o.complete();
                })
            }
        }
    }

    public sequence(): Observable<any> {
        let keyName = `sequence:${this.className}`
        if (!this.cacheService.shouldCache()) {
            return super.sequence();
        } else if (!this.networkService.hasInitialized()) {
            return new Observable(o => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (this.networkService.isOnline()) {
                        super.sequence().pipe(tap(data => {
                            this.cacheService.set(keyName, data)
                            o.next(data);
                            o.complete();
                            return data;
                        }));
                    } else {
                        o.next(this.cacheService.get(keyName));
                        o.complete();
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return super.sequence().pipe(tap(data => {
                    this.cacheService.set(keyName, data)
                    return data;
                }));
            } else {
                return new Observable(o => {
                    o.next(this.cacheService.get(keyName));
                    o.complete();
                })
            }
        }
    }

    public async getOneAsync(query: Object, size: number = 50): Promise<T> {
        let keyName = `getOneAsync:${this.className}`
        if (!this.cacheService.shouldCache()) {
            return super.getOneAsync(query, size)
        } else if (!this.networkService.hasInitialized()) {
            return new Promise((resolve, reject) => {
                this.networkService.onConnectionChange$.subscribe(connection => {
                    if (this.networkService.isOnline()) {
                        super.getOneAsync(query, size).then(data => {
                            this.cacheService.set(keyName, data)
                            resolve(data);
                        })
                    } else {
                        resolve(this.cacheService.get(keyName));
                    }
                })
            })
        } else {
            if (this.networkService.isOnline()) {
                return super.getOneAsync(query, size).then(data => {
                    this.cacheService.set(keyName, data)
                    return data;
                })
            } else {
                return new Promise((resolve, reject) => {
                    resolve(this.cacheService.get(keyName))
                })
            }
        }
    }

    public validate(tObject: T): Observable<ValidationResponse> {
        if (this.networkService.isOnline()) {
            return super.validate(tObject);
        } else {
            return new Observable(o => {
                o.next(new ValidationResponse());
                o.complete();
            });
        }
    }
}
