export const pgiDef = {
  "name": "pgi",
  "description": "Patient Global Impression",
  "instructions": null,
  "questions": [
    {
      "id": 0,
      "questionText":
        "Please rate the level of change you have observed in your sleep since you started this program:",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Very much improved", "value": 0 },
        { "display": "Much improved", "value": 1 },
        { "display": "Minimally improved", "value": 2 },
        { "display": "No change", "value": 3 },
        { "display": "Minimally worse", "value": 4 },
        { "display": "Much worse", "value": 5 },
        { "display": "Very much worse", "value": 6 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PGI_RATING"
    },
    /** this section is available from db
    {
      "id": 1,
      "questionText":
        "Please rate the level of change you have observed in your mood since you started this sleep program:",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Noticeably more calm and cheerful", "value": 0 },
        { "display": "Moderately more calm and cheerful", "value": 1 },
        { "display": "Slightly more calm and cheerful", "value": 2 },
        { "display": "No change", "value": 3 },
        { "display": "Slightly more down or irritable", "value": 4 },
        { "display": "Moderately more down or irritable", "value": 5 },
        { "display": "Noticeably more down or irritable", "value": 6 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PGI_MOOD_RATING"
    },
    {
      "id": 2,
      "questionText":
        "Please rate the level of change you have observed in your daily functioning (i.e., ability to meet family, social, or occupational goals) since you started this sleep program:",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Very much improved", "value": 0 },
        { "display": "Much improved", "value": 1 },
        { "display": "Minimally improved", "value": 2 },
        { "display": "No change", "value": 3 },
        { "display": "Minimally worse", "value": 4 },
        { "display": "Much worse", "value": 5 },
        { "display": "Very much worse", "value": 6 }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "PGI_DF_RATING"
    }
    */
  ]
};
