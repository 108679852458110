import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const relaxationAlgorithm = new SleepModuleDefinition({
  name: 'relaxation',
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-relive',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-avoid',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-irritable',
      valueToCompare: 2, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),


    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-nervous',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-worry',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-worry-too-much',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-relaxing',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-restless',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'gad7-irritable',
      valueToCompare: 0, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    })
  ],
});

