export const medicationUseDef = {
  name: 'medicationUse',
  description: 'Medication Use',
  instructions: '',
  questions: [
    {
      id: 0,
      questionText: 'In the past week, did you add a medication or OTC product to help you sleep?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          id: 1,
          questionText: 'What medication(s)?',
          displayIfAnswerEquals: [true],
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          conditionalQuestions: [],
          uniqueAnswerId: 'MEDUSE_ADD_WHAT',
          showNotApplicableHint: false
        }
      ],
      uniqueAnswerId: 'MEDUSE_ADD'
    },
    {
      id: 2,
      questionText:
        'In the past week, did you change a medication or OTC product to help you sleep?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          id: 3,
          questionText: 'What medication(s)?',
          displayIfAnswerEquals: [true],
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          conditionalQuestions: [],
          uniqueAnswerId: 'MEDUSE_CHANGE_WHAT',
          showNotApplicableHint: false
        }
      ],
      uniqueAnswerId: 'MEDUSE_CHANGE'
    }
  ]
};
