import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { some as _some } from 'lodash';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AssetDefinition } from '../../../../core/contracts/models.calculations';

@Component({
  selector: 'carousel-container',
  templateUrl: './carousel-container.component.html',
  styleUrls: ['../carousel-page.component.scss']
})

// This class is for the overall carousel of modules to display. Using the SwiperModule library
export class CarouselContainerComponent implements OnInit, AfterViewInit {
  @Input() public modulesPerPage = 3;
  @Input() public modules: Array<AssetDefinition>;
  @Input() public showProgress = false;
  @Input() public mods = [];

  public slides: Array<Array<AssetDefinition>> = [];

  public carouselConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    pagination: true,
    keyboard: true,
  };

  constructor() {}
  
  ngAfterViewInit(): void {

  }

  ngOnInit() {
    let modIndex = 0;
    let currentSlide: Array<AssetDefinition> = null;
    this.modules.forEach((module, index) => {
      if (index === 0 || modIndex === this.modulesPerPage) {
        currentSlide = new Array<AssetDefinition>();
        this.slides.push(currentSlide);
        modIndex = 0;
      }
      currentSlide.push(module);
      modIndex++;
    });
  }


}
