import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
import { CalendarStateService } from '../../../state/calendar-state.service';
import { DatePipe } from '@angular/common';
import { CalendarEvent } from '../../../services/models';
import { addOffsetToDate } from '../../../shared/helpers';

@Component({
    selector: 'add-edit-event-dialog',
    templateUrl: './add-edit-event-dialog.component.html',
})
export class AddEditEventDialog implements OnInit {
    pipe = new DatePipe('en-US');
    eventDate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    eventTime = this.pipe.transform(new Date(), 'HH:00');
    eventRepeat = false;
    eventRepeatTimes = 1;
    eventRepeatType;
    eventPhaseName;
    eventColor;
    eventReadOnly = false;
    editableEvents = [1, 2, 9, 999]; // non weekevents are editable
    constructor(
        public dialogRef: MatDialogRef<AddEditEventDialog>,
        public stateService: CalendarStateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        const calEvent = this.data.calEvent;
        if (this.data.isEdit) {
            this.eventDate = this.pipe.transform(new Date(calEvent.start), 'yyyy-MM-dd');
            this.eventTime = this.pipe.transform(new Date(calEvent.start), 'HH:mm');
            this.eventPhaseName = calEvent.phaseName;
            this.eventColor = calEvent.backgroundColor;
            const isEditableEvent = _find(this.editableEvents, thisEventType => this.eventPhaseName && this.eventPhaseName.toString() === thisEventType.toString());
            if (!isEditableEvent) {
                this.eventReadOnly = true;
            } else {
                this.eventReadOnly = false;
            }
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onAddNew() {
        const state = this.stateService.stateModel.get();
        const thisAction = _find(state.userActions, thisAction => thisAction.ActionType.toString() === this.eventPhaseName);
        const startTime = new Date();
        startTime.setHours(parseInt(this.eventTime.split(':')[0]), parseInt(this.eventTime.split(':')[1]));
        const eventInstance: CalendarEvent = {
            ...new CalendarEvent(),
            phaseName: this.eventPhaseName,
            startTime: this.pipe.transform(new Date(startTime), 'hh:mm a'),
            endTime: '',
            isDownloaded: false,
            user: {
                id: state.selectedPatient.id,
                model: 'UserSettings',
                display: state.selectedPatient.display
            },
            repeatTimes: this.eventRepeat ? this.eventRepeatTimes : 1,
            repeatType: this.eventRepeat ? this.eventRepeatType : 1,
            endDate: null,
            startDate: addOffsetToDate(new Date(this.eventDate)).toISOString(),
            title: `[${state.selectedPatient.display}] ${thisAction.Description}`,
            color: this.eventColor
        };
        this.stateService.saveEvent(eventInstance);
        this.onNoClick();
    }

    onSave() {
        const calEvent = this.data.calEvent;
        const startTime = new Date();
        startTime.setHours(parseInt(this.eventTime.split(':')[0]), parseInt(this.eventTime.split(':')[1]));
        const eventInstance = calEvent.event;
        eventInstance.startDate = addOffsetToDate(new Date(this.eventDate));
        eventInstance.startTime = this.pipe.transform(new Date(startTime), 'hh:mm a');
        eventInstance.color = this.eventColor;
        this.stateService.saveEvent(eventInstance);
        this.onNoClick();
    }

    onDelete() {
        const doDelete = confirm('Are you sure you want to delete this item?');
        if (!doDelete) {
            return;
        }
        const calEvent = this.data.calEvent;
        const eventInstance = calEvent.event;
        eventInstance.phaseName = 0;
        this.stateService.saveEvent(eventInstance);
        this.onNoClick();
    }
}
