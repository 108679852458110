import { Component, OnInit, Inject, ViewEncapsulation, Pipe, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportService } from '../../../../../../../../../noctem-lib/src/lib/services/report-service';
import * as html2pdf from 'html2pdf.js';
import { ReportViewer, UserStateService, NoctemUser } from '@noctem/web';
import { TimeTrackingService } from '../../../../../../../../../noctem-lib/src/lib/services/time-tracking.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
  providers: [TimeTrackingService]
})

export class ReportDialogComponent implements OnInit, OnDestroy {
  public reportDef: ReportViewer;
  public dateString: string;
  public pageOptions = new Array<any>();
  public isLoading:boolean = true;
  private currentUser: NoctemUser;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private timeTrackingService: TimeTrackingService,
    private userStateService: UserStateService,
    ) { }

  destroy$: Subject<any> = new Subject();
  now = new Date();
  
  ngOnInit(): void {
    this.userStateService.initialize();
    this.userStateService.state$
      .pipe(
        filter(userState => !!userState.User),
        takeUntil(this.destroy$)
      )
      .subscribe(userState => {
        this.currentUser = (userState.User as any) as NoctemUser;
      });

    this.reportService.getReportById(0).then(reportDef => {
      this.isLoading = false;
      this.reportDef = reportDef;
      this.dateString = this.now.toDateString();
      this.reportDef.pages.forEach(p => {
        // Only offer toggleable report components where required is false
        if(p.required === false) {
          this.pageOptions.push({ name: p.name, selected:true });
        }
      });
    });
  }

  ngAfterViewInit(){
    this.timeTrackingService.trackTimeOnElement("mat-dialog-container", { activityType: 'Patient_Page', subEvent: 'Intervention_Report_Dialog', patientId: this.data.recipientId });
  }

  /**
   * Cancels dialog box
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.reportService.clear();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Converts html to PDF for download
   */
  public print() {
    const printClickTime = new Date();
    
    const origDocumentDropDown:any = document.getElementsByClassName('therapy-type')[0];

    const root = (document.getElementById('document').cloneNode(true) as HTMLElement);
    const textAreaElements:any = root.getElementsByTagName('textarea');
    [...textAreaElements].forEach(element => {
      let value = element.value;
      this.replaceDOMElement(element, value, 'p');
    });

    const dropdownElements:any = root.getElementsByClassName('therapy-type');
    [...dropdownElements].forEach(element => {
      let value = origDocumentDropDown.options[origDocumentDropDown.selectedIndex].text;
      this.replaceDOMElement(element, value, 'label');
    });

    const textElements:any = root.getElementsByClassName('other-text');
    [...textElements].forEach(element => {
      let value = element.value;
      this.replaceDOMElement(element, value, 'label');
    });

    const options = {
      // [top, left, bottom, right]
      margin: [20, 0, 40, 0],
      // Add timestamp to filename to help prevent against accidental overwrites
      filename: `COAST Patient Intervention Report - ${Math.floor(printClickTime.getTime()/1000)}.pdf`,
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: { scale: 2 },
      // Current setting as a reference. Given the HTML is manly classed divs, using section elements as page break markers
      // pagebreak: { mode: 'avoid-all'},
      pagebreak: { mode: ['avoid-all'], avoid: ['section'] },
      jsPDF: { unit: 'px', hotfixes: ['px_scaling'], format: 'letter', orientation: 'portrait' }
    };

    html2pdf().set(options).from(root).toPdf().get('pdf').then((pdf) => {
      let totalPages = pdf.internal.getNumberOfPages();
      for (let i=1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFont('Courier');

        // console.debug(pdf.getFontList()); // Other font options

        pdf.setFontSize(10);
        pdf.text(`Page ${i}/${totalPages}`, pdf.internal.pageSize.getWidth() - 110, pdf.internal.pageSize.getHeight() - 28);

        // Add an eSignature block to the footer; show on all pages with an option for only the last
        if (true || i === totalPages) {
          const userId = this.currentUser.UserId;
          const userIdShort = userId.split("-").pop();
          const firstName = this.currentUser.profile.name?.First || this.currentUser.profile.firstName;
          const lastName = this.currentUser.profile.name?.Last || this.currentUser.profile.lastName;
          const userName = `${firstName} ${lastName}`;

          pdf.setFont('Courier');
          pdf.setFontSize(9);
          pdf.text(`/s/ ${userName} (${userIdShort})\n${this.now.toISOString()}`, 40, pdf.internal.pageSize.getHeight() - 42);
        }
      }
    }).save();
  }

  private replaceDOMElement(element, value, elementType) {
    const valueSpan = document.createElement(elementType);
    valueSpan.innerText = value;
    let parent = element.parentNode;
    parent.replaceChild(valueSpan, element);
  }
}
