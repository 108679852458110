import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const gaugeSleepHealth: AssessmentDefinition = {
  name: 'gauge-sleep-health',
  description: 'Gauge Sleep Health',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'There is more to good sleep than getting 7-9 hours of sleep per 24-hour period',
      questionEmphasis: ``,
      subText: `<p>Good sleep can be defined as sleep that is:</p>
                <p class="call-out">
                <b>Regular and Predictable</b><br />
                    You generally know when you will feel sleepy and sleep starts and ends at about the same time each day.
                </p>
                <p class="call-out">
                <b>Consolidated</b><br />
                  Sleep is not broken or fragmented. It takes less than 30 minutes to fall asleep and you are awake for less than 30 minutes during the night.
                </p>
                <p class="call-out">
                <b>Restorative</b><br />
                    You feel physically and mentally refreshed upon awakening.
                </p>
                <p class="call-out">
                <b>Sufficient Duration</b><br />
                    The time spent sleeping matches your individual sleep need. For most people, sleep need is between 7 – 9 hours per 24-hour period.
                </p>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'There are several behaviors that support sleep.',
      questionEmphasis: ``,
      subText: `<p>Try these activities to support good sleep.</p>
                <p class='dark-call-out'>Use your bed/bedroom for <b>sleeping only</b></p>
                <p class="call-out">Create a comfortable sleep environment that is <b>cool, dark, and quiet</b>.</p>
                <p class='dark-call-out call-out-card top-card'>Create a <b>daily routine</b> that includes doing activities at around the <b>same time each day</b>.</p>
                <div class="call-out call-out-card bottom-card">
                  <p><b>For Example...</b></p>
                  <p>Get out of bed at around the same time each day.</p>
                  <p>Eat meals at around the same time each day.</p>
                  <p>Exercise at around the time each day.</p>
                  <p>Prepare for bed/engaging in your wind-down routine at around the same time each day.</p>
                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: 'There are several behaviors that interfere with sleep',
      questionEmphasis: ``,
      subText: `<p>Avoid these activities to support good sleep.</p>
                <p class='dark-call-out'>Doing non-sleep related activities in bed such as using smartphones, playing video games, watching TV, or thinking.</p>
                <p class="call-out">Having a poor sleep environment or one that is not cool, dark, and quiet and does not feel safe to you.</p>
                <p class='dark-call-out'>Using alcohol prior to sleep.</p>
                <p class="call-out">Using caffeine and other stimulants prior to sleep.</p>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: 'Occasional poor sleep is normal',
      questionEmphasis: ``,
      subText: `<p>However, if you experience ongoing difficulties with falling asleep or staying asleep the following may be helpful:</p>
                <div class="call-out">
                <h5 class='module'>Try These!</h5>
                  <p>Create a relaxing pre-sleep routine to do around the same time each day.</p>
                  <p>Practice relaxation exercises such as meditation or mindfulness.</p>
                  <p>Don’t get into bed unless sleepy.</p>
                  <p>If you wake up during the night and cannot return to sleep, get out of bed. Return to bed only when sleepy again. </p>
                </div>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 4,
      questionText: '',
      questionEmphasis: ``,
      subText: `
                <p>If difficulties with falling asleep or staying asleep persist, support from a healthcare provider may be needed. </p>
                <p>Let a healthcare provider know if you have persistent difficulties falling or staying asleep, excessive daytime sleepiness, or have been told you snore loudly or gasp for air during sleep.</p>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
