import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const aceDaytimeSleep: AssessmentDefinition = {
  name: 'ace-daytime-sleep',
  description: 'Ace Daytime Sleep',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Humans are diurnal</h5>
                <p>We evolved to be awake and alert during the day, and asleep at night.</p>
                <h5>Your body and brain know:</h5>
                <p>It is harder to sleep during the daytime than during the nighttime.</p>
                <p>This often means difficulties falling and staying asleep when we try to sleep during the day.</p>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false,
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Try it out! Follow these tips to ace daytime sleeping.</p>
                <div class='call-out'>
                  <h5 class='module'>Break up your daytime sleeping</h5>
                  Rather than aiming for a single 7 to 9 hour period of sleep during the day,
                  supplement your main daytime sleep period with short naps.
                </div>
                <p>
                  <b>Lets say that your main sleep period is from 0700–⁠1100</b>. A 2–hour nap later that day (e.g., at 1500), plus a 30–minute nap 1 hour before your shift will get you closer to 7 hours of total sleep time.
                </p>
                <div class='call-out'>
                  <h5 class='module'>Sleep in an area that promotes good sleep</h5>
                </div>
                <div>
                  <img class='module-image-margin module-image-background' src='./assets/img/module-images/ace-daytime-sleep/temp.png'/>
                  <img class='module-image-margin module-image-background' src='./assets/img/module-images/ace-daytime-sleep/quiet.png'/>
                  <img class='module-image-background' src='./assets/img/module-images/ace-daytime-sleep/dark.png'/>
                </div>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Do things to signal to your brain and body that a sleep opportunity is nearing:</p>
                <div class='call-out'>
                Limit light exposure 1-hour before bed. <br><br>
                Have your last dose of caffeine no later than 6 hours before your next planned sleep opportunity. <br><br>
                Avoid nicotine or heavy exercise directly before sleep.
                </div>
                `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
