import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { teamCommunication } from '../questions/team-communication/team-communication';

export const teamCommunicationDef: AssetDefinition = {
  id: 27,
  name: 'team-communication',
  label: 'Team Communication',
  description: `
  Stress can negatively affect group functioning, but effective communication within the team is crucial for mission success. Help your Soldiers learn strategies to communicate tactical information clearly, as well as steps to resolve conflicts.
  `,
  categories: ['all', 'team-communication'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    teamCommunication
  ],
  url: 'team-communication'
};
