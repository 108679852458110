<div class="wrapper">
<div class="row">
    <div class="col-6 nocBG">

    </div>
    <div class="col-6 centeringParent">
        <div class="col-6 centeringChild">
            <div class="">
                <h1 class="text-center welcome">Welcome Back!</h1>
                <p class="text-center loginText">Please log in to COAST™ to access NOCTEM's remote therapeutic monitoring and clinical decision support platform to help manage your patients with insomnia and other sleep disorders.</p>
                <form [formGroup]="loginForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="">
                    <div class="form-group">
                        <div class="">
                            <input class="login-input form-control" id="exampleInputEmail1" type="email" name="email" placeholder="Email or username" (keydown)="isValidLogin = true; " autocomplete="off" formControlName="email" required="" />
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty && loginForm.controls['email'].touched)">This field must be a valid email address</div>
                    </div>
                    <div class="form-group">
                        <div class="">
                            <input class="login-input mb-1 form-control" id="exampleInputPassword1" (keydown)="isValidLogin = true;" type="password" name="password" placeholder="Password" formControlName="password" required="" />
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty && loginForm.controls['password'].touched)">A password is required</div>
                    </div>
                    <div class="row" *ngIf="!isValidLogin">
                        <div class="text-danger mb-3 ml-3">Invalid login. Username or password is incorrect.</div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="w-100">

                                <div class="text-center">
                                    <patient-dialog modalType="terms"></patient-dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mBreathe3 login-btn">
                        <div class="col-12">
                            <button class="btn btn-block btn-primary" type="submit" (click)="submitForm($event, loginForm.value)">Login</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="p-3 text-center">
                <span>&copy;</span>
                <span>{{ settings.getAppSetting('year') }}</span>
                
                <span>&nbsp;{{ settings.getAppSetting('name') }}</span>
                <br/>
                <span>{{ settings.getAppSetting('description') }}</span>
            </div>
            <img width="100%" class="footer-img" src="../../../../assets/img/logos/Carpe_Noctem_BlackTeal.svg" alt="">
            <span class="trademark">NOCTEM®</span>
        </div>
    </div>
</div>
</div>
