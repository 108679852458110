export const osaDetailsTrueDef = {
  name: 'osa-details-true',
  description: null,
  instructions: '',
  questions: [
    {
      id: 1,
      questionText: 'Which of the following treatment(s) do you or have you used for sleep apnea?',
      uniqueAnswerId: 'OSA_TREATMENTS',
      questionType: 'checkbox',
      autofill: false,
      answerOptions: [
        {
          display: 'PAP Machine',
          value: 0,
          isChecked: false,
          clearOtherAnswers: false
        },
        {
          display: 'Dental Appliance',
          value: 1,
          isChecked: false,
          clearOtherAnswers: false
        },
        {
          display: 'Nasal Strips',
          value: 2,
          isChecked: false,
          clearOtherAnswers: false
        },
        {
          display:
            'I had surgery to open my airways (e.g., correction of a deviated septum, tonsils removal, UPPP surgery)',
          value: 3,
          isChecked: false,
          clearOtherAnswers: false
        },
        {
          display: 'Other',
          value: 4,
          isChecked: false,
          clearOtherAnswers: false
        },
        {
          display: 'I have not or do not use any treatment.',
          value: 5,
          isChecked: false,
          clearOtherAnswers: true
        }
      ],
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [4],
          id: 4,
          questionText: 'Other',
          questionEmphasis: 'Other Treatment',
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'OSA_OTHER_TREATMENT',
          conditionalQuestions: []
        },
        {
          id: 22,
          displayIfAnswerEquals: [0],
          questionText: 'Do you use a CPAP nightly, at least 4 hours per night?',
          questionEmphasis: 'CPAP Treatment',
          questionType: 'radio',
          autofill: false,
          answerOptions: [
            {
              display: 'Yes',
              value: true
            },
            {
              display: 'No',
              value: false
            }
          ],
          uniqueAnswerId: 'OSA_CPAP',
          conditionalQuestions: []
        },
        {
          id: 3,
          displayIfAnswerEquals: [1],
          questionText: 'Do you wear it nightly, at least 4 hours per night?',
          questionEmphasis: 'Dental Appliance Treatment',
          questionType: 'radio',
          autofill: false,
          answerOptions: [
            {
              display: 'Yes',
              value: true
            },
            {
              display: 'No',
              value: false
            }
          ],
          uniqueAnswerId: 'OSA_DENTAL_APPLIANCE',
          conditionalQuestions: []
        }
      ]
    }
  ]
};
