import { Injectable, Inject } from '@angular/core';
import { flatMap as _flatMap, filter as _filter, sortBy as _sortBy, some as _some } from 'lodash';
import { BaseStateService } from './base-state.service';

import { find as _find } from 'lodash';
import { LogService, AssessmentService, AssessmentDefinitionService } from '../services/services';
import { logDefinitions, logTypes, uniqueAnswerIds, moduleIds } from '../../constants/constants';

import { UserStateService } from './user-state';
import { Observable, Subject } from 'rxjs';
import {
  Assessment,
  AssessmentQuestion,
  AssessmentAnswer,
  AssessmentGroup,
  AssessmentTemplate,
  AssessmentStatus,
  SleepPrescription,
  SleepPrescriptionFlag,
  UserSimpleInstance,
  SimpleInstance,
  CalendarEvent
} from '../services/models';
import { filter, map, take } from 'rxjs/operators';
import { ModelFactory, USER_STATE_SERVICE, Meta, SystemModel, ApplicationContext, UserSettings } from '../../core';
import { TranslationService } from '../translations';
import moment, { ISO_8601, Moment } from 'moment';
import { CALC_CONFIG } from './user';
import { BaseCalculationDefinition } from '../../core/contracts/models.calculations';
import { TreatmentPlanStateService,TreatmentPhase } from './treatment-plan-state.service';
import { NetworkService } from '../services/network-service';

export class Log {
  logType: string;
  questions: Array<AssessmentQuestion>;
  logStatus: string;
  logDate?: string;
}

export class DayLog {
  morningLog: Log;
  eveningLog: Log;
  day: string;
  isCurrent: boolean;
  logDate: string;
  patient: UserSimpleInstance;
}

export class LogType {
  // static day: LogType;
  // static night: LogType;
  name: string;
  label: string;
}

export class LogState {
  isLoading = false;
  isInitialized: boolean;
  isModalDisplayed: boolean;
  currentWeekLogs: DayLog[];
  currentLog: Log;
  logStartTime: Date;
  incompleteLogCount: number;
  treatmentPlanStatus: string;
  currentSleepPrescription: SleepPrescription;
  previousSleepPrescription: SleepPrescription;
  inProgressLogs: Array<Assessment>;
}

export const dayOfWeekAbbreviations = {
  SUNDAY: 'Su',
  MONDAY: 'M',
  TUESDAY: 'T',
  WEDNESDAY: 'W',
  THURSDAY: 'Th',
  FRIDAY: 'F',
  SATURDAY: 'Sa'
};

@Injectable()
export class LogStateService extends BaseStateService<LogState> {
  public state$: Observable<LogState>;
  public onLogStateLoaded$: Subject<string> = new Subject<string>();
  public onLogCompleted$: Subject<LogState> = new Subject<LogState>();
  private currentPhase: string;
  private previousPhase: TreatmentPhase;
  private readonly EVENING_LOG_HOUR = 20;
  private calendarEvents: CalendarEvent[];
  private sleepRx: SleepPrescription[];

  constructor(
    translationService: TranslationService,
    stateFactory: ModelFactory<LogState>,
    private logService: LogService,
    private assessmentService: AssessmentService,
    private treatmentStateService: TreatmentPlanStateService,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    @Inject(CALC_CONFIG) private calculationsDefinitions: BaseCalculationDefinition,
    private applicationContext: ApplicationContext,
    private networkService: NetworkService,
    private assessmentDefinitionService: AssessmentDefinitionService
  ) {
    super(new LogState(), stateFactory, translationService);

    this.initialize();
  }

  public async initialize() {
    const state = this.getState();
    if (state.isInitialized) {
      return;
    }
    state.isLoading = false;
    state.treatmentPlanStatus = null;
    state.isModalDisplayed = false;
    state.isInitialized = true;
    this.getThisWeeksSleepLogs()
      .pipe(take(1))
      .subscribe(response => {
        response.then(sleepLogs => {
          state.currentWeekLogs = sleepLogs;
          this.setState(state);
          this.setCurrentLog();
          if (this.hasActiveLog()) {
            this.onLogStateLoaded$.next(state.currentLog.logType);
          }
        });
      });

    this.treatmentStateService.state$.subscribe(sub => {
      this.currentPhase = sub.currentPhase?.name;
      this.calendarEvents = sub.calendarEvents;
      this.sleepRx = sub.sleepPrescriptions;

      if (this.currentPhase !== 'followup1') {
        this.previousPhase = sub.phases?.find(phase => phase.order === sub.currentPhase?.order - 1);
      } else {
        // work around for skipped order number in sub.phases list from 'Post' to 'Follow Up'
        this.previousPhase = sub.phases?.find(phase => phase.order === sub.currentPhase?.order - 2);
      }
    });
  }

  private getThisWeeksSleepLogs(): any {
    const logs = [];
    const state = this.getState();
    return this.userStateService.state$.pipe(
      filter(userState => !!userState.User),
      map(async userState => {
        const weekStart = this.getWeekStart().format('YYYY-MM-DD') + 'T00:00:00.000Z' // added time so the query is more specific
        const treatmentStart = userState.User.treatmentStartDate;
        const sleepLogs = await this.assessmentService.getAllAsync({
          $and: [
            {
              'Payload.groups.type': {
                $in: [AssessmentTemplate.morningLog.name, AssessmentTemplate.eveningLog.name]
              }
            },
            { 'Payload.user.id': userState.User?.UserId },
            { 'Payload.assessmentDate': { $gte: weekStart } }
          ]
        });
        state.inProgressLogs = sleepLogs?.filter(log => log.isIncomplete);
        this.setState(state);
        for (let i = 1; i <= 7; i++) {
          const dayLog = this.setupDayLog(sleepLogs, i, treatmentStart);
          logs.push(dayLog);
        }
        return logs;
      })
    );
  }

  private async getSleepLogsFromDate(start: Date, numDays: number) {
    const endDate = moment(start)
      .add(moment().utcOffset(), 'm')
      .add(numDays-1, 'days') // start was created by substracting 6 instead of 7
      .endOf('day');
    const treatmentStart = this.userStateService.model.get().User.treatmentStartDate;
    const userId = this.userStateService.model.get().User?.UserId;
    const startDate = moment(start).add(moment().utcOffset(), 'm').toISOString();
    const sleepLogs = await this.assessmentService.getAllAsync({
      $and: [
        {
          'Payload.groups.type': {
            $in: [AssessmentTemplate.morningLog.name, AssessmentTemplate.eveningLog.name]
          }
        },
        { 'Payload.user.id': userId },
        { 'Payload.assessmentDate': { $gte: startDate } },
        { 'Payload.assessmentDate': { $lte: endDate.toISOString() } }
      ]
    });

    // Create numDays days worth of logs, starting from "start" date
    return [...Array(numDays).keys()].map(i => {
      return this.setupDayLog(sleepLogs, i, treatmentStart, start);
    });
  }

  public async getUserCheckIns() {
    const checkIns = [];
    const state = this.getState();

    const userId = this.userStateService.model.get().User?.UserId;
    const sleepLogs = this.assessmentService.getAllAsync({
      $and: [{ 'Payload.groups.type': 'check-in' }, { 'Payload.user.id': userId }]
    });
    return sleepLogs;
  }

  /**
   * Checks for any logs that are incomplete, but not yet expired
   * @returns boolean
   */
  public areAllLogsComplete(): boolean {
    const state = this.getState();
    if (!state.currentWeekLogs) {
      return false;
    }

    // logs can be active for up to 3 days before, so only need to check those.
    // All before that can be considered expired
    const logsInRange = state.currentWeekLogs.filter(c =>
      moment.utc(c.logDate).isAfter(moment.utc(state.currentLog.logDate).subtract(3, 'days'))
    );
    let allComplete = true;
    logsInRange.forEach(day => {
      if (this.isLogActive(day.morningLog) || this.isLogActive(day.eveningLog)) {
        allComplete = false;
      }
    });

    return allComplete;
  }

  /**
   * Determines if log has an active status
   * @param log log to be checked
   * @returns boolean
   */
  private isLogActive(log: Log): boolean {
    return (
      log.logStatus === AssessmentStatus.ACTIVE.name ||
      log.logStatus === AssessmentStatus.EXPIRING.name
    );
  }

  private setupDayLog(
    sleepLogs: Array<Assessment>,
    i: number,
    treatmentStartDate: string,
    weekStart?: Date
  ) {
      let weekStartMoment: moment.Moment = null;
      if (!weekStart) {
        weekStartMoment = this.getWeekStart().startOf('day');
      } else {
        weekStartMoment = moment(weekStart);
      }

      const logDate = this.addDays(weekStartMoment, i);
      const log = {
        day: this.getDayOfWeek(logDate.weekday()),
        logDate: logDate.format('YYYY-MM-DD'),
        morningLog: this.getLogForDate(sleepLogs, 'morningLog', treatmentStartDate, logDate),
        eveningLog: this.getLogForDate(sleepLogs, 'eveningLog', treatmentStartDate, logDate)
      };
      return log;
  }

  private addDays(initialDate: moment.Moment, daysToAdd: number) {
    return initialDate.add(daysToAdd, 'days');
  }

  private getLogForDate(
    sleepLogs: Array<Assessment>,
    logType: string,
    treatmentStartDate: string,
    logDate: moment.Moment
  ) {
    if(logDate.hour()===23){ //handle fall back
      logDate = logDate.add(1, 'h');
    }
    if (!sleepLogs) {
      sleepLogs = [];
    }
    else{
      const log = sleepLogs.find(log => moment.utc(log.assessmentDate).toISOString().substring(0, 10)===logDate.format("YYYY-MM-DD") && log.groups.find(g => g.type=== logType));
      if(log){
          const status = log.isIncomplete
            ? this.getIncompleteLogStatus(logDate, logType, treatmentStartDate)
            : AssessmentStatus.LOGGED.name;
          return {
            logType,
            questions: this.getQuestionAnswers(log, log.groups[0], logType),
            logStatus: status,
            isIncomplete: log.isIncomplete
          };
      }
    }
    return {
      logType,
      questions: this.getQuestionDefinitions(logType),
      logStatus: this.getIncompleteLogStatus(logDate, logType, treatmentStartDate)
    };
  }

  getQuestionAnswers(log: Assessment, group, logType: string) {
    if (!log.isIncomplete) {
      return group.answers;
    } else {
      let questions: Array<AssessmentQuestion> = this.getQuestionDefinitions(logType);
      questions = questions.map(question => this.assignQuestions(question, [log]));
      return questions;
    }
  }

  private assignQuestions(question: AssessmentQuestion, assessments: Array<Assessment>) {
    if (question.uniqueAnswerId) {
      let answers = [];
      for (const ca of assessments) {
        for (const group of ca.groups) {
          answers = answers.concat(group.answers);
        }
      }

      const existingAnswer = answers.find(
        answer =>
          answer.uniqueAnswerId &&
          answer.uniqueAnswerId === question.uniqueAnswerId &&
          (!!answer.value || answer.value === 0 || answer.value === false)
      );
      if (existingAnswer) {
        question.answer = existingAnswer.value;
      }
      return question;
    }
  }

  getIncompleteLogStatus(logDate: Moment, logType: string, treatmentStartDate: string) {
    // COAST 604, if the current time is between 0 and 3, we should treat it as the day before.  Otherwise, it checks the status
    // for the new day's evening log instead of the previous, and shows as active, even if already completed.
    
    // Today is in local time
    const currentDate = moment()
    // The date of the log is in local time
    const logMoment = logDate
    // Take the treatmentStartDate and convert to the users local time for comparison
    const treatmentStart = moment(treatmentStartDate).subtract(moment().utcOffset(), 'minutes').startOf('day')

    if (!treatmentStartDate) {
      return AssessmentStatus.TBD;
    }

    if (logMoment.isBefore(treatmentStart, 'days')) {
      return AssessmentStatus.PRE_REGISTRATION.name;
    } else if (currentDate.isSame(logMoment, 'days')) {
      return this.getStatusForTodaysLogs(logType);
    } else if (currentDate.isBefore(logMoment, 'days')) {
      return AssessmentStatus.TBD.name;
    } else if (this.getHowManyHoursOld(logMoment, logType, true) >= 48) {
      return AssessmentStatus.MISSED.name;
    } else {
      return AssessmentStatus.EXPIRING.name;
    }
  }

  private getStatusForTodaysLogs(logType: string): string {
    if (this.getLogTypeByTimeOfDay() === 'morningLog') {
      return logType === 'morningLog' ? AssessmentStatus.ACTIVE.name : AssessmentStatus.TBD.name;
    } else {
      return logType === 'morningLog'
        ? AssessmentStatus.EXPIRING.name
        : AssessmentStatus.ACTIVE.name;
    }
  }

  /**
   * Calculates how many hours old the log is in order to display that number
   * in the weekly log view
   * @param date date of log
   * @param logType type of log (morningLog/eveningLog)
   * @param trueDiff TBD
   */
  public getHowManyHoursOld(date: moment.Moment, logType: string, trueDiff?: boolean): number {
    const logDate = date;
    if (logType === 'eveningLog') {
      logDate.hour(this.EVENING_LOG_HOUR);
    } else {
      logDate.hour(0);
    }
    
    const currentDate = moment();
    if (!trueDiff) {
      currentDate.startOf('day'); //what is this for?
    }

    const diff = currentDate.diff(logDate, 'hour')
    return diff;
  }

  private doesDateMatch(firstDate: Date, secondDate: Date): boolean {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }

  private isAfterToday(logDate: Date, now: Date): boolean {
    if (now.getFullYear() < logDate.getFullYear()) {
      return true;
    }
    if (now.getMonth() < logDate.getMonth() && now.getFullYear() <= logDate.getFullYear()) {
      return true;
    }
    if (
      now.getDate() < logDate.getDate() &&
      now.getMonth() <= logDate.getMonth() &&
      now.getFullYear() <= logDate.getFullYear()
    ) {
      return true;
    }
    return false;
  }

  private getDayOfWeek(index: number) {
    const weekday = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    return weekday[index];
  }

  /**
   * Returns the date that is 6 days ago for display in the weekly log view
   */
  private getWeekStart(): moment.Moment {
    return moment().subtract(6, 'days').startOf('day');
  }

  getQuestionDefinitions(logName: string) {
    const log = this.assessmentDefinitionService.getByName(logName);
    return log ? log.questions : null;
  }

  /**
   * Sets the current log in the state, which is used to load the log questions for the
   * patient to answer
   * @param logDate date of log to be set
   * @param logType type of log to be set (morningLog or eveningLog)
   */
  public setCurrentLog(logDate?: string, logType?: string) {
    const state = this.getState();

    if (!logDate) {
      const current = moment();
      logDate = current.format('YYYY-MM-DD');
    }

    if (!logType) {
      logType = this.getLogTypeByTimeOfDay();
    }

    // both values should just be a simple string representation that was already formatted without times,
    // so no need to convert to moment or Date object to do comparison
    const todaysLog = state.currentWeekLogs.find(log => log.logDate === logDate);
    if (todaysLog) {
      state.currentLog = logType === 'morningLog' ? todaysLog.morningLog : todaysLog.eveningLog;
      state.currentLog.logDate = todaysLog.logDate;
    }
    this.setState(state);
  }

  /**
   * Computers which log should be available to the patient based on the current time
   * @returns Log type of available log
   */
  getLogTypeByTimeOfDay(): string {
    const now = new Date();
    const hourOfDay = now.getHours();
    return hourOfDay < this.EVENING_LOG_HOUR ? 'morningLog' : 'eveningLog';
  }

  getIncompleteLogCount(): number {
    const state = this.stateModel.get();
    let count = 0;
    if (state.currentWeekLogs && state.currentWeekLogs.length > 0) {
      for (const log of state.currentWeekLogs) {
        if (log.morningLog.logStatus === AssessmentStatus.EXPIRING.name 
          || log.morningLog.logStatus === AssessmentStatus.ACTIVE.name) {
          count++;
        }
        if (log.eveningLog.logStatus === AssessmentStatus.EXPIRING.name 
          || log.eveningLog.logStatus === AssessmentStatus.ACTIVE.name) {
          count++;
        }
      }
    }
    return count;
  }

  getCompletedLogsThisWeek(logType: string) {
    const state = this.getState();
    const completed = state.currentWeekLogs.filter(
      log => log[logType].logStatus === AssessmentStatus.LOGGED.name
    );
    return completed;
  }

  setIsLoading(isLoading: boolean) {
    const state = this.stateModel.get();
    state.isLoading = isLoading;
    this.stateModel.set(state);
  }

  areQuestionsUnanswered(questions: Array<AssessmentQuestion>): boolean {
    return _some(questions, q => !q.answer || q.answer === '');
  }

  public setIsModalDisplayed(isDisplayed: boolean) {
    const state = this.getState();
    state.isModalDisplayed = isDisplayed;
    this.setState(state);
  }

  public getIsModalDisplayed(): boolean {
    const state = this.getState();
    return !!state.isModalDisplayed;
  }

  hasActiveLog(): boolean {
    const state = this.getState();
    return _some(
      state.currentWeekLogs,
      log =>
        log.morningLog.logStatus === AssessmentStatus.ACTIVE.name ||
        log.eveningLog.logStatus === AssessmentStatus.ACTIVE.name
    );
  }

  async transformWeeklyLogs() {
    const periodStart = moment().subtract(6, 'days').startOf('day');
    const sleepLogs = await this.getSleepLogsFromDate(periodStart.toDate(), 7);
    const formattedLogs = [];
    for (const log of sleepLogs) {
      const formattedLog = {
        dayOfWeek: this.getDayAbbreviation(log.day),
        logDate: log.logDate,
        logDateFormatted: this.getDayAndMonth(log.logDate),
        morningStatus: log.morningLog.logStatus,
        eveningStatus: log.eveningLog.logStatus,
        pointInTime: this.getPointInTime(log.logDate)
      };
      formattedLogs.push(formattedLog);
    }
    return formattedLogs;
  }

  private getDayAbbreviation(dayOfWeek: string) {
    return dayOfWeekAbbreviations[dayOfWeek];
  }

  private getDayAndMonth(dateStr: string): string {
    const date = new Date(dateStr);
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    return `${month}/${day}`;
  }

  private getPointInTime(dateStr: string): string {
    const date = this.addOffsetToDate(new Date(dateStr));
    date.setHours(0, 0, 0, 0);
    const today = new Date();
    if (today.getHours() < 3) {
      today.setDate(today.getDate() - 1);
    }
    today.setHours(0, 0, 0, 0);

    if (date.getTime() < today.getTime()) {
      return 'past';
    } else if (date.getTime() === today.getTime()) {
      return 'present';
    } else {
      return 'future';
    }
  }

  public setLogStartTime(startTime: Date) {
    const state = this.getState();
    state.logStartTime = startTime;
    this.setState(state);
  }

  public getLogStartTime(): Date {
    const state = this.getState();
    if (!state && !state.logStartTime) {
      return new Date();
    }
    return state.logStartTime;
  }

  public addOffsetToDate(date: Date): Date {
    const offset = date.getTimezoneOffset();
    date.setTime(date.getTime() + offset * 60 * 1000);
    return date;
  }

  public subtractOffsetFromDate(date: Date): Date {
    const offset = date.getTimezoneOffset();
    date.setTime(date.getTime() - offset * 60 * 1000);
    return date;
  }

  private getUserSimpleInstance(): UserSimpleInstance {
    const user = new UserSimpleInstance();
    const currentUser: any = this.userStateService.model.get().User;
    if (user) {
      user.id = currentUser?.UserId;
      user.display = `${currentUser?.profile?.firstName} ${currentUser.profile.lastName}`;
      user.model = 'UserSettings';
      user.providers = currentUser.profile.assignedProviders;
      return user;
    } else {
      return null;
    }
  }

  public saveLog(
    logType: string,
    questions: Array<AssessmentQuestion>,
    logDate: string,
    isPartial?: boolean,
    logMeta?: Meta,
    percentage?: number
  ) {
    // changed to use logToAssessment to avoid code duplication
    const assessment = this.logToAssessment(
      logType,
      questions,
      logDate,
      null,
      null,
      isPartial,
      logMeta,
      percentage
    );

    return this.assessmentService.save(assessment);
  }

  /**
   * Handle saving from cached log seperately to account for their start and end time
   * @param logType
   * @param questions
   * @param logDate
   * @param logStartTime
   * @param logEndTime
   * @param isPartial
   * @param logMeta
   * @param percentage
   */
  public saveCachedLog(
    logType: string,
    questions: Array<AssessmentQuestion>,
    logDate: string,
    logStartTime?: Date,
    logEndTime?: Date,
    isPartial?: boolean,
    logMeta?: Meta,
    percentage?: number
  ) {
    // changed to use logToAssessment to avoid code duplication
    const assessment = this.logToAssessment(
      logType,
      questions,
      logDate,
      logStartTime,
      logEndTime,
      isPartial,
      logMeta,
      percentage
    );

    return this.assessmentService.save(assessment);
  }

  /**
   * Convert log to Assessment
   * Made public so it can be called from LogCacheService
   * @param logType
   * @param questions
   * @param logDate
   * @param isPartial
   * @param logMeta
   * @param percentage
   */
  public logToAssessment(
    logType: string,
    questions: Array<AssessmentQuestion>,
    logDate: string,
    logStartTime?: Date,
    logEndTime?: Date,
    isPartial?: boolean,
    logMeta?: Meta,
    percentage?: number,
    phaseName?: string
  ) {
    if (!logStartTime) {
      const state = this.getState();
      logStartTime = state.logStartTime;
    }
    const assessment = this.createAssessment(
      logDate,
      logStartTime,
      logType === 'dailyCheckIn' ? 'daily' : null,
      percentage
    );
    if (logMeta) {
      assessment.__i = logMeta;
    }
    if (logEndTime) {
      assessment.endTime = logEndTime;
    }
    const group = new AssessmentGroup();
    group.type = logType;
    group.answers = this.transformQuestions(questions);
    assessment.groups.push(group);
    assessment.phaseName = phaseName;
    assessment.isIncomplete = !!isPartial;
    return assessment;
  }

  public saveLogAIRE(
    logType: string,
    questions: Array<AssessmentQuestion>,
    logDate: string,
    isPartial?: boolean,
    lastPage?: number,
    isMedicRecommended?: boolean,
    logMeta?: Meta,
    percentage?: number,
    includeChildQuestions?: boolean,
    startTime?: string,
    endTime?: string
  ){
    const assessment = this.translateAIRELog(
      logType,
      questions,
      logDate,
      isPartial,
      lastPage,
      isMedicRecommended,
      logMeta,
      percentage,
      includeChildQuestions,
      logType
    );
    if(startTime){
      assessment.startTime = new Date(startTime);
    }
    if(endTime){
      assessment.endTime = new Date(endTime);
    }
    /*
    if(startTime){ //called from submit cache
      //check whether this is already synced by soldier
      this.assessmentService.getOneAsync(this.getCompletedAssessmentQuery(assessment.phaseName, moment(assessment.startTime))).then(async exist => {
        if(!exist){
          return this.assessmentService.save(assessment); 
        }
      })
    }
    else{*/
    return this.assessmentService.save(assessment);
    //}
  }

  public translateAIRELog(
    logType: string,
    questions: Array<AssessmentQuestion>,
    logDate: string,
    isPartial?: boolean,
    lastPage?: number,
    isMedicRecommended?: boolean,
    logMeta?: Meta,
    percentage?: number,
    includeChildQuestions?: boolean,
    phaseName?: string,
    patientId?: string,
    startTime?: string,
    endTime?: string
  ): Assessment {
    const state = this.getState();
    const assessment = this.createAssessment(
      logDate,
      state.logStartTime,
      logType,
      percentage,
      logMeta?.guid
    );
    assessment.isProviderRecommended = isMedicRecommended;
    if(phaseName) //only need to reassign if there's an overide value
      assessment.phaseName = phaseName;
    assessment.lastPage = lastPage;
    if (logMeta) {
      assessment.__i = logMeta;
    }
    //set start and end time, important in medic on BLE
    if(startTime){
      assessment.startTime = new Date(startTime);
    }
    if(endTime){
      assessment.endTime = new Date(endTime);
    }
    this.calculationsDefinitions.ModuleTemplates.find(m => m.name === logType).assessments.forEach(
      a => {
        const group = new AssessmentGroup();
        const groupQuestions = new Array<AssessmentQuestion>();
        group.type = a.name;
        a.questions.forEach(q => {
          const answerQuestion = questions.find(qa => qa?.uniqueAnswerId === q?.uniqueAnswerId);
          if (q.uniqueAnswerId) {
            groupQuestions.push(answerQuestion);
          }
          if (includeChildQuestions === true && answerQuestion?.conditionalQuestions?.length > 0) {
            answerQuestion?.conditionalQuestions?.forEach(cq => {
              groupQuestions.push(cq);
            });
          }
          if (answerQuestion?.followUpTemplateQuestions?.length > 0) {
            (q.answer as Array<any>)?.forEach(opt => {
              if(opt.isChecked && opt.value !== 'N/A') {
                answerQuestion?.followUpTemplateQuestions?.forEach(fu => {
                  const qExists = questions.find(fuq => fuq?.uniqueAnswerId === fu.uniqueAnswerId);
                  if (qExists) {
                    const answerFUQuestion = questions.find(fuq => fuq?.uniqueAnswerId === fu.uniqueAnswerId.replace('[value]', opt.value));
                  let copy = { ...answerFUQuestion };
                  groupQuestions.push(copy);
                  if(fu.conditionalQuestions?.length > 0) {
                    fu.conditionalQuestions.forEach(cq => {
                      const answerFUCQuestion = questions.find(fucq => fucq?.uniqueAnswerId === cq.uniqueAnswerId.replace('[value]', opt.value));
                      const cqCopy = {...answerFUCQuestion};
                      groupQuestions.push(cqCopy);
                    });
                  }
                  }
                });
              }
            });

          }
        });
        group.answers = this.transformQuestions(groupQuestions);
        assessment.groups.push(group);
      }
    );

    assessment.isIncomplete = percentage !== 100;

    return assessment;
  }

  private getCompletedAssessmentQuery(logType:string, startDate: moment.Moment){
    return {
      $and: [
        {
          'Payload.phaseName': { $eq: logType }
        },
        {
          'Payload.isIncomplete': false
        },
        {
          'Payload.startTime': {$regex: startDate.toISOString().split('.')[0]} //ignore milliseconds
        }
      ]
    };
  }

  public async saveLogForPatient(
   assessment:Assessment
  ): Promise<Assessment> {
    return new Promise(resolve =>{
      
      if(!assessment.user){//no user info, assign current user assuming this is from soldier app
        assessment.user = this.getUserSimpleInstance();
      }
      //check whether this is already synced by soldier
      this.assessmentService.getOneAsync(this.getCompletedAssessmentQuery(assessment.phaseName, moment(assessment.startTime))).then(async exist => {
        if(!exist){
          const a = await this.assessmentService.save(assessment).toPromise();
          resolve(a);
        }
        else{
          if(assessment.user.display && assessment.user.display.length>0){ //sync from soldier has more detail, so should overide the one from medic if possible
            exist.user = assessment.user;
            exist.groups = assessment.groups;
            const a = await this.assessmentService.save(exist).toPromise();
            resolve(a);
          }
          else{
            resolve(exist);
          }
        }
      });
    })
  }

  public emitCompletedLog() {
    const state = this.getState();
    this.onLogCompleted$.next(state);
  }

  public saveAssessments(
    assessments: Array<{ assessmentName: string; questions: Array<AssessmentQuestion> }>,
    logDate: string,
    phaseName: string,
    isPartial?: boolean,
    assessmentMeta?: Meta
  ) {
    const assessment = this.rawToAssessments(
      assessments,
      logDate,
      phaseName,
      null,
      null,
      isPartial,
      assessmentMeta
    );
    return this.assessmentService.save(assessment);
  }

  public saveCachedAssessments(
    assessments: Array<{ assessmentName: string; questions: Array<AssessmentQuestion> }>,
    logDate: string,
    phaseName: string,
    logStartTime?: Date,
    logEndTime?: Date,
    isPartial?: boolean,
    assessmentMeta?: Meta
  ) {
    const assessment = this.rawToAssessments(
      assessments,
      logDate,
      phaseName,
      logStartTime,
      logEndTime,
      isPartial,
      assessmentMeta
    );
    return this.assessmentService.save(assessment);
  }

  /**
   * Convert raw assessment parameters into ready to save assessment
   * Made pubic so it can be called by LogCacheService
   * @param assessments
   * @param logDate
   * @param phaseName
   * @param logStartTime
   * @param logEndTime
   * @param isPartial
   * @param assessmentMeta
   */
  public rawToAssessments(
    assessments: Array<{ assessmentName: string; questions: Array<AssessmentQuestion> }>,
    logDate: string,
    phaseName: string,
    logStartTime?: Date,
    logEndTime?: Date,
    isPartial?: boolean,
    assessmentMeta?: Meta
  ) {
    if (!logStartTime) {
      logStartTime = this.getState().logStartTime;
    }
    const assessment = this.createAssessment(logDate, logStartTime, phaseName);
    if (assessmentMeta) {
      assessment.__i = assessmentMeta;
    }
    if (logEndTime) {
      assessment.endTime = logEndTime;
    }
    for (const log of assessments) {
      const group = new AssessmentGroup();
      group.type = log.assessmentName;
      group.answers = this.transformQuestions(log.questions);

      assessment.groups.push(group);
    }

    assessment.isIncomplete = !!isPartial;
    return assessment;
  }

  private createAssessment(
    logDate: string,
    logStartTime: Date,
    phaseName: string,
    percentage?: number,
    id?: string
  ) {
    const assessment = new Assessment();
    assessment.__i.model = new SystemModel('Assessment');
    assessment.comment = null;
    assessment.percentageComplete = percentage;
    assessment.phaseName = phaseName;
    assessment.id = id;
    assessment.assessmentDate = logDate;
    if(this.networkService.isOnline()) {
      assessment.user = this.getUserSimpleInstance();
    }

    assessment.startTime = logStartTime || new Date();
    assessment.endTime = new Date();
    assessment.groups = [];
    assessment.timeZoneOffset = new Date().getTimezoneOffset();
    assessment.moduleId = moduleIds[phaseName];
    return assessment;
  }

  public async updateWeekLogs() {
    const state = this.getState();
    this.getThisWeeksSleepLogs()
      .pipe(take(1))
      .subscribe(response => {
        response.then(sleepLogs => {
          state.currentWeekLogs = sleepLogs;
          state.isLoading = false;
          this.setSleepPrescriptionQuestions(this.sleepRx);
          this.setState(state);
          this.setCurrentLog();
        });
      });
  }

  /**
   * To update week log during offline mode
   * otherwise the remaining log # will not be updated when
   * new log saved during offline mode.
   */
  public cacheUpdateWeekLogs() {
    const state = this.getState();
    this.getThisWeeksSleepLogs()
      .pipe(take(1))
      .subscribe(response => {
        response.then(sleepLogs => {
          state.currentWeekLogs = sleepLogs;
          state.isLoading = false;
          this.setState(state);
          this.setCurrentLog();
        });
      });
  }

  private transformQuestions(questions: Array<AssessmentQuestion>): Array<AssessmentAnswer> {
    const answers = [];
    let index = 1;

    for (const question of questions) {
      if (!question) {
        continue;
      } //TODO: investigate why initial index in questions is undefined
      const answer = new AssessmentAnswer();
      answer.index = index;
      index++;

      if (question.answer || question.answer === false || question.answer === 0) {
        answer.value = question.answer;
      } else {
        answer.value = null;
      }

      if (question.uniqueAnswerId) {
        answer.uniqueAnswerId = question.uniqueAnswerId;
      }

      if (question.conditionalQuestions?.length > 0) {
       answer.conditionalQuestionAnswers = [];
        for (
          let conditionalInd = 0;
          conditionalInd < question.conditionalQuestions.length;
          conditionalInd++
        ) {
          const conditionalAnswer = new AssessmentAnswer();
          conditionalAnswer.index = conditionalInd + 1;
          conditionalAnswer.uniqueAnswerId = question.conditionalQuestions[conditionalInd].uniqueAnswerId;
          conditionalAnswer.value = question.conditionalQuestions[conditionalInd].answer;
          // adding a null check so that unanswered conditional questions are not saved to the DB
          if (conditionalAnswer.value !== null && conditionalAnswer.value !== undefined) { 
            answer.conditionalQuestionAnswers.push(conditionalAnswer);
          }
          // adding this logic to save the answers of nested conditional questions
          if (question.conditionalQuestions[conditionalInd].conditionalQuestions && 
            question.conditionalQuestions[conditionalInd].conditionalQuestions.length > 0) {
            question.conditionalQuestions[conditionalInd].conditionalQuestions.forEach(nestedConditionalQuestion => {
              const nestedConditionalAnswer = new AssessmentAnswer();
              nestedConditionalAnswer.uniqueAnswerId = nestedConditionalQuestion.uniqueAnswerId;
              nestedConditionalAnswer.value = nestedConditionalQuestion.answer;
               // adding a null check so that unanswered conditional questions are not saved to the DB
              if (nestedConditionalAnswer.value !== null && nestedConditionalAnswer.value !== undefined) {
                answer.conditionalQuestionAnswers.push(nestedConditionalAnswer);
              }
            })
          }
        }
      }

      if (question.multipleQuestionArray?.length > 0) {
        answer.multipleQuestionArrayAnswers = [];
        for (
          let questionInd = 0;
          questionInd < question.multipleQuestionArray.length;
          questionInd++
        ) {
          const questionAnswer = new AssessmentAnswer();
          questionAnswer.index = questionInd + 1;
          questionAnswer.uniqueAnswerId =
            question.multipleQuestionArray[questionInd].uniqueAnswerId;
          questionAnswer.value = question.multipleQuestionArray[questionInd].answer;
          answer.multipleQuestionArrayAnswers.push(questionAnswer);
        }
      }

      answers.push(answer);
    }

    return answers;
  }

  /**
   * Gets assessment questions that deal with the current sleep prescription, such as asking
   * what tactics the patient has practiced
   * @param sleepPrescription current sleep prescription (which may not match the current week if a prescrption has not been sent yet)
   */
  public setSleepPrescriptionQuestions(sleepPrescriptions: SleepPrescription[]) {
    const state = this.getState();

    sleepPrescriptions.forEach(sleepRx => {
      if (sleepRx.phaseName === this.currentPhase) {
        state.currentSleepPrescription = sleepRx;
      } else if (sleepRx.phaseName === this.previousPhase.name) {
        state.previousSleepPrescription = sleepRx;
      }
    });

    if (state.currentSleepPrescription) {
      const currentTacticQuestions: Array<AssessmentQuestion> = this.getTacticQuestions(
        state.currentSleepPrescription
      );

      if (currentTacticQuestions.length > 0) {
        state.currentWeekLogs.forEach(log => {
          if (
            log.eveningLog.logStatus !== AssessmentStatus.LOGGED.name &&
            log.eveningLog.logStatus !== AssessmentStatus.MISSED.name &&
            !moment(log.logDate).isBefore(moment(state.currentSleepPrescription.startDate))
          ) {
            if (
              log.eveningLog.questions[log.eveningLog.questions.length - 1].questionText !==
              currentTacticQuestions[currentTacticQuestions.length - 1].questionText
            ) {
              log.eveningLog.questions = log.eveningLog.questions.concat(currentTacticQuestions);
            }
          }
        });
      }
    }

    if (state.previousSleepPrescription) {
      const previousTacticQuestions: Array<AssessmentQuestion> = this.getTacticQuestions(
        state.previousSleepPrescription
      );

      if (this.previousPhase) {
        // find the end date for the previous phase from the calendar events list
        this.previousPhase.endDate = this.calendarEvents.find(
          calEvent => calEvent.phaseName === this.previousPhase.name
        ).endDate;
      }

      if (previousTacticQuestions.length > 0) {
        state.currentWeekLogs.forEach(log => {
          if (
            log.eveningLog.logStatus !== AssessmentStatus.LOGGED.name &&
            log.eveningLog.logStatus !== AssessmentStatus.MISSED.name &&
            moment(log.logDate).isSameOrBefore(
              moment(this.previousPhase.endDate).utcOffset(6).format('YYYY-MM-DD').toString()
            )
          ) {
            if (
              log.eveningLog.questions[log.eveningLog.questions.length - 1].questionText !==
              previousTacticQuestions[previousTacticQuestions.length - 1].questionText
            ) {
              log.eveningLog.questions = log.eveningLog.questions.concat(previousTacticQuestions);
            }
          }
        });
      }
    }

    this.setState(state);
    this.setCurrentLog();
  }

  private getTacticQuestions(sleepPrescription: SleepPrescription): Array<AssessmentQuestion> {
    const tacticQuestions: Array<AssessmentQuestion> = [];

    if (sleepPrescription) {
      if (sleepPrescription && sleepPrescription.isRestless) {
        const question = this.createTacticQuestion('isRestless');
        tacticQuestions.push(question);
      }
      if (sleepPrescription && sleepPrescription.isThinking) {
        const question = this.createTacticQuestion('isThinking');
        tacticQuestions.push(question);
      }
      if (sleepPrescription && sleepPrescription.isNightmare) {
        const question = this.createTacticQuestion('isNightmare');
        tacticQuestions.push(question);
      }
      if (
        sleepPrescription &&
        sleepPrescription.patientFlags &&
        sleepPrescription.patientFlags.length > 0
      ) {
        if (this.isFlagged(sleepPrescription.patientFlags, 'IsClockRetraining')) {
          const question = this.createTacticQuestion('isClockRetraining');
          tacticQuestions.push(question);
        }
        if (this.isFlagged(sleepPrescription.patientFlags, 'IsStressReduction')) {
          const question = this.createTacticQuestion('isStressReduction');
          tacticQuestions.push(question);
        }
        if (this.isFlagged(sleepPrescription.patientFlags, 'IsFatgiueCounter')) {
          const question = this.createTacticQuestion('isFatigueCounter');
          tacticQuestions.push(question);
        }
      }
    }
    return tacticQuestions;
  }

  private createTacticQuestion(tacticName: string) {
    let tacticText: string;
    switch (tacticName) {
      case 'isRestless':
        tacticText = 'Sleep Intensity Training';
        break;
      case 'isThinking':
        tacticText = 'Thinking Efficiency';
        break;
      case 'isNightmare':
        tacticText = 'Dream Retraining';
        break;
      case 'isClockRetraining':
        tacticText = 'Clock Retraining';
        break;
      case 'isStressReduction':
        tacticText = 'Stress Reduction';
        break;
      case 'isFatigueCounter':
        tacticText = 'Fatigue Countermeasures';
        break;
      default:
        return;
    }

    const question = new AssessmentQuestion();
    question.questionText = `Did you practice your ${tacticText}?`;
    question.questionType = 'radio';
    question.questionHeader = true,
    question.answerOptions = [
      { display: 'Yes', value: true },
      { display: 'No', value: false }
    ];
    question.conditionalQuestions = [];
    question.uniqueAnswerId = `TACTIC_DID_PRACTICE_${tacticName.toUpperCase()}`;
    question.autofill = false;
    return question;
  }

  public isFlagged(flags: Array<SleepPrescriptionFlag>, property: string) {
    return _some(flags, flag => flag.name === property && flag.isSelected);
  }

  public getInProgressAssessmentMeta(log: Log): Meta {
    const state = this.getState();
    const inProgressLogs = state.inProgressLogs.filter(
      a =>
        !!a.groups && a.groups.length > 0 && !!a.groups[0].answers && a.groups[0].answers.length > 0
    );
    const assessment = inProgressLogs.find(
      a =>
        a.groups[0].type === log.logType &&
        this.doesDateMatch(new Date(a.assessmentDate), new Date(log.logDate))
    );
    if (assessment) {
      return assessment.__i;
    } else {
      return null;
    }
  }

  public async isRoutineStartAfterRiseTime(logDate: any, routineStart: any) {
    // Use assessment service to get corresponding log type
    const userId = this.userStateService.model.get().User?.UserId;
    const wakeTimeObject = {
      wakeTime: null,
      before: false
    };

    await this.assessmentService
      .getLog(logDate, logTypes.morning, userId)
      .toPromise()
      .then(result => {
        if (result != null && result.groups != null) {
          const wakeTime = moment(
            result.groups[0].answers.find(x => x.uniqueAnswerId === uniqueAnswerIds.wakeTime).value,
            'HH:mm'
          );
          const attemptToSleep = moment(
            result.groups[0].answers.find(x => x.uniqueAnswerId === uniqueAnswerIds.attemptToSleep).value,
            'HH:mm'
          );
          const isSameDate = wakeTime.hour() >= attemptToSleep.hour();

          wakeTimeObject.before = (routineStart.isBefore(wakeTime) && !isSameDate) || (routineStart.isBefore(wakeTime) && isSameDate && !routineStart.isBefore(attemptToSleep));
          wakeTimeObject.wakeTime = wakeTime;
        }
      });
    return wakeTimeObject;
  }

  public async isRiseTimeBeforeRoutineStart(logDate: any, wakeOrRiseTime: any) {
    // Use assessment service to get corresponding log type
    const userId = this.userStateService.model.get().User.UserId;
    const routineStartObject = {
      time: null,
      after: false
    };
    await this.assessmentService
      .getLog(logDate, logTypes.evening, userId)
      .toPromise()
      .then(result => {
        if (result != null && result.groups != null) {
          const eveningMoment = moment(
            result.groups[0].answers.find(x => x.uniqueAnswerId === uniqueAnswerIds.routineStart)
              .value,
            'HH:mm'
          );
          routineStartObject.after = wakeOrRiseTime.isAfter(eveningMoment);
          routineStartObject.time = eveningMoment;
        }
      });
    return routineStartObject;
  }
}
