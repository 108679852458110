import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const phq2: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'phq2-overall',
    unit: UnitType.Number,
    abbreviation: 'phq2-overall',
    description: `PHQ2 7 Overall`,
    assessmentType: LogDefinitionTypes.PHQ2
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq2-interest',
    unit: UnitType.Number,
    abbreviation: 'Interest',
    description: `Interest`,
    assessmentType: LogDefinitionTypes.PHQ2,
    answerId: 'PHQ2_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq2-depression',
    unit: UnitType.Number,
    abbreviation: 'Depression',
    description: `Depression`,
    assessmentType: LogDefinitionTypes.PHQ2,
    answerId: 'PHQ2_DEPRESSION'
  }),


  //#endregion
];
