import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reportPageFilter',
    pure:false
})
export class ReportPageFilterPipe implements PipeTransform {
    transform(pages: any[], filter: Array<any>): any {
        if (!pages || !filter) {
            return pages;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return pages.filter(page => page.required || filter.find(f => f.name === page.name)?.selected);

    }
}
