export const sleepHealthToolsDef = {
    "name":"sleepHealthTools",
    "description":"Sleep Health Tools and Treatments",
    "instructions":"",
    /*"questions":[
        {
            "id":0,
            "questionText":"Have you used any of the following to try to improve your sleep? Select all that apply.",
            "questionType":"checkbox",
            "autofill":false,
            "answerOptions":[
                {
                "display":"Apps I found myself (Calm, Headspace, Mindfulness Coach, My Fitness Pal, etc.)",
                "value":1,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Apps recommended by my provider (CBT-I Coach, Insomnia Coach, etc.)",
                "value":2,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Wearables (Fitbit, Garmin, Oura Ring, Whoop Strap, etc.)",
                "value":3,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Sleep hygiene class",
                "value":4,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Cognitive Behavioral Therapy for Insomnia (CBTI) – Group therapy",
                "value":5,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Cognitive Behavioral Therapy for Insomnia (CBTI) – Individual therapy",
                "value":6,
                "isChecked":false,
                "clearOtherAnswers":false
                },
                {
                "display":"Have not used any of these",
                "value":"NA",
                "isChecked":false,
                "clearOtherAnswers":true
                }
            ],
            "uniqueAnswerId":"SLEEP_HEALTH_TOOLS",
            "conditionalQuestions":[
                
            ],
            "validation":{
                
            }
        }
    ]*/
}