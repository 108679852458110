import { Component, OnInit, Input } from '@angular/core';
import { some as _some } from 'lodash';

@Component({
  selector: 'carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['../carousel-page.component.scss']
})

export class CarouselSlideComponent implements OnInit {
  @Input() public modules: any[];
  @Input() public showProgress = false;

  constructor() { }

  ngOnInit() {

  }
}
