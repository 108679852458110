import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const fatigueScanning: AssessmentDefinition = {
  name: 'fatigue-scanning',
  description: 'Fatigue Scanning',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: '',
      subText: `<p>Fatigue can compromise your own performance, safety, and mission success.</p>
                <p class='content-image'><img src='./assets/img/module-images/fatigue-scanning/performance.svg'/></p>
                <p>Therefore, it is important that you be proactive in recognizing signs of fatigue.</p>
                <p class='content-bold'>Scan yourself and battle buddies for signs of fatigue.  If any are noticed, get sleep <span class='content-highlighted'>ASAP.</span></p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText:
        'Select the signs of fatigue you will scan yourself and battle buddies for daily',
      questionEmphasis: '',
      subText: '<p>If you observe any of these for yourself or your battle buddy, get some sleep.</p>',
      questionType: 'checkbox',

      uniqueAnswerId: 'FATIGUE_SIGNS',
      autofill: false,
      answerOptions: [
        {
          display: 'Frequent yawning',
          value: 0,
          isChecked: false
        },
        {
          display: 'Nodding off',
          value: 1,
          isChecked: false
        },
        {
          display: 'Difficulty concentrating',
          value: 2,
          isChecked: false
        },
        {
          display: 'Irritable or bad mood',
          value: 3,
          isChecked: false
        },

        {
          display: 'Making more errors or mistakes',
          value: 4,
          isChecked: false
        },
        {
          display: 'Forgetfulness',
          value: 5,
          isChecked: false
        },
        {
          display: 'Decreased motivation',
          value: 6,
          isChecked: false
        },
        {
          display: 'Slow responses',
          value: 7,
          isChecked: false
        }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
