import 'reflect-metadata';
import { CollectionUtils } from './utils';
import { get, has } from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IHttpProvider, IRepository } from './contracts/interfaces';
import {
  BaseObject, IApplicationContext, InstanceAccess, Meta, SystemModel, ValidationResponse
} from './contracts/models';
import { ApiHelper } from './helpers';
import { Injectable, Inject } from '@angular/core';
import { ApplicationContext } from './web-ng';

@Injectable()
export class BaseRepository<T extends BaseObject> implements IRepository<T> {
  private _applicationContext: IApplicationContext;
  private apiHelper: ApiHelper;
  private _serviceUrl: string;
  private _className: string;
  private _httpProvider: IHttpProvider;

  constructor(@Inject(ApplicationContext) applicationContext: IApplicationContext, @Inject(String) className: string) {
    this._className = className;
    this._applicationContext = applicationContext;
    const provider = this._applicationContext.HttpProvider;
    this.apiHelper = new ApiHelper(this._applicationContext);
    if (!provider) {
      throw new Error('Provider is not set.');
    } else {
      this._httpProvider = provider;
    }
  }

  private getParams(url, verb, data?: any): any {
    return {
      uri: url,
      headers: this.apiHelper.Headers(),
      data: null,
      method: verb,
      json: data
    };
  }

  public setAccess(instance: BaseObject, acl: InstanceAccess, override?: boolean): Observable<any> {
    const method = override ? 'POST' : 'PUT';
    const params = this.getParams(this.apiHelper.AclUrl(instance.__i.model.name, instance.__i.guid), method, acl);
    const observable = this._httpProvider.put(params).pipe(
      map(r => r)
    );

    return observable;
  }

  public setAcl(instance: BaseObject, acl: InstanceAccess, override?: boolean): Promise<any> {
    return this.setAccess(instance, acl, override).toPromise();
  }

  public sequence(): Observable<any> {
    const params = this.getParams(this.apiHelper.SequenceUrl(this._className), 'GET');
    const observable = this._httpProvider.get(params).pipe(
      map(r => r)
    );

    return observable;
  }

  public sequenceAsync(): Promise<any> {
    return this.sequence().toPromise();
  }

  public factory(): Observable<any> {
    const className: string = this._className;
    const params = this.getParams(this.apiHelper.FactoryUrl(this._className), 'GET');
    const observable = this._httpProvider.get(params).pipe(
      map(r => r)
    );

    return observable;
  }

  public factoryAsync(): Promise<T> {
    return this.factory().toPromise();
  }

  public validate(tObject: T): Observable<ValidationResponse> {
    const className: string = this._className;
    const params = this.getParams(this.apiHelper.ValidateUrl(this._className), 'POST', tObject);
    const observable = this._httpProvider.post(params).pipe(
      map(r => r)
    );

    return observable;
  }

  public validateAsync(tObject: T): Promise<ValidationResponse> {
    return this.validate(tObject).toPromise();
  }

  public save(tObject: T): Observable<T> {
    const self = this;
    // Temporary.  saveAsync needs to be observable
    return Observable.create(observer => {
      self.saveAsync(tObject).then(r => {
        observer.next(r);
        observer.complete();
      }, err => {
        observer.error(err || new Error(`Failed to save instance: ${err}`));
      });

    });
  }

  public saveAsync(tObject: T): Promise<T> {
    const self = this;
    const className = this._className;
    return new Promise((resolve, reject) => {
      // If we have an id we PUT, if not we post.
      const id: string = get<any, string>(tObject, '__i.guid');
      const isNew: boolean = !id;

      if (isNew) {
        // Set the model
        tObject.__i = tObject.__i ? tObject.__i : new Meta();
        tObject.__i.model = new SystemModel(className);
      }

      sequence(tObject)
      .then(validate)
      .then(save)
      .then(complete)
      .catch(err => {
        return reject(err);
      });

      function sequence(instance) {
        return new Promise(function(seqResolve, seqReject) {
          if (!isNew || !!instance.Id) {
            return seqResolve(instance);
          }
          self.sequenceAsync().then(sequenceId => {
            instance.Id = sequenceId;
            return seqResolve(instance);
          }).catch(err => {
            return seqReject(err);
          });
        });
      }

      // If we're creating new, we need to validate first
      function validate(instance) {
        return new Promise((validateResolve, validateReject) => {
          if (!isNew) {
            return validateResolve(tObject);
          }
          self.validateAsync(tObject).then((r: ValidationResponse) => {
            if (r.IsValid) {
              return validateResolve(tObject);
            } else {
              return validateReject(r);
            }
          }, err => {
            validateReject(err);
          });
        });
      }

      function save(instance) {
        return new Promise((saveResolve, saveReject) => {
          const method = id != null ? 'put' : 'post';
          const params = self.getParams(self.apiHelper.InstanceUrl(self._className, id), method, tObject);

          self._httpProvider[method](params)
          .pipe(map(r => r))
          .subscribe(r => {
            if (r && has(r, '__i')) {
              saveResolve(r);
            } else {
              saveReject(`Failed to ${method} instance`);
            }
          });
        });
      }

      function complete(instance) {
        resolve(instance);
      }
    });
  }

  public getAll(query?: any, size?: number, sorts?: Array<{property: string, direction: string}>): Observable<Array<T>> {
    const params = this.getParams(this.apiHelper.InstanceUrl(this._className), 'GET');

    // We can take this as a parameter
    size = size ? size : 1000;
    params.uri = params.uri + '?' + 'size=' + size;

    if (query != null) {
      params.qs = { q: query };
    }

    if (sorts != null && sorts.length > 0) {
      params.sorts = [];
      for (const sort of sorts) {
        const direction = sort.direction || 'ASC';
        params.sorts.push(`${sort.property}:${direction}`);
      }
    }

    const observable = this._httpProvider.get(params).pipe(
      map(r => {
        if (!has(r, 'r')) {
          throw Error('Records not returned');
        }

        return r.r;
      })
    );

    return observable;
  }

  public getAllAsync(query?: any, size?: number, sorts?: Array<{property: string, direction: string}>): Promise<Array<T>> {
    return this.getAll(query, size, sorts).toPromise();
  }

  public get(id: string): Observable<T> {
    const params = this.getParams(this.apiHelper.InstanceUrl(this._className, id), 'GET');
    const observable = this._httpProvider.get(params).pipe(
      map(r => r)
    );

    return observable;
  }

  public getAsync(id: string): Promise<T> {
    return this.get(id).toPromise();
  }

  public async getOneAsync(query: Object, size: number = 50): Promise<T> {
    const instances: T[] = await this.getAllAsync(query, size);
    return CollectionUtils.firstOrDefault(instances);
  }

  public remove(id: string): Observable<any> {
    if (!id) {
      throw new Error('Failed to remove.  Id is required.');
    }
    let params: any, url: string, observable: Observable<any>;
    url = this.apiHelper.InstanceUrl(this._className, id);
    params = this.getParams(url, 'DELETE', null);
    observable = this._httpProvider.delete(params).pipe(
      map(r => r)
    );
    return observable;
  }

  public removeAsync(id: string): Promise<any> {
    return this.remove(id).toPromise();
  }
}
