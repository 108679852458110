import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';

import { AssessmentQuestion } from '../../services/models';
import { CALC_CONFIG } from '../../state/user';
import { AssessmentDefinition, BaseCalculationDefinition } from '../../../core/contracts/models.calculations';

@Component({
  selector: 'slider-question',
  templateUrl: './slider-question.component.html',
  styleUrls: ['./slider-question.component.scss']
})
export class SliderQuestionComponent implements OnInit, AfterViewInit {
  @Input() question: AssessmentQuestion;
  @Input() step: number;
  @Input() min: number;
  @Input() max: number;
  @Input() initialValue: number;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  @ViewChild('slider', {read: ElementRef}) slider: ElementRef;
  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = false;
  thumbLabel = false;
  value = null;
  vertical = true;
  tickInterval = 1;
  colorClass = 'normal-color';
  public valueMessage = '';
  public showSideDisplay = false;

  public defaultAnswer = 1;
  public labelHeight = 0;
  public labelTextHeight = 19.2;

  constructor(@Inject(CALC_CONFIG) private calcConfig: BaseCalculationDefinition, private cdRef: ChangeDetectorRef,) {

  }

  ngOnInit() {
    this.value = this.initialValue;

    this.min = this.question.answerOptions[0].value;
    this.max = this.question.answerOptions[this.question.answerOptions.length - 1].value;
    if (this.min > this.max) {
      const tmp = this.min;
      this.min = this.max;
      this.max = tmp;
    }
    this.showSideDisplay = this.question.answerOptions.length > 5;
    setTimeout(() => {
      this.labelHeight = (this.slider.nativeElement.offsetHeight - this.labelTextHeight) / (this.question.answerOptions.length - 1);
      this.cdRef.detectChanges();
    }, 100);
    this.getColorClass();
  }

  ngAfterViewInit() {}

  public getLabelHeight(index: number): number {
    return (index === this.question.answerOptions.length - 1) ? this.labelTextHeight : this.labelHeight;
  }

  getDefaultAnswer() {
    return Math.round((this.min + this.max) / 2);
  }

  getAnswerDisplay(text: string, index: number): string {
    return index % 2 === 0 ? text : null;
  }

  sliderUpdated() {
    if (this.showSideDisplay) {
      const assessmentDefinition = this.calcConfig.AssessmentQuestions.find(va => va.name === this.question.name) as AssessmentDefinition;
      const questionDef = assessmentDefinition.questions.find(q => q.uniqueAnswerId === this.question.uniqueAnswerId);
      this.valueMessage = questionDef.answerOptions.find(a => a.value === this.value).display;
    }
    this.answerUpdated.emit(this.value);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  getColorClass() {  // The color scale for these questions should be reversed, i.e., red should be on top
    this.colorClass = this.question.uniqueAnswerId == 'TEAM_ANGRY' || this.question.uniqueAnswerId == 'TEAM_LEFT_OUT' ? 'reverse-color' : 'normal-color';
  }

}
