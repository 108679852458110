import { UserAgreement } from "../../../../core";

export default new UserAgreement({
  id:1,
  name: 'consent',
  imgName:'nhrc.png',
  title: 'Consent',
  questionHeader: 'To finalize the activation of the NOCTEM app, please check each box below to confirm that all statements are true:',
  textReplacementMethod: 'replaceConsentText',
  agreementQuestions: [
    {
      answerId: 'haveReviewed',
      text: 'I have reviewed information about the NOCTEM project with a research team member and my questions were answered.'
    },
    {
      answerId: 'agree',
      text: 'I agree to participate in this research study and use the app with my Provider to improve my sleep.'
    },
    {
      answerId: 'understand',
      text: 'I understand I can withdraw at any time and that my Provider can offer other sleep management methods.'
    }
  ],
  pages: [
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header"></div>
        <div class="container text-center">
          <p>Naval Health Research Center</p>
          <h4>CONSENT TO PARTICIPATE IN RESEARCH</h4>
          <p><b>Title:</b>Optimizing the Scalability of Evidence-Based Behaviorial Sleep Medicine Practices with a mobile Health Platform</p>
          <p><i>Patient Consent Form</i><p>
          <p><b>Rachel Markwald, Ph.D.</b></p>
          </div>
          <p>You may be eligible to take part in this research study. This form gives you important information about the study.</p>
          <p>Please take time to carefully review this information. You should talk to the researchers about the research study and ask them any questions you have. You may also wish to talk to others (for example, your friends, family, or your personal physician) about your potential participation in this research study. You do not have to take part in this study. Participation is voluntary. You may also leave the research study at any time without penalization.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Key Information</div>
          <div class="container">
            <p>The following focused information is being presented to assist you in understanding the key elements of this study, as well as the basic reasons why you may or may not wish to consider taking part. This section is only a summary; more detailed information, including how to contact the research team for additional information or questions, follows within the remainder of this document. </p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Voluntary Consent</div>
          <div class="container">
            <p>You are being asked to participate in a research study. Participation is voluntary and you do not have to take part. You may also leave the research study at any time without penalty or loss of benefits.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Purpose</div>
          <div class="container">
            <p>
              The purpose of this study is to examine how a sleep digital platform called NOCTEM, can be best used to treat insomnia and other sleep disturbances in service members, veterans, and dependents who receive care at participating military treatment facilities and clinics. The study also aims to determine the magnitude of sleep improvements in patients who use the NOCTEM app with their clinicians.
            </p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Duration</div>
          <div class="container">
            <p>In general, your participation in the research will be approximately six weeks. The time commitment summary is outlined below:</p>
            <p><b>Visit 1</b>: Consent process, download and learn how to use NOCTEM app (~20 minutes)</p>
            <p><b>Assessment 1</b>: Complete a brief assessment on your phone or computer at home  (~15 minutes)</p>
            <p><b>Home App Use</b>: Complete daily “morning” and “night” logs for six weeks  (~2 minutes each day)</p>
            <p><b>Assessment 2</b>: Complete a brief assessment on your device at home (~15 minutes)</p>
            <p><b>Assessment 3</b>: Complete a brief assessment on your device at home, 1 month after treatment (~15 minutes)</p>
            <p><b>Assessment 4</b>: Complete a brief assessment on your device at home, 3 months after treatment (~15 minutes)</p>
            <p><b>Total Time: ~2.75 hours hours</b></p>
            <p>Note.  The amount of time spent using NOCTEM may vary based on the clinician's judgement.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Procedures and Activities</div>
          <div class="container">
            <p>The digital platform, called NOCTEM is designed to support the delivery of behavioral treatment of insomnia and other behavioral sleep disorders. The platform includes a portal that is accessible from a computer, tablet, or smartphone, and that is linked to your own smart device, with an embedded secure two-way text messaging system. Most of the study will be conducted via an application (app) on your personal smart device. Through the app, you will complete an assessment before starting treatment. Once treatment begins, you will complete brief logs about your sleep and your clinician will send you personalized recommendations on how to change some behaviors to improve your sleep. Lastly brief follow- up assessments will be completed on your smart device after treatment is complete and again 1 and 3 months later.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Benefits</div>
          <div class="container">
            <p>There is no certain benefit for participating in the study. You may find it easier to communicate relevant information as it occurs with your clinician through the secure text messaging system embedded in the app. The information from this research study may help researchers learn more about the effectiveness of smart device-assisted sleep interventions in improving the sleep of service members with disturbed sleep.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Risks</div>
          <div class="container">
            <p>There are minimal risks to you in taking part in this study. However, you may experience slight discomfort in answering some of the assessment questions that ask about anxiety. There is also a small potential risk of loss of privacy and/or confidential information about you.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Alternatives</div>
          <div class="container">
            <p>The only alternative is to not take part in this research study.</p>
            <p>Your decision will not affect your future care at Naval Medical Center San Diego. If you decide to take part in this research study, you will be asked to sign this document. Before you sign this document, be sure you understand what the research study is about in all sections of the consent form, including the risks and possible benefits to you.</p>
            <p>Please tell the researchers if you are taking part in another research study.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What is the purpose and duration of this research and who will take part?</div>
          <div class="container">
            <p>You are being asked to take part in this research study because you have reported sleep problems that are consistent with insomnia, like difficulty falling or staying asleep, and/or other sleep difficulties that disrupt sleep (e.g., nightmares). The purpose of this research study is to examine how a sleep digital platform called NOCTEM, can be best used to treat insomnia and other sleep disturbances in service members, veterans, and dependents who receive care at participating military treatment facilities and clinics. The study also aims to determine the magnitude of sleep improvements in patients who use the NOCTEM app with their clinicians. The duration of participation is approximately 2.75 hours over three months. During the study, you will meet with your clinical provider at least once to learn about using the NOCTEM app for treating insomnia. If you decide to use NOCTEM, you will receive Cognitive Behavioral Therapy for Insomnia (CBTI) from home using the NOCTEM app. You will complete a brief 15-minute assessment immediately before and after treatment, and then again at 1 and 3 months after treatment.</p>
            <p>There will be about 576 people taking part in the study at Naval Medical Center San Diego, over a period of one year.</p>
            <p>At the end of this research study the clinical results, including research results about you will be shared with you. You will have access to your treatment progress through the NOCTEM app and will also be able to communicate with your provider.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Screening process to qualify for participation in this study</div>
          <div class="container">
            <p>Before you can take part in this study, you will need to provide some information so that the Investigator can confirm that you qualify for the study. This is called the “Screening Process”.</p>
            <p>Please tell your provider if you are pregnant or breastfeeding, recently became a parent to a newborn in the past 9 months, have a history of bipolar disorder or psychotic symptoms, or have suspected or diagnosed sleep apnea. These exclusion criteria will be used to avoid exacerbating symptoms for sleep apnea, psychotic or bipolar disorders, and excessive sleepiness and sleep disruption in women who are pregnant or breastfeeding. This information may have been collected as a part of your regular medical care.</p>
            <p>You are eligible for this study if you own a smart device; have sleep difficulties and are willing to try to improve your sleep by modifying some of your daytime and nighttime behaviors; and your clinician is confident it is safe for you to participate in this study.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What will happen if you decide to be in this research?</div>
          <div class="container">
            <p>If you decide to take part in this study, there are <b>4 steps</b> that will happen over the 3-month duration.</p>
            <table>
              <tr>
                <td class="list-number">1</td>
                <td>
                  <p><b>Consent to participating and downloading the NOCTEM app.</b></p>
                  <p>The research team will review the patient informational brochure with you. The brochure will explain that your clinician is part of a study to compare different ways of using digital technology to deliver the recommended treatment of insomnia and other behavioral sleep disorders. To complete the activation of the app, you will be asked to check 2 boxes to confirm that (1) you have received the information about the project and that your questions were answered, and (2) you are willing to use the app with your provider to improve your sleep. You will also be asked to share your contact information with the Naval Health Research Center (NHRC) study team. You will be provided with a phone number to contact NHRC study team if you have questions, concerns, or need help after the consent process.</p>
                  <p>After providing your consent to participate in the study, you will download the NOCTEM app onto your personal smart device. We will give you a unique activation code to start using the app. Then, you will get a brief orientation on how to use the app, and receive an instruction sheet to help you remember how to use the app. You may download the NOCTEM app on all of your smart devices if you want, including your tablet, laptop, or desktop computer. Study personnel will inform your clinician you are now registered and “visible” on the NOCTEM portal.</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What will happen if you decide to be in this research? (cont'd)</div>
          <div class="container">
            <p>If you decide to take part in this study, there are <b>4 steps</b> that will happen over the 3-month duration.</p>
            <table>
              <tr>
                <td class="list-number">2</td>
                <td>
                  <p><b>Complete the morning and evening logs every day, and weekly questionnaires once per week for 4 to 6 weeks.</b></p>
                  <p>Using the NOCTEM app, you will be asked to complete brief morning and evening logs that ask questions about your sleep. The total time for each morning entry is about 90 seconds. Each evening entry takes about 60 seconds. Additionally, you will fill out weekly questionnaires that will each take about 5 minutes each to complete. Most people complete the acute intervention phase in 4 weeks, but to accommodate for everyone’s unique schedule, the duration of this phase can be up to 6 weeks. The information you provide on the sleep/wake logs and weekly questionnaires will be shared and reviewed regularly by your clinician.</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What will happen if you decide to be in this research? (cont'd)</div>
          <div class="container">
            <p>If you decide to take part in this study, there are <b>4 steps</b> that will happen over the 3-month duration.</p>
            <table>
              <tr>
                <td class="list-number"></td>
                <td>
                  <p><b>OPTIONAL STEP</b></p>
                  <p>If you are using wearable or non-wearable sensors, you can easily link them to the NOCTEM app. This information will be shared with your clinician and used by the research team to develop better sleep/wake detection algorithms specifically designed for people with sleep disturbances and receiving sleep treatments.</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What will happen if you decide to be in this research? (cont'd)</div>
          <div class="container">
            <p>If you decide to take part in this study, there are <b>4 steps</b> that will happen over the 3-month duration.</p>
            <table>
              <tr>
                <td class="list-number">3</td>
                <td>
                  <p><b>Follow the recommendations provided by your clinician through the NOCTEM app to improve your sleep</b></p>
                  <p>The information you enter through the app will be used by your clinician to give you recommendations to improve your sleep. In the first 4 to 6 weeks of the study, your clinician may agree with the app's recommendations or may make changes to the recommendations before they forward them to you, through the app. The recommendations will give you specific instructions about what to do, how to do it, and why you are being asked to do it. The recommendations are changes in your daytime and nighttime behaviors that are known to be helpful for sleep.</p>
                  <p>During the study, you will be able to exchange text messages with your clinician through the app. This text messaging system is not the one attached to your cell phone plan, and does not use your personal data plan, or that of your clinician.</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What will happen if you decide to be in this research? (cont'd)</div>
          <div class="container">
          <p>If you decide to take part in this study, there are 4 steps that will happen over the 12-month  duration.</p>
          <table>
          <tr>
            <td class="list-number">4</td>
            <td>
              <p><b>Complete the follow-up questionnaires, 1 and 3 months after finishing treatment with your clinician.</b></p>
              <p>After finishing treatment with your clinician, you will be asked to complete the same questionnaires again through the app 1 and 3 months later, or for the duration of your care with the clinician. These questionnaires will allow the research team to assess if sleep improvements are maintained over time, and/or if new improvements occur after treatment.</p>
            </td>
          </tr>
        </table>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What are the risks or discomforts from being in this research?</div>
          <div class="container">
            <p>If you choose to take part in this study, there is a risk of:</p>
            <p>
              <ul>
                <li>
                  Some <u>discomfort when filling out the questionnaires</u>: Some of the questions will ask about mood and stress symptoms you may experience. Some people feel uncomfortable answering questions of a personal nature.
                  <br />
                  <br />
                  You can refuse to answer any questions that make you uncomfortable. Discomfort related to questionnaires infrequently occurs (in less than 5 out of 100 participants).
                </li>
                <li>
                  <u>Breach of confidentiality</u>: It is possible that information regarding your sleep and psychological health will be discovered by individuals outside of study personnel, despite careful steps to protect confidentiality. Breach of confidentiality rarely occurs (in less than 1 out of 100 participants). Maintaining strict security of the information that you provide will minimize these risks. Study data will be kept strictly confidential and participants’ identities will not be revealed in any publication. All participants have a unique identifier number that will be used on forms and for data storage purposes. No results will be released to employers, ensuring no impact on future insurability or employability. Computer databases are protected by several procedures, including password protection of subject data and a firewall around the entire NOCTEM network. The only party other than you and the study staff that will have access to your identifiable information will be the NHRC Institutional Review Board (IRB).
                </li>
                <li>
                  <u>Risks specific to the use of smart devices</u>: If using text messaging for communication and scheduling purposes, be aware that although every reasonable effort may be taken, confidentiality during internet communication and text messaging activities cannot be guaranteed and it is possible that information may be intercepted and used by others not associated with this study.
                </li>
              </ul>
            </p>
            <p>
            Although efforts are made to protect your research study records, there is always a risk that someone could get access to the personal information in your medical records or other information researchers have stored about you.
            </p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What are the possible benefits of this research?</div>
          <div class="container">
            <p>There is no certain benefit for participating in the study. You may find it easier to communicate relevant information as it occurs with your clinician through the secure text messaging system embedded in the app. Additionally, the information from this research study may help researchers learn more about the effectiveness of smart device-assisted sleep interventions in improving the sleep of service members with disturbed sleep. This information could help improve readiness and overall wellbeing of US service members.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What are the alternatives to taking part in this research?</div>
          <div class="container">
          <p>Your alternative is not to participate in this research.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Is there compensation for your participation in this research?</div>
          <div class="container">
          <p>No, you will not receive any compensation for participating in this study.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Are there costs for participating in this research?</div>
          <div class="container">
          <p>No, there are no costs to you for taking part in this research study. </p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Principal Investigator (the person(s) responsible for the scientific and technical direction of the study)</div>
          <div class="container">
          <p>Dr. Rachel Markwald, Ph.D.  <br />
          Sleep Physiologist <br />
          <a href="mailTo:rachel.r.markwald.civ@mail.mil">rachel.r.markwald.civ@mail.mil</a> </p>
          <p>
          <b>For questions about the technical aspects and the content of the NOCTEM app, portal, and messaging system, contact Dr. Anne Germain at 412-897-3183 or <a href="mailTo:anne@noctemhealth.com">anne@noctemhealth.com</a>.  She is the overall Principal Investigator (PI) of the project and lead project manager for the NOCTEM team, the company who develops, deploys, and tests the NOCTEM platform. </b>
          </p>
          </div>
        </div>
        `
      },
      {
        title:'',
        imgPath:'',
        text: `
        <div class="consent-content">
          <div class="consent-header">Study Sponsor (the organizations or persons who oversee the study and are responsible for analyzing the study data):</div>
          <div class="container">
          <p>Naval Health Research Center</p>
          <p>As the sponsor of this research, the Department of Defense may have access to your research data in accordance with DoDI 3216.02.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Source of Funding</div>
          <div class="container">
          <p>This research is being conducted by NOCTEM, LLC with funding from the Medical Technology Enterprise Consortium.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Location of the Research</div>
          <div class="container">
          <p>This study is being conducted by researchers at NOCTEM LLC, the Naval Health Research Center (NHRC) in San Diego, California, the Naval Medical Center San Diego (NMCSD), and the University of Pittsburgh. Study procedures will take place primarily through the NOCTEM app, which you can access from your own home. Other study procedures may take place in participating clinicians’ offices at NMCSD or wherever you receive care.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Disclosure of Financial Interests and Other Personal Arrangements</div>
          <div class="container">
          <p>A member of the research team, Dr. Anne Germain, is the founder and CEO of NOCTEM, LLC.  </p>

          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Who Will See My Information (Privacy) and How Will it Be Protected (Confidentiality)?</div>
          <div class="container">
            <p>Records of your participation in this research study may only be disclosed in accordance with state and federal law, including the Federal Privacy Act, 5 U.S.C.552a, and its implementing regulations. DD Form 2005, Privacy Act Statement - Military Health Records, contains the Privacy Act Statement for the records. A copy of DD Form 2005 can be given to you upon request, or you can read on-line at:  </p>
            <p class='text-break'><a href="https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf">https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf</a></p>
            <p>The research team will keep your research records. These records may be looked at by staff from the NHRC, the Institutional Review Board (IRB), and the DoD Higher Level Review as part of their duties. These duties include making sure that the research participants are protected.  Confidentiality of your records will be protected to the extent possible under existing regulations and laws but cannot be guaranteed. </p>
            <p>Security and confidentiality in a mobile health application is of paramount importance.  Procedures to protect the confidentiality of the data in this study include but are not limited to:</p>
            <p>Layered security measures to protect the privacy and confidentiality of the data, including:</p>
            <p>(1) the use of a forced PIN/password to access the app; (2) a strong authentication procedure that combines a PIN/password, the phone number of the device, and the device’s unique International Mobile Equipment Identity (IMEI) number; (3) encryption of all communication between the smartphone and the cloud using the AES (Advanced Encryption Standard) 192-bit key; (4) a novel security method that allows the research team to disable the app and to erase records from the device in the event of a lost smart device while preserving the records on the secure cloud;  (5) a secure messaging system between your smart device and your clinician’s portal will be used in the place of SMS/text messaging, and lastly, (6) a secure cloud behind a firewall with security policy in place that includes a dedicated cloud, regular back-up of data, a monitored network with active security measures, and a well-defined, role-based, access to the database.  </p>

            <p>All assessments will be given via the NOCTEM app and stored in a secure HIPAA-compliant cloud. Access to all data will be limited to staff involved in this study, and results may be published in DoD technical reports, scientific journals, or presented at scientific meetings. No publication or presentation about the research study described above will reveal your identity without another authorization from you.</p>
            <p>Data collected through this study, from you will not be shared or sold to a third party.</p>
            <p>Researchers will make every effort to protect your privacy and confidentiality; however, there are risks of breach of information security and information loss.</p>
            <p>If applicable, a description of this clinical trial will be available on <a href="http://www.ClinicalTrials.gov">http://www.ClinicalTrials.gov</a> as required by U.S. Law. This web site will not include information that can identify you. At most, the Web site will include a summary of results. You can search this Web site at any time.</p>
            <p>Complete confidentiality cannot be promised for military personnel, because information regarding your health may be required to be reported to appropriate medical or command authorities to ensure the proper execution of the military mission, including evaluation of fitness for duty.</p>
            <p>The Principal Investigator, Co-investigators, and study personnel approved to be on this protocol by the IRB will have access to your records and agree to safeguard your protected health information by using and disclosing it only as permitted by you in this consent or as directed by state and federal law.</p>
            <p>Information gained from your participation in this research study may be published in literature, discussed for educational purposes, and used generally to further science. You will not be personally identified when your information is shared in these ways; all information will de-identified.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Long Term Use of Data</div>
          <div class="container">
            <p>Any future research using your retained data will require a research protocol for the proposed study approved by an Institutional Review Board (IRB) (a committee responsible for protecting research participants) or other authorized official responsible for protecting human subjects of research. The data protections for privacy and confidentiality described in this consent form will apply to any future use of your stored data.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Incidental Findings</div>
          <div class="container">
            <p>We do not anticipate discovering any abnormal medical results as part of this study. If we find something of urgent medical importance to you, we will inform you, although we expect that this will be a very rare occurrence.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Voluntary Participation</div>
          <div class="container">
            <p>The decision to take part in this research study is completely voluntary on your part which means you do not have to take part if you do not want to. You may also leave the research study at any time. If you choose not to take part in this research study or if you leave the study before it is finished, there will be no penalty or loss of benefits to which you are otherwise entitled.</p>
            <p>You will be informed if significant new findings develop during the course of this research study that may relate to your decision to continue participation.</p>
          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">What happens if I withdraw from this research?</div>
          <div class="container">
            <p>Should you choose to withdraw, you must write to the person in charge of the study, Dr. Rachel Markwald, at rachel.r.markwald.civ@mail.mil, as well as the NOCTEM team (NOCTEM4health@gmail.com) in order to terminate access to the app. When you revoke permission, no new health information about you will be gathered after that date and you may no longer be allowed to further participate in the study. Information that has already been gathered may still be used and there is no guarantee that it will be removed from the electronic database for this study.</p>
            <p>If you are receiving treatment as part of this research study, you will no longer be eligible for such research-related treatment. Contact your personal physician to discuss medical treatment for your condition</p>
            <p>The principal investigator of this research study may terminate your participation in this research study at any time if she determines this to be in your best interest, if you are unable to comply with the procedures required, or if you no longer meet eligibility criteria.</p>

          </div>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">Contact Information</div>
          <div class="container">
            <p><b>Principal Investigator (PI)</b><br />
            The Principal Investigator or a member of the research staff will be available to answer any questions throughout this study.
            </p>
            <p>
              Principal Investigator: Rachel Markwald, Ph.D. <br />
              Phone: (619) 767-4494  <br />
              Mailing Address: 140 Sylvester Rd., San Diego, CA 92106-3521 <br />
              Email: rachel.r.markwald.civ@mail.mil <br />
            </p>
            <p>
            <b>Institutional Review Board (IRB) Office</b><br />
            If you have any questions about your rights as a research participant or if you have concerns or complaints about the research study, please contact the IRB Chair, Lt Col Patricia Rohrbeck, at:<br/>
            Phone: 619-553-8424
            <a href="mailTo:USN.NHRC.IRB@mail.mil">USN.NHRC.IRB@mail.mil</a>
            </p>
            <p>IF THERE IS ANY PORTION OF THIS DOCUMENT THAT YOU DO NOT UNDERSTAND, ASK THE INVESTIGATOR BEFORE SIGNING. YOU MAY CONSULT WITH YOUR PERSONAL PHYSICIAN OR LEGAL ADVISOR, IF YOU WISH.</p>
            <p>A signed and dated copy of this document will be given to you.</p>
          </div>
        </div>
        `
      }
  ]
});
