export const pssuqDef = {
    "name": "pssuq",
    "description": "Post-Study System Usability Questionnaire",
    "instructions": null,
    "questions": [
    /** moved to db
      {
        "id": 0,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "Overall, I am satisfied with how easy it is to use COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ01"
      },
      {
        "id": 1,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "It was simple to use COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ02"
      },
      {
        "id": 2,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "I was able to complete the tasks and scenarios quickly using COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ03"
      },
      {
        "id": 3,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "I felt comfortable using COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ04"
      },
      {
        "id": 4,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "It was easy to learn to use COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ05"
      },
      {
        "id": 5,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "I believe I could become productive quickly using COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ06"
      },
      {
        "id": 6,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "COAST gave error messages that clearly told me how to fix problems.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ07"
      },
      {
        "id": 7,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "Whenever I made a mistake using COAST, I could recover easily and quickly.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ08"
      },
      {
        "id": 8,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "The information (such as online help, on-screen messages, and other documentation) provided with COAST was clear.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ09"
      },
      {
        "id": 9,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "It was easy to find the information I needed.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ10"
      },
      {
        "id": 10,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "The information was effective in helping me complete the tasks and scenarios.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ11"
      },
      {
        "id": 11,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "The organization of information on COAST screens was clear.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ12"
      },
      {
        "id": 12,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "The interface of COAST was pleasant.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ13"
      },
      {
        "id": 13,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "I liked using the interface of COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ14"
      },
      {
        "id": 14,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "COAST has all the functions and capabilities I expect it to have.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ15"
      },
      {
        "id": 15,
        "questionText":
          "On a scale between Strongly Agree to Strongly Disagree, please rate the following statements about the COAST platform:",
        "questionEmphasis": "Overall, I am satisfied with COAST.",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "1 - Strongly Agree", "value": 1 },
          { "display": "2", "value": 2 },
          { "display": "3", "value": 3 },
          { "display": "4", "value": 4 },
          { "display": "5", "value": 5 },
          { "display": "6", "value": 6 },
          { "display": "7 - Strongly Disagree", "value": 7 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PSSUQ16"
      }
      **/
    ]
  };
  