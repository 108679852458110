import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const eveningLog: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'routine-start',
    unit: UnitType.MorningTime,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'ROUTINE_START'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'had-caffeinated-drinks',
    unit: UnitType.YesNo,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'CAFFEINATED_DRINKS',
    defaultValueForEmpty: 0
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'caffeinated-drinks-before-lunch',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'CAFFEINATED_BEFORE_LUNCH',
    defaultValueForEmpty: 0
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'caffeinated-drinks-before-dinner',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'CAFFEINATED_BEFORE_DINNER'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'caffeinated-drinks-after-dinner',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'CAFFEINATED_AFTER_DINNER'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'had-alcoholic-drinks',
    unit: UnitType.YesNo,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'ALCOHOL_DRINKS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'alcoholic-drinks-before-lunch',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'ALCOHOL_MORNING',
    defaultValueForEmpty: 0
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'alcoholic-drinks-before-dinner',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'ALCOHOL_AFTERNOON',
    defaultValueForEmpty: 0
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'alcoholic-drinks-after-dinner',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'ALCOHOL_EVENING',
    defaultValueForEmpty: 0
  }),




  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'took-naps',
    unit: UnitType.YesNo,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'NAPS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'number-of-naps',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'NAPS_NUMBER',
    defaultValueForEmpty: 0
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'naps-duration',
    unit: UnitType.Number,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.EveningLog,
    answerId: 'NAPS_DURATION',
    defaultValueForEmpty: 0
  })
  //#endregion
];
