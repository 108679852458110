import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { sleepAndHighTempoOps } from '../questions/sleep-and-high-tempo-ops/sleep-and-high-tempo-ops';

export const sleepAndHighTempoOpsDef: AssetDefinition = {
  id: 7,
  name: 'sleep-and-high-tempo-ops',
  label: 'Sleep and High Tempo Ops',
  categories: ['ops-help'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    sleepAndHighTempoOps
  ],
  url: 'sleep-and-high-tempo-ops'
};
