import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;
declare var require: any;
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { pipe, Subject } from 'rxjs';
import { ApplicationStateService, ChatStateService} from '@noctem/web';
import { UserStateService } from '../../../../../../noctem-lib/src/lib/state';
import { filter, takeUntil, take } from 'rxjs/operators';
import { APPLICATION_ORGANIZATION } from '../../../../../../noctem-lib/src/constants/constants';
import { GroupService } from '../../../../../../noctem-lib/src/lib/services';
import { BugReportDialogComponent } from '../../routes/patient/patient-page/dialog/bug-report-dialog/bug-report-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import moment from 'moment';
import { TermsDialog } from '../../routes/patient/patient-page/dialog/terms-dialog/terms-dialog.component';
import { userAgreements } from '../../../../../..//noctem-lib/src/constants/definitions/user-agreements';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    route:any;
    isNavSearchVisible: boolean;
    isNavIconVIsible: boolean;
    @ViewChild('fsbutton' , {static:false}) fsbutton;  // the fullscreen button
    user:any = null;
    isResearch:boolean = false;
    isConsentRequired:boolean = false;
    isOffHours:boolean = false;
    dialogRef: any;
    destroy$: Subject<any> = new Subject();

    constructor(
        public menu: MenuService,
        public settings: SettingsService,
        private userStateService:UserStateService,
        private groupsService: GroupService,
      private dialog: MatDialog,
        public applicationStateService?: ApplicationStateService,
        public chatStateService?: ChatStateService,
        private router?: Router,
      private location?: Location
    ) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0,4); // for horizontal layout
        router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            if(location.path() === '/home'){
                this.isNavIconVIsible = true;
            } else {
                this.isNavIconVIsible = false;
            }
          });

    }

    ngOnInit() {
      this.userStateService.state$.pipe(
        takeUntil(this.destroy$),
        filter((userState: any) => userState.User)
        ).subscribe(userState => {
        this.user = (userState as any).User;
        this.isOffHours = this.isClinicianUnavailable(this.user);
        const userGroup = userState.User.groups.find(g => g.display?.toLowerCase() !== APPLICATION_ORGANIZATION.name && g.Name?.toLowerCase() !== APPLICATION_ORGANIZATION.name);
        this.groupsService.getAllAsync().then(groups => {
          const group = groups.find(g => g.__i.guid === (userGroup as any)._id || g.__i.guid === (userGroup as any).id);
          this.isResearch = group?.isResearch;

          // In the event that the consentId DNE, do not present the clinician with a blank modal.
          const clinicianConsent = userAgreements.ClinicianConsents.find(c => c.id === group.consentId);
          this.isConsentRequired = (clinicianConsent && group?.isConsentRequired) ? true : false;
        });
      });
        this.isNavSearchVisible = false;
        if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }
    }



    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        }
        else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }

  openManual(): void {
    window.open('../../../assets/docs/UserManual.pdf', '_blank');
  }

    goToChangePassword() {
        this.router.navigateByUrl('/user/password');
    }

    goToConsent(){
      window.open('/print-consent/' + this.user?.applicationData?.consentId);
    }

    public openTermsDialog() {
      this.dialogRef = this.dialog.open(TermsDialog, {
        data: {}
      });
      this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      });
    }

    openPdf(filename){
        let path = "/assets/docs/"+filename
        window.open(path,"_blank");
    }

  public openBugReportDialog() {
    this.dialogRef = this.dialog.open(BugReportDialogComponent, {
      width: '550px'
    });
    this.dialogRef.afterClosed().pipe(take(1)).subscribe();
  }
  
  interval = setInterval(() =>{
    if(this.user){
      this.userStateService.getCurrentUser();
      this.user = this.userStateService.model.get().User;
      this.isOffHours = this.isClinicianUnavailable(this.user);
    }
  }, 60 * 1000);

  private isClinicianUnavailable(clincian: any):boolean{
    if(clincian.unavailability && clincian.unavailability.length>0){
      if(!clincian.timeZoneOffset){
        clincian.timeZoneOffset = moment().utcOffset();
      }
      const now = moment.utc().add(clincian.timeZoneOffset,"m");
      const day = now.day();
      let unavail = false;
      clincian.unavailability.filter(event => event.days[day]).some(event =>{
        const startH = parseInt(event.startTime.slice(0,2));
        const startM = parseInt(event.startTime.slice(3,5));
        const endH = parseInt(event.endTime.slice(0,2));
        const endM = parseInt(event.endTime.slice(3,5));
        const nextDay = startH > endH || (startH == endH && startM > endM) && endH!=0;

        if(event.startTime==event.endTime){
          unavail = true;
          return unavail;
        }

        let start = moment.utc().add(clincian.timeZoneOffset,"m");;
        start.hour(startH);
        start.minute(startM);
        start.second(0); start.millisecond(0);

        let end = moment.utc().add(clincian.timeZoneOffset,"m");
        if(nextDay)
          end.add(1,"d");
        end.hour(endH);
        end.minute(endM)
        end.second(0); end.millisecond(0);
        unavail = now.isSameOrAfter(start) && now.isSameOrBefore(end);
        return unavail;
      });
      return unavail
    }
    else{
      return false; //default; 
    }
  }

  ngOnDestroy() {
    this.dialogRef?.close();
    this.destroy$.next();
    this.destroy$.complete();
    if (this.interval) {
      clearInterval(this.interval);
    }
 }
}
