import { Component, OnInit, OnChanges, Input, Output, EventEmitter, Inject } from '@angular/core';
import {  PatientStateService, UserStateService } from '../../../../../../../noctem-lib/src/lib/state';
import _ from 'lodash';
import { FormControl } from '@angular/forms';
import { USER_STATE_SERVICE, SearchService } from '@noctem/web';
import { PatientState } from '../../../../../../../noctem-lib/src/lib/state/PatientState';
import { APPLICATION_ORGANIZATION } from '../../../../../../../noctem-lib/src/constants/constants';


// TODO: This needs even more generalized.  This is a little bit of a hack right now.


@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    styles:[
      `::ng-deep .mat-autocomplete-panel {
        max-height:40vh !important;
      }`
    ],
    styleUrls: [
      "./auto-complete.component.scss"
    ]
})
export class AutoCompleteComponent implements OnInit, OnChanges{
    users: any;
    @Input() filterFields: string[];
    @Input() context: string;
    @Input() searchStyle: string;

    @Output() itemSelected = new EventEmitter<any>();
    public searchControl = new FormControl();
    items: any[] = [];
    public filteredUsers: any[];

    constructor(@Inject(USER_STATE_SERVICE) private userStateService: UserStateService, private patientStateService: PatientStateService) {
    }

    ngOnInit() {
        this.getUsers();
    }

    async getUsers() {
        // DR:  I really want to change this to query records on demand as opposed to loading all users up front
        // However, I need to lay some groundwork to do that effectively and still retain the ability to search across multiple fields.
        const user = this.userStateService.model.get() as any;
        if (user.User) {
            const groups = user.User.groups;
            const orQuery = [];
            groups.map(g => {
              if (g._id !== APPLICATION_ORGANIZATION._id) {
                orQuery.push(
                  {
                    $and:[
                      {
                        'Payload.groups':
                          {
                            $elemMatch: { id: g._id}
                          }
                      },
                      { 'Payload.pin': {$ne: null} },
                      { 'Payload.profile.isActive': {$ne: false } }
                  ]
                }
                );
              }
            });

            const query = {
                $or: orQuery
            };

            this.patientStateService.getPatients(query, Number.MAX_SAFE_INTEGER).then(u => {
                this.users = u;
            });

        }
    }

    optionClicked(option: any): void {
        this.searchControl = new FormControl();
        this.itemSelected.emit({value: option});
    }

    ngOnChanges(changes: any) {
        this.items = this.users;
    }


    searchBlur(event: Event): void {
      const searchString = this.searchControl.value.toUpperCase();

      if (this.filterFields && this.users) {
        const fields = this.filterFields.slice(); // create a new reference
        this.filteredUsers = [];

        for (let i = 0; i < this.users.length; i++) {
          let matchValue = '';
          fields.map(f => {
              matchValue += ' ' + this.getMatchValue(this.users[i], f);
          });

          if (matchValue?.toUpperCase().indexOf(searchString) !== -1) {
              this.filteredUsers.push(this.users[i]);
          }
        }
        this.filteredUsers = this.filteredUsers.sort(
          (a, b) => {
            if (a.profile.firstName > b.profile.firstName) {
              return 1;
            } else {
              return -1;
            }
          });
      }
    }

    getMatchValue(obj: any, field: string): string {
        if (!obj) { return ''; }

        if (field.indexOf('.') === -1 && field in obj) {
          return obj[field];
        } else {
          return this.getMatchValue(obj[field.split('.')[0]], field.substring(field.indexOf('.') + 1))?.toUpperCase();
        }
    }
}
