
const Home = {
    text: 'Roll Call',
    link: '/home',
    icon: 'icon-home'
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

export const menu = [
    headingMain,
    Home,
    {
        text: 'Operations',
        heading: true
    },
    // {
    //     text: 'Calendar',
    //     link: '/calendar',
    //     params: { },
    //     icon: 'icon-calendar',
    // },
    {
        text: 'Secure Chat',
        link: '/secure_chat',
        params: {},
        icon: 'icon-envelope',
        indicator: 'unread_messages'
    },
    {
        text: "Clinician Mgmt",
        link:'/user/listing',
        params:{},
        icon:'icon-people',
        isAdmin:true
    },
    {
        text: "User Settings",
        link:'/user/settings',
        baseLink: '/user/settings',
        params:{},
        icon:'icon-settings'
    }
    /*headingComponents,
    Material,
    Elements,
    Forms,
    Charts,
    Tables,
    Maps,
    headingMore,
    Pages,
    Widgets,
    Blog,
    Ecommerce,
    Extras*/
];
