import { Component, OnInit, Inject, Injector, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { UserStateService, NoctemUser, UserState, ChatStateService } from '@noctem/web';
import { find as _find } from 'lodash';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Gtag } from 'angular-gtag';
import { Subject } from 'rxjs';
import { USER_STATE_SERVICE, CLIENT_ID } from '@noctem/web';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser: NoctemUser;
  menuItems: Array<any>;
  unreadMessages: Array<any> = [];
  rawMessages: Array<any> = [];
  router: Router;
  sbclickEvent = 'click.sidebar-toggle';
  $doc: any = null;
  public isNoctemUser: boolean = false;
  destroy$: Subject<any> = new Subject();

  constructor(
    public menu: MenuService,
    public settings: SettingsService,
    public injector: Injector,
    public chatStateService: ChatStateService,
    private gtag: Gtag,
    @Inject(USER_STATE_SERVICE) private userStateService?: UserStateService,
  ) {
    this.menuItems = menu.getMenu(); 
  }

  ngOnInit() {
    const self = this;
    this.userStateService.initialize();
    this.userStateService.state$
      .pipe(
        // filter(userState => !!userState.User),
        takeUntil(this.destroy$)
      )
      .subscribe(userState => {
        if (userState.User) {
        const currentUser = (userState.User as any) as NoctemUser;
        // Do not rewrite over the link property when building the dynamic route link for /user/settings/<id>. Instead, add a baseLink property then modify link to match that plus the appended userId of the logged-in clinician.
        this.menuItems[this.menuItems.length-1].link = this.menu.getMenu()[this.menuItems.length-1].baseLink + "/" + currentUser.UserId;
        this.isNoctemUser =
          currentUser.profile.emailAddress.toLowerCase().indexOf('@noctemhealth.com') !== -1;
        this.chatStateService.setupRecipients();
        }
      });

    this.router = this.injector.get(Router);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(val => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.setLayoutSetting('asideToggled', false);
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();
  }

  ngAfterViewInit() {
    const self = this;

    this.chatStateService.state$.pipe(takeUntil(this.destroy$)).subscribe(chatState => {
      self.unreadMessages = chatState.unreadMessages || [];
      self.rawMessages = chatState.rawMessages || [];
    });
  }

  renderIndicator(item) {
    if (item && item.indicator === "unread_messages") {
      let unreadMessageCount = this.unreadMessages ? this.unreadMessages.length : 0;
      let messageCount = unreadMessageCount + this.rawMessages.length;
      return messageCount > 0 ? messageCount : "";
    }
    return 0;
  }
  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, e => {
      if (!$(e.target).parents('.aside-container').length) {
        this.settings.setLayoutSetting('asideToggled', false);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.$doc) this.$doc.off(this.sbclickEvent);
  }

  toggleSubmenuClick(event, item) {
    event.preventDefault();

    this.gtag.event('click', {
      'event_label' : 'sidebar ' + item.text + ' clicked'
    });

    if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
      let target = $(event.target || event.srcElement || event.currentTarget);
      let ul,
        anchor = target;

      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a').first();
      }
      ul = anchor.next();

      // hide other submenus
      let parentNav = ul.parents('.sidebar-subnav');
      $('.sidebar-subnav').each((idx, el) => {
        let $el = $(el);
        // if element is not a parent or self ul
        if (!$el.is(parentNav) && !$el.is(ul)) {
          this.closeMenu($el);
        }
      });

      // abort if not UL to process
      if (!ul.length) {
        return;
      }

      // any child menu should start closed
      ul.find('.sidebar-subnav').each((idx, el) => {
        this.closeMenu($(el));
      });

      // toggle UL height
      if (parseInt(ul.height(), 0)) {
        this.closeMenu(ul);
      } else {
        // expand menu
        ul.on('transitionend', () => {
          ul.height('auto').off('transitionend');
        }).height(ul[0].scrollHeight);
        // add class to manage animation
        ul.addClass('opening');
      }
    }
  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.height(elem[0].scrollHeight); // set height
    elem.height(0); // and move to zero to collapse
    elem.removeClass('opening');
  }

  toggleSubmenuHover(event) {
    let self = this;
    if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
      event.preventDefault();

      this.removeFloatingNav();

      let target = $(event.target || event.srcElement || event.currentTarget);
      let ul,
        anchor = target;
      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a');
      }
      ul = anchor.next();

      if (!ul.length) {
        return; // if not submenu return
      }

      let $aside = $('.aside-container');
      let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
      let $sidebar = $asideInner.children('.sidebar');
      let mar =
        parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
      let itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

      let floatingNav = ul.clone().appendTo($aside);
      let vwHeight = $(window).height();

      // let itemTop = anchor.position().top || anchor.offset().top;

      floatingNav
        .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
        .addClass('nav-floating')
        .css({
          position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
          top: itemTop,
          bottom: floatingNav.outerHeight(true) + itemTop > vwHeight ? 0 : 'auto'
        });

      floatingNav
        .on('mouseleave', () => {
          floatingNav.remove();
        })
        .find('a')
        .on('click', function (e) {
          e.preventDefault(); // prevents page reload on click
          // get the exact route path to navigate
          let routeTo = $(this).attr('route');
          if (routeTo) self.router.navigate([routeTo]);
        });

      this.listenForExternalClicks();
    }
  }

  listenForExternalClicks() {
    let $doc = $(document).on('click.sidebar', e => {
      if (!$(e.target).parents('.aside-container').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }
  isEnabledHover() {
    return this.settings.getLayoutSetting('asideHover');
  }
}
