import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStateService } from '@noctem/web';

@Injectable()
export class SimpleHttpInterceptor implements HttpInterceptor {
    constructor(
        private applicationStateService: ApplicationStateService,
        private router: Router,
    ) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addAuthentication(req);
        return next.handle(req).pipe(catchError((err) => {
            if (err.status === 401 || err.status === 403) {
                // Clear localstorage
                localStorage.clear();

                // Strip parameters from this.router.url
                const urlPath = this.router.url.split("?")[0];
                if(urlPath != "/" && urlPath != "/login") {
                    this.applicationStateService.logout("Your session has expired. Please login again.");
                }
            }
            return observableThrowError(err);
        }));
    }

    // here you add the bearer in every request
    addAuthentication(req: HttpRequest<any>): HttpRequest<any> {
        if (req.url.indexOf('session') < 0) {
            const headers: any = {};

            
            // Create config object to pass to $http which sets header
            const authHeader = localStorage.getItem('token');
            if (authHeader) {
              headers['Authorization'] = authHeader; // add it to the header
              req = req.clone({
                setHeaders: headers
              });
            }
        }
        
        return req;
    }
}
