import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const controlJetLag: AssessmentDefinition = {
  name: 'control-jet-lag',
  description: 'Control Jet Lag',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Controlling jet lag',
      questionEmphasis: ``,
      subText: `<p>Jet lag is a temporary sleep disturbance that occurs because you have travelled across several time zones and  your internal biological clock hasn’t yet adjusted to the new local time.</p>
                <div><p><img src='./assets/img/module-images/control-jet-lag/airplane.png'/></div>
                <h5>Signs of Jet Lag</h5>
                <div class='call-out'>Difficulty falling and staying asleep</div>
                <div class='dark-call-out'>Waking too early in the morning</div>
                <div class='call-out'>Daytime sleepiness</div>
                <div class='dark-call-out'>Digestive problems</div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Use the following three quick tips to accelerate your internal clock's adjustment to your new local time</p>
                <div class='call-out call-out-card top-card'>
                <h5 class='module'>Tip 1</h5>
                In transit to your destination, start operating on your new, destination time
                </div>
                <div class='dark-call-out call-out-card bottom-card'>
                Increase exposure to bright light during your destination time day<br><br>
                Limit exposure to bright light during your destination time night. You can do this by wearing blue-light blocking glasses, adjusting brightness settings on your devices, covering windows.
                </div>
                <div class='call-out call-out-card top-card'>
                <h5 class='module'>Tip 2</h5>
                When you arrive at your destination, don't nap. Stay up until your destination bedtime.
                </div>
                <div class='dark-call-out call-out-card bottom-card'>
                If you arrive in the evening, engage in relaxing activities in low light.<br><br>
                If you arrive in the morning, get active! Doing activities in bright light can help to create energy.
                </div>
                <div class='call-out call-out-card top-card'>
                <h5 class='module'>Tip 3</h5>
                Use Caffeine Strategically
                </div>
                <div class='dark-call-out call-out-card bottom-card'>
                Use 200 mg of caffeine every 4 hours as needed to support alertness.<br><br>
                Don't use any caffeine at least 6 hours before your intended bedtime.<br><br>
                Caffeine too close to bedtime can make it difficult to fall asleep.
                </div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    }
  ]
};
