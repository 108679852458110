export const essDef = {
  name: 'ess',
  description: 'Epworth Sleepiness Scale',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Sitting and reading?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_READ'
    },
    {
      id: 1,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Watching TV?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_TV'
    },
    {
      id: 2,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Sitting inactive in a public place (e.g., theater or meeting)?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_PUBLIC'
    },
    {
      id: 3,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'As a passenger in a car for an hour without a break?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_CAR'
    },
    {
      id: 4,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Lying down to rest in the afternoon when circumstances permit?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_AFTERNOON'
    },
    {
      id: 5,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Sitting and talking to someone?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_TALK'
    },
    {
      id: 6,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'Sitting quietly after a lunch without alcohol?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_LUNCH_NO_ALOCHOL'
    },
    {
      id: 7,
      questionText:
        'In the past month, how likely are you to fall asleep in the following situation:',
      questionEmphasis: 'In a car, while stopped for a few minutes in traffic?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Would never doze', value: 0 },
        { display: 'Slight chance of dozing', value: 1 },
        { display: 'Moderate chance of dozing', value: 2 },
        { display: 'High chance of dozing', value: 3 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ESS_SLEEP_TRAFFIC'
    }
  ]
};
