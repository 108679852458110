import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserStateService } from '../../../../../../../../../noctem-lib/src/lib/state';
import { MessageService } from '../../../../../../../../../noctem-lib/src/core/services/MessageService';
import { USER_STATE_SERVICE } from '@noctem/web';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'bug-report-dialog',
  styles: [],
  templateUrl: './bug-report-dialog.component.html'
})
export class BugReportDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<BugReportDialogComponent>,
    private messageService: MessageService,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      bugDescription: ['', [Validators.required, Validators.maxLength(255)]]
    });
  }

  async save() {
    const sender = this.userStateService.model.get().User;
    await this.messageService.sendBugReport(
      sender.profile.emailAddress,
      this.form.value.bugDescription
    );
    alert('Thanks for letting us know! We appreciate you taking the time to notify us of your issue and will get back to you ASAP.');
    this.dialogRef.close();
  }
}
