import { Injectable } from '@angular/core';
import { CalculationService } from '../services/calculation.service';
import moment from 'moment';
import { ThresholdIndices } from '../../../../aire/constant-definitions/modules/thresholds/threshold-indices';
import { AssessmentFieldDefinition, UnitType } from '../../core/contracts/models.calculations';
import { AssessmentQuestion } from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private calculationsService: CalculationService) {}

  // get assessment values
  // get thresholds

  public async getChartStatisticsByThresholds(fields: Array<any>,
                                              userIds: Array<string>,
                                              startDate: moment.Moment,
                                              endDate: moment.Moment,
                                              useLowestThresholdValue: boolean): Promise<Map<string, Array<Array<number>>>> {
    const data = await this.calculationsService.getChartDataForThresholds(startDate, endDate, fields.map(f => f.name), Array.from(userIds), true);
    let returnMap = new Map<string, any>();
    if(!useLowestThresholdValue) {

      data.forEach((patientMaps, fieldName) => {
        if(fieldName === "radar-count"){ //to get radar count
          returnMap.set(fieldName, patientMaps.average);
        }
        else if(patientMaps.data == null) {
          returnMap.set(fieldName, null);
        } else {
          const thresholdsForField = this.calculationsService.calculationsDefinitions
                                              .ThresholdDefinitions.find(def => def.name === fieldName)?.thresholds;

          const fieldTemplate = this.calculationsService.getAssessessmentFieldTemplate(fieldName);
          if(fieldTemplate.unit === UnitType.Multi) {
            const assessmentQuestionsTemplate = this.calculationsService.calculationsDefinitions.AssessmentQuestions.find(a => a.name === (fieldTemplate as AssessmentFieldDefinition).assessmentType);
            const question = assessmentQuestionsTemplate.questions.find(q => q.name === fieldTemplate.name);
            returnMap.set(fieldName, this.getMultiTextValues(question, patientMaps.data));
          }
          else {
            returnMap.set(fieldName, this.getThresholdValues(fields.find(f => f.name === fieldName), patientMaps, thresholdsForField, endDate.diff(startDate, 'days')));
          }
        }
      });
    } else {
      returnMap.set('lowestThreshold', this.getLowestThresholdValues(data, endDate.diff(startDate, 'days')));
    }

    return Promise.resolve(returnMap);
  }

  private getLowestThresholdValues(data: Map<string, {average: number, data:Map<string, Array<number>>}>, daysDifference: number){
    const tempMap = new Map<string, Array<number>>();
    // field
    data.forEach((patientMap, fieldName) => {
      const thresholdsForField = this.calculationsService.calculationsDefinitions
      .ThresholdDefinitions.find(def => def.name === fieldName)?.thresholds;

      patientMap.data.forEach((valMap, patientId) => {
        // value for each date
        let valIndex = 0;
        valMap.forEach((val) => {
          if(val != null) {
            const thresholdValue = this.getThresholdValueForField(thresholdsForField, val);
            if(!tempMap.has(patientId)) {
              const newArray = new Array<number>(daysDifference).fill(null);
              newArray[valIndex] = thresholdValue;
              tempMap.set(patientId, newArray);
            } else {
              if(tempMap.get(patientId)[valIndex] === null || thresholdValue < tempMap.get(patientId)[valIndex]) {
                tempMap.get(patientId)[valIndex] = thresholdValue;
              }
            }
          }
          valIndex++;
        });
      });
    });

    // Now have a Map of type PatientId => Array of lowest threshold value of each date
    const patientArray = Array.from(tempMap);
    const returnArray = [Array<number>(daysDifference).fill(0),
                         Array<number>(daysDifference).fill(0),
                         Array<number>(daysDifference).fill(0)];
    for(let i = 0; i < patientArray.length; i++) {
      const patArray = patientArray[i][1];
      for(let x = 0; x < patArray.length; x++) {
        if(patArray[x] != null)
          returnArray[patArray[x]][patArray.length - x - 1]++;
      }
    }
    return returnArray;
  }



  private getMultiTextValues(fieldQuestionsTemplate: AssessmentQuestion, data: Map<string, Array<any>>): Array<{label:string, value:number, count:number}>{
    const returnArray = new Array<{label:string, value:number, count:number}>();
    fieldQuestionsTemplate.answerOptions.forEach(opt => {
      returnArray.push({
        label: opt.display,
        value: opt.value,
        count: 0
      });
    })
    data?.forEach((valArray) => {
      valArray.forEach((value) => {
        if(value != null) {
          (value as Array<any>).forEach(v => {
            const entry = returnArray.find(a => a.value === v.value);
            if(entry && v.isChecked === true) {
              entry.count++;
            }
          });
        }
      });
    });
    return returnArray;
  }

  private getThresholdValues(field: {name:string, average:boolean}, data: {average: number, data:Map<string, Array<number>>}, thresholdsForField: any, daysDifference:number): Array<Array<number>> {

    let returnArray = new Array<any>();
    if(field.average){
      returnArray.push([this.getThresholdValueForField(thresholdsForField, data.average)]);
    } else {
      returnArray = new Array<Array<number>>();
      Object.keys(ThresholdIndices).forEach(key => {
        returnArray.push(new Array<number>(daysDifference + 1).fill(0));
      });
      // patient id is first
      data.data.forEach((valArray, patientId) => {
        valArray.forEach((value, index) => {
          if (value != undefined) {
            Object.keys(ThresholdIndices).forEach(key => {
              const thresholdValueIndex = ThresholdIndices[key].index;
              const thresholdValue = this.getThresholdValueForField(thresholdsForField, value);
              if (thresholdValue === thresholdValueIndex) {
                returnArray[thresholdValueIndex][valArray.length - index - 1]++;
              }
            });
          }
        });
      });
    }



    return returnArray;
  }

  private getThresholdValueForField(thresholdsForField, value): number {
    let thresholdValue = 0;
    if(typeof value === 'string') { value = Number.parseInt(value);}
    for (const threshold of thresholdsForField) {

      if (value >= threshold.minValue) {
        thresholdValue = threshold.threshold.index;
      }
    }
    return thresholdValue;
  }

  public countData(subTab, data):number {
    let count = 0;
    if(subTab.chartType === 'radar') {
      count = data.get('radar-count');
    } else if(subTab.chartType === 'barriers'){
      for(let val of data.values()) {
        (val as Array<any>)?.forEach(item => {
          if(item.count > count) {
            count = item.count;
          }
        });
      }
    } else {
      Array.from(data).forEach(d => {
        const field = subTab.fields.find(f => f.name === d[0]);
        if(field && d && d[1] != null) {
          count = d[1][0]?.reduce((a, b) => a + b, 0)+d[1][1]?.reduce((a, b) => a + b, 0)+d[1][2]?.reduce((a, b) => a + b, 0);
        }
      });
    }
    return count;
  }

  private sumMultiField(array) {
    let count = 0;
    array.forEach(a => count += a.count);
    return count;
  }

  private sum2DArray(array):number{
    return array.reduce((sum, num) => sum + (Array.isArray(num) ? this.sum2DArray(num) : num * 1), 0);
  }

  public async getIndividualLowestThresholdValues(fields: Array<any>,
                                              userIds: Array<string>,
                                              startDate: moment.Moment,
                                              endDate: moment.Moment): Promise<{ pieChart: Array<number>, barCharts: Array<Array<number>>}> {
    const numberOfThresholdKeys = Object.keys(ThresholdIndices).length;
    const pieArray = new Array<number>(numberOfThresholdKeys).fill(0);
    const barArray = [[0,0,0], [0,0,0], [0,0,0]];
    const soldierArray = new Array<number>(userIds.length).fill(null);
    const data = await this.calculationsService.getChartDataForThresholds(startDate, endDate, fields.map(f => f.name), Array.from(userIds), true, false);
    fields.forEach((field, fieldIndex) => {
      const soldierMaps = data.get(field.name);
      const thresholdsForField = this.calculationsService.calculationsDefinitions
                                          .ThresholdDefinitions.find(def => def.name === field.name)?.thresholds;

      userIds.forEach((soldierId, index) => {
        const soldierData = soldierMaps.data.get(soldierId);
        let worstThresholdValue = null;
        soldierData.forEach(dataPoint => {
          if(dataPoint != null) {
            const thresholdValue = this.getThresholdValueForField(thresholdsForField, dataPoint);
            if(soldierArray[index] === null || thresholdValue > soldierArray[index]) {
              soldierArray[index] = thresholdValue;
            }
            if(worstThresholdValue === null || thresholdValue > worstThresholdValue) {
              worstThresholdValue = thresholdValue;
            }
          }
        });
        if(worstThresholdValue !== null) {
          barArray[numberOfThresholdKeys - worstThresholdValue - 1][fieldIndex]++;
        }
      });
    });
    soldierArray.forEach(s => {
      pieArray[s]++;
    });
    return {pieChart:pieArray, barCharts: barArray};
  }

}
