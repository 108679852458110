export const cgiDef = {
  name: 'cgi',
  description: 'Clinician Global Impressions',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText:
        'Considering your total clinical experience with this patient population, how severe are this particular patient’s sleep problems at this this time?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not assessed', value: 0 },
        { display: 'Normal, no sleep problems', value: 1 },
        { display: 'Borderline sleep problems', value: 2 },
        { display: 'Mild sleep problems', value: 3 },
        { display: 'Moderate sleep problems', value: 4 },
        { display: 'Marked sleep problems', value: 5 },
        { display: 'Severe sleep problems', value: 6 },
        { display: 'Among the most extremely severe sleep problems', value: 7 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ILLNESS'
    },
    {
      id: 1,
      questionText:
        'Rate the overall total improvement whether or not, in your judgement, it is due to the sleep intervention.  Compared to your patient’s condition at the start of the intervention, how much has s/he changed?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not assessed', value: 0 },
        { display: 'Very much improved', value: 1 },
        { display: 'Much improved', value: 2 },
        { display: 'Minimally improved', value: 3 },
        { display: 'No change', value: 4 },
        { display: 'Minimally worse', value: 5 },
        { display: 'Much worse', value: 6 },
        { display: 'Very much worse', value: 7 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'GLOBAL'
    },
    {
      id: 2,
      questionText:
        // eslint-disable-next-line quotes
        "Select the terms that best described the degree of change and side effects relative to the sleep intervention and select the number where the 2 intersect. Example: Therapeutic effect is rated as 'Moderate' and side effect are judged 'Do not significantly interfere with patient's functioning.' Select 06.",
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: '00', value: 0 },
        { display: '01', value: 1 },
        { display: '02', value: 2 },
        { display: '03', value: 3 },
        { display: '04', value: 4 },
        { display: '05', value: 5 },
        { display: '06', value: 6 },
        { display: '07', value: 7 },
        { display: '08', value: 8 },
        { display: '09', value: 9 },
        { display: '10', value: 10 },
        { display: '11', value: 11 },
        { display: '12', value: 12 },
        { display: '13', value: 13 },
        { display: '14', value: 14 },
        { display: '15', value: 15 },
        { display: '16', value: 16 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'EFFICACY'
    },
    {
      id: 3,
      questionText: 'Comment',
      questionType: 'text',
      autofill: false,
      answerOptions: [],
      showNotApplicableHint: true,
      uniqueAnswerId: 'COMMENT',
      conditionalQuestions: []
    }
  ]
};
