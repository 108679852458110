import { Component, OnInit, OnDestroy, Input, SimpleChanges, AfterViewInit, Output, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ManagePatientDialog } from '../manage-patient-dialog/manage-patient-dialog.component';
import { ReportDialogComponent } from '../report-dialog/report-dialog.component';
import { PatientStateService, UserFuncService } from '@noctem/web';
import { TermsDialog } from '../terms-dialog/terms-dialog.component';
import { ConfirmDeleteDialog } from '../confirm-delete-dialog/confirm-delete-dialog.component';

import { AddCheckinDialog } from '../add-check-in-dialog/add-check-in-dialog.component';
import { ThreadMessageDialog } from '../../../../../../../../../noctem-lib/src/lib/components/dialogs/thread-message-dialog/thread-message-dialog.component';
import { ConsentDialog } from '../consent-dialog/consent-dialog.component';
import { Subject } from 'rxjs';
import { UserStateService } from '../../../../../../../../../noctem-lib/src/lib/state';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'patient-dialog',
  styleUrls: ['../../patient-page.component.scss'],
  templateUrl: './patient-dialog.component.html'
})
export class PatientDialogComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() modalType;
  @Input() isEditWeek = false;
  @Input() recipientId;
  @Input() recipientName;
  @Input() isOpen: boolean = null;
  @Input() defaultMessageDialogToOpen;
  @Input() EULAAgreed = false;
  @Input() eventToDelete;
  public dialogRef;
  public onDialogClosed$: Subject<any> = new Subject<any>();
  public reportDialogIsOpen = false;
  public messageDialogIsOpen = false;

  ngOnChanges(changes: SimpleChanges) {
    if ((this.modalType === 'consent' || this.modalType === 'EULA') && changes.isOpen
      && changes.isOpen.currentValue === true &&  changes.isOpen.previousValue !== true) {
      this.openConsentDialog();
    }
  }

  constructor(
    public dialog: MatDialog,
    public stateService: PatientStateService,
    public userStateService: UserStateService,
    public userService: UserFuncService,
    private gtag: Gtag
    ) { }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.dialogRef?.close();
  }
  onNotificationChange(event, userId) {
    this.userService.updateUser(userId, {
      diaryReminderFrequency: (event.target as HTMLSelectElement).value
    }).subscribe(updateUser => {
      this.stateService.loadPatientProfile(userId);
    });
  }

  ngAfterViewInit() {
    this.stateService.state$.subscribe(() => {
      if (this.defaultMessageDialogToOpen &&
          this.recipientId &&
          !this.messageDialogIsOpen) {
        this.openThreadDialog();
      }
    });
  }

  public openEditDialog() {
    this.dialogRef = this.dialog.open(ManagePatientDialog, {
      width: '900px',
      data: { 
        isExistingUser: true,
        recipientId: this.recipientId 
      },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  public openAddDialog() {
    this.dialogRef = this.dialog.open(ManagePatientDialog, {
      width: '750px',
      data: {},
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  public openCheckinDialog() {
    const dialogRef = this.dialog.open(AddCheckinDialog, {
      width: '750px',
      height: '95vh',
      data: {}
    });
    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  public openTermsDialog() {
    this.dialogRef = this.dialog.open(TermsDialog, {
      width: '900px',
      height: '95vh',
      data: {}
    });
    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  public openConsentDialog() {
    this.dialogRef = this.dialog.open(ConsentDialog, {
      width: '900px',
      height: '95vh',
      data: { modalType: this.modalType},
      disableClose: true
    });
    this.onDialogClosed$.next(true);
    this.dialogRef.afterClosed().subscribe(() => {
      this.userStateService.onEULACompleted$.next(true);
    });
  }

  public openThreadDialog() {
    this.gtag.event('click', {
      'event_category' : 'engagement',
      'event_label' : 'patient page message user clicked'
    });

    this.messageDialogIsOpen = true;
    this.dialogRef = this.dialog.open(ThreadMessageDialog, {
      width: '750px',
      height: '95vh',
      data: {
        recipientName: this.recipientName,
        recipientId: this.recipientId,
      } // if this is present, we are going to hide/skip recipient selector
    });

    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  /**
   * Opens dialog window for the Intervention Report
   */
  public openReportDialog() {
    this.gtag.event('click', {
      'event_category' : 'engagement',
      'event_label' : 'patient page Intervention Report clicked'
    });
    this.reportDialogIsOpen = true;
    this.dialogRef = this.dialog.open(ReportDialogComponent, {
      minWidth: '750px',
      width: '60vw',
      data: {
        recipientName: this.recipientName,
        recipientId: this.recipientId,
      } // if this is present, we are going to hide/skip recipient selector
    });

    this.dialogRef.afterClosed().subscribe(() => {
    });
  }

  public openConfirmDeleteDialog() {
    this.dialogRef = this.dialog.open(ConfirmDeleteDialog, {
      width: '400px',
      data: { 
        eventToDelete: this.eventToDelete,
        recipientId: this.recipientId,
      },
      disableClose: true
    });
    this.onDialogClosed$.next(true);

    this.dialogRef.afterClosed().subscribe(() => {
    });
  }
}
