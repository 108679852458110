import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { PrintConsentComponent } from './pages/print-consent/print-consent.component';
import { HomeComponent } from './home/home.component';
import { UserManualComponent } from './pages/user-manual/user-manual.component';
import { ReportingComponent } from './reporting/reporting.component'; 
import { LoggedInGuard } from '@noctem/web';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [LoggedInGuard],
    children: [
      // FROM TEMPLATE
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'reporting', component: ReportingComponent },
      { path: 'user', loadChildren: './user/user.module#UserModule' },
      //Removed to prevent access due to potential data expsure risk. 
      //{ path: 'calendar', component: CalendarPageComponent },
      { path: 'patient/:id', loadChildren: './patient/patient.module#PatientModule' },
      {
        path: 'secure_chat/:id',
        loadChildren: './clinician-chat/clinician-chat.module#ClinicianChatModule'
      },
      {
        path: 'secure_chat',
        loadChildren: './clinician-chat/clinician-chat.module#ClinicianChatModule'
      }

      // NOT FROM TEMPLATE
    ]
  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'lock', component: LockComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },
  { path: 'print-consent/:id', component: PrintConsentComponent },
  { path: 'user-manual', component: UserManualComponent },

  // Not found
  { path: '**', redirectTo: 'home' }
];
