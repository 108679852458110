<div [ngClass]="{'chart-size-class': chartType != 'pie' && chartType != 'horizontalBar', 'in-accordion': inAccordion, 'not-in-accordion': !inAccordion}"
    class="w-100">
    <canvas baseChart height='{{getChartHeight()}}' [attr.id]='chartId' class="w-100"
        style="padding:15px;pointer-events:none;" [datasets]="data" [labels]="xAxisLabels" [options]="options"
        [colors]="chartColors" [legend]="legend" [chartType]="chartType" [plugins]="plugins"
        *ngIf="data && data.length > 0">
    </canvas>
    <div class="h-100 w-100 container-fluid text-center mt-3" *ngIf="!data || data.length === 0">
        No data to display.
    </div>
</div>