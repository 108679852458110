import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentAnswer, AssessmentQuestion } from '../../services/models';

@Component({
  selector: 'checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.scss']
})
export class CheckboxQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Input() previousValue;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  @Output() otherAnswerUpdated: EventEmitter<any> = new EventEmitter();
  @Output() handleSelectAll: EventEmitter<any> = new EventEmitter();
  answers = [];
  otherSelected = false;

  constructor() {}

  ngOnInit() {
    // Fill the question with the updated answers from the user (previousvalue) or the original set of answer options
    if(!Array.isArray(this.question?.answer)) { return; }
    this.question?.answer?.forEach(answer => {
      this.question.answerOptions.find(o => o.value === answer.value).isChecked = answer.isChecked ?? false;
    });
  }

  valueUpdated(element, answer: any) {
    if (answer.fillin !== undefined) {
      // This is the "other, please specify" bubble. Flip the flag to make the input box either able to be written in or disabled
      this.otherSelected = !this.otherSelected;
    }
    //Flip the isChecked field on a specific answer
    answer.isChecked = !(answer.isChecked ?? false);
    this.answerUpdated.emit(answer);
  }

  selectAllToggle(element) {
    element.checked = !element.checked; // Flip from checked to unchecked or vice versa
    const checkboxes = document.getElementsByTagName('input');
    if (element.checked) {
      if (!this.otherSelected) {
        this.otherSelected = !this.otherSelected; // Other was unchecked, but Select All will check it
      }
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
    } else {
      if (this.otherSelected) {
        this.otherSelected = !this.otherSelected; // Other was checked, but Select All will uncheck it
      }
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
    }
  }

  // When the text for "other" answer is edited, update the question answer
  otherTextModified(event, answer: any) {
    const inputValue = event.target.value;
    answer.fillin = inputValue;
    this.answerUpdated.emit(answer);
  }

  getQuestionType(answerOption) {
    return answerOption.fillin === undefined;
  }
}
