import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { grounding } from '../questions/grounding/grounding';

export const groundingDef: AssetDefinition = {
  id: 20,
  name: 'grounding',
  label: 'Grounding',
  description: `
    Stressful situations can make thoughts erratic. Help your Soldiers learn to bring their attention and awareness to the present moment by focusing on their senses - what they see, feel and hear.
  `,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    grounding
  ],
  url: 'grounding'
};
