import { NgModule, PipeTransform, Pipe } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NgxSummernoteModule } from 'ngx-summernote';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { UserManualComponent } from './user-manual';
import { RegisterComponent } from './register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PrintConsentComponent } from './print-consent/print-consent.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutModule } from '../../layout/layout.module';

/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  imports: [SharedModule, NgxSummernoteModule, LayoutModule, PdfViewerModule],
  declarations: [
    SafeHtmlPipe,
    LoginComponent,
    UserManualComponent,
    RegisterComponent,
    RecoverComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component,
    PrintConsentComponent
  ],
  exports: [
    RouterModule,
    LoginComponent,
    RegisterComponent,
    RecoverComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component,
    PrintConsentComponent
  ],
  providers: []
})
export class PagesModule {}
