import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { strategicNapping } from '../questions/strategic-napping/strategic-napping';

export const strategicNappingDef: AssetDefinition = {
  id: 24,
  name: 'strategic-napping',
  label: 'Strategic Napping',
  description: `
  The only fix for sleepiness and fatigue is sleep. Help your Soldiers get the most out of nap opportunities by strategically selecting the length and timing of naps.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    strategicNapping
  ],
  url: 'strategic-napping'
};
