import { UserAgreement } from "../../../../core";

export default new UserAgreement({
  id:0,
  name: 'consent',
  title: 'Consent',
  imgName:'irb.png',
  questionHeader: 'To finalize the activation of the NOCTEM app, please check each box below to confirm that all statements are true:',
  textReplacementMethod:'replaceConsentText',
  agreementQuestions: [
    {
      answerId: 'haveReviewed',
      text: 'I have reviewed information about the NOCTEM project with a research team member and my questions were answered.'
    },
    {
      answerId: 'agree',
      text: 'I agree to participate in this research study and use the app with my Provider to improve my sleep.'
    },
    {
      answerId: 'understand',
      text: 'I understand I can withdraw at any time and that my Provider can offer other sleep management methods.'
    }
  ],
  pages: [
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <div class="consent-header">App User Consent</div>
          <p><i>You are participating in a research study</i></p>
          <p class='sub-header'>Optimizing The Scalability of <br />Evidence-Based Sleep Interventions<br/> with Digital Health Technology</p>
          <p>This study is conducted by NOCTEM, LLC with partial funding from the U.S. Department of Defense.</p>
          <p>Here are your local points of contact:
          <p class="sub-header pb-0 mb-0">[pi_name]</b></p>
          <p class="pb-0 mb-0"><a href="tel:[pi_phone]">[pi_phone]</p>
          <p><a href="mailto:[coordinator_email]">[pi_email]</a></p>
          <p class="sub-header pb-0 mb-0">[coordinator_name]</p>
          <p class="pb-0 mb-0"><a href="tel:[coordinator_phone]">[coordinator_phone]</p>
          <p class="pb-0 mb-0"><a href="mailto:[coordinator_email]">[coordinator_email]</a></p>
          <p class="product-name"><b>NOCTEM® COAST™</b></p>
          <div>NOCTEM, 218 Oakland Ave<br/> Pittsburgh, PA 15213<br/>
          <b><a href="https://noctemhealth.com" target="_blank">noctemhealth.com</a></b>
          </div>
        </div>
        `
      },
      {
          title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
          <div class="consent-header">Why is this study being done?</div>
          <ol>
            <li>The <b>first aim</b> is to test how the NOCTEM® digital
                platform called, COAST™ (Clinician-Operated
                Assistive Sleep Technology), can be best deployed to
                treat insomnia and other sleep disturbances in service
                members, family members, and civilians.
            </li>
            <li>The <b>second aim</b> is to measure the magnitude of
            sleep improvements in people who use the NOCTEM
            app with their provider, or sleep point of contact
            (Provider).</li>
          </ol>
          </div>
          `
      },
      {
        title: '',
        imgPath: '',
        text: `
            <div class="consent-content">
              <div class="consent-header">Why are you being asked to participate?</div>
                <ol>
                  <li>You reported sleep problems, such as trouble falling
                  or staying asleep and/or other sleep difficulties that
                  disrupt sleep.</li>
                  <li>Your Sleep Point of Contact (Provider) has
                  volunteered to participate in this same research
                  study examining how a sleep digital platform called
                  NOCTEM can be best used to support the treatment
                  of insomnia and other sleep disturbances.</li>
                  <li>Your Provider has determined that the NOCTEM
                  platform:
                    <p>
                      <ul>
                          <li>Is appropriate to address your sleep complaints</li>
                          <li>May be an effective method for supporting the treatment of insomnia and other behavioral sleep disorders</li>
                      </ul>
                    </p>
                  </li>
              </ol>
            </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
          <div class="consent-content">
            <div class="consent-header">You are eligible if...</div>
              <ol>
                <li>You are willing to provide consent.</li>
                <li>You own a smartphone.</li>
                <li>
                  You have sleep difficulties and are willing to try to
                  improve your sleep by modifying some of your
                  daytime and nighttime behaviors known to
                  improve sleep quality
                </li>
                <li>Your provider is confident it is safe for you to
                participate in the study.</li>
              </ol>
              <p class="call-out">If you agree to participate, you give permission
                to your Provider to disclose information such as
                your name and phone number to the NOCTEM
                and local research teams.</p>
          </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text:`
          <div class="consent-content">
            <div class="consent-header">You are not eligible if...</div>
              <ol>
                <li>You are pregnant</li>
                <li>You are breastfeeding</li>
                <li>You are the parent of children under the age of 6 months old</li>
              </ol>
              <p>This is because you may not benefit from the recommendations your provider will share.</p>
            </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text:`
          <div class="consent-content">
            <div class="consent-header">You do not have to participate</div>
              <ol>
                <li>
                  If you decide not to participate, your Provider will
                  tell you about other therapies available for
                  insomnia. S/he may also refer you to your primary
                  care doctor to discuss medications for insomnia.
                </li>
                <li>
                  If you decide not to participate, there will be no
                  penalty to you, and you will not lose any benefit
                  to which you are otherwise entitled.
                </li>
              </ol>
            </div>`
      },
      {
          title: '',
          imgPath: '',
          text: `
              <div class="consent-content">
                <div class="consent-header">What would I be asked to do<br /> if I choose to participate in this study?</div>
                  <ol>
                    <li>Download the NOCTEM® COAST™ app on your smart device(s).</li>
                    <li>Complete daily Morning and Evening logs on the
                    app. Each entry will require about 20 seconds of
                    your time.</li>
                    <li>Complete weekly assessments* on the app to tell
                    your Provider about your sleep, mood, stress
                    levels, and possible side effects. Completing the
                    weekly assessments will take 5 minutes of your
                    time each time.
                    <div class="p-2 m-2">* Some questions asked may cause mild
                    unease.</div></li>
                  </ol>
                </div>`
      },
      {
        title: '',
        imgPath: '',
        text: `
      <div class="consent-content">
        <div class="consent-header">What would I be asked to do<br /> if I choose to participate in this study? <span class="cont"></span></div>
          <table>
            <tr>
              <td class="list-number">4</td>
              <td class="list-content">
                Review and follow the sleep recommendations
                sent by your Provider on the app.
                <div class="p-2 m-2">* Some of these recommendations may lead
                to a short-term increase in daytime
                sleepiness and fatigue.</div>
              </td>
            </tr>
            <tr>
              <td class="list-number">5</td>
              <td class="list-content">Communicate with your Provider through the app
              messaging system.</td>
            </tr>
            <tr>
              <td class="list-number">6</td>
              <td class="list-content">Complete baseline, post intervention, and
              follow-up assessments at 3, 6, and 12 months or
              for the duration of your care with your Provider.
              The follow-up assessment may take up to 20
              minutes of your time and can be done over more
              than one sitting.</td>
            </tr>
          </table>
        </div>`
      },
      {
        title: '',
          imgPath: '',
          text: `
            <div class="consent-content">
              <div class="consent-header">What are the risks of this study?</div>
                <ol>
                  <li>
                    The risks or possible discomfort are minimal and
                    do not exceed the typical risks expected in
                    clinical practice or use of smartphone and
                    web-based computer tools.
                  </li>
                 <li>
                    You will be promptly notified if, during this
                    research study, any new risks arise that may
                    cause you to reconsider participation.
                 </li>
                </ol>
              </div>
                 `
      },
      {
        title:'',
        imgPath:'',
        text:`
          <div class="consent-content">
            <div class="consent-header">Main risks and discomforts for participating users</div>
              <ol>
                <li><b>A temporary increase in sleepiness:</b>
                    10 to 25 of 100 people who follow the
                    recommendations to improve sleep may
                    experience a temporary increase in
                    sleepiness. Sleepiness related to the
                    recommendation could increase your risk
                    of accidents while driving or at work. You
                    may report side effects to your Provider at
                    any time.
                </li>
                <li>
                  <b>Some psychological discomfort when
                  filling out questionnaires</b>: Some of the
                  questions ask about mood and stress. 5
                  to 10 out of 100 people feel
                  uncomfortable answering these
                  questions. You can refuse to answer any
                  questions or questionnaires that make
                  you uncomfortable.
                </li>
              </ol>
            </div>`
      },
      {
          title: ``,
          imgPath: '',
          text: `
            <div class="consent-content">
              <div class="consent-header">Main risks and discomforts for participating users <span class="cont"></span></div>
                <table>
                <tr>
                  <td class="list-number">3</td>
                  <td class="list-content">
                    <b>A breach of confidentiality:</b> It is possible that
                      information regarding your sleep and
                      behavioral health will be discovered by
                      individuals outside of study personnel, despite
                      careful steps to protect confidentiality.

                    <p>Breach of confidentiality rarely occurs, in less
                      than 1 out of 100 people.</p>
                    <p>Maintaining strict security will minimize these
                      risks. Study data is kept strictly confidential
                      and participants’ identities are not be revealed
                      in any publication.</p>
                    <p>All participants have a unique identifier number
                    that will be used on forms and for data storage
                    purposes. Computer databases are protected
                    by several procedures, including password
                    protection of subject data and a firewall around
                    the entire NOCTEM network.</p></td>
                </tr>
                </table>
              </div>`


      },
      {
        title: ``,
        imgPath: '',
        text:`
        <div class="consent-content">
          <div class="consent-header">Main risks and discomforts for participating users <span class="cont"></span></div>
          <table>
            <tr>
                <td class="list-number">4</td>
                <td class="list-content">
                  <b>Risks specific to the use of smartphones</b>: Be aware that confidentiality during internet
                  communication and text messaging activities
                  cannot be guaranteed even if every reasonable
                  effort may be taken to assure security. Like for
                  the regular use of your smart devices,
                  information may be intercepted and used by
                  others not associated with this study.
                </td>
            </tr>
          </table>
        </div>`
      },
      {
          title: '',
          imgPath: '',
          text: `
        <div class="consent-content">
          <div class="consent-header">What alternatives are there<br/> to study participation?</div>
          <p>If you decide not to take part in this study, you may explore ways to receive sleep treatments,
                    including:</p>
                 <ol>
                  <li>
                    You may receive the traditional, face-to-face
                    treatment for insomnia and other sleep
                    disturbances. These treatments typically require
                    four (4) to eight (8) in-person appointments with
                    a specialized provider.</li>
                  <li>
                    You can also request a referral to a sleep
                    specialist in a sleep clinic. Note that there are
                    often waiting lists to receive these types of
                    treatments in specialized clinics.</li>
                  <li>
                    You can ask your provider to refer you to a
                    physician who may prescribe a sleep medication.
                  </li>
                  <li>You may also use web-based sleep treatment
                    programs or stand-along apps that provide
                    education about sleep.
                  </li>
                </ol>
                <div class='call-out'>You do not have to participate in this study in
                  order to receive these alternative sleep treatments.
                </div>
              </div>`

      },
      {
        title: '',
        imgPath:'',
        text:`
        <div class="consent-content">
          <div class="consent-header">Can I participate now and change my mind later?</div>
          <ol>
            <li>Yes.  Your participation is completely voluntary.</li>
            <li>You can stop your participation at any time.</li>
            <li>To do so, simply tell your Provider and then uninstall the app on your phone.</li>
            <li>Discuss other insomnia care options with your Provider.</li>
          </ol>
        </div>
          `
      },
      {
          title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
            <div class="consent-header">What health information will be collected and how will it be kept confidential?</div>
            <ol>
              <li>All your information will be kept confidential.</li>
              <li>All information will be collected electronically.</li>
              <li>Your phone number and device unique identifier will be collected and stored over the duration of the study.  It will be deleted and de-identified at the end of the project.</li>
              <li>No other personal private or health identifiers will be collected.</li>
              <li>No information is stored on the app.</li>
              <li>Federal regulations give certain rights related to health information.  These include the right to know who will have access to the information.</li>
            </ol>
          </div>`
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header">What health information will be collected and how will it be kept confidential? <span class="cont"></span></div>
          <table>
            <tr>
              <td class="list-number">7</td>
              <td class="list-content">
                If you choose to participate in this study, you will be asked to provide some demographic information (for example: age, sex, rank, MOS, information about your sleep and wake behaviors
                that impact sleep and biological cycles, mood and stress symptoms, as well as physical symptoms you may already be experiencing or that occur during the study).
              </td>
            </tr>
            <tr>
              <td class="list-number">8</td>
              <td class="list-content">You will also be asked feedback about if you feel the recommendations you receive are helpful or not, and feedback you have about the app.</td>
            </tr>
          </table>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header">What health information will be collected and how will it be kept confidential? <span class="cont"></span></div>
          <table>
            <tr>
              <td class="list-number">9</td>
              <td class="list-content">You will receive a unique login to access the NOCTEM app.</td>
            </tr>
            <tr>
              <td class="list-number">10</td>
              <td class="list-content">This information will be used only by your provider and study personnel and will be maintained indefinitely.</td>
            </tr>
          </table>
        </div>
        `
      },
      {
        title: '',
        imgPath:'',
        text:`
        <div class="consent-content">
          <div class="consent-header">What health information will be collected and how will it be kept confidential? <span class="cont"></span></div>
          <table>
            <tr>
              <td class="list-number">11</td>
              <td class="list-content">
                No private identifiable information will be associated
                with data at any time during this study, therefore
                minimizing the risk of breach to confidentiality.
              </td>
            </tr>
            <tr>
            <td class="list-number">12</td>
            <td class="list-content">
              Maintenance of data will allow for further analysis, as
              well as comparison of this dataset to others.
              Information gleaned from such analyses may increase
              our knowledge about sleep disturbances in service
              members, and may better inform sleep treatments,
              thereby increasing total force fitness and readiness.
            </td>
          </tr>
          </table>

          <div class='call-out'><p>Your data will not be sold to a third party.  NOCTEM and [site_name] maintain full control over the data collected in this study.</p></div>
          </div>
        `
      },
      {
        title:'',
        imgPath:'',
        text:`
        <div class="consent-content">
        <div class="consent-header">Who will have access to my information?</div>
          <ol>
            <li>Your Provider will have access to your information and know it belongs to you. <u>DoDI 6490.08 rules for
            protecting and disclosing your information will be
            followed.</u></li>
            <li>Your de-identified information will be accessible to
            the local research team and the NOCTEM team.</>
            <li>Authorized representatives of Solutions IRB and
            USAMRMC Human Research Protection Office
            may review your research information for audits
            and monitoring the appropriate conduct of this
            research.</li>
            <li>Authorized representatives of the Department of
            Defense are eligible to review research records.</li>
            <li>None of your information will be shared or sold to a
            third party.</li>
          </ol>
        </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header">What about security?</div>
        <ol>
                <li>
                  Security and confidentiality in a mobile health application
                  is of paramount importance.
                </li>
                <li>We use layered security measures to protect the privacy
                and confidentiality of the data, including:</li>
                <ul>
                  <li>The use of a forced PIN/password to access the
                  app.</li>
                  <li>A strong authentication procedure that combines a
                  PIN/password, the phone number of the device, and
                  the device’s unique International Mobile Equipment
                  Identity (IMEI) number.</li>
                  <li>Encryption of all communication between the
                  smartphone and the server using the AES
                  (Advanced Encryption Standard) 192-bit key.</li>
                  <li>A security method that allows the research team to
                  disable the app and to erase records from the
                  device in the event of a lost smartphone while
                  preserving the records on the secure server.</li>
                  <li>A secure messaging system between the provider’s
                  portal and your smartphone will be used in the place
                  of SMS/text messaging.</li>
                  <li>A secure server behind a firewall with security policy
                  in place that includes a dedicated cloud, regular
                  back-up of data, a monitored network with active
                  security measures, and a well-defined, role-based,
                  access to the database.</li>
                </ol>
              </ol>
              <p><b>The COAST app does not collect your GPS location.</b></p>
            </div>`
      },
      {
        title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
          <div class="consent-header">What are the potential benefits of participating in this study?</div>
            <ol>
              <li>You will not receive compensation for your participation.</li>
              <li>There are no costs for taking part in this research study.</li>
              <li>There are no certain benefits to you. You may learn more about your sleep and sleep-promoting practices.</li>
              <li>
                The information from this study may help researchers learn more about the effectiveness
                of smart phone-based tools for sleep improvement in Service Members with disrupted
                sleep.
              </li>
          </ol>
        </div>`
      },
      {
        title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
          <div class="consent-header">What happens if you are injured because you took part in this study?</div>
            <p>Injury or illness due to this study is unlikely. However, if
              you are injured or become ill because of taking part in
              this study, please immediately contact [pi_name] at [pi_phone].</p>
            <p>Medical care will be available through your provider.</p>
            <div class="call-out">By signing this consent form, you will not be giving up any legal rights.</div>
          </div>
          `
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header">What if you have questions about this research study?</div>
            <ul>
                <li>For questions about the research study, contact
                Dr. Anne Germain at (412) 897-3183 or
                anne@noctemhealth.com. She is the overall PI of
                the project and lead project manager for the NOCTEM
                team, the company who develops, deploys, and tests
                the NOCTEM platform.</li>
                <li>You may also contact your local point of contact
                [pi_name], or his/her
                coordinator, [coordinator_name], at [coordinator_phone] or via email at [coordinator_email].</li>
                <li>
                For questions about the ethical aspects of this
            study or participants rights, contact Solutions IRB
            (the body that oversees our protection of study
            participants) at (855) 226-4472 or
            participants@solutionsirb.com.
                </li>
              </ul>
          </div>`
      },
      {
          title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
            <div class="consent-header">Consent</div>
              <ul>
                <li>You have read the information in this consent form.</li>
                <li>You have been given an opportunity to ask
                questions about this study and its procedures
                and risks, as well as any of the other
                information contained in this consent form.</li>
                <li>All of your questions have been answered to
                your satisfaction, and you know how to contact
                in case additional questions come up during the
                participation.</li>
                <li>You authorize the use and disclosure of your
                de-identified data to the persons listed in the
                health information and confidentiality section of
                this consent for the purposes described above.</li>
                <li>By checking each of the 3 boxes on the next
                screen, you freely give your consent to be in
                this research study as it has been explained to
                you.</li>
                <li>You understand that you can access a copy of
                all the information presented here on the
                NOCTEM® COAST™ app and a statement
                informing you about the provisions of the
                Privacy Act.</li>
              </ul>
              <div class='call-out'>
                Remember:<br />
                By completing the final steps, you do not give up any legal rights.
              </div>
            </div>`
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <div class="consent-header">Privacy Act Statement</div>
          <ul>
              <li><b>AUTHORITY</b>: 32 CFR Part 219, Protection of Human Subjects;
              45 CFR Part 46, Protection of Human Subjects; DoDI 3216.02,
              “Protection of Human Subjects and Adherence to Ethical
              Standards in DoD-Supported Research,” October 20, 2011; 45
              CFR Parts 160 and 164, Health Insurance Portability and
              Accountability Act (HIPAA) Privacy and Security Rules; and E.O.
              9397 (SSN), as amended.</li>
              <li><b>PURPOSE</b>: Information is collected to enhance basic medical
              knowledge, or develop tests, procedures, and equipment to
              improve diagnosis, treatment, or prevention of illness, injury, or
              performance impairment under research protocol
              NHRC.2019.0018, entitled “Optimizing The Scalability of
              Evidence-Based Sleep Interventions with Digital Health
              Technology.”</li>
              <li><b>ROUTINE USES</b>: In addition to those disclosures generally
              permitted under 5 U.S.C. 552a(b) of the Privacy Act of 1974, the
              DoD “Blanket Routine uses” under 5 U.S.C. 552a(b)(3) apply to
              this collection. Medical research information will be used for
              analysis and reports by the Department of Defense, and other
              U.S. Government agencies, provided this use is compatible with
              the purpose for which the information was collected. Use of the
              information may be granted to non-Government agencies or
              individuals by the Navy Surgeon General and/or the defense
              Health Agency following the provisions of the Freedom of
              Information Act or as may be indicated in the accompanying
              Informed Consent Form.</li>
              <li><b>DISCLOSURE</b>: Provision of information is voluntary. There are
              no penalties for not providing requested information, but failure to
              provide the requested information may result in failure to be
              accepted as a research volunteer in an experiment or removal
              from the program.</li>
            </ul>
          </div>`
    },
  ]
});
