import { LogDefinitionTypes } from "../../../../noctem-lib/src/constants/constants";
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition, AssessmentGroupScoreFieldDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const teamCommunicationFields: Array<FieldDefinitionBase> =
[

  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'team-communication-overall',
    unit: UnitType.Number,
    abbreviation: 'Team Communication',
    description: `Team Communicatoon`,
    assessmentType: LogDefinitionTypes.TeamCommunication
  }),
  //#region  "Team Communication data Questions"
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-work',
    unit: UnitType.Number,
    abbreviation: 'Teamwork',
    description: `Teamwork`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_WORK'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-communication',
    unit: UnitType.Number,
    abbreviation: 'Team Communication',
    description: `Team Communication`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_COMMUNICATION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-expressing',
    unit: UnitType.Number,
    abbreviation: 'Team Expressing',
    description: `Team Expressing`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_EXPRESSING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-angry',
    unit: UnitType.Number,
    abbreviation: 'Team Angry',
    description: `Team Angry`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_ANGRY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-handle-conflict',
    unit: UnitType.Number,
    abbreviation: 'Team Handle Conflict',
    description: `Team Handle Conflict`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_HANDLE_CONFLICT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'team-left-out',
    unit: UnitType.Number,
    abbreviation: 'Team Left Out',
    description: `Team Left Out`,
    assessmentType: LogDefinitionTypes.TeamCommunication,
    answerId: 'TEAM_LEFT_OUT'
  })


  //#endregion
];
