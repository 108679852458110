import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const dangersOfFatigue: AssessmentDefinition = {
  name: 'dangers-of-fatigue',
  description: 'Dangers of Fatigue',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Without adequate sleep, your brain and body will not perform at peak readiness',
      questionEmphasis: ``,
      subText: `<p><img src='./assets/img/module-images/dangers-of-fatigue/dangers-of-fatigue.svg' /></p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: 'Dangers of Fatigue',
      questionEmphasis: ``,
      subText: `<p>Fatigue can lead to <b>serious</b> consequences...</p>
                <p class="call-out">
                  Accidents<br />
                  <b>Death</b><br />
                  Depression<br />
                  <b>Anxiety</b><br />
                  Suicidality<br />
                  <b>Irritability</b><br />
                  Poor Morale<br />
                  <b>Obesity</b><br />
                  Memory Gaps<br />
                </p>
                <p>
                  <img src='./assets/img/module-images/dangers-of-fatigue/fatigue.svg'/>
                </p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: 'Dangers of Fatigue',
      questionEmphasis: ``,
      subText: `<p class="call-out">
                  A US Army 2014 Survey found that fatigue was a contributing factor in 32 deaths and 628 accidents with injuries</p>

                <p>
                  <h5>Fatal collision with chemical tanker Alnic MC resulting in 10 deaths. Sailors operating on 5 hours of sleep or less</h5>
                  DDG-56, Arleigh-Burke-class Destroyer</p>
                <p>Suffered a fatal collision with Liberian flagged chemical tanker ALNIC MC at 05:24 on 21 August 2017</p>
                <p><img src='./assets/img/module-images/dangers-of-fatigue/us-navy-damage.png'/></p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 3,
      questionText: 'Dangers of Fatigue',
      questionEmphasis: ``,
      subText: `<p>What types of tasks are impacted by fatigue?</p>
      <p><img src='./assets/img/module-images/dangers-of-fatigue/dangers-of-fatigue-stacked.svg' /></p>`,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
