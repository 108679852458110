export const maestroDef = {
  name: 'maestro',
  displayName: 'Maestro',
  label: 'Sleep Maestro',
  pointLowerLimit: 25,
  pointUpperLimit: 48,
  stageName: 'Stage 2',
  activeImagePath: 'maestro-front.png',
  inactiveImagePath: 'maestro-front-inactive.png',
  imagePathTails: 'maestro-back.png',
  isActive: true,
  activeCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Your second challenge coin.  [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin is achieved by completing stage 2 and accumulating 48 or more points'
    },
    {
      title: 'Description',
      description:
        'Building good sleep requires perseverance. You are on track to reconstruct new sleep habits and build sleep that is sufficient, predictable, and consolidated. Carpe Noctem! Seize the Night.'
    }
  ],
  inactiveCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'The second challenge coin.  [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin can be achieved by completing stage 2 and accumulating 48 or more points'
    },
    {
      title: 'Description',
      description:
        'Building good sleep requires perseverance. The goal is to reconstruct new sleep habits and build sleep that is sufficient, predictable, and consolidated. Carpe Noctem! Seize the Night.'
    }
  ]
};
