import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { USER_STATE_SERVICE, CLIENT_ID } from '@noctem/web';
import { UserStateService } from '@noctem/web';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivatedRoute } from '@angular/router'; 

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    password: string;
    email: string;
    isValidLogin: boolean = true;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    nextPath: string;
    destroy$: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(USER_STATE_SERVICE) public userStateService: UserStateService,
        @Inject(CLIENT_ID) private clientId: string,
        public settings: SettingsService,
        fb: FormBuilder
    ) {

        this.loginForm = fb.group({
            'email': ['',  {validators: [Validators.required, Validators.pattern(this.emailPattern)], asyncValidators: []}],
            'password': ['', Validators.required]
        });

        // Parse next path from the route's queryParams.
        this.route.queryParams.pipe(filter(p => p.next)).subscribe(p => {
            this.nextPath = p.next;
        });

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        if (this.loginForm.valid) {
            this.userStateService.authenticate(this.loginForm.get('email').value, this.loginForm.get('password').value, this.clientId, false);
        } else {
            this.loginForm.get('email').markAsDirty();
            this.loginForm.get('email').markAsTouched();
            this.loginForm.get('password').markAsDirty();
            this.loginForm.get('password').markAsTouched();
        }
    }

    ngOnInit() {
        this.userStateService.onUserLoaded$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(u => {
            if (this.nextPath) {
                this.router.navigate([this.nextPath]);
            } else {
                this.router.navigate(['/home']);
            }
        });

        this.userStateService.onError$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(err => {
            this.isValidLogin = false;
        })
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

