import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const pgi: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'pgi-rating',
    unit: UnitType.Number,
    abbreviation: 'Rating',
    description: `Rating`,
    assessmentType: LogDefinitionTypes.PGI,
    answerId: 'PGI_RATING'
  }),

  //#endregion
];
