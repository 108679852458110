<div class="consent">
    <div #scrollDiv id="scrollDiv" mat-dialog-content (scroll)="onScroll($event)">
        <div class="modal-header">
            <div align="right">Page {{pageNumber + 1}} of {{template?.pages.length}}</div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="row breathe">
                        <div class="terms" [innerHtml]="template?.pages[pageNumber].content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="pageNumber !== template?.pages.length - 1">
        <input type="text" maxlength="4" [placeholder]="initPlaceholder" [(ngModel)]="currentPageInitials"
            [disabled]="!end" *ngIf="template?.pages[pageNumber].requireInitials">

        <div mat-dialog-actions>
            <button mat-button class="btn btn-primary" (click)="next()" [disabled]="!currentPageInitials">Next</button>

        </div>
    </div>
    <div class="modal-footer" *ngIf="pageNumber === template?.pages.length - 1">
        <input type="text" placeholder="Sign with first and last name" [(ngModel)]="consent['SIGNATURE']"
            [disabled]="!end" *ngIf="data.modalType === 'consent'">
        <div class="mt-4" *ngIf="data.modalType==='EULA'"><input type="checkbox" [(ngModel)]="checkEULA" /><label
                class="pl-2 pr-2">By checking this box, I agree to the terms and conditions described in this
                agreement.</label></div>
        <div mat-dialog-actions>
            <button mat-button class="btn btn-primary" (click)="submit()"
                [disabled]="(!currentPageInitials && !consent['SIGNATURE']) && !checkEULA">Submit</button>
        </div>
    </div>
</div>