import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { sleepAndNightOps } from '../questions/sleep-and-night-ops/sleep-and-night-ops';

export const sleepAndNightOpsDef: AssetDefinition = {
  id: 8,
  name: 'sleep-and-night-ops',
  label: 'Sleep and Night Ops',
  categories: ['ops-help'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    sleepAndNightOps
  ],
  url: 'sleep-and-night-ops'
};
