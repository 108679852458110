import moment from 'moment';
import { chartOptionsDefs } from './chart-options';
import { medicChartTabsDict } from './chart-tabs';
export const timeSpanDict = [
  { id: '24', label: '24 Hours', startDate: moment().subtract(24, 'hours'), capped: false },
  { id: '72', label: '72 Hours', startDate: moment().subtract(72, 'hours'), capped: false },
  { id: 'week', label: '1 Week', startDate: moment().subtract(7, 'days'), capped: false },
  { id: 'year', label: 'All', startDate: null, capped: true }
];





export const chartOptions = {...chartOptionsDefs};
export const chartTabsDefs = [...medicChartTabsDict ];
