import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const compartmentalization: AssessmentDefinition = {
  name: 'prioritize-thoughts',
  description: 'Prioritize Thoughts',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Learn</h5>
                <p>Often several things compete for your attention, however attentional capacity is limited. Performance is best with focused attention – very specific & precise targets. </p>

                  <h5>To optimize performance,
                  focus on the most relevant task.</h5>

                <p>After accomplishing that task you can then shift to another. This skill will help you perform at your peak and accomplish your mission.</p>
                <div class="call-out">
                <h5 class='module'>Here’s what you can do.</h5>
                <p>Ask yourself “What’s Important Now?” To identify your WIN figure out the single most important thing you need to attend to so that you can accomplish the task. <p>
                </div>
                <h5>Identify your target.<br/>
                    Execute the task. <br />
                    Decide to deal with other concerns at another time.
                </h5>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <div class='call-out'>
        <h5 class='module'>Practice</h5>
        <p>You can use this skill in a high stress situation or when a chronic thought distracts you.
          Think critically when selecting your target – it could be an action or state of being for performance preparation.
        </p>
        <h5 class='module'>Avoid thoughts about the past or future, judging or analyzing your actions.</h5>
        <p>Acknowledge unhelpful thoughts, but make a conscious decision to deal with it at another time - you can even schedule a specific time to think about it.</p>
      </div>
      <div class="call-out">
        <h5 class='module'>Activity 1: </h5><b>Personal Distracting Thoughts</b><br><br>
        <p>Identify and write in this box your personal distracting thoughts that compromise focus. Think about the last time you had to perform an important task, what distracting thoughts did you have?</p>
      </div>
      `,
      questionType: 'textarea',
      postText: `The next time you find yourself having those thoughts, tell yourself you will deal with them at another time.`,
      uniqueAnswerId: 'COMPARTMENT_DISTRACTING',
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `
      <div class='call-out'>
        <h5 class='module'>Activity 2</h5>
        <p>Visualize this scenario: You are qualifying to shoot a new weapon. Your first two rounds were not great. You are getting very down on yourself and are worried about the consequences if you don’t improve this last round. Your leader is watching you closely.</p>

        <p>You ask yourself “What’s Important Now?”  and “What is most critical to my performance?</p>
      </div>
      Write in this box what you need to focus on:
      `,
      questionType: 'textarea',
      postText: `Now imagine yourself bringing your thoughts to what you wrote in the box, not dwelling on potential outcomes. Your mind is focused solely on the task. Your shots are all on target.`,
      uniqueAnswerId: 'COMPARTMENT_FOCUS',
      autofill: true,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
