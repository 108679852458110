<div class="container-fluid animated fadeIn bottom">
    <div class="row align-items-center picker-links-container">
        <div class="col-6 picker-links cancel">
            <a (click)="closeModal()">Cancel</a>
        </div>
        <div class="col-6 picker-links submit">
            <a (click)="submit()">Submit</a>
        </div>
        <hr style="color: white" />
    </div>
    <div>
        <the-wheel-selector [data]="timeData" (change)="timeValueUpdated($event)"></the-wheel-selector>
    </div>
</div>