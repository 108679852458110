import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const sleepBankingAlgorithm = new SleepModuleDefinition({
  name: 'sleep-banking',
  isMedic: true,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'total-sleep-past-24',
      valueToCompare: 361,
      operator: EqualityType.LessThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleepy-feels',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'isi-overall',
      valueToCompare: 6,
      operator: EqualityType.LessThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-long-sleep',
      valueToCompare: 1,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
  ],
});
