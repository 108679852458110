import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const sleepBanking: AssessmentDefinition = {
  name: 'sleep-banking',
  description: 'Sleep Banking',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `<p>Sleep banking is about readying your brain and body for an anticipated period of sleep loss.</p>
                <h5>Stockpile sleep before a period of sleep loss occurs</h5>
                <p>Sleep banking supports Warfighter endurance and accelerates recovery from sleep loss.</p>
                <div class='call-out'>
                  <h5 class='module'><b>How does it work?</b></h5>
                  A few days to 2-weeks before an anticipated period of sleep loss, get as much sleep as possible.
                </div>
                <div class='dark-call-out'>Wake up later than usual (easiest).</div>
                <div class='call-out'>Go to sleep earlier than usual.</div>
                <div class='dark-call-out'>Augment your main sleep period with naps.</div>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 1,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Let's try it out. Select how you will bank sleep:</h5>
      `,
      questionType: 'checkbox',

      uniqueAnswerId: 'SLEEP-BANK-EXERCISE',
      autofill: false,
      isCheckInQuestion: false,
      answerOptions: [
        {
          display: 'Delay my wake time',
          value: 0, isChecked: false
        },
        {
          display: 'Advance my sleep time',
          value: 1, isChecked: false
        },
        {
          display: 'Add naps to my sleep-wake schedule',
          value: 2, isChecked: false
        },
      ],
      conditionalQuestions: []
    },
    {
      id: 2,
      questionText: '',
      questionEmphasis: ``,
      subText: `<h5>Why does this work?</h5>
                <p>
                Let's say a Warfighter is following a 23:00 - 04:00 sleep-wake schedule and knows sleep is going
                to be limited 3 days from now due to an upcoming mission.<br><br>Starting this evening, this Warfighter delays their wake up time to 05:00 (sleep banking).<br><br>
                At the start of the mission, this Warfighter has an extra 3 hours of sleep at their disposal.<br><br>That's an extra half a night's sleep that is available to
                support brain and body functioning.
                </p>
      `,
      questionType: 'module',

      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
