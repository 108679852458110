export const aspirantDef = {
  name: 'aspirant',
  displayName: 'Aspirant',
  label: 'Sleep Aspirant',
  pointLowerLimit: 0,
  pointUpperLimit: 24,
  stageName: 'Stage 1',
  activeImagePath: 'aspirant-front.png',
  inactiveImagePath: 'aspirant-front-inactive.png',
  imagePathTails: 'aspirant-back.png',
  isActive: true,
  activeCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Your first challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'You completed stage 1 and accumulated 24 or more points'
    },
    {
      title: 'Description',
      description:
        'This coin was earned on effort alone.  You get out of your sleep training what you put into it. Continue to forge ahead with your best effort. Carpe Noctem! Seize the night.'
    }
  ],
  inactiveCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'The first challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin can be achieved by completing stage 1 and accumulating 24 or more points'
    },
    {
      title: 'Description',
      description:
        'This coin is earned on effort alone.  You will get out of your sleep training what you put into it. Forge ahead with your best effort. Carpe Noctem! Seize the night.'
    }
  ]
};
