export const luminaryDef = {
  name: 'luminary',
  displayName: 'Luminary',
  label: 'Sleep Luminary',
  pointLowerLimit: 49,
  pointUpperLimit: 72,
  stageName: 'Stage 3',
  activeImagePath: 'luminary-front.png',
  inactiveImagePath: 'luminary-front-inactive.png',
  imagePathTails: 'luminary-back.png',
  isActive: true,
  activeCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Rare challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin can be achieved by completing stage 3 and accumulating 72 or more points'
    },
    {
      title: 'Description',
      description:
        'Refreshing sleep brightens the day and optimizes performance. Congrats on your sleep training endurance. Leverage your sleep training skills to maximize restorative sleep. Carpe Noctem! Seize the night.'
    }
  ],
  inactiveCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Rare challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin can be achieved by completing stage 3 and accumulating 72 or more points'
    },
    {
      title: 'Description',
      description:
        'Refreshing sleep brightens the day and optimizes performance. Leverage your sleep training skills to maximize restorative sleep. Carpe Noctem! Seize the night.'
    }
  ]
};
