export enum LayoutDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum errorType {
  Required = 'required',
  Max = 'max',
  Min = 'min',
  Email = 'email',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Patttern = 'pattern'
}

export const ErrorMessageHashtable: any  = {
    required: 'Required',
    email: 'Invalid format',
    minlength: 'Must be a minimum of {0} characters',
    maxlength: 'Must be less than {0} characters',
    pattern: 'Invalid format',
    max: 'Must be less than {0}',
    min: 'Must be greater than {0}'
};

export const DefaultCssClasses = 'form-control';
