import { LocalModuleProgress } from '@noctem/web';

export class LocalStorageService {
  public static getLocalModuleProgress(): Map<string, LocalModuleProgress> {
    const localStorageItem = localStorage.getItem('moduleProgress');
    let previousModules = new Map<string, LocalModuleProgress>();
    if(!localStorageItem || localStorageItem === '{}') {
      previousModules = new Map<string, LocalModuleProgress>();
    }
    else { previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorageItem)); }
    return previousModules;
  }

  public static saveLocalModuleProgress(modules: Map<string, LocalModuleProgress>){
    localStorage.setItem('moduleProgress', JSON.stringify(Array.from(modules.entries())));
  }
}
