<div class="wrapper">
    <div class="row">
        <div class="col-6 nocBG">
            
        </div>
        <div class="col-6 centeringParent">
            <div class="col-6 centeringChild">
                <div class="logo text-center">
                    <a href="#">
                        <h1>NOCTEM</h1>
                    </a>
                </div>
                <div class="">
                        <p class="text-center loginText">Don't fret! We can reset your password for you. Simply input your email address below.</p>
                        <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                            <div class="form-group">
                                <div class="input-group with-focus">    
                                     <input class="form-control border-right-0" type="email" name="email" placeholder="Enter email" autocomplete="off" formControlName="email" />
                                </div>
                                <div class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</div>
                                <div class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field must be a valid email address</div>

                            </div>
                            <div class="row mBreathe3">
                                <div class="col-6">
                                    <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Back</a>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-danger btn-block" type="submit">Reset</button>
                                </div>
                            </div>
                            
                            
                        </form>
                        <!-- <div class="alert alert-danger text-center" ></div> -->
                        
                </div>
                <div class="p-3 text-center">
                        <span>&copy;</span>
                        <span>{{ settings.getAppSetting('year') }}</span>
                        <span class="mx-2">-</span>
                        <span>{{ settings.getAppSetting('name') }}</span>
                        <br/>
                        <span>{{ settings.getAppSetting('description') }}</span>
                    </div>
            </div>
        </div>
    </div>
    </div>