import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const shiftWork: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'gad7-overall',
    unit: UnitType.Number,
    abbreviation: 'Gad 7 Overall',
    description: `Gad 7 Overall`,
    assessmentType: LogDefinitionTypes.GAD7
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-current',
    unit: UnitType.Text,
    abbreviation: 'Current',
    description: `Current`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CURRENT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-current-count',
    unit: UnitType.Text,
    abbreviation: 'Current Count',
    description: `Current Count`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CURRENT_COUNT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-current',
    unit: UnitType.Text,
    abbreviation: 'Current',
    description: `Current`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CURRENT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-current-start',
    unit: UnitType.Text,
    abbreviation: 'Current Start',
    description: `Current Start`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CURRENT_START'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-change',
    unit: UnitType.Text,
    abbreviation: 'Change',
    description: `Change`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CHANGE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-change-count',
    unit: UnitType.Text,
    abbreviation: 'Change Count',
    description: `Change Count`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CHANGE_COUNT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-change-start',
    unit: UnitType.Text,
    abbreviation: 'Change Start',
    description: `Change Start`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CHANGE_START'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-current-start',
    unit: UnitType.Text,
    abbreviation: 'Current Start',
    description: `Current Start`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_CURRENT_START'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-outside-typical',
    unit: UnitType.Text,
    abbreviation: 'Outside Typical',
    description: `Outside Typical`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_OUTSIDE_TYPICAL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-typical',
    unit: UnitType.Text,
    abbreviation: 'Typical',
    description: `Typical`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_TYPICAL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'shift-typical-start',
    unit: UnitType.Text,
    abbreviation: 'Typical Start',
    description: `Typical Start`,
    assessmentType: LogDefinitionTypes.ShiftWork,
    answerId: 'SHIFT_WORK_TYPICAL_START'
  }),

  //#endregion

];
