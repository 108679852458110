import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';

export const teamDynamics: AssessmentDefinition = {
  name: 'team-dynamics',
  description: 'Team Communication',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `I find it easy to work as part of the team`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_WORK',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 1,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `The members of my team have been communicating well with each other`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_COMMUNICATION',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 2,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `I feel comfortable expressing my thoughts to my fellow teammates`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_EXPRESSING',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 3,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `I find myself getting angry with people or situations`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_ANGRY',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 4,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `If a conflict arises, I am able to handle it appropriately`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_HANDLE_CONFLICT',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 5,
      questionText: 'Thinking about the <b>past few days</b>, rate how often each of the statements is true for you:',
      questionEmphasis: `I feel left out and isolated`,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_LEFT_OUT',
      autofill: false,
      answerOptions: [
        { display: 'Almost Always', value: 0 },
        { display: 'Most of the Time', value: 1 },
        { display: 'About Half of the Time', value: 2 },
        { display: 'Occasionally', value: 3 },
        { display: 'Never', value: 4 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
  ]
};
