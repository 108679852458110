const SWPS4 = ['swps4'];

export const swps2Def = {
  name: 'swps2',
  description: null,
  instructions: '',
  questions: [
    {
      uniqueAnswerId: 'SWPS_RISETIME_IS_LATER',
      id: 4,
      questionText: 'My preferred rise time is more than 2 hours later than most people.',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: [],
      redirectOptions: [
        { value: true, assessmentsToRemove: [], assessmentsToAdd: SWPS4 },
        { value: false, assessmentsToRemove: SWPS4, assessmentsToAdd: [] }
      ]
    }
  ]
};
