import { TreatmentPhase } from '../../../lib/state';
import { AssessmentTemplate } from '../../../lib/services/models';

export const followup2Def = {
  name: 'followup2',
  label: 'Follow Up 2',
  order: 9,
  assessments: [
    AssessmentTemplate.morningLog.name,
    AssessmentTemplate.eveningLog.name,
    AssessmentTemplate.niqM.name,
    AssessmentTemplate.isi.name,
    AssessmentTemplate.ess.name,
    AssessmentTemplate.swps.name,
    AssessmentTemplate.shiftWork.name,
    AssessmentTemplate.trauma.name,
    AssessmentTemplate.phq8.name,
    AssessmentTemplate.phq2.name, //1.5 will use phq2 version
    AssessmentTemplate.gad7.name,
    AssessmentTemplate.gad2.name, //1.5 will use gad2 version
    AssessmentTemplate.promis.name,
    AssessmentTemplate.peg.name,
    AssessmentTemplate.medicationUse.name,
    AssessmentTemplate.pgi.name,
    //AssessmentTemplate.sus.name, //1.5 no sus or nps on followup
    AssessmentTemplate.nsi.name
  ]
} as TreatmentPhase;
