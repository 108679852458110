import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const sideEffects: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-fatigue',
    unit: UnitType.Number,
    abbreviation: 'Fatigue',
    description: `Fatigue`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_FATIGUE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-headaches',
    unit: UnitType.Number,
    abbreviation: 'Headaches',
    description: `Headaches`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_HEADACHES'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-dizziness',
    unit: UnitType.Number,
    abbreviation: 'Dizziness',
    description: `Dizziness`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_DIZZINESS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-sexual-interest',
    unit: UnitType.Number,
    abbreviation: 'Sexual Interest',
    description: `Sexual Interest`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_SEXUAL_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-drowsiness',
    unit: UnitType.Number,
    abbreviation: 'Drowsiness',
    description: `Drowsiness`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_DROWSINESS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-muscle-tightness',
    unit: UnitType.Number,
    abbreviation: 'Muscle Tightness',
    description: `Muscle Tightness`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_ MUSCLE_TIGHTNESS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'side-effects-sensitivity',
    unit: UnitType.Number,
    abbreviation: 'Sensitivity',
    description: `Sensitivity`,
    assessmentType: LogDefinitionTypes.SideEffects,
    answerId: 'SIDE_EFFECTS_SENSITIVITY'
  }),

  //#endregion
];
