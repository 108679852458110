<div class="w-100">
<div class="row no-gutters secondary-content" (click)="goToUrl()">
  <div class="col-10">
    <div class="container module-container align-middle">
      {{module.label}}
    </div>
  </div>
  <div class="col-2 p-1">
    <div class="container p-0 module-container align-middle image-center">
      <img src="../../../assets/img/aire-recommended.svg" *ngIf="module.medicRequested === false" />
      <img src="../../../assets/img/medic-recommended.svg" *ngIf="module.medicRequested === true" />
    </div>
  </div>
</div>
<div class="row row-pad" *ngIf="showProgress">
  <div class="col-12 progress-pad pl-1 pr-1">
    <div class="container progress-container" style="margin-top:-.5em;">
      <div class="progress rounded-pill" *ngIf="module.medicRequested != null">
        <span class="progress-value" *ngIf="module.medicRequested != null">{{module.percentComplete}}%</span>
        <div class="progress-bar primary-background" *ngIf="module.percentComplete > 0 && module.medicRequested != null" [style.width]="module.percentComplete + '%'"></div>
      </div>
    </div>
  </div>
</div>
</div>
