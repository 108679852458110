import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '@noctem/web';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    
    constructor(public appState?: ApplicationStateService) { }

    ngOnInit() {
    }

}
