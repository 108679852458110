import { pcl5Short } from './pcl5-short';
import { teamDynamics } from './team-dynamics/team-dynamics';
import { teamCommunication } from './team-communication/team-communication';
import { vacds } from './vacds';
import { sleepAids } from './sleep-aids/sleep-aids';
import { sleepAndHighTempoOps } from './sleep-and-high-tempo-ops/sleep-and-high-tempo-ops';
import { sleepAndNightOps } from './sleep-and-night-ops/sleep-and-night-ops';
import { sleepAreaSafeguarding } from './sleep-area-safeguarding/sleep-area-safeguarding';
import { sleepBanking } from './sleep-banking/sleep-banking';
import { twoInternalSystems } from './two-internal-sleep-systems/two-internal-sleep-systems';
import { aceDaytimeSleep } from './ace-daytime-sleep/ace-daytime-sleep';
import { controlJetLag } from './control-jet-lag/control-jet-lag'
import { dangersOfFatigue } from './dangers-of-fatigue/dangers-of-fatigue';
import { dreamRetraining } from './dream-retraining/dream-retraining';
import { fatigueScanning } from './fatigue-scanning/fatigue-scanning';
import { gad7 } from './gad7';
import { gaugeSleepHealth } from './gauge-sleep-health/gauge-sleep-health';
import { upYourSleepArea } from './up-your-sleep-area/up-your-sleep-area';
import { strategicCaffeine } from './strategic-caffeine/strategic-caffeine';
import { unwindBeforeBed } from './unwind-before-bed/unwind-before-bed';
import { vactcs } from './vactcs/vactcs';
import { sleepCheckin } from './sleep-check-in';
import { buildConfidence } from './build-confidence/build-confidence';
import { grounding } from './grounding/grounding';
import { powerOfSleep } from './power-of-sleep/power-of-sleep';
import { prepareThroughVisualization } from './prepare-through-visualization/prepare-through-visualization';
import { compartmentalization } from './compartmentalization/compartmentalization';
import { relaxation } from './relaxation/relaxation';
import { strategicNapping } from './strategic-napping/strategic-napping';
import { tacticalBreathing } from './tactical-breathing/tactical-breathing';


export const ModulePageDefinitions = [
  aceDaytimeSleep,
  controlJetLag,
  dangersOfFatigue,
  buildConfidence,
  dreamRetraining,
  fatigueScanning,
  gad7,
  gaugeSleepHealth,
  grounding,
  compartmentalization,
  upYourSleepArea,
  strategicCaffeine,
  unwindBeforeBed,
  pcl5Short,
  sleepCheckin,
  teamDynamics,
  teamCommunication,
  prepareThroughVisualization,
  strategicNapping,
  relaxation,
  powerOfSleep,
  vacds,
  vactcs,
  sleepAids,
  sleepAndHighTempoOps,
  tacticalBreathing,
  twoInternalSystems,
  sleepAndNightOps,
  sleepAreaSafeguarding,
  sleepBanking,
  twoInternalSystems
];
