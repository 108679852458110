
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

export function getAllActionTypes() {
  // TODO: Stored locally for now.  Revisit
  return of([{ Id: 1, ActionType: 3, Description: 'Baseline' }, { Id: 2, ActionType: 4, Description: 'Week 1' }, { Id: 3, ActionType: 5, Description: 'Week 2' }, { Id: 4, ActionType: 6, Description: 'Week 3' }, { Id: 5, ActionType: 7, Description: 'Week 4' }, { Id: 6, ActionType: 8, Description: 'Follow Up ' }, { Id: 7, ActionType: 9, Description: 'Check In' }, { Id: 8, ActionType: 10, Description: 'Week 5' }, { Id: 9, ActionType: 11, Description: 'Follow Up 2' }, { Id: 10, ActionType: 12, Description: 'Follow Up 3' }, { Id: 16, ActionType: 999, Description: 'Other' }]);
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  rootUrl = '/api/';
  constructor(
    private http: HttpClient
  ) {

  }

  getEvent(start: any, end: any, ownerid?: number) {
    const startDate = start ? start.toString().substring(0, start.toString().length - 3) : '';
    const endDate = end ? end.toString().substring(0, end.toString().length - 3) : '';
    return Observable.create(observer => {
      this.http.get(`${this.rootUrl}/event/${ownerid ? '?display=true&sownerid=' + ownerid : ''}&start=${startDate}&end=${endDate}`).subscribe(res => {
        if (res) {
          observer.next(res);
        } else {
          observer.next();
        }
      });
    });
  }


}


