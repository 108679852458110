import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Inject, OnDestroy  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService, Assessment, USER_STATE_SERVICE, UserStateService, GroupService } from '@noctem/web';
import * as jsPDF from 'jspdf';
import 'zone.js/dist/zone';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { APPLICATION_ORGANIZATION } from '../../../../../../../noctem-lib/src/constants/constants';
import { userAgreements } from '../../../../../../../noctem-lib/src/constants/definitions/user-agreements';

@Component({
  selector: 'app-print-consent',
  templateUrl: './print-consent.component.html',
  styleUrls: ['./print-consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintConsentComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    private groupService: GroupService
    ) { }
  @ViewChild('page') page;
  assessment: Assessment = new Assessment();
  public consent: any = {};
  public template:any;
  public initials:Array<string> = [];
  public group = null;
  private printCount:number = 0;
  destroy$: Subject<any> = new Subject();

  ngOnInit() {
    this.userStateService.state$.pipe(
      takeUntil(this.destroy$),
      filter((userState: any) => userState.User)
      ).subscribe(userState => {
      const user = userState.User;
      const userGroup = user.groups?.find(g =>  (g.id ?? g._id) !== APPLICATION_ORGANIZATION._id);
      this.groupService.getAll().subscribe(groups => {
        const group = groups.find(g => g.__i.guid === (userGroup._id ?? userGroup.id));
        this.group = group;
        this.template = userAgreements.ClinicianConsents.find(c => c.id === group.consentId ?? 0);
        this.replaceConsentText();
      });
    });


    this.route.params.subscribe(params => {
      if(params.id){
        this.assessmentService.getConsent(params.id).pipe(takeUntil(this.destroy$)).subscribe(assessment => {
          this.assessment = assessment;
          const group = assessment?.groups[0];
          this.consent = {};
          this.initials = [];
          if(group){
            for(let i = 0; i < group.answers.length; i++)
            {
              const answerValue = group.answers[i].value;
              switch(group.answers[i].uniqueAnswerId) {
                case 'DATE':
                  this.consent.DATE = answerValue;
                  break;
                case 'TIME':
                  this.consent.TIME = answerValue;
                  break;
                case 'SIGNATURE':
                  this.consent.signature = answerValue;
                  break;
                default:
                  this.initials.push(group.answers[i].value);
                  break;
              }
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
}

  print(){
    window.print();
  }

  private replaceConsentText(): string {

    return this.template.pages.forEach(page => {

            page.content = page.content.replace(/\[pi_name\]/g, this.group.pi?.name ?? '')
              .replace(/\[pi_phone\]/g, this.group.pi?.phone ?? '')
              .replace(/\[pi_email\]/g, this.group.pi?.email ?? '')
              .replace(/\[coordinator_name\]/g, this.group.coordinator?.name ?? '')
              .replace(/\[coordinator_phone\]/g, this.group.coordinator?.phone ?? '')
              .replace(/\[coordinator_email\]/g, this.group.coordinator?.email ?? '')
              .replace(/\[site_name\]/g, this.group?.name ?? '')
              .replace(/\[doctor\]/g, this.group?.doctor ?? '')
              .replace(/\[poc\]/g, this.group?.poc ?? '')
              .replace(/\[provider_name\]/g, this.group?.provider_name ?? '')
              .replace(/\[provider_contact_name\]/g, this.group?.provider_contact_name ?? '')
              .replace(/\[provider_contact_number\]/g, this.group?.provider_contact_number ?? '');
    });
  }

  public printPdf() {
    const doc = new jsPDF(1, 'px', [595, 892]);

    const pdfPage = this.page.nativeElement.innerHTML;

    this.printCount = 0;
    const pages = pdfPage.replace(/(Page Break) <\/div>/g, '</div>(Page Break)').split('(Page Break)');

    this.addHtmlToDoc(doc, pages);
  }

  private addHtmlToDoc(doc, pages) {
    doc.fromHTML(pages[this.printCount], 35, 35, {
      width: 520
    }, _ => {
      if (this.printCount === this.template.pages.length - 1) {
        doc.save('consent-' + this.initials[0] + '-' + (new Date()).getTime() + '.pdf');
      } else {
        doc.addPage();
        this.printCount++;
        this.addHtmlToDoc(doc, pages);
      }
  });
  }
}
