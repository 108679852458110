import { TreatmentPhase } from '../../../lib/state';
import { AssessmentTemplate } from '../../../lib/services/models';

export const weekFiveDef = {
  name: 'post',
  label: 'Post',
  order: 6,
  assessments: [
    AssessmentTemplate.morningLog.name,
    AssessmentTemplate.eveningLog.name,
    AssessmentTemplate.niqW.name,
    AssessmentTemplate.isi.name,
    AssessmentTemplate.essWeekly.name,
    AssessmentTemplate.sleepGoalsPost.name,
    AssessmentTemplate.shiftWork.name,
    AssessmentTemplate.trauma.name,
    AssessmentTemplate.phq8.name,
    AssessmentTemplate.phq2.name, //1.5 will use phq2 version
    AssessmentTemplate.gad7.name,
    AssessmentTemplate.gad2.name, //1.5 will use gad2 version
    AssessmentTemplate.peg.name,
    AssessmentTemplate.promis.name,
    AssessmentTemplate.sideEffects.name,
    AssessmentTemplate.medicationUse.name,
    AssessmentTemplate.pgi.name,
    AssessmentTemplate.nps.name,
   // AssessmentTemplate.sus2.name, //no longer needed in 1.5
    AssessmentTemplate.nsi.name
  ]
} as TreatmentPhase;
