export const npsDef = {
    "name": "nps",
    "description": "Net Promoter Score",
    "instructions": "",
    "questions": [
     //commented this as it has been copied to the database
      {
        "id": 0,
        "questionText": "How likely is it that you would recommend this app-based sleep treatment to a friend or colleague who is experiencing sleep difficulties?",
        "questionEmphasis": "",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "10 Extremely likely", "value": 10 },
            { "display": "9", "value": 9 },
            { "display": "8", "value": 8 },
            { "display": "7", "value": 7 },
            { "display": "6", "value": 6 },
            { "display": "5 Neutral", "value": 5 },
            { "display": "4", "value": 4 },
            { "display": "3", "value": 3 },
            { "display": "2", "value": 2 },
            { "display": "1", "value": 1 },
            { "display": "0 Not likely at all", "value": 0 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "NPS"
      }
    
    ]
    };
    