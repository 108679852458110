import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThreadMessageDialog } from '../thread-message-dialog/thread-message-dialog.component';

@Component({
  selector: 'inbox-new-message-dialog',
  templateUrl: './inbox-dialog.component.html',
  styles: [`
  .unReadMessage{
    color:#32D03F !important;
    font-weight: 900 !important;
  }
  `]
})
export class InboxDialogComponent implements OnInit {
  @Input() modalType;
  @Input() recipientName;
  @Input() recipientId;
  @Input() newMessage?;
  @Input() userId?;
  public baseIconClass = 'icon-envelope';
  public iconClass;

  constructor(
    public dialog: MatDialog,
    ) {}

  ngOnInit() {
    this.applyClass();
  }

  ngOnChanges() {
    this.applyClass();
  }

  private applyClass() {
    if (this.newMessage) {
      this.iconClass = `${this.baseIconClass} unReadMessage`;
    } else {
      this.iconClass = this.baseIconClass;
    }
  }

  public openThreadDialog() {
    this.iconClass = this.baseIconClass;
    const dialogRef = this.dialog.open(ThreadMessageDialog, {
      width: '750px',
      data: {
        recipientName: this.recipientName,
        recipientId: this.recipientId,
        userId: this.userId
      } // if this is present, we are going to hide/skip recipient selector
    });
    dialogRef.afterClosed().subscribe(result => {
    
    });
  }

}
