import { Component, HostBinding, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
declare var $: any;
import { Gtag } from 'angular-gtag';

import { SettingsService } from './core/settings/settings.service';
import { ServiceBus } from './core/state/service-bus.service';
import { ApplicationStateService } from '@noctem/web';
import { environment } from '../environments/environment';
import { NetworkService } from '../../../../noctem-lib/src/lib/services/network-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); }
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); }
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); }
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); }
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); }
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); }
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); }
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); }

    constructor(
        private meta: Meta,
        public settings: SettingsService,
        private serviceBus: ServiceBus,
        private networkService: NetworkService,
        public applicationStateService: ApplicationStateService) {
        this.applicationStateService.initialize();
        this.serviceBus.initialize();
        this.networkService.initialize();
    }

    ngOnInit() {
        // Add SEO Meta description
        this.meta.addTag({name: 'description', content:'The COAST Clinician Portal from NOCTEM Health empowers clinicians and other healthcare providers to manage the delivery of CBTI (Cognitive Behavioral Therapy for Insomnia).'});

        // Allow indexing of the production deployment, but do not follow links on the page
        if (environment.production === true) {
            this.meta.addTag({name: 'robots', content:'NOFOLLOW'});
        } else {
            this.meta.addTag({name: 'robots', content:'NOINDEX,NOFOLLOW'});
        }
        
        $(document).on('click', '[href="#"]', e => e.preventDefault());
    }
}
