import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu } from './menu';
import { routes } from './routes';
import { CalendarPageComponent, CallbackPipe } from './calendar/calendar-page.component';
import { NoctemLibModule, IsAdminGuard } from '@noctem/web';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    PagesModule,
    NoctemLibModule
  ],
  declarations: [
    CalendarPageComponent,
    CallbackPipe
  ],
  exports: [
    RouterModule
  ],
  providers: [
      IsAdminGuard
  ]
})

export class RoutesModule {
  constructor(public menuService: MenuService) {
    menuService.addMenu(menu);
  }
}
