<div class="container-fluid" *ngIf="stateService.state$ | async as appState">
    <div class="row">
        <div class="col-6">
            <h3 class="homeTitle">Clinic-level Reporting</h3>
            <small>Download a *.csv for a given timeframe.</small>
            <div *ngFor="let clinic of RTMClinics">
            <h4 class="pt-4">{{ clinic.display }}</h4>
            <table class="">
                <tr>
                    <td class="w-100px">RTM Billing Report:</td>
                    <td class="text-right"><a href="#" (click)="getRTMReportWrapper(clinic._id, clinic.display, 1)">Current Month</a></td>
                    <td class="text-right"><a href="#" (click)="getRTMReportWrapper(clinic._id, clinic.display, 3)">Last 3 Months</a></td>
                    <td class="text-right"><a href="#" (click)="getRTMReportWrapper(clinic._id, clinic.display, 6)">Last 6 Months</a></td>
                </tr>
            </table>
            </div>
        </div>
    </div>
</div>

