<div class="ng-data-picker flex-box">
  <!-- picker-group-layer -->
  <div #pickerGroupLayer *ngFor="let group of data; let gIndex = index" class="picker-group"
    [ngClass]="getGroupClass(gIndex)">
    <div class="picker-list">
      <ng-container *ngFor="let item of group.text; let iIndex = index">
        <div *ngIf="group.divider; else ngIfElse" class="picker-item divider"
          [ngClass]="getItemClass(gIndex, iIndex, true)">
          {{ group.text }}
        </div>

        <ng-template #ngIfElse>
          <div class="picker-item" [ngClass]="getItemClass(gIndex, iIndex)" [ngStyle]="getItemStyle(gIndex, iIndex)">
            {{ item }}
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div #pickerHandleLayer class="picker-handle-layer flex-box dir-column">
    <div class="picker-top weight-1">
      <div data-type="top" class="picker-top-select"></div>
      <div data-type="middle-top" class="picker-middle-top-select"></div>
    </div>
    <div data-type="middle" class="picker-middle"></div>
    <div class="picker-bottom weight-1">
      <div data-type="middle-bottom" class="picker-middle-bottom-select"></div>
      <div data-type="bottom" class="picker-bottom-select"></div>
    </div>
  </div>
</div>