import { UserAgreement } from "../../../../core";

export default new UserAgreement({
  id: 2,
  name: 'consent',
  title: 'Consent',
  textReplacementMethod: 'replaceConsentText',
  agreementQuestions: [
    {
      answerId:'haveReviewed',
      text: 'I have reviewed this information and agree to use the NOCTEM&trade; COAST&trade; application with my provider'
    }],
  pages: [{
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Mobile App Use and Disclosures</div>
      <ul>
          <li>NOCTEM®, LLC respects the privacy of our users. The following Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application.</li>
          <li>Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not use the COAST™ application.</li>
          <li>Your provider can tell you about other options to manage sleep issues that do not use the COAST™ system.</li>
          <li>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about changes via email. </li>
          <li>You can access a copy of all the information presented here on the NOCTEM® COAST™ app and a statement informing you about the provisions of the Privacy Act.</li>
        </ul>
      </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Collection of your Information</div>
      <p>All information will be collected electronically. Information we may collect through the application depends on the content and features you use.</p>
      <p><u>Personal Data</u></p>
      <ul>
          <li>You will be asked to provide some demographic information (for example: age, sex, rank, MOS, information about your sleep and wake behaviors that impact sleep and biological cycles, mood and stress symptoms, as well as physical symptoms you may already be experiencing or that occur during the study).</li>
          <li>You will also be asked feedback about if you feel the recommendations you receive are helpful or not, and feedback you have about the app. </li>
        </ul>
      </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Collection of your Information (cont'd)</div>
      <p><u>Mobile Device Data</u></p>
      <ul>
        <li>Your phone number and device unique identifier will be collected and stored over the duration of the project. It will be deleted and de-identified at the completion of the project. </li>
      </ul>
      <p><u>Push Notifications</u></p>
      <ul>
        <li>We may request to send you push notifications. If you wish to opt-out from receiving push notifications, you may turn them off in your device’s settings at any time. </li>
      </ul>
      <p><u>Geo-Location Information</u></p>
      <ul>
        <li>The application does not collect your GPS location.</li>
      </ul>
      </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Use of your Information</div>
      <ul>
        <li>Your information will be used by your provider to support addressing your sleep complaints.</li>
        <li>NOCTEM will maintain your information indefinitely.  Maintenance of data will allow for further analysis, as well as comparison of this dataset to others. Information gleaned from such analyses may increase our knowledge about sleep disturbances in service members, and may better inform sleep treatments, thereby increasing total force fitness and readiness.</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Disclosure of your Information</div>
      <ul>
        <li>Your provider will have access to your information and know it belongs to you. <u>DoDI 6490.08 rules for protecting and disclosing your information will be followed</u>.</li>
        <li>None of your information will be shared or sold to a third party.</li>
        <li>NOCTEM maintains full control over the data collected in this project.</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Security of your information</div>
      <ul>
        <li>Security and confidentiality in a mobile health application is of paramount importance.</li>
        <li>Despite our careful steps to protect confidentiality, please be aware that confidentiality during internet communication and text messaging activities cannot be guaranteed even if every reasonable effort may be taken to assure security. </li>
        <li>Like for the regular use of your smart devices, information may be intercepted and used by others not associated with this project.</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Security of your information (cont'd)</div>
      <p>We use layered security measures to protect the privacy and confidentiality of your data, including:</p>
      <ul>
        <li>The use of a forced PIN/password to access the app; </li>
        <li>A strong authentication procedure that combines a PIN/password, the phone number of the device, and the device’s unique International Mobile Equipment Identity (IMEI) number</li>
        <li>Encryption of all communication between the smartphone and the server using the AES (Advanced Encryption Standard) 192-bit key</li>
        <li>A security method that allows the NOCTEM team to disable the app and to erase records from the device in the event of a lost smartphone while preserving the records on the secure server</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Security of your information (cont'd)</div>
      <ul>
        <li>A secure messaging system between the sleep point of contact’s (Provider) portal and your smartphone will be used in the place of SMS/text messaging</li>
        <li>A secure server behind a firewall with security policy in place that includes a dedicated cloud, regular back-up of data, a monitored network with active security measures, and a well-defined, role-based, access to the database.</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Contact Us</div>
      <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
      <p>NOCTEM, LLC</p>
      <p>218 Oakland Ave</p>
      <p>Pittsburgh, PA 15213</p>
      <p><a href='mailto:support@noctemhealth.com'>support@noctemhealth.com</a></p>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Privacy Act Statement</div>
      <ul>
        <li><b>AUTHORITY: </b>32 CFR Part 219, Protection of Human Subjects; 45 CFR Part 46, Protection of Human Subjects; DoDI 3216.02, “Protection of Human Subjects and Adherence to Ethical Standards in DoD-Supported Research,” October 20, 2011; 45 CFR Parts 160 and 164, Health Insurance Portability and Accountability Act (HIPAA) Privacy and Security Rules; and E.O. 9397 (SSN), as amended.</li>
        <li><b>PURPOSE: </b>Information is collected to enhance basic medical knowledge, or develop tests, procedures, and equipment to improve diagnosis, treatment, or prevention of illness, injury, or performance impairment under research protocol NHRC.2019.0018, entitled “Optimizing The Scalability of Evidence-Based Sleep Interventions with Digital Health Technology.”</li>
      </ul>
    </div>`
  },
  {
    title: '',
    imgPath: '',
    text: `
    <div class="consent-content">
    <div class="consent-header">Privacy Act Statement (cont'd)</div>
      <li><b>ROUTINE USES:</b>In addition to those disclosures generally permitted under 5 U.S.C. 552a(b) of the Privacy Act of 1974, the DoD “Blanket Routine uses” under 5 U.S.C. 552a(b)(3) apply to this collection. Medical research information will be used for analysis and reports by the Department of Defense, and other U.S. Government agencies, provided this use is compatible with the purpose for which the information was collected. Use of the information may be granted to non-Government agencies or individuals by the Navy Surgeon General and/or the defense Health Agency following the provisions of the Freedom of Information Act or as may be indicated in the accompanying Informed Consent Form.</li>
      <li><b>DISCLOSURE:</b>Provision of information is voluntary. There are no penalties for not providing requested information, but failure to provide the requested information may result in failure to be accepted as a research volunteer in an experiment or removal from the program.</li>
    </div>`
  }
]
});
