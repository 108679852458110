import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { unwindBeforeBed } from '../questions/unwind-before-bed/unwind-before-bed';


export const unwindBeforeBedDef: AssetDefinition = {
  id: 17,
  name: 'unwind-before-bed',
  label: 'Unwind Before Bed',
  description: `
  In training and deployed environments, it can be difficult to quickly shift from being vigilant to sleep-ready. Help your Soldiers make this shift quickly by building a pre-sleep wind down routine.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    unwindBeforeBed
  ],
  url: 'unwind-before-bed'
};
