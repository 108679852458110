export const sleepGoalsPreDef = {
  "name": "sleepGoalsPre",
  "description": "Sleep Goals",
  "instructions": "",
  "questions": [
    {
      "id": 0,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Fall asleep quickly",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_QUICK"
    },
    {
      "id": 1,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Stay asleep throughout the entire night",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_STAY"
    },
    {
      "id": 2,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Wake up < 3 times during the night",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_THREE"
    },
    {
      "id": 3,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Feel refreshed when I wake up",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_REFRESHED"
    },
    {
      "id": 4,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Get rid of bad dreams",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_NIGHTMARES"
    },
    {
      "id": 5,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "No more racing thoughts at bedtime",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_RACING"
    },
    {
      "id": 6,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Share bed with bed partner",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_SHARE_BED"
    },
    {
      "id": 7,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Get along better with other people",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_GETALONG"
    },
    {
      "id": 8,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Not yell at other people",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_YELL"
    },
    {
      "id": 9,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Less tossing and turning in bed",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_TOSSING"
    },
    {
      "id": 10,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Stop using alcohol to help me fall asleep",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_ALCOHOL"
    },
    {
      "id": 11,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Stop using sleep aids to help me fall asleep",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_SLEEP_AIDS"
    },
    {
      "id": 12,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Better quality sleep to improve my general health",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_HEALTH"
    },
    {
      "id": 13,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Better quality sleep to improve my performance at work",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_WORK"
    },
    {
      "id": 14,
      "questionText":
        "Some common sleep goals are listed.  Select the goals that are important to you.",
      "questionEmphasis": "Other:",
      "questionType": "text",
      "autofill": false,
      "answerOptions": [],
      showNotApplicableHint: true,
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_GOALS_PRE_OTHER"
    },
    {
      "id": 15,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "No TV while in bed",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_TV"
    },
    {
      "id": 16,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "No phone while in bed",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_PHONE"
    },
    {
      "id": 17,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "Remembering to complete daily morning and evening logs",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_LOGS"
    },
    {
      "id": 18,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "No caffeine 6-8 hours before bedtime",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_CAFFEINE"
    },
    {
      "id": 19,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "Changes to my sleep schedule that may impact my spouse/family",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_SCHEDULE"
    },
    {
      "id": 20,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "Digital technology lacks a personal touch",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_TECH"
    },
    {
      "id": 21,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "Changing habits is hard for me",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_HABITS"
    },
    {
      "id": 22,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      // eslint-disable-next-line quotes
      "questionEmphasis": "Giving up some of my 'me' time",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_ME"
    },
    {
      "id": 23,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "I don’t remember what it is like to sleep well",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        { "display": "Yes", "value": true },
        { "display": "No", "value": false }
      ],
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_WELL"
    },
    {
      "id": 24,
      "questionText":
        "Some common challenges are listed. Select those you anticipate may be difficult for you.",
      "questionEmphasis": "Other:",
      "questionType": "text",
      "autofill": false,
      "answerOptions": [],
      showNotApplicableHint: true,
      "conditionalQuestions": [],
      "uniqueAnswerId": "SLEEP_CHALLENGES_PRE_OTHER"
    }
  ]
};
