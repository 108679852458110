import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const phq9: Array<FieldDefinitionBase> =
[
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'phq-overall',
    unit: UnitType.Number,
    abbreviation: 'PHQ 8 Overall',
    description: `PHQ 8 Overall`,
    assessmentType: LogDefinitionTypes.PHQ9
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-interest',
    unit: UnitType.Number,
    abbreviation: 'Interest',
    description: `Interest`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_INTEREST'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-depression',
    unit: UnitType.Number,
    abbreviation: 'Depression',
    description: `Depression`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_DEPRESSION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-sleep',
    unit: UnitType.Number,
    abbreviation: 'Sleep',
    description: `Sleep`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_SLEEP'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-fatigue',
    unit: UnitType.Number,
    abbreviation: 'Fatigue',
    description: `Fatigue`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_FATIGUE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-appetite',
    unit: UnitType.Number,
    abbreviation: 'Appetite',
    description: `Appetite`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_APPETITE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-failure',
    unit: UnitType.Number,
    abbreviation: 'Failure',
    description: `Failure`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_FAILURE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-concentration',
    unit: UnitType.Number,
    abbreviation: 'Concentration',
    description: `Concentration`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_CONCENTRATION'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-slowly',
    unit: UnitType.Number,
    abbreviation: 'Slowly',
    description: `Slowly`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_SLOWLY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'phq9-si',
    unit: UnitType.Number,
    abbreviation: 'Suicidal Ideation',
    description: `Suicidal Ideation`,
    assessmentType: LogDefinitionTypes.PHQ9,
    answerId: 'PHQ_SI'
  }),
];
