import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const peg: AssessmentDefinition = {
  name: 'peg',
  description: 'Pain, Enjoyment, General Activity',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText:
        'On a scale of 0 (NO PAIN AT ALL) to 10 (PAIN AS BAD AS YOU CAN IMAGINE), what number best describes your pain in the past week:',
      questionType: 'select',
      uniqueAnswerId: 'PAIN_SCALE',
      autofill: false,
      answerOptions: [
        { display: '0', value: 0 },
        { display: '1', value: 1 },
        { display: '2', value: 2 },
        { display: '3', value: 3 },
        { display: '4', value: 4 },
        { display: '5', value: 5 },
        { display: '6', value: 6 },
        { display: '7', value: 7 },
        { display: '8', value: 8 },
        { display: '9', value: 9 },
        { display: '10', value: 10 }
      ],
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          id: 1,
          questionText:
            'On a scale of 0 (DOES NOT INTERFERE) to 10 (COMPLETELY INTERFERED),  what number best describes how your pain interfered with your enjoyment of life in the past week',
          questionType: 'select',
          autofill: false,
          answerOptions: [
            { display: '0', value: 0 },
            { display: '1', value: 1 },
            { display: '2', value: 2 },
            { display: '3', value: 3 },
            { display: '4', value: 4 },
            { display: '5', value: 5 },
            { display: '6', value: 6 },
            { display: '7', value: 7 },
            { display: '8', value: 8 },
            { display: '9', value: 9 },
            { display: '10', value: 10 }
          ],
          uniqueAnswerId: 'PAIN_SCALE_ENJOYMENT',
          conditionalQuestions: []
        },
        {
          displayIfAnswerEquals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          id: 2,
          questionText:
            'On a scale of 0 (DOES NOT INTERFERE) to 10 (COMPLETELY INTERFERED),  what number best describes how your pain interfered with your general activity in the past week',
          questionType: 'select',
          autofill: false,
          answerOptions: [
            { display: '0', value: 0 },
            { display: '1', value: 1 },
            { display: '2', value: 2 },
            { display: '3', value: 3 },
            { display: '4', value: 4 },
            { display: '5', value: 5 },
            { display: '6', value: 6 },
            { display: '7', value: 7 },
            { display: '8', value: 8 },
            { display: '9', value: 9 },
            { display: '10', value: 10 }
          ],
          uniqueAnswerId: 'PAIN_SCALE_ACTIVITY',
          conditionalQuestions: []
        }
      ]
    }
  ]
};
