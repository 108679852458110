import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';


export const grounding: AssessmentDefinition = {
  name: 'grounding',
  description: 'Grounding',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: '',
      questionEmphasis: `Learn`,
      subText: `
        <p>Anxiety and stressful situations can make your thoughts erratic, bouncing around in an unhelpful way – usually about the past or worrying about the future.
        To help bring these thoughts under control you can engage in a simple exercise. You will bring your attention and awareness to things in the present. Focusing on
        your senses will help with this.
        </p>
        <p class='center-align'><img class='module-image-background' src='./assets/img/module-images/grounding/senses.png'/></p>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
    {
      id: 0,
      questionText: '',
      questionEmphasis: ``,
      subText: `
        <div class='call-out'>
          <h5 class='module'>Practice</h5>
          Begin by paying attention to your breathing - see the Tactical Breathing exercise. Once you've taken a few slow breaths
          go through the 3 steps.<br><br>
          <b>You can focus your attention on anything in your surroundings</b><br><br>
          Examples might include seeing a vehicle or your boot, the feel of your uniform on your skin or the sun on your face, and hearing a motor whir or
          your belly rumbling.<br><br>
          Focus on each item/sensation for a few seconds, label it, and fully appreciate it - what colors are in the item, what is its texture, etc.<br><br>
          <h5 class='module'>Activity 1</h5>
          Right now go through this exercise.
        </div>
        <div class='call-out'>
          <h5 class='module'>Activity 2</h5>
          When starting to feel stressed, overwhelmed, or preoccupied with unhelpful thoughts about the past or future, go through these steps.
        </div>
      `,
      questionType: 'module',
      uniqueAnswerId: null,
      autofill: false,
      answerOptions: null,
      conditionalQuestions: [],
      isCheckInQuestion: false
    },
  ]
};
