<div class="sendMessageModal" *ngIf="stateService.state$ | async as state">
    <div class="messagesScroll">
        <div class="flex-column messagesCompose" #scrollMe [scrollTop]="scrollMe.scrollHeight">
            <div class="messageArea" *ngFor="let message of decorateWithHeaders(messages)">
                <div class="date-header" *ngIf="message.dateHeader">
                    <div class="date-header-line"></div>
                    <div class="date-header-text">
                        <div class="date-header-text-label">{{ message.dateHeader }}</div>
                    </div>
                </div>
                <div [class.send]="!message.fromRecipient" [class.receive]="message.fromRecipient">
                    <div class="message-content">{{message.content}}</div>
                    <div class="message-header">
                        <span class="sendID date">{{dateAgo(message.sentOn | date : 'medium')}},</span>
                        <span class="sendID sender"> {{message.senderDisplay}}</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="messageCompose" *ngIf="!readonly">
        <div class="row">
            <div class="composition-container">
                <!--<div class="actions"><a href="#" class="attachement"><i class="fas fa-paperclip"></i></a></div>-->
                <textarea [(ngModel)]="state.messageBody" (keyup)="stateService.setMessageBody($event.target, false)"
                    class="form-control messageBox"
                    placeholder="Write your message...">{{state.messageBody}}</textarea>
                <div (click)="send()" class="sendingIcon row"><button type="button"
                        class="btn btn-primary">Send</button></div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center inactivePatientMessage mr-2" *ngIf="readonly">
        <div class="row">Patient is inactive and unable to receive messages.</div>
    </div>
</div>