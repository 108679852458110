import { Component, OnInit, Inject, HostListener, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
import { DatePipe } from '@angular/common';
import { USER_STATE_SERVICE, AssessmentService } from '@noctem/web';
import { UserStateService } from '@noctem/web';
import { filter } from 'rxjs/operators';
import { Group } from '../../../../../../../../../noctem-lib/src/lib/services/models';
import { GroupService } from '../../../../../../../../../noctem-lib/src/lib/services';
import { userAgreements } from '../../../../../../../../../noctem-lib/src/constants/definitions/user-agreements';
import { APPLICATION_ORGANIZATION } from '../../../../../../../../../noctem-lib/src/constants/constants';
@Component({
    selector: 'consent-dialog',
    templateUrl: './consent-dialog.component.html' ,
})
export class ConsentDialog implements OnInit {
    pageNumber: number = 0;
    @ViewChild('scrollDiv') scrollDiv;
    currentPageInitials = null;
    end: boolean = false;
    initPlaceholder: string = "Please read the entire page (scroll down)";
    pipe = new DatePipe('en-US');
    startDate: Date;
    user: any;
    group:Group;
    template:any;
    checkEULA:boolean = false;

    consent: any = {
        DATE : this.pipe.transform(Date.now(), 'yyyy-MM-dd'),
        TIME : this.pipe.transform(Date.now(), 'HH:mm')
    };
    constructor(
        public dialogRef: MatDialogRef<ConsentDialog>,
        private assessmentService: AssessmentService,
        @Inject(USER_STATE_SERVICE) public userStateService: UserStateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private groupService: GroupService
    ) { }


    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
      this.checkScrollState(event.target.offsetHeight + event.target.scrollTop, event.target.scrollHeight, false);
    }

    next(): void {

      const prev = this.pageNumber-1;
      this.consent['INITIAL' + this.pageNumber] = this.currentPageInitials;
      if(this.pageNumber==0 || this.consent['INITIAL'+prev].toLowerCase()==this.consent['INITIAL'+this.pageNumber].toLowerCase()){
        this.pageNumber++;
        this.end = false;
        if(!this.consent['INITIAL'+this.pageNumber])
        {
          setTimeout(() => {
            this.scrollDiv.nativeElement.scrollTo(0,0);
            this.checkScrollState(this.scrollDiv.nativeElement.offsetHeight, this.scrollDiv.nativeElement.scrollHeight, true);
          }, 0);
        }
        this.currentPageInitials = this.consent['INITIAL' + this.pageNumber + 1];

      }
      else{
          alert('Please use the same initial on all consent pages');
      }
    }
    prev(): void {
        this.pageNumber--;
        this.currentPageInitials = this.consent['INITIAL' + this.prev];
    }
    private updateConsentFlag(consentID: string){
      if (!this.user.applicationData) {
        this.user.applicationData = {};
    }
    if (!this.user.applicationData.consentObtainedOn) {
      this.user.applicationData.consentObtainedOn = new Date().toISOString();
      this.user.applicationData.consentId = consentID;
        this.userStateService.save(this.user).subscribe();
    }
    }


    private updateEULAFlag(){
      if (!this.user.applicationData) {
        this.user.applicationData = {};
      }
      if (!this.user.applicationData.eulaAgreedOn) {
        this.user.applicationData.eulaAgreedOn = new Date().toISOString();
          this.userStateService.save(this.user).subscribe();
      }
    }

    async submit() {
        const answerGroup: any = {};
        answerGroup.type = 'clician-consent';
        answerGroup.answers = [];
        let consentAssessment: any = {};
        if(this.template.name === 'eula')
        {
          this.consent["SIGNATURE"] = "ACCEPTED";
        }
        else{
          for (const answer in this.consent){
              let answerInstance = {};
              answerInstance = {
                index: 1,
                value: this.consent[answer],
                uniqueAnswerId:  answer
              }
              answerGroup.answers.push(answerInstance);
          }
        }
        consentAssessment = {
            comment: 'CLINICIAN CONSENT',
            startTime: this.startDate,
            endTime: new Date(),
            assessmentDate: new Date(this.consent.DATE),
            groups: []
        }
        consentAssessment.groups.push(answerGroup);

        if(this.data.modalType === 'consent') {
          const obj = await this.assessmentService.save(consentAssessment).toPromise();
          this.updateConsentFlag(obj.__i.guid);
        }
        else{
          this.updateEULAFlag();
        }
        this.dialogRef.close();
    }

    ngOnInit() {
        this.startDate = new Date();
        this.userStateService.state$.pipe(
          filter((userState: any) => userState.User))
          .subscribe(userState => {
          this.user = userState.User;
          const userGroup = this.user.groups?.find(g =>  g.display?.toLowerCase() !== APPLICATION_ORGANIZATION.name.toLowerCase() && g.Name?.toLowerCase() !== APPLICATION_ORGANIZATION.name);
          this.groupService.getAll().subscribe(groups => {
            const isEULA = (this.data.modalType === 'EULA');
            this.group = groups.find(g => g.__i.guid === (userGroup._id ?? userGroup.id));
            if(isEULA)
              this.template = userAgreements.ClinicianEULAs.find(c => c.id === (this.group.eulaId == null ? 0 : this.group.eulaId));
            else
              this.template = userAgreements.ClinicianConsents.find(c => c.id === (this.group.consentId == null ? 0: this.group.consentId));
            this.replaceConsentText();
            setTimeout(() => this.checkScrollState(this.scrollDiv.nativeElement.offsetHeight, this.scrollDiv.nativeElement.scrollHeight, true), 0);
          });
      });
    }


    private checkScrollState(elementHeight:number, scrollHeight:number, init:boolean){
      if(elementHeight >= scrollHeight - (elementHeight * .01)) {
        this.initPlaceholder = "Initial";
        this.end = true;
      }
      else if (init){
        this.initPlaceholder = "Please read the entire page (scroll down)";
        this.end = false;
      }
    }


    private replaceConsentText(): string {
      if(!this.group || !this.template || !this.template.pages){
        return "";
      }

      for(let i = 0; i < this.template.pages.length; i++){
        this.template.pages[i].content = this.template.pages[i].content?.replace(/\[pi_name\]/g, this.group?.pi?.name ?? '')
                .replace(/\[pi_phone\]/g, this.group?.pi?.phone ?? '')
                .replace(/\[pi_email\]/g, this.group?.pi?.email ?? '')
                .replace(/\[coordinator_name\]/g, this.group?.coordinator?.name ?? '')
                .replace(/\[coordinator_phone\]/g, this.group?.coordinator?.phone ?? '')
                .replace(/\[coordinator_email\]/g, this.group?.coordinator?.email ?? '')
                .replace(/\[site_name\]/g, this.group?.name ?? '')
                .replace(/\[doctor\]/g, this.group?.doctor ?? '')
                .replace(/\[poc\]/g, this.group?.poc ?? '')
                .replace(/\[provider_name\]/g, this.group?.provider_name ?? '')
                .replace(/\[provider_contact_name\]/g, this.group?.provider_contact_name ?? '')
                .replace(/\[provider_contact_number\]/g, this.group?.provider_contact_number ?? '');
      }
      return this.template;

    }

}
