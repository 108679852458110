<div class="container mt-5">

    <div class="row" [class.no-side-display]="!showSideDisplay" [class.side-display]="showSideDisplay">
        <div class="col-2 p-0">
            <mat-slider #slider class="{{colorClass}}" [disabled]="disabled" [invert]="true" [max]="max" [min]="min"
                [step]="1" [thumbLabel]="thumbLabel" [tickInterval]="tickInterval" [(ngModel)]="value"
                [vertical]="vertical" (change)="sliderUpdated()">
            </mat-slider>
        </div>
        <div class="col-10 d-flex">
            <div class="container" *ngIf="showSideDisplay">
                <div class="row m-3 align-items-center justify-content-center"
                    style="border:2px solid #d8dae6; border-radius:3px;font-size:4em;">
                    {{value}}
                    <div class="d-flex align-items-center justify-content-center" *ngIf="value == undefined">
                        <b>-</b>
                    </div>
                </div>
                <div class="row call-out" *ngIf="value != undefined">{{valueMessage}}</div>
            </div>
            <div class="container p-0 m-0 h-100 form-check-label pl-1" *ngIf="!showSideDisplay">
                <div class="row" [style.height.px]="getLabelHeight(i)"
                    *ngFor="let opt of question.answerOptions; let i = index;">{{opt.display}}</div>
            </div>
        </div>
    </div>
</div>