import { UserAgreement } from "../../../../core";

export default new UserAgreement({
  id:3,
  name: 'consent',
  title: '',
  imgName:'',
  questionHeader: ``,
  questionSubtext: `I agree that I have been provided time to read the information describing the research study in the consent form. The content and meaning of this information has been explained to me. I have been provided with the opportunity to ask questions. I voluntarily agree to participate in this study.
  By agreeing to participate, I have not given up any of my legal rights as a research participant. I confirm that the 4 statements below are true`,
  textReplacementMethod:'replaceConsentText',
  agreementQuestions: [
    {
      answerId: 'haveReviewed',
      text: 'I have reviewed information about the NOCTEM project with a research team member and my questions were answered.'
    },
    {
      answerId: 'agree',
      text: 'I agree to participate in this research study and use the app.'
    },
    {
      answerId: 'understand',
      text: 'I understand that my de-identified data will be maintained for future use, including further analysis, as well as comparison of this dataset to others. '
    },
    {
      answerId: 'withdraw',
      text: 'I understand I can withdraw at any time and that my sleep clinician can offer other insomnia treatment or management methods. '
    }
  ],
  pages: [
      {
        title: '',
        imgPath: '',
        text: `<div class="consent-content">
        <p class="text-bold text-center mb-0">Madigan Army Medical Center (MAMC)</p>
        <p class="text-bold text-center">
          Consent to Participate in Research & Authorization to Use and Disclose Protected Health
          Information for Research
        </p>
        <p class="text-center">Meets 2018 Common Rule Requirements</p>
      
        <p>
          <span class="text-important">Site Principal Investigator:</span>  Dr. Brian O’Reilly
          <a href="tel:253-968-2731">253-968-2731</a>
        </p>
       
        <p> 
          <span class="text-important"> Key Information for Protocol:</span> CICADA (Comparing Insomnia
          Care As usual to Digital Augmentation)
        </p>
        <p class="pb-3">
        You are invited to take part in a research study. Your participation is voluntary. This page
        gives you key information about the study to help you decide whether to participate. Detailed
        information follows this page. Ask the researchers questions you have. If you have questions
        later, the contact information for the research investigator is below.
      </p>
        <p>
          <span class="text-important"
            >What are the Purpose, Procedures, and Duration of the Study?
          </span>
        </p>
        <p class="pb-3">
          By doing this study, we hope to learn more about the extent to which digital cognitive
          behavioral therapy for insomnia treatment can augment military treatment facilities capabilities
          in delivering high quality insomnia care. Your participation in this research will last about
          6-8 weeks, as well as a follow up survey 3 months later.
        </p>
        <p>
          <span class="text-important">
            What are the Key Reasons You Might Choose to Participate in this Study (Benefits)?
          </span>
        </p>
        <p class="pb-3">
          There are no direct, guaranteed benefits to you. You may find it easier to enter your sleep data
          in the COAST app rather than a standard sleep diary, and digital insomnia treatment with COAST
          may save you time that would be spent going to in-person appointments. This study may help
          researchers learn more about the effectiveness of digitally enhanced insomnia therapy in Service
          Members.
        </p>
        <p>
          <span class="text-important"
            >What are the Key Reasons You Might Choose Not to Participate in this Study (Risks and
            Alternatives)?
          </span>
        </p>
        <p class="pb-3">
          The risks or possible discomfort are minimal and do not exceed the usual risks in clinical
          practice or use of smartphones. This most common risk is a temporary increase in sleepiness due
          to following recommendations to improve sleep. You do not have to participate. If you decide not
          to participate, your sleep clinician will discuss either CBTI or other treatment options for
          insomnia.
        </p>
        <p>
          <span class="text-important">Do You Have to Take Part in this Study? </span>
        </p>
        <p class="pb-3">
          If you decide to take part in the study, it should be because you really want to volunteer. You
          will not lose any services, benefits or rights you would normally have at MAMC if you choose not
          to volunteer.
        </p>
        <p>
          <span class="text-important">What if You Have Questions, Suggestions, or Concerns? </span>
        </p>
        <p class="pb-3">
          Project Lead is Dr. Anne Germain. Her contact information is: <a href="tel:412-897-3183"> 412-897-3183 </a>  or
          <a href="mailto:anne@noctemhealth.com">anne@noctemhealth.com</a>
         
        </p>
        <p class="pb-3">
          You may also contact your local point of contact (POC), Dr. Brian O’Reilly at <a href="tel:253-968-2731">253-968-2731 </a>
           <a href="mailto:brian.m.oreilly6.civ@health.mil"> brian.m.oreilly6.civ@health.mil </a> or his research coordinator, Ms. Sem Chan at <a href="tel:253-232-7057">253-232-7057</a>
          <a href="mailto:schan@knowesis-inc.com">schan@knowesis-inc.com</a>.
        </p>
        <p class="pb-3">
          If you have any questions about your rights as a research subject or if you have concerns or
          complaints about the research, please contact the Human Research Director Ms. Sandra Smith at
          <a href="tel:253-968-0149">253-968-0149</a>.
        </p>
        <p class="pb-3">Please tell the researchers if you are taking part in another study.</p>
        <p class="pb-3">
          If you decide to take part in this research study, you will be asked to review this document.
          Before you agree to participate, be sure you understand what the research study is about in all
          sections of the consent form, including the risks and possible benefits to you.
        </p>
      </div>
      `
      },
      {
          title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
          <p class="text-bold text-center">Detailed Consent:</p>
        
          <p>
            <span class="text-important"
              >What is the Purpose and Duration of this Research and Who Will Take Part?</span
            >
            You are being asked to take part in this research study because:
          </p>
        
          <ul class="pb-3">
            <li>You are an active-duty service member</li>
            <li>You were diagnosed with insomnia by your sleep medicine provider</li>
            <li>
              Your provider determined Cognitive Behavioral Treatment for Insomnia (CBTI) delivered in a
              digital or face-to-face format was appropriate for you
            </li>
          </ul>
        
          <p>The purpose of this research study is to:</p>
          <ul>
            <li>
              Evaluate whether digital cognitive behavioral therapy for insomnia (CBTI) treatment using the
              NOCTEM® digital platform called, COAST™ (Clinician-Operated Assistive Sleep Technology) is at
              least as good as face-to-face insomnia care provided at your facility.
            </li>
            <li>
              Evaluate whether digital CBTI via COAST is at least as good as face-to-face insomnia care
              provided at your facility for mood and stress.
            </li>
            <li>
              Compare satisfaction with digital CBTI via COAST versus face-to-face insomnia care provided at
              your facility
            </li>
          </ul>
        
          <p class="pb-3">
            The duration of participation is about 6-8 weeks, as well as a follow up survey 3 months later.
            Your overall participation in the study will last 3 months.
          </p>
          <p class="pb-3">
            There will be about 188 people taking part in this study overall, across 3 different military
            sites, over a period of 14 months.
          </p>
          <p class="pb-3">
            At the end of this research study the clinical results, including research results about you
            will not be shared with you.
          </p>
        </div>
        
          `
      },
      {
        title: '',
        imgPath: '',
        text: `<div class="consent-content">
        <p class="text-important">
           What Will Happen if You Decide to be in This Research?
        </p>
       
        <p>You will: </p>
        <ol>
           <li>Download the NOCTEM®  COAST™ app on your smartphone.</li>
           <li>Complete daily Morning and Evening logs on the app. Each entry will require no more than about 90 seconds of your time.</li>
           <li>Complete weekly assessments on the app to tell your sleep provider about your sleep, mood, and possible side effects. Completing the weekly assessments will take 5 -10 minutes of your time.</li>
           <li>You will be randomly assigned to one of two insomnia treatment groups. Randomization is a process like flipping a coin and means you will have a chance of being assigned to either of the groups. You will be randomly selected to receive either (1) face-to-face insomnia care at your military treatment facility or (2) digital insomnia treatment by a NOCTEM research clinician through the COAST app.  </li>
           <li>If randomized to face-to-face insomnia care, your treatment and appointments will be coordinated through your military treatment facility.  Treatment is typically 6-8 weeks.</li>
           <li>If randomized to digital insomnia treatment via COAST, you will receive treatment recommendations for 6-8 weeks through the app and  communicate with your NOCTEM research clinician through the secure app messaging system.</li>
           <li>Both groups will complete an assessment before treatment, after treatment, and at 3 months.  These assessments may take up to 20 minutes of your time.</li>
           <li>Receive calls, text messages or email from the Research Coordinator regarding follow-ups, completion of study questionnaires or study updates.  Contact will be made for any missed logs and assessments as a reminder. 
           </li>
        </ol>
       </div>`
      },
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
        <p class="text-important">What are the Risk or Discomforts from Being in this Research?</p>
      
        <p>If you choose to take part in this study, there is a risk of:</p>
        <ul>
          <li>
            <span class="text-bold"> A temporary increase in sleepiness: </span> 10 to 25 of 100 people
            who follow the recommendations to improve sleep may experience a temporary increase in
            sleepiness. Sleepiness related to the recommendation could increase your risk of accidents
            while driving or at work. You may report side effects to your clinician at any time. This risk
            exists if you participate in the study and receive CBTI face-to-face or digitally via COAST.
            It also exists if you do not participate in the study and receive CBTI.
          </li>
          <li>
            <span class="text-bold">Some psychological discomfort when filling out assessments:</span>
            Some of the questions ask about mood and stress. 5 to 10 out of 100 people feel uncomfortable
            answering these questions. You can refuse to answer any assessments that make you
            uncomfortable.
          </li>
          <li>
            <span class="text-bold">A breach of confidentiality:</span> Although efforts are made to
            protect your research study efforts, there is always a risk that someone could get access to
            the personal information in your medical records or other information researchers have stored
            about you. Breach of confidentiality rarely occurs, in less than 1 out of 100 people.
            Maintaining strict security will minimize these risks. Study data is kept strictly
            confidential, and participants’ identities will not be revealed in any publication or other
            documents. All participants have a
            <span class="text-bold">unique identifier number </span> hat will be used on forms and for
            data storage purposes. Computer databases are protected by several procedures, including
            password protection of subject data and a firewall around the entire NOCTEM network.
          </li>
        </ul>
      </div>
      
        `
      },
      {
        title: '',
        imgPath: '',
        text:`
        <div class="consent-content">
        <p class="text-important">What are the Risk or Discomforts from Being in this Research (continued)? </p>
        <ul>
          <li>
            <span class="text-bold"> Risks specific to the use of smartphones: </span>  Be aware that confidentiality during internet communication, data entry on apps, and text messaging activities cannot be guaranteed even if every reasonable effort is taken to assure security. Just like with the regular use of your smartphone, information may be intercepted. It is always possible that this could occur during the study and your deidentified data could be accessed by others not associated with this study.  If this were to occur, you would be notified promptly regarding any breach in data security.  Security and confidentiality in a mobile health app is of paramount importance.We use layered security measures to protect the privacy and confidentiality of the data,and the COAST app does not collect your GPS location. 
      
          </li>
          <li>
              There may also be other risks of taking part in this study that we do not yet know about. You will be promptly notified if, during this research study, any new risks arise that may cause you to reconsider participation. 
          </li>
        </ul>
      </div>
        `
      },
      {
        title: '',
        imgPath: '',
        text:`
        <div class="consent-content">
        <p class="text-important">What are the Alternatives to Taking Part in this Research? </p>
      <p>There may be other options for treating your insomnia. Alternative treatments that may be available to you include: </p>
        <ul>
          <li>
              You may receive the traditional, face-to-face in person behavioral treatment for insomnia by your sleep provider or another clinician. 
      
          </li>
          <li>
              You can discuss with your provider if a sleep medication is an appropriate treatment for insomnia in your case. 
          </li>
          <li>
              You may also use web-based sleep treatment programs or stand-alone apps that provide education about sleep. 
          </li>
        </ul>
      
        <p>You should talk with your personal physician (if applicable) about these options. </p>
        <p class="text-bold text-center">
          Choosing not to take part in this research study is also an option. 
        </p>
      </div>
      `
      },
      {
          title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
          <p class="pb-3"> 
              <span class="text-important"> Is there Compensation for Your Participation in this Research? </span>  No, you will not receive any compensation for participating in this study. 
          </p>
          <p class="pb-3"> 
              <span class="text-important"> Are there Costs for Participating in this Research? </span> No, there are no costs to you for taking part in this research study. 
      
          </p>
          <p> 
              <span class="text-important"> Principal Investigator (the person(s) responsible for the scientific and technical direction of the study):</span> Dr. Brian O’Reilly <a href="tel:253-968-2731">253-968-2731</a>
          </p>
      
          <p>Overall Project Lead: Dr. Anne Germain, Founder & CEO NOCTEM, LLC</p>
          <p class="pb-3"> 
              <span class="text-important"> Study Sponsor (the organizations or persons who oversee the study and are responsible for analyzing the study data):  </span> This study is conducted by NOCTEM, LLC
          </p>
          <p>As the sponsor of this research, the Department of Defense may have access to your research data in accordance with DODI 3216.02. </p>
          <p class="pb-3"> 
              <span class="text-important"> Source of Funding:   </span> This study is partially funded by the Department of Defense, Joint Warfighter Medical Research Program
          </p>
          <p class="pb-3"> 
              <span class="text-important">Location of the Research:   </span> Madigan Army Medical Center
          </p>
          <p class="pb-3"> 
              <span class="text-important"> Disclosure of Financial Interest and Other Personal Arrangements:   </span> Dr. Anne Germain and some research team members work for NOCTEM, LLC, the company who develops, deploys, and tests the NOCTEM COAST platform. 
          </p>
      </div>
      `
      },
      {
        title: '',
        imgPath: '',
        text: `<div class="consent-content">
        <p class="pb-3">
          <span class="text-important"
            >Who Will See My Information (Privacy) and How Will It Be Protected (Confidentiality)?
          </span>
        </p>
        <p>
          Records of your participation in this research study may only be disclosed in accordance with
          state and federal law, including the Federal Privacy Act, 5 U.S.C.552a, and its implementing
          regulations. DD Form 2005, Privacy Act Statement - Military Health Records, contains the Privacy
          Act Statement for the records. A copy of DD Form 2005 can be given to you upon request, or you
          can read on-line at: <a style="word-break: break-word;" href="https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf">https://www.esd.whs.mil/Portals/54/Documents/DD/forms/dd/dd2005.pdf</a>.
        </p>
        <p class="pb-3">
          The research team will keep your research records. These records may be looked at by staff from
          the NOCTEM and local research team, the Institutional Review Board (IRB), and the DoD Higher
          Level Review as part of their duties. These duties include making sure that the research
          participants are protected to the extent possible under existing regulations and laws, but
          cannot be guaranteed.
        </p>
        <p>
          Procedures to protect the confidentiality of the data in this study include but are not limited
          to:
        </p>
        <ul>
          <li>
            All participants have a unique identifier number that will be used on forms and for data
            storage purposes.
          </li>
          <li>
            Computer databases are protected by several procedures, including password protection of
            subject data and a firewall (a barrier within a computer to protect the information from being
            viewed by unauthorized people) around the entire NOCTEM network.
          </li>
          <li>
            We use layered security measures on the COAST app such as:
      
            <ul>
              <li>
                The app can only be activated using authentication (username and password, or PIN) with a
                specific alphanumeric PIN unique to you, preventing unauthorized access.
              </li>
              <li>No phone number is used.</li>
              <li>
                A state-of-the-art security method can be implemented when a participant loses their
                smartphone. Specifically, a participant's app is disabled by changing the user’s pin and
                expiring the token. This erases all data on the smartphone while preserving the records on
                the secure cloud.
              </li>
              <li>
                For communication on the app, no SMS/text messaging will be used. We have implemented
                secure messaging between the portal and the smartphone.The secure messaging functions like
                text messaging but is conducted over a secure communication channel that we have
                developed.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      `
      },
      {
        title: '',
          imgPath: '',
          text: `
          <div class="consent-content">
  <p>
    <span class="text-important"
      >Who Will See My Information (Privacy) and How Will It Be Protected (Confidentiality)
      (continued)?
    </span>
  </p>
  <p>
    Researchers will make every effort to protect your privacy and confidentiality; however, there
    are risks of breach of information security and information loss.
  </p>
  <p>
    Complete confidentiality cannot be promised for military personnel, because information
    regarding your health may be required to be reported to appropriate medical or command
    authorities to ensure the proper execution of the military mission, including evaluation of
    fitness for duty.
  </p>
  <p>
    The research coordinator and your treating sleep clinician will have access to your information
    and know it belongs to you. Your de-identified information will be accessible by the local
    research team and the NOCTEM team.
  </p>
  <p>
    Those listed above will have access to your records and agree to safeguard your protected health
    information by using and disclosing it only as permitted by you in this consent or as directed
    by state and federal law.
  </p>
  <p>
    Information gained from your participation in this research study may be published in
    literature, discussed for educational purposes, and used generally to further science. You will
    not be personally identified; all information will be presented as anonymous data.
  </p>
</div>

                 `
      },
      {
        title:'',
        imgPath:'',
        text:`
        <div class="consent-content">
        <p>
          <span class="text-important"
            >Authorization to Use and Disclosure Protected Health Information for this Research: 
          </span>
        </p>
        <p>You are being asked for permission to use and disclose your protected health information (PHI) for this research study.  Protected health information is defined as individually identifiable health information.</p>
        <p> The Health Insurance Portability & Accountability Act of 1996, Public Law 104-191 (also known as HIPAA), establishes privacy standards to protect your health information.  This law requires the researchers to obtain your authorization (by signing this document) before they use or disclose your protected health information for research purposes in the study listed above.</p>
        <p class="pb-3">
          <span class="text-important"
            >What Personal Identifiers and/or Protected Health Information (PHI) May Be Used and Disclosed in this Research? 
          </span>
        </p>
        <p>The identifiers and/or PHI collected, used, or disclosed are below: </p>
        <ul>
          <li>Phone Number</li>
          <li>Email address</li>
          <li>Name</li>
          <li>Device unique identifier</li>
        </ul>
      
        <p class="pb-3">
          <span class="text-important"
            >How will Your Protected Health Information be Used or Disclosed in this Research? 
          </span>
        </p>
        <ul>
          <li>Your phone number and email address will be used by the Research Coordinator regarding study follow-ups, completion of study questionnaires or study updates. It may also be used by your treating sleep clinician. </li>
          <li>Your device unique identifier is required for use of the COAST app. </li>
          <li>This PHI will be collected and stored for the duration of the study. It will be deleted at the completion of the project. </li>
        </ul>
        <p>The use and disclosure of your protected health information is necessary in order to be able to conduct the research described. Records of your participation in this research may only be disclosed in accordance with state and federal law, including the Privacy Act (5 U.S.C. 552a) and the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and its implementing regulations (45 CFR 160 & 164).</p>
        <p>Note:  Protected health information of military service members may be used or disclosed without your authorization to military command authorities to ensure the proper execution of the military mission, including evaluation of fitness for duty.</p>
        <p> By agreeing to participate,  you give your permission for information gained from your participation in this research to be published in medical literature, discussed for educational purposes, and used generally to further medical science.  You will not be personally identified; all information will be presented as anonymous data.</p>
      </div>
      `
      },
      {
          title: ``,
          imgPath: '',
          text: `
          <div class="consent-content">
          <p class="pb-3">
            <span class="text-important"
              >With Whom May Your Protected Health Information be Shared Through This Research?
            </span>
          </p>
        
          <ul>
            <li>Madigan Army Medical Center Institutional Review Board</li>
            <li>Madigan Army Medical Center or Department of Defense representatives</li>
            <li>
              State and Federal Government representatives, when required by law (such as the Food and Drug
              Administration (FDA))
            </li>
          </ul>
          <p>
            Those listed above who are covered entities under HIPAA agree to safeguard your protected health
            information by using and disclosing it only as permitted by you in this Authorization or as
            directed by state and federal law.
          </p>
          <p>
            You need to be aware that some parties receiving your protected health information may not have
            the same obligations to safeguard your protected health information and may re-disclose your
            protected health information to parties not named above. If your protected health information is
            re-disclosed, it may no longer be protected by state or federal privacy laws.
          </p>
          <p>
            <span class="text-important">You do not have to participate in this research study: </span>
          </p>
          <ul>
            <li>
              It will not affect your treatment, payment or enrollment in any health plans or affect your
              eligibility for benefits.
            </li>
            <li>You will not be allowed to participate in the research.</li>
          </ul>
        </div>
        `


      },
      {
        title: ``,
        imgPath: '',
        text:`
        <div class="consent-content">
        <p>
          <span class="text-important">
            After agreeing to participate, you can change your mind and: 
          </span>
        </p>
      
        <ul>
         <li>Notify the site principal investigator in writing that you have withdrawn your permission to disclose or use your protected health information (revoke the Authorization). </li>
         <li>Send your email  to Dr. Brian O’Reilly at  <a href="mailto:brian.m.oreilly6.civ@health.mil"> brian.m.oreilly6.civ@health.mil </a>  to inform him of your decision. Your revocation is not effective until your email  is received. </li>
         <li>Researchers may continue to use and disclose your PHI that was obtained before your revocation became effective to the extent that the researchers have taken action in reliance on your earlier authorization. Researchers may also continue to use or disclose your PHI as necessary to maintain integrity or reliability of the current research, as, for example, to account for your withdrawal from the study, to conduct misconduct investigations, or to report adverse events. </li>
         <li>If you withdraw the Authorization, you will not be allowed to continue to participate in the research. </li>
        </ul>
        <p>
            If you have not already received a copy of the brochure entitled “Military Health System Notice of Privacy Practices”, you may request one, or it is available on-line at: 
            <a style="word-break: break-word;" href="https://www.health.mil/Military-Health-Topics/Privacy-and-Civil-Liberties/HIPAA-Compliance-within-the-MHS/Notice-of-Privacy-Practices"> https://www.health.mil/Military-Health-Topics/Privacy-and-Civil-Liberties/HIPAA-Compliance-within-the-MHS/Notice-of-Privacy-Practices </a>
        </p>
        <p>
            If you have any questions or concerns about your privacy rights, you should contact the MAMC HIPAA Privacy Officer, 9040 Jackson Ave, Tacoma, WA, 98431. Telephone: <a href="tel:253-968-1642">253-968-1642 </a>
        </p>
       <p> This Authorization does not have an expiration date.
    </p>
    <p>By clicking agree at the end of this document acknowledges that you authorize Madigan Army Medical Center and members of the NOCTEM and local research team to use and disclose your Protected Health Information (PHI) collected about you for research purposes as described above.</p>
      </div>`
      },
      {
          title: '',
          imgPath: '',
          text: `<div class="consent-content">
          <p>
            <span class="text-important"> Voluntary Participation </span>
          </p>
          <p>
            The decision to take part in this research study is completely voluntary on your part which
            means you do not have to take part if you do not want to. You may also leave the research study
            at any time. If you choose not to take part in this research study or if you leave the study
            before it is finished, there will be no penalty or loss of benefits to which you are otherwise
            entitled.
          </p>
          <p>
            <span class="text-important"> What Happens if I Withdraw From This Research? </span>
          </p>
          <p>Should you choose to withdraw, you must:</p>
          <ul>
            <li>
              Tell your treating sleep clinician, notify the research coordinator, and then uninstall the
              app on your phone.
            </li>
            <li>
              Upon stopping participation, you will no longer be eligible for such research-related
              treatment. Discuss other insomnia care options with your sleep clinician and/or your primary
              care provider.
            </li>
          </ul>
          <p>
            If you do not follow these procedures, you may experience undesired gaps in treatment for your
            insomnia.
          </p>
          <p>
            If you decide to no longer participate in this research study, the researcher will maintain any
            research information recorded for, or resulting from your participation in this research study
            prior to the date that you formally withdraw your consent.
          </p>
          <p>
            Please note that withdrawing your consent to participate in this research does not fully revoke
            your HIPAA Authorization Form to use/disclose your protected health information. To make that
            revocation, please send a letter to the principal investigator as discussed in the HIPAA
            Authorization Form.
          </p>
          <p>
            The principal investigator of this research study may terminate your participation in this
            research study at any time if they determine this to be in your best interest, if you are unable
            to comply with the procedures required, or if you no longer meet eligibility criteria.
          </p>
        </div>
        
       `

      },
      {
        title: '',
        imgPath:'',
        text:`
        <div class="consent-content">
        <p class="pb-3">
          <span class="text-important">
            What if New Information is Learned During the Study that Might Affect Your Decision to
            Participate? :
          </span>
        </p>
        <p>
          We will tell you if we learn new information that could change your mind about staying in the
          study. We may ask you to review the new consent form if the information is provided to you after
          you have joined the study.
        </p>
        <p>
          <span class="text-important"> Contact Information: </span>
        </p>
        <p>
          The Project Lead is Dr. Anne Germain. Her contact information is: <a href="tel:412-897-3183"> 412-897-3183 </a> or
           <a href="mailto:anne@noctemhealth.com">anne@noctemhealth.com</a>.
        </p>
        <p>
          You may also contact your local point of contact (POC), Dr. Brian O’Reilly <a href="tel:253-968-2731">253-968-2731 </a>
           <a href="mailto:brian.m.oreilly6.civ@health.mil"> brian.m.oreilly6.civ@health.mil </a>, or his research coordinator, Ms. Sem Chan, at <a href="tel:253-232-7057"> 253-232-7057 </a>
          <a href="mailto:schan@knowesis-inc.com"> schan@knowesis-inc.com </a>
        </p>
        <p class="pb-3">
          <span class="text-important"> Madigan Human Research Protection Program (HRPP) Office </span>
          The Human Research Protection Program Office staff and/or Human Protections Administrator (HPA)
          will be available to answer questions or discuss concerns you may have about this research
          study. Please contact the Madigan HRPP Office at: <a href="tel:253-968-0147">253-968-0147</a>, Department of Clinical
          Investigation, 9040 Jackson, Tacoma, WA 98431-1100.
        </p>
        <p class="pb-3">
          <span class="text-important"> Institutional Review Board (IRB) Office </span>
          If you have any questions about your rights as a research participant or if you have concerns or
          complaints about the research study, please contact the Madigan IRB Office at: <a href="tel:253-968-0149">253-968-0149</a>,
          Department of Clinical Investigation, 9040 Jackson, Tacoma, WA 98431-1100.
        </p>
        <p>
          IF THERE IS ANY PORTION OF THIS DOCUMENT THAT YOU DO NOT UNDERSTAND, ASK THE INVESTIGATOR BEFORE
          SIGNING. YOU MAY CONSULT WITH YOUR PERSONAL PHYSICIAN OR LEGAL ADVISOR, IF YOU WISH.
        </p>
      </div>
      
          `
      }
      ]
});
