import { SearchRecord, MessageThread, UserSimpleInstance } from '../services/models';




// Client side state
export class MessageThreadState {
    Records: SearchRecord<MessageThread>[];
    rawMessages: MessageThread[] = [];
    messages: ChatMessage[] = [];
    recipientIds: string[];
    recipientId: string;
    currentUserId: string;
    groupIds: string[];

    recipients: UserSimpleInstance[];
    isPatient: boolean;
  respondingClinicianId: string;
}

export class ChatMessage {
    sentOn: Date;
    senderDisplay: string;
    content: string;
    fromRecipient: boolean;
}
