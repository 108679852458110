import { FieldDefinitionBase, CalculatedFieldDefinition, FieldType, UnitType, Calculation, OperationType } from "../../../../noctem-lib/src/core/contracts/models.calculations";
import { FieldDictionary } from "./field-dictionary";

export const CalculatedFieldDefinitions: Array<FieldDefinitionBase> = [
new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: FieldDictionary.TimeInBed,
  unit: UnitType.Hours,
  abbreviation: 'TIB',
  description: 'Total duration in hours and minutes between “tried to go to sleep at” to “got out of bed at”',
  priority: 0,
  calculations: [
    new Calculation({
      dataFields: [
        { dataFieldName: 'rise-time' },
        { dataFieldName: 'attempt-to-sleep' },


      ],
      operation: OperationType.Subtract,
      priority:0
    })
  ]
}),
new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: FieldDictionary.AsleepTime,
  unit: UnitType.Time,
  abbreviation: 'Asleep Time',
  description: 'Time fell asleep after sleep latency',
  priority: 0,
  calculations: [
    {
      dataFields: [
        { dataFieldName: FieldDictionary.BedTime },
        { dataFieldName: FieldDictionary.SleepLatency }
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ]
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: FieldDictionary.MorningTimeInBed,
  unit: UnitType.Minutes,
  abbreviation: 'Time in bed betweek waking up, and getting out of bed',
  description: 'Time spent awake after initial latency to sleep; includes all nocturnal wakefulness including early morning wakefulness prior to rise time, after sleep onset',
  calculations: [
    {
      dataFields: [
        { dataFieldName: FieldDictionary.RiseTime},
        { dataFieldName: FieldDictionary.WakeTime},
      ],
      operation: OperationType.Subtract,
      priority: 0
    }
  ],
  priority: 0
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: FieldDictionary.WASO,
  unit: UnitType.Hours,
  abbreviation: 'WASO',
  description: 'Time spent awake after initial latency to sleep; includes all nocturnal wakefulness including early morning wakefulness prior to rise time, after sleep onset',
  calculations: [
    {
      dataFields: [
        { dataFieldName: FieldDictionary.MorningTimeInBed },
        { dataFieldName: 'total-minutes-wokeups'},
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ],
  priority: 1
}),
new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: FieldDictionary.TotalSleepTime,
  unit: UnitType.Hours,
  abbreviation: 'TST',
  description: 'Total duration in hours and minutes of sleep i.e., time spent sleeping only',
  priority: 2,
  calculations: [
    {
      dataFields: [
        { dataFieldName: FieldDictionary.TimeInBed },
        { dataFieldName: 'waso' },
        { dataFieldName: 'sleep-latency'}
      ],
      operation: OperationType.Subtract,
      priority: 0
    }
  ]
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'total-caffeine-intake',
  unit: UnitType.Number,
  abbreviation: 'Caffeine',
  description: 'Caffeine',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'caffeinated-drinks-before-lunch'},
        { dataFieldName: 'caffeinated-drinks-before-dinner'},
        { dataFieldName: 'caffeinated-drinks-after-dinner'},
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ],
  priority: 0,
  defaultValueForEmpty: 0
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'total-alcohol-intake',
  unit: UnitType.Number,
  abbreviation: 'Alcohol',
  description: 'Alcohol',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'alcoholic-drinks-before-lunch'},
        { dataFieldName: 'alcoholic-drinks-before-dinner'},
        { dataFieldName: 'alcoholic-drinks-after-dinner'},
      ],
      operation: OperationType.Add,
      priority: 0
    }
  ],
  priority: 0,
  defaultValueForEmpty:0
}),

new CalculatedFieldDefinition({
  _type: FieldType.Calculated,
  name: 'sleep-efficiency',
  unit: UnitType.Percent,
  abbreviation: 'SE',
  description: 'Sleep Efficiency',
  calculations: [
    {
      dataFields: [
        { dataFieldName: 'total-sleep-time'},
        { dataFieldName: 'time-in-bed'},
      ],
      operation: OperationType.Divide,
      priority: 0
    }
  ],
  priority: 3
}),


]
