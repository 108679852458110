import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { tacticalBreathing } from '../questions/tactical-breathing/tactical-breathing';

export const tacticalBreathingDef: AssetDefinition = {
  id: 23,
  name: 'tactical-breathing',
  label: 'Tactical Breathing',
  description: `
  Stress often leads to taking short, shallow breaths. Slow breathing produces physiological changes that decrease anxiety and improve focus. Help your Soldiers practice this low and slow breathing. An audio-guided example is available.
  `,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    tacticalBreathing
  ],
  url: 'tactical-breathing'
};
