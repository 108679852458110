import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { upYourSleepArea } from '../questions/up-your-sleep-area/up-your-sleep-area';


export const upYourSleepAreaDef: AssetDefinition = {
  id: 11,
  name: 'up-your-sleep-area',
  label: 'Up Your Sleep Area',
  description: `
  Help your Soldiers create a sleep-ready environment and overcome external sleep challenges such as loud noises, harsh temperatures, bright light, and snoring.
  `,
  categories: ['all', 'sleep-and-fatigue'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    upYourSleepArea
  ],
  url: 'up-your-sleep-area'
};
