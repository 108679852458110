export const sus2Def = {
  name: 'sus2',
  description: 'System Usability Scale',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText:
        'How satisfied are you with the sleep training you received through the NOCTEM app?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not at all satisfied', value: 0 },
        { display: 'Slightly satisfied', value: 1 },
        { display: 'Moderately satisfied', value: 2 },
        { display: 'Very satisfied', value: 3 },
        { display: 'Extremely satisfied', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_SATISFIED'
    },
    {
      id: 1,
      questionText: 'How much effort do you think this training required on your part?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'No effort', value: 0 },
        { display: 'Some effort', value: 1 },
        { display: 'The right amount of effort', value: 2 },
        { display: 'Quite a bit of effort', value: 3 },
        { display: 'Extreme amount of effort', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_EFFORT'
    },
    {
      id: 2,
      questionText: 'Was it was easy to communicate with your coach?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_COACH'
    },
    {
      id: 3,
      questionText: 'Did the opportunity to earn coins keep your motivation high?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_MOTIVATION'
    },
    {
      id: 4,
      questionText: 'Did you like being able to track your progress?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_TRACK_PROGRESS'
    },
    {
      id: 5,
      questionText: 'Was it easy to complete your daily sleep logs?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_EASY_TO_COMPLETE'
    },
    {
      id: 6,
      questionText: 'Did you have fewer in-person appointments to attend?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_FEWER_APPOINTMENTS'
    },
    {
      id: 7,
      questionText: 'Did you feel more in control of your sleep training?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_BENEFITS_CONTROL'
    },
    {
      id: 8,
      questionText: 'Other benefits?',
      questionType: 'text',
      autofill: false,
      answerOptions: [],
      conditionalQuestions: [],
      showNotApplicableHint: true,
      uniqueAnswerId: 'SUS2_BENEFITS_OTHER'
    },
    {
      id: 9,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'The training felt impersonal',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_IMPERSONAL'
    },
    {
      id: 10,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'I felt uncomfortable sharing personal information on an app',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_COMFORTABLE'
    },
    {
      id: 11,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'I felt the tactics were difficult to understand',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_TACTICS'
    },
    {
      id: 12,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'There was too much reading',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_READING'
    },
    {
      id: 13,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'It was hard to find time to complete my daily sleep logs',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_FIND_TIME'
    },
    {
      id: 14,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'I prefer face-to-face discussions about my health',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_FACE_TO_FACE'
    },
    {
      id: 15,
      questionText: 'Drawbacks of receiving sleep training through the NOCTEM app:',
      questionEmphasis: 'Other: ___',
      questionType: 'text',
      autofill: false,
      answerOptions: [],
      showNotApplicableHint: false,
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_DRAWBACKS_OTHER'
    },
    {
      id: 16,
      questionText:
        'How likely are you to recommend this type of sleep training to a friend who is experiencing sleep difficulties?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Very Unlikely', value: 0 },
        { display: 'Unlikely', value: 1 },
        { display: 'Undecided', value: 2 },
        { display: 'Likely', value: 3 },
        { display: 'Very Likely', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS2_RECOMMEND'
    },
    {
      id: 17,
      questionText:
        'We aim to optimize users’ experience with NOCTEM.  Can a NOCTEM team member contact you to hear how we can continue to improve?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          id: 18,
          questionText: 'Please enter the phone # you can be reached at:',
          displayIfAnswerEquals: [true],
          questionType: 'text',
          autofill: false,
          answerOptions: [],
          showNotApplicableHint: true,
          conditionalQuestions: [],
          uniqueAnswerId: 'SUS2_CONTACT_NUMBER'
        }
      ],
      uniqueAnswerId: 'SUS2_CONTACT'
    }
  ]
};
