import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const gad2: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'gad2-overall',
    unit: UnitType.Number,
    abbreviation: 'Gad 2 Overall',
    description: `Gad 2 Overall`,
    assessmentType: LogDefinitionTypes.GAD2
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad2-nervous',
    unit: UnitType.Number,
    abbreviation: 'Nervous',
    description: `Nervous`,
    assessmentType: LogDefinitionTypes.GAD2,
    answerId: 'GAD2_NERVOUS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad2-control',
    unit: UnitType.Number,
    abbreviation: 'Control',
    description: `Control`,
    assessmentType: LogDefinitionTypes.GAD2,
    answerId: 'GAD2_CONTROL'
  }),

  //#endregion
];
