<div class="position-fixed bottom">
    <alert-display [message]="message" (cleared)="onAlertDismissed()"></alert-display>
    <div class="row p-4" *ngIf="!allowBackwardsNavigation">
        <div class="col">
            <div style="background-color:black;">
                <a (click)="onNextClicked()" class="btn btn-primary btn-lg btn-block"
                    [class.inactive-btn]="areCurrentQuestionsUnanswered">Next</a>
            </div>
        </div>
    </div>
    <div class="row p-4" *ngIf="allowBackwardsNavigation">
        <div class="col btn-group" role="group">
            <div class="mr-2 prev" style="background-color:black;"><a (click)="previousClicked.emit(true)"
                    class="btn btn-primary btn-lg pl-0 pr-0 mr-2 btn-block prev"><i class="fas fa-chevron-left"></i></a>
            </div>
            <div class="next" style="background-color:black;"><a (click)="onNextClicked()"
                    class="btn btn-primary btn-lg btn-block"
                    [class.inactive-btn]="areCurrentQuestionsUnanswered">Next</a></div>
        </div>
    </div>
    <div class="progress-segment">
        <div *ngFor="let page of pageArray" class="item" [class.complete]="page.isComplete"></div>
    </div>
</div>