import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const swps: AssessmentDefinition = {
  name: 'swps',
  description: 'Reported advanced or delayed sleep-wake schedule or shift work',
  instructions: null,
  questions: [
    {
      uniqueAnswerId: 'SWPS_BEDTIME_PREFERENCE',
      id: 0,
      questionText:
        'If you could choose to go to bed at any time, without restriction due to work or family responsibilities, your preferred bedtime would be:',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Between 22:00 and 24:00 ',
          value: '1'
        },
        {
          display: 'Between 24:00 and 02:00',
          value: '2'
        },
        {
          display: 'Between 02:00 and 04:00 ',
          value: '3'
        },
        {
          display: 'Between 04:00 and 06:00',
          value: '4'
        },
        {
          display: 'Between 06:00 and 12:00',
          value: '5'
        },
        {
          display: 'Between 12:00 and 18:00',
          value: '6'
        },
        {
          display: 'Between 18:00 and 20:00',
          value: '7'
        },
        {
          display: 'Between 20:00 and 22:00',
          value: '8'
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_BEDTIME_IS_LATER',
      id: 1,
      questionText: 'My preferred bedtime is more than 2 hours later than most people.',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_BEDTIME_IS_EARLIER',
      id: 2,
      questionText: 'My preferred bedtime is more than 2 hours earlier than most people.',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_RISETIME_PREFERENCE',
      id: 3,
      questionText:
        'If you could choose to wake up at any time, without restriction due to work or family responsibilities, your preferred rise time would be:',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: '8-10',
      answerOptions: [
        {
          display: 'Between 04:00 and 06:00',
          value: '1'
        },
        {
          display: 'Between 06:00 and 08:00',
          value: '2'
        },
        {
          display: 'Between 08:00 and 10:00',
          value: '3'
        },
        {
          display: 'Between 10:00 and 12:00',
          value: '4'
        },
        {
          display: 'Between 12:00 and 14:00',
          value: '5'
        },
        {
          display: 'Between 14:00 and 16:00',
          value: '6'
        },
        {
          display: 'Between 16:00 and 24:00',
          value: '7'
        },
        {
          display: 'Between 24:00 and 02:00',
          value: '8'
        },
        {
          display: 'Between 02:00 and 04:00',
          value: '9'
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_RISETIME_IS_LATER',
      id: 4,
      questionText: 'My preferred rise time is more than 2 hours later than most people.',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_RISETIME_IS_EARLIER',
      id: 5,
      questionText: 'My preferred rise time is more than 2 hours earlier than most people.',
      questionEmphasis: '',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    }
  ]
};
