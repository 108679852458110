import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DashboardStateService, UserStateService, TreatmentPhase, SleepPrescriptionService, APPLICATION_CONTEXT } from '@noctem/web';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { APPLICATION_ORGANIZATION } from '../../../../../../noctem-lib/src/constants/constants';
import { PatientDialogComponent } from '../patient/patient-page/dialog/patient-dialog/patient-dialog.component';
import { GroupService } from '../../../../../../noctem-lib/src/lib/services';
import { treatmentPlanPhases } from '../../../../../../noctem-lib/src/constants/constants';
import { ClinicianPortalTitles } from '../../core/title/titles';
import { sortStatus, sortSleepPrescription } from './sorters';
import { Gtag } from 'angular-gtag';

import { userAgreements } from 'projects/noctem-lib/src/constants/definitions/user-agreements';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  length: number;
  index = 0;
  pageSize = 50;
  pageIndex = 1;
  displayedColumns: string[] = [
    'icon',
    'id',
    'appActivation',
    'status',
    'flags',
    'efficiency',
    /*'quality',*/
    'adherence',
    'sleepPrescription'
  ];
  dataSource = new MatTableDataSource<PatientRow>();
  isConsent = false;
  isFirstLoad = true;
  public isEULAAccepted = true;
  isActiveOnly = false;
  public isResearch = false;
  public isConsentRequired = false;
  isFiltered = false;
  destroy$: Subject<any> = new Subject();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(PatientDialogComponent) eulaDialog: PatientDialogComponent;

  constructor(
    public dashboardStateService: DashboardStateService,
    private sleepPrescriptionService: SleepPrescriptionService,
    private userStateService: UserStateService,
    public router: Router,
    private groupsService: GroupService,
    private titleService: Title,
    private gtag: Gtag,
  ) {
    userStateService.initialize();
  }

  ngOnInit() {
    this.gtag.pageview({
      page_title: ClinicianPortalTitles.HOME,
    });
    this.titleService.setTitle(ClinicianPortalTitles.HOME);

    this.dashboardStateService.buildDashboard(
      1000,
      this.index,
      null,
      null
    );
    this.dashboardStateService.state$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => !(state as any).IsLoading && !!(state as any).dashboardObject)
        ).subscribe(state => {
        this.dataSource = new MatTableDataSource<PatientRow>(state.dashboardObject);
        this.length = state.resultPager.TotalRecords;

        if(this.isFirstLoad) {
          this.onPaginateChange({pageSize: 50, index: this.index, pageIndex: this.paginator.firstPage()});
          this.isFirstLoad = false;
        }
        
        this.index = state.resultPager.RecordIndex;
        this.pageIndex = state.resultPager.PageIndex;

        this.dataSource.paginator = this.paginator;

        this.dataSource.sortingDataAccessor = ( data: PatientRow, sortHeaderId: string) => {
          switch (sortHeaderId) {
            case 'status':        
              return sortStatus(data.status);
            case 'sleepPrescription':
              return sortSleepPrescription(data.sleepPrescription, this.sort.direction, data.userString);
            default:
              return data[sortHeaderId];
          }
        };

        this.dataSource.sort = this.sort;
        
      });
      
    this.userStateService.onUserLoaded$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(userState => {
      // TODO: Array.find() only returns the first element. Is this intended?
      const userGroup = userState.User.groups.find(
        g =>
          g.display?.toLowerCase() !== APPLICATION_ORGANIZATION.name &&
          (g as any).Name?.toLowerCase() !== APPLICATION_ORGANIZATION.name
      );
      this.groupsService.getAllAsync().then(groups => {
        let userGroupId = (userGroup as any)._id;
        if(userGroupId == null) {
          userGroupId = (userGroup as any).id
        }
        const group = groups.find(g => g.__i.guid === userGroupId);
        this.isResearch = group?.isResearch;

        // In the event that the consentId DNE, do not present the clinician with a blank modal.
        const clinicianConsent = userAgreements.ClinicianConsents.find(c => c.id === group.consentId);
        this.isConsentRequired = (clinicianConsent && group?.isConsentRequired) ? true : false;
      });
      this.isEULAAccepted =
        userState.User.applicationData &&
        userState.User.applicationData.eulaAgreedOn != null &&
        userState.User.applicationData.eulaAgreedOn.length > 0;
      this.isConsent = !(
        userState.User.applicationData &&
        userState.User.applicationData.consentObtainedOn &&
        userState.User.applicationData.consentObtainedOn.length > 0
      );
    });
  }

  ngAfterViewInit() {
    this.userStateService.onEULACompleted$.pipe(
      takeUntil(this.destroy$)
      ).subscribe(sub => {
      this.isEULAAccepted = true;
    });
    //return user to first page on every sort change
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPaginateChange(paginationSettings) {
    this.pageSize = paginationSettings.pageSize;
    this.index = paginationSettings.pageIndex * this.pageSize;
    this.pageIndex = paginationSettings.pageIndex;
  }

  userFilterSelected(event: any) {
    this.router.navigate([`/patient/${event.value.UserId}`]);
  }

  hoverPatientFlag(description) {
    this.gtag.event('hover', {
      'event_category' : 'engagement',
      'event_label' : 'Patient Flag Hovered: ' + description
    });
  }

  toggleActive() {
    this.isActiveOnly = !this.isActiveOnly;
    this.dashboardStateService.toggleActive(this.isActiveOnly);
  }

  // Filters patient rows
  setFilter(value: string, size?: number) {
    this.dataSource.filter = value.trim().toLowerCase();
    const pageSize = this.pageSize;
    this.onPaginateChange({pageSize, pageIndex: 0});
  }

  // If user clears input reset filter
  ifReset(value) {
    if (value === '') {
      this.setFilter(value, this.pageSize)
    }
  }

  /**
   * Remove all whitespace and lowercase a string
   * @param s A string
   * @example
   * 'Week One' => 'weekone'
   */
  lowerNoSpace(s: string): string {
    return s?.replace(/\s/g, '').toLowerCase();
  }
}
export interface PatientRow {
  icon: Object;
  id: number;
  userString: string;
  status: string;
  flags: Array<any>;
  pgi: string;
  isi: string;
  ess: string;
  efficiency: string;
  //quality: string;
  lastViewed: string;
  adherence: string;
  sleepPrescription: {
    status: 'UPCOMING_DUE'|'PAST_DUE'|'NOT_DUE';
    dueDate: Date;
    display: string;
  };
  message: string;
  position: number;
  mostRecentAssessmentDate: string;
  isPrescriptionSent: boolean;
  noNewTreatments: boolean;
}
