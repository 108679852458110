import { UserAgreement } from "../../../../core";

export default {
    id:0,
    name:'consent',
    imgName:'irb.png',
    pages: [
      {
        requireInitials: true,
        content: `
        <div class='container text-center'>
          <h4 class="center">VOLUNTARY CONSENT TO PARTICIPATE IN A RESEARCH STUDY</h4>
          <h4 class="center">Optimizing the Scalability of Evidence-Based Behavioral Sleep Medicine Practices with a mobile Health Platform </h4>
          <p class="center">Anne Germain, PhD<br/>Principal Investigator, NOCTEM®, LLC</p>
        </div>
          <p><b>Key Information</b></p>
          <p>The following focused information is being presented to assist you in understanding the key elements of this study, as well as the basic reasons why you may or may not wish to consider taking part. This section is only a summary; more detailed information, including how to contact the research team for additional information or questions, follows within the remainder of this document</p>
          <p><b>What should I know about a research study?</b></p>
          <ul>
              <li>Someone will explain this research study to you.</li>
              <li>Taking part in the research is voluntary; whether you take part is up to you.</li>
              <li>You can choose not to take part.</li>
              <li>You can agree to take part and later change your mind.</li>
              <li>Your decision will not be held against you.</li>
              <li>You can ask all the questions you want before you decide and can ask questions at any time during the study.</li>
          </ul>
          <p>This research study has two aims: 1. To compare three different ways of deploying a digital health platform to support the behavioral treatment of insomnia and other behavioral sleep disturbances; and 2. To evaluate if the magnitude of changes in insomnia severity in patients in patients with whom clinicians use the digital tools differ across the three ways of deploying the digital health platform. </p>
          <p>We invite you to take part in this research study because you offer behavioral health services to service members, veterans, and/or adult dependents with insomnia and to whom you may deliver behavioral sleep interventions.</p>
          <p>The digital platform, called NOCTEM® COAST™ (Clinician-Operated Assistive Sleep Technology) is designed to support your clinical decision making when delivering behavioral treatment of insomnia and other behavioral sleep disorders. The platform includes a portal for clinicians that is accessible from a computer, tablet, or smartphone, and that is linked to the smart device application (app) your patients own, and an embedded secure two-way text messaging system. The text messaging system is operated through the cloud and does not go directly to and from your phone or that of your patients.</p>
          <p>You are being asked to participate in this study because you are a military health care clinician who serves service members, veterans, and /or their adult dependents who present with complaints of insomnia. This research is being conducted by NOCTEM®, LLC with funding from the Medical Technology Enterprise Consortium.</p>

    `
      },
      {
        requireInitials: true,
        content: `
      <p>Your participation in this research project involves monitoring sleep habits and symptoms that your patient with insomnia will report by completing a sleep log and brief surveys through the patient app.  You can monitor their entries by and status via your clinician portal.  Based on patients' entries, the NOCTEM platform will offer recommenations on how to improve sleep quality for each patient, based on their sleep patterns.  You may make changes as you wish to these recommendations, before sending them to your patient, from your portal to their app.  You will have the ability to confidentially communicate via the embedded text messaging system with your patients during this time.  Your patients may also communicate with you by using the text messaging system.  Like in the traditional face-to-face insomnia interventions, the process will occur on a weekly basis, and the total duration of the intervention for each patient is about 6 weeks, from intake to completion. </p>
      <p>On their personal smart devices, your patients will be asked to complete daily morning and evening sleep logs. They will also be asked to complete a larger battery questionnaire on their app at the onset of the intervention, at the end, and again, every 3 months for up to 12 months, or until you discharge them from your care, whichever comes first.  During the sleep intervention with the NOCTEM platform, your patients will be asked to complete weekly assessment of their perceived progress, somatic symptoms including daytime sleepiness, and symptoms of depression, anxiety, and posttraumatic stress. You will have access to all this information through your clinician portal. You will be asked to give your general impression of their clinical progress via the portal on a weekly basis during this period.</p>
      <p>Participating in this research study should not change the frequency at which you see your patients in person or complete telephone visits. You may choose to meet in-person or by phone with your patient(s) as many times as you deem necessary for their care. However, this specific intervention allows you to meet in-person or by phone only once to help your patient download the application on their phone and teach them how to use it. You may completely or partially deliver the sleep intervention through the app if you decide to do so.</p>
      <p>Before using the NOCTEM® COAST™ platform with your patients, you will receive a two-part training session in person, or delivered remotely via a live, interactive webinar on the basic principles and practice of behavioral sleep medicine that is the basis of this digital platform (Part I), and on how to use this mobile health platform (Part II). You will receive continuing education credits for your attendance at this training. You will also be asked to practice using the portal, app, and text messaging system for up to 2 weeks before you start using the NOCTEM platform with your patients, to make sure you are comfortable with its functions.</p>
      <p><b>Time Commitment Summary</b></p>
      <div class="container table" style="border:1px solid red !important;">
          <div class="row">
              <div class="col center"><b>Event</b></div>
              <div class="col"><b>Approx. Time</b></div>
          </div>
          <div class="row">
              <div class="col"><p><b>Training:</b>Consent process, download and learn how to use NOCTEM platform, complete training surveys, complete the 2-week NOCTEM familiarization period</p></div>
              <div class="col"><p>~12 hours</p></div>
          </div>
          <div class="row">
              <div class="col"><p><b>Assessments:</b>Clinicians will review the brief patients' assessments and complete their own assessment of the patient's progress</p></div>
              <div class="col"><p>~9 hours total over 36 patients</p></div>
          </div>
          <div class="row">
              <div class="col"><p><b>Portal Monitoring:</b>Review the daily "Morning" and "Night" logs that patients complete for 4 to 6 weeks</p></div>
              <div class="col"><p>~1.2 hours total over 36 patients</p></div>
          </div>
          <div class="row">
              <div class="col"><p><b>Clinician Recommendations:</b>Clinician will recommend various sleep tactics and a sleep prescription for each patient</p></div>
              <div class="col"><p>~6 hours total over 36 patients</p></div>
          </div>
          <div class="row">
              <div class="col"><p><b>Total Time</b></p></div>
              <div class="col"><p>~28.2 hours over one year</p></div>
          </div>
      </div>
      <p><b><i>Note.</i></b>The amount of time that the clinician and patient spend using NOCTEM may vary based on the clincian's judgement.</p>
    `
      },
      {
        requireInitials: true,
        content: `
      <p>There are a few risks that may arise from your participation in this study including mild frustration or unease related to use of the NOCTEM digital health platform, mild increase in your workload, and a small potential risk of security and privacy breach related to the use of a cloud-based program and smart devices.</p>
      <p>Neither you, nor your patients will receive payment for participation in this study. </p>
      <p>You are being asked to volunteer to participate in a research study titled "Optimizing the Scalability of Evidence-Based Behavorial Sleep Medicine Practices with the NOCTEM Digital Platform."  Your participation is completely voluntary.  If there is anything in this Consent Document that you do not understand, be sure to contact the investigators to explain that portion of the study.  If you have any quesitons, do not hesitate to ask them.</p>
      <div class="container">
          <div class="row">
              <div class="col center">
                  <h4>CONSENT TO PARTICIPATE IN RESEARCH</h4><br/>
                  <p><b>Title:</b> <i>Optimizing the Scalability of Evidence-Based Behavioral Sleep Medicine Practices with a mobile Health Platform <br/>Consent Form</i><br/>
                      <b>Principal Investigator:</b><i> Anne Germain, Ph.D.</i><br/>
                      <b>Study Coordinator:</b><i>Megan Wolfson, LSW</i>
                  </p>
                  <p>
                      <b>Local Point of Contact:</b><i>[pi_name]</i><br/>
                      <b>Local Coordinator:</b><i>[coordinator_name]</i>
                  </p>
              </div>
          </div>
      </div>
      <br/>
      <div class="container">
          <div class="row">
              <div class="col-2"><img src="../../../../../assets/img/consent/1.png"></div>
              <div class="col-10">
                  <b>Why is this study being done?</b><br/>
                  The purpose of this study is to examine how a sleep digital platform called NOCTEM COAST (Clinician Operated Assistive Sleep Technology), can be best used to treat insomnia and other sleep disturbances in service members, veterans, and dependents who receive care at participating military treatment facilities and clinics. The study also aims to determine the magnitude of sleep improvements in patients who use the NOCTEM app with their clinicians.
              </div>
          </div>
      </div>
      <br/>
      <div class="container">
          <div class="row">
            <div class="col-11">
                <b>Who is conducting the study?</b><br/>
                The Medical Technology Enterprise Consortium (MTEC) provided funding for this collaborative study between NOCTEM LLC, NHRC in San Diego, California, Naval Medical Center San Diego (NMCSD) in San Diego, California, and the University of Pittsburgh.  Other sites will also join the study.
            </div>
            <div class="col-1"><img src="../../../../../assets/img/consent/2.png"></div>
          </div>
      </div>
      <p>
          <b>Why are you asked to participate in the study?</b><br/>
          We are asking you to take part in this study because you are treating or would like to treat patients who report sleep problems that are consistent with insomnia, like difficulty falling or staying asleep, and/or other sleep difficulties that disrupt sleep (e.g., nightmares). At least 24 providers across multiple sites will take part in this study.
      </p>
      `
      }
      ,
      {
        requireInitials: true,
        content: `
      <p><b>You are eligible for this study if: </b></p>
      <ol>
          <li>You use cognitive-behavioral techniques as part of your practice.</li>
          <li>See patients who present with insomnia or other behavioral sleep disturbances in your practice.</li>
          <li>Have completed a training in behavioral sleep medicine within the last 6 months if you do not complete Part I of the full-day training, and complete Part II of the training on how to use the NOCTEM platform.</li>
      </ol>
      <div class="container">
          <div class="row">
            <div class="col">
                  <p><b>What will happen if you decide to take part in this study?</b><br/></p>
                  <p>If you decide to take part in this study, there are <b>6 steps</b> that will happen over the 12-month duration.</p>
                  <p>1. Consent to participating and learning the NOCTEM platform.</p>
            </div>
            <div class="col"><img src="../../../../../assets/img/consent/3.png" width="120px"></div>
          </div>
      </div>
      <p>After providing your consent to participate in this study, you will participate in a hands-on training on the architecture, functions, and use of the NOCTEM platform. The training may be delivered in person, or remotely via an interactive, live webinar. You will be provided with a unique activation code to start using the portal on computer. You will receive continuing education (CE) credits for your attendance at this training.</p>
      <p>At the completion of the training, you will complete a 2-week familiarization period where you will use the NOCTEM app and portal tools monitoring yourself as your own patient. The NOCTEM team will supervise your use during this period and provide feedback and support, as needed.  You may reach out directly to them if you have any questions or suggestions at any time during the study.</p>
      <p>Once you feel confident in your use of the NOCTEM platform, you will begin offering NOCTEM to your patients who you deem are suitable to have their sleep complaints addressed through this digital sleep platform.</p>
      <p><b>2. Receive random assignment to one of the three study conditions.</b></p>
      <p>Using a computer-generated list, you will be randomly assigned to one of three conditions for the study.  Conditions for the study include modifying the level of structured support and scheduled communication provided to you by the research team as you learn and integrate the NOCTEM platform into your practice.</p>
      <div class="container">
          <div class="row">
              <div class="col"><img src="../../../../../assets/img/consent/4.png" width="80px"></div>
              <div class="col">
                  If you are assigned to <b>Condition 1</b>, you will have access to the NOCTEM portal and be free to use it as you see fit.  You will receive the contact information for the study team members who can answer any questions or concerns you may have during the study.

                  If you are assigned to <b>Condition 2</b>, you will also have access to the NOCTEM portal and be free to use it as you see fit.  You will also be assigned to a remote expert, called an external facilitator.  The external facilitator will be directly in touch with you on a regular basis to assist with any question you may have and to provide you with suggestions and feedback to take full advantage of the portal functions.
              </div>
          </div>
      </div>
      <br/>
      <p><b>Condition 3</b> is similar to Condition 2, except that you will also have a local champion.  The local champion will provide feedback and suggestions and will assist the external facilitator when troubleshooting is necessary to make sure you can fully use the functions of the NOCTEM platform in your practice.</p>
    `
      },
      {
        requireInitials: true,
        content: `
      <p><b>3. Enroll patients who are eligible and willing to use the NOCTEM app to support the sleep care you provide. </b></p>
      <p>Next, you will be encouraged to use the NOCTEM platform with patients you see and who endorse poor sleep including symptoms of insomnia, poor sleep habits, nightmares, other disruptive nocturnal behaviors, or odd sleep times.</p>
      <p><b>Eligible patients will: </b></p>
      <ul>
          <li>Own a smart device (i.e., smartphone or tablet)</li>
          <li>Report an insomnia complaint</li>
      </ul>
      <p><b>Eligible patients will not: </b></p>
      <ul>
          <li>Have suspected, or diagnosed, and/or inadequately treated sleep apnea as defined by use of positive airway pressure (PAP) therapy for less than 4 hours per night</li>
          <li>Have a history of or current symptoms of bipolar disorders or psychotic symptoms</li>
          <li>Be pregnant or breast-feeding women or parents of an infant under the age of 3 months.</li>
      </ul>
      <p>These exclusion criteria are recommended to avoid exacerbating excessive daytime sleepiness (for sleep apnea) and exacerbating psychiatric symptoms (for psychotic or bipolar disorders).  Since pregnant or breastfeeding women may already experience sleep loss, they may not benefit from the recommendeds you will share and will not be included in this study.  Also, parents of an infant under the age of 3 months may already experience sleep loss, and may not benefit from the clinician's recommendations, and therefore will not be included in this study.  <b>However, the ultimate decision regarding the inclusion of any patient will be left to you as you have the necessary clinical information to determine the best course of action for each given patient.</b></p>
      <p><b>4. Use NOCTEM tools to obtain consent, and monitor and communicate with your patients while using the NOCTEM platform to support sleep care. </b></p>
      <div class="container">
          <div class="row">
              <div class="col">
                For patients whom you deem eligible, you will offer them use of the NOCTEM app to support the sleep care you will provide to them.  You will be asked to obtain consent for patients you wish to use the NOCTEM app to support their sleep care. To do so, you will first be asked to register your patient on the NOCTEM portal and assist them with downloading and activating the app. This process will take less than 3 minutes. During the activation process, you will then be required to present and discuss the study procedures and risk with them, and obtain the electronic consent by confirming your patient understands the project and is willing to use the NOCTEM app to support the sleep care you will provide to them. To protect your patients' privacy, your patient's name or signature will not be collected; only an electronic confirmation that they have received the information and that they are willing to use the NOCTEM app to support their sleep care will be required. A hard copy of the brochure that details the information to present to your patients via the app during the activation process will be provided if you or your patients also want a hard copy.  This process will take no more than 5 minutes.
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/5.png" width="250px"></div>
          </div>
      </div>
    `
      },
      {
        requireInitials: true,
        content: `
      <p>Once your patient confirms that s/he has received the information about the project and that s/he agrees to use the NOCTEM app to support the treatment of their sleep disturbance with you, the NOCTEM app will be fully activated and you will be able to start monitoring your patient's sleep/wake patterns through your clinician portal for the next 4 to 6 weeks. Data about their sleep/wake patterns is collected through morning and evening log completed daily on the NOCTEM app.</p>
      <p>Most people complete the acute intervention phase in 4 weeks, but to accommodate for everyone's unique schedule, the duration of this phase can be up to 6 weeks. During this time, you will also communicate with your patient via the secure HIPAA compliant two-way messaging system that is built into the NOCTEM platform. This text messaging system is not the one attached to your or your patient's cell phone plan and does not use your or their personal data plan.</p>
      <p><b>5. Provide recommendations for sleep improvement through the NOCTEM app. </b></p>
      <p>After 4 to 7 days of your patient using the app, you will see that specific recommendations for better sleep appear on your clinician portal.  These recommendations are individualized for your patient based on the data s/he has entered.  All recommendations are consistent with the evidence-based principles and practices of behavioral sleep medicine. If you agree with the recommendation, you can forward the information to your patient as is.  If you do not agree, you can modify the recommendation as you see fit. The recommendations will give your patient specific instructions about what to do, how to do it, and why they are being asked to do it. The recommendations are changes in their daytime and nighttime behaviors that are known to be helpful for sleep.</p>
      <p><b>6. Review patient assessments and complete the clinician assessment weekly over 4 to 6 weeks, and again at 3, 6, and 12 months, or up until discharge.</b></p>
      <p>Weekly over the 4 to 6 week period, and again at the 3, 6, and 12 month marks, or until discharge from your care, whichever comes first, you will review your patient's assessments of sleep, sleepiness, mood and stress symptoms, as well as physical symptoms they may already be experiencing or that occur during the study. You will then be asked to rate your impression of change for each of your patients who use the NOCTEM app.</p>
      <div class="container">
          <div class="row">
              <div class="col">
                  <b>What are the risks if you take part in this study? </b><br/>
                  The investigators believe that the risks or discomforts are minimal and do not exceed the typical risks expected in clinical practice or use of smartphone and web-based computer tools.
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/6.png"></div>
          </div>
      </div>
      <br/>
      <p>The <b>main risks and discomforts</b> for participating clinicians are: </p>
      <ul>
          <li><u>Mild frustration and/or psychological unease related to use of the NOCTEM digital health platform:</u> There are wide individual differences in acquiring proficiency with new programs and technologies. Based on our prior experiences, we expect that less than 10% of participating clinicians will experience this risk during the proficiency training phase, and that less than 5% will experience after starting to use the platform with their patients.</li>
          <li><u>Mild increase in workload:</u> Using the NOCTEM platform may increase your workload. It is possible that the use of the NOCTEM platform will increase the time required for charting or chart review for a given patient.  Based on our prior experiences, we expect that less than 5% of participating clinicians will report increased workload by using the NOCTEM portal to monitor patients' progress and adherence.</li>
      </ul>
    `
      },
      {
        requireInitials: true,
        content: `
      <ul>
          <li><u>Risks specific to the use of smart devices:</u> With the use of any smart devices, there is a risk of breach of security and privacy. If communicating with your patients via text for therapeutic and scheduling purposes, be aware that although every reasonable effort may be taken, confidentiality during internet communication and text messaging activities cannot be guaranteed and it is possible that information may be intercepted and used by others not associated with this study. We expect the risk of security and privacy breach to be less than 1%.  Although no information is stored on devices used by you or your patient(s), the magnitude of this risk would be moderate as email addresses, phone numbers, and access codes may be revealed.</li>
      </ul>
      <div class="container">
          <div class="row">
              <div class="col">
                  Security and confidentiality in a mobile health application is of paramount importance. We use layered security measures to protect the privacy and confidentiality of the data, including: (1) the use of a forced PIN/password to access the app; (2) a strong authentication procedure that combines a PIN/password, the phone number of the device, and the device's unique International Mobile Equipment Identity (IMEI) number; (3) encryption of all communication between the smartphone and the cloud using the AES (Advanced Encryption Standard) 192-bit key; (4) A novel security method that allows the research team to disable the app and to erase records from the device in the event of a lost smart device while preserving the records on the secure cloud;  (5) a secure messaging system between your portal and your patient's smart device will be used in the place of SMS/text messaging, and lastly, (6) a secure cloud behind a firewall with security policy in place that includes a dedicated cloud, regular back-up of data, a monitored network with active security measures, and a well-defined, role-based, access to the database.
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/7.png" width="80px"></div>
          </div>
      </div>
      <br/>
      <p><u>Data collected through this study, from you or your patients, will not be shared or sold to a third party.</u></p>
      <p><b>New risks that may be found during the study.</b><br/>
          You will be promptly notified if, during this research study, any new information develops which may cause you or your patient(s) to reconsider participation.</p>
      <p><b>What alternatives are there to study participation?</b><br/>
          The alternative to participation is to not enroll in this study. If you decide not to take part in this study, you may continue to offer sleep services to your patients using other methods such as:</p>
      <div class="container">
          <div class="row">
              <div class="col">
                  <ul>
                      <li>Traditional cognitive-behavioral therapy for insomnia, imagery rehearsal for nightmares, and other techniques to promote healthy sleep behaviors. Traditional treatment delivery typically requires four to eight in-person appointment with your patients;</li>
                      <li>Referral to a trained sleep specialist at your local sleep clinic;</li>
                      <li>Referral to a physician who may prescribe a sleep medication; or</li>
                      <li>You may inform your patients about web-based sleep treatment programs, or of stand-alone apps that provide education about sleep health. </li>
                  </ul>
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/8.png" width="100px"></div>
          </div>
      </div>
      <p><b>Is there compensation for your participation in this research?</b><br/>
          No, you will not receive any compensation for participating in this study.</p>
      <p><b>Are there costs for participating in this research?</b><br/>
          No, there are no costs to you or your patients for taking part in this research study. </p>
    `
      },
      {
        requireInitials: true,
        content: `
      <p><b>Are there any potential benefits for participating in the study?</b></p>
      <div class="container">
          <div class="row">
              <div class="col"><img src="../../../../../assets/img/consent/9.png" width="100px"></div>
              <div class="col">
                  There is no certain benefit for participating in the study. You may experience a significant decrease in workload, and an increase in number of patients to whom CBTI can be provided efficiently.  You may learn more about your patient(s) sleep and about healthy sleep practices that help sleep quality.  You may see improvements in your patient(s) sleep quality. Your patient may find it easier to communicate relevant information as it occurs with you through the secure text messaging system embedded in the app.
              </div>
          </div>
      </div>
      <p>Finally, the information from this research study may help researchers learn more about the best strategies to deploy digital health technologies to support clinical decision making and workflow that can improve insomnia among service members, veterans, and their adult dependents who receive behavioral health care. This information could help improve readiness and overall wellbeing of US service members and their family.</p>
      <p><b>What health information will be collected, and will it be kept confidential?</b><br/>
          Federal regulations give certain rights related to health information.  These include the right to know who will have access to the information. </p>
      <div class="container">
          <div class="row">
              <div class="col">
              If you choose to participate in this study, you will be asked to provide some demographic information (for example: age, sex, background training, current position) and some information about your training in behavioral modification and expectations. You will also be asked for feedback regarding how you feel about the recommendations you gave your patient(s), and whether they were helpful or not, as well as any other feedback you may have about the platform.  During the study, we will collect information about how often and how long you use the NOCTEM clinician portal, the number of texts you send to your patients via the messaging system, and the type of modifications you may decide to make the recommendations for healthy sleep practices. The score of your weekly and follow-up ratings of clinical improvements for each of your patients will also be collected.
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/10.png" width="80px"></div>
          </div>
      </div>
      <br/>
      <p>All your information will be kept confidential. You will receive a unique login and choose your own password to access the NOCTEM portal.</p>
      <p>When your patient(s) first enroll in the study and download the app, they will be asked to provide their phone number (to link their cellular-enabled smart device to your clinician portal), which will be maintained over the duration of study and then deleted at the completion of the project. They will be asked to provide information about sleep and wake behaviors that impact sleep and biological rhythms, mood and stress symptoms, as well as physical symptoms they may already be experiencing or that occur during the study. No private identifiable information will be associated with data at any time during this study.</p>
      <p>All of your patient's information will be kept confidential. Your patients will receive their own unique login for the app. You and members of the research team will have access to deidentified patients' data.</p>
      <div class="container">
          <div class="row">
              <div class="col"><img src="../../../../../assets/img/consent/11.png" width="100px"></div>
              <div class="col">
                  You may change your mind and revoke your decision to participate at any time. To revoke your permission you must write to the person in charge of the study, Dr. Anne Germain at <a href='mailto:anne@noctemhealth.com.'>anne@noctemhealth.com.</a>  At this time, your access to the platform will be terminated. When you revoke permission, no new information about you will be gathered after that date and you may no longer be allowed to further participate in the study. Information that has already been gathered may still be used and there is no guarantee that it will be removed from the electronic database for this study.
              </div>
          </div>
      </div>

    `
      },
      {
        requireInitials: true,
        content: `
      <p>Dr. Anne Garmin is responsible for stroing you and your patient(s) information collected during the study.  There will be no PII/PHI collected for the purposes of this study other than your patient's telephone number and device identifier for the purose of using the NOCTEM smartphone application.  All assessments will be given via the NOCTEM platform and stored in a secure HIPAA-compliant cloud.  Access to all data will be limited to staff involved in this study, and results may be published in DoD technical reports, scientific journals, or presented at scientific meetings.  No publication or presentation about the research study described above will reveal your, or your patients' identity without another authorization from you.</p>
      <p>By signing this consent form, you are giving permission to use the information listed above for the purposes described in this form.  If you refuse to give permission, you will not be able to participate in this study.</p>
      <p><b>Who else may receive or review information about your participation in the study?</b><br/>
          In addition to the investigators listed on the first page of this consent form and their research staff, the following individuals will or may have access to identifiable information (which may include your patient(s) identifiable medical information) for the purpose of monitoring the appropriate conduct of this research study:</p>
      <ul><li>Authorized representatives of Solutions IRB (the organization that oversees the protection of human research) and USAMRMC Human Research Protection Office may review your or your patients' research information (which may include their identifiable study information) for the purpose of audits and monitoring the appropriate conduct of the research study.</li></ul>
      <p><b>What are your rights if you take part in this study?</b><br/>
          Taking part in this study is your choice. Your participation must be completely voluntary. If you decide to take part, you may still leave the study at any time. No matter what decision you make, there will be no penalty to you (or your patients), and you will not lose any benefit to which you are otherwise entitled.</p>
      <div class="container">
          <div class="row">
              <div class="col-8">
                  <b>What if you have questions about the study?</b>
                  Do not sign this consent form unless you have had a chance to ask questions and have received satisfactory answers to all of your questions. You should contact the following individuals to answer your questions:
              </div>
              <div class="col-4"><img src="../../../../../assets/img/consent/12.png" width="80px"></div>
          </div>
      </div>
      <p><b>For questions about the research study</b>, contact Dr. Anne Germain at (412) 897-3183 or <a href="mailto:anne@noctemhealth.com">anne@noctemhealth.com.</a> She is the overall PI of the project and lead project manager for the NOCTEM team, the company who develops, deploys, and tests the NOCTEM platform.</p>
      <p><b>You may also contact your local point of contact (POC)</b>, [doctor], or his/her coordinator, [coordinator_name], at [coordinator_phone] or via email at [coordinator_email].</p>
      <p><b>For questions about the technical aspects and the content of the NOCTEM app, portal, and messaging system</b>, contact Dr. Anne Germain at (412) 897-3183 or <a href="mailto:anne@noctemhealth.com">anne@noctemhealth.com.</a></p>
      <p><b>For questions about the ethical aspects of this study or subjects' rights</b>, contact Solutions IRB (the body that oversees our protection of study participants) at (855) 226-4472 or <a href="mailto:participants@solutionsirb.com">participants@solutionsirb.com</a>.
    `
      },
      {
        requireInitials: false,
        content: `
      <h4>CONSENT TO TAKE PART IN THIS RESEARCH STUDY</h4>
      <div class="container">
          <div class="row">
              <div class="col">
                  You have read the information in this consent form. You have been given an opportunity to ask questions about this study and its procedures and risks, as well as any of the other information contained in this consent form. All your questions have been answered to your satisfaction, and you know how to contact in case additional questions come up during the participation.
              </div>
              <div class="col"><img src="../../../../../assets/img/consent/13.png" width="80px"></div>
          </div>
      </div>
      <br/>
      <p><b>Consent for future use.</b> By signing below, you consent to use of information collected about you during this study to be used for future research efforts which may include research on clinicians' use of digital health technologies in their habitual practice and outcomes. Your research data may also be used to advance computerized methods to improve the precision of automated prediction for the detection of sleep disturbance and precision of sleep recommendations. At present, the knowledge needed to estimate sleep disturbances and provide personalized interventions is limited to clinicians in specialty care clinics, such as sleep clinics. We aim to develop reliable computerized methods that support a greater number of clinicians to be able to reach and offer solid sleep assessment and interventions to a greater number of individuals, regardless of their geographical location.  For these reasons, we request your permission to save your data for potential use in the future.</p>
      <p>By providing your electronic signature below, you freely give your consent to be in this research study as it has been explained to you. You authorize the use and disclosure of you de-identified data to the persons listed in the investigators and health information and privacy section of this consent for the purposes described above. You will receive a copy of this form for your personal records via email, which will include a statement informing you about the provisions of the Privacy Act.</p>
    `
      }
     ]
  } as UserAgreement;

  