import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentAnswer, AssessmentQuestion } from '../../services/models';

@Component({
  selector: 'dropdown-question',
  templateUrl: './dropdown-question.component.html',
  styleUrls: ['./dropdown-question.component.scss']
})
export class DropdownQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Input() previousValue;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  @Output() checkboxUpdated: EventEmitter<any> = new EventEmitter();
  @Output() otherUpdated: EventEmitter<any> = new EventEmitter();
  selectedValue: any;
  conditionalSelectedValue: any;
  selectableValues = [];

  constructor() { }

  ngOnInit() {
    let value;
    if (this.question.dropdownAnswerOptions[0].display === 'min') {
      // Numerical dropdown options
      for (value = this.question.dropdownAnswerOptions.find(x => x.display === 'min').value; value < this.question.dropdownAnswerOptions.find(x => x.display === 'max').value; value++) {
        this.selectableValues.push({label:value, value});
      }
      this.selectableValues.push({label:'Prefer not to answer', value:'N/A'});
      if(this.question.dropdownAnswerOptions[this.question.dropdownAnswerOptions.length - 1].value === 'N/A') {
        const val = this.question.dropdownAnswerOptions[this.question.dropdownAnswerOptions.length - 1];
        this.selectableValues.push(val);
      }
      this.selectedValue = this.selectableValues[0].value;
    }
    else {
      for (value = 0; value < this.question.dropdownAnswerOptions.length; value++) {
        const val = this.question.dropdownAnswerOptions[value];
        this.selectableValues.push({label:val.display, value:val.value});
      }
      this.selectedValue = this.selectableValues[0].value;
    }
    if(this.previousValue != null) {
      this.selectedValue = this.previousValue;
    }
  }

  valueUpdated() {
    this.answerUpdated.emit(this.selectedValue);
  }

  otherInputUpdated(answer) {
    this.answerUpdated.emit(answer);
  }

  checkboxValueUpdated(answer) {
    this.answerUpdated.emit(answer);
  }

}
