import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const sleepGoalsPre: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-quick',
    unit: UnitType.YesNo,
    abbreviation: 'Fall Asleep Quickly',
    description: `Fall Asleep Quickly`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_QUICK'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-stay',
    unit: UnitType.YesNo,
    abbreviation: 'Stay',
    description: `Stay`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_STAY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-three',
    unit: UnitType.YesNo,
    abbreviation: 'Three',
    description: `Three`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_THREE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-refreshed',
    unit: UnitType.YesNo,
    abbreviation: 'Refreshed',
    description: `Refreshed`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_REFRESHED'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-nightmares',
    unit: UnitType.YesNo,
    abbreviation: 'Nightmares',
    description: `Nightmares`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_NIGHTMARES'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-racing',
    unit: UnitType.YesNo,
    abbreviation: 'Racing',
    description: `Racing`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_RACING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-share-bed',
    unit: UnitType.YesNo,
    abbreviation: 'Share Bed',
    description: `Share Bed`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_SHARE_BED'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-get-along',
    unit: UnitType.YesNo,
    abbreviation: 'Get Along',
    description: `Get Along`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_GETALONG'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-yell',
    unit: UnitType.YesNo,
    abbreviation: 'Yell',
    description: `Yell`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_YELL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-tossing',
    unit: UnitType.YesNo,
    abbreviation: 'Tossing',
    description: `Tossing`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_TOSSING'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-alcohol',
    unit: UnitType.YesNo,
    abbreviation: 'Alcohol',
    description: `Alcohol`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_ALCOHOL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-sleep-aids',
    unit: UnitType.YesNo,
    abbreviation: 'Sleep Aids',
    description: `Sleep Aids`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_SLEEP_AIDS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-health',
    unit: UnitType.YesNo,
    abbreviation: 'Health',
    description: `Health`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_HEALTH'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-work',
    unit: UnitType.YesNo,
    abbreviation: 'Work',
    description: `Work`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_WORK'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-other',
    unit: UnitType.Text,
    abbreviation: 'Other',
    description: `Other`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_GOALS_PRE_OTHER'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-tv',
    unit: UnitType.YesNo,
    abbreviation: 'TV',
    description: `TV`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_TV'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-phone',
    unit: UnitType.YesNo,
    abbreviation: 'Phone',
    description: `Phone`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_PHONE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-logs',
    unit: UnitType.YesNo,
    abbreviation: 'Logs',
    description: `Logs`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_LOGS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-caffeine',
    unit: UnitType.YesNo,
    abbreviation: 'Caffeine',
    description: `Caffeine`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_CAFFEINE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-schedule',
    unit: UnitType.YesNo,
    abbreviation: 'Schedule',
    description: `Schedule`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_SCHEDULE'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-tech',
    unit: UnitType.YesNo,
    abbreviation: 'Tech',
    description: `Tech`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_TECH'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-habits',
    unit: UnitType.YesNo,
    abbreviation: 'Habits',
    description: `Habits`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_HABITS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-me',
    unit: UnitType.YesNo,
    abbreviation: 'Me',
    description: `Me`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_ME'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-goals-pre-well',
    unit: UnitType.YesNo,
    abbreviation: 'Well',
    description: `Well`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_WELL'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'sleep-challenges-pre-other',
    unit: UnitType.Text,
    abbreviation: 'Other',
    description: `Other`,
    assessmentType: LogDefinitionTypes.SleepGoalsPre,
    answerId: 'SLEEP_CHALLENGES_PRE_OTHER'
  }),
  //#endregion
];
