import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const teamCommunicationAlgorithm = new SleepModuleDefinition({
  name: 'team-communication',
  isMedic: false,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-distant',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-irritable',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-work',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-communication',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-expressing',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-angry',
      valueToCompare: 3,
      operator: EqualityType.LessThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-handle-conflict',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'team-left-out',
      valueToCompare: 3,
      operator: EqualityType.LessThan,
      priority: 0
    }),
  ],
});
