<fieldset class="form-group question">
    <div class="form-check" *ngFor="let answer of question.answerOptions; let i = index">
        <ng-container *ngIf="getQuestionType(answer); else otherQuestionType">
            <div class="row no-gutters py-1">
                <input class="form-check-input col-2 align-self-center" type="checkbox" name="checkboxInput"
                    [value]="answer.value" (click)="valueUpdated(this, answer)" [checked]="answer.isChecked" />
                <label class="form-check-label col align-self-center" for="gridRadios">
                    {{ answer.display }}
                </label>
            </div>
        </ng-container>
        <ng-template #otherQuestionType>
            <div class="row no-gutters py-1">
                <input class="form-check-input col-2 align-self-center" type="checkbox" name="checkboxInput"
                    [value]="answer.value" (click)="valueUpdated(this, answer)" />
                <label class="form-check-label col align-self-center" for="gridRadios">
                    {{ answer.display }}
                    <input type="text" (keyup)="otherTextModified($event, answer)" [disabled]="!otherSelected" />
                </label>
            </div>
        </ng-template>
    </div>
</fieldset>