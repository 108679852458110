import { get, size, clone, chain, compact, filter, map } from 'lodash';
import { ErrorService, UtilityService, Validator } from '../utils';
import { UserSettings, IApplicationContext, IHttpProvider, InstanceAccess,
          InstanceAccessItem, MessageResponse, Message, Recipient, MessageType } from '../contracts';
import { BaseRepository } from '../core';
import { ApiHelper } from '../helpers';


export interface IMessageService {
    send(message: Message, usersIds: Array<string>, emailAddresses?: Array<string>): Promise<MessageResponse>;
    send(message: Message, users?: Array<UserSettings>, emailAddresses?: Array<string>): Promise<MessageResponse>;
    send(message: Message, users: any, emailAddresses?: Array<string>): Promise<MessageResponse>;
    queue(message: Message): Promise<MessageResponse>;
}

export class MessageService implements IMessageService {
  private http: IHttpProvider;
  protected _apiHelper: ApiHelper;
  protected _messageRepository: BaseRepository<Message>;
  protected _userRepository: BaseRepository<UserSettings>;

  constructor(protected appContext: IApplicationContext) {
    this._apiHelper = new ApiHelper(appContext);
    this._messageRepository = new BaseRepository<Message>(appContext, 'Message');
    this._userRepository = new BaseRepository<UserSettings>(appContext, 'UserSettings');
    this.http = appContext.HttpProvider;
  }

  public async send(message: Message, usersIds: Array<string>, emailAddresses?: Array<string>): Promise<MessageResponse>;
  public async send(message: Message, users: Array<UserSettings>, emailAddresses?: Array<string>): Promise<MessageResponse>;
  public async send(message: Message, users: any[], emailAddresses?: Array<string>): Promise<MessageResponse> {
    try {
      let from: string, messageRespose: MessageResponse;

      Validator.throwIfNil(message, 'Missing message');
      from = get<any, string>(message, 'From');

      Validator.throwIfAnyAreFalsey([from], 'Missing message sender (from).');

      // fetch users
      if (size(users) > 0) {
        if (typeof users[0] == 'string') {
          // We need to fetch them
          const userIds = clone(users);
          const query = { $or: [{ 'Payload.UserId': { $in: userIds } }, { _id: { $in: userIds } }] };
          users = await this._userRepository.getAllAsync(query);
        }
      } else {
        users = [];
      }

      // set recipients
      message.Recipients = message.Recipients || [];
      if (size(users) > 0) {
        for (const rep of users) {
          message.Recipients.push(new Recipient(rep.UserId, rep.Profile.EmailAddress, MessageType.Email));
        }
      }

      // for free-text email input
      if (size(emailAddresses)) {
        for (const emailAddress of emailAddresses) {
          if (!UtilityService.isValidEmailAddress(emailAddress)) {
            continue;
          }
          message.Recipients.push(new Recipient(null, emailAddress, MessageType.Email));
        }
      }
      message = await this._messageRepository.saveAsync(message);
      await this.setAcl(message);
      //TODO: Wayan disabled this 20200326, need to investigate more on what the purpose and what the effect
      //messageRespose = await this.queue(message); <-- this still call API /v1/ !BLAME
      //return messageRespose;
      let result = new MessageResponse()
      result.Succeeded = true;
      return result;
    } catch (e) {
      throw ErrorService.handle(e, 'Error in method: send');
    }
  }

  public async sendBugReport(email: string, message: string) {
    const params: any = {
      uri: this._apiHelper.RootUrl() + '/messageThread/sendBugReport',
      json: {email, bugDescription: message},
      data: null,
      method: 'POST',
      headers: this._apiHelper.Headers()
  };

    return this.http.post(params).toPromise();
  }

  public async setAcl(message): Promise<any> {
    let acl: InstanceAccess, viewers: InstanceAccessItem[];

    viewers = this.buildAclViewersFromRecipientsThatHaveUserGuid(message.Recipients);
    if (!size(viewers)) {
      return null;
    }

    acl = new InstanceAccess();
    acl.Cascade = false;
    acl.List = { All: false, Editors: [], Viewers: viewers, Owners: [] };

    return this._messageRepository.setAcl(message, acl);
  }

  private buildAclViewersFromRecipientsThatHaveUserGuid(recipients: Recipient[]): InstanceAccessItem[] {
    return chain(recipients)
      .compact()
      .filter(recipient => !!recipient.Id)
      .map<Recipient, InstanceAccessItem>(recipient => new InstanceAccessItem(recipient.Id, 'User', false))
      .value();
  }

  // TODO switch to request-promise
  public queue(message: Message): Promise<MessageResponse> {
    return new Promise((resolve, reject) => {
      // Call tds endpoint

      const params = {
        uri: this._apiHelper.MessageUrl(),
        headers: this._apiHelper.Headers(),
        method: 'POST',
        json: message,
        data: null
      };

      this.http.post(params).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
}
