import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
@Component({
    selector: 'terms-dialog',
    styles: [`
      .mat-dialog-content {
        max-height: 80vh;
      }
    `],
    templateUrl: './terms-dialog.component.html',
})
export class TermsDialog implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<TermsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

}
