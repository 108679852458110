export const proprDef = {
    "name": "propr",
    "description": "PROMIS® 29+2 Profile v2.1 (PROPr)",
    "instructions": null,
    "questions": [
    /* moved to db
      {
        "id": 0,
        "questionText":
          "",
        "questionEmphasis": "Are you able to do chores such as vacuuming or yard work?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
          { "display": "Without any difficulty", "value": 5 },
          { "display": "With a little difficulty", "value": 4 },
          { "display": "With some difficulty", "value": 3 },
          { "display": "With much difficulty", "value": 2 },
          { "display": "Unable to do", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PFA11"
      },
      {
        "id": 1,
        "questionText":
          "",
        "questionEmphasis": "Are you able to go up and down stairs at a normal pace?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Without any difficulty", "value": 5 },
            { "display": "With a little difficulty", "value": 4 },
            { "display": "With some difficulty", "value": 3 },
            { "display": "With much difficulty", "value": 2 },
            { "display": "Unable to do", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PFA21"
      },
      {
        "id": 2,
        "questionText":
          "",
        "questionEmphasis": "Are you able to go for a walk of at least 15 minutes?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Without any difficulty", "value": 5 },
            { "display": "With a little difficulty", "value": 4 },
            { "display": "With some difficulty", "value": 3 },
            { "display": "With much difficulty", "value": 2 },
            { "display": "Unable to do", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PFA23"
      },
      {
        "id": 3,
        "questionText":
          "",
        "questionEmphasis": "Are you able to run errands and shop?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Without any difficulty", "value": 5 },
            { "display": "With a little difficulty", "value": 4 },
            { "display": "With some difficulty", "value": 3 },
            { "display": "With much difficulty", "value": 2 },
            { "display": "Unable to do", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PFA53"
      },

      {
        "id": 4,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt fearful",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDANX01"
      },
      {
        "id": 5,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I found it hard to focus on anything other than my anxiety",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDANX40"
      },
      {
        "id": 6,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "My worries overwhelmed me",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDANX41"
      },
      {
        "id": 7,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt uneasy",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDANX53"
      },

      {
        "id": 8,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt worthless",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDDEP04"
      },
      {
        "id": 9,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt helpless",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDDEP06"
      },
      {
        "id": 10,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt depressed",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDDEP29"
      },
      {
        "id": 11,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I felt hopeless",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 1 },
            { "display": "Rarely", "value": 2 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Often", "value": 4 },
            { "display": "Always", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "EDDEP41"
      },

      {
        "id": 12,
        "questionText":
          "During the past 7 days, ",
        "questionEmphasis": "I feel fatigued",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "HI7"
      },
      {
        "id": 13,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I have trouble starting things because I am tired",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "AN3"
      },
      {
        "id": 14,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How run-down did you feel on average?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "FATEXP41"
      },
      {
        "id": 15,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How fatigued were you on average?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "FATEXP40"
      },

      {
        "id": 16,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "My sleep quality was",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Very poor", "value": 5 },
            { "display": "Poor", "value": 4 },
            { "display": "Fair", "value": 3 },
            { "display": "Good", "value": 2 },
            { "display": "Very good", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "Sleep109"
      },
      {
        "id": 17,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "My sleep was refreshing",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 5 },
            { "display": "A little bit", "value": 4 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 2 },
            { "display": "Very much", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "Sleep116"
      },
      {
        "id": 18,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I had a problem with my sleep",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "Sleep20"
      },
      {
        "id": 19,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I had difficulty falling asleep",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "Sleep44"
      },

      {
        "id": 20,
        "questionText":
          "",
        "questionEmphasis": "I have trouble doing all of my regular leisure activities with others",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 5 },
            { "display": "Rarely", "value": 4 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Usually   ", "value": 2 },
            { "display": "Always", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "SRPPER11_CaPS"
      },
      {
        "id": 21,
        "questionText":
          "",
        "questionEmphasis": "I have trouble doing all of the family activities that I want to do",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 5 },
            { "display": "Rarely", "value": 4 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Usually", "value": 2 },
            { "display": "Always", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "SRPPER18_CaPS"
      },
      {
        "id": 22,
        "questionText":
          "",
        "questionEmphasis": "I have trouble doing all of my usual work (include work at home)",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 5 },
            { "display": "Rarely", "value": 4 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Usually", "value": 2 },
            { "display": "Always", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "SRPPER23_CaPS"
      },
      {
        "id": 23,
        "questionText":
          "",
        "questionEmphasis": "I have trouble doing all of the activities with friends that I want to do",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Never", "value": 5 },
            { "display": "Rarely", "value": 4 },
            { "display": "Sometimes", "value": 3 },
            { "display": "Usually", "value": 2 },
            { "display": "Always", "value": 1 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "SRPPER46_CaPS"
      },

      {
        "id": 24,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How much did pain interfere with your day to day activities?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PAININ9"
      },
      {
        "id": 25,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How much did pain interfere with work around the home?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PAININ22"
      },
      {
        "id": 26,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How much did pain interfere with your ability to participate in social activities?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PAININ31"
      },
      {
        "id": 27,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How much did pain interfere with your household chores?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PAININ34"
      },

      {
        "id": 28,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I have been able to concentrate",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PC6r"
      },
      {
        "id": 29,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "I have been able to remember to do things, like take medicine or buy something I needed",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "Not at all", "value": 1 },
            { "display": "A little bit", "value": 2 },
            { "display": "Somewhat", "value": 3 },
            { "display": "Quite a bit", "value": 4 },
            { "display": "Very much", "value": 5 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "PC27r"
      },

      {
        "id": 30,
        "questionText":
          "In the past 7 days, ",
        "questionEmphasis": "How would you rate your pain on average?",
        "questionType": "radio",
        "autofill": false,
        "answerOptions": [
            { "display": "0  - No Pain", "value": 0 },
            { "display": "1", "value": 1 },
            { "display": "2", "value": 2 },
            { "display": "3", "value": 3 },
            { "display": "4", "value": 4 },
            { "display": "5", "value": 5 },
            { "display": "6", "value": 6 },
            { "display": "7", "value": 7 },
            { "display": "8", "value": 8 },
            { "display": "9", "value": 9 },
            { "display": "10 - Worst pain imaginable", "value": 10 }
        ],
        "conditionalQuestions": [],
        "uniqueAnswerId": "Global07"
      }
      **/
    ]
  };
  