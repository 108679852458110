<div *ngIf="stateService.state$ | async as state">
    <div *ngIf="!data.isEdit">
        <div mat-dialog-content>
            <div class="modal-header">
                <h5 class="modal-title">Add Event For: {{state.selectedPatient.display}}</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <label>Event Start</label>
                        <input name="eventDate" [(ngModel)]="eventDate" [value]="eventDate" placeholder="Select a date"
                            type="date">
                    </div>
                    <div class="col-6">
                        <label>&nbsp;</label>
                        <input name="eventTime" [(ngModel)]="eventTime" [value]="eventTime" placeholder="Select a time"
                            type="time">
                    </div>
                </div>

                <div class="row no-gutters breathe">
                    <div class="col-3">
                        <input type="checkbox" name="eventRepeat" [(ngModel)]="eventRepeat">
                        <label>Repeat</label>
                    </div>
                    <div class="col" *ngIf="eventRepeat">
                        <input type="number" min="1" max="10" name="eventRepeatTimes" [(ngModel)]="eventRepeatTimes">
                    </div>
                    <div class="col" *ngIf="eventRepeat">
                        <select id="eventRepeatType" [(ngModel)]="eventRepeatType"
                            style="width: 100%; margin-top: 12px; padding: 4px 0;">
                            <option value="1" selected>Daily</option>
                            <option value="2">Biweekly</option>
                        </select>
                    </div>
                </div>

                <div class="row breathe" *ngIf="state.userActions.length">
                    <div class="col-6">
                        <label>Action Type</label>
                        <select name="eventPhaseName" [(ngModel)]="eventPhaseName">
                            <option *ngFor="let thisAction of state.userActions" [(value)]="thisAction.ActionType">
                                {{thisAction.Description}}
                            </option>
                        </select>
                    </div>
                    <div class="col-6">
                        <label>Color</label><input name="eventColor" [(ngModel)]="eventColor" type="color">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div mat-dialog-actions>
                <button mat-button class="btn btn-secondary" (click)="onNoClick()">Close</button>
                <button mat-button class="btn btn-primary" (click)="onAddNew()">Add</button>
            </div>
        </div>
    </div>
    <div *ngIf="data.isEdit">
        <div mat-dialog-content>
            <div class="modal-header">
                <h5 class="modal-title">{{!eventReadOnly ? 'Edit' : 'View'}} Event For: {{data.calEvent.owner.display}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="field">
                    <label>Event Start</label>
                    <div class="ui input" style="width:200px">
                        <input class="ui input form-control" [attr.disabled]="(eventReadOnly ? '' : null)"
                            name="eventDate" [(ngModel)]="eventDate" [value]="eventDate" placeholder="Select a date"
                            type="date">
                        <input class="ui input form-control" [attr.disabled]="(eventReadOnly ? '' : null)"
                            name="eventTime" [(ngModel)]="eventTime" [value]="eventTime" placeholder="Select a time"
                            type="time">
                    </div>
                </div>
                <div class="field" *ngIf="state.allActions.length">
                    <label>Action Type</label><br>
                    <select style="width: 150px; height: 37px" disabled name="eventPhaseName"
                        [(ngModel)]="eventPhaseName">
                        <option *ngFor="let thisAction of state.allActions" [(value)]="thisAction.ActionType">
                            {{thisAction.Description}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Color</label>
                    <input name="eventColor" [attr.disabled]="(eventReadOnly ? '' : null)" [(ngModel)]="eventColor"
                        type="color">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div mat-dialog-actions>
                <button mat-button class="btn btn-secondary" (click)="onNoClick()">Close</button>
                <button mat-button class="btn btn-danger" (click)="onDelete()"
                    *ngIf="!this.eventReadOnly">Delete</button>
                <button mat-button class="btn btn-primary" (click)="onSave()" *ngIf="!this.eventReadOnly">Save</button>
            </div>
        </div>
    </div>
</div>