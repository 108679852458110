import { UserAgreement } from "../../../../core";

export default new UserAgreement({
  id:6,
  name: 'consent',
  title: 'Patient Consent to RTM',
  // imgName:'rtm.png',
  questionHeader: 'To finalize the activation of the NOCTEM app, please check each box below to confirm that all statements are true:',
  textReplacementMethod:'replaceConsentText',
  agreementQuestions: [
    {
      answerId: 'agree',
      text: 'I hereby consent to receiving RTM services using COAST.'
    },
  ],
  pages: [
      {
        title: '',
        imgPath: '',
        text: `
        <div class="consent-content">
          <p>By checking the box associated with “INFORMED CONSENT“, you acknowledge that you understand and agree with the following:</p>

          <ol>
            <li>The use of the COAST app is subject to my consent for sleep care executed with [provider_name], as well as the HIPAA consent form.</li>
            <li>I understand that sleep services using the COAST app will be provided under my current treatment plan with [provider_name] and includes the use of remote data transfer and internet-based messaging and its related internet platform to communicate with my sleep provider.</li>
            <li>The COAST app collects daily measurements of my daily sleep and other habits that I will personally report via the app.  I may also communicate additional sleep-relevant information to my provider via the messaging function of the COAST app.  This reporting is referred to by Medicare and other payors as “Remote Sleep Monitoring” (or RTM).  The Coast app can only transmit information that I voluntarily provide by answering daily questions on the sleep logs or by completing weekly assessments.  My healthcare provider will receive and review this information and use it to send me personalized recommendations and voluntary instructions to improve my sleep .  I will receive these instructions and information via the COAST app.</li>
            <li>I understand that information and instructions I will receive are sent by my healthcare provider and are not an automated response from the app itself.</li>
            <li>I understand the information provided through the COAST patient app transfers my self-reported personal health information via an internet-based website and will be stored in the “cloud”. I further understand that the clinical/ RTM services that I receive through the COAST app may be provided by other health practitioners or clinical staff who may be located in other areas, including out of state.</li>
            <li>I understand that this is NOT AN EMERGENCY RESPONSE UNIT AND IS NOT MONITORED 24/7, and that I will be directed to dial 9-1-1 immediately and that [provider_name] and NOCTEM Health or [provider_name] and NOCTEM Health’s clinical staff are not able to connect me directly to any local emergency services.</li>
            <li>I understand there is a risk of technical failures during the RTM services provided through COAST beyond the control of the [provider_name] and NOCTEM Health.  I understand that [provider_name] and NOCTEM Health make no warranties of any kind with respect to delays in the exchange of information through COAST (including information about my sleep care)  or for information lost due to technical failures, delays, or similar problems.</li>
            <li>I understand that I have the right to withhold or withdraw my consent to use of COAST and/or RTM service in the course of my care at any time, without affecting my right to future care or treatment. I may suspend or terminate use of the RTM services at any time for any reason or for no reason.</li>
            <li>I understand that my healthcare information may be shared with other individuals for scheduling and billing purposes.</li>
          </ol>

          <p>For questions, I can reach [provider_contact_name] at [provider_contact_number].</p>

        </div>
        `
      },
  ]
});
