import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BaseUserStateService } from './user/BaseUserStateService';
import { UserService } from './user/user.service';
import { CachedUserDecorator } from './user/cached-user-decorator';
import { AuthService, UserState, ANON_TOKEN, ChangePasswordState } from './user';
import { ModelFactory, ApplicationContext } from '../../core/web-ng';
import { NoctemUser } from '../services/models';
import { CacheService } from '../services/cache-service';
import { NetworkService } from '../services/network-service';
export const USER_STATE_KEY = "BASE_USER_STATE_KEY";

@Injectable()
export class UserStateService extends BaseUserStateService<NoctemUser, UserState<NoctemUser>> {
    saveAsync(user: NoctemUser) {
      throw new Error('Method not implemented.');
    }
    constructor(
        private appContext: ApplicationContext,
        @Inject(ANON_TOKEN) anonToken: string,
        authService: AuthService,
        private cacheService: CacheService,
        networkService: NetworkService,
        modelFactory: ModelFactory<UserState<NoctemUser>>,
        router: Router
    ) {
        super(new UserService(appContext), anonToken, authService, appContext, modelFactory, networkService, router);

        this.onUserLoaded$.subscribe(state => {
            if (state.IsUserInitialized && !state.IsLoading && state.User) {
                this.cacheService.set(USER_STATE_KEY, state);
            }
        });

        if (this.networkService.hasInitialized() && !this.networkService.isOnline()) {
            this.emitCachedUser();
        } else {
            this.networkService.onConnectionChange$.subscribe(connection => {
                if (!this.networkService.isOnline()) {
                    setTimeout(() => {
                        this.emitCachedUser();
                    }, 1000);
                }
            })
        }
    }

    private emitCachedUser() {
        let state = this.cacheService.get(USER_STATE_KEY)
        if (state) {
            this.appContext.User = state.User;
            state.IsUserInitialized = true;
            state.IsLoading = false;
            state.IsLoggedIn = true;
            this.onUserLoaded$.next(state);
            this.setModel(state);
        }
    }

    get defaultState(): UserState<NoctemUser> {
        return {
            User: null,
            Roles: [],
            Organizations: [],
            Token: null,
            IsLoading: false,
            IsLoggedIn: false,
            IsUserInitialized: false,
            Errors: null,
            ChangePasswordState: new ChangePasswordState()
        };
    }

    initialize() {
        if (this.networkService.hasInitialized() && !this.networkService.isOnline()) {
            this.emitCachedUser();
        }
        this.setToken(localStorage.getItem('token'));
    }
}
