<div class="auto-complete-container {{ searchStyle }}">
    <div class="input-container">
        <i class="fa fa-search" aria-hidden="true"></i>
        <input class="{{ searchStyle }}" type="text" matInput [formControl]="searchControl" (input)="searchBlur($event)"
            [matAutocomplete]="auto" placeholder="Search...">
    </div>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of filteredUsers" [value]="user" (click)="optionClicked(user)"
            (onSelectionChange)="optionClicked(user)">
            <span>{{user.profile.firstName?.toUpperCase()}} {{user.profile.lastName?.toUpperCase()}}</span>
        </mat-option>
    </mat-autocomplete>
</div>