export const defenderDef = {
  name: 'defender',
  displayName: 'Defender',
  label: 'Sleep Defender',
  pointLowerLimit: 73,
  pointUpperLimit: 144,
  stageName: 'Stage 4',
  activeImagePath: 'defender-front.png',
  inactiveImagePath: 'defender-front-inactive.png',
  imagePathTails: 'defender-back.png',
  isActive: true,
  activeCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Very rare challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin is achieved by completing stage 4 and accumulating 144 or more points'
    },
    {
      title: 'Description',
      description:
        'This coin is earned by only the most elite sleep trainers. You have implemented NOCTEM sleep training tactics with high accuracy and precision. Congratulations on mastering your sleep.  Carpe Noctem! Seize the night.'
    }
  ],
  inactiveCoinDescriptions: [
    {
      title: 'Basics',
      description:
        'Very rare challenge coin. [achievedCount] Noctem Users have achieved this challenge coin.'
    },
    {
      title: 'Requirements',
      description:
        'This coin can be achieved by completing stage 4 and accumulating 144 or more points'
    },
    {
      title: 'Description',
      description:
        'This coin is earned by only the most elite sleep trainers, those who implement NOCTEM sleep training tactics with high accuracy and precision.  Carpe Noctem! Seize the night.'
    }
  ]
};
