import { AssessmentDefinition } from '../../../../../noctem-lib/src/core/contracts/models.calculations';
const greenMessage = 'Great communication, high level of comfort sharing thoughts and feelings with team members';
const yellowMessage = 'Can communicate to accomplish tasks, however only moderate trust and openness';
const redMessage = 'High level of conflict, hostile interactions or no communication between team members';

export const vactcs: AssessmentDefinition = {
  name: 'vactcs',
  description: 'Visual Analog Current Team Communication Scale',
  instructions: null,
  questions: [
    {
      id: 0,
      name: 'vactcs',
      questionText: 'How well has your team been working together & communicating?',
      questionEmphasis: null,
      questionType: 'slider',
      uniqueAnswerId: 'TEAM_COMMUNICATION_VISUAL',
      autofill: false,
      step: 1,
      max: 10,
      min: 0,
      answerOptions: [
        { display: greenMessage, value: 0 },
        { display: greenMessage, value: 1 },
        { display: greenMessage, value: 2 },
        { display: greenMessage, value: 3 },
        { display: yellowMessage, value: 4 },
        { display: yellowMessage, value: 5 },
        { display: yellowMessage, value: 6 },
        { display: redMessage, value: 7 },
        { display: redMessage, value: 8 },
        { display: redMessage, value: 9 },
        { display: redMessage, value: 10 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
  ]
};
