export const isiDef = {
  name: 'isi',
  description: 'Insomnia Severity Index',
  instructions: '',
  questions: [
    {
      id: 0,
      questionText:
        'Please select the answer that best describes the severity of the following problem over the last 2 weeks:',
      questionEmphasis: 'Difficulty falling asleep',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very Severe', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_FALL_ASLEEP'
    },
    {
      id: 1,
      questionText:
        'Please select the answer that best describes the severity of the following problem over the last 2 weeks:',
      questionEmphasis: 'Difficulty staying asleep',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very Severe', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_STAY_ASLEEP'
    },
    {
      id: 2,
      questionText:
        'Please select the answer that best describes the severity of the following problem over the last 2 weeks:',
      questionEmphasis: 'Problems waking up too early',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'None', value: 0 },
        { display: 'Mild', value: 1 },
        { display: 'Moderate', value: 2 },
        { display: 'Severe', value: 3 },
        { display: 'Very Severe', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_WAKE_EARLY'
    },
    {
      id: 3,
      questionText:
        'In the past 2 weeks, how satisfied/dissatisfied are you with your current sleep pattern?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Very Satisfied', value: 0 },
        { display: 'Satisfied', value: 1 },
        { display: 'Moderately Satisfied', value: 2 },
        { display: 'Dissatisfied', value: 3 },
        { display: 'Very Dissatisfied', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_SLEEP_PATTERN'
    },
    {
      id: 4,
      questionText:
        'In the past 2 weeks, how noticeable to others do you think your sleep problem is in terms of impairing the quality of your life?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Noticeable A Little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Noticeable', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_NOTICEABLE'
    },
    {
      id: 5,
      questionText:
        'In the past 2 weeks, how worried/distressed are you about your current sleep problem?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Worried A Little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Worried', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_WORRIED'
    },
    {
      id: 6,
      questionText:
        'Currently, to what extent do you consider your sleep problem to interfere with your daily functioning (e.g. daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.)?',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Interfering A Little', value: 1 },
        { display: 'Somewhat', value: 2 },
        { display: 'Much', value: 3 },
        { display: 'Very Much Interfering', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'ISI_INTERFERE'
    }
  ]
};
