import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'alert-display',
  templateUrl: './alert-display.component.html',
  styleUrls: []
})
export class AlertDisplayComponent implements OnInit {
  @Input() message: string;
  @Input() alertClass: string = 'alert alert-dismissible alert-noctem';
  @Output() cleared: EventEmitter<boolean> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.message = null;
    this.cleared.emit(true);
  }

}
