export const promisDef = {
  "name": "promis",
  "description": "Patient-Reported Outcomes Measurement Information System",
  "instructions": "",
  "questions": [
    {
      "id": 0,
      "questionText": "I am satisfied with my ability to do things for my family",
      "questionType": "radio",
      "uniqueAnswerId": "PROMIS_FAMILY",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "A little bit", "value": 1 },
        { "display": "Somewhat", "value": 2 },
        { "display": "Quite a bit", "value": 3 },
        { "display": "Very much", "value": 4 }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "questionText": "I am satisfied with my ability to do things for fun with others",
      "questionType": "radio",
      "uniqueAnswerId": "PROMIS_OTHERS",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "A little bit", "value": 1 },
        { "display": "Somewhat", "value": 2 },
        { "display": "Quite a bit", "value": 3 },
        { "display": "Very much", "value": 4 }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "I feel good about my ability to do things for my friends",
      "questionType": "radio",
      "uniqueAnswerId": "PROMIS_FRIENDS",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "A little bit", "value": 1 },
        { "display": "Somewhat", "value": 2 },
        { "display": "Quite a bit", "value": 3 },
        { "display": "Very much", "value": 4 }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 3,
      "questionText": "I am satisfied with my ability to perform my daily routines",
      "questionType": "radio",
      "uniqueAnswerId": "PROMIS_ROUTINES",
      "autofill": false,
      "answerOptions": [
        { "display": "Not at all", "value": 0 },
        { "display": "A little bit", "value": 1 },
        { "display": "Somewhat", "value": 2 },
        { "display": "Quite a bit", "value": 3 },
        { "display": "Very much", "value": 4 }
      ],
      "conditionalQuestions": []
    }
  ]
};
