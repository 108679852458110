<div class="row">
    <div class="col">
        <div class="form-group">
            <label>{{ question.questionText }}</label>
            <div class="center-align">
                <h3 *ngIf="question.questionEmphasis">
                    {{ question.questionEmphasis }}
                </h3>
            </div>
            <div *ngIf="question.questionType === 'text'">
                <input type="text" class="form-control" [(ngModel)]="answerValue" (keyup)="valueUpdated($event)"
                    (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
                <div class="center-align" *ngIf="question.showNotApplicableHint">
                    (answer "NA" if Not Applicable)
                </div>
            </div>
            <!-- Questions Type "textarea" is to be used for questions that may require more than one line of text for patient response -->
            <div *ngIf="question.questionType === 'textarea'" class="grow-wrap">
                <textarea class="form-control" [(ngModel)]="answerValue" (keyup)="valueUpdated($event)"
                    (focus)="controlFocused($event)" (blur)="controlBlurred($event)" oninput="growWrap()" onInput="this.parentNode.dataset.replicatedValue = this.value" ></textarea>
                <div class="center-align" *ngIf="question.showNotApplicableHint">
                    (answer "NA" if Not Applicable)
                </div>
            </div>
            <input *ngIf="question.questionType === 'number' && !question.answerOptions[0]" type="number"
                pattern="[0-9]*" (keypress)="valueValidated($event, question.validation.maxLength)" class="form-control"
                [(ngModel)]="answerValue" (keyup)="valueUpdated($event)" (focus)="controlFocused($event)"
                (blur)="controlBlurred($event)" />
            <input *ngIf="question.questionType === 'number' && question.answerOptions[0]" type="number"
                pattern="[0-9]*" (keypress)="valueValidated($event, question.answerOptions[0].value)"
                class="form-control" [(ngModel)]="answerValue" (keyup)="valueUpdated($event)"
                (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
            <input id="timeInput" *ngIf="question.questionType === 'time'" readonly="readonly" type="text"
                class="form-control" [(ngModel)]="answerValue" (click)="openTimeInput()"
                (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
            <input id="numberInput" *ngIf="question.questionType === 'number-wheel'" readonly="readonly"
                onfocus="this.removeAttribute('readonly');" type="text" class="form-control" [(ngModel)]="answerValue"
                (click)="openNumberWheelInput()" (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
            <input *ngIf="question.questionType === 'date'" readonly="readonly"
                onfocus="this.removeAttribute('readonly');" type="date" class="form-control"
                [value]="answerValue | date: 'yyyy-MM-dd'" (change)="valueUpdated($event)"
                (focus)="controlFocused($event)" (blur)="controlBlurred($event)" />
            <div *ngIf="question.questionType === 'select'" class="form-group select">
                <select id="inputState" class="form-control" [(ngModel)]="answerValue"
                    (change)="selectValueUpdated($event)" (focus)="controlFocused($event)"
                    (blur)="controlBlurred($event)">
                    <option value="select" disabled></option>
                    <option *ngFor="let option of question.answerOptions" [value]="option.value">
                        {{ option.display }}
                    </option>
                </select>
                <div class="select_arrow"></div>
            </div>
            <radio-group-question [question]="question" [previousValue]="answerValue"
                *ngIf="question.questionType === 'radio'" (answerUpdated)="radioValueUpdated($event)">
            </radio-group-question>
            <checkbox-question [question]="question" [previousValue]="checkboxAnswers"
                *ngIf="question.questionType === 'checkbox'" (answerUpdated)="checkboxValueUpdated($event)">
            </checkbox-question>
            <slider-question [question]="question" *ngIf="question.questionType === 'slider'"
                (answerUpdated)="radioValueUpdated($event)">
            </slider-question>
            <time-input-modal *ngIf="isModalDisplayed && question.questionType === 'time'" [question]="question"
                (answerUpdated)="onTimeQuestionAnswered($event)"></time-input-modal>
        </div>
    </div>
</div>