import { SleepModuleDefinition, Condition, DataSummationType, EqualityType } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const strategicNappingAlgorithm = new SleepModuleDefinition({
  name: 'strategic-napping',
  isMedic: false,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'total-sleep-past-24',
      valueToCompare: 361, // in minutes
      operator: EqualityType.LessThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleepy-feels',
      valueToCompare: 2, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    })

  ],
});

