import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const promis: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'promis-family',
    unit: UnitType.Number,
    abbreviation: 'Family',
    description: `Family`,
    assessmentType: LogDefinitionTypes.PROMIS,
    answerId: 'PROMIS_FAMILY'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'promis-others',
    unit: UnitType.Number,
    abbreviation: 'Others',
    description: `Others`,
    assessmentType: LogDefinitionTypes.PROMIS,
    answerId: 'PROMIS_OTHERS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'promis-friends',
    unit: UnitType.Number,
    abbreviation: 'Friends',
    description: `Friends`,
    assessmentType: LogDefinitionTypes.PROMIS,
    answerId: 'PROMIS_FRIENDS'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'promis-routines',
    unit: UnitType.Number,
    abbreviation: 'Routines',
    description: `Routines`,
    assessmentType: LogDefinitionTypes.PROMIS,
    answerId: 'PROMIS_ROUTINES'
  }),

  //#endregion
];
