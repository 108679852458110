<div class="confirm-delete-modal">
    <div mat-dialog-content>
        <div class="modal-header">
            <h3 class="modal-title">Remove {{data.eventToDelete.name}}?</h3>
        </div>
    </div>
    <div class="modal-footer">
        <div mat-dialog-actions>
            <button mat-button class="btn btn-primary" (click)="onNoClick()">Go Back</button>
            <button mat-button class="btn btn-danger" (click)="onSubmit()">Remove</button>
        </div>
    </div>
</div>