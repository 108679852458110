import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentQuestion } from '../../services/models';
import { LogStateService } from '../../state';
import { min } from 'rxjs/operators';
import { WheelSelectorIndexService } from '../../services/wheel-selector-index-service';

@Component({
  selector: 'time-input-modal',
  templateUrl: './time-input-modal.component.html',
  styles: []
})
export class TimeInputModalComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  answerValue: any;
  hourValue;
  minuteValue;
  hourOptions: Array<string>;
  twelveHourTimeDisplay: Array<string>;
  hourMinDisplay: Array<string>;
  minuteOptions: Array<string>;
  timeData = [];
  HOUR_ARRAY_UP = 24;
  MINUTE_ARRAY_UP = 12;
  displayStyle: Array<string>;

  constructor(
    private logStateService: LogStateService,
    private wheelSelectorIndexService: WheelSelectorIndexService
  ) {}

  ngOnInit() {
    this.hourOptions = this.createTimeOptions(24);
    this.minuteOptions = this.createTimeOptions(60);
    this.twelveHourTimeDisplay = this.createTwelveHourTimeOptions();
    this.hourMinDisplay = this.createHourMinOptions();
    this.displayStyle =
      (!this.question || !this.question.questionType || this.question.questionType === 'time') ? this.twelveHourTimeDisplay : this.hourMinDisplay;

    this.timeData = [
      {
        list: this.hourOptions,
        text: this.displayStyle,
        textAlign: 'right',
        className: 'hour-picker',
        weight: 2
      },
      {
        list: this.minuteOptions,
        text: this.minuteOptions,
        textAlign: 'left',
        className: 'minute-picker',
        weight: 1
      }
    ];

    if (this.question && this.question.answer) {
      this.hourValue = this.question.answer.split(':')[0] + ' :';
      const hourIndex = this.hourOptions.indexOf(this.hourValue) + this.HOUR_ARRAY_UP; // start at the indicated answer of the center array
      this.hourValue = this.hourValue.replace(' ', '');
      this.timeData[0].currentIndex = hourIndex;
      this.minuteValue = this.question.answer.split(':')[1];
      const minuteIndex = this.minuteOptions.indexOf(this.minuteValue) + this.MINUTE_ARRAY_UP; // start at the indicated answer of the center array
      this.timeData[1].currentIndex = minuteIndex;
      this.answerValue = `${this.hourValue}${this.minuteValue}`;
    } else if (this.question?.defaultAnswer) {
      this.hourValue = this.question.defaultAnswer.split(':')[0] + ' :';
      const hourIndex = this.hourOptions.indexOf(this.hourValue) + this.HOUR_ARRAY_UP; // start at the end of the center array (hour 23:)
      this.hourValue = this.hourValue.replace(' ', '');
      this.timeData[0].currentIndex = hourIndex;
      this.minuteValue = this.question.defaultAnswer.split(':')[1];
      const minuteIndex = this.minuteOptions.indexOf(this.minuteValue) + this.MINUTE_ARRAY_UP; // start at the beginning of the center array (minute 00)
      this.timeData[1].currentIndex = minuteIndex;
      this.answerValue = `${this.hourValue}${this.minuteValue}`;
    } else {
      this.hourValue = '00:';
      this.minuteValue = '00';
      this.answerValue = '00:00';
    }
  }

  createTimeOptions(count: number) {
    const options = [];
    const increment = count === 24 ? 1 : 5;
    for (let i = 0; i < count; i += increment) {
      let option = this.addPrecedingZero(i.toString());
      if (count === 24) {
        option = `${option} :`;
      }
      options.push(option);
    }
    if (count === 24) {
      // have 3 versions of array.
      // If go into the end array, move the beginning array ahead of the current end array (which becomes the middle).
      // If go into beginning array, move end array before the current beginning array (which becomes the middle).
      options.push(...this.wheelSelectorIndexService.getHourValues());
      options.push(...this.wheelSelectorIndexService.getHourValues());
    }
    if (count !== 24) {
      options.push(...this.wheelSelectorIndexService.getMinuteValues());
      options.push(...this.wheelSelectorIndexService.getMinuteValues());
    }
    return options;
  }

  createTwelveHourTimeOptions(count?: number): Array<string> {
    // Do do: if we decide to keep things structured they way they are, this should be made dynamic for 12 or 24hr time by using the count param
    const options: Array<string> = [];
    options.push(...this.wheelSelectorIndexService.getTwelveHourTimeDisplay());
    options.push(...this.wheelSelectorIndexService.getTwelveHourTimeDisplay());
    options.push(...this.wheelSelectorIndexService.getTwelveHourTimeDisplay());
    return options;
  }

  createHourMinOptions(count?: number): Array<string> {
    // Do do: if we decide to keep things structured they way they are, this should be made dynamic for 12 or 24hr time by using the count param
    const options: Array<string> = [];
    options.push(...this.wheelSelectorIndexService.getHourMinDisplay());
    options.push(...this.wheelSelectorIndexService.getHourMinDisplay());
    options.push(...this.wheelSelectorIndexService.getHourMinDisplay());
    return options;
  }

  submit() {
    this.answerUpdated.emit(this.answerValue);
    this.logStateService.setIsModalDisplayed(false);
  }

  closeModal() {
    this.logStateService.setIsModalDisplayed(false);
  }

  timeValueUpdated({ gIndex, iIndex }) {
    if (gIndex === 0) {
      this.hourValue = this.hourOptions[iIndex].replace(' ', '');
    } else {
      this.minuteValue = this.minuteOptions[iIndex];
    }
    this.answerValue = `${this.hourValue}${this.minuteValue}`;
  }

  addPrecedingZero(value): string {
    if (value && value.toString().length === 1) {
      return `0${value}`;
    } else {
      return value;
    }
  }
}
