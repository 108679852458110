/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import './vendor.ts';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

let token = localStorage.getItem('token');
if (!token || token === 'null') {
    token = getCookie('token');
    if (token) {
        localStorage.setItem('token', token);
    } else {
        localStorage.setItem('token', environment.token);
    }
}


const p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); });
// .catch(err => console.error(err));

export function getCookie(name: string) {
	const ca: Array<string> = document.cookie.split(';');
	const caLen: number = ca.length;
	const cookieName = `${name}=`;
	let c: string;

	for (let i = 0; i < caLen; i += 1) {
		c = ca[i].replace(/^\s+/g, '');
		if (c.indexOf(cookieName) === 0) {
			return c.substring(cookieName.length, c.length);
		}
	}
	return '';
}
