<form>
    <h2 mat-diaglog-title class="modal-title">Report A Bug</h2>
    <mat-dialog-content [formGroup]="form">
        <label aria-for="">Describe Your Issue; please do not include any specific personal health-related information:</label>
        <textarea class="form-control" rows="4" type="text" placeholder="I noticed an issue with ..."
            formControlName="bugDescription">
      </textarea>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button type="button" class="btn btn-secondary" mat-dialog-close>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()"
            disabled="{{this.form.get('bugDescription')?.errors}}">
            Submit Report
        </button>
    </mat-dialog-actions>
</form>
