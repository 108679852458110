import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { USER_STATE_SERVICE } from '@noctem/web';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ApplicationStateService, UserStateService } from '@noctem/web';
import { PatientStateService, DashboardStateService, CalendarStateService, ChatStateService } from '@noctem/web';
import { combineLatest, Subject } from 'rxjs';
import { APPLICATION_ORGANIZATION } from 'projects/noctem-lib/src/constants/constants';

@Injectable()
export class ServiceBus {
  private isInitialized = false;
  constructor(
    private router: Router,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    private appStateService: ApplicationStateService,
    private patientState: PatientStateService,
    private dashBoardState: DashboardStateService,
    private calendarState: CalendarStateService,
    private chatState: ChatStateService
  ) { }

  destroy$: Subject<any> = new Subject();

  public initialize() {

    this.userStateService.onUserLoggedOut$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.appStateService.logout();
    });

    this.userStateService.onUserLoaded$.pipe(
      take(1)
    ).subscribe((userState) => {
      const userId = userState.User?.UserId;
      const groups = (this.userStateService.model.get().User as any)?.groups;
      if(groups) {
        this.chatState.initialize(userId, groups);
        this.chatState.setupRecipients();
      }
    });

    const checkLoading = ([patientState, dashboardState, calendarState]) => {
      const isLoading = patientState.IsLoading || dashboardState.IsLoading || calendarState.IsLoading;
      this.appStateService.setLoading(isLoading);
    };

    this.chatState.onGroupUsersLoaded$.pipe(
      take(1)
      ).subscribe(users => {
      this.calendarState.setPatientList(users);
    });

    combineLatest(this.patientState.state$, this.dashBoardState.state$, this.calendarState.state$).subscribe(checkLoading);

    return this.appStateService.initialize();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
