import { ApplicationContext } from '../web-ng'; 
import { ApiHelper } from '../helpers'; 
import { Injectable } from '@angular/core'; 
import { IHttpProvider } from '../contracts'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RTMReportService {
    private apiHelper: ApiHelper;
    private http: IHttpProvider;

    constructor(
        applicationContext: ApplicationContext,
    ) {
        this.http = applicationContext.HttpProvider;
        this.apiHelper = new ApiHelper(applicationContext);
    }
    
    public getRTMReport(clinicId: string, calendarMonthPeriods: number = 1): Observable<any> {
        const params: any = {
            uri: this.apiHelper.RootUrl() + '/timedSession/clinicRTMReport',
            json: null,
            data: { clinicId, calendarMonthPeriods },
            method: 'GET',
            headers: this.apiHelper.Headers()
        };
        return this.http.get(params).pipe(
            map((r: any) => {
                return r;
            })
        );
    }
}
