import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CALC_CONFIG } from './user/contracts';
import { ModuleSentBy, SentModule } from '../services/models';
import moment from 'moment';
import { MODULE_TIMEOUT_IN_DAYS } from '../../../../aire/constant-definitions/constants';
import { quickstartDef } from '../../../../aire/constant-definitions/modules/definitions/quickstart';
import { Observable, Subject } from 'rxjs';
import { APPLICATION_ORGANIZATION } from '../../constants/constants';
import { AireTraining, ApplicationContext, BaseRepository, LocalModuleProgress, ModelFactory, Organization } from '../../core';
import { AssetDefinition, BaseCalculationDefinition } from '../../core/contracts/models.calculations';
import { ApiService } from '../services';
import { CalculationService } from '../services/calculation.service';
import { NetworkService } from '../services/network-service';
import { AssessmentService, SearchService, SentModuleService, UserFuncService } from '../services/services';
import { TranslationService } from '../translations';
import { BaseStateService } from './base-state.service';
import { LocalStorageService } from '../../../../aire/aire-soldier/src/app/services/localStorageService';
import { timeSpanDict } from '../../../../aire/constant-definitions/charts';
import { LogCacheServiceInterface } from '../../../../noctem-lib/src/lib/state/log-cache-service.interface';


export class DashboardState {
  constructor() {}

  public IsLoading = false;
  public chartDataForSleepTrendTabs;
  public chartDataForStressTrendsTabs;
  dashboardObject: any = [];
  resultPager: { PageIndex: number, PageSize: number, RecordIndex: number, TotalRecords: any } = {
    PageIndex: 1,
    PageSize: 20,
    RecordIndex: 0,
    TotalRecords: 0
  };
  filterValue = null;
  nextActions: any = [];
  messages: any = [];
  sorts: any = [];
  isActive: boolean;
  needsToDoDailyCheckIn: boolean;
  needsToDoTeamCheckIn: boolean;
  needsToDoStressCheckIn: boolean;
  needsToDoTrainingFeedback: boolean;
  needsToDoPostTraining: boolean;
  needsToDoPostField: boolean;
  needsToDoPreField: boolean;
  dailyTimeRemaining: number;
  teamCheckInTimeRemaining: number;
  stressCheckInTimeRemaining: number;
  selectedTimeSpan: moment.Moment = timeSpanDict[0].startDate;
  modulesToDo = [];
  trainingList = [];
  performanceRollUpRed = 0;
  performanceRollUpYellow = 0;
  performaceRollUpGreen = 0;
  soldierRollUpAnswers = 0;
  graphRedZone = [0, 0, 0]; /* The bar graph is separated by color. This will be the red "bad" zone.
                            Indexes in order: sleep/fatigue(gad7), stress levels(pcl5), teamwork(sleep-check-in)*/
  graphYellowZone = [0, 0, 0]; // The yellow "ok" zone
  graphGreenZone = [0, 0, 0]; // The green "good" zone
  radarData = []; /* Radar points. In order:
                                  Teamwork, Teamwork Comm, Self-Express, Irritability, Conflict Management, Isolation*/
  patientIds = new Array<string>();

}

@Injectable()
export class DashboardStateService extends BaseStateService<DashboardState> {

  constructor(
    stateFactory: ModelFactory<DashboardState>,
    private apiService: ApiService,
    private searchService: SearchService,
    public translationService: TranslationService,
    protected http: HttpClient,
    public assessmentService: AssessmentService,
    private applicationContext: ApplicationContext,
    private userService: UserFuncService,
    private networkService: NetworkService,
    private calculationService: CalculationService,
    private sentModuleService: SentModuleService,
    protected logCacheService: LogCacheServiceInterface,
    @Inject(CALC_CONFIG) private calcConstants: BaseCalculationDefinition
  ) {
    super(new DashboardState(), stateFactory, translationService);
    this.trainingScheduleRepository = new BaseRepository<AireTraining>(this.applicationContext, 'AireTraining')
  }
  public onLoadingChange$: Subject<boolean> = new Subject();
  public onTimeSpanUpdated$: Subject<any> = new Subject();
  public onDashboardLoaded$: Subject<boolean> = new Subject();
  public onSearchFilter$: Subject<boolean> = new Subject();
  public selectedTimeSpan: moment.Moment;
  public userId;
  protected trainingScheduleRepository: BaseRepository<AireTraining>;

  public async initializeModules(currentUser: string) {
    this.onTimeSpanUpdated$.subscribe(timeSpan => {
      const stateModel = this.stateModel.get();
      stateModel.selectedTimeSpan = timeSpan.startDate;
      this.setState(stateModel);
    });
    this.userId = currentUser;
    return this.getModulesToDo();
  }

  public async initializeAssessments() {
    return this.checkForAssessments();
  }

  private checkInIntervals = new Map([
    ['sleep-check-in', 1],
    ['team-check-in', 3]
  ]);

  // for aire-medic
  public initializeForClinician(currentUser: string): Promise<void> {
    this.userId = currentUser;
    return Promise.resolve();
  }

  async getBaseLineBatteryDone(state: DashboardState) {
    const user = await this.userService.getOneAsync({'Payload.UserId': this.applicationContext.User.UserId});
    state.needsToDoPreField = !(user?.profile?.preFieldSurveyComplete === true);
    state.needsToDoTrainingFeedback = !(user?.profile?.trainingFeedbackComplete === true);
    state.needsToDoPostField = false;
    if(this.networkService.isOnline()) {
      this.trainingScheduleRepository.getOneAsync({}).then(trainingData => {
        if(trainingData &&
            trainingData.endDate &&
            moment(trainingData.endDate).isSameOrBefore(moment())) {
          state.needsToDoPostField = !user?.profile?.postFieldComplete; //hide after completed
        }
      });
    }
  }

  private compareTimeDifference(lastCompletedDate: moment.Moment, treatmentStartDate: moment.Moment, interval: number): number {
    const today = moment().startOf('day');
    const daysSinceTreatmentStart = today.diff(treatmentStartDate.startOf('day'), 'days');
    const daysIntoCycle = daysSinceTreatmentStart % interval;
    const cycleStartDate = today.subtract(daysIntoCycle, 'days'); // The cycle started on this day
    if (lastCompletedDate.isAfter(cycleStartDate)) {
      /* Get time until midnight and add the amount of days remaining in the cycle
      As an example, if they're on day 1 of the cycle, they still have 2 days left of the cycle */
      return Math.ceil(moment().startOf('day').add(interval - (daysIntoCycle), 'days').diff(moment(), 'hours', true));
    } else {
      return -1;
    }
  }

  public async getModulesToDo(): Promise<boolean> {

    const recommendations: Map<string, LocalModuleProgress> = await this.getPreviouslyRecommendedModules();
    const state = this.stateModel.get();
    await this.getNewAIRERecommendedModules(recommendations);
    await this.populateModuleProgress(recommendations);
    state.needsToDoStressCheckIn = recommendations.has('stress-check-in')
                                  && recommendations.get('stress-check-in').percentComplete !== 100;
    //to enable stressCheckin in offline mode
    let previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorage.getItem('moduleProgress')));
    const checkInType = 'stress-check-in';
    if(this.networkService.isOnline()){
      if(state.needsToDoStressCheckIn){
        if(!previousModules.get(checkInType)){
          const last = recommendations.get('stress-check-in');
          let modProgress = new LocalModuleProgress();
          modProgress.dateRecommended = last.dateRecommended;
          modProgress.percentComplete = last.percentComplete;
          modProgress.medicRecommended = last.medicRecommended;
          modProgress.lastPage = last.lastPage;
          previousModules.set(checkInType, modProgress);
          localStorage.setItem(
            'moduleProgress',
            JSON.stringify(Array.from(previousModules.entries()))
          );
        }
      }
    }
    else{
      const stressPro = previousModules.get(checkInType);
      if(stressPro && !state.needsToDoStressCheckIn){ //make sure stress checkin doesn't disappear in offline
        state.needsToDoStressCheckIn = stressPro.percentComplete !== 100;
      }
    }


    recommendations.delete('stress-check-in');
    recommendations.delete('sleep-check-in');
    recommendations.delete('team-check-in');
    state.modulesToDo = [];
    recommendations.forEach((mod, key) => {
      const template = this.calcConstants.ModuleTemplates.find(t => t.name === key);
      // Removed this to fix sorting on Train page - EW
      // if(mod.percentComplete !== 100 && mod.dateCompleted == null) {
      //   template.medicRequested = null;
      // }

      template.percentComplete = mod.percentComplete;
      template.medicRequested = mod.medicRecommended;
      state.modulesToDo.push(template);

    });
    state.modulesToDo.sort((a, b) => {
      let order = 0;
      if(a.medicRequested === true && b.medicRequested !== true){
        order = -1;
      } else if (a.medicRequested === false && b.medicRequested == null) {
        order = -1;
      }
      else if (a.medicRequested === b.medicRequested) {
        order = a.label > b.label ? 1 : -1;
      } else {
        order = 1;
      }
      return order;
    });
    this.stateModel.set(state);
    this.onDashboardLoaded$.next(null);
    return true;
  }

  private async getPreviouslyRecommendedModules(): Promise<Map<string, LocalModuleProgress>> {
    const modules = new Map<string, LocalModuleProgress>();
    const medicPushed = await this.retrieveToDoAssessment();
    medicPushed.forEach((mod, key) => {
      modules.set(key, {
        dateRecommended: moment(mod.dateSent).toDate(),
        id: mod.id,
        name: key,
        dateStarted: null,
        lastPage: 0,
        percentComplete: 0,
        dateCompleted: null,
        medicRecommended: (mod.medicRequested) ? true : false,
        soldierCompletedAssessment: mod['soldierCompletedAssessment']
      });
    });
    return modules;
  }

  /**
   * Checks modules that have been pushed by AIRE and compares them with current recommendations to determine if a new recommendation
   * should be shown
   * @param recommendations
   */
  private async getNewAIRERecommendedModules(recommendations:Map<string, LocalModuleProgress>): Promise<void> {
    const startDate = moment.utc(this.applicationContext.User.treatmentStartDate).startOf('day');
    const endDate = moment();
    const modules: Array<AssetDefinition> = await this.calculationService.getSleepModuleRecommendations(
                                                          moment().startOf('d').utc(),
                                                          endDate,
                                                          [this.applicationContext.User.UserId], false);
    if (!this.applicationContext.User.profile.quickstartComplete && this.applicationContext.User.roles.some(role => role.Name === 'Soldier')) {
      modules.push(quickstartDef);
    }
    modules.forEach(async mod => {
      // module has not been recommended, add it
      const existing = recommendations.get(mod.name);
      if(!existing || moment.utc().diff(existing.dateRecommended, 'd') >= 1) {
        const recommendation = {
          dateRecommended: moment().startOf('d').utc().toDate(),
          dateStarted: null,
          id: mod.id,
          lastPage: 0,
          medicRecommended: false,
          percentComplete: 0,
          name: mod.name,
          dateCompleted: null
        };
        recommendations.set(mod.name, recommendation);
        const sentRecommendation = new SentModule();
        sentRecommendation.id = mod.id;
        sentRecommendation.medicId = this.applicationContext.User.profile?.assignedProviders[0]?.id;
        sentRecommendation.sendDate = moment.utc().toDate();
        sentRecommendation.sentBy = ModuleSentBy.AIRE;
        sentRecommendation.soldierId = this.applicationContext.User.UserId;
        await this.sentModuleService.saveAsync(sentRecommendation);
      }
    });
  }

  private async populateModuleProgress(recommendations: Map<string, LocalModuleProgress>) {

    let progressRecords = new Map<string, LocalModuleProgress>();

    if(this.networkService.isOnline()) {
      // get progress from saved assessments
      const state = this.stateModel.get();
      progressRecords = await this.getProgressFromDB(state.modulesToDo);
      this.stateModel.set(state);
    }
    const localStorageProgress = await LocalStorageService.getLocalModuleProgress();
    this.mergeProgressRecords(progressRecords, localStorageProgress);
    // assume that recommendations have already been vetted to see which is the last, etc
    recommendations.forEach(todo => {
      const latest = progressRecords.get(todo.name);
      if(moment().diff(todo.dateRecommended, 'd') > 3 && todo.dateRecommended == null) { // WP: I think this will always be false no?
        todo.dateCompleted = new Date();
      }

      if(latest && (moment(latest.dateRecommended).isAfter(todo.dateRecommended) || moment(latest.dateStarted).isAfter(todo.dateRecommended))) {

        todo.lastPage = latest.lastPage;
        todo.percentComplete = latest.percentComplete;
        todo.dateStarted = latest.dateRecommended;
        todo.dateCompleted = latest.dateCompleted;
        // Removed this to fix sorting on Train page - EW
        // if(latest.percentComplete === 100) {
        //   todo.medicRecommended = null;
        // }
      }
    });

    LocalStorageService.saveLocalModuleProgress(progressRecords);
  }

  public mergeProgressRecords(dbRecords: Map<string, LocalModuleProgress>,
                              localRecords: Map<string, LocalModuleProgress>): void {
    dbRecords.forEach((rec, key) => {
      const latest = this.getLatestLocalProgress(rec, localRecords);
      dbRecords.set(key, latest);
      localRecords.delete(key);
    });
    localRecords.forEach((rec, key) => {
      dbRecords.set(key, rec);
    });
  }

  public async retrieveToDoAssessment(): Promise<Map<string, AssetDefinition>> {
    let cachedAssessments = null;
    if((this.logCacheService as any).retrieveCachedLogs) {
      cachedAssessments = <unknown> this.logCacheService?.retrieveCachedLogs();
    }

    if (!cachedAssessments) {
      cachedAssessments = {};
    }

    const assessmentEntries = Object.entries(cachedAssessments);
    const mappedAssessments = new Map<string, AssetDefinition>();

    let onlineModuleRecommendations = new Array<SentModule>();
        if(this.applicationContext.User) {
            const medics = this.applicationContext.User.profile.assignedProviders.map(p => p.id);
            onlineModuleRecommendations = await this.sentModuleService.getAllAsync(
                                                            {
                                                              $and: [
                                                                {'Payload.medicId': {$in: medics}},
                                                                {
                                                                  $or:[
                                                                    { 'Payload.soldierId': this.applicationContext.User.UserId },
                                                                    { 'Payload.sentBy': 0 }
                                                                  ]
                                                                }
                                                              ],
                                                            });
            onlineModuleRecommendations = onlineModuleRecommendations.sort((a,b) => {
                return (moment.utc(a.sendDate).isAfter(moment.utc(b.sendDate)) ? -1 : 1);
            });
            onlineModuleRecommendations.forEach(o => {
                const module = {...this.calcConstants.ModuleTemplates.find(t => t.id === o.id)};
                module.medicRequested = o.sentBy === ModuleSentBy.Medic;
                module.dateSent = o.sendDate;
                if(!mappedAssessments.has(module.name)) {
                  mappedAssessments.set(module.name, module);
                }
            });
        }

      assessmentEntries.forEach(entry => {
        if (entry[1]['medicAssigned']) {

          const module = {...this.calcConstants.ModuleTemplates.find(t => t.id === entry[1]['id'])};
          if (module && module["name"]) {
            module.medicRequested = true;
            module.dateSent = entry[1]['logDate'];
            module['soldierCompletedAssessment'] = entry[1]['soldierCompletedAssessment'];
            if(!mappedAssessments.has(module.name)) {
              mappedAssessments.set(module.name, module);
            }
          }
        }
    });
    return mappedAssessments;
}

  private getLatestLocalProgress(module: LocalModuleProgress,
                                 compareRecords: Map<string, LocalModuleProgress>) {
    const compareModule = compareRecords.get(module.name);
    if(!compareModule || moment(module.dateStarted).isAfter(compareModule.dateStarted)) {
      return module;
    } else {
      return compareModule;
    }
  }

  public async getProgressFromDB(modulesToDo): Promise<Map<string, LocalModuleProgress>> {
    const moduleTypes:Array<string> = modulesToDo.map(asset => asset.name);
    const lastDateBeforeExpire = moment.utc().subtract(MODULE_TIMEOUT_IN_DAYS, 'days').startOf('days').toISOString();
    let assessments = await this.assessmentService.getAllAsync({
      $and: [
        {'Payload.phaseName': {$in: moduleTypes } },
        {'Payload.assessmentDate': {$gte: lastDateBeforeExpire}},
        {'Payload.user.id': this.applicationContext.User.UserId}
      ]
    });
    assessments = assessments.sort((a,b) => moment(a.assessmentDate).isBefore(b.assessmentDate) ? 1 : -1);
    const modules = new Map<string, LocalModuleProgress>();
    assessments.forEach(assessment => {
      if(!modules.has(assessment.phaseName)) {
        modules.set(assessment.phaseName, {
          dateStarted: new Date(assessment.assessmentDate),
          id:assessment.moduleId,
          name: assessment.phaseName,
          lastPage: assessment.lastPage,
          medicRecommended: assessment.isProviderRecommended,
          dateRecommended: null,
          percentComplete: assessment.percentageComplete,
          dateCompleted: null
        });
      }
    });
    return modules;
  }


  public getCheckInStatus() {
    return this.stateModel.get().needsToDoDailyCheckIn;
  }

  public getTeamCheckInStatus() {
    return this.stateModel.get().needsToDoTeamCheckIn;
  }

  public getStressCheckInStatus() {
    return this.stateModel.get().needsToDoStressCheckIn;
  }

  public getTrainingFeedback() {
    return this.stateModel.get().needsToDoTrainingFeedback;
  }

  public getPostFieldStatus() {
    return this.stateModel.get().needsToDoPostField;
  }

  public getPreFieldStatus() {
    return this.stateModel.get().needsToDoPreField;
  }

  public getDailyCheckInTimeRemaining() {
    return this.stateModel.get().dailyTimeRemaining;
  }

  public getTeamCheckInTimeRemaining() {
    return this.stateModel.get().teamCheckInTimeRemaining;
  }

  public getStressCheckInTimeRemaining() {
    return this.stateModel.get().stressCheckInTimeRemaining;
  }

  public getNextActions(ownerId) {
    const startDate = new Date();
    const endDate = new Date();
    const state = this.stateModel.get();
    state.nextActions = [];

    // TODO: the next action is upcoming CalendarEvents
    startDate.setDate(startDate.getDate() - 2);
    endDate.setDate(endDate.getDate() + 14);
    this.apiService.getEvent(startDate.getTime(), endDate.getTime(), ownerId).subscribe(res => {
      for (const event of res) {
        const match = new RegExp(/(\[.*\])/).exec(event.title);
        const eventTitle = event.title.replace(match[0], ''); // remove [username] from title
        const eventInstance = {
          user: event.Owner.Name,
          task: eventTitle,
          link: `/patient/${event.Owner.UserId}`
        };
        state.nextActions.push(eventInstance);
      }
      this.stateModel.set(state);
    });
  }

  public async buildDashboard(size?: number, index?: number, sorts?: Array<any>, phrase?: string) {
    const user = this.applicationContext.User;
    const organizations: Organization[] = user.groups.map((g: { id: any; display: any; }) => {
          return {
            _id: (g as any)._id ?? g.id,
            name: g.display,
            roles: []
          };
        });
    const state = this.getState();
    if (size || size === 0) {
      state.resultPager.PageSize = size;
    }
    if (index || index === 0) {
      state.resultPager.RecordIndex = index;
    }
    if (phrase) {
      state.filterValue = phrase;
    } else {
      state.filterValue = null;
    }

    state.messages = [];
    this.setIsLoading(true);
    const filters = [];
    const filter  = {
      property : 'Specs.isActive',
      filterType: 'nin',
      values: [false]
    };
    filters.push(filter);
    if (!organizations || organizations.length === 0) {
      return alert('An error occured while loading data. Please refresh the page, and if the problem persists contact support.');
    } else {
      const groupIds: string[] = [];
      organizations.map(g => {
        if (g._id !== APPLICATION_ORGANIZATION._id) {
          groupIds.push(g._id );
        }
      });
      filters.push({ property: 'OrganizationIds', filterType: 'in', values: groupIds});
    }

    this.searchService.getSleepDailyOutcomeRecords(state.resultPager.RecordIndex, state.resultPager.PageSize,
          sorts, filters, [], state.filterValue).subscribe(results => {
      state.dashboardObject = [];
      state.resultPager = results.Pager;
      for (const result of results.Records) {
        state.dashboardObject.push(result.Attributes);
      }
      this.stateModel.set(state);
      this.onDashboardLoaded$.next(state.dashboardObject);
      this.setIsLoading(false);
    });
  }

  public isDashboardLoading() {
    const state = this.stateModel.get();
    return state.IsLoading;
  }

  public setIsLoading(isLoading: boolean) {
    const state = this.stateModel.get();
    state.IsLoading = isLoading;
    this.stateModel.set(state);
  }

  public filterTable(term) {
    const state = this.getState();
    state.filterValue = term;
    state.resultPager.RecordIndex = 0;
    this.setState(state);
    this.buildDashboard(state.resultPager.TotalRecords.value, state.resultPager.RecordIndex, state.sorts, state.filterValue);
  }

  public toggleActive(active) {
    const state = this.getState();
    if (active != null) {
      state.isActive = active;
      this.setState(state);
      this.buildDashboard(state.resultPager.PageSize, state.resultPager.RecordIndex, state.sorts, state.filterValue);
    }
  }

  public dateAgo(value: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      // tslint:disable-next-line: forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }


  public changePassword(newPassword, currentPassword) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token });
    const options = { headers };
    const payload = {newPassword, confirmNewPassword: newPassword, oldPassword: currentPassword};
    const url = `/api/auth/updatepassword`;
    return this.http.post(url, payload, options);
  }

  private compareTimeDifferenceDaily(lastCompletedDate: moment.Moment, fixedCheckInTime: moment.Moment): number {
    const daysSinceLastCheckIn = fixedCheckInTime.diff(lastCompletedDate, 'days');
    if (daysSinceLastCheckIn < 1) {
      return Math.ceil(fixedCheckInTime.diff(moment(), 'hours', true));
    } else {
      return -1;
    }
  }

  private async getCheckInInfo(checkInType: string, treatmentStartDate: Date, userId: string): Promise<number> {
    let previousCheckIn;
    if(!treatmentStartDate){ //first checkin in offline mode
      treatmentStartDate = moment().startOf("day").toDate();
    }
    if (this.networkService.isOnline()) {
      const assess = await this.assessmentService.getAllAsync(this.getModuleQuery(checkInType, userId));
      const last = assess[assess.length - 1];
      if(last?.percentageComplete !== 100) {return -1;}
      //checkins are not always available in moduleProgress
      let previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorage.getItem('moduleProgress')));
      if(!previousModules.get(checkInType)){
        let modProgress = new LocalModuleProgress();
        modProgress.dateRecommended = last.endTime;
        modProgress.percentComplete = last.percentageComplete;
        modProgress.medicRecommended = false;
        modProgress.lastPage = last.lastPage;
        previousModules.set(checkInType, modProgress);
        localStorage.setItem(
          'moduleProgress',
          JSON.stringify(Array.from(previousModules.entries()))
        );
      }
      return this.getTime(last, checkInType, treatmentStartDate);
    }
    else {
      const previousModules = new Map<string, LocalModuleProgress>(JSON.parse(localStorage.getItem('moduleProgress')));
      previousCheckIn = previousModules.get(checkInType);
      if(previousCheckIn?.percentComplete !== 100) {return -1;}
      return this.getTime(previousCheckIn, checkInType, treatmentStartDate);
    }
  }

  private getTime(previousCheckIn: any, checkInType: string, treatmentStartDate: Date) {
    const interval = this.checkInIntervals.get(checkInType);
    if (previousCheckIn && checkInType === 'sleep-check-in') {
      const time = this.networkService.isOnline() ? moment.utc(previousCheckIn.assessmentDate) : moment.utc(previousCheckIn.dateCompleted?previousCheckIn.dateCompleted:previousCheckIn.dateRecommended);
      return this.compareTimeDifferenceDaily(time, moment('0000', 'HH').add(1, 'days'));;
    } else if (previousCheckIn) {
      const time = this.networkService.isOnline() ? moment.utc(previousCheckIn.assessmentDate) : moment.utc(previousCheckIn.dateCompleted?previousCheckIn.dateCompleted:previousCheckIn.dateRecommended);
      return this.compareTimeDifference(time, moment(treatmentStartDate), interval);
    } else {
      return -1;
    }
  }

  async checkForAssessments() {
    const state = this.stateModel.get();
    const patient = this.applicationContext.User;
    const startDate = patient.profile.trainingFeedbackDateComplete?patient.profile.trainingFeedbackDateComplete:patient.treatmentStartDate;
    await this.updateCheckInInfo(state, startDate, patient.UserId);

    this.stateModel.set(state);
    this.getBaseLineBatteryDone(state);
  }

  public async updateCheckInInfo(state: DashboardState, startDate: Date, userId: string) {
    const promises = [];
    promises.push(this.updateDailyCheckInInfo(state, startDate, userId));
    promises.push(this.updateTeamCheckInInfo(state, startDate, userId));
    return Promise.all(promises);
  }

  private async updateDailyCheckInInfo(state: DashboardState, startDate: Date, userId: string) {
    const hoursTilDue = await this.getCheckInInfo('sleep-check-in', startDate, userId);

    if (hoursTilDue < 0) {
      state.needsToDoDailyCheckIn = true;
      state.dailyTimeRemaining = -1;
    } else {
      state.needsToDoDailyCheckIn = false;
      state.dailyTimeRemaining = hoursTilDue;
    }
    this.setState(state);
  }

  private async updateTeamCheckInInfo(state: DashboardState, startDate: Date, userId: string) {
    const hoursTilDue = await this.getCheckInInfo('team-check-in', startDate, userId);
    if (hoursTilDue < 0) {
      state.needsToDoTeamCheckIn = true;
      state.teamCheckInTimeRemaining = -1;
    } else {
      state.needsToDoTeamCheckIn = false;
      state.teamCheckInTimeRemaining = hoursTilDue;
    }
    this.setState(state);
  }
  private getModuleQuery(checkInType: string, userId: string) {
    return {
      $and:
      [
        { 'Payload.user.id': userId },
        { 'Payload.phaseName': checkInType }
      ]
    };
  }

}
