import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const medicationUse: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'medication-use-what',
    unit: UnitType.Text,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.MedicationUse,
    answerId: 'MEDUSE_ADD_WHAT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'medication-use-add',
    unit: UnitType.YesNo,
    abbreviation: 'Medications Added',
    description: `Medications Added`,
    assessmentType: LogDefinitionTypes.MedicationUse,
    answerId: 'MEDUSE_ADD'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'medication-use-change-what',
    unit: UnitType.Text,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.MedicationUse,
    answerId: 'MEDUSE_CHANGE_WHAT'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'medication-use-change',
    unit: UnitType.YesNo,
    abbreviation: '',
    description: ``,
    assessmentType: LogDefinitionTypes.MedicationUse,
    answerId: 'MEDUSE_CHANGE'
  }),

  //#endregion
];
