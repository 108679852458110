import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const upYourSleepAreaAlgorithm = new SleepModuleDefinition({
  name: 'up-your-sleep-area',
  isMedic: false,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'total-sleep-past-24',
      valueToCompare: 361,
      operator: EqualityType.LessThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleep-latency',
      valueToCompare: 44,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),

    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleep-efficiency',
      valueToCompare: .85,
      operator: EqualityType.LessThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'sleep-quality',
      valueToCompare: 2,
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
  ],
});
