export const osaDetailsFalseDef = {
  "name": "osa-details-false",
  "description": null,
  "instructions": "",
  "questions": [
    {
      "id": 5,
      "questionText": "Do you ever wake up from sleep gasping for air?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_GASPING",
      "conditionalQuestions": []
    },
    {
      "id": 6,
      "questionText": "Has anyone ever told you that you stop breathing when sleeping?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_STOP_BREATHING",
      "conditionalQuestions": []
    },
    {
      "id": 7,
      "questionText": "Have you ever been told you snore loudly?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_SNORE_LOUDLY",
      "conditionalQuestions": []
    },
    {
      "id": 8,
      "questionText": "Do you wake up with a dry mouth?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_DRY_MOUTH",
      "conditionalQuestions": []
    },
    {
      "id": 9,
      "questionText": "Do you wake up with congestion or headaches?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_CONGESTION_HEADACHES",
      "conditionalQuestions": []
    },
    {
      "id": 10,
      "questionText": "Do you have high blood pressure?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_BLOOD_PRESSURE",
      "conditionalQuestions": []
    },
    {
      "id": 11,
      "questionText": "Do you feel tired or sleepy on most days?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes",
          "value": true
        },
        {
          "display": "No",
          "value": false
        }
      ],
      "uniqueAnswerId": "OSA_SLEEPY",
      "conditionalQuestions": []
    },
    {
      "id": 12,
      "questionText": "What is your neck circumference (shirt collar size?)?",
      "questionType": "radio",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Less than 16 inches",
          "value": 0
        },
        {
          "display": "16 inches or greater",
          "value": 1
        }
      ],
      "uniqueAnswerId": "OSA_NECK_SIZE",
      "conditionalQuestions": []
    }
  ]
};
