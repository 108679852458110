<div *ngIf="appState.state$ | async as state">
    <div class="loadingdOVR" *ngIf="state.IsLoading">
        <div class="loadingOVRcontainer">
            <div class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-double-ring">
                    <div></div>
                    <div></div>
                </div>
               <p class="pt-4 text-bold"> Loading Patient Data ... </p>
            </div>
        </div>
    </div>

</div>
<div class="wrapper">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
      <!-- sidebar-->
      <app-sidebar class="aside-container"></app-sidebar>
    <!-- Main section-->
    <section class="section-container">
        <!-- Page content-->

        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>

    </section>
    <!-- Page footer-->
    <footer class="footer-container" app-footer></footer>
</div>