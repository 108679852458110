import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { teamDynamics } from '../questions/team-dynamics/team-dynamics';

export const teamCheckIn: AssetDefinition = {
  id: 15,
  name: 'team-check-in',
  label: 'Team Check-In',
  readOnly: false,
  categories: [],
  showInLibrary: false,
  isCheckIn: true,
  checkInFrequency: 3,
  assessments: [teamDynamics],
  url: 'team-check-in'
};
