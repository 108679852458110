import { LogDefinitionTypes } from "../../../../noctem-lib/src/constants/constants";
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const vactcsFields: Array<FieldDefinitionBase> =
[

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'vactcs',
    unit: UnitType.Number,
    abbreviation: 'Vactcs',
    description: `Vactcs`,
    assessmentType: LogDefinitionTypes.VACTCS,
    answerId: 'TEAM_COMMUNICATION_VISUAL'
  }),
];
