import * as _ from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject, combineLatest } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { UserStateService } from './user-state';
import { filter } from 'rxjs/operators';
import { AuthService } from './user/auth.service';
import {  ModelFactory, USER_STATE_SERVICE } from '../../core';
import { ANON_TOKEN, UserState } from './user';
import { TranslationService } from '../translations';
import { Model, ApplicationContext } from '../../core/web-ng';
import { Router } from '@angular/router';
import { NetworkService } from '../services/network-service';
export class ApplicationStateText {
    Continue: string;
}

export class ApplicationState {
    public Header: String;
    public SubHeader: String;
    public Title: string;
    public Error: string;
    public ShowLogout = false;
    public IsLoading = false;
    public DeviceApplicationVersion = '';
    public IsNativeDevice = true;
    public ShowHeaderBackButton: boolean;
    public BackButtonToHome: boolean;
    public HideHamburgerButton: boolean;
    public BlurBody: boolean;
    public Text: ApplicationStateText;
    public IsInitialized = false;
    Organization: string;
    UserName: string;
    alerts: any = { chat: 0 };
    InitializedOn: Date;
}

@Injectable()
export class ApplicationStateService extends BaseStateService<ApplicationState> {

    public stateModel: Model<ApplicationState>;
    public state$: Observable<ApplicationState>;

    constructor(
        stateFactory: ModelFactory<ApplicationState>,
        private authService: AuthService,
        @Inject(ANON_TOKEN) private anonToken: string,
        private applicationContext: ApplicationContext,
        private translationService: TranslationService,
        @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
        private titleService: Title,
        private router: Router,
        private networkService: NetworkService

    ) {
        super(new ApplicationState(), stateFactory, null);
    }

    initialize() {
        this.setInitialized(false);

        // To facilitate Auth0 login, look for the token cookie first
        const token = this.getCookie('token') || localStorage.getItem('token');
        if (token) {
            localStorage.setItem('token', token);

            // Mark cookie for deletion
            document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        // If a cookie that specifies an alternate logout path (e.g. for Auth0) has been set, save it in the context then mark the cookie for deletion
        let altLogoutPath = this.getCookie('altLogoutPath') || localStorage.getItem('altLogoutPath');
        if (altLogoutPath) {
            this.applicationContext.AltLogoutPath = altLogoutPath;
            localStorage.setItem('altLogoutPath', altLogoutPath);

            // Mark cookie for deletion
            document.cookie = 'altLogoutPath=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        
        return Observable.create(o => {
            this.authService.validate(token).subscribe(isValid => {
                if (!isValid && this.networkService.isOnline()) {
                    localStorage.setItem('token', this.anonToken);
                }
                else{
                    this.setInitialized(true);
                }

                this.applicationContext.Token = token;
                this.userStateService.initialize();

                // Load and set cache values for global usage
                const onUserLoad = this.userStateService.onUserLoaded$;

                combineLatest(
                    onUserLoad.pipe(
                        filter(
                            state => !!state && !state.IsLoading && state.IsUserInitialized
                        )
                    ),
                ).subscribe(responses => {
                    const userState = responses[0] as UserState<any>;
                    const cacheModel = null;
                    // const cacheModel = responses[1];
                    const cultureMap = {
                        'English (US)': 'en-US',
                        'English (UK)': 'en-US',
                        'Mandarin (CH)': 'zh-Hans',
                        'French (FR)': 'fr-FR',
                        'Deutsch (DE)': 'de-DE'
                    };

                    // Not using translations atm
                    localStorage.setItem('translationsCache', JSON.stringify({ data: {}, lastUpdatedOn: 1579363061121, expires: 2579449461121 }));

                    if (userState.IsLoggedIn) {
                        this.setInitialized(true);
                        let currentCulture = (userState as any).User.LanguagePreference; // TODO: add language preference to the model
                        if (cultureMap[currentCulture]) {
                            currentCulture = cultureMap[currentCulture];
                        }
                        if (!currentCulture) {
                            currentCulture = localStorage.getItem('culture') || 'en-US';
                        } else {
                            localStorage.setItem('culture', currentCulture);
                        }
                        this.translationService
                            .initialize(
                                localStorage.getItem('token'),
                                cacheModel,
                                currentCulture
                            )
                            .subscribe(t => {
                                o.next();
                                o.complete();
                            });
                    } else {
                        this.setInitialized(true);

                        const currentCulture = localStorage.getItem('culture') || 'en-US';
                        this.translationService
                            .initialize(
                                localStorage.getItem('token'),
                                cacheModel,
                                currentCulture
                            )
                            .subscribe(t => {
                                o.next();
                                o.complete();
                            });
                    }
                });
            });
        });
    }

    protected setState(state: ApplicationState) {
        state = this.update(state);
        return super.setState(state);
    }

    private update(state): ApplicationState {
        return state;
    }

    public hasToken(): boolean {
      return this.applicationContext.Token != null;
    }

    public hasLocalLogin(): boolean {
        // e.g. this app's /login route + NOCTEM's database was used as a mechanism for login 
        return this.applicationContext.AltLogoutPath == null;
    }

    public hasAltLogin(): boolean {
        // e.g. Auth0 was used as a mechanism for login
        return this.applicationContext.AltLogoutPath != null;
    }

    public addAlert(alertType) {
        const state = this.stateModel.get();
        state.alerts[alertType] = state.alerts[alertType] ? state.alerts[alertType] + 1 : 1;
        this.setState(state);
    }

    public clearChatAlerts(){
        const state = this.stateModel.get();
        state.alerts["chat"] = 0;
    }

    public clearAlert(alertType) {
        const state = this.stateModel.get();
        state.alerts[alertType] = 0;
        this.setState(state);
    }

    public setHeader(header: string, subHeader?: string, backButtonEnabled?: boolean) {
        const state = this.stateModel.get();
        state.Header = header;
        state.SubHeader = subHeader ? subHeader : '';
        this.setState(state);
    }

    public setHeaderBackButton(showBackButton: boolean, straightHome?: boolean) {
        const state = this.stateModel.get();
        state.ShowHeaderBackButton = showBackButton;
        state.BackButtonToHome = straightHome;
        this.setState(state);
    }

    public setTitle(title: string) {
        const currentState = this.stateModel.get();
        currentState.Title = title;
        this.titleService.setTitle(title ? `Swarovski | ${title}` : 'Swarovski');
        this.setState(currentState);
    }

    public setLoading(isLoading: boolean) {
        const state = this.stateModel.get();
        state.IsLoading = isLoading;
        this.setState(state);
    }

    public setInitialized(isInitialized: boolean) {
        let state = this.stateModel.get();

        if (!isInitialized) {
            state.InitializedOn = new Date();
            state.IsInitialized = isInitialized;

            this.setState(state);

        } else {
            // Wait before setting to false
            setTimeout(() => {
                state = this.stateModel.get();
                state.IsInitialized = isInitialized;

                this.setState(state);

            }, 1000);
        }
        // Only set back to false if the time has elapsed to show the loading indicator
    }

    public setOrganization(org: string) {
        const state = this.stateModel.get();
        state.Organization = org;
        this.stateModel.set(state);
    }


    public logout(message?: string) {
      this.userStateService.expire();
      localStorage.clear();
      const state = this.stateModel.get();
      state.UserName = null;
      this.stateModel.set(state);

      if (this.applicationContext?.AltLogoutPath) {
        // TODO: Better to create a provider, component for external urls using Angular's router? Ref: https://adrianfaciu.dev/posts/angular-router-external-links/
        window.location.pathname = this.applicationContext.AltLogoutPath;
      } else {
        this.router.navigateByUrl('/login').then(() => {
            if (message) {
                setTimeout(() => { alert(message); }, 200); 
            }
        });
      }
    }

    private getCookie(name: string): string | null {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
      return
    
    null;
    }
}
