import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const pgi: AssessmentDefinition = {
  name: 'pgi',
  description: 'Patient Global Impression',
  instructions: null,
  questions: [
    {
      id: 0,
      name: 'pgi-rating',
      questionText:
        'Please rate the level of change you have observed in your sleep since you started this program:',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Very much improved', value: 0 },
        { display: 'Much improved', value: 1 },
        { display: 'Minimally improved', value: 2 },
        { display: 'No change', value: 3 },
        { display: 'Minimally worse', value: 4 },
        { display: 'Much worse', value: 5 },
        { display: 'Very much worse', value: 6 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'PGI_RATING'
    }
  ]
};
