import { gad7 } from './gad7';
import { trauma } from './trauma';
import { ess } from './ess';
import { essWeekly } from './essWeekly';
import { isi } from './isi';
import { medicationUse } from './medicationUse';
import { osa } from './osa';
import { pcPtsd } from './pcPtsd';
import { peg } from './peg';
import { pgi } from './pgi';
import { phq8 } from './phq8';
import { promis } from './promis';
import { shiftWork } from './shift-work';
import { sideEffects } from './side-effects';
import { sleepGoalsPre } from './sleep-goals-pre';
import { swps } from './swps';
import { pcl5 } from './pcl5';
import { gad2 } from './gad2';
import { sus } from './sus';
import { sleepGoalsPost } from './sleep-goals-post';
import { sus2 } from './sus2';
import { eveningLog } from './evening-log';
import { morningLog } from './morning-log';
import { CalculatedFieldDefinitions } from './calculated-field-definitions';
import { phq2 } from './phq2';

// Custom; Intermountain
import { phq3 } from './phq3'; 
import { phq9 } from './phq9';

export const AssessmentDefinitions = [
  ...ess,
  ...essWeekly,
  ...gad2,
  ...gad7,
  ...eveningLog,
  ...isi,
  ...medicationUse,
  ...morningLog,
  ...osa,
  ...pcl5,
  ...pcPtsd,
  ...peg,
  ...pgi,
  ...phq2,
  ...phq8,
  ...promis,
  ...shiftWork,
  ...sideEffects,
  ...sleepGoalsPre,
  ...sleepGoalsPost,
  ...sus,
  ...sus2,
  ...swps,
  ...trauma,
  ...CalculatedFieldDefinitions,
  ...phq3, // Custom; Intermountain
  ...phq9, // Custom; Intermountain
];
