export const eveningLogDef = {
  name: 'eveningLog',
  questions: [
    {
      id: 0,
      questionText: 'I started my daily routine at:',
      questionType: 'time',
      questionHeader: true,
      autofill: false,
      answerOptions: [],
      defaultAnswer: '08:00',
      uniqueAnswerId: 'ROUTINE_START',
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'I had caffeinated drinks:',
      questionType: 'radio',
      questionHeader: true,
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      uniqueAnswerId: 'CAFFEINATED_DRINKS',
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [true],
          id: 2,
          questionText: 'Number before lunch time:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'CAFFEINATED_BEFORE_LUNCH',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        },
        {
          displayIfAnswerEquals: [true],
          id: 3,
          questionText: 'Number between lunch and dinner:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'CAFFEINATED_BEFORE_DINNER',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        },
        {
          displayIfAnswerEquals: [true],
          id: 4,
          questionText: 'Number after dinner:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'CAFFEINATED_AFTER_DINNER',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        }
      ]
    },
    {
      id: 5,
      questionText: 'I had alcoholic drinks:',
      questionType: 'radio',
      questionHeader: true,
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      uniqueAnswerId: 'ALCOHOL_DRINKS',
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [true],
          id: 6,
          questionText: 'Number before lunch time:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'ALCOHOL_MORNING',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        },
        {
          displayIfAnswerEquals: [true],
          id: 7,
          questionText: 'Number between lunch and dinner:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'ALCOHOL_AFTERNOON',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        },
        {
          displayIfAnswerEquals: [true],
          id: 8,
          questionText: 'Number after dinner:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'ALCOHOL_EVENING',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        }
      ]
    },
    {
      id: 9,
      questionText: 'I felt nervous and stressed',
      questionType: 'radio',
      questionHeader: true,
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      uniqueAnswerId: 'NERVOUS_STRESSED',
      conditionalQuestions: []
    },
    {
      id: 10,
      questionText: 'I exercised',
      questionType: 'radio',
      questionHeader: true,
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      uniqueAnswerId: 'EXERCIZE',
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [true],
          id: 11,
          questionText: 'Exercise intensity:',
          questionType: 'radio',
          questionHeader: false,
          autofill: false,
          answerOptions: [
            { display: 'Light', value: 'Light' },
            { display: 'Moderate', value: 'Moderate' },
            { display: 'High', value: 'High' }
          ],
          uniqueAnswerId: 'EXERCIZE_INTENSITY',
          conditionalQuestions: []
        },
        {
          displayIfAnswerEquals: [true],
          id: 12,
          questionText: 'Exercise duration:',
          questionType: 'radio',
          questionHeader: false,
          autofill: false,
          answerOptions: [
            { display: 'Less than 30 minutes', value: '<30' },
            { display: '30 minutes or more', value: '>30' }
          ],
          uniqueAnswerId: 'EXERCIZE_DURATIONS',
          conditionalQuestions: []
        }
      ]
    },
    {
      id: 13,
      questionText: 'I took a nap(s)',
      questionType: 'radio',
      questionHeader: true,
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      uniqueAnswerId: 'NAPS',
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [true],
          id: 14,
          questionText: 'Number of naps:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'NAPS_NUMBER',
          conditionalQuestions: [],
          validation: {
            maxLength: 2
          }
        },
        {
          displayIfAnswerEquals: [true],
          id: 15,
          questionText: 'Total nap duration in minutes:',
          questionType: 'number',
          questionHeader: false,
          autofill: false,
          answerOptions: [],
          uniqueAnswerId: 'NAPS_DURATION',
          conditionalQuestions: [],
          validation: {
            maxLength: 3
          }
        }
      ]
    }
  ]
};
