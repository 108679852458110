// TODO: Do we really need all these imports?

import { Injectable, OnInit, OnDestroy, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseRepository, SystemResponse, UserSettings } from '../../core';
// Ref: https://github.com/rodgc/ngx-socket-io#how-to-use
import { SocketService, SearchService } from '../services/services';
import { ClientFuncService, USER_STATE_SERVICE, ApplicationContext } from '../../core/web-ng';
import { TimeTracking } from './models';
import { UserStateService } from '../state/user-state';
import { PatientStateService } from '@noctem/web';
// import { threadId } from 'worker_threads';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService extends ClientFuncService<TimeTracking> implements OnDestroy {
  modelName = 'TimeTracking';

  constructor(
    applicationContext: ApplicationContext,
    @Inject(USER_STATE_SERVICE)
    userStateService: UserStateService,
    private socketService: SocketService,
    public patientStateService: PatientStateService,
  ) {
    super(
      applicationContext,
      new BaseRepository<any>(applicationContext, 'TimeTracking'),
      userStateService
    );
  }

  setIntervals: number[] = [];
  idleIntervalMs = 250;
  idleMaxMs = 30 * 1000;
  idleCountMs = 0;
  isIdle = false;

  id: string = 'default';
  activityContext: any = {};

  abortController: AbortController = new AbortController();

  /* TODO: Create an explicit interface for this data sctructure. Currently it is indended to mimic the following:
  trackedItems['id'] = {
    activityContext: {
      activityType: 'Patient_Page',
      patientId: 'ef7339f9-a839-4285-9b5a-4d06def5058d',
      clinicianId: 'c838c7b0-0d96-488c-a5e3-70b9a480f73a',
      patientIsRegistered: true
    },
    activityObservations: [
      { startTimeMs: 1658102400000, endTimeMs: 1658102700000},
      { startTimeMs: 1658104200000, endTimeMs: 1658104500000}
    ]
  }
  */
  trackedItems: any = {};

  startTimer(id: string, activityContext: any): void {
    // console.debug('Timer Started...')
    if (!this.isIdle) {
      // If this is the id's first trackedItem, initialize the object. TODO: Fail if no (or improper) activityContext
      if (typeof this.trackedItems[id] === 'undefined') {
        this.trackedItems[id] = {
          activityContext,
          activityObservations: [{
              startTimeMs: new Date().getTime(),
          }]
        };
        // console.debug("New item to track: ", id);
      } else {
          // Update activityContext. This is useful especialy for within the Clinician Chat component
          this.trackedItems[id].activityContext = activityContext;
      }

      // Get last activityObservation. If it has a defined endTimeMs, create a new observation.
      let activityObservations = this.trackedItems[id].activityObservations;

      // Reference vs. copy
      let lastActivityObservation = activityObservations[activityObservations.length - 1];
      if (typeof lastActivityObservation.endTimeMs !== 'undefined') {
        activityObservations.push({
          startTimeMs: new Date().getTime()
        });
      }
    }
  }

  stopTimer(id: string): void {
    // Get last activityObservation for trackedItem id. If does not yet have a defined endTimeMs, mark it.
    if (typeof this.trackedItems[id] !== 'undefined') {
      // console.debug("Timer Stopped...", id, this.trackedItems[id].activityContext);
      let activityObservations = this.trackedItems[id].activityObservations;
      let lastActivityObservation = activityObservations[activityObservations.length - 1];
      if (typeof lastActivityObservation.endTimeMs === 'undefined') {
        lastActivityObservation.endTimeMs = new Date().getTime();

        // NOTE: This effectively updates clinicianId. TODO: is this intended? If so, should we remove the parameter from all calls to trackElement?
        let activityContext = this.trackedItems[id].activityContext;
        let token;

        this.userStateService.state$.subscribe(userState => {
            activityContext.clinicianId = userState.User.UserId;
            token = userState.Token;
        });

        // Send data. TODO: Is there a better place to call this?
        this.socketService.sendCTTData({
          activityContext,
          startTimeMs: lastActivityObservation.startTimeMs,
          endTimeMs: lastActivityObservation.endTimeMs,
          token
        });
      }
    }
  }

  stopAllTimers(): void {
    Object.keys(this.trackedItems).forEach((id) => {
      this.stopTimer(id);

      // Debug
      // let totalMs = this.calcDurationMs(id);
      // console.debug(id, totalMs);
    });
  }

  trackTimeOnElement(id: string, activityContext?: any): void {
    // console.debug(`starting time track on ${id}`, activityContext)

    let el
    el = document.getElementById(id);
    if(el == null){
      let els = document.getElementsByClassName(id);
      el = els[0]
    }

    if (el) {
      this.startIdleCounter();

      // Add listeners for events that should start the timer
      el.addEventListener("mouseover", () => {
        this.resetIdleCounter();
        this.startTimer(id, activityContext);
      });
      el.addEventListener("focus", () => {
        this.resetIdleCounter();
        this.startTimer(id, activityContext);
      });
      el.addEventListener("keydown", () => {
        this.resetIdleCounter();
        this.startTimer(id, activityContext);
      });
      el.addEventListener("click", () => {
        this.resetIdleCounter();
        this.startTimer(id, activityContext);
      });
      el.addEventListener("scroll", () => {
        this.resetIdleCounter();
        this.startTimer(id, activityContext);
      });

      // Add listeners to stop the timer
      el.addEventListener("mouseleave", () => {
        this.resetIdleCounter();
        this.stopTimer(id);
      });
      el.addEventListener("blur", () => {
        this.resetIdleCounter();
        this.stopTimer(id);
      });

      // For visibility changes, listeners must be attached to the document. Important: ensure they are properly removed - currently passing once = true.
      // Stop the timer on hidden, start on visible
      document.addEventListener("visibilitychange", () => {
        // Added as Safari does not seem to be removing the listener on abort. TODO: debug further ...
        if (this.abortController.signal.aborted === false ) {
          if (document.visibilityState === 'hidden') {
            // console.debug('Document visibility changed to hidden; stopping timer:', id);
            this.resetIdleCounter();
            this.stopTimer(id);
          } else {
            // console.debug('Document visibility changed to visible; starting timer:', id);
            this.resetIdleCounter();
            this.startTimer(id, activityContext);
          }
        }
      }, { passive: true, once: false, signal: this.abortController.signal } as AddEventListenerOptions);

    }
  }

  calcDurationMs(id: string): number {
    // TODO: better (or more comprehensive) assertion of object existance and structure.
    if (typeof this.trackedItems[id] !== 'object') {
      return NaN;
    } else {
      let totalMs = this.trackedItems[id].activityObservations
      .map((i: any) => {
        let startTimeMs = i.startTimeMs;
        let endTimeMs = (typeof i.endTimeMs !== 'undefined') ? i.endTimeMs : new Date().getTime();
        let duration = Number(endTimeMs - startTimeMs);
        if (duration > 0) {
          return duration
        } else {
          return 0;
        }
      })
      .reduce ((pv: number, cv: number) => {
        return pv + cv
      }, 0);

      return totalMs;
    }
  }

  startIdleCounter(): void {
    this.setIntervals.push(
      window.setInterval(() => {
        this.idleCountMs += this.idleIntervalMs
        if (!this.isIdle && (this.idleCountMs > this.idleMaxMs)) {
          // console.debug("Idle timeout; stopping all timers ...");
          this.stopAllTimers();
          this.isIdle = true;
        }
      }, this.idleIntervalMs)
    );
  }

  resetIdleCounter(): void {
    this.idleCountMs = 0;
    this.isIdle = false;
  }

  ngOnDestroy(): void {
    // console.debug('CTTService Destroy!');
    this.stopAllTimers();

    // this.abortController.signal.addEventListener('abort', () => console.debug("Abort signal called."))
    this.abortController.abort();

    // Clear intervals
    this.setIntervals.forEach((i) => {
      clearInterval(i);
    });
  }
}
