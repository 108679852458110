<div class="intervention-report-dialog">
  <div mat-dialog-content>
    <div class="modal-header container">
      <div><h3>Intervention Report Preview</h3></div><br />
    </div>
  
    <div class="modal-body intervention-report-dialog" id="document">
  
      <div class="container">
        <div class="loading" *ngIf="isLoading">
          <div class="lds-css ng-scope">
            <div style="width:100%;height:100%" class="lds-double-ring">
              <div></div>
              <div></div>
            </div>
            <p class="pt-4 text-bold">Generating Report... </p>
          </div>
        </div>
        <div *ngIf="!isLoading">
          <div class="container subpage" *ngFor="let page of reportDef?.pages | reportPageFilter: pageOptions; let i = index;">
            <div class="report-header mb-3" *ngIf="i === 0">
              <div class="row">
                <div class="col-1"><img src='../../../assets/img/icons/coast-icon.png' width='30px' /></div>
                <div class="col-7"><h3>{{reportDef?.title}}</h3></div>
                <div class="col-4 text-right"> <span class="font-weight-bold">Created:</span> {{dateString}}</div>
              </div>
            </div>
  
            <div class="report-content" [innerHTML]='page.content'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="px-5 justify-content-between" mat-dialog-actions>
      <div class="col-7">
        <p class="mr-3 mb-0"><b>Include the following components:</b></p>
          <div class='form-check-inline' *ngFor='let pageOption of pageOptions; let i = index;'>
            <input type='checkbox' class='form-check-input' id="cb{{i}}" [checked]="pageOption.selected"  (change)="pageOption.selected = !pageOption.selected"/>
            <label for="cb{{i}}" class="form-check-label">{{pageOption?.name}}</label>
          </div>
      </div>
        <div class="col-5 row justify-content-end">
          <button mat-button class="btn btn-secondary" (click)="onNoClick()">Close</button>
          <button mat-button class="btn btn-primary" (click)="print()">Sign &amp; Download Report</button>
        </div>
    </div>
  </div>

</div>
