import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const gad2: AssessmentDefinition = {
  name: 'gad2',
  description: 'Generalized Anxiety Disorder',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'IN THE PAST WEEK, how often have you been bothered by:',
      questionEmphasis: 'Feeling nervous, anxious, or on edge',
      questionType: 'radio',
      uniqueAnswerId: 'GAD2_NERVOUS',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    },
    {
      id: 1,
      questionText: 'IN THE PAST WEEK, how often have you been bothered by:',
      questionEmphasis: 'Not being able to stop or control worrying',
      questionType: 'radio',
      uniqueAnswerId: 'GAD2_CONTROL',
      autofill: false,
      answerOptions: [
        { display: 'Not at all', value: 0 },
        { display: 'Several Days', value: 1 },
        { display: 'More than half the days', value: 2 },
        { display: 'Nearly every day', value: 3 },
        { display: 'Prefer not to answer', value: 'N/A' }
      ],
      conditionalQuestions: []
    }
  ]
};
