import { IHttpProvider } from '../contracts';
import { intersectionWith as _intersectionWith } from 'lodash';

const TYPES = {
    ApplicationContext: Symbol('ApplicationContext')
};

export default TYPES;

export class HttpParams {
    public uri: string;
    public headers: Array<any>;
    public method: string;
    public data: any;
    public json: any;

    public qs?: any;
    public sorts?: any;
}

export interface IApplicationContext {
    Token: string;
    Host: string;
    Organization: string;
    User: any;
    HttpProvider: IHttpProvider;
    AppHost?: string;
}


export class Role {

  public static Administrator: Role = {
    name: 'Administrator',
    label: 'Administrator',
    _id: '65aef88f-ecff-4468-b6b4-7c6ee14a8308'
  };

  public static DefaultUser: Role = {
    name: 'DefaultUser',
    label: 'Default User',
    _id: '4366ca46-e15c-41b1-86f7-69ef06c57a10'
  };
  public static Customer: Role = {
    name: 'Customer',
    label: 'Customer',
    _id: '4375e968-f214-4ddf-8a79-ea9fa6673f33'
  };

  public name: string;
  public label: string;
  public _id: string;

  public static Contains(roles: Role[], ...containsRoles: Role[]) {
    const matches = _intersectionWith(roles, containsRoles, (arrVal, othVal) => arrVal.name === othVal.name);

    return matches.length > 0;
  }
}



export class SystemContext {
    user: any;
    organization: string;
    identity: SystemContextIdentity;
    applicationSettings: any;

    constructor() {
        this.user = null;
        this.organization = null;
        this.identity = new SystemContextIdentity();
        this.applicationSettings = null;
    }
}

export class SystemContextIdentity {
    user: any;
    settings: any;

    constructor() {
        this.user = null;
        this.settings = null;
    }
}

export class SystemRequest<T> {
    context: SystemContext;
    params: T;

    constructor() {
        this.context = new SystemContext();
        this.params = null;
    }
}

export class SystemResponse<T> {
    forEach(arg0: (element: any) => void) {
      throw new Error('Method not implemented.');
    }
    success: boolean;
    data: T;
    messages: string[];
    code: string;
    errors: string[];
    warnings: string[];

    constructor(success?: boolean, data?: T, messages?: string[]) {
        this.success = success || false;
        this.data = data || null;
        this.messages = messages || [];
        this.code = null;
        this.errors = [];
        this.warnings = [];
    }


}

export class BaseObject {
    public __i: Meta;

    constructor() {
        this.__i = new Meta();
    }
}

export class Meta {
    public guid: string;
    public model: SystemModel;
    public tags: Array<Tag>;
    public auditing: Audit;

    constructor() {
        this.guid = null;
        this.model = null;
        this.tags = [];
        this.auditing = null;
    }
}

export class SystemModel {
    public id: string;
    public name: string;
    public version: string;

    constructor(name: string) {
        this.id = null;
        this.name = name;
        this.version = null;
    }
}

export class TagPath {
    public ancestorSymbol: string;
    public parentSymbol: string;
}

export class Tag extends BaseObject {
    public Name: string;
    public Description: string;
    public Symbol: string;
    public Paths: Array<TagPath>;

    constructor(name?: string, symbol?: string, paths?: Array<TagPath>) {
        super();

        this.Name = name ? name : null;
        this.Symbol = symbol ? symbol : null;
        this.Paths = paths ? paths : [];
        this.Description = null;
    }
}

export class Audit {
    public createdBy: string;
    public createdOn: Date;
    public lastUpdatedBy: string;
    public lastUpdatedOn: Date;

    constructor() {
        this.createdBy = null;
        this.createdOn = null;
        this.lastUpdatedBy = null;
        this.lastUpdatedOn = null;
    }
}

export class ValidationResponse {
    public IsValid: boolean;
    public Messages: Array<ValidationMessage>;

    constructor() {
        this.IsValid = false;
        this.Messages = [];
    }
}

export class ValidationMessage {
    public Target: string;
    public Description: string;
    public Message: string;

    constructor() {
        this.Target = null;
        this.Description = null;
        this.Message = null;
    }
}

export class AireTraining extends BaseObject{
  public endDate:Date;
}

export class InstanceAccess {
    public List: InstanceAccessList;
    public Cascade: boolean;

    constructor() {
        this.Cascade = false;
        this.List = new InstanceAccessList();
        this.List.All = false;
        this.List.Owners = new Array<InstanceAccessItem>();
        this.List.Editors = new Array<InstanceAccessItem>();
        this.List.Viewers = new Array<InstanceAccessItem>();
    }
}

export class InstanceAccessList {
    public All: boolean;
    public Owners: Array<InstanceAccessItem>;
    public Editors: Array<InstanceAccessItem>;
    public Viewers: Array<InstanceAccessItem>;
    /*"All": false,
     "Owners": [{ "Id": "65aef88f-ecff-4468-b6b4-7c6ee14a8308", "Label": "Administrator", "IsRole": true }],
     "Editors": [],
     "Viewers": []*/
}

export class InstanceAccessItem {
    public Id: string;
    public Label: string;
    public IsRole: boolean;

    constructor(Id: string, Label: string, IsRole: boolean) {
        this.Id = Id;
        this.Label = Label;
        this.IsRole = IsRole;
    }
}


export class FileInfo {
    public Id: string;
    public Size: number;
    public Name: string;

    constructor() {
        this.Id = null;
        this.Size = null;
        this.Name = null;
    }
}

export class Amount {
    public Value: number;
    public Currency: Currency;

    constructor() {
        this.Value = null;
        this.Currency = new Currency();
    }
}

export class Currency {
    public Value: number;

    constructor() {
        this.Value = null;
    }
}

export class ActivityLog extends BaseObject {
    User: UserSettings;
    Model: string;
    FunctionName: string;
    Request: string;
    Response: string;
    StartedOn: string;
    EndedOn: string;
    ResponseTimeSeconds: number;

    constructor() {
        super();
    }
}

export class UserSettings extends BaseObject {
    public UserId: string;
    public Profile: any;

    constructor() {
        super();
    }
}

export class TimedSession extends BaseObject{
    socketId: string;
    activityContext: {
      activityType: string,
      subEvent: string,
      clinicianId: string,
      patientId: string,
      patientIsRegistered: boolean
    };
    startTimeMs: number;
    endTimeMs: number;
    totalSeconds: number;
  }

export class Name {
    First: string;
    Last: string;
}


export class AddressState {
    Name: string;
    Abbreviation: string;

    constructor() {
    }
}


export class Device {
    public Id: string;
    public Type: string; // change to enum
    public AppVersion: string;
    public Version: string;
}


export enum MessageType {
    Email,
    iOs,
    Android,
    Sms
}

export class Message extends BaseObject {
    Id: string;
    Recipients: Array<Recipient>;
    DeliversOn: string;
    Subject: string;
    Content: string;
    From: string;
    Origin: Origin;
    Attachments: Array<any>;
    DataContext: any;

    constructor(from?: string, subject?: string, content?: string, recipients?: Recipient[]) {
        super();
        this.Id = null;
        this.Recipients = recipients || [];
        this.DeliversOn = null;
        this.Subject = subject || null;
        this.Content = content || null;
        this.From = from || null;
        this.Origin = null;
        this.Attachments = [];
        this.DataContext = null;
    }
}

export class Recipient {
    Id: string;
    Address: string;
    Type: MessageType;
    AcknowledgedOn: Date;

    constructor(id?: string, address?: string, type?: MessageType) {
        this.Id = id || null;
        this.Address = address || null;
        this.Type = type || MessageType.Email;
        this.AcknowledgedOn = null;
    }
}

export class Origin {
    Application: string;
    Organization: string;
}

export class MessageResponse {
    Id: string;
    Message: string;
    Succeeded: boolean;
}


export class Organization {
  _id: string;
  roles: Role[];
  name: string;

}

export class UserAgreement {
  constructor(data: Partial<UserAgreement>){
    Object.assign(this, data);
  }
  id:number;
  name:string;
  pages:Array<any>;
  title?:string;
  questionHeader?:string;
  questionSubtext?: string;
  textReplacementMethod?:string;
  agreementQuestions?:Array<any>;
  imgName?:string;
}



export class LocalModuleProgress{
  dateRecommended: Date;
  dateStarted: Date;
  lastPage = 0;
  percentComplete = 0;
  medicRecommended = false;
  dateCompleted: Date;
  id: number;
  name?: string;
  soldierCompletedAssessment?: boolean;
}

