<fieldset class="form-group question">
    <div class="container">
        <div class="row header">
            <div class="col align-items-center"><b><u>{{question.conditionalQuestionOptions.columnHeaders[0]}}</u></b>
            </div>
            <div class="col align-items-center"><b><u>{{question.conditionalQuestionOptions.columnHeaders[1]}}</u></b>
            </div>
        </div>
        <div class="row p-1" *ngFor="let question of question.conditionalQuestions;let i = index;">
            <div class="col justify-content-center align-self-center"><b>{{question.questionText}}</b></div>
            <div class="col align-items-center">
                <div>
                    <input *ngIf="question.questionType==='number'" type="number" pattern="[0-9]*"
                        (keypress)="valueValidated($event, 3)" (keyup)="valueUpdated($event, question)"
                        class="form-control" [(ngModel)]="answerValues[i]">
                </div>
            </div>
        </div>
    </div>
</fieldset>