export const swps4Def = {
  name: 'swps4',
  description: null,
  instructions: '',
  questions: [
    {
      uniqueAnswerId: 'SWPS_MISS_MORNING_EVENTS',
      id: 0,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I am often late or miss morning appointments or events',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_WAKING_UP_RESTED',
      id: 1,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis:
        'I have a difficult time waking up and feeling alert and rested when I wake up',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_WAKE_UP_ASSISTANCE',
      id: 2,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis:
        'I have a difficult time waking up without several alarms and/or someone’s help',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_STAYING_AWAKE',
      id: 3,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I have difficulty staying awake in the evening',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_WORK_TROUBLES',
      id: 4,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I have had troubles at work',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_FAMILY_TENSION',
      id: 5,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I have experienced tension with my family',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_CANCELLED_SOCIAL_EVENTS',
      id: 6,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I have missed or cancelled social events',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    },
    {
      uniqueAnswerId: 'SWPS_MISSED_SOCIAL_EVENTS',
      id: 7,
      questionText: 'Because of my preferred bedtime and rise time,',
      questionEmphasis: 'I miss out of social events, gatherings, and activities',
      questionType: 'radio',
      autofill: 'false',
      answerOptions: [
        {
          display: 'Yes',
          value: true
        },
        {
          display: 'No',
          value: false
        }
      ],
      conditionalQuestions: []
    }
  ]
};
