import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-manual',
  template: ` <div class="pdf-main">
    <pdf-viewer [src]="pdfUrl"></pdf-viewer>
  </div>`,
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent implements OnInit {
  constructor() {}
  pdfUrl = '../../../../assets/docs/UserManual.pdf';

  ngOnInit() {}
}
