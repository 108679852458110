import { AssessmentDefinition } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const sus: AssessmentDefinition = {
  name: 'sus',
  description: 'SSystem Usability Scale',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'I think I would like to use this system frequently',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 0 },
        { display: 'Somewhat Disagree', value: 1 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 3 },
        { display: 'Strongly Agree', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_FREQUENCY'
    },
    {
      id: 1,
      questionText: 'I found the system unnecessarily complex',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 4 },
        { display: 'Somewhat Disagree', value: 3 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 1 },
        { display: 'Strongly Agree', value: 0 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_COMPLEX'
    },
    {
      id: 2,
      questionText: 'I thought the system was easy to use',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 0 },
        { display: 'Somewhat Disagree', value: 1 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 3 },
        { display: 'Strongly Agree', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_EASY'
    },
    {
      id: 3,
      questionText:
        'I think that I would need the support of a technical person to be able to use this system',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 4 },
        { display: 'Somewhat Disagree', value: 3 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 1 },
        { display: 'Strongly Agree', value: 0 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_TECHNICAL'
    },
    {
      id: 4,
      questionText: 'I found the various functions in this system were well integrated',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 0 },
        { display: 'Somewhat Disagree', value: 1 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 3 },
        { display: 'Strongly Agree', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_INTEGRATION'
    },
    {
      id: 5,
      questionText: 'I thought there was too much inconsistency in this system',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 4 },
        { display: 'Somewhat Disagree', value: 3 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 1 },
        { display: 'Strongly Agree', value: 0 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_INCONSISTENT'
    },
    {
      id: 6,
      questionText: 'I would imagine that most people would learn to use this system very quickly',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 0 },
        { display: 'Somewhat Disagree', value: 1 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 3 },
        { display: 'Strongly Agree', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_LEARN_QUICKLY'
    },
    {
      id: 7,
      questionText: 'I found the system very cumbersome to use',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 4 },
        { display: 'Somewhat Disagree', value: 3 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 1 },
        { display: 'Strongly Agree', value: 0 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_CUMBERSOME'
    },
    {
      id: 8,
      questionText: 'I felt very confident using the system',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 0 },
        { display: 'Somewhat Disagree', value: 1 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 3 },
        { display: 'Strongly Agree', value: 4 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_CONFIDENT'
    },
    {
      id: 9,
      questionText: 'I needed to learn a lot of things before I could get going with this system',
      questionType: 'radio',
      autofill: false,
      answerOptions: [
        { display: 'Strongly Disagree', value: 4 },
        { display: 'Somewhat Disagree', value: 3 },
        { display: 'Neutral', value: 2 },
        { display: 'Somewhat', value: 1 },
        { display: 'Strongly Agree', value: 0 }
      ],
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_LEARN'
    },
    {
      id: 10,
      questionText: 'Please provide any comments about the NOCTEM system:',
      questionType: 'text',
      autofill: false,
      answerOptions: [],
      showNotApplicableHint: true,
      conditionalQuestions: [],
      uniqueAnswerId: 'SUS_COMMENTS'
    }
  ]
};
