import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LogStateService, DashboardStateService } from '@noctem/web';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import * as _ from 'lodash';

@Component({
  selector: 'accordion-container',
  templateUrl: './accordion-container.component.html',
})
export class AccordionContainerComponent implements OnInit {

    @Input() data: ChartDataSets[];
    @Input() chartType: ChartType;
    @Input() chartTitle: string;
    @Input() timeSpan: string;
    @Input() legend: boolean;
    @Input() xAxisLabels: Label[];
    @Input() options: (ChartOptions & { annotation: any });
  constructor() {}
  ngOnInit() {}
}
