import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardStateService, LogStateService } from '../../../lib/state';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import * as _ from 'lodash';

@Component({
  selector: 'charts-component',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

    @Input() adjustChartSize: boolean;
    @Input() height: number;
    @Input() chartId: string;
    @Input() data: ChartDataSets[];
    @Input() chartColors: Color[];
    @Input() plugins: Array<any>;
    @Input() chartType: ChartType;
    @Input() legend: boolean;
    @Input() xAxisLabels: Label[];
    @Input() options: (ChartOptions & { annotation?: any });
    @Input() inAccordion: boolean;  // Slightly adjust the width of the chart if it's in an accordion

    public colors: Color[] = [  // TODO: depending on number of lines to display, will need to adjust this number; ng2-charts have colors by default
      {
        borderColor: 'white',  // Line color
        //backgroundColor: 'green',  // Under line color
      },
      {
        borderColor: 'white',
      },
    ];

    public cType:ChartType;

  constructor(private router: Router,
    public logStateService: LogStateService,
    public dashboardStateService: DashboardStateService) { }


  ngOnInit() {
  }

  getChartHeight() {
    if(this.height) { return this.height; }
    const mediaSize = window.matchMedia('(min-width: 768px)').matches;
    if (mediaSize) {  // For tablets
      if (this.chartType === 'pie') {
        return 200;  // Pie chart is a bit smaller than bar charts. Size needs to be bigger
      } else {
        return 175;
      }
    } else {
      if (this.adjustChartSize) {
        return 250;  // This height will be for teamwork horizontal bar charts. TODO: may need to expand on this for future charts
      }
      return 150;
    }
  }

  goHome() {
    this.router.navigateByUrl('/');
  }
}
