import { Injectable } from '@angular/core';
import { flatMap as _flatMap } from 'lodash';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import {
  find as _find,
  filter as _filter,
  sortBy as _sortBy,
  last as _last,
  reverse as _reverse,
  orderBy as _orderBy
} from 'lodash';
import { SocketService, SearchService } from '../services/services';
import { MessageThreadStateService } from './message-thread-state.service';
import { MessageThreadService } from '../services/MessageThreadService';
import { MessageThreadState, ChatMessage } from './stateModels';
import { NoctemUser, MessageThread } from '../services/models';
import { TranslationService } from '../translations';
import { ModelFactory, UserSettings } from '../../core';
import { NetworkService } from '../services/network-service';

export class ChatState extends MessageThreadState {
  public isLoading = false;
  public patientInfo: any;

  messages: ChatMessage[] = [];
  recipient: any;
  recipients: any = [];
  groupUsers: any = [];
  messageBody;
  newMessageBody;
  alertsCount: number;
  unreadMessages: MessageThread[] = [];
  lastMessageReceived: null;
}

@Injectable({
  providedIn: 'root'
})
export class ChatStateService extends MessageThreadStateService<ChatState> {
  public onGroupUsersLoaded$: Subject<NoctemUser[]> = new Subject();

  constructor(
    stateFactory: ModelFactory<ChatState>,
    private router: Router,
    public translationService: TranslationService,
    searchService: SearchService,
    socketService: SocketService, // TODO: Should this be in ServiceBus?
    messageThreadService: MessageThreadService,
    networkService: NetworkService
  ) {
    super(stateFactory, searchService, socketService, messageThreadService, networkService);
  }

  onRecordsSet() {
    const state = this.stateModel.get();
    this.loadAndTransformMessages();
    this.messageThreadService.allUnread().subscribe(res => {
      this.setCurrentRecipients(state, res.data);
    });
    this.setIsLoading(false);
  }

  // Fetches all records to determine all recipients
  public setupRecipients() {
    const state = this.stateModel.get();
    state.messages = [];
    state.recipients = [];
    state.recipientId = null;

    this.setIsLoading(true);
    this.messageThreadService.init().subscribe(res => {
      if (res.success) {
        state.groupUsers = res.data.groupUsers.sort(this.sortRecipients);
        state.recipientIds = res.data.allRecipientIds;
        state.rawMessages = [];
        this.onGroupUsersLoaded$.next(state.groupUsers);
        this.stateModel.set(state);
        this.messageThreadService.allUnread().subscribe(res => {
          this.setCurrentRecipients(state, res.data);
        });
      }
      this.setIsLoading(false);
    });
  }

  private setCurrentRecipients(state?: any, unreadMessages?: any[]) {
    if (!state) {
      state = this.getState();
    }
    if (state.groupUsers) {
      for (const recipient of state.groupUsers) {
        const alreadyExists = state.recipients.find(u => u.UserId === recipient.UserId);
        const userMessages = unreadMessages
          ? unreadMessages.filter(message => message.from.id === recipient.UserId)
          : [];
        const allMessages = state.Records?.filter(
          record => record.Attributes.from.id === recipient.UserId
        );
        if (recipient && !alreadyExists) {
          recipient.unreadMessages = userMessages;
          recipient.allMessages = allMessages;
          recipient.alert = userMessages.length;
          state.recipients.push(recipient);
        }
      }
    }

    state.unreadMessages = unreadMessages;
    state.messageBody = '';
    this.stateModel.set(state);
  }

  public setIsLoading(isLoading: boolean) {
    const state = this.stateModel.get();
    state.isLoading = isLoading;
    this.stateModel.set(state);
  }

  public goHome() {
    this.router.navigateByUrl('/');
  }

  public setRecipient(recipientId: string) {
    const state = this.stateModel.get();
    // If the current user is already selected, disregard
    if (recipientId === state.recipientId) {
      return;
    }
    state.recipientId = recipientId;
    state.messages = [];
    state.alertsCount = null;
    state.recipient = _find(state.groupUsers, thisUser => thisUser.UserId === recipientId);
    state.rawMessages = state.rawMessages.filter(message => recipientId != message.from.id);

    if (state.recipient) {
      state.recipient.alert = null;
      if (state.unreadMessages) {
        state.unreadMessages = state.unreadMessages.filter(
          message => recipientId != message.from.id
        );
      }
      state.recipient.unreadMessages = [];
      let unreadMessageCount = state.recipient.unreadMessages
        ? state.recipient.unreadMessages.length
        : 0;
      let rawMessageCount = state.recipient.rawMessages ? state.recipient.rawMessages.length : 0;
      state.recipient.alert = state.recipient.alert = rawMessageCount + unreadMessageCount;
    }

    const existing = state.recipients.find(r => r.UserId === recipientId);
    if (!existing && state.recipient) {
      state.recipients.push(state.recipient);
    } else if (existing) {
      existing.rawMessages = [];
      let unreadMessageCount = existing.unreadMessages ? existing.unreadMessages.length : 0;
      existing.alert = existing.alert = existing.rawMessages.length + unreadMessageCount;
    }

    // Updates the recipient's messages as read and updates the ES index
    this.messageThreadService.markRead(recipientId).subscribe();

    this.stateModel.set(state);
    this.setIsLoading(true);
    this.load(null);
  }

  setCurrentUserMessagesRead() {
    let state = this.stateModel.get();
    this.messageThreadService.markToRecipientRead(state.currentUserId).subscribe();
  }

  allUnread() {
    return this.messageThreadService.allUnread();
  }

  clearRecipient() {
    const state = this.stateModel.get();
    state.recipient = null;
    state.recipientId = null;
    state.messages = [];

    this.stateModel.set(state);
  }

  private sortRecipients(a: UserSettings, b: UserSettings): number {
    if (!a['applicationData']?.lastMessageDate) {
      return 1;
    } else if (!b['applicationData']?.lastMessageDate) {
      return -1;
    } else {
      return new Date(a['applicationData']?.lastMessageDate) <
        new Date(b['applicationData']?.lastMessageDate)
        ? 1
        : -1;
    }
  }

  public setMessageBody(target, newMessage) {
    const state = this.stateModel.get();
    if (!newMessage) {
      state.messageBody = target.value;
    } else {
      state.newMessageBody = target.value;
    }
    this.stateModel.set(state);
  }

  public sendMessage(message?: string) {
    const state = this.stateModel.get();

    if (!message && !state.messageBody) {
      return;
    }

    if (message) {
      state.messageBody = message;
    }

    this.send(state.messageBody, state.recipientId);

    let newMessage = {
      sentOn: new Date(),
      senderDisplay: 'You',
      content: state.messageBody,
      fromRecipient: false
    };
    state.messages.push(newMessage);

    if (state.recipient) {
      if (!state.recipient.applicationData) {
        state.recipient.applicationData = {};
      }
      state.recipient['applicationData'].lastMessageDate = new Date();
      state.recipients.sort(this.sortRecipients);
    }
    state.messageBody = null;
    this.setChatState(state);
    this.updateRecipientWithNewMessage(state.recipientId, newMessage);
  }

  updateRecipientWithNewMessage(recipientId: string, newMessage: any) {
    const state = this.stateModel.get();
    const recipient = state.recipients?.find(recipient => recipient.UserId === recipientId);
    if (recipient) {
      recipient.lastSentMessage = newMessage;
    }
    this.setChatState(state);
  }

  updateMessagesOnReceive(rawMessage: MessageThread) {
    const state = this.stateModel.get();

    // Recipients may not be loaded yet.  When they are not, we don't have the alerts
    // associated to anyone - so when you go the chat page, you don't know who the new messages
    // are from
    state.alertsCount = state.alertsCount ? state.alertsCount + 1 : 1;

    // If there's no recipient id, this is a patient sending to a group
    if (!state.isPatient && state.recipientId !== rawMessage.from.id && state.recipients) {
      // However, add an alert
      const user = state.recipients.find(r => r.UserId === rawMessage.from.id);
      if (user) {
        user.rawMessages = user.rawMessages || [];

        // Check that message wasn't already added.
        let existingMessage = null;
        if (state.unreadMessages) {
          existingMessage = state.unreadMessages.find(message => message.Id === rawMessage.Id);
        }
        if (!existingMessage) {
          user.rawMessages.push(rawMessage);
        }

        const unreadMessageCount = user.unreadMessages ? user.unreadMessages.length : 0;
        user.alert = user.rawMessages.length + unreadMessageCount;

        if (user) {
          if (!user.applicationData) {
            user.applicationData = {};
          }
          user.applicationData.lastMessageDate = new Date();
          state.recipients.sort(this.sortRecipients);
        }

        this.setChatState(state);
      }
    }

    if (!state.recipientId || rawMessage.from.id === state.recipientId) {
      const text = rawMessage.body;

      this.setChatState(state);

      if (text) {
        const newRecord = this.transformMessage(rawMessage, true);

        state.messages = this.refineMessages(state.messages.concat([newRecord]));
        this.setChatState(state);
      }

      // If this is the current recipient, and the message has not been marked read - set it
      if (state.recipientId && !rawMessage.readDateTime) {
        this.messageThreadService.markRead(state.recipientId).subscribe();
      }
    }
  }

  private setChatState(state: ChatState) {
    super.setState(state);
  }

  private loadAndTransformMessages() {
    const state = this.stateModel.get();
    state.messages = [];

    const getCurrentUserMessages = (specName: string, specUserId: string) => {
      return state.Records.filter((r: any) => {
        const specs = r.Specs || r.specs; // TODO: normalize and remove
        const hasCurrentUserId = specs.find(s => s.name === specName && s.value === specUserId);
        return !!hasCurrentUserId;
      });
    };

    let messagesFromUser = [];
    let messagesToUser = [];
    const recipientId = state.recipientId;

    if (state.isPatient) {
      messagesToUser = getCurrentUserMessages('recipientUserId', state.currentUserId).map(m =>
        this.transformMessage(m.Attributes, true)
      );
      messagesFromUser = state.messages.concat(
        getCurrentUserMessages('fromUserId', state.currentUserId).map(m =>
          this.transformMessage(m.Attributes, false)
        )
      );
    } else {
      messagesFromUser = getCurrentUserMessages('fromUserId', recipientId).map(m =>
        this.transformMessage(m.Attributes, true)
      );
      messagesToUser = state.messages.concat(
        getCurrentUserMessages('recipientUserId', recipientId).map(m =>
          this.transformMessage(m.Attributes, false)
        )
      );
    }

    state.messages = this.refineMessages(messagesFromUser.concat(messagesToUser));
    this.setChatState(state);
  }

  private transformMessage(message: MessageThread, fromRecipient: boolean) {
    const { sentOn, body, from } = message;
    const state = this.stateModel.get();
    let defaultSender = 'You';
    if (!fromRecipient && !state.isPatient) {
      const currentUserId = state.currentUserId;
      if (currentUserId != from.id) {
        defaultSender = from.display;
      }
    }
    return {
      senderDisplay: fromRecipient ? from.display : defaultSender,
      sentOn,
      content: body,
      fromRecipient
    };
  }

  private refineMessages(messages: ChatMessage[]) {
    messages = _orderBy(messages, 'sentOn');

    return messages;
  }
}
