import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { some as _some } from 'lodash';
import { AssessmentQuestion } from '../../services/models';
import { TreatmentPlanStateService } from '../../state/treatment-plan-state.service';
import { uniqueAnswerIds } from '../../../constants/constants';

@Component({
  selector: 'log-navigation',
  templateUrl: './log-navigation.component.html',
  styleUrls: []
})
export class LogNavigationComponent implements OnInit {
  @Input() questionIndex: number;
  @Input() pageSize: number;
  @Input() pages: number;
  @Input() pageNumber: number;
  @Input() currentQuestions: Array<AssessmentQuestion>;
  @Input() allowNavigation: boolean;
  @Input() message: string;
  @Output() nextClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() previousClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() alertDismissed: EventEmitter<boolean> = new EventEmitter();
  public pageArray: Array<{ isComplete: boolean }>;
  public areCurrentQuestionsUnanswered: boolean;
  public allowBackwardsNavigation: boolean;

  constructor() { }

  ngOnInit() {
    this.setPages();
  }

  onAlertDismissed() {
    this.message = null;
    this.alertDismissed.emit(true);
  }

  onNextClicked() {
    this.onAlertDismissed();
    // At this point for morning logs, if both times woke and minutes awake have been answered, the user entered zero for one, or both, of the values
    // This is due to the fact that areCurrentQuestionsUnanswered gets set to true if one or the other is set to zero
    // Only check this for the WAS_RESTLESS question type
    let timesAwokeWasAnswered = false;
    let timeAwakeWasAnswered = false;
    if (this.currentQuestions[0].uniqueAnswerId == uniqueAnswerIds.wasRestless) {
      timesAwokeWasAnswered = this.currentQuestions[0].conditionalQuestions[0]?.answer != null;
      timeAwakeWasAnswered = this.currentQuestions[0].conditionalQuestions[1]?.answer != null;
    }
    if (this.areCurrentQuestionsUnanswered) {
      if (timesAwokeWasAnswered && timeAwakeWasAnswered){
        this.message = 'Times awoken or minutes awake must not be 0.';
      }
      else {
        this.message = 'Please answer question(s) to continue.';
      }
    } else {
      this.nextClicked.emit(true);
    }
  }

  ngOnChanges(changes) {
    if (changes.pageNumber || changes.pages) {
      this.setPages();
    }
    if (changes.allowNavigation) {
      this.areCurrentQuestionsUnanswered = !this.allowNavigation;//_some(this.currentQuestions, q => (!q.answer || q.answer === '') && q.answer !== 0);
      this.message = null;
    }
    if (changes.questionIndex || changes.pageSize) {
      this.allowBackwardsNavigation = this.questionIndex >= this.pageSize;
    }
    if (changes.message && changes.message === 'CLEAR') {
      this.message = null;
    }
  }

  private setPages() {
    this.pageArray = [];
    for (let i = 0; i < this.pages; i++) {
      this.pageArray.push({isComplete: i < this.pageNumber});
    }
  }


}
