import { AssetDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';
import { prepareThroughVisualization } from '../questions/prepare-through-visualization/prepare-through-visualization';

export const prepareThroughVisualizationDef: AssetDefinition = {
  id: 21,
  name: 'prepare-through-visualization',
  label: 'Prepare Through Visualization',
  description: `Imagining oneself engage in behaviors improves performance and reduces anxiety.
                Help your Soldiers learn this skill by having them review visualization guidelines, listening to an example, and creating their own personalized mental imagery.
  `,
  categories: ['all', 'stress'],
  showInLibrary: true,
  readOnly: false,
  assessments: [
    prepareThroughVisualization
  ],
  url: 'prepare-through-visualization'
};
