import { AssessmentDefinitions } from './modules/field-definitions/index';
import { CalculatedFieldDefinitions } from './modules/field-definitions/calculated-field-definitions';
import { ModuleTemplates } from './modules/definitions';
import { AlgorithmDefinitions} from './modules/algorithms';
import { BaseCalculationDefinition } from '../../noctem-lib/src/core/contracts/models.calculations';
import { Dictionary } from './dictionary';
import { ThresholdDefinitions } from './modules/thresholds';
import { ModulePageDefinitions } from './modules/questions';



export const minimumLogEntriesToRecommendTactics = 2;
export const dateKeyFormat = 'YYYY-MM-DD';
export const CHECK_IN_ACTIVITATION_TIME = 5;
export const DEFAULT_NOTIFICATION_TIME = '05:00';
export const MODULE_TIMEOUT_IN_DAYS = 3;
export const minimumCompletedAssessmentsToShowData = 1;
export const MAXIMUM_LATENCY_OR_WASO = 180;

export const CalculationConstants: BaseCalculationDefinition = {
  GlobalFlags: {
    minimumLogEntriesToRecommendTactics,
    dateKeyFormat
  },
  AssessmentDefinitions,
  CalculatedFieldDefinitions,
  AlgorithmDefinitions,
  ModuleTemplates,
  FlagDefinitions: [],
  AssessmentQuestions: ModulePageDefinitions,
  ThresholdDefinitions
};


export const checkInIntervals = new Map([
    ['sleep-check-in', 1],
    ['team-check-in', 3]
  ]);

export class DataDictionary extends Dictionary {}
