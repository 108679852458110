import { Injectable }           from '@angular/core';
import { CacheService }         from '../../../../../noctem-lib/src/lib/services/cache-service';
import { AssessmentQuestion }   from '../../../../../noctem-lib/src/lib/services/models';
import { Meta }                 from '../../../../../noctem-lib/src/core';
import { LogStateService, Log, TreatmentPlanStateService } from '@noctem/web';
import { Subject } from 'rxjs';

const LOG_ENTRIES_KEY        = "CUSTOMER:LogCacheEntry:LOG_ENTRIES_KEY";
const COMPLETED_LOGS_KEY     = "getAllAsync:assessment"; //TODO: there should be a better way to link this back to assessment service
const PRESCRIPTION_KEY       = "getAllAsync:SleepPrescription";
const MAX_FAILED_SUBMISSIONS = 10;

@Injectable({
    providedIn: 'root'
})
export class LogCacheService {

    public onSync$ = new Subject<boolean>();

    constructor(private cacheService: CacheService,
                private treatmentPlanStateService: TreatmentPlanStateService,
                private logStateService: LogStateService) { }

    public saveLogForResubmission(logType: string, questions: Array<AssessmentQuestion>, logDate: string, logStartTime? : Date,logEndTime?: Date, isPartial?: boolean, logMeta?: Meta, percentage?: number) {
        this.updateSubmittableCacheEntry(`${logType}:${logDate}`, {
            logType, questions, logDate, logStartTime, logEndTime, isPartial, logMeta, percentage, failedSubmissions: 0, isInProcess: false
        })
        // Also update completed logs/assessments local list
        this.updateCacheLogsList(logType, questions, logDate, logStartTime, logEndTime, isPartial, logMeta);
    }

    private updateCacheLogsList(logType: string, questions: Array<AssessmentQuestion>, logDate: string, logStartTime? : Date,logEndTime?: Date, isPartial?: boolean, logMeta?: Meta){
        let cacheLogs = <Array<any>> this.cacheService.get(COMPLETED_LOGS_KEY);
        if(!cacheLogs){
            cacheLogs = [];
        }
        //create assessment
        const assessment = this.logStateService.logToAssessment(logType, questions, logDate, logStartTime, logEndTime, isPartial, logMeta);
        //add assessment to the list
        cacheLogs.push(assessment);
        this.cacheService.set(COMPLETED_LOGS_KEY, cacheLogs);
    }

    /**
     * Cache weekly assessment when there's no connection/offline mode
     * @param assessments
     * @param logDate
     * @param phaseName
     * @param logStartTime
     * @param logEndTime
     * @param isPartial
     * @param assessmentMeta
     */
    public saveAssessmentForResubmission(assessments: Array<{assessmentName: string, questions: Array<AssessmentQuestion>}>, logDate: string, phaseName: string, logStartTime? : Date,logEndTime?: Date, isPartial?: boolean, assessmentMeta?: Meta) {
        this.updateSubmittableCacheEntry(`${phaseName}`, {
            assessments, logDate, phaseName, logStartTime, logEndTime, isPartial, assessmentMeta, failedSubmissions: 0, isInProcess: false
        })
        // Also update completed logs/assessments local list
        this.updateCacheAssessmentList(assessments, logDate, phaseName, logStartTime, logEndTime, isPartial, assessmentMeta);
    }

    private updateCacheAssessmentList(assessments: Array<{assessmentName: string, questions: Array<AssessmentQuestion>}>, logDate: string, phaseName: string, logStartTime? : Date,logEndTime?: Date, isPartial?: boolean, assessmentMeta?: Meta){
        let cacheLogs = <Array<any>> this.cacheService.get(COMPLETED_LOGS_KEY);
        if(!cacheLogs){
            cacheLogs = []
        }
        //create assessment
        const assessment = this.logStateService.rawToAssessments(assessments, logDate, phaseName, logStartTime, logEndTime, isPartial, assessmentMeta);

        //check whether this phaseName already in the list
        const idx = cacheLogs.findIndex(item => item.phaseName == phaseName)
        if(idx>=0){
            cacheLogs[idx] = assessment;
        }
        else{
            //add assessment to the list if it's not there yet
            cacheLogs.push(assessment);
        }
        this.cacheService.set(COMPLETED_LOGS_KEY, cacheLogs);
    }

    /**
     * Cache tactic completion
     * @param tacticName
     */
    public cacheCompleteTactic(tacticName: string){
        const now = new Date().toISOString();
        this.updateSubmittableCacheEntry(`Tactics:${tacticName}`, {tacticName, date: now, failedSubmissions: 0, isInProcess: false});
        let cachePrescription = <Array <any>> this.cacheService.get(PRESCRIPTION_KEY);
        if(cachePrescription && cachePrescription.length>0){
            //get the last prescription as the current week
            const property = this.treatmentPlanStateService.tacticNameToReadOnProp(tacticName);
            cachePrescription[cachePrescription.length-1][property] = now;
            this.cacheService.set(PRESCRIPTION_KEY, cachePrescription);
        }
    }

    private updateSubmittableCacheEntry(key: string, entry: any) {
        let cacheEntries = this.cacheService.get(LOG_ENTRIES_KEY);
        if (!cacheEntries) {
            cacheEntries = {};
        }
        cacheEntries[key] = entry;
        this.cacheService.set(LOG_ENTRIES_KEY, cacheEntries);
    }

    public submitCachedLogs() {
        let cacheEntries = this.cacheService.get(LOG_ENTRIES_KEY);
        if (cacheEntries && Object.keys(cacheEntries).length > 0) {
            Object.entries(cacheEntries).forEach(entry => {
                let log = entry[1];
                const key = entry[0];
                if(!log['isInProcess']){
                    //flag in process to avoid multiple submission
                    log['isInProcess'] = true;
                    this.onSync$.next(true);
                    this.updateSubmittableCacheEntry(key, log);
                    if(key.includes('Log')){
                        this.logStateService.saveCachedLog(log['logType'], log['questions'], log['logDate'], log['logStartTime'], log['logEndTime'], log['isPartial'], log['logMeta']).subscribe(async (assessment) => {
                            this.deleteCacheEntry(key);
                            await this.logStateService.updateWeekLogs().catch(err =>{
                                console.log("Failed to updateWeekLogs " + err);
                            });
                            this.onSync$.next(false);
                        }, err => {
                            console.log(`Failed to save cached assessment: ${err}`);
                            if (log['failedSubmissions'] >= MAX_FAILED_SUBMISSIONS) {
                                alert("Failed to upload cached log: " + log['logType'] + " > " + log['logDate']);
                                this.deleteCacheEntry(key);
                                this.onSync$.next(false);
                            } else {
                                log['failedSubmissions'] += 1;
                                log['isInProcess'] = false;
                                this.updateSubmittableCacheEntry(key, log);
                                this.onSync$.next(false);
                            }
                        })
                    }
                    else if(key.includes('Tactics')){ //tactics completion
                        this.treatmentPlanStateService.completeCachedTactic(log["tacticName"], log["date"]).subscribe( prescription => {
                            this.deleteCacheEntry(key);
                            this.onSync$.next(false);
                        }, err => {
                            console.log(`Failed to save cached assessment: ${err}`);
                            if (log['failedSubmissions'] >= MAX_FAILED_SUBMISSIONS) {
                                alert("Failed to upload cached tactic completion: " + log["tacticName"]);
                                this.deleteCacheEntry(key);
                                this.onSync$.next(false);
                            } else {
                                log['failedSubmissions'] += 1;
                                log['isInProcess'] = false;
                                this.updateSubmittableCacheEntry(key, log);
                                this.onSync$.next(false);
                            }
                        })
                    }
                    else{ //Other assessment
                        this.logStateService.saveCachedAssessments(log['assessments'], log['logDate'], log['phaseName'], log['logStartTime'], log['logEndTime'], log['isPartial'], log['assessmentMeta']).subscribe(async (assessment) => {
                            this.deleteCacheEntry(key);
                            this.onSync$.next(false);
                            await this.treatmentPlanStateService.buildUserActionList(log['phaseName'], true).catch(err => {
                                console.log(err); //sometime
                            }); //need to call this to update app dashboard after submission

                        }, err => {
                            console.log(`Failed to save cached assessment: ${err}`);
                            if (log['failedSubmissions'] >= MAX_FAILED_SUBMISSIONS) {
                                alert("Failed to upload cached assessment: " + log['phaseName']);
                                this.deleteCacheEntry(key);
                                this.onSync$.next(false);
                            } else {
                                log['failedSubmissions'] += 1;
                                log['isInProcess'] = false;
                                this.updateSubmittableCacheEntry(key, log);
                                this.onSync$.next(false);
                            }
                        })
                    }
                }

            })
        }
    }

    private deleteCacheEntry(key: string) {
        let cacheEntries = this.cacheService.get(LOG_ENTRIES_KEY);
        if (cacheEntries) {
            delete cacheEntries[key]
            this.cacheService.set(LOG_ENTRIES_KEY, cacheEntries);
        }
    }
}
