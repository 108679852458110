import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const sleepRetrainingAlgorithm = new SleepModuleDefinition({
  name: 'sleep-retraining',
  isMedic: true,
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'isi-overall',
      valueToCompare: 13, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    }),
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-long-sleep',
      valueToCompare: 1, // in minutes
      operator: EqualityType.GreaterThan,
      priority: 0
    })

  ],
});

