<fieldset class="form-group question">
    <div class="form-check" *ngFor="let answer of question.answerOptions">
        <span (click)="valueUpdated($event, answer)">
            <div class="row no-gutters py-1">
                <input class="form-check-input col-2 align-self-center" type="radio" [value]="answer.value"
                    [(ngModel)]="radioSelected" name="radiobtn{{question.questionText}}{{answer.value}}">
                <label class="form-check-label col align-self-center" for="gridRadios1">
                    {{answer.display}}
                </label>
            </div>
        </span>
    </div>
</fieldset>