import { AssessmentDefinitions } from './modules/field-definitions';
import { CalculatedFieldDefinitions } from './modules/field-definitions/calculated-field-definitions';
import { FlagDefinitions } from './flag-definitions/flag-definitions';
import { BaseCalculationDefinition } from '../../noctem-lib/src/core/contracts/models.calculations';
import { Dictionary } from './dictionary';
import { questions } from './modules/questions';



const minimumLogEntriesToRecommendTactics = 3;
const dateKeyFormat = 'YYYY-MM-DD';

export const CalculationConstants: BaseCalculationDefinition = {
    GlobalFlags: {
      minimumLogEntriesToRecommendTactics,
      dateKeyFormat
    },
    AssessmentDefinitions,
    CalculatedFieldDefinitions,
    AlgorithmDefinitions: null,
    ModuleTemplates: [],
    FlagDefinitions,
    ThresholdDefinitions: [],
    AssessmentQuestions:Object.values(questions)
  };

export class DataDictionary extends Dictionary {}

