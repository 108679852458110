import { AssessmentDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const sleepCheckin: AssessmentDefinition = {
  name: 'sleep-check-in',
  description: 'Sleep Check-In',
  instructions: null,
  questions: [
    {
      id: 0,
      questionText: 'Rate how sleepy you feel right now?',
      questionEmphasis: null,
      questionType: 'slider',
      uniqueAnswerId: 'SLEEPY_FEELS',
      autofill: false,
      answerOptions: [
        { display: 'Extremely Alert', value: 1 },
        { display: 'Alert', value: 2 },
        { display: 'Neither alert nor sleepy', value: 3 },
        { display: 'Sleepy, but no difficulty remaining awake', value: 4 },
        { display: 'Extremely Sleepy, fighting sleep', value: 5 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 1,
      questionText: 'Have you used caffeine in the past 24 hours?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'USED_CAFFEINE',
      autofill: false,
      isCheckInQuestion: true,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [true],
          id: 0,
          questionText: 'In the past 24 hours, what sources of caffeine have you used?',
          questionEmphasis: 'Select all that apply',
          questionType: 'checkbox',
          uniqueAnswerId: 'SLEEP_CAFFEINE_SOURCE',
          autofill: false,
          isCheckInQuestion: true,
          conditionalQuestions: [
            {
              id: 1,
              questionText: '',
              questionType: 'text',
              autofill: false,
              uniqueAnswerId: 'SLEEP_CAFFEINE_SOURCE_OTHER',
              answerOptions: [],
              displayIfAnswerEquals: [5]
            }
          ],
          answerOptions: [
            {
              display: 'Coffee',
              value: 0
            },
            {
              display: 'Soda',
              value: 1
            },
            {
              display: 'Energy Drink',
              value: 2
            },
            {
              display: 'Tea',
              value: 3
            },
            {
              display: 'Gum',
              value: 4
            },
            {
              display: 'Other, please specify',
              value: 5,
            }
          ]
        },
        {
          displayIfAnswerEquals: [true],
          id: 2,
          questionText: `Enter the amount of caffeine used between the following (e.g., 1 stick of
            gum and one energy drink between 1200 – 1800 would be 2):`,
          questionEmphasis: null,
          questionType: 'column',
          uniqueAnswerId: 'CAFFEINE_AMOUNT',
          autofill: false,
          isCheckInQuestion: true,
          answerOptions: null,
          conditionalQuestionOptions: {
            columns: 2,
            columnHeaders: ['Time of Day', 'Amount']
          },
          conditionalQuestions: [
            {
              id: 0,
              questionText: '0000 - 0559',
              questionType: 'number',
              uniqueAnswerId: 'CAFFEINE_AMOUNT_1',
              autofill: false,
              isCheckInQuestion: true,
              answerOptions: null
            },
            {
              id: 1,
              questionText: '0600 - 1159',
              questionType: 'number',
              uniqueAnswerId: 'CAFFEINE_AMOUNT_2',
              autofill: false,
              isCheckInQuestion: true,
              answerOptions: null
            },
            {
              id: 2,
              questionText: '1200 - 1759',
              questionType: 'number',
              uniqueAnswerId: 'CAFFEINE_AMOUNT_3',
              autofill: false,
              isCheckInQuestion: true,
              answerOptions: null
            },
            {
              id: 3,
              questionText: '1800 - 2359',
              questionType: 'number',
              uniqueAnswerId: 'CAFFEINE_AMOUNT_4',
              autofill: false,
              isCheckInQuestion: true,
              answerOptions: null
            }
          ]
        }
      ],
      redirectMap: new Map([[false, 2]])
    },
    {
      id: 2,
      questionText: 'Have you slept in the past 24 hours?',
      questionEmphasis: null,
      questionType: 'radio',
      uniqueAnswerId: 'SLEPT_IN_PAST_24',
      autofill: false,
      answerOptions: [
        { display: 'Yes', value: true },
        { display: 'No', value: false }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true,
      redirectMap: new Map([[false, 8]])
    },
    {
      id: 3,
      questionText: 'How many total hours did you sleep over the past 24 hours?',
      questionEmphasis: null,
      questionType: 'duration',
      uniqueAnswerId: 'TOTAL_SLEEP_HOURS_PAST_24',
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: true,
      validation: {
        maxLength: 24
      },
      conditionalQuestions: []
    },
    {
      id: 4,
      questionText: 'The quality of my sleep was',
      questionEmphasis: 'Rate your sleep quality on the scale below',
      questionType: 'slider',
      uniqueAnswerId: 'SLEEP_QUALITY',
      invertValues: true,
      autofill: false,
      answerOptions: [
        { display: 'Very Good', value: 1 },
        { display: 'Good', value: 2 },
        { display: 'Alright', value: 3 },
        { display: 'Bad', value: 4 },
        { display: 'Very Bad', value: 5 }
      ],
      conditionalQuestions: [],
      isCheckInQuestion: true
    },
    {
      id: 5,
      questionText: 'Think about your longest sleep period in the past 24 hours:',
      questionEmphasis: 'What time did you try to go to sleep?',
      questionType: 'time',
      uniqueAnswerId: 'SLEEP_LONGEST_SLEEP',
      autofill: false,
      isCheckInQuestion: true,
      answerOptions: null,
      conditionalQuestions: [
        {
          displayIfAnswerEquals: [],
          id: 0,
          questionText: 'How many minutes did it take you to fall asleep?',
          questionEmphasis: null,
          questionType: 'number',
          uniqueAnswerId: 'SLEEP_LATENCY',
          autofill: false,
          answerOptions: null,
          isCheckInQuestion: true
        },
        {
          displayIfAnswerEquals: [],
          id: 1,
          questionText: 'How many times was your sleep interrupted?',
          questionEmphasis: null,
          questionType: 'number',
          uniqueAnswerId: 'SLEEP_INTERRUPTED',
          autofill: false,
          answerOptions: null,
          isCheckInQuestion: true
        },
        {
          displayIfAnswerEquals: [],
          id: 2,
          questionText: 'How many minutes did these interruptions last?',
          questionEmphasis: null,
          questionType: 'number',
          uniqueAnswerId: 'SLEEP_INTERRUPTION_TOTAL',
          autofill: false,
          answerOptions: null,
          isCheckInQuestion: true
        }
      ]
    },
    {
      id: 6,
      questionText: 'What time did you wake up?',
      questionEmphasis: null,
      questionType: 'time',
      uniqueAnswerId: 'WAKE_TIME',
      autofill: false,
      answerOptions: null,
      isCheckInQuestion: true
    },
    {
      id: 7,
      questionText: 'Which of the following disrupted your sleep?',
      questionEmphasis: 'Select all that apply',
      questionType: 'checkbox',
      name: 'disrupted-sleep',
      uniqueAnswerId: 'SLEEP_DISRUPTED',
      autofill: false,
      answerOptions: [
        { display: 'Sleep Environment', value: 0 },
        { display: 'Duties', value: 1 },
        { display: 'Nightmares', value: 2 },
        { display: 'Worries', value: 3 },
        { display: 'Other, please specify', value: 4 },
        { display: 'None', value: 5, clearOtherAnswers: true }
      ],
      conditionalQuestions: [
        {
          id: 1,
          questionText: 'Other',
          questionType: 'text',
          autofill: false,
          uniqueAnswerId: 'SLEEP_DISRUPTED_OTHER',
          answerOptions: [],
          displayIfAnswerEquals: [4]
        }
      ],
      isCheckInQuestion: true
    },
    {
      id: 8,
      questionText: 'In the past 24 hours, what prevented you from sleeping?',
      questionEmphasis: 'Select all that apply',
      name: 'prevented-sleep',
      questionType: 'checkbox',
      uniqueAnswerId: 'WHAT_PREVENTED_SLEEP',
      autofill: false,
      answerOptions: [
        { display: 'Duty-related activities', value: 0 },
        { display: 'Environment (temperature, noise, sleeping space)', value: 1 },
        { display: 'Daytime sleeping', value: 2 },
        { display: 'Thoughts and concerns', value: 3 },
        { display: 'Bad dreams or nightmares', value: 4 },
        { display: 'Spending time with buddies', value: 5 },
        { display: 'Communicating with friends/family back home', value: 6 },
        { display: 'Other, please specify', value: 7 },
        { display: 'None', value: 8, clearOtherAnswers: true }
      ],
      conditionalQuestions: [
        {
          id: 1,
          questionText: 'Other',
          questionType: 'text',
          autofill: false,
          uniqueAnswerId: 'WHAT_PREVENTED_SLEEP_OTHER',
          answerOptions: [],
          displayIfAnswerEquals: [7]
        }
      ],
      isCheckInQuestion: true
    }
  ]
};
