import { SleepModuleDefinition, Condition, DataSummationType, EqualityType, ModuleContentPage } from "../../../../noctem-lib/src/core/contracts/models.calculations";

export const buildConfidenceAlgorithm = new SleepModuleDefinition({
  name: 'build-confidence',
  formula: [
    new Condition({
      summationType: DataSummationType.Average,
      fieldName: 'pcl5-avoid',
      valueToCompare: 0,
      operator: EqualityType.GreaterThan,
      priority: 0
  }),
  new Condition({
    summationType: DataSummationType.Average,
    fieldName: 'gad7-nervous',
    valueToCompare: 0,
    operator: EqualityType.GreaterThan,
    priority: 0
  }),
  new Condition({
    summationType: DataSummationType.Average,
    fieldName: 'gad7-worry',
    valueToCompare: 0,
    operator: EqualityType.GreaterThan,
    priority: 0
  }),
  new Condition({
    summationType: DataSummationType.Average,
    fieldName: 'gad7-worry-too-much',
    valueToCompare: 0,
    operator: EqualityType.GreaterThan,
    priority: 0
  })
],
});
