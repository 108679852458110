import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentAnswer, AssessmentQuestion } from '../../services/models';

@Component({
  selector: 'column-question',
  templateUrl: './column-question.component.html',
  styleUrls: []
})
export class ColumnQuestionComponent implements OnInit {
  @Input() question: AssessmentQuestion;
  @Output() answerUpdated: EventEmitter<any> = new EventEmitter();
  public answerValues = [];

  constructor() { }

  ngOnInit() {
    if(this.question.conditionalQuestions == null || this.question.conditionalQuestions.length === 0) {
      throw new Error("Column question types must have conditional questions");
    }

    this.question.conditionalQuestions.forEach(q => {
        this.answerValues.push(q.answer);
    });
    }

  checkAnswers(value: number) {
    return this.question.answer?.some(ans => ans.value === value);
  }

  valueUpdated(event, question: AssessmentQuestion) {
    const answer = {
      answerId: question.uniqueAnswerId,
      value: event.target.value
    }
    this.answerUpdated.emit(answer);
  }

  valueValidated(e, maxlength) {
    return true;
    // if (e && e.target && e.target.value && e.target.value !== '') {
    //    return e.target.value.length < maxlength;
    // } else {
    //   return true;
    // }
  }
}
