import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const sus: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'gad7-overall',
    unit: UnitType.Number,
    abbreviation: 'Gad 7 Overall',
    description: `Gad 7 Overall`,
    assessmentType: LogDefinitionTypes.GAD7
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'gad7-nervous',
    unit: UnitType.Number,
    abbreviation: 'Nervous',
    description: `Nervous`,
    assessmentType: LogDefinitionTypes.GAD7,
    answerId: 'GAD7_NERVOUS'
  }),

  //#endregion
];
