import { LogDefinitionTypes } from '../../../../noctem-lib/src/constants/constants';
import { FieldDefinitionBase, FieldType, UnitType, AssessmentFieldDefinition,
         AssessmentGroupScoreFieldDefinition } from '../../../../noctem-lib/src/core/contracts/models.calculations';

export const isi: Array<FieldDefinitionBase> =
[

//#endregion
  new AssessmentGroupScoreFieldDefinition({
    _type: FieldType.AssessmentGroupScore,
    name: 'isi-overall',
    unit: UnitType.Number,
    abbreviation: 'ISI Overall',
    description: `ISI Overall`,
    assessmentType: LogDefinitionTypes.ISI
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-fall-asleep',
    unit: UnitType.Number,
    abbreviation: 'Asleep',
    description: `Fall Asleep`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_FALL_ASLEEP'
  }),
  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-stay-asleep',
    unit: UnitType.Number,
    abbreviation: 'Stay Asleep',
    description: `Stay Asleep`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_STAY_ASLEEP'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-wake-early',
    unit: UnitType.Number,
    abbreviation: 'Wake Early',
    description: `Wake Early`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_WAKE_EARLY'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-sleep-pattern',
    unit: UnitType.Number,
    abbreviation: 'Sleep Pattern',
    description: `Sleep Pattern`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_SLEEP_PATTERN'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-noticeable',
    unit: UnitType.Number,
    abbreviation: 'Is Noticeable',
    description: `Is Noticeable`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_NOTICEABLE'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-worried',
    unit: UnitType.Number,
    abbreviation: 'Worried',
    description: `Worried`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_WORRIED'
  }),

  new AssessmentFieldDefinition({
    _type: FieldType.Assessment,
    name: 'isi-interferes',
    unit: UnitType.Number,
    abbreviation: 'Interferes',
    description: `Interferes`,
    assessmentType: LogDefinitionTypes.ISI,
    answerId: 'ISI_INTERFERE'
  }),


  //#endregion
];
